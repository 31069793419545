import { Button } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

type WrapProps = { rtl?: boolean }

export const Wrap = styled.div<WrapProps>`
  body {
    margin: 0;
    padding: 0;
    @page {
      margin: 0;
    }
  }

  direction: ${p => ((p.rtl ?? false) ? 'rtl' : 'ltr')};

  padding: 2cm;
  width: 210mm;
  margin: 0 auto;
  line-height: 1.5;
  height: auto;
`

export const NovartisWrap = styled(Wrap)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 16mm auto;
  height: 220mm;

  @media print {
    margin: 16mm;
  }
`

export const NovartisFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;

  @media print {
    display: block;
    width: auto;
  }
`

export const NovartisLogo = styled.img`
  height: 62px;
  vertical-align: middle;
`

export const SmallSanaLogo = styled.img`
  height: 25px;
  vertical-align: middle;

  @media print {
    margin-left: 105mm;
  }
`

export const WrapButtons = styled.div`
  position: fixed;
  top: 2rem;
  right: 2rem;
  text-align: center;

  @media print {
    display: none !important;
  }
`

export const WrapMedLearn = styled.div<WrapProps>`
  body {
    margin: 0;
    padding: 0;
    @page {
      margin: 0;
    }
  }

  direction: ${p => ((p.rtl ?? false) ? 'rtl' : 'ltr')};

  padding: 2cm;
  width: 210mm;
  padding-bottom: 0;
  margin: 0 auto;
  margin-top: -7rem; /* Cover HeaderWrapper */
  line-height: 1.5;
`

export const TempHeading = styled.div`
  text-align: center;
  font-size: 3rem;
  margin: 0 0 2rem 0;
`

export const DateStyled = styled.div`
  text-align: center;
`

export const UserName = styled.div`
  text-align: center;
  margin-top: 1rem;
`

export const OnBehalf = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const GiantPadding = styled.div`
  padding-top: 8rem;
`

export const BiggerPadding = styled.div`
  padding-top: 8rem;
`

export const BigPadding = styled.div`
  padding-top: 4rem;
`

export const MediumPadding = styled.div`
  padding-top: 3rem;
`

export const CourseTitle = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.8rem;
`

export const CourseSubTitle = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 1.3rem;
`

export const P = styled.p<{ bold?: boolean }>`
  margin: 1rem 0;
  font-size: 0.75rem;
  ${p =>
    (p.bold ?? false) &&
    css`
      font-weight: 500;
    `}
`

export const LogoWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 1rem 0;
`

export const SanaLogoWrap = styled.div`
  margin-bottom: 1rem;
  & svg {
    display: block;
    width: 3rem;
    height: 3rem;
  }
`

export const LogoWrapMedLearn = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0 0;
`

export const SignatureWrapMedLearn = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
`

export const LogoKarolinska = styled.img`
  height: 85px;
`

export const HemfridLogo = styled.img`
  display: block;
  margin: auto;
  height: 10rem;
`

export const NovartisContainer = styled.div`
  font-size: 1.5rem;
  text-align: center;
  line-height: 5rem;
`

export const LogoNovartis = styled.img`
  display: block;
  margin: auto;
  height: 10rem;
`

export const NovartisCourseName = styled.div`
  color: #0f018e;
  font-size: 2rem;
`

export const LogoSocialstyrelsen = styled.img`
  height: 35px;
`

export const LogoVNS = styled.img`
  height: 160px;
`

export const NVSLogo = styled.img`
  width: 130px;
`

export const LogoMedLearn = styled.img`
  height: 60px;
  align: left;
`

export const SignatureMedLearn = styled.img`
  height: 100px;
  align: left;
`

export const MedlearnDetails = styled.div`
  padding: 0 1.5rem;
`

export const PMedLearn = styled.p`
  margin: 1rem 0;
  font-size: 1rem;
  text-align: center;
`

export const PMedLearnLeftLine = styled.div`
  border-bottom: 1px solid #ccc;
`
export const PMedLearnLeft = styled.p`
  margin: 0 0 1rem;
  font-size: 1rem;
  padding: 1rem 0;
`

export const MedLearnInfo = styled.p`
  margin: 1rem 0;
  font-size: 0.75rem;
`

export const H2MedlearnRed = styled.h2`
  font-size: 1.3rem;
  color: #d4102f;
  line-height: 1.1em;
  margin-bottom: 0;
`

export const H1Medlearn = styled.h1`
  font-size: 2rem;
  line-height: 1.1em;
`

export const NovartisLineSeparator = styled.div`
  height: 1px;
  background-color: #ccc;
  margin: 1rem;
`

export const PrintButton = styled(Button)`
  margin: 1rem;
`
