import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { IdentitiesSelector } from 'sierra-client/components/common/identities-selector'
import { useAssignableUsersFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { Identity } from 'sierra-client/components/common/identities-selector/types'
import { useNotif } from 'sierra-client/components/common/notifications'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { RequiredAssignmnentSwitch } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { useExistingUserAssignments, useUnassignContent } from 'sierra-client/components/sharing/hooks'
import { ExistingAssignedUsersContainer } from 'sierra-client/components/sharing/tabs/components/containers'
import {
  RemainingUsersMenuItem,
  ShareModalListItemSkeleton,
  SharingModalMenuItemWithAssignSelfPacedControls,
} from 'sierra-client/components/sharing/tabs/components/sharing-modal-menu-item'
import {
  DropdownContainer,
  IdentitiesToCollaborators,
} from 'sierra-client/components/sharing/tabs/utils/autocomplete'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { getFlag } from 'sierra-client/config/global-config'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { getAbsoluteDueDate, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { AssignmentPriority } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminCoursesCourseEnrollmentsCount } from 'sierra-domain/routes'
import { DatePickerPrimitive } from 'sierra-ui/components/date-picker/date-picker-primitive'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const RequiredContainer = styled(View)`
  border-top: 1px solid ${token('border/default')};
  padding-top: 20px;
  max-width: 258px;
`

export const AssignCourseParticipants: React.FC<{
  content: SharingModalContent
  canEditAssignments: boolean
}> = ({ content, canEditAssignments }) => {
  const { t } = useTranslation()
  const notifications = useNotif()
  const { assignWithDueDate, setUsersDueDate } = useDueDate()
  const unassignContent = useUnassignContent()
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()

  const [dueDate, setDueDate] = useState<string>()
  const [numRemainingGroupAssignments, setNumRemainingGroupAssignments] = useState<number>()

  const [selectedIdentities, setSelectedIdentities] = useState<Identity[]>([])

  const [assignmentPriority, setAssignmentPriority] = useState<AssignmentPriority>(
    content.isDefaultRequiredAssignmentEnabled ? 'required' : 'normal'
  )

  const [wantMoreExistingAssignments, setWantMoreExistingAssignments] = useState(false)
  const canSetContentDueDates = useHasOrganizationPermission('SET_CONTENT_DUE_DATES')

  const fetchIdentities = useAssignableUsersFetcher()

  const {
    assignments,
    hasMore: hasMoreAssignments,
    reloadAssignments,
    isLoading: isLoadingAssignments,
  } = useExistingUserAssignments({
    courseId: content.id as NanoId12,
    shouldLoadMore: wantMoreExistingAssignments,
  })

  const onAssignClick = async (): Promise<void> => {
    if (selectedIdentities.length === 0) return
    await assignWithDueDate(
      IdentitiesToCollaborators(selectedIdentities).map(user => ({
        assignee: {
          type: 'user',
          id: user.uuid,
          dueDate: dueDate !== undefined ? { type: 'absolute', date: dueDate } : undefined,
        },
        content: {
          id: content.id,
          type: content.type,
        },
        isRequired: assignmentPriority === 'required',
      }))
    )

    setSelectedIdentities([])
    setDueDate(undefined)
    setAssignmentPriority(content.isDefaultRequiredAssignmentEnabled ? 'required' : 'normal')
    notifications.push({
      type: 'custom',
      level: 'info',
      icon: 'send--filled',
      body: 'Invite sent',
    })

    selectedIdentities.forEach(identity => {
      void dispatch(
        assignmentPriorityLogger({
          contentType: 'course',
          assignmentPriority: assignmentPriority,
          hasDueDate: dueDate !== undefined,
          contentId: content.id,
          userId: identity.id,
        })
      )
    })
  }

  const fetchEnrollmentCount = useCallback(
    async () =>
      postWithUserErrorException(XRealtimeAdminCoursesCourseEnrollmentsCount, {
        courseIds: [content.id],
      }),
    [content.id, postWithUserErrorException]
  )

  useEffect(() => {
    const hasLoadedAssignmentCount = numRemainingGroupAssignments !== undefined
    if (isLoadingAssignments === false && !hasLoadedAssignmentCount) {
      void fetchEnrollmentCount().then(({ courseToEnrollments }) => {
        const totalAssignmentCount = courseToEnrollments[content.id]
        if (totalAssignmentCount !== undefined) {
          setNumRemainingGroupAssignments(totalAssignmentCount - assignments.length)
        }
      })
    }
  }, [
    assignments.length,
    content.id,
    fetchEnrollmentCount,
    isLoadingAssignments,
    numRemainingGroupAssignments,
  ])

  return (
    <div>
      <View justifyContent='space-between'>
        <IdentitiesSelector
          disabled={!canEditAssignments}
          grow
          fetchIdentities={fetchIdentities}
          selectedIdentities={selectedIdentities}
          onSelect={identity => setSelectedIdentities(previous => [...previous, identity])}
          onUnselect={identity => {
            setSelectedIdentities(previous => previous.filter(it => it.id !== identity.id))
          }}
          placeholder='admin.add-users'
          trailingVisual={
            <DropdownContainer>
              <DatePickerPrimitive
                disablePastDates
                value={dueDate !== undefined ? DateTime.fromFormat(dueDate, 'yyyy-MM-dd') : undefined}
                onChange={newDate => setDueDate(newDate?.toFormat('yyyy-MM-dd'))}
                renderExtraContent={() =>
                  getFlag('required-assignments') && (
                    <RequiredContainer direction='column' gap='4'>
                      <RequiredAssignmnentSwitch
                        assignmentPriority={assignmentPriority}
                        setAssignmentPriority={setAssignmentPriority}
                      />
                    </RequiredContainer>
                  )
                }
                renderTrigger={() => (
                  <DefaultDropdownTrigger variant='ghost' grow>
                    {dueDate ?? t('due-date.due-date')}
                    {dueDate !== undefined && (
                      <IconButton
                        variant='transparent'
                        size='small'
                        onClick={() => setDueDate(undefined)}
                        iconId='close'
                      />
                    )}
                  </DefaultDropdownTrigger>
                )}
              />
            </DropdownContainer>
          }
        />
        <Button
          disabled={selectedIdentities.length === 0}
          onClick={async () => {
            await onAssignClick()
            void reloadAssignments()
          }}
        >
          {t('dictionary.assign')}
        </Button>
      </View>

      <ExistingAssignedUsersContainer>
        {isLoadingAssignments === true && assignments.length === 0 && (
          <View direction='column' gap='none'>
            <ShareModalListItemSkeleton />
            <ShareModalListItemSkeleton />
          </View>
        )}

        {assignments
          .map(assignment => ({
            type: 'user' as const,
            uuid: assignment.userInfo.baseUserInfo.userId,
            firstName: assignment.userInfo.baseUserInfo.firstName,
            lastName: assignment.userInfo.baseUserInfo.lastName,
            email: assignment.userInfo.baseUserInfo.email,
            avatar: assignment.userInfo.baseUserInfo.avatar,
            avatarColor: assignment.userInfo.baseUserInfo.avatarColor,
            dueDate: assignment.dueDateDirect ?? assignment.dueDateGroup,
          }))
          .map(u => (
            <SharingModalMenuItemWithAssignSelfPacedControls
              key={u.uuid}
              user={u}
              onSetDueDate={
                canSetContentDueDates
                  ? async dueDate => {
                      await setUsersDueDate(
                        [u.uuid],
                        [
                          {
                            content: { id: content.id, type: content.type },
                            // Recurrent assignments not supported from the share modal
                            dueDate: dueDate === u.dueDate ? undefined : getAbsoluteDueDate(dueDate),
                          },
                        ]
                      )
                      void reloadAssignments()
                    }
                  : undefined
              }
              onRemoveDueDate={
                canSetContentDueDates
                  ? async () => {
                      await setUsersDueDate(
                        [u.uuid],
                        [
                          {
                            content: { id: content.id, type: content.type },
                            dueDate: undefined,
                          },
                        ]
                      )
                      void reloadAssignments()
                    }
                  : undefined
              }
              onUnassign={
                canEditAssignments
                  ? async () => {
                      await unassignContent(u.uuid, content)
                      void reloadAssignments()
                    }
                  : undefined
              }
            />
          ))}

        <InView
          key={'load-more'}
          onChange={inView => {
            if (hasMoreAssignments) setWantMoreExistingAssignments(inView && isLoadingAssignments !== true)
          }}
        >
          <div />
        </InView>
        {!hasMoreAssignments &&
          numRemainingGroupAssignments !== undefined &&
          numRemainingGroupAssignments > 0 && (
            <RemainingUsersMenuItem
              remainingUsersCount={numRemainingGroupAssignments}
              onClick={() => getGlobalRouter().navigate({ to: `/manage/courses/${content.id}` })}
            />
          )}
        {hasMoreAssignments && <ShareModalListItemSkeleton />}
      </ExistingAssignedUsersContainer>
    </div>
  )
}
