import { useMemo, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'
import { DesktopHelper } from 'sierra-client/views/course-helper/desktop/desktop-helper'
import { useIsMobileHelper } from 'sierra-client/views/course-helper/hooks/use-is-mobile-helper'
import { useUpdateCourseHelperState } from 'sierra-client/views/course-helper/hooks/use-update-course-helper-state'
import { MobileHelper } from 'sierra-client/views/course-helper/mobile/mobile-helper'
import { HelperAnimationProvider } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import { SanaEditor } from 'sierra-domain/v3-author'
import { LightTokenProvider } from 'sierra-ui/theming'

export const CourseHelper: React.FC<{
  editor: SanaEditor | undefined
  currentFile: SelfPacedFile
  tutorState: TutorState
}> = ({ editor, currentFile, tutorState }) => {
  const { prevFile, nextFile, nextUp } = useSelfPacedFiles()
  const isOnePageCourse = prevFile === undefined && nextFile === undefined

  const [animationEnabled, setAnimationEnabled] = useState(true)
  const isMobileHelper = useIsMobileHelper()

  const ref = useRef<HTMLDivElement>(null)
  const [measure, { width, height }] = useMeasure()

  const helperAnimation = useMemo(
    () => ({
      ref,
      size: { width, height },
      measure,
      animationEnabled,
      setAnimationEnabled,
    }),
    [animationEnabled, width, height, measure]
  )

  useUpdateCourseHelperState({ editor, currentFile })

  if (
    isOnePageCourse &&
    nextUp.type !== 'next-course-in-program' &&
    nextUp.type !== 'program-pending' &&
    nextUp.type !== 'program-completed' &&
    nextUp.type !== 'next-course-in-path'
  ) {
    return null
  }

  return (
    <LightTokenProvider>
      <HelperAnimationProvider value={helperAnimation}>
        {isMobileHelper ? (
          <MobileHelper tutorState={tutorState} />
        ) : (
          <DesktopHelper currentFile={currentFile} tutorState={tutorState} />
        )}
      </HelperAnimationProvider>
    </LightTokenProvider>
  )
}
