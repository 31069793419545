import { useQuery } from '@tanstack/react-query'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { User } from 'sierra-domain/user'

export function isFurtherAuthenticationRequired(user: User | undefined): boolean {
  if (user === undefined) return true
  if (user.registrationStep !== 'done' || user.completedAt === undefined) return true
  if (user.isPendingEmailVerification) return true
  if (user.disabled) return true
  return false
}

export function useIsFurtherAuthenticationRequired(): boolean {
  const authQueryResult = useQuery(getAuthClient().authQuery)
  const isLoggedIn = authQueryResult.data?.status !== 'ok'

  const userQueryResult = useQuery({
    ...currentUserQuery,
    enabled: isLoggedIn,
    select: isFurtherAuthenticationRequired,
  })

  return Boolean(isLoggedIn || userQueryResult.isPending || userQueryResult.data)
}
