import { apiQueryOptions } from 'sierra-client/state/api'
import { XRealtimeContentHome } from 'sierra-domain/routes'

export const homeContentQuery = apiQueryOptions(
  XRealtimeContentHome,
  {},
  {
    retry: false,
    staleTime: 5 * 1000, // 5 seconds
  }
)
