import { useTeamspaceByContentId } from 'sierra-client/api/hooks/use-teamspace'
import { useCourseCollaborators } from 'sierra-client/api/queries/course-collaborators'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CourseId } from 'sierra-domain/api/nano-id'
import { CourseTeamspace } from 'sierra-domain/api/teamspace'
import { isDefined } from 'sierra-domain/utils'

export type TeamspaceName =
  | { href: string; name: string; id: string; type: 'found'; iconTheme?: string }
  | { type: 'not-found'; name: string; id: string }
  | { type: 'private-teamspace' }
  | { type: 'loading' }

export const useTeamspaceNameLight = (
  role: 'owner' | 'editor' | undefined,
  teamspace?: CourseTeamspace
): TeamspaceName => {
  const { t } = useTranslation()

  if (isDefined(teamspace))
    return {
      type: 'found',
      name: teamspace.displayName,
      href: `/t/${teamspace.id}`,
      id: teamspace.id,
      iconTheme: teamspace.iconTheme,
    }
  if (role === 'owner')
    return {
      type: 'found',
      name: t('author.content.my-content'),
      href: '/create?tab=created-by-me',
      id: 'my-content',
    }
  else if (role === 'editor')
    return {
      type: 'found',
      name: t('author.content.shared-with-me'),
      href: '/create?tab=shared-with-me',
      id: 'shared-with-me',
    }
  return { type: 'not-found', name: 'No teamspace', id: 'no-teamspace' }
}

export const useTeamspaceName = (contentId: CourseId): TeamspaceName => {
  const { t } = useTranslation()
  const courseCollaborators = useCourseCollaborators({ courseId: contentId })
  const teamspaceQuery = useTeamspaceByContentId(contentId)
  const teamspace = teamspaceQuery.data
  const userId = useSelector(selectUserId)

  if (teamspace === undefined) return { type: 'loading' }

  const isMyContent = courseCollaborators.some(user => user.role === 'owner' && user.uuid === userId)
  const isSharedWithMe = courseCollaborators.some(user => user.role !== 'owner' && user.uuid === userId)

  if (teamspace.type === 'found')
    return {
      type: 'found',
      name: teamspace.displayName,
      href: `/t/${teamspace.id}`,
      id: teamspace.id,
      iconTheme: teamspace.iconTheme,
    }
  if (isMyContent)
    return {
      type: 'found',
      name: t('author.content.my-content'),
      href: '/create?tab=created-by-me',
      id: 'my-content',
    }
  if (isSharedWithMe)
    return {
      type: 'found',
      name: t('author.content.shared-with-me'),
      href: '/create?tab=shared-with-me',
      id: 'shared-with-me',
    }
  if (teamspace.type === 'not-found') return { type: 'not-found', name: 'No teamspace', id: 'no-teamspace' }
  else return { type: 'private-teamspace' }
}
