import React, { useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { BlockQuoteColorPicker } from 'sierra-client/views/v3-author/block-quote/block-quote-color-picker'
import { Toolbar, ToolbarIcon, ToolbarSeparator } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext, useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { CourseTheme, QuoteBlockColor } from 'sierra-domain/content/v2/content'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { isDefined } from 'sierra-domain/utils'
import { color as transformColor } from 'sierra-ui/color'
import { Tooltip } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider, palette, spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { ThemeName, getTheme } from 'sierra-ui/theming/legacy-theme'
import { Node } from 'slate'
import { useSelected, useSlateStatic } from 'slate-react'
import styled, { ThemeProvider, css, useTheme } from 'styled-components'

const SubtitlePlaceholder = styled.span`
  position: absolute;
  user-select: none;
  pointer-events: none;
  bottom: 0;
  width: max-content;
  opacity: 0.5;
`

const LargeTextButton = styled(Button)<{ selected: boolean }>`
  color: ${p => (p.selected ? palette.primitives.white : p.theme.color.grey35)};
  padding: 0;

  ${Text} {
    font-size: 1rem;
  }

  &:hover {
    color: ${palette.primitives.white};
  }
`
const SmallTextButton = styled(Button)<{ selected: boolean }>`
  color: ${p => (p.selected ? palette.primitives.white : p.theme.color.grey35)};
  padding: 0;

  ${Text} {
    font-size: 0.8rem;
  }

  &:hover {
    color: ${palette.primitives.white};
  }
`

function isEmpty(node: Node): boolean {
  return hasOnlyEmptyTextInNodes([node])
}

const SubtitleWrapper = styled.span`
  ${fonts.body.small};
  color: ${p => p.theme.home.textColor};
  width: 100%;
  position: relative;
  display: block;
`

export const SubtitleContainer = React.forwardRef<HTMLSpanElement, SlateWrapperProps>(
  ({ element, readOnly, children, attributes, ...rest }, ref) => {
    const { preview } = useRenderingContext()
    const isHidden = isEmpty(element) && (preview === false || readOnly)

    return (
      <SubtitleWrapper
        {...rest}
        {...attributes}
        ref={ref}
        // eslint-disable-next-line react/forbid-component-props
        style={isHidden ? { display: 'none' } : undefined}
      >
        {children}
      </SubtitleWrapper>
    )
  }
)

export const BlockQuoteSubtitle: SlateFC = ({ element, children, readOnly }) => {
  const { t } = useTranslation()
  assertElementType('block-quote-subtitle', element)

  return (
    <>
      {children}
      {isEmpty(element) && !readOnly && (
        <SubtitlePlaceholder contentEditable={false}>
          {t('author.slate.block-quote-add-subtitle')}
        </SubtitlePlaceholder>
      )}
    </>
  )
}

const QuoteWrapper = styled.div<{ $big: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: text;
  margin-right: auto;

  & > *:not(${SubtitleWrapper}) {
    & span {
      ${p =>
        p.$big === true
          ? css`
              font-size: 1.6875rem;
              letter-spacing: -0.005em;
              line-height: 1.3;

              @media screen and (max-width: 460px) {
                font-size: 1.5rem;
              }
            `
          : css`
              font-size: 1.375rem;
              line-height: 1.3;
              letter-spacing: -0.02em;
            `};
    }
  }

  & ${SubtitleWrapper} {
    color: ${p => p.theme.home.textColor};
  }

  padding-left: 28px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: ${p => transformColor(p.theme.home.textColor).opacity(0.1).toString()};
    width: 4px;
    height: 100%;
    border-radius: 2px;
    left: 0;
  }
`

const QuoteContainer = styled.div`
  position: relative;
  background: ${p => p.theme.home.backgroundColor};
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out;
  padding: ${spacing['40']} ${spacing['32']};
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BlockQuote: SlateFC = ({ children, element, readOnly }) => {
  assertElementType('block-quote', element)

  const { t } = useTranslation()
  const editor = useSlateStatic()
  const size = element.size ?? 'small'
  const preview = useSelected()

  // We still need to support the deprecated color attribute, and translate them to a theme name
  const mapQuoteBlockColorToThemeName = ({
    color,
    theme,
  }: {
    color?: QuoteBlockColor
    theme?: CourseTheme
  }): ThemeName => {
    if (isDefined(theme)) return theme.name

    if (isDefined(color)) {
      switch (color) {
        case 'white':
          return 'white'
        case 'black':
          return 'black'
        case 'blue':
          return 'blue-dark'
        case 'red':
          return 'red-vivid-darker'
      }
      color satisfies never
    }

    return 'white'
  }

  const theme = useTheme()

  const currentThemeName = mapQuoteBlockColorToThemeName({
    theme: element.theme,
    color: element.color,
  })

  const [previewThemeName, setPreviewThemeName] = useState<ThemeName | undefined>()
  const currentTheme = getTheme(theme, previewThemeName ?? currentThemeName)

  const { id } = element

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <RenderingContext
          withGrid={false}
          preventDrag={true}
          allowBlockComments={false}
          preview={preview}
          placeholder={readOnly ? undefined : 'author.slate.quote-title-placeholder'}
          disableMenu={true}
        >
          <QuoteContainer data-block-inner={id}>
            <QuoteWrapper $big={element.size === 'big'}>{children}</QuoteWrapper>

            {!readOnly && (
              <Toolbar elementId={id}>
                {/* Light token provider needed for tooltip text color */}
                <LightTokenProvider>
                  <View paddingLeft='8' paddingRight='8'>
                    <Tooltip title={t('author.block-editor.quote-small')}>
                      <SmallTextButton
                        variant='transparent'
                        selected={size === 'small'}
                        onClick={() => updateNodeWithId(editor, id, { size: 'small' })}
                      >
                        Aa
                      </SmallTextButton>
                    </Tooltip>
                    <Tooltip title={t('author.block-editor.quote-big')}>
                      <LargeTextButton
                        variant='transparent'
                        selected={size === 'big'}
                        onClick={() => updateNodeWithId(editor, id, { size: 'big' })}
                      >
                        Aa
                      </LargeTextButton>
                    </Tooltip>
                  </View>
                </LightTokenProvider>

                <ToolbarSeparator />

                <BlockQuoteColorPicker
                  onMouseOver={(themeName: string) => {
                    const theme = CourseTheme.parse({ type: 'preset', name: themeName })
                    setPreviewThemeName(theme.name)
                  }}
                  onMouseOut={() => {
                    if (previewThemeName !== undefined) setPreviewThemeName(undefined)
                  }}
                  onClick={(themeName: string): void => {
                    const theme = CourseTheme.parse({ type: 'preset', name: themeName })
                    updateNodeWithId(editor, id, { theme })
                  }}
                  selectedTheme={currentThemeName}
                />

                <ToolbarSeparator />

                <ToolbarIcon
                  tooltip={t('author.block-editor.remove')}
                  iconId='trash-can'
                  onClick={() => removeNodeWithId(editor, id)}
                />
              </Toolbar>
            )}
          </QuoteContainer>
        </RenderingContext>
      </ThemeProvider>

      <BlockCommentIndicator element={element} radius='xsmall' />
    </>
  )
}
