import { UseMutationResult } from '@tanstack/react-query'
import { typedInvalidateQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  ContentDistributionSettings,
  ContentDistributionVisibility,
  CourseVisibilityInOrg,
  SetDistributionSettingsRequest,
} from 'sierra-domain/api/author-v2'
import { RequestError } from 'sierra-domain/error'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import {
  XRealtimeGetCourseDistributionPublishStates,
  XRealtimeSetCourseDistributionSettings,
} from 'sierra-domain/routes'

type CourseVisibilityWithoutPrivate = Exclude<CourseVisibilityInOrg, 'private'>

export function translateVisibility(
  visibility: CourseVisibilityWithoutPrivate
): ContentDistributionVisibility {
  switch (visibility) {
    case 'visible-admins': {
      return 'visible-for-admins'
    }
    case 'visible-everyone': {
      return 'visible'
    }
    case 'separate-visibility': {
      throw Error("Refuse to translate 'separate-visibility'")
    }
  }
}

export function constructDistributionPatch(
  organization: AccessibleOrganization,
  patch: {
    visibility: CourseVisibilityWithoutPrivate
  }
): ContentDistributionSettings {
  const patchVisibility = translateVisibility(patch.visibility)

  return {
    childNamespaceId: organization.namespaceId,
    visibility: patchVisibility,
  }
}

export const useCourseDistributionSettingsMutation = (): UseMutationResult<
  null,
  RequestError,
  SetDistributionSettingsRequest
> => {
  return useTypedMutation(XRealtimeSetCourseDistributionSettings, {
    onSettled: async (_data, _error, variables) => {
      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, {
        courseId: variables.courseId,
      })
    },
  })
}
