import React, { useEffect, useRef, useState } from 'react'
import { getFileContent } from 'sierra-client/api/content'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { Link } from 'sierra-client/components/common/link'
import { EMBED_HELP_PAGE_URL } from 'sierra-client/config/links'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  EmbedHelpLinks,
  EmbedIcons,
  HexCodeBackgrounds,
  getEmbeddableUrl,
  getIsPlainEmbed,
  getSpecificStyling,
  helperText,
  inputPlaceholder,
  isUrlMatchingUrlType,
} from 'sierra-client/views/flexible-content/embed-card-utils'
import { useSafeFileContext } from 'sierra-client/views/flexible-content/file-context'
import { getEmbedElement } from 'sierra-client/views/v3-author/embed/utils/handlers'
import { EmbedData, UrlType } from 'sierra-domain/flexible-content/types'
import { assertNever, getUrlFromText } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, Input, InputGroup } from 'sierra-ui/components'
import { Button, Heading, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { SanaColor } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

export type EmbedCardProps = {
  data: EmbedData
  onUploadDone?: (url: string, embedType: 'plain' | 'legacy') => void
  readOnly: boolean
}

const BorderRadius = css<{ $isInline: boolean }>`
  ${p =>
    p.$isInline &&
    css`
      border-radius: ${p => p.theme.borderRadius['size-10']};
    `}
`

type EmbedWrapperProps = { backgroundColor: SanaColor | HexCodeBackgrounds; $isInline: boolean }
const EmbedWrapper = styled(View).attrs({
  justifyContent: 'center',
  direction: 'column',
  alignItems: 'center',
})<EmbedWrapperProps>`
  background-color: ${p => color(p.backgroundColor)};
  overflow: hidden;
  position: relative;
  height: 100%;
  ${BorderRadius}
`

const RichEmbedWrapper = styled.div`
  max-height: 97vh;
  width: 100%;
  overflow: auto;
`

const PlainEmbedIframe = styled.iframe`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
  ${BorderRadius}
`

const EmbedLogo = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: center center;
  align-self: center;
`
const CreateEmbedHeader: React.FC<{ urlType?: UrlType }> = ({ urlType }) => {
  const { t } = useTranslation()

  if (urlType !== undefined) {
    return <EmbedLogo src={EmbedIcons[urlType]} />
  }

  return (
    <View gap='large' direction='column' alignItems='center'>
      <VideoPlayer
        options={{
          height: 112,
          autoplay: true,
          loop: true,
          muted: true,
          preload: 'auto',
          responsive: false,
          fluid: false,
          sources: [
            {
              src: getFileContent('videos/embed-loop.mp4', { bucket: 'sierra-static' }),
              type: 'video/mp4',
            },
          ],
        }}
      />

      <Heading size='h5' color='grey70' bold>
        {t('author.embed-card.heading')}
      </Heading>
    </View>
  )
}

const StyledInput = styled(Input)`
  background-color: ${palette.primitives.white};
  border-radius: 8px;
`

const HelpLink = styled(Link)<{ textColor: SanaColor; opacity: number }>`
  color: ${p => p.textColor};

  &:hover {
    color: ${p => p.textColor};
  }
`

const HelpText = styled(Text)<{ textColor: ColorName; opacity: number }>`
  color: ${p => p.textColor};
  opacity: ${p => p.opacity};

  ${HelpLink} {
    &:hover {
      opacity: 0.9;
    }
  }
`
const HelpTextWrapper = styled.div`
  max-width: 350px;
  text-align: center;
  padding: 0 ${spacing[16]};
`
const CreateEmbedFooter: React.FC<{ error: string; urlType?: UrlType }> = ({ error, urlType }) => {
  const { t } = useTranslation()
  const styling = getSpecificStyling(urlType)

  return (
    <HelpTextWrapper>
      <HelpText
        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
        size='small'
        textColor={styling.textStyling.color}
        opacity={styling.textStyling.opacity}
      >
        {urlType === undefined ? t('author.embed-card.helper-text') : t(helperText[urlType])}{' '}
        {t('author.embed-card.learn-more')}{' '}
        <HelpLink
          textColor={styling.textStyling.color}
          opacity={styling.textStyling.opacity}
          size='small'
          onClick={() => {
            if (urlType === undefined) {
              window.open(EMBED_HELP_PAGE_URL, '_blank')
            } else {
              window.open(EmbedHelpLinks[urlType], '_blank')
            }
          }}
        >
          {t('author.embed-card.tutorial')}
        </HelpLink>
      </HelpText>
      <HelpText
        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
        size='small'
        textColor={styling.textStyling.color}
        opacity={styling.textStyling.opacity}
      >
        {error}
      </HelpText>
    </HelpTextWrapper>
  )
}

const InputWrapper = styled(View)`
  flex-wrap: wrap;

  ${InputGroup} {
    flex-grow: 1;
  }
`
type EmbedInputProps = {
  data: EmbedData
  handleUpdate: (input: string) => Promise<void>
  setError: React.Dispatch<React.SetStateAction<string>>
  localUrl: string
  setLocalUrl: React.Dispatch<React.SetStateAction<string>>
}

const EmbedInput: React.FC<EmbedInputProps> = ({ data, handleUpdate, setError, localUrl, setLocalUrl }) => {
  const { t } = useTranslation()
  const styling = getSpecificStyling(data.urlType)

  return (
    <InputWrapper justifyContent='space-between' alignItems='center' marginTop={'16'}>
      <StyledInput
        gap='none'
        spacing='none'
        id='embedlink'
        type='text'
        value={localUrl}
        onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}
        placeholder={
          data.urlType === undefined
            ? t('author.embed-card.input-placeholder')
            : t(inputPlaceholder[data.urlType])
        }
        onKeyDown={event => {
          if (event.key === 'Enter') {
            void handleUpdate(localUrl)
            event.preventDefault()
            return
          }
        }}
        onChange={event => {
          setError('')
          setLocalUrl(event.currentTarget.value)
        }}
      />
      <Button
        variant={styling.buttonVariant}
        disabled={localUrl === ''}
        onClick={() => {
          if (localUrl !== '') void handleUpdate(localUrl)
        }}
      >
        {t('author.embed-card.embed-button')}
      </Button>
    </InputWrapper>
  )
}

export const EmbedCard: React.FC<EmbedCardProps> = ({ data, onUploadDone, readOnly }) => {
  const [element, setElement] = useState<undefined | JSX.Element>(undefined)
  const [localUrl, setLocalUrl] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const isUrlDefined = data.url !== undefined && data.url !== ''
  const isRichEmbed = data.embedType === 'legacy'
  const noEmbedLearnerView = !isUrlDefined && readOnly
  const noEmbedCreatorView = !isUrlDefined && !readOnly
  const styling = getSpecificStyling(data.urlType)

  const isInline = useSafeFileContext()?.file.data.type === 'general'

  const handleUpdate = async (input: string): Promise<void> => {
    if (input === '') {
      setError(t('author.embed-card.write-link'))
      return
    }
    try {
      const urlFromText = getUrlFromText(input)
      const url = urlFromText !== undefined ? getEmbeddableUrl(urlFromText, data.urlType) : ''
      const embedType = (await getIsPlainEmbed(url, data.urlType)) ? 'plain' : 'legacy'

      if (url === '') {
        setError(t('author.embed-card.error'))
        return
      }
      if (isUrlMatchingUrlType(url, data.urlType)) {
        onUploadDone?.(url, embedType)
        setLocalUrl('')
        setError('')
      } else {
        setError(t('author.embed-card.error'))
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
    }
  }

  useEffect(() => {
    void (async () => {
      if (data.url !== undefined && user !== undefined) {
        setElement(await getEmbedElement(data.url, user))
      }
    })()
  }, [data, user])

  const onLoad = (): void => {
    setError('')
    if (!isRichEmbed && iframeRef.current !== null) {
      const success = data.url === iframeRef.current.src
      if (!success) {
        setError(t('author.embed-card.error'))
      }
    }
  }

  const embedContents = (() => {
    const resultType = noEmbedLearnerView
      ? 'no-embed-learner-view'
      : noEmbedCreatorView
        ? 'no-embed-creator-view'
        : !isRichEmbed
          ? 'plain-embed'
          : 'rich-embed'

    switch (resultType) {
      case 'no-embed-learner-view':
        return (
          <>
            <Icon iconId='code' color='grey35' />
            <Text size='regular' color='grey35'>
              {t('learner.embed-card.no-embed')}
            </Text>
          </>
        )
      case 'no-embed-creator-view':
        return (
          <View gap='24' direction='column'>
            <CreateEmbedHeader urlType={data.urlType} />
            <EmbedInput
              data={data}
              handleUpdate={handleUpdate}
              setError={setError}
              localUrl={localUrl}
              setLocalUrl={setLocalUrl}
            />
            <CreateEmbedFooter error={error} urlType={data.urlType} />
          </View>
        )
      case 'plain-embed':
        return (
          <PlainEmbedIframe
            ref={iframeRef}
            src={data.url}
            allow='microphone'
            onLoad={onLoad}
            $isInline={isInline}
          />
        )
      case 'rich-embed':
        return <RichEmbedWrapper>{element}</RichEmbedWrapper>
      default:
        assertNever(resultType)
    }
  })()

  return (
    <EmbedWrapper backgroundColor={styling.backgroundColor} $isInline={isInline}>
      {embedContents}
    </EmbedWrapper>
  )
}
