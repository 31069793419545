import { useSetAtom } from 'jotai'
import Lottie from 'lottie-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ptStart from 'sierra-client/assets/animations/pt-start.json'
import ptSuccess from 'sierra-client/assets/animations/pt-success.json'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { closePlacementTest, fetchCourseProgress } from 'sierra-client/state/card-progress/actions'
import {
  selectCardStatuses,
  selectIsCourseCompleted,
  selectPlacementTestProgressState,
} from 'sierra-client/state/card-progress/selectors'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { selectFlexibleContentFolder } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  ShuffleElementsProvider,
  useQuestionShuffle,
} from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { AdaptiveModal } from 'sierra-client/views/self-paced/adaptive-features/adaptive-border'
import { AdaptiveState } from 'sierra-client/views/self-paced/adaptive-features/adaptive-context'
import { DuringAdaptiveModal } from 'sierra-client/views/self-paced/adaptive-features/modal'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { isPlacementTestModalOpen } from 'sierra-client/views/self-paced/placement-test/atoms'
import {
  PlacementTestContext,
  PlacementTestContextProvider,
  PlacementTests,
} from 'sierra-client/views/self-paced/placement-test/placement-test-context'
import { StaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { tutorStateUnavailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentAdaptivePlacementTest } from 'sierra-domain/api/strategy-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeStrategySelfPacedContentAdaptiveElements } from 'sierra-domain/routes'
import { guardWith, isDefined } from 'sierra-domain/utils'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { Icon, aiGradient } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const initialisePlacementTestState = (
  placementTest: FlexibleContentAdaptivePlacementTest,
  contentId: CreateContentId
): AdaptiveState => ({
  contentId,
  index: 0,
  status: 'start',
  elements: placementTest.elements.map(({ cardId, exerciseId, slateNodes }) => ({
    fileId: cardId,
    exerciseId: exerciseId,
    element: slateNodes,
  })),
})

const startFinishedProps = {
  size: {
    width: 517,
    height: 640,
  },
  background: 'white' as const,
  radius: 'size-28' as const,
}

const duringProps = {
  size: 'full-screen' as const,
  background: 'white' as const,
  margin: '16' as const,
}

const StartButtonIcon = styled(Icon)`
  opacity: 0.6;
`

const AIStyledButton = styled(Button)`
  ${aiGradient};
`

const AnimationContainer = styled.div`
  width: 64px;
  height: 64px;

  & svg *:not([fill~='transparent']) {
    fill: ${token('foreground/primary')};
  }
`

const StartModal: React.FC<{
  onStart: () => void
  onClose: () => void
  numberOfConcepts: number
  totalTimeOfModule: number
}> = ({ onStart, onClose, numberOfConcepts, totalTimeOfModule }) => {
  const { t } = useTranslation()

  return (
    <View direction='column' justifyContent='space-between' grow>
      <View
        grow
        direction='column'
        justifyContent='center'
        alignItems='center'
        gap='16'
        paddingLeft='64'
        paddingRight='64'
      >
        <AnimationContainer>
          <Lottie animationData={ptStart} />
        </AnimationContainer>
        <Heading align='center' size='h3' bold avoidHanging={false}>
          {t('helper.placement-test.heading')}
        </Heading>
        <Text align='center' color='foreground/secondary' size='regular'>
          {t('helper.placement-test.body-text')}
        </Text>
        <View gap='none'>
          <Text size='small' color='foreground/muted'>
            {t('content.placement-test.concepts', { count: numberOfConcepts })}
          </Text>
          <Icon color='foreground/muted' iconId='radio-button--dot' size='size-12' />
          <Text size='small' color='foreground/muted' bold>
            {t('content.placement-test.save-time', { number: totalTimeOfModule })}
          </Text>
        </View>
      </View>
      <View paddingBottom='64' gap='8' justifyContent='center'>
        <Button variant='secondary' onClick={onClose}>
          {t('dictionary.skip')}
        </Button>
        <AIStyledButton
          base='#5B59EA'
          onClick={onStart}
          decoratorPosition='right'
          customDecorator={<StartButtonIcon iconId='next--filled' color='button/foreground' />}
        >
          {t('helper.placement-test.primary-action-text')}
        </AIStyledButton>
      </View>
    </View>
  )
}

const useGetEndModalHeader = ({
  isCourseCompleted,
  correctAnswered,
  moduleTotalCard,
}: {
  isCourseCompleted: boolean
  correctAnswered: number
  moduleTotalCard: number
}): string => {
  const { t } = useTranslation()
  return isCourseCompleted
    ? t('content.prs.end-course.title')
    : correctAnswered === moduleTotalCard
      ? t('content.placement-test.header.skip-module')
      : correctAnswered === 0
        ? t('content.placement-test.header.learning-continues')
        : t('content.placement-test.header.skip-cards', { number: correctAnswered })
}

const useGetEndModalBodyText = ({
  isCourseCompleted,
  correctAnswered,
  moduleTotalCard,
}: {
  isCourseCompleted: boolean
  correctAnswered: number
  moduleTotalCard: number
}): string => {
  const { t } = useTranslation()
  return isCourseCompleted
    ? t('content.placement-test.body-text.course-completed')
    : correctAnswered === moduleTotalCard
      ? t('content.placement-test.body-text.test-completed')
      : correctAnswered === 0
        ? t('content.placement-test.body-text.learning-continues')
        : t('content.placement-test.finished')
}

const EndModal: React.FC<{
  savedTime: number
  isCourseCompleted: boolean
  correctAnswered: number
  moduleTotalCard: number
  skipPlacementTest: () => Promise<void>
  closeModalAndPlacementTest: () => Promise<void>
  goToNextUp: () => void
}> = ({
  savedTime,
  isCourseCompleted,
  correctAnswered,
  moduleTotalCard,
  skipPlacementTest,
  closeModalAndPlacementTest,
  goToNextUp,
}) => {
  const { t } = useTranslation()

  const heading = useGetEndModalHeader({ isCourseCompleted, correctAnswered, moduleTotalCard })
  const bodyText = useGetEndModalBodyText({ isCourseCompleted, correctAnswered, moduleTotalCard })
  const primaryActionText = isCourseCompleted
    ? t('content.adaptive.back-to-home')
    : t('content.adaptive.continue')
  const primaryActionIcon = isCourseCompleted ? 'home' : 'next--filled'

  return (
    <View direction='column' justifyContent='space-between' grow>
      <View
        grow
        direction='column'
        justifyContent='center'
        alignItems='center'
        gap='16'
        paddingLeft='64'
        paddingRight='64'
      >
        <AnimationContainer>
          <Lottie animationData={ptSuccess} />
        </AnimationContainer>
        <Heading align='center' size='h3' bold avoidHanging={false}>
          {heading}
        </Heading>
        <Text align='center' color='foreground/secondary' size='regular'>
          {bodyText}
        </Text>
        <View gap='none'>
          <Text size='small' color='foreground/muted'>
            {t('content.placement-test.completed', { completed: correctAnswered, total: moduleTotalCard })}
          </Text>
          {correctAnswered !== 0 && (
            <>
              <Icon color='foreground/muted' iconId='radio-button--dot' size='size-12' />
              <Text size='small' color='foreground/muted' bold>
                {t('content.placement-test.time-saved', { count: savedTime })}
              </Text>
            </>
          )}
        </View>
      </View>
      <View paddingBottom='64' gap='8' justifyContent='center'>
        {correctAnswered !== 0 && (
          <Button variant='secondary' onClick={skipPlacementTest}>
            {t('content.placement-test.close-and-view')}
          </Button>
        )}
        <Button
          onClick={async () => {
            await closeModalAndPlacementTest()

            if (isCourseCompleted) {
              await getGlobalRouter().navigate({ to: '/' })
            } else if (correctAnswered === 0) {
              await skipPlacementTest()
              return
            } else {
              goToNextUp()
            }
          }}
          decoratorPosition='right'
          customDecorator={<StartButtonIcon iconId={primaryActionIcon} color='button/foreground' />}
        >
          {primaryActionText}
        </Button>
      </View>
    </View>
  )
}

const PlacementTestModal: React.FC<{
  courseId: CreateContentId
  folderId: FolderId | undefined
  closeModal: () => void
}> = ({ courseId, folderId, closeModal }) => {
  const [state, setState] = useState<AdaptiveState>({ status: 'loading', contentId: courseId, index: 0 })
  const placementTestContextValue: PlacementTestContext = useMemo(
    () => ({ state, setState }),
    [state, setState]
  )
  const { postWithUserErrorException } = usePost()
  const { smartNextUp, goToNextUp, flexibleContentId, currentFile, goTo } = useSelfPacedFiles()
  const currentFileId = currentFile?.id

  /**
   * When the new course helper is enabled, we don't navigate in to the placement test folder directly.
   * So the currentFolder is still the rootfolder of the course. Therefore we need to get the placement test folder
   * in order to correctly calculate the saved time.
   */
  const placementTestFolder = useSelector(state =>
    selectFlexibleContentFolder(state, flexibleContentId, folderId)
  )

  const isCourseCompleted = useSelector(state => selectIsCourseCompleted(state, flexibleContentId))
  const { t } = useTranslation()
  const { shuffleQuestionsInDocument } = useQuestionShuffle()
  const dispatch = useDispatch()
  const open = folderId !== undefined
  const setPlacementTestModalOpen = useSetAtom(isPlacementTestModalOpen)

  useEffect(() => {
    setPlacementTestModalOpen(open)
  }, [open, setPlacementTestModalOpen])

  const modalProps = state.status === 'during' ? duringProps : startFinishedProps

  const closeModalAndPlacementTest = useCallback(async () => {
    if (folderId === undefined) {
      return
    }

    await dispatch(closePlacementTest({ courseId, moduleId: folderId, fileId: currentFileId }))

    closeModal()
  }, [dispatch, courseId, folderId, closeModal, currentFileId])

  /**
   * When the new course helper is enabled, we don't navigate in to the placement test folder directly.
   * So in some cases we need to navigate to the first file in the folder after the placement test is closed.
   */
  const skipPlacementTest = useCallback(async () => {
    if (!isDefined(placementTestFolder)) {
      return
    }
    await closeModalAndPlacementTest()
    const firstFileId = placementTestFolder.nodeIds.find(guardWith(FileId))
    if (isDefined(firstFileId)) {
      goTo(firstFileId)
    }
  }, [placementTestFolder, closeModalAndPlacementTest, goTo])

  useEffect(() => {
    if (folderId === undefined) return
    void postWithUserErrorException(XRealtimeStrategySelfPacedContentAdaptiveElements, {
      courseId,
    }).then(({ adaptiveElements }) => {
      const placementTest = adaptiveElements.placementTests[folderId]

      if (placementTest === undefined) {
        throw new Error('PlacementTestModal was flagged as open but did not return any elements.')
      }

      setState(initialisePlacementTestState(placementTest, courseId))
    })
  }, [courseId, folderId, postWithUserErrorException])

  const element = PlacementTests.currentElement(state)
  const document: SlateRootElement[] = element !== undefined ? element.element : []
  const shuffledDocument = shuffleQuestionsInDocument(document)

  const hideContinue = element?.evaluation === undefined

  useEffect(() => {
    if (state.status === 'successful') {
      void dispatch(
        fetchCourseProgress({
          courseId: flexibleContentId,
          fileId: currentFileId,
        })
      )
    }
  }, [dispatch, state.status, flexibleContentId, state, currentFileId])

  const course = useSelector(state => selectCourseById(state, flexibleContentId))

  const getTotalTimeOfModule = (): number => {
    if (course === undefined || course.readingTimes === undefined || folderId === undefined) return 2

    const id = folderId.split(':')[1] as string
    const time = course.readingTimes.lessonsModules[id]

    if (time === undefined) return 2

    return Math.round(time / 60)
  }

  // calculate approx. saved time
  // based on the total time of the module times (correctAnswered/totalAmountOfCards)
  const getSavedTime = (fraction: number): number => {
    const moduleTotalTime = getTotalTimeOfModule()
    if (fraction <= 0) return 0
    if (fraction > 1) return moduleTotalTime

    const timeSaved = Math.round(moduleTotalTime * fraction)

    if (timeSaved < 2) return 2

    return timeSaved
  }

  const progress = useSelector(state => selectCardStatuses(state, flexibleContentId))

  const getCorrectAnswered = (): number => {
    if (placementTestFolder === undefined) return 0

    let correctAnswered = 0
    placementTestFolder.nodeIds.filter(guardWith(FileId)).forEach(fileId => {
      if (progress !== undefined && progress[fileId]?.completed !== undefined) {
        correctAnswered += 1
      }
    })
    return correctAnswered
  }

  const correctAnswered = getCorrectAnswered()
  const moduleTotalCard = placementTestFolder !== undefined ? placementTestFolder.nodeIds.length : 0
  const moduleTitle = placementTestFolder !== undefined ? placementTestFolder.title : ''

  const assetContext: AssetContext = useMemo(
    () => ({
      type: 'course' as const,
      courseId,
    }),
    [courseId]
  )

  return (
    <PlacementTestContextProvider value={placementTestContextValue}>
      <AdaptiveModal
        open={open}
        onClose={closeModalAndPlacementTest}
        hideBorder={state.status !== 'start'}
        {...modalProps}
      >
        {state.status === 'loading' && (
          <View grow justifyContent='center' alignItems='center'>
            <LoadingSpinner />
          </View>
        )}
        {state.status === 'start' && (
          <StartModal
            numberOfConcepts={state.elements.length}
            totalTimeOfModule={getTotalTimeOfModule()}
            onStart={() => setState(PlacementTests.start)}
            onClose={skipPlacementTest}
          />
        )}
        {state.status === 'during' && (
          <DuringAdaptiveModal
            onContinue={() => setState(PlacementTests.goToNextElement)}
            hideContinue={hideContinue}
            closeModal={closeModalAndPlacementTest}
            total={state.elements.length}
            index={state.index}
            moduleTitle={moduleTitle}
            iconId='fast-forward'
            topbar={t('content.placement-test.placement-test')}
            tooltip={t('content.placement-test.tooltip')}
          >
            <StaticEditor
              tutorState={tutorStateUnavailable}
              mode='placement-test'
              document={shuffledDocument}
              assetContext={assetContext}
              file={undefined}
            />
          </DuringAdaptiveModal>
        )}
        {state.status === 'successful' && (
          <EndModal
            isCourseCompleted={isCourseCompleted}
            goToNextUp={() => {
              if (!isCourseCompleted && smartNextUp.type !== 'none') {
                goToNextUp(smartNextUp)
              }
            }}
            closeModalAndPlacementTest={closeModalAndPlacementTest}
            skipPlacementTest={skipPlacementTest}
            moduleTotalCard={moduleTotalCard}
            correctAnswered={correctAnswered}
            savedTime={getSavedTime(correctAnswered / moduleTotalCard)}
          />
        )}
      </AdaptiveModal>
    </PlacementTestContextProvider>
  )
}

export const PlacementTest: React.FC = () => {
  const {
    flexibleContentId,
    currentFolder,
    currentPlacementTest,
    startPlacementTest,
    setCurrentPlacementTest,
  } = useSelfPacedFiles()

  // Automatically start the placement test if it's available in the current module;
  // * Keep in place if we change the file id
  // * Works with SetCardProgressProvider
  const isPlacementTestForModuleOpen =
    useSelector(state => selectPlacementTestProgressState(state, flexibleContentId))(currentFolder?.id)
      ?.state === 'open'

  const initiatePlacementTest = useCallback(() => {
    if (isPlacementTestForModuleOpen) {
      setCurrentPlacementTest(currentFolder?.id)
    }
  }, [currentFolder, setCurrentPlacementTest, isPlacementTestForModuleOpen])

  useEffect(initiatePlacementTest, [initiatePlacementTest])

  return (
    <ShuffleElementsProvider>
      <PlacementTestModal
        key={currentPlacementTest}
        courseId={flexibleContentId}
        folderId={currentPlacementTest}
        closeModal={() => startPlacementTest(undefined)}
      />
    </ShuffleElementsProvider>
  )
}
