import { DateTime } from 'luxon'
import {
  EmailTemplateBindingsForProgramInput,
  FetchEmailTemplateBindingsQuery,
  FetchProgramOutlineQuery,
  ContentKind as GraphQLContentKind,
  InputProgramSectionInput,
  RenderableProgramEmailInput,
  StepContentFragmentFragment,
  UpdateProgramStepsMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { LiveContentAssignmentType } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  EmailPlaceholderKeys,
  EmailPlaceholderValues,
  EmailTemplate,
  StaggeredAssignmentsMode,
} from 'sierra-client/views/manage/programs/staggered-assignments/types'
import { CourseKind } from 'sierra-domain/api/common'
import {
  ContentProgramStep,
  ContentStepBase,
  CourseStep,
  DueDateAbsolute,
  DueDateGroup,
  DueDateRelativeDays,
  EmailStep,
  EventProgramStep,
  PathStep,
  ProgramOutline,
  ProgramStep,
  Schedule,
  ScheduleOffset,
  StepBase,
  TimeScheduleAbsolute,
  TimeScheduleNow,
  TimeScheduleOnPreviousCompletion,
  TimeScheduleRelative,
} from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { UUID } from 'sierra-domain/api/uuid'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Color, ColorBuilder, color } from 'sierra-ui/color'

export const isContentProgramStep = (step: ProgramStep): step is ContentProgramStep => {
  switch (step.type) {
    case 'course':
    case 'path':
      return true
    case 'email':
      return false
    default:
      assertNever(step)
  }
}

export const isEventProgramStep = (step: ProgramStep): step is EventProgramStep => !isContentProgramStep(step)

export const getStepId = (step: ProgramStep): string => {
  switch (step.type) {
    case 'path':
      return step.pathId
    case 'course':
      return step.courseId
    case 'email':
      return step.resourceId
  }
}

export const isStepLiveCourseOrPath = (step: ProgramStep): boolean => {
  switch (step.type) {
    case 'course':
      switch (step.courseKind) {
        case 'native:live':
          return true
        case 'native:event-group':
          return true
        default:
          return false
      }
    case 'email':
      return false
    case 'path':
      return true
  }
}

export const getStepTypeTranslation = (step: ProgramStep, t: TranslationLookup): string => {
  switch (step.type) {
    case 'course':
      switch (step.courseKind) {
        case 'native:event-group':
          return t('dictionary.in-person')
        case 'native:live':
          return t('dictionary.live')
        // return t('dictionary.course-edition-singular')
        case 'native:course-group':
        default:
          return t('dictionary.course-singular')
      }
    case 'email':
      return t('dictionary.email')
    case 'path':
      return t('dictionary.path-singular')
  }
}

export const getStepTypeGraphQlTranslation = (
  step: StepContentFragmentFragment,
  t: TranslationLookup
): string => {
  switch (step.contentType) {
    case 'COURSE':
      if (!('courseKind' in step)) {
        return t('dictionary.course-singular')
      }

      switch (step.courseKind) {
        case 'NATIVE_LIVE':
          return t('dictionary.live')
        case 'NATIVE_EVENT_GROUP':
          return t('dictionary.in-person')
        case 'NATIVE_COURSE_GROUP':
        default:
          return t('dictionary.course-singular')
      }
    case 'PATH':
      return t('dictionary.path-singular')
    case 'PROGRAM':
      return t('dictionary.program-singular')
    default:
      assertNever(step.contentType)
  }
}

export const getStepIdGraphQl = (step: StepContentFragmentFragment): string => {
  if (step.__typename === 'Program') throw new Error('Programs are not supported as steps')

  if (step.__typename === 'Path') {
    return `path:${step.pathId}`
  } else {
    return `course:${step.courseId}`
  }
}

export const getLiveContentAssignmentTranslation = (
  assignmentState: LiveContentAssignmentType,
  t: TranslationLookup
): string => {
  switch (assignmentState) {
    case 'auto-assign':
      return t('manage.program.program-details.steps.live.auto-assign-title')
    case 'self-enroll':
      return t('manage.program.program-details.steps.live.self-enroll-title')
    case 'manual':
      return t('manage.program.program-details.steps.live.manual-title')
  }
}

export const getLiveContentAssignmentStateFromStep = (
  step: ContentProgramStep
): LiveContentAssignmentType => {
  if (step.autoAssignLiveSession) {
    return 'auto-assign'
  }

  if (step.enableSelfEnrollment) {
    return 'self-enroll'
  } else {
    return 'manual'
  }
}

function renderScheduleLabel(schedule: Schedule, isFirstStep: boolean, t: TranslationLookup): string {
  switch (schedule.type) {
    case 'on-previous-steps-completion':
      return t('manage.program.program-details.steps.schedule-item-directly-description-other-step')
    case 'now':
      return isFirstStep
        ? t('manage.program.program-details.steps.schedule-item-directly')
        : t('manage.program.program-details.steps.schedule-item-with-previous')
    case 'absolute':
      return '' /* TODO: Add support for absolute dates */
    case 'relative':
      return t('manage.program.program-details.steps.schedule-item-delayed')
  }
}

function renderScheduleDescription(
  previousStep: ProgramStep | undefined,
  schedule: Schedule,
  isFirstStep: boolean,
  t: TranslationLookup
): string {
  switch (schedule.type) {
    case 'now':
      return isFirstStep
        ? t('manage.program.program-details.steps.schedule-item-directly-description-first-step')
        : t('manage.program.program-details.steps.schedule-item-directly-description-other-step')
    case 'absolute':
      return ``

    case 'relative':
      return isFirstStep
        ? t('manage.program.program-details.steps.schedule-item-delayed-program-start', {
            count: schedule.offset.value,
          })
        : t('manage.program.program-details.steps.schedule-item-delayed-days', {
            count: schedule.offset.value,
            course: previousStep?.title ?? '',
          })
  }

  return 'N/A'
}

/**
 * The new behaviour is kept separate from the previous
 */
type ScheduleRenderArgs = {
  outline: ProgramOutline
  index: number
  t: TranslationLookup
}

export function renderScheduleDescriptionWithPreviousCompletion({
  outline,
  index,
  t,
}: ScheduleRenderArgs): string {
  const schedule = outline.steps[index]?.schedule

  const previousStep = outline.steps[index - 1]
  const previousStepIsEmail = previousStep?.type === 'email'

  const previousIndex = outline.steps.reduce((num, _, j) => {
    const previous = outline.steps[j - 1]
    if (previous?.type !== 'email' && j <= index) {
      return num + 1
    } else {
      return num
    }
  }, -1)

  // assert(isDefined(schedule), 'Schedule is not defined on step')
  if (!isDefined(schedule)) {
    return ''
  }

  switch (schedule.type) {
    case 'now':
    case 'relative': {
      const days = schedule.type === 'relative' ? schedule.offset.value : undefined
      const hasDays = days !== undefined && days > 0

      if (index === 0) {
        return hasDays
          ? t('manage.programs.scheduling.on-program-start-with-delay', { count: days })
          : t('manage.programs.scheduling.on-program-start')
      } else {
        return hasDays
          ? t(
              previousStepIsEmail
                ? 'manage.programs.scheduling.with-step-email-delayed'
                : 'manage.programs.scheduling.with-step-delayed',
              {
                step: previousIndex,
                count: days,
              }
            )
          : t(
              previousStepIsEmail
                ? 'manage.programs.scheduling.with-step-email'
                : 'manage.programs.scheduling.with-step',
              { step: previousIndex }
            )
      }
    }

    case 'on-previous-steps-completion': {
      const days = schedule.offset?.value
      const hasDays = days !== undefined && days > 0

      return hasDays
        ? t(
            previousStepIsEmail
              ? 'manage.programs.scheduling.after-completion-email-delayed'
              : 'manage.programs.scheduling.after-completion-delayed',
            {
              step: previousIndex,
              count: days,
            }
          )
        : t(
            previousStepIsEmail
              ? 'manage.programs.scheduling.after-completion-email'
              : 'manage.programs.scheduling.after-completion',
            { step: previousIndex }
          )
    }

    case 'absolute': {
      return schedule.date
    }

    default:
      assertNever(schedule)
  }
}

export const renderSchedule = (
  step: ProgramStep,
  previousStep: ProgramStep | undefined,
  t: TranslationLookup
): { label: string; description: string } => {
  const { schedule } = step
  const isFirstStep = step.index === 0

  const label = renderScheduleLabel(schedule, isFirstStep, t)
  const description = renderScheduleDescription(previousStep, schedule, isFirstStep, t)

  return {
    label,
    description,
  }
}

function renderDueDateLabel(dueDate: DueDateGroup): string {
  switch (dueDate.type) {
    case 'absolute':
      return ''
    case 'relative':
      return ''
  }
}

function renderDueDateDescription(dueDate: DueDateGroup, t: TranslationLookup): string {
  switch (dueDate.type) {
    case 'absolute':
      return dueDate.date
    case 'relative':
      return `${dueDate.days} ${t('manage.program.program-details.steps.n-days-after-assignment', {
        count: dueDate.days,
      })}`
  }
}

export const renderDueDate = (
  dueDate: DueDateGroup | undefined,
  t: TranslationLookup
): { label: string; description: string } => {
  if (dueDate === undefined) {
    return {
      label: t('manage.program.program-details.steps.table-cell-no-due-date'),
      description: t('manage.program.program-details.steps.table-cell-no-due-date'),
    }
  }

  const label = renderDueDateLabel(dueDate)
  const description = renderDueDateDescription(dueDate, t)

  return {
    label,
    description,
  }
}

export const getCurrentDateString = (): string => {
  const date = new Date()
  const day = `${date.getDate()}`.padStart(2, '0')
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export const createDueDateAbsolute = (date?: string): DueDateAbsolute => {
  return {
    type: 'absolute',
    date: date ?? getCurrentDateString(),
  }
}

export const RELATIVE_DUE_DATE_DEFAULT = 7

export const SCHEDULE_OFFSET_DEFAULT = 1

export const createDueDateRelative = (days: number = RELATIVE_DUE_DATE_DEFAULT): DueDateRelativeDays => ({
  type: 'relative',
  days,
})

export const createScheduleOffset = (value: number = SCHEDULE_OFFSET_DEFAULT): ScheduleOffset => ({
  type: 'days',
  value,
})

export const createTimeScheduleNow = (): TimeScheduleNow => ({
  type: 'now',
})

export const createTimeScheduleAbsolute = (
  date: string = DateTime.now().toString()
): TimeScheduleAbsolute => ({
  type: 'absolute',
  date,
})

export const createTimeScheduleOnPreviousComplete = (
  offset?: ScheduleOffset
): TimeScheduleOnPreviousCompletion => ({
  type: 'on-previous-steps-completion',
  offset,
})

export const createTimeScheduleRelative = (
  offset: ScheduleOffset = createScheduleOffset()
): TimeScheduleRelative => ({
  type: 'relative',
  offset,
})

const createContentStep = ({
  enableSelfEnrollment = false,
  autoAssignLiveSession = true,
  dueDate = undefined,
  schedule = createTimeScheduleNow(),
  index = 0,
  image,
  title = '',
}: Partial<ContentStepBase>): ContentStepBase => ({
  enableSelfEnrollment,
  autoAssignLiveSession,
  dueDate,
  schedule,
  index,
  image,
  title,
})

const createStep = ({
  schedule = createTimeScheduleNow(),
  index = 0,
  image,
  title = '',
}: Partial<StepBase>): StepBase => ({
  schedule,
  index,
  image,
  title,
})

export const createCourseStep = ({
  courseId,
  courseKind,
  ...stepProps
}: Pick<CourseStep, 'courseId' | 'courseKind'> & Partial<StepBase>): CourseStep => ({
  type: 'course',
  courseKind,
  courseId,
  ...createContentStep(stepProps),
})

export const createPathStep = ({
  pathId,
  ...stepProps
}: Pick<PathStep, 'pathId'> & Partial<StepBase>): PathStep => ({
  type: 'path',
  pathId,
  ...createContentStep(stepProps),
})

export const createEmailStep = ({
  resourceId,
  emailTemplateBindingsId,
  ...stepProps
}: Partial<EmailStep> &
  Pick<EmailStep, 'resourceId' | 'emailTemplateBindingsId'> &
  Partial<StepBase>): EmailStep => {
  return {
    type: 'email',
    resourceId: resourceId,
    emailTemplateBindingsId: emailTemplateBindingsId,
    description: stepProps.description,
    ...createStep(stepProps),
  }
}

export const graphQLContentKindToCourseKind = (graphQLContentKind: GraphQLContentKind): CourseKind => {
  switch (graphQLContentKind) {
    case 'NATIVE_SELF_PACED':
      return 'native:self-paced'
    case 'NATIVE_COURSE_GROUP':
      return 'native:course-group'
    case 'SCORM_COURSE_GROUP':
      return 'scorm:course-group'
    case 'LINK':
      return 'native:self-paced'
    case 'NATIVE_LIVE':
      return 'native:live'
    case 'LINKEDIN':
      return 'linkedin'
    case 'SCORM':
      return 'scorm'
    case 'NATIVE_EVENT_GROUP':
      return 'native:event-group'
  }
}

export const deserializeOutline = (
  program: FetchProgramOutlineQuery['program'] | UpdateProgramStepsMutation['updateProgramSteps']
): ProgramOutline => {
  const steps = program?.steps ?? []
  const sections = program?.sections ?? []

  const convertedSteps: ProgramStep[] = steps
    .map((s, index) => {
      const schedule = ((): Schedule => {
        if (s.schedule === undefined || s.schedule === null) {
          return { type: 'now' }
        }

        switch (s.schedule.__typename) {
          case 'AbsoluteDate':
            return { type: 'absolute', date: s.schedule.date }
          case 'RelativeDate':
            return { type: 'relative', offset: { type: 'days', value: s.schedule.offset.value } }
          case 'Directly':
            return { type: 'now' }
          case 'OnCompletionOfPrevious':
            return {
              type: 'on-previous-steps-completion',
              offset: { type: 'days', value: s.schedule.offset.value },
            }
          default:
            assertNever(s.schedule)
        }
      })()

      const commonProps = {
        index,
        schedule,
        sectionId: s.sectionId,
        sectionIndex: s.sectionIndex,
      }

      if (s.__typename === 'CourseProgramStep' && s.course) {
        const dueDate: DueDateGroup | undefined = ((): DueDateGroup | undefined => {
          if (s.dueDate === undefined || s.dueDate === null) return undefined

          if (s.dueDate.__typename === 'Relative') {
            return { type: 'relative', days: s.dueDate.days }
          } else {
            // if (s.dueDate.__typename === 'Absolute') {
            return { type: 'absolute', date: s.dueDate.date }
          }
        })()

        const image = convertGQLImage(s.course.image)

        const step: CourseStep = {
          ...commonProps,
          dueDate,
          type: 'course',
          courseKind: graphQLContentKindToCourseKind(s.course.courseKind),
          enableSelfEnrollment: s.enableSelfEnrollment,
          autoAssignLiveSession: s.autoAssignLiveSession,
          title: s.course.title,
          courseId: s.courseId,
          image,
        }

        return step
      } else if (s.__typename === 'PathProgramStep' && s.path) {
        const dueDate: DueDateGroup | undefined = ((): DueDateGroup | undefined => {
          if (s.dueDate === undefined || s.dueDate === null) return undefined

          if (s.dueDate.__typename === 'Relative') {
            return { type: 'relative', days: s.dueDate.days }
          } else {
            // if (s.dueDate.__typename === 'Absolute') {
            return { type: 'absolute', date: s.dueDate.date }
          }
        })()

        // if (s.__typename === 'PathProgramStep') {
        const image = convertGQLImage(s.path.image)

        const step: PathStep = {
          ...commonProps,
          dueDate,
          type: 'path',
          enableSelfEnrollment: s.enableSelfEnrollment,
          autoAssignLiveSession: s.autoAssignLiveSession,
          title: s.path.title,
          pathId: s.pathId,
          image,
        }

        return step
      } else if (s.__typename === 'EmailProgramStep') {
        const image = convertGQLImage(s.image)
        const step: EmailStep = {
          ...commonProps,
          type: 'email',
          title: s.title,
          resourceId: s.resourceId,
          emailTemplateBindingsId: s.emailTemplateBindingsId,
          image,
        }

        return step
      }

      return undefined // path or course might be deleted
    })
    .filter(isDefined)

  return {
    sections,
    steps: convertedSteps,
  }
}

const serializeDueDate = (dueDate: DueDateGroup | undefined): SerializedDueDate => {
  if (dueDate === undefined) {
    return {}
  }

  if (dueDate.type === 'relative') {
    return {
      dueDateRelativeDays: dueDate.days,
    }
  } else {
    return {
      dueDateAbsolute: dueDate.date,
    }
  }
}

const serializeSchedule = (schedule: Schedule | undefined): SerializedSchedule => {
  if (schedule === undefined) {
    return {}
  }

  switch (schedule.type) {
    case 'now':
      return {}
    case 'relative':
      return {
        scheduleRelative: {
          unit: 'DAYS',
          value: schedule.offset.value,
        },
      }
    case 'absolute':
      return {
        scheduleAbsolute: schedule.date,
      }
    case 'on-previous-steps-completion': {
      return {
        scheduleOnCompletion: {
          unit: 'DAYS',
          value: schedule.offset?.value ?? 0,
        },
      }
    }
    default:
      assertNever(schedule)
  }
}

type SerializedDueDate = {
  dueDateAbsolute?: string
  dueDateRelativeDays?: number
}

type SerializedSchedule = {
  scheduleAbsolute?: string
  scheduleRelative?: {
    unit: 'DAYS'
    value: number
  }
  scheduleOnCompletion?: {
    unit: 'DAYS'
    value: number
  }
}

type SerializedOutlineContentStep = {
  autoAssignLiveSession?: boolean
  enableSelfEnrollment?: boolean
  sectionIndex: number | undefined
} & (
  | {
      type: 'COURSE'
      courseId: CourseId
    }
  | {
      type: 'PATH'
      pathId: PathId
    }
  | {
      type: 'EMAIL'
      resourceId: UUID
    }
) &
  SerializedDueDate

type SerializedOutlineStep = SerializedOutlineContentStep & SerializedSchedule

export type SerializedOutline = {
  steps: SerializedOutlineStep[]
  sections: InputProgramSectionInput[]
}

export const serializeOutline = (outline: ProgramOutline): SerializedOutline => {
  const serialized: SerializedOutlineStep[] = [
    ...outline.steps.map(step => {
      const schedule = serializeSchedule(step.schedule)
      const sectionIndex = step.sectionIndex ?? undefined

      if (isContentProgramStep(step)) {
        const commonProps = {
          ...schedule,
          ...serializeDueDate(step.dueDate),
          autoAssignLiveSession: step.autoAssignLiveSession,
          enableSelfEnrollment: step.enableSelfEnrollment,
          sectionIndex,
        }

        if (step.type === 'path') {
          return {
            ...commonProps,
            type: 'PATH',
            pathId: step.pathId,
          } as const
        } else {
          return {
            ...commonProps,
            type: 'COURSE',
            courseId: step.courseId,
          } as const
        }
      } else {
        return {
          ...schedule,
          type: 'EMAIL',
          resourceId: step.resourceId,
          sectionIndex,
        } as const
      }
    }),
  ]

  return {
    steps: serialized,
    sections: outline.sections.map(s => ({ title: s.title, description: s.description ?? '' })),
  }
}

export const getOnlyInProgressUsers = (mode: StaggeredAssignmentsMode): boolean => {
  if (mode === 'in-progress') {
    return true
  }

  return false
}

export const emptyEmailTemplate: EmailTemplate = {
  headline: '',
  description: '',
  buttonText: '',
  image: undefined,
}

export const emailPlaceholders: Pick<EmailTemplate, 'headline' | 'description' | 'buttonText'> = {
  headline: '',
  description: '',
  buttonText: '',
}

export const deserializeTemplateBindings = (
  bindings: FetchEmailTemplateBindingsQuery['emailTemplateBindings']
): EmailTemplate => {
  if (bindings === undefined || bindings === null) {
    return emptyEmailTemplate
  }

  return {
    headline: bindings.headline,
    description: bindings.description,
    buttonText: bindings.buttonText,
    customButtonUrl: bindings.customButtonUrl ?? undefined,
    image: convertGQLImage(bindings.image),
  }
}

export const serializeTemplateAsBindingsInput = (
  template: EmailTemplate
): EmailTemplateBindingsForProgramInput => {
  return {
    headline: template.headline,
    description: template.description,
    buttonText: template.buttonText,
    customButtonUrl: template.customButtonUrl,
    image: JSON.stringify(template.image),
  }
}

export const serializeTemplateAsRenderable = (
  programId: string,
  template: EmailTemplate
): RenderableProgramEmailInput => {
  return {
    programId: programId,
    bindings: serializeTemplateAsBindingsInput(template),
  }
}

export const getNonEmptyEmailTemplate = (
  emailPlaceholders: Pick<EmailTemplate, 'headline' | 'description' | 'buttonText'>,
  emailTemplate: EmailTemplate,
  target?: EmailPlaceholderKeys,
  value?: EmailPlaceholderValues
): EmailTemplate => {
  let newEmailTemplate =
    target !== undefined && value !== undefined ? { ...emailTemplate, [target]: value } : emailTemplate

  Object.entries(newEmailTemplate).forEach(([target, value]) => {
    if (['headline', 'description', 'buttonText'].includes(target)) {
      if (value === '') {
        newEmailTemplate = {
          ...newEmailTemplate,
          [target]: emailPlaceholders[target as EmailPlaceholderKeys],
        }
      }
    }
  })

  return newEmailTemplate
}

export const useLightColor = (tokenColor: ColorBuilder): Color => {
  const colorAsHsla = tokenColor.toHsla()

  const lightColor = color({
    h: colorAsHsla.h,
    s: colorAsHsla.s,
    l: 93,
    a: colorAsHsla.a,
  })

  return lightColor
}
