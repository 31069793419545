import React from 'react'
import {
  AblyYDocWithAwarenessState,
  useAblyYDocWithAwareness,
  useAblyYDocWithFakeAwareness,
} from 'sierra-client/collaboration/use-ably-ydoc'
import { useInitEditorWithFile } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { SlateCardWithExplicitEditor } from 'sierra-client/views/flexible-content/slate-card'
import { tutorStateUnavailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { AssetContext } from 'sierra-domain/asset-context'
import {
  ScopedLiveContentId,
  ScopedYDocId,
  createScopedExternalNotepadId,
} from 'sierra-domain/collaboration/types'
import { ExternalNotepadData, File } from 'sierra-domain/flexible-content/types'

type ExternalNotepadSlateCardProps = {
  file: File & { data: ExternalNotepadData }
  scopedLiveContentId: ScopedLiveContentId
  pasteFile: (card: string) => void
  mode: EditorMode
  enableCommenting?: boolean
  assetContext: AssetContext
}

const LoadedExternalNotepadSlateCard: React.FC<
  ExternalNotepadSlateCardProps & {
    yDocId: ScopedYDocId
    context: AblyYDocWithAwarenessState
    readOnly: boolean
    assetContext: AssetContext
  }
> = ({
  context,
  file,
  pasteFile,
  mode,
  scopedLiveContentId,
  enableCommenting,
  yDocId,
  readOnly,
  assetContext,
}) => {
  const editor = useInitEditorWithFile({
    awareness: context.awareness,
    yDoc: context.yDoc,
    fileId: file.id,
    readOnly,
    slateFileType: 'notepad',
    pasteFile,
    yDocId,
  })

  return (
    <>
      {editor.type === 'available' && (
        <SlateCardWithExplicitEditor
          tutorState={tutorStateUnavailable}
          editor={editor.value}
          // Must use key to remount component when the editor ref changes because SlateContext requires an unchanging editor prop.
          key={editor.value.key}
          courseId={ScopedLiveContentId.extractId(scopedLiveContentId)}
          file={file}
          mode={mode}
          readOnly={readOnly}
          enableCommenting={enableCommenting}
          assetContext={assetContext}
        />
      )}
    </>
  )
}

export const ExternalNotepadSlateCardWithAwareness: React.FC<ExternalNotepadSlateCardProps> = ({
  file,
  scopedLiveContentId,
  pasteFile,
  mode,
  enableCommenting,
  assetContext,
}) => {
  const yDocId = createScopedExternalNotepadId(file.id, scopedLiveContentId)
  const context = useAblyYDocWithAwareness(yDocId)

  return (
    <>
      {context !== undefined && (
        <LoadedExternalNotepadSlateCard
          yDocId={yDocId}
          pasteFile={pasteFile}
          file={file}
          context={context}
          mode={mode}
          scopedLiveContentId={scopedLiveContentId}
          enableCommenting={enableCommenting}
          readOnly={context.permission !== 'edit'}
          assetContext={assetContext}
        />
      )}
    </>
  )
}

export const ExternalNotepadSlateCardWithoutAwareness: React.FC<ExternalNotepadSlateCardProps> = ({
  file,
  scopedLiveContentId,
  pasteFile,
  mode,
  enableCommenting,
  assetContext,
}) => {
  const yDocId = createScopedExternalNotepadId(file.id, scopedLiveContentId)
  const context = useAblyYDocWithFakeAwareness(yDocId)

  return (
    <>
      {context !== undefined && (
        <LoadedExternalNotepadSlateCard
          yDocId={yDocId}
          pasteFile={pasteFile}
          file={file}
          context={context}
          mode={mode}
          scopedLiveContentId={scopedLiveContentId}
          enableCommenting={enableCommenting}
          readOnly={true}
          assetContext={assetContext}
        />
      )}
    </>
  )
}

export const ExternalNotepadSlateCard: React.FC<
  ExternalNotepadSlateCardProps & {
    blockedDueToSessionLimit: boolean
  }
> = ({
  file,
  scopedLiveContentId,
  pasteFile,
  mode,
  enableCommenting,
  blockedDueToSessionLimit,
  assetContext,
}) => {
  if (blockedDueToSessionLimit === true) {
    return (
      <ExternalNotepadSlateCardWithoutAwareness
        file={file}
        scopedLiveContentId={scopedLiveContentId}
        pasteFile={pasteFile}
        mode={mode}
        enableCommenting={enableCommenting}
        assetContext={assetContext}
      />
    )
  }

  return (
    <ExternalNotepadSlateCardWithAwareness
      file={file}
      scopedLiveContentId={scopedLiveContentId}
      pasteFile={pasteFile}
      mode={mode}
      enableCommenting={enableCommenting}
      assetContext={assetContext}
    />
  )
}
