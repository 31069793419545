import { useCallback, useMemo } from 'react'
import { IdentitiesSelector } from 'sierra-client/components/common/identities-selector'
import { FetchIdentities, Identity } from 'sierra-client/components/common/identities-selector/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'

export type EventParticipantSelectorProps = {
  disabled?: boolean
  selectedIdentities: IdentityWithMetadata[]
  setSelectedIdentities: (update: (identities: IdentityWithMetadata[]) => IdentityWithMetadata[]) => void
  grow?: boolean
}

export const CalendarEventUserSelector: React.FC<
  EventParticipantSelectorProps & { fetchIdentities: FetchIdentities }
> = ({ selectedIdentities, setSelectedIdentities, fetchIdentities, disabled, grow }) => {
  const onSelect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities => [...selectedIdentities, identity])
    },
    [setSelectedIdentities]
  )

  const onUnselect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities =>
        selectedIdentities.filter(i => i.identity.id !== identity.id)
      )
    },
    [setSelectedIdentities]
  )

  const _selectedIdentitiesWithId = useMemo(
    () =>
      selectedIdentities.flatMap(identity =>
        identity.identity.type === 'user'
          ? [
              {
                ...identity,
                id: identity.identity.id,
              },
            ]
          : []
      ),
    [selectedIdentities]
  )

  const selectedIdentitiesWithId = useDeepEqualityMemo(_selectedIdentitiesWithId)

  return (
    <IdentitiesSelector
      fetchIdentities={fetchIdentities}
      selectedIdentities={selectedIdentitiesWithId}
      onSelect={onSelect}
      onUnselect={onUnselect}
      grow={grow}
      placeholder='dictionary.add-people'
      disabled={disabled}
    />
  )
}
