import { useId, useState } from 'react'
import { usePathCollaboratorGroupsMutation } from 'sierra-client/api/hooks/use-path-collaborator'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import {
  PathCollaboratorIdentitiesSelector,
  PathCollaboratorIdentitiesSelectorProps,
} from 'sierra-client/views/manage/paths/components/path-collaborator-identities-selector'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import type { PathCollaboratorGroup } from 'sierra-domain/api/admin'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import {
  XRealtimeAdminPathsListPathCollaboratorGroups,
  XRealtimeAdminPathsListPotentialPathCollaboratorGroups,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, LoadingSpinner, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AutocompleteContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const GroupList = styled.ul`
  list-style: none;
  position: relative;

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: PathCollaboratorGroup): string => option.groupName ?? ''

export const CollaboratorGroupsTab: SettingsTabComponent = ({ pathId, onSave, canEditCollaborators }) => {
  const collaboratorGroupsQuery = useCachedQuery(XRealtimeAdminPathsListPathCollaboratorGroups, { pathId })
  const potentialCollaboratorGroupsQuery = useCachedQuery(
    XRealtimeAdminPathsListPotentialPathCollaboratorGroups,
    { pathId }
  )
  const collaborationGroupMutation = usePathCollaboratorGroupsMutation()

  const collaboratorGroups = collaboratorGroupsQuery.data?.collaborators ?? []

  const inputId = useId()

  const { t } = useTranslation()
  const notifications = useNotif()

  const [selectedCollaboratorIdentities, setSelectedCollaboratorIdentitiesState] = useState<
    IdentityWithMetadata[]
  >([])

  const setSelectedCollaboratorIdentities: PathCollaboratorIdentitiesSelectorProps['setSelectedIdentities'] =
    update => {
      const updatedIdentities = update(selectedCollaboratorIdentities)
      setSelectedCollaboratorIdentitiesState(updatedIdentities)
    }

  const fetchCollaboratorIdentities = useAdminIdentitiesFetcher({ withTypes: ['user-group'] })

  const handleInviteSelections = (): void => {
    collaborationGroupMutation.mutate(
      {
        pathId,
        add: selectedCollaboratorIdentities.map(i => {
          switch (i.identity.type) {
            case 'userGroup':
              return i.identity.id
            case 'user':
              throw new Error('User identity in group selector on path collaborator groups tab')
            default:
              assertNever(i.identity)
          }
        }),
        remove: [],
      },
      {
        onSuccess() {
          setSelectedCollaboratorIdentities(() => [])
          onSave()

          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.group-invited-singular'),
          })
        },
      }
    )
  }

  const handleRemoveCollaboratorGroup = (group: PathCollaboratorGroup): void => {
    collaborationGroupMutation.mutate(
      { pathId, add: [], remove: [group.groupId] },
      {
        onSuccess() {
          onSave()
        },
      }
    )
  }

  return (
    <FormElement htmlFor={inputId} label={t('author.invite-groups')}>
      {potentialCollaboratorGroupsQuery.data === undefined ? (
        <LoadingSpinner padding='large' />
      ) : (
        <AutocompleteContainer>
          <PathCollaboratorIdentitiesSelector
            selectedIdentities={selectedCollaboratorIdentities}
            setSelectedIdentities={setSelectedCollaboratorIdentities}
            fetchIdentities={fetchCollaboratorIdentities}
            type='user-group'
          />
          <Button
            onClick={handleInviteSelections}
            disabled={!canEditCollaborators || selectedCollaboratorIdentities.length === 0}
          >
            {t('dictionary.invite')}
          </Button>
        </AutocompleteContainer>
      )}
      <GroupList>
        {collaboratorGroups.length === 0 ? (
          <li>
            <Text color='grey35'>{t('author.no-collaborators')}</Text>
          </li>
        ) : (
          collaboratorGroups.map((group: PathCollaboratorGroup) => (
            <li key={group.groupId} className='grid'>
              <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
              <Text bold>{getOptionLabel(group)}</Text>

              {canEditCollaborators && (
                <IconButton
                  onClick={() => handleRemoveCollaboratorGroup(group)}
                  iconId='close'
                  variant='transparent'
                />
              )}
            </li>
          ))
        )}
      </GroupList>
    </FormElement>
  )
}
