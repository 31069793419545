import { useMutation } from '@tanstack/react-query'
import React, { useCallback, useRef } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import { ZoomOauth } from 'sierra-client/components/sana-now-integration-oauth/zoom-oauth'
import { useAuthenticatedUserIntegrationsQuery } from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isNonNullable } from 'sierra-domain/utils'
import { Button } from 'sierra-ui/primitives'

export const ZoomButton: React.FC<{
  onLink: (url: string) => void
  disabled?: boolean
}> = ({ onLink, disabled }) => {
  const { t } = useTranslation()
  const oauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)

  const authenticatedQuery = useAuthenticatedUserIntegrationsQuery()

  const createMeetingLinkMutation = useMutation({
    mutationFn: () =>
      graphQuery(
        graphql(`
          mutation createZoomUrl {
            createZoomUrl {
              url
            }
          }
        `)
      ),
    onSuccess: res => {
      if (isNonNullable(res.createZoomUrl?.url)) {
        onLink(res.createZoomUrl.url)
      } else {
        oauthRef.current?.onBtnClick()
      }
    },
  })

  const handleOnButtonClick = useCallback(() => {
    if (isNonNullable(createMeetingLinkMutation.data?.createZoomUrl?.url)) {
      onLink(createMeetingLinkMutation.data.createZoomUrl.url)
      return
    }

    if (authenticatedQuery.data?.viewer.integrations.zoom.capabilities.canCreateMeetingUrl === true) {
      createMeetingLinkMutation.mutate()
    } else {
      oauthRef.current?.onBtnClick()
    }
  }, [
    authenticatedQuery.data?.viewer.integrations.zoom.capabilities.canCreateMeetingUrl,
    createMeetingLinkMutation,
    onLink,
  ])

  return (
    <>
      {authenticatedQuery.isSuccess && (
        <ZoomOauth
          ref={oauthRef}
          clientId={authenticatedQuery.data.viewer.integrations.zoom.clientId}
          onSuccess={() => {
            createMeetingLinkMutation.mutate()
          }}
          scope={authenticatedQuery.data.viewer.integrations.zoom.createMeetingUrlScope.map(
            ({ value }) => value
          )}
        />
      )}
      <Button
        variant='secondary'
        onClick={handleOnButtonClick}
        loading={authenticatedQuery.isPending || createMeetingLinkMutation.isPending}
        disabled={authenticatedQuery.isPending || disabled}
        customDecorator={<ZoomLogo width={14} height={14} />}
      >
        {t('dictionary.zoom')}
      </Button>
    </>
  )
}
