import { useQuery } from '@tanstack/react-query'
import { sha512 } from 'js-sha512'
import _ from 'lodash'
import { useMemo } from 'react'
import { learnerTagListQuery } from 'sierra-client/api/queries/learner-tags'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { TagId } from 'sierra-domain/api/nano-id'
import { TagData } from 'sierra-domain/content/tag'

function hash(userId: string, tagId: TagId): string {
  return sha512.hex(`${userId}:${tagId}`)
}

type TagWithId = { id: TagId } & TagData

export function useOrderedTags(): TagWithId[] {
  const user = useSelector(selectUser)
  const tags = useQuery(learnerTagListQuery).data
  const userId = user?.uuid

  const orderedTags = useMemo(() => {
    if (userId === undefined) return []

    const sortedTags = _.chain(tags ?? [])
      .map(tag => ({ id: tag.id, name: tag.data.name, description: tag.data.description }))
      .sortBy(tag => hash(userId, tag.id))
      .value()

    return sortedTags
  }, [userId, tags])

  return orderedTags
}

export function useRankedTags(): TagWithId[] {
  const tags = useQuery(learnerTagListQuery).data

  const orderedTags = useMemo(
    () =>
      (tags ?? []).map(tag => ({
        id: tag.id,
        name: tag.data.name,
        description: tag.data.description,
      })),
    [tags]
  )

  return orderedTags
}
