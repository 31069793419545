import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { FCC } from 'sierra-client/types'

type FocusEditorContext = {
  setShouldAutoFocus: Dispatch<SetStateAction<boolean>>
  shouldAutoFocus: boolean
}

const ReactFocusEditorContext = React.createContext<FocusEditorContext | undefined>(undefined)

export const FocusEditorContext: FCC<{ override?: boolean }> = ({ children, override }) => {
  const isOwner = useIsOwnerUser()

  const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(true)
  const value = useMemo(
    (): FocusEditorContext => ({
      shouldAutoFocus: override ?? (shouldAutoFocus && isOwner),
      setShouldAutoFocus,
    }),
    [override, shouldAutoFocus, isOwner, setShouldAutoFocus]
  )
  return <ReactFocusEditorContext.Provider value={value}>{children}</ReactFocusEditorContext.Provider>
}

export function useEditorFocusContext(): FocusEditorContext {
  const context = React.useContext(ReactFocusEditorContext)
  if (context === undefined) throw new Error('Could not find a <FocusEditorContext>')
  return context
}

export function useAutoFocus(): boolean {
  return useEditorFocusContext().shouldAutoFocus
}
