import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { SearchContent } from 'sierra-client/components/shortcut-menu/search-content'
import { PostWithoutErrorsType, usePost } from 'sierra-client/hooks/use-post'
import { useTemporaryFileUpload } from 'sierra-client/hooks/use-temporary-file-upload'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorExportFlexibleContentAsZip,
  XRealtimeAuthorImportFlexibleContentAsZip,
} from 'sierra-domain/routes'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { AvatarStack, Label, TruncatedText } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const numberOfCoursesToDisplay = 25

const ContentRowTitle: React.FC<{ title: string; type: string; isTemplate: boolean; courseId: string }> = ({
  courseId,
  title,
  type,
  isTemplate,
}) => (
  <View>
    <TruncatedText lines={2} size='small' bold>
      {`${title} [${courseId}]`}
    </TruncatedText>
    {type === 'native:live' && (
      <Label iconId='play--circle--filled' $size='small' bold $bgColor='redVivid' $color='white'>
        Live
      </Label>
    )}
    {type === 'native:self-paced' && (
      <Label iconId='bookmark--filled' $size='small' bold $bgColor='grey5' $color='grey90'>
        Course
      </Label>
    )}
    {type === 'path' && (
      <Label iconId='path' $size='small' bold $bgColor='grey5' $color='grey90'>
        Path
      </Label>
    )}
    {isTemplate && (
      <Label iconId='template' $size='small' bold $bgColor='bluePastel' $color='black'>
        Template
      </Label>
    )}
  </View>
)

const Content: React.FC<EditableContent> = ({ ...content }) => {
  const { t } = useTranslation()
  const displayTitle = content.title === '' ? t('admin.author.no-title') : content.title
  const users = useUsersLegacy(content.collaborators.map(u => u.userId))
  const definedUsers = users.filter(isDefined).map(resolveUserAvatar)

  return (
    <>
      <ContentRowTitle
        {...content}
        title={displayTitle}
        courseId={content.id}
        isTemplate={content.type !== 'path' && content.templateSettings !== undefined}
      />
      <AvatarStack users={definedUsers} size='tiny' />
    </>
  )
}

const exportCourseAsZip = async (
  content: EditableContent,
  postWithUserErrorException: PostWithoutErrorsType
): Promise<void> => {
  const response = await postWithUserErrorException(XRealtimeAuthorExportFlexibleContentAsZip, {
    courseId: NanoId12.parse(content.id),
  })

  window.open(response.url, '_blank', 'noopener,noreferrer')
}

export const ExportCourseAsZip: React.FC = () => {
  const { postWithUserErrorException } = usePost()

  return (
    <SearchContent
      numberOfCoursesToDisplay={numberOfCoursesToDisplay}
      ContentComponent={Content}
      onContentSelected={content => exportCourseAsZip(content, postWithUserErrorException)}
      successMessage={_ => 'Exported course as ZIP file'}
    />
  )
}

const HiddenInput = styled.input`
  display: none;
`

const FileInput: React.FC<{ value?: File; onChange: (file?: File) => void }> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const fileInput = useRef<HTMLInputElement>(null)
  const handleClickUpload = (): void => {
    if (fileInput.current === null) return
    fileInput.current.click()
  }

  useEffect(() => {
    if (value === undefined && fileInput.current) {
      fileInput.current.value = ''
    }
  }, [value])

  return (
    <View>
      <HiddenInput
        type='file'
        accept='.zip'
        ref={fileInput}
        onChange={e => {
          const file = e.target.files?.[0]
          if (isNonNullable(file)) {
            onChange(file)
          }
        }}
      />
      <Button onClick={handleClickUpload}>{t('dictionary.upload')}</Button>
    </View>
  )
}

export type UploadedFile = { id: string; file: File }
export const FileInputWithUpload: React.FC<{
  value?: UploadedFile
  onChange: (file: UploadedFile | undefined) => void
}> = ({ value, onChange }) => {
  const { uploadTemporaryFile } = useTemporaryFileUpload(undefined)

  const [assetContext] = useState({ type: 'unknown' as const })

  return (
    <FileInput
      value={value?.file}
      onChange={async file => {
        if (file) {
          const { fileId } = await uploadTemporaryFile(file, assetContext)
          onChange({ id: fileId, file: file })
        } else {
          onChange(undefined)
        }
      }}
    />
  )
}

export const ImportContainer = styled.div`
  position: relative;
  width: 720px;
  height: 200px;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
`

export const ImportCourseAsZip: React.FC = () => {
  const { postWithUserErrorException } = usePost()
  const notif = useNotif()
  const dispatch = useShortcutMenuDispatch()
  const { t } = useTranslation()

  const uploadMutation = useMutation({
    mutationFn: async (fileId: UploadedFile['id']) => {
      await postWithUserErrorException(XRealtimeAuthorImportFlexibleContentAsZip, { fileId })
      void dispatch({ type: 'close' })
      notif.push({ type: 'custom', level: 'success', body: 'Imported course successfully' })
    },
  })

  return (
    <ImportContainer>
      <View direction='column' alignItems='center' grow gap='16'>
        <Text color='foreground/primary' size='regular' bold align='center'>
          {t('manage.course.import-courses')}
        </Text>

        {uploadMutation.isPending ? (
          <LoadingSpinner />
        ) : (
          <FileInputWithUpload
            onChange={file => {
              if (file === undefined) {
                notif.push({ type: 'custom', level: 'error', body: 'No file selected' })
              } else {
                uploadMutation.mutate(file.id)
              }
            }}
          />
        )}
      </View>
    </ImportContainer>
  )
}
