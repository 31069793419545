import Placeholder from '@tiptap/extension-placeholder'
import { useEditor } from '@tiptap/react'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { CustomMention } from 'sierra-client/components/chat/mention/custom-mention'
import { CustomSuggestion } from 'sierra-client/components/chat/mention/use-mention-suggestion'
import {
  StyledEditorContent,
  getTiptapBaseExtensions,
  getTiptapContents,
} from 'sierra-client/components/chat/tiptap'
import { PreventInsertOnKeydown } from 'sierra-client/components/chat/tiptap-enter-handler'
import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const CommentEditorContent = styled(StyledEditorContent)<{ $lines: number; $maxLines: number }>`
  .ProseMirror {
    background: ${palette.primitives.white};
    border: 1px solid ${palette.grey[5]};
    margin: ${spacing['4']};
    padding: 0.5rem;
    border-radius: ${spacing['4']};

    overflow-y: auto;
    font-size: 0.875rem;
    transition: all 150ms;

    & > p {
      max-width: 32ch;
    }

    &:hover {
      border-color: ${p => p.theme.color.grey25};
    }

    &:focus-within {
      border-color: ${p => p.theme.color.blueBright};
    }
  }
`

type CommentInputProps = {
  onChange?: (value: string) => void
  placeholder?: string
  lines?: number
  maxLines?: number
  onEnter?: (event: React.KeyboardEvent) => void
  autoFocus?: boolean
  mentionSuggestion?: CustomSuggestion
}

export type CommentInputRef = {
  focus: () => void
  getJSON: () => Record<string, unknown>
}

export const CommentInput = forwardRef<CommentInputRef | undefined, CommentInputProps>(
  ({ mentionSuggestion, autoFocus, onChange, placeholder, onEnter, lines = 3, maxLines = 20 }, ref) => {
    // Mentions are currently only supported on /create/{l,s}
    const mentionPlugin = useMemo(
      () =>
        mentionSuggestion !== undefined
          ? [
              CustomMention.configure({
                HTMLAttributes: {
                  class: 'mention',
                },
                suggestion: mentionSuggestion,
              }),
            ]
          : [],
      [mentionSuggestion]
    )

    const editor = useEditor(
      {
        extensions: [
          ...getTiptapBaseExtensions(),
          ...mentionPlugin,
          Placeholder.configure({
            placeholder,
          }),
          PreventInsertOnKeydown.configure({ keys: ['Mod-Enter'] }),
        ],
        onUpdate: ({ editor: currentEditor }) => {
          onChange?.(currentEditor.getText().trim())
        },
      },
      [onChange, placeholder, mentionPlugin]
    )

    useEffect(() => {
      if (autoFocus === true) {
        editor?.commands.focus()
      }
    }, [editor, autoFocus])

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          editor?.commands.focus()
        },
        getJSON: () => (editor !== null ? getTiptapContents(editor) : {}),
      }
    }, [editor])

    const onKeyDown = (event: React.KeyboardEvent): void => {
      event.stopPropagation()

      if (onEnter !== undefined && event.key === 'Enter' && event.shiftKey === false) {
        onEnter(event)
      }
    }

    return <CommentEditorContent editor={editor} onKeyDown={onKeyDown} $lines={lines} $maxLines={maxLines} />
  }
)
