import {
  XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates,
  XRealtimeAdminLiveSessionsAssignUsers,
  XRealtimeAdminUsersSetContentDueDates,
  XRealtimeAdminUsersSetContentIsRequiredAssignments,
  XRealtimeAdminUsersSetContentRecurrence,
} from 'sierra-domain/routes'

import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'
import { IntervalInput } from 'sierra-client/components/common/interval-input'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import {
  AssignMultipleWithDueDatesRequest,
  ComputedDueDate,
  DueDate,
  DueDateAbsolute,
  DueDateGroup,
  DueDateRelativeInterval,
  DueDateSource,
  SetUserContentAssignmentDueDate,
  SetUserContentAssignmentRecurrence,
  SetUserContentIsRequiredAssignment,
  UserDueDates,
  UserDueDatesEffective,
} from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isLeft } from 'sierra-domain/either'
import { UserErrorCode } from 'sierra-domain/error'
import { assertNever } from 'sierra-domain/utils'
import { Icon, InputDatePicker, Label, Modal, Tooltip } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

const DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_ABSOLUTE_DATE: DueDateAbsolute = {
  type: 'absolute',
  date: DateTime.now().plus({ days: 7 }).toFormat(DATE_FORMAT),
}
const DEFAULT_RELATIVE_DATE: DueDateRelativeInterval = {
  type: 'relative',
  interval: { value: 7, unit: 'days' },
}

// Not using generic because they get on the way
type DueDateDialogProps = {
  open: boolean
  value?: DueDate
  fallbackDueDateType: DueDate['type']
  onChange: (value: DueDate | undefined) => void
  onClose: () => void
  forceDisableRemove?: boolean
}

export const DueDateDialog = ({
  open,
  fallbackDueDateType,
  value,
  onChange,
  onClose,
  forceDisableRemove,
}: DueDateDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState<DueDate>(
    value ?? (fallbackDueDateType === 'absolute' ? DEFAULT_ABSOLUTE_DATE : DEFAULT_RELATIVE_DATE)
  )
  const disableRemove = forceDisableRemove === true || value === undefined

  return (
    <Modal open={open} onClose={onClose} size={{ width: 444 }}>
      <View direction='column' margin='small' gap='none'>
        <Text size='large' bold>
          {t('due-date.modal.heading')}
        </Text>
        <Spacer size='xxsmall' />
        {currentValue.type === 'absolute' && (
          <>
            <Text size='regular'>{t('due-date.modal.absolute-message')}</Text>
            <Spacer size='medium' />
            <InputDatePicker
              value={DateTime.fromISO(
                ((currentValue as undefined | DueDateAbsolute) ?? DEFAULT_ABSOLUTE_DATE).date
              )}
              disablePastDates
              onChange={newDate => {
                if (newDate === undefined) return
                const date = newDate.toISODate()
                setCurrentValue({ type: 'absolute', date })
              }}
            />
          </>
        )}
        {currentValue.type === 'relative' && (
          <>
            <Text size='regular'>{t('due-date.modal.relative-message')}</Text>
            <Spacer size='medium' />
            <IntervalInput
              value={
                ((currentValue as undefined | DueDateRelativeInterval) ?? DEFAULT_RELATIVE_DATE).interval
              }
              leadingText={t('due-date.interval.leading-text')}
              onIntervalChange={i => {
                setCurrentValue({ type: 'relative', interval: i })
              }}
            />
          </>
        )}
        <Spacer size='small' />
        <View direction='row' gap='none' justifyContent='space-between'>
          <View margin='none' direction='row' gap='none' justifyContent='flex-start' alignItems='flex-end'>
            <Button
              disabled={disableRemove}
              variant='destructive'
              onClick={() => {
                if (!disableRemove) {
                  onChange(undefined)
                }
              }}
            >
              {t('dictionary.clear')}
            </Button>
          </View>
          <View margin='none' direction='row' gap='none' justifyContent='flex-end' alignItems='flex-end'>
            <Button variant='secondary' onClick={onClose}>
              {t('dictionary.cancel')}
            </Button>
            <Spacer />
            <Button
              onClick={() => {
                onChange(currentValue)
              }}
            >
              {t('dictionary.save')}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export const getDueDateStringRelativeInterval = ({
  t,
  dueDate,
}: {
  t: TranslationLookup
  dueDate: DueDateRelativeInterval
}): string => {
  const count = dueDate.interval.value
  const unit = dueDate.interval.unit
  switch (unit) {
    case 'days':
      return t('due-date.cadence.days-assignment', { count })
    case 'months':
      return t('due-date.cadence.months-assignment', { count })
    case 'years':
      return t('due-date.cadence.years-assignment', { count })
    default:
      assertNever(unit)
  }
}

export const getDueDateString = ({ t, dueDate }: { t: TranslationLookup; dueDate: DueDate }): string => {
  switch (dueDate.type) {
    case 'absolute':
      return dueDate.date
    case 'relative':
      return getDueDateStringRelativeInterval({ t, dueDate })
    default:
      assertNever(dueDate)
  }
}

export const getDueDateStringForGroup = ({
  t,
  dueDate,
}: {
  t: TranslationLookup
  dueDate: DueDateGroup
}): string => {
  if (dueDate.type === 'absolute') return dueDate.date

  return t(`dictionary.n-days`, { count: dueDate.days })
}

export const getDueDateSourceString = ({
  t,
  dueDateSource,
}: {
  t: TranslationLookup
  dueDateSource: DueDateSource
}): string => {
  switch (dueDateSource) {
    case 'direct':
      return t('due-date.source.direct')
    case 'program':
      return t('due-date.source.program')
    default:
      assertNever(dueDateSource)
  }
}

type DueDateTextProps = {
  dueDatesEffective: UserDueDatesEffective | undefined
}

const DueDateText: React.FC<DueDateTextProps> = ({ dueDatesEffective }) => {
  const { t } = useTranslation()
  return (
    <Text>
      {dueDatesEffective
        ? getDueDateStringForGroup({
            t,
            dueDate: { type: 'absolute', date: dueDatesEffective.effectiveDueDate.date },
          })
        : '-'}
    </Text>
  )
}

type DueDateCellProps = {
  dueDatesEffective: UserDueDatesEffective | undefined
  expectedSource: DueDateSource | undefined
}

export const DueDateCell: React.FC<DueDateCellProps> = ({ dueDatesEffective, expectedSource }) => {
  const { t } = useTranslation()
  return (
    <View>
      {dueDatesEffective &&
      expectedSource &&
      expectedSource in dueDatesEffective.dueDates &&
      dueDatesEffective.effectiveSource !== expectedSource ? (
        // Show tooltip if there is a due date for the expected source but the effective one comes from a different one
        <Tooltip title={getDueDateSourceString({ t, dueDateSource: dueDatesEffective.effectiveSource })}>
          <View>
            <Icon iconId='warning' />
            <DueDateText dueDatesEffective={dueDatesEffective} />
          </View>
        </Tooltip>
      ) : (
        <Text>
          <DueDateText dueDatesEffective={dueDatesEffective} />
        </Text>
      )}
    </View>
  )
}

type DueDateLabelProps = {
  dueDatesEffective: UserDueDatesEffective
}

export const DueDateLabel: React.FC<DueDateLabelProps> = ({ dueDatesEffective }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={getDueDateSourceString({ t, dueDateSource: dueDatesEffective.effectiveSource })}>
      <Label $size='small' $bgColor='grey30' $color='white'>
        <b>
          {t('manage.user.due-on-date-label', {
            date: DateTime.fromISO(dueDatesEffective.effectiveDueDate.date).toFormat('MMM dd yyyy'),
          })}
        </b>
      </Label>
    </Tooltip>
  )
}

export const getAbsoluteDueDate = (dueDate?: string): DueDateAbsolute | undefined => {
  return dueDate === undefined ? undefined : { type: 'absolute', date: dueDate }
}

export const getDueDateFromComputedDueDate = (computedDueDate?: ComputedDueDate): DueDate | undefined => {
  if (computedDueDate === undefined) return undefined
  return computedDueDate.interval === undefined
    ? {
        type: 'absolute',
        date: computedDueDate.date,
      }
    : {
        type: 'relative',
        interval: computedDueDate.interval,
      }
}

export const calculateUserDueDateMetadata = (
  dueDates?: UserDueDates
): { dueDate?: DueDateAbsolute; origin?: 'user' | 'group' } => {
  const date = dueDates?.dueDateGroup ?? dueDates?.dueDateDirect
  const dueDate: DueDateAbsolute | undefined =
    date === undefined
      ? undefined
      : {
          type: 'absolute',
          date,
        }
  const origin: 'group' | 'user' | undefined =
    dueDates?.dueDateGroup !== undefined
      ? 'group'
      : dueDates?.dueDateDirect !== undefined
        ? 'user'
        : undefined

  return { dueDate, origin }
}

export type AssignableCourse = { id: string; type: 'course'; isRequired: boolean }
export type AssignablePath = { id: string; type: 'path'; isRequired: boolean }

type UseDueDateData = {
  setUsersDueDate: (
    userIds: UserId[],
    assignments: SetUserContentAssignmentDueDate['assignments']
  ) => Promise<void>
  setUsersRecurrence: (
    userIds: UserId[],
    assignments: SetUserContentAssignmentRecurrence['assignments']
  ) => Promise<void>
  setUsersIsRequiredContent: (
    userIds: UserId[],
    assignments: SetUserContentIsRequiredAssignment['assignments']
  ) => Promise<void>
  assignWithDueDate: (
    assignments: AssignMultipleWithDueDatesRequest['assignments']
  ) => Promise<undefined | { error: UserErrorCode }>
}
export const useDueDate = (): UseDueDateData => {
  const { postWithUserErrorException, postWithUserErrorCode } = usePost()
  const { t } = useTranslation()
  const notif = useNotif()

  const assignWithDueDate = useCallback<UseDueDateData['assignWithDueDate']>(
    async assignments => {
      const [liveSessionAssignments, restAssignments] = _.partition(
        assignments,
        it => it.content.type === 'live-session'
      )
      if (liveSessionAssignments.length > 0) {
        const result = await postWithUserErrorCode(
          XRealtimeAdminLiveSessionsAssignUsers,
          liveSessionAssignments
        )

        if (isLeft(result)) {
          notif.push({ type: 'error', body: t(getUserErrorTranslationKey(result.left)) })
          return { error: result.left }
        }
      }

      if (restAssignments.length > 0)
        await postWithUserErrorException(XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates, {
          assignments: restAssignments,
        })

      return undefined
    },
    [postWithUserErrorException, postWithUserErrorCode, notif, t]
  )

  const setUsersDueDate = useCallback<UseDueDateData['setUsersDueDate']>(
    async (userIds, assignments) => {
      await postWithUserErrorException(XRealtimeAdminUsersSetContentDueDates, {
        userIds: userIds,
        assignments,
      })
    },
    [postWithUserErrorException]
  )

  const setUsersRecurrence = useCallback<UseDueDateData['setUsersDueDate']>(
    async (userIds, assignments) => {
      await postWithUserErrorException(XRealtimeAdminUsersSetContentRecurrence, {
        userIds: userIds,
        assignments,
      })
    },
    [postWithUserErrorException]
  )

  const setUsersIsRequiredContent = useCallback<UseDueDateData['setUsersIsRequiredContent']>(
    async (userIds, assignments) => {
      await postWithUserErrorException(XRealtimeAdminUsersSetContentIsRequiredAssignments, {
        userIds: userIds,
        assignments,
      })
    },
    [postWithUserErrorException]
  )

  return {
    setUsersDueDate,
    setUsersRecurrence,
    setUsersIsRequiredContent,
    assignWithDueDate,
  }
}
