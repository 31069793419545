import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { useFieldArray, useForm } from 'react-hook-form'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ContentAttributeInfo, ContentAttributeValueByIdInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentAttribute } from 'sierra-client/views/manage/content-attributes/types'
import { FormDatePicker, FormInput, FormSingleSelectDropdown } from 'sierra-client/views/manage/shared/form'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'
import { Button, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const SAVE_CONTAINER_HEIGHT = '84px'

const saveAttributes = graphql(`
  mutation updateAttributesForACourse(
    $courseId: NanoId12!
    $attributes: [ContentAttributeValueByIdInput!]!
    $tagIds: [NanoId12!]!
  ) {
    updateAttributesForACourse(courseId: $courseId, attributeValuesById: $attributes, tagIds: $tagIds)
  }
`)

const SaveButtonContainer = styled(motion.div)`
  background: ${token('surface/default')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 48px;
  height: ${SAVE_CONTAINER_HEIGHT};
  border-top: 1px solid ${token('border/default')};
`

const ArributeFormValue = z.object({ attribute: ContentAttribute }).and(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('number'),
      value: z.string().optional(),
    }),
    z.object({
      type: z.literal('text'),
      value: z.string().optional(),
    }),
    z.object({
      type: z.literal('date'),
      value: z.date().optional(),
    }),
    z.object({
      type: z.literal('select'),
      value: z.string().optional(),
    }),
    z.object({
      type: z.literal('categories'),
      value: z.string().optional(),
    }),
  ])
)

const AttributesFormSchema = z.object({
  attributes: z.array(ArributeFormValue),
})

type AttributesFormSchema = z.infer<typeof AttributesFormSchema>

type AttributesFormProps = {
  courseId: CourseId
  attributes: ContentAttribute[]
  courseAttributes: ContentAttributeInfo[]
  onSave: () => void
  onClose: () => void
}

export const AttributesForm: React.FC<AttributesFormProps> = ({
  courseId,
  attributes,
  courseAttributes,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation()

  const defaultValues: AttributesFormSchema = {
    attributes: attributes.map(attr => {
      const courseAttribute = courseAttributes.find(courseAttr => courseAttr.def.id === attr.id)
      if (attr.type === 'date') {
        return {
          type: 'date',
          value: isDefined(courseAttribute?.values[0]) ? new Date(courseAttribute.values[0]) : undefined,
          attribute: attr,
        }
      }

      return {
        type: attr.type,
        value: courseAttribute?.values[0],
        attribute: attr,
      }
    }),
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: zodResolver(AttributesFormSchema),
    defaultValues,
  })

  const { fields } = useFieldArray({
    control,
    name: 'attributes',
  })

  const { mutate: saveAttributesMutation } = useMutation({
    mutationFn: ({
      attributes,
      tagIds = [],
    }: {
      attributes: Array<ContentAttributeValueByIdInput>
      tagIds: NanoId12[]
    }) => {
      return graphQuery(saveAttributes, { courseId, attributes, tagIds })
    },
  })

  const onSubmit = (formData: AttributesFormSchema): void => {
    // TODO(CATs): Add tagIds
    const updatedAttributes: ContentAttributeValueByIdInput[] = formData.attributes.reduce<
      ContentAttributeValueByIdInput[]
    >((acc, { attribute, value }) => {
      if (isDefined(value) && isDefined(attribute.id)) {
        acc.push({ id: attribute.id, values: [value.toString()] })
      }
      return acc
    }, [])

    saveAttributesMutation({ attributes: updatedAttributes, tagIds: [] })
    onSave()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <View direction='column' gap='24'>
        {fields.map((field, index) => {
          const { attribute } = field
          switch (attribute.type) {
            case 'number':
            case 'text': {
              return (
                <View key={attribute.id} direction='column'>
                  <FormInput
                    label={attribute.name}
                    // TODO(CATs): fix types
                    name={`attributes.${index}.value` as any}
                    type={attribute.type}
                    control={control}
                  />
                </View>
              )
            }

            // TODO(CATs): make dates work
            case 'date': {
              return (
                <FormDatePicker
                  label={attribute.name}
                  control={control}
                  name={`attributes.${index}.value` as never}
                />
              )
            }
            case 'categories': {
              const options = attribute.options.map(option => ({
                id: option.id,
                label: option.label,
                type: 'label' as const,
              }))

              return (
                <View key='categories' direction='column'>
                  <FormSingleSelectDropdown
                    label={attribute.name}
                    name={`attributes.${index}.value` as any}
                    control={control}
                    menuItems={[
                      {
                        id: '_unselector',
                        label: t('dictionary.none'),
                        type: 'label',
                        // TODO(CAT): implement this action
                        onClick: () => null,
                      },
                      { id: '_separator', type: 'separator' },
                      ...options,
                    ]}
                  />
                </View>
              )
            }

            case 'select': {
              const options = attribute.options.map(option => ({
                id: option.id,
                label: option.label,
                type: 'label' as const,
              }))

              return (
                <View key={attribute.id} direction='column'>
                  <FormSingleSelectDropdown
                    label={attribute.name}
                    name={`attributes.${index}.value` as any}
                    control={control}
                    menuItems={[
                      {
                        id: '_unselector',
                        label: t('dictionary.none'),
                        type: 'label',
                        // TODO(CAT): implement this action
                        onClick: () => null,
                      },
                      { id: '_separator', type: 'separator' },
                      ...options,
                    ]}
                  />
                </View>
              )
            }
          }
        })}
        <AnimatePresence>
          {isDirty && (
            <SaveButtonContainer
              variants={{
                hidden: { y: 84, opacity: 0 },
                visible: { y: 0, opacity: 1, transition: { duration: 0.15, ease: [0.25, 0.1, 0.25, 1] } },
              }}
              initial='hidden'
              animate='visible'
              exit='hidden'
            >
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Button variant='primary' type='submit'>
                {t('dictionary.save')}
              </Button>
            </SaveButtonContainer>
          )}
        </AnimatePresence>
      </View>
    </form>
  )
}
