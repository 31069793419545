import { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { OrganizationClusterState } from 'sierra-client/features/multi-tenancy/types'
import { organizationComparator } from 'sierra-client/features/multi-tenancy/utils/organization-comparator'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useCachedQuery } from 'sierra-client/state/api'
import { XRealtimeUserGetClusterOrganizations } from 'sierra-domain/routes'

const clusterEmptyState: OrganizationClusterState = {
  loading: true,
  cluster: null,
}

/**
 * Read information about the current organization's multi-tenancy cluster.
 */
export const useOrganizationCluster = (): OrganizationClusterState => {
  const orgPermissions = useOrganizationPermissions()
  const enableMultiTenancy = orgPermissions.has('ACCESS_LMS')

  const clusterRequest = useCachedQuery(
    XRealtimeUserGetClusterOrganizations,
    {},
    {
      enabled: enableMultiTenancy,
      staleTime: Infinity,
    }
  )

  const state = useMemo(() => {
    if (!clusterRequest.isSuccess) {
      return clusterEmptyState
    }

    const currentOrg = clusterRequest.data.cluster.find(org => config.organization.domain === org.domain)
    const selfIsParent = currentOrg?.isClusterParent === true

    // Sort organizations to always have the parent first
    const sorted = clusterRequest.data.cluster.sort(organizationComparator)

    return { loading: false, cluster: sorted, selfIsParent } satisfies OrganizationClusterState
  }, [clusterRequest])

  return state
}
