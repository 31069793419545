import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  selectCardIsCompletedOrHasSubmission,
  selectCourseProgressState,
} from 'sierra-client/state/card-progress/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { AppDispatch } from 'sierra-client/state/store'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { courseHelperGoToBlockingExerciseClicked } from 'sierra-client/views/course-helper/logger'
import { useSelfPacedCardScroll } from 'sierra-client/views/course-helper/shared/self-paced-card-scroll-context'
import { HelperState, PendingReason } from 'sierra-client/views/course-helper/types'
import {
  elementTypeToIconId,
  fileTypeToIconId,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/common'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { useFilteredDocumentContainsNodeWithId } from 'sierra-client/views/self-paced/use-filtered-document'
import { findNode } from 'sierra-client/views/v3-author/queries'
import { NextUp } from 'sierra-domain/api/backend-self-paced/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import { FileType } from 'sierra-domain/flexible-content/types'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { CustomElement, SanaEditor } from 'sierra-domain/v3-author'
import { ReactEditor } from 'slate-react'

// These cards do not need to be completed and can just be skipped; we show '>' instead of 'continue'
const forwardStateFileTypes: FileType[] = ['drop-a-word', 'flip-cards', 'image', 'stupid-questions', 'bullet']

const blockTypeToTranslation = (type: CustomElement['type']): TranslationKey => {
  switch (type) {
    case 'takeaways':
      return 'helper.missed-takeaways'
    case 'question-card':
    case 'question-card-free-text-body':
    case 'question-card-match-the-pairs-alternative':
    case 'question-card-match-the-pairs-alternative-option':
    case 'question-card-match-the-pairs-body':
    case 'question-card-multiple-choice-alternative':
    case 'question-card-multiple-choice-alternative-option':
    case 'question-card-multiple-choice-alternative-explanation':
    case 'question-card-pick-the-best-option-body':
    case 'question-card-select-all-that-apply-body':
    case 'question-variations':
    case 'assessment-question':
      return 'helper.missed-question'
    default:
      return 'helper.missed-exercise'
  }
}

const fileTypeToTranslation = (type: FileType): TranslationKey => {
  switch (type) {
    case 'sliding-scale':
      return 'helper.uncompleted-sliding-scale'
    case 'homework':
      return 'helper.uncompleted-homework'
    case 'poll':
      return 'helper.uncompleted-poll'
    case 'question-card':
      return 'helper.uncompleted-question'
    case 'video':
      return 'helper.uncompleted-video'
    case 'assessment-card':
      return 'helper.uncompleted-assessment-card'
    default:
      return 'helper.complete-the-card'
  }
}

const nextUpToHelperState = ({
  nextUpState,
  fileRequiresScrollToBottom,
  scrollCompleted,
  cardCompletedOrHasSubmission,
  fileType,
  onScroll,
  onContinue,
  unCompletedDiv,
  hasUnCompletedExercises,
  nextFile,
  pendingReason,
  t,
  dispatch,
}: {
  nextUpState: NextUp['type']
  fileRequiresScrollToBottom: boolean
  scrollCompleted: boolean
  cardCompletedOrHasSubmission: boolean
  fileType: FileType
  onScroll: () => void
  onContinue: () => void
  unCompletedDiv: { element: Element; type: CustomElement['type'] } | undefined
  hasUnCompletedExercises: boolean
  nextFile: SelfPacedFile | undefined
  pendingReason: PendingReason | undefined
  t: TranslationLookup
  dispatch: AppDispatch
}): HelperState => {
  if (fileRequiresScrollToBottom) {
    if (!scrollCompleted) {
      return {
        type: 'text',
        textType: 'scroll',
        text: t('helper.scroll-down'),
        iconId: 'scroll--down',
        onClick: onScroll,
      }
    }
  }

  if (!cardCompletedOrHasSubmission) {
    if (isDefined(unCompletedDiv)) {
      return {
        type: 'text',
        textType: 'un-completed-exercise',
        text: t(blockTypeToTranslation(unCompletedDiv.type)),
        iconId: elementTypeToIconId(unCompletedDiv.type),
        onClick: () => {
          unCompletedDiv.element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          })
          void dispatch(
            courseHelperGoToBlockingExerciseClicked({
              typeOfBlockingExercise: unCompletedDiv.type,
            })
          )
        },
      }
    } else if (hasUnCompletedExercises) {
      return {
        type: 'text',
        textType: 'un-completed-exercise',
        text: t(fileTypeToTranslation(fileType)),
        iconId: fileTypeToIconId(fileType),
      }
    } else if (
      fileType === 'sliding-scale' ||
      fileType === 'homework' ||
      fileType === 'video' ||
      fileType === 'assessment-card'
    ) {
      return {
        type: 'text',
        textType: 'un-completed-card',
        text: t(fileTypeToTranslation(fileType)),
        iconId: fileTypeToIconId(fileType),
      }
    } else {
      return { type: 'none' }
    }
  }

  switch (nextUpState) {
    case 'none':
      return { type: 'none' }
    case 'file':
    case 'review':
    case 'placement-test':
      if (forwardStateFileTypes.includes(fileType)) {
        return { type: 'forward' }
      }

      return {
        type: 'text',
        textType: 'continue',
        text: t('helper.continue'),
        iconId: 'next--filled',
        onClick: onContinue,
      }
    case 'course-pending':
      return { type: 'course-pending', pendingReason }
    case 'course-completed': // not in a path or program
    case 'program-completed':
    case 'program-pending':
    case 'next-course-in-program':
    case 'next-course-in-path':
      // when we have finished a course / program, we want to show the finish state only on the last page
      if (nextFile === undefined) {
        return { type: nextUpState }
      } else {
        return { type: 'forward' }
      }
    default:
      assertNever(nextUpState)
  }
}

function tryGetDomNode(
  editor: SanaEditor,
  id: string
): { element: Element; type: CustomElement['type'] } | undefined {
  const [node] = findNode(editor, node => node.id === id)
  if (node !== undefined) {
    try {
      return { element: ReactEditor.toDOMNode(editor, node), type: node.type }
    } catch (e) {
      return undefined
    }
  }
  return undefined
}

function useDomElementForUncompletedExercise({
  courseId,
  fileId,
  editor,
  unCompletedExerciseId,
  isPageCard,
}: {
  courseId: CreateContentId
  fileId: FileId
  editor: SanaEditor | undefined
  unCompletedExerciseId: string | undefined
  isPageCard: boolean
}): { element: Element; type: CustomElement['type'] } | undefined {
  const documentHasNode = useFilteredDocumentContainsNodeWithId(courseId, fileId, unCompletedExerciseId)
  const [unCompletedDomNode, setUnCompletedDomNode] = useState<
    [{ element: Element; type: CustomElement['type'] }, id: string] | undefined
  >(undefined)

  const isDebug = useIsDebugMode()
  const debugDomNode = unCompletedDomNode?.[0]
  useEffect(() => {
    if (!isDebug || debugDomNode === undefined || !(debugDomNode instanceof HTMLElement)) {
      return
    }

    const previousOutline = debugDomNode.style.outline
    debugDomNode.style.outline = '2px dashed rgba(0, 0, 0, 0.2)'
    return () => {
      debugDomNode.style.outline = previousOutline
    }
  }, [debugDomNode, isDebug, unCompletedDomNode])

  useEffect(() => {
    if (unCompletedExerciseId === undefined || editor === undefined || !documentHasNode) {
      return
    }

    let isCancelled = false

    requestAnimationFrame(function loop() {
      const domNode = tryGetDomNode(editor, unCompletedExerciseId)
      const unCompletedElement = unCompletedDomNode?.[0].element
      const uncompletedId = unCompletedDomNode?.[1]

      if (
        domNode !== undefined &&
        (unCompletedElement !== domNode.element || uncompletedId !== unCompletedExerciseId) &&
        isPageCard
      ) {
        setUnCompletedDomNode([domNode, unCompletedExerciseId])
      }

      if (!isCancelled) {
        return requestAnimationFrame(() => loop())
      }
    })

    return () => {
      isCancelled = true
    }
    // loop every frame try to get dom node...
  })

  return useMemo(() => {
    if (unCompletedDomNode === undefined) {
      return undefined
    }
    const [node, id] = unCompletedDomNode
    if (id === unCompletedExerciseId) {
      return node
    }
  }, [unCompletedDomNode, unCompletedExerciseId])
}

export const useUpdateCourseHelperState = ({
  editor,
  currentFile,
}: {
  editor: SanaEditor | undefined
  currentFile: SelfPacedFile
}): void => {
  const setHelperState = useSetAtom(helperStateAtom)
  const dispatch = useDispatch()

  const setAtomDebounced = useMemo(() => {
    // We debounce this atom because we don't want it to show a state for a very short while and then change, however
    return _.debounce(setHelperState, 100) //DON'T EVEN THINK ABOUT INCREASING THIS NUMBER. If you do, there is a problem elsewhere.
  }, [setHelperState])

  const { t } = useTranslation()
  const { nextUp, flexibleContentId, goToNextUp, linearNextUp, nextFile } = useSelfPacedFiles()
  const { scrollCompleted } = useSelfPacedCardScroll()
  const { scrollBy } = useSelfPacedCardScroll()

  const fileRequiresScrollToBottom = currentFile.data.type === 'general'
  const courseProgress = useSelector(state => selectCourseProgressState(state, flexibleContentId))
  const cardCompletedOrHasSubmission = useSelector(state =>
    selectCardIsCompletedOrHasSubmission(state, flexibleContentId, currentFile.id)
  )

  const pendingReasons = Object.entries(courseProgress?.cardStatuses ?? {}).reduce<PendingReason[]>(
    (pendingReasons, [unParsedfileId, cardStatus]) => {
      const moreStatus = cardStatus?.moreStatus.at(0)
      const fileId = FileId.parse(unParsedfileId)

      if (isDefined(moreStatus)) {
        return [...pendingReasons, { fileId, moreStatus }]
      } else {
        return pendingReasons
      }
    },
    []
  )

  const pendingReason = pendingReasons.at(0)

  const cardProgress =
    isDefined(currentFile) && isDefined(courseProgress)
      ? courseProgress.cardStatuses[currentFile.id]
      : undefined

  const unCompletedExerciseIds = cardProgress?.exerciseStatuses
    .filter(it => it.hasCorrectInteraction === false)
    .map(it => it.id)

  const unCompletedExerciseId = unCompletedExerciseIds?.[0]
  const unCompletedDiv = useDomElementForUncompletedExercise({
    editor,
    courseId: flexibleContentId,
    fileId: currentFile.id,
    unCompletedExerciseId: unCompletedExerciseId,
    isPageCard: currentFile.data.type === 'general',
  })

  const goToNext = useCallback(() => {
    if (nextUp.type !== 'none') {
      goToNextUp(nextUp)
    } else if (linearNextUp.type !== 'none') {
      goToNextUp(linearNextUp)
    }
  }, [nextUp, linearNextUp, goToNextUp])

  useEffect(() => {
    const newState = nextUpToHelperState({
      nextUpState: nextUp.type,
      fileRequiresScrollToBottom,
      scrollCompleted,
      cardCompletedOrHasSubmission,
      fileType: currentFile.data.type,
      onScroll: () => scrollBy(500),
      onContinue: goToNext,
      unCompletedDiv,
      hasUnCompletedExercises: isDefined(unCompletedExerciseIds) && unCompletedExerciseIds.length > 0,
      nextFile,
      pendingReason,
      t,
      dispatch,
    })

    setAtomDebounced(newState)
    return () => {
      setAtomDebounced.cancel()
    }
  }, [
    currentFile,
    nextUp,
    scrollCompleted,
    cardCompletedOrHasSubmission,
    setHelperState,
    fileRequiresScrollToBottom,
    goToNext,
    scrollBy,
    unCompletedDiv,
    unCompletedExerciseIds,
    t,
    nextFile,
    setAtomDebounced,
    pendingReason,
    dispatch,
  ])
}
