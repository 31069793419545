import { AnimatePresence } from 'framer-motion'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { useAddSubtitleTracks } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getServerTimeNow } from 'sierra-client/state/collaboration/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import {
  selectFollowMeIsEnabled,
  selectFollowMeVideoPlaybackState,
  selectIsFollowingScroll,
} from 'sierra-client/state/live-session/selectors'
import { FollowVideoState } from 'sierra-client/state/live-session/types'
import { store } from 'sierra-client/state/store'
import { VideoData } from 'sierra-domain/flexible-content/types'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled, { createGlobalStyle, css } from 'styled-components'

const Overlay = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  flex-direction: column;
`

const VideoStyles = createGlobalStyle<{ isFacilitatorOrLearnerLed: boolean; isFollowing: boolean }>`
  .vjs-overwrite {
    ${props =>
      props.isFacilitatorOrLearnerLed
        ? css`
            cursor: default !important;
          `
        : undefined}


    ${props =>
      props.isFollowing
        ? css`
            & .vjs-progress-control {
              pointer-events: none !important;
            }

            & .vjs-control-bar {
              display: flex !important;
            }

            & .vjs-play-control {
              display: none !important;
            }
          `
        : undefined}
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

const getPlaybackState = (player: VideoJsPlayer): FollowVideoState['playbackState'] => {
  if (player.ended()) {
    return 'ended'
  } else if (player.paused()) {
    return 'paused'
  } else {
    return 'playing'
  }
}

const getPlayerState = (player: VideoJsPlayer | null, videoId: string): FollowVideoState | undefined => {
  if (player === null) return

  return {
    playbackTimestamp: player.currentTime(),
    playbackState: getPlaybackState(player),
    videoId,
    updatedAt: getServerTimeNow(store.getState().collaboration.clock),
  }
}

const FollowMePlaybackStateTracker = ({ playbackState }: { playbackState: FollowVideoState }): null => {
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ followVideoState: playbackState }))

    return () => {
      void dispatch(liveSessionLocalAwarenessStateMerged({ followVideoState: undefined }))
    }
  }, [dispatch, playbackState])

  return null
}

const PLAY_TIME_DRIFT_THRESHOLD = 4

const getDelayAdjustedTimestamp = (playbackTimestamp: number, updatedAt: string): number => {
  const serverTimeNow = getServerTimeNow(store.getState().collaboration.clock)
  const elapsedTime = (Date.parse(serverTimeNow) - Date.parse(updatedAt)) / 1000
  return Math.max(playbackTimestamp + elapsedTime, 0)
}

const FollowMePlaybackStateUpdater = (props: { player: VideoJsPlayer; videoId: string }): null => {
  const videoPlaybackState = useSelector(selectFollowMeVideoPlaybackState)

  useOnChanged(
    prevState => {
      if (videoPlaybackState === undefined) return
      if (videoPlaybackState.videoId !== props.videoId) {
        // if there are other players playing, ensure this player is paused
        props.player.pause()
        return
      }
      const { playbackState, playbackTimestamp, updatedAt } = videoPlaybackState

      if (playbackState === 'playing') {
        // Since the update might be delayed, we adjust it according to the current time
        const delayAdjustedTimestamp = getDelayAdjustedTimestamp(playbackTimestamp, updatedAt)

        // To avoid jumping is we are already "close enough" to the remote playing time
        // some player drift is expected, so we allow a small drift.
        // If the player just started syncing (prevState === undefined) ensure we are a bit more strict,
        // so that if a user is 3 seconds off it will resync by toggling the follow button
        const timeDiff = Math.abs(props.player.currentTime() - delayAdjustedTimestamp)
        const playerStateIsCloseEnoughToRemote =
          getPlaybackState(props.player) === 'playing' &&
          ((prevState !== undefined && timeDiff < PLAY_TIME_DRIFT_THRESHOLD) ||
            (prevState === undefined && timeDiff < 0.3))

        if (!playerStateIsCloseEnoughToRemote) {
          props.player.currentTime(delayAdjustedTimestamp)
          void props.player.play()
        }
      } else if (playbackState === 'paused') {
        props.player.currentTime(playbackTimestamp)
        props.player.pause()
      } else {
        // the remote state is 'ended'
        // we want to ensure the video is close to being done for the user as well, so if it's not done for the user we skip to the end

        if (!props.player.ended() && props.player.duration() - props.player.currentTime() > 5) {
          props.player.currentTime(props.player.duration() - 5)
          void props.player.play()
        }
      }
    },
    [videoPlaybackState]
  )

  return null
}

const PlayButtonCircle = styled(Icon)`
  & svg {
    width: 60px !important;
    height: 60px !important;
  }
`

// On safari, the user needs to interact with the video player before the facilitator can control it
const SafariVideoPlaybackAllowedButton: FC<{
  faciliatorIsAllowedToControlVideo: boolean
  setFaciliatorIsAllowedToControlVideo: (value: boolean) => void
}> = ({ faciliatorIsAllowedToControlVideo, setFaciliatorIsAllowedToControlVideo }) => {
  const { t } = useTranslation()
  const isCurrentlyFollowing = useSelector(selectIsFollowingScroll)

  if (faciliatorIsAllowedToControlVideo || !isCurrentlyFollowing) return null

  return (
    <Overlay animated initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <View
        direction='column'
        alignItems='center'
        gap='xsmall'
        onClick={() => setFaciliatorIsAllowedToControlVideo(true)}
      >
        <PlayButtonCircle iconId='play--circle--filled' color='white' />
        <Text bold size='small' color='white'>
          {t('live.video-player-card.allow-facilitator-control-button-2')}
        </Text>
      </View>
    </Overlay>
  )
}

/**
 * A video player that can be used in a live session to that will use the Follow Me feature to sync the playback state
 * Only use this in Live!
 */
export const LiveFollowMeVideoPlayer: React.FC<{
  video: VideoData['video']
  videoId: string
  onPlay?: () => void
  onPause?: () => void
  setVideoDuration?: (durationInSeconds: number) => void
  fluid?: boolean
  className?: string
}> = ({ video, videoId, onPlay, onPause, setVideoDuration, fluid, className }) => {
  const isFacilitatorOrLearnerLed = useIsFacilitatorOrLearnerLedSession()
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const followMeIsEnabled = useSelector(selectFollowMeIsEnabled)
  const isCurrentlyFollowing = useSelector(selectIsFollowingScroll)
  const [playerState, setPlayerState] = useState<FollowVideoState | undefined>(undefined)

  const [faciliatorIsAllowedToControlVideo, setFaciliatorIsAllowedToControlVideo] = useState(!isSafari)
  const addSubtitles = useAddSubtitleTracks(video.url, video.disableSubtitles !== true)

  const handleOnPlay = useCallback(
    async (player: VideoJsPlayer) => {
      const state = getPlayerState(player, videoId)
      setPlayerState(state)

      if (videoRef.current !== null && !getFlag('disable-live-session-acoustic-echo-cancellation')) {
        const videoCallService = await getVideoCallService()
        videoCallService.enableEchoCancellerOnMedia(videoRef.current)
      }

      onPlay?.()
    },
    [videoId, onPlay]
  )

  const handleOnPaused = useCallback(
    (player: VideoJsPlayer) => {
      const state = getPlayerState(player, videoId)
      setPlayerState(state)
      onPause?.()
    },
    [onPause, videoId]
  )

  const handleOnSeeked = useCallback(
    (player: VideoJsPlayer) => {
      const state = getPlayerState(player, videoId)
      setPlayerState(state)
    },
    [videoId]
  )

  const handleOnEnded = useCallback(
    (player: VideoJsPlayer) => {
      const state = getPlayerState(player, videoId)
      setPlayerState(state)
    },
    [videoId]
  )

  const onReady = useCallback(
    (player: VideoJsPlayer, videoElement: HTMLVideoElement) => {
      playerRef.current = player
      videoRef.current = videoElement
      setPlayer(player)
    },
    [playerRef]
  )

  useEffect(() => {
    if (video.url !== undefined)
      playerRef.current?.src({
        src: video.url,
        type: 'video/mp4',
      })
  }, [videoId, video.url])

  useEffect(() => {
    if (player === null) return

    const videoEl = videoRef.current
    if (videoEl !== null && !getFlag('disable-live-session-acoustic-echo-cancellation')) {
      void getVideoCallService().then(videoCallService => {
        videoCallService.enableEchoCancellerOnMedia(videoEl)
      })
    }
  }, [player])

  const onLoadedMetadata = useCallback(
    (player: VideoJsPlayer) => {
      const duration = player.player().duration()
      if (!Number.isNaN(duration)) setVideoDuration?.(duration)
      addSubtitles(player)
    },
    [setVideoDuration, addSubtitles]
  )
  if (video.url === undefined) return null

  return (
    <Wrapper className={className}>
      <VideoStyles isFacilitatorOrLearnerLed={isFacilitatorOrLearnerLed} isFollowing={isCurrentlyFollowing} />
      <VideoPlayer
        onSeeked={handleOnSeeked}
        onPlay={handleOnPlay}
        onEnded={handleOnEnded}
        onPause={handleOnPaused}
        onReady={onReady}
        onLoadedMetadata={onLoadedMetadata}
        // crossOrigin must be 'anonymous' for videoCallService.enableEchoCancellerOnMedia
        // to work for cross-origin media files
        videoElementProps={{ crossOrigin: 'anonymous' }}
        options={{
          autoplay: false,
          playsinline: true,
          controls: !isCurrentlyFollowing,
          responsive: true,
          fill: true,
          preload: 'auto',
          fluid,
          controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
            playbackRateMenuButton: false,
          },
          sources: [{ src: video.url, type: 'video/mp4' }],
          playbackRates: [1],
        }}
        disableSkipping={video.disableSkipping}
      />
      <AnimatePresence>
        <SafariVideoPlaybackAllowedButton
          faciliatorIsAllowedToControlVideo={faciliatorIsAllowedToControlVideo}
          setFaciliatorIsAllowedToControlVideo={setFaciliatorIsAllowedToControlVideo}
        />
      </AnimatePresence>
      {followMeIsEnabled && playerState !== undefined && (
        <FollowMePlaybackStateTracker playbackState={playerState} />
      )}
      {isCurrentlyFollowing && faciliatorIsAllowedToControlVideo && player && (
        <FollowMePlaybackStateUpdater player={player} videoId={videoId} />
      )}
    </Wrapper>
  )
}
