import { useMemo } from 'react'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { segmentService } from 'sierra-client/core/segment'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { UserLoggedOutRequestParams } from 'sierra-domain/segment/event'
import { assertNever } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'

type SettingsMenuItemId = 'settings' | 'logout' | 'cmd+k'
type SettingsMenuItem = MenuItem<SettingsMenuItemId>

export function isSettingsMenuItem(item: MenuItem): item is SettingsMenuItem {
  return ['settings', 'logout', 'cmd+k'].includes(item.id)
}

export const useSettingsMenuitems = (
  setUserDialogOpen: (open: boolean) => void
): {
  menuItems: LabelMenuItem<SettingsMenuItemId>[]
  onMenuSelect: (item: MenuItem<SettingsMenuItemId>) => void
} => {
  const { t } = useTranslation()
  const dispatch = useShortcutMenuDispatch()
  const isDevTools = useDeveloperToolsEnabled()
  const logout = (): void => {
    void segmentService.track(new UserLoggedOutRequestParams())
    void getAuthClient().signOut()
    void getGlobalRouter().navigate({ to: '/' })
  }

  const onMenuSelect = (item: MenuItem<SettingsMenuItemId>): void => {
    switch (item.id) {
      case 'settings':
        setUserDialogOpen(true)
        return
      case 'logout':
        logout()
        return
      case 'cmd+k':
        dispatch({ type: 'open' })
        return
      default:
        assertNever(item.id)
    }
  }

  const menuItems: LabelMenuItem<SettingsMenuItemId>[] = useMemo(() => {
    return [
      {
        id: 'settings',
        type: 'label',
        label: t('topbar.settings'),
        icon: 'settings',
      },
      {
        id: 'logout',
        type: 'label',
        label: t('topbar.log-out'),
        icon: 'logout',
      },

      ...(isDevTools
        ? [
            {
              id: 'cmd+k',
              type: 'label',
              label: '[Dev] cmd+k',
              icon: 'settings',
            } as const,
          ]
        : []),
    ]
  }, [isDevTools, t])

  return { menuItems, onMenuSelect }
}
