import { useCallback, useState } from 'react'
import { errorLogger } from 'sierra-client/error/error-logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isLeft } from 'sierra-domain/either'
import {
  XRealtimeAdminScormImportGetGcsUrl,
  XRealtimeAdminScormImportProcessPackage,
  XRealtimeAdminScormImportProcessPackageUpdate,
} from 'sierra-domain/routes'

const MAX_SCORM_SIZE_MB = 2000
const MAX_SCORM_SIZE_BYTES = MAX_SCORM_SIZE_MB * 1024 * 1024

type UploadFile = {
  file?: File
  progress: number
  status: 'not-started' | 'uploading' | 'upload-done' | 'processing-done' | 'error'
  i18nArgs?: I18nArgs
  uploadUrl?: string
  packageId?: string
  courseId?: CourseId
}

type UseScormUploadData = {
  uploadData: UploadFile
  uploadFile: (file: File, process: (packageId: string) => Promise<void>) => Promise<void>
  processFile: (packageId: string) => Promise<void>
  processFileUpdate: (packageId: string, courseId: CourseId) => Promise<void>
  reset: () => void
}

const DEFAULT_UPLOAD_FILE: UploadFile = {
  status: 'not-started',
  progress: 0,
}

const getErrorMeta = (status: UploadFile['status']): { meta: Record<string, string> } => ({
  meta: { status },
})

export const useScormUpload = (): UseScormUploadData => {
  const { postWithUserErrorException, postWithUserErrorCode } = usePost()
  const [uploadData, setUploadData] = useState<UseScormUploadData['uploadData']>(DEFAULT_UPLOAD_FILE)

  const processFile = useCallback<UseScormUploadData['processFile']>(
    async packageId => {
      const response = await postWithUserErrorCode(XRealtimeAdminScormImportProcessPackage, {
        packageId,
      })
      if (isLeft(response)) {
        const error = response.left
        errorLogger.captureError(error, getErrorMeta('processing-done'))
        setUploadData(u => ({
          ...u,
          status: 'error',
          i18nArgs: [getUserErrorTranslationKey(error, 'scorm.import.other-errors.generic')],
        }))
        return
      }

      setUploadData(u => ({
        ...u,
        status: 'processing-done',
        i18nArgs: undefined,
        courseId: response.right.courseId,
      }))
    },
    [postWithUserErrorCode]
  )

  const processFileUpdate = useCallback<UseScormUploadData['processFileUpdate']>(
    async (packageId, courseId) => {
      const response = await postWithUserErrorCode(XRealtimeAdminScormImportProcessPackageUpdate, {
        packageId,
        courseId,
      })
      if (isLeft(response)) {
        const error = response.left
        errorLogger.captureError(error, getErrorMeta('processing-done'))
        setUploadData(u => ({
          ...u,
          status: 'error',
          i18nArgs: [getUserErrorTranslationKey(error, 'scorm.import.other-errors.generic')],
        }))
        return
      }

      setUploadData(u => ({
        ...u,
        status: 'processing-done',
        i18nArgs: undefined,
        // TODO: Can be parsed at the request level after we switch everything to zod
        courseId: courseId,
      }))
    },
    [postWithUserErrorCode]
  )

  const uploadFile = useCallback<UseScormUploadData['uploadFile']>(
    async (newFile, process) => {
      // Check file size
      if (newFile.size > MAX_SCORM_SIZE_BYTES) {
        setUploadData(u => ({
          ...u,
          status: 'error',
          i18nArgs: ['scorm.import.other-errors.max-size', { size: `${MAX_SCORM_SIZE_MB}MB` }],
        }))
        return
      }

      // Get's Upload URL
      let url: string
      let packageId: string
      try {
        const response = await postWithUserErrorException(XRealtimeAdminScormImportGetGcsUrl, {})
        url = response.url
        packageId = response.packageId

        setUploadData(u => ({
          ...u,
          status: 'uploading',
          file: newFile,
          i18nArgs: undefined,
          uploadUrl: url,
          packageId: packageId,
        }))
      } catch (e) {
        errorLogger.captureError(e, getErrorMeta('not-started'))
        setUploadData(u => ({ ...u, status: 'error', i18nArgs: ['scorm.import.other-errors.generic'] }))
        return
      }

      // Uploads to GCS
      const request = new XMLHttpRequest()
      request.upload.addEventListener('progress', e => {
        // Progress
        const percentage = Math.round(e.loaded / newFile.size)
        setUploadData(u => ({ ...u, progress: percentage }))
      })
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          // Completion
          setUploadData(u => ({
            ...u,
            progress: 1,
            status: 'upload-done',
            i18nArgs: undefined,
          }))
          void process(packageId)
        }
      }
      request.open('PUT', url) // Starts request
      request.onerror = () => {
        errorLogger.captureError('Error uploading scorm file to GCS', getErrorMeta('uploading'))
        // Error
        setUploadData(u => ({
          ...u,
          status: 'error',
          i18nArgs: ['scorm.import.other-errors.generic'],
        }))
      }
      request.setRequestHeader('Content-Type', 'application/zip') // Header
      request.send(newFile) // Send the request
    },
    [postWithUserErrorException]
  )

  const reset = useCallback(() => {
    setUploadData(DEFAULT_UPLOAD_FILE)
  }, [])

  return {
    uploadData,
    uploadFile,
    processFile,
    processFileUpdate,
    reset,
  }
}
