import { useAtom } from 'jotai'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { config, getFlag } from 'sierra-client/config/global-config'
import { atomWithStorage } from 'sierra-client/state/storage'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const Container = styled.div`
  width: 100%;
  display: flex;

  padding: 8px;
  background-color: ${token('warning/background')};
  color: ${token('warning/foreground')};
`

const HideButton = styled(IconButton).attrs({
  iconId: 'close',
  variant: 'transparent',
})`
  color: currentColor;
`

const organizationWarningBannerDismissedAtom = atomWithStorage<{ dismissedAt?: string }>(
  'organization-warning-banner-dismissed',
  { dismissedAt: undefined },
  z.object({
    dismissedAt: z.string().optional(),
  }),
  { getOnInit: true }
)

const getWarnings = (): string[] => {
  const isSandboxEnabled = getFlag('sandbox')
  const isMuteEnabled = getFlag('mute')
  const isExternalOrganization = config.organization.type === 'external'

  const warnings = []

  if (isSandboxEnabled && isExternalOrganization) {
    warnings.push('Sandbox enabled on external organization')
  }

  if (isMuteEnabled && isExternalOrganization) {
    warnings.push('Notification mute enabled on external organization')
  }

  return warnings
}

/**
 * Intended for displaying important organization-wide warnings that impact
 * normal organization functions to Sana owner users.
 *
 * It can be dismissed, and will not be shown again for the next 24 hours.
 *
 * Examples:
 *  - "Sandbox enabled"
 *  - "All notifications have been disabled"
 */
export const OrganizationWarningBanner: FC = () => {
  const isOwner = useIsOwnerUser()

  const [dismissedState, setDismissedState] = useAtom(organizationWarningBannerDismissedAtom)

  const dismissedRecently =
    dismissedState.dismissedAt !== undefined &&
    DateTime.fromISO(dismissedState.dismissedAt).plus({ hours: 24 }) > DateTime.now()

  if (!isOwner || dismissedRecently) {
    return null
  }

  const warnings = getWarnings()

  if (warnings.length === 0) {
    return null
  }

  return (
    <Container>
      <View grow alignItems='center' justifyContent='center'>
        <View direction='column' alignItems='center'>
          {warnings.map(warning => (
            <Text key={warning} color='currentColor'>
              Warning: {warning}
            </Text>
          ))}
        </View>
      </View>
      <HideButton
        tooltip='Dismiss for 24 hours'
        iconId='close'
        onClick={() => {
          setDismissedState({ dismissedAt: DateTime.now().toISO() })
        }}
      />
    </Container>
  )
}
