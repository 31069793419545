import { Text, TextSize, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import { BorderRadius, FlexAlignItems, Padding, Spacing } from 'sierra-ui/types'
import { StyledCSSWithProps, focusRing } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

type IsSelected = { isSelected?: boolean }
type ReadOnly = { readOnly: boolean }
type HasError = { hasError?: boolean }

/*
  Config
*/
const gap: Spacing = '4'
const textSize: TextSize = 'small'
const textAlignment: FlexAlignItems = 'center'
const backgroundColor: DesignToken = 'surface/default'
const backgroundHoverColor: DesignToken = 'surface/soft'
const borderColor: DesignToken = 'border/default'
const borderHoverColor: DesignToken = 'border/default'
const borderColorError: DesignToken = 'destructive/background'
const borderHoverColorError: DesignToken = 'destructive/background'
const filterMixin = {
  radius: 'size-10' as BorderRadius,
  padding: 'none' as Padding,
}

const focus = css<IsSelected>`
  ${p => p.isSelected === true && focusRing}
`

const readOnly = (applyCss: StyledCSSWithProps<ReadOnly>): StyledCSSWithProps<ReadOnly> => css<ReadOnly>`
  ${p => p.readOnly === true && applyCss}
`

const readOnlyRemoveInteraction = readOnly(css`
  cursor: text;
  user-select: text;
  opacity: 0.85;
`)

const backgroundHover = css`
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${token(backgroundHoverColor)};
  }
`

const borderHover = css<HasError>`
  transition: border-color 200ms ease;

  &:hover {
    border-color: ${p => token(p.hasError === true ? borderHoverColorError : borderHoverColor)};
  }
`

export const FilterGroup = {
  Wrapper: styled(View).attrs({ wrap: 'wrap', alignItems: 'stretch', gap })<ReadOnly>`
    ${readOnlyRemoveInteraction};
    height: auto;
  `,
}

export const Filter = styled(View).attrs({
  alignItems: 'stretch',
  radius: filterMixin.radius,
  gap,
})<IsSelected & ReadOnly>`
  border: 0;
  ${focus};
  ${readOnlyRemoveInteraction};
  flex-wrap: wrap;
`

export const FilterSectionRemove = styled(View).attrs({
  justifyContent: 'flex-end',
  alignItems: 'center',
})`
  border: 0;
  height: 100%;
  color: ${token('foreground/secondary')};
  background-color: transparent;
  background-image: linear-gradient(to right, transparent 5%, ${token(backgroundColor)} 20%);
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  grid-area: value;
`

// THis layer is needed because `surface/soft` has an alpha channel :sad:
export const FilterSectionRemoveHoverLayer = styled(View).attrs({ padding: 'xxsmall' })`
  width: 100%;
  height: 100%;
  &:hover {
    color: ${token('foreground/primary')};
    background-color: ${token(backgroundHoverColor)};
  }
`

export const FilterSection = {
  Wrapper: styled(View).attrs<HasError>(p => ({
    ...filterMixin,
    background: backgroundColor,
    gap: 'none',
    cursor: 'pointer',
    alignItems: 'stretch',
    borderColor: p.hasError === true ? borderColorError : borderColor,
  }))<IsSelected & ReadOnly & HasError>`
    ${focus};
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-template-areas: 'attribute operator value';
    overflow: hidden;
    height: 36px;

    ${borderHover};

    & > div:nth-child(1) {
      grid-area: attribute;
    }

    & > div:nth-child(2) {
      grid-area: operator;
    }

    & > div:nth-child(3) {
      grid-area: value;
    }

    & > div:nth-child(4) {
      grid-area: value;
      pointer-events: none;
    }

    ${FilterSectionRemove} {
      pointer-events: all;
      opacity: 0;
      transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    &:hover ${FilterSectionRemove} {
      opacity: 1;
    }

    ${readOnlyRemoveInteraction};
  `,
}

const UnselectableDiv = styled.div`
  display: inline-flex;
  user-select: none;
`

export const Attribute = {
  Wrapper: styled(View).attrs({ padding: 'xxsmall 10', alignItems: textAlignment })<ReadOnly>`
    ${p =>
      p.readOnly === false &&
      css`
        ${backgroundHover};
        ${borderHover};
      `}

    & ${UnselectableDiv} {
      grid-area: attribute;
    }

    ${readOnlyRemoveInteraction};
  `,
  Text: styled(Text).attrs({ bold: true, size: textSize })``,
}

export const Value = {
  Wrapper: styled(View).attrs({ padding: 'xxsmall 10', alignItems: textAlignment })<ReadOnly>`
    /* Minimal clickable area according to Google, + delete button - minus padding */
    min-width: calc(48px + 40px - 30px);
    ${p =>
      p.readOnly === false &&
      css`
        ${backgroundHover};
        ${borderHover};
      `}

    & ${UnselectableDiv} {
      grid-area: value;
    }

    ${readOnlyRemoveInteraction};
  `,
  Text: styled(Text).attrs({ size: textSize })``,
}

export const Distinction = {
  Wrapper: styled(View).attrs({
    radius: filterMixin.radius,
    cursor: 'pointer',
    alignItems: textAlignment,
    padding: 'none 6',
    background: 'transparent',
  })<ReadOnly>`
    ${p =>
      p.readOnly === false &&
      css`
        ${backgroundHover};
        ${borderHover};
      `}
    ${readOnlyRemoveInteraction};
    border: ${p => p.readOnly === false && `1px solid ${token(borderColor)(p)}`};

    background-color: ${token('surface/default')};
    white-space: nowrap;
  `,
  Text: styled(Text).attrs({ bold: true, size: textSize })`
    user-select: none;
  `,
}

export const Operator = {
  Wrapper: styled(View).attrs({ radius: 'none', padding: 'xxsmall 6' })<ReadOnly>`
    ${p =>
      p.readOnly === false &&
      css`
        ${backgroundHover};
      `};

    ${readOnlyRemoveInteraction};
  `,
  Text: styled(Text).attrs({ size: textSize })`
    user-select: none;
  `,
}
