import { DesktopHelperInternal } from 'sierra-client/views/course-helper/desktop/desktop-helper-internal'
import { Previous } from 'sierra-client/views/course-helper/desktop/previous'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import styled from 'styled-components'

const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 16px 16px 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  pointer-events: none;
`

export const DesktopHelper: React.FC<{
  currentFile: SelfPacedFile
  tutorState: TutorState
}> = ({ currentFile, tutorState }) => {
  return (
    <BottomFixed>
      <Previous />
      <DesktopHelperInternal currentFile={currentFile} tutorState={tutorState} />
    </BottomFixed>
  )
}
