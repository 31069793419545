import { produce } from 'immer'
import { useAtom, useAtomValue } from 'jotai/index'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { userInvitationDomainsAtom } from 'sierra-client/views/manage/components/user-attributes/atoms'
import {
  initialUserAttributesAtom,
  isReadonlyAttributesAtom,
  isReadonlyUserMetadataAtom,
  userAttributesDataAtom,
  userAttributesValidationErrors,
} from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/atoms'
import {
  addOrReplaceAttributes as addOrReplaceAttributesUtil,
  isEmptyValidationErrors,
} from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/utils'
import { equalsAttributeRef } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserInvitationDomains } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminEmailInvitesValidateUserInvitationData,
  XRealtimeAdminUpdateUserAttributes,
} from 'sierra-domain/routes'
import { UserInvitationAttributeData } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { UserInvitationDomainRef } from 'sierra-domain/user-attributes/user-invitation-domain-ref'
import { EmailUserValidateInvitationError } from 'sierra-domain/user-attributes/user-invitation-validation-error'

type UseUserAttributesDraft = {
  readOnlyAttributes: boolean
  readOnlyMetadata: boolean
  hasChanged: boolean
  resetDraft: () => void
  hasValidationErrors: boolean
  validateInvites: (attributeData?: UserInvitationAttributeData) => Promise<boolean>
  sendInvites: (userId: UserId) => Promise<boolean>
  userAttributesDraft: UserInvitationAttributeData
  getAttributeErrors: (ref: UserInvitationDomainRef) => boolean
  addOrReplaceAttributes: (attributes: UserInvitationAttribute[]) => void
  resetAttribute: (ref: UserInvitationDomainRef) => void
}

/*
 * Any attribute which editableInternally !== true should not be sent to the backend
 * */
const normalize = (
  draft: UserInvitationAttributeData,
  customAttributeDomains: UserInvitationDomains | undefined
): UserInvitationAttributeData =>
  produce(draft, draftData => {
    draftData.data = draftData.data.filter(d => {
      const domain = customAttributeDomains?.domains.find(c => equalsAttributeRef(c.ref, d.ref))
      return domain !== undefined && domain.editableInternally === true
    })
  })

export const useUserAttributesDraft = (): UseUserAttributesDraft => {
  const [userAttributesData, setUserAttributesData] = useAtom(userAttributesDataAtom)
  const initialUserAttributes = useAtomValue(initialUserAttributesAtom)
  const readOnlyAttributes = useAtomValue(isReadonlyAttributesAtom)
  const readOnlyMetadata = useAtomValue(isReadonlyUserMetadataAtom)

  const hasChanged = !_.isEqual(userAttributesData, initialUserAttributes)
  const { postWithUserErrorException } = usePost()
  const [validationErrors, setValidationErrors] = useAtom(userAttributesValidationErrors)
  const invitationDomains = useAtomValue(userInvitationDomainsAtom)

  const hasValidationErrors = useMemo(() => !isEmptyValidationErrors(validationErrors), [validationErrors])

  if (initialUserAttributes === undefined) {
    throw new Error('Initial user attributes is empty. Have you called useUserAttributesSettingsLoader?')
  }

  const validateInvites: UseUserAttributesDraft['validateInvites'] = useCallback(
    async attributeData => {
      const validation = await postWithUserErrorException(
        XRealtimeAdminEmailInvitesValidateUserInvitationData,
        {
          invitationDataPerUser: {
            ['validation@sanalabs.com']: attributeData ?? normalize(userAttributesData, invitationDomains),
          },
        }
      )

      const errors: EmailUserValidateInvitationError[] =
        validation.errors === undefined ? [] : (validation.errors['validation@sanalabs.com'] ?? [])
      setValidationErrors(errors)

      return validation.success
    },
    [invitationDomains, postWithUserErrorException, setValidationErrors, userAttributesData]
  )

  const addOrReplaceAttributes: UseUserAttributesDraft['addOrReplaceAttributes'] = useCallback(
    attributes => {
      const updatedDraft = addOrReplaceAttributesUtil(userAttributesData, attributes)
      setUserAttributesData(updatedDraft)

      // Keep checking for errors on every change until none are left
      if (!isEmptyValidationErrors(validationErrors)) {
        void validateInvites(userAttributesData)
      }
    },
    [setUserAttributesData, userAttributesData, validateInvites, validationErrors]
  )

  const resetAttribute = useCallback(
    (ref: UserInvitationDomainRef): void => {
      const updatedDraft = userAttributesData.data.filter(d => !equalsAttributeRef(d.ref, ref))
      setUserAttributesData({ data: updatedDraft })
    },
    [setUserAttributesData, userAttributesData]
  )

  const sendInvites: UseUserAttributesDraft['sendInvites'] = useCallback(
    async userId => {
      const { success } = await postWithUserErrorException(XRealtimeAdminUpdateUserAttributes, {
        userId,
        attributes: normalize(userAttributesData, invitationDomains),
      })

      return success
    },
    [invitationDomains, postWithUserErrorException, userAttributesData]
  )

  const getAttributeErrors: UseUserAttributesDraft['getAttributeErrors'] = useCallback(
    ref => {
      const errorsForRef = validationErrors.filter(e => _.isEqual(e.attribute, ref))
      return errorsForRef.length > 0
    },
    [validationErrors]
  )

  const resetDraft = useCallback((): void => {
    setUserAttributesData(initialUserAttributes)
    setValidationErrors([])
  }, [setValidationErrors, initialUserAttributes, setUserAttributesData])

  return {
    readOnlyAttributes,
    readOnlyMetadata,
    hasChanged,
    resetDraft,
    validateInvites,
    sendInvites,
    getAttributeErrors,
    hasValidationErrors,
    userAttributesDraft: userAttributesData,
    addOrReplaceAttributes,
    resetAttribute,
  }
}
