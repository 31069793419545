import { useCallback } from 'react'
import { IdentitiesSelector } from 'sierra-client/components/common/identities-selector'
import { FetchIdentities, Identity } from 'sierra-client/components/common/identities-selector/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'

export type CourseCollaboratorIdentitiesSelectorProps = {
  selectedIdentities: IdentityWithMetadata[]
  setSelectedIdentities: (update: (identities: IdentityWithMetadata[]) => IdentityWithMetadata[]) => void
  fetchIdentities: FetchIdentities
  type: 'user' | 'user-group'
}

export const CourseCollaboratorIdentitiesSelector: React.FC<CourseCollaboratorIdentitiesSelectorProps> = ({
  selectedIdentities,
  setSelectedIdentities,
  fetchIdentities,
  type,
}) => {
  const onSelect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities => [...selectedIdentities, identity])
    },
    [setSelectedIdentities]
  )

  const onUnselect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities =>
        selectedIdentities.filter(i => i.identity.id !== identity.id)
      )
    },
    [setSelectedIdentities]
  )

  const selectedIdentitiesWithId = useDeepEqualityMemo(
    selectedIdentities.map(identity => ({ ...identity, id: identity.identity.id }))
  )

  return (
    <IdentitiesSelector
      fetchIdentities={fetchIdentities}
      selectedIdentities={selectedIdentitiesWithId}
      fetchOptions={{ cacheKey: 'course-settings-collaborator-identities-' + type }}
      onSelect={onSelect}
      onUnselect={onUnselect}
      placeholder='dictionary.search'
      grow
    />
  )
}
