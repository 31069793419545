import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { getFlag } from 'sierra-client/config/global-config'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import {
  GranularVisibilityState,
  OrganizationClusterVisibilitySettings,
  useOrganizationCluster,
  visibilityPatch,
} from 'sierra-client/features/multi-tenancy'
import {
  ContentSkillSelectionWithRecommendation,
  unassignSkillsFromContentMutation,
  useAssignSkillsToContentMutation,
  type ContentSkill,
} from 'sierra-client/features/skills'
import { useIsVerticalScrollbarVisible } from 'sierra-client/hooks/use-is-scrollbar-visible'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { subscribeSse, useCachedQuery } from 'sierra-client/state/api'
import * as actions from 'sierra-client/state/author-course-settings/actions'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectors as settingsSelectors } from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCoursePermissionSettings } from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { CourseTagsSelector } from 'sierra-client/views/flexible-content/editor/topbar/course-tags-selector'
import { SmartCardsGenerator } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/smart-cards-generator'
import { Card } from 'sierra-client/views/showcase/sections/lil-stack'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { Tag } from 'sierra-domain/api/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import {
  XRealtimeAuthorCourseContentLength,
  XRealtimeAuthorExtractFirstImageInContent,
} from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateDescription } from 'sierra-domain/routes-sse'
import { assertNever, iife, isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { Button, InputPrimitive, ScrollView, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useOnChanged } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const ThumbnailImageContainer = styled.div`
  background-color: transparent;
  position: relative;
  height: 72px;
  max-width: 112px;
  min-width: 112px;
`

const ThumbnailImg = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-block: var(--publish_modal-padding);
  height: 100svh;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --_bottom-space: 12px;
    height: auto;
    max-height: calc(100svh - var(--_bottom-space) - var(--publish_modal-top));
  }
`

const FormScrollView = styled(ScrollView)`
  gap: 24px;
  padding-inline: var(--publish_modal-padding) var(--publish_modal-padding--scroll);
  padding-block-end: 24px;

  scrollbar-gutter: stable;
`

const TopContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex-direction: row;
    align-items: center;
  }
`

const ButtonGroup = styled.footer<{ elevated: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-block-start: auto;
  padding-inline: var(--publish_modal-padding);
  padding-block-start: calc(var(--publish_modal-padding) * 0.6);

  ${p =>
    p.elevated &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: var(--publish_modal-padding);
        right: var(--publish_modal-padding);
        border-top: 1px solid ${token('border/default')};
      }
    `}
`

const DescriptionWrapper = styled.div`
  position: relative;
`

const GenerateButton = styled(Button)`
  position: absolute;
  bottom: 12px;
  left: 8px;
`

export type InitialPublishFormProps = {
  onModalClose: () => void
  publish: (granularVisibility: GranularVisibilityState) => Promise<void>
  selfPacedContentId: CourseId
  title: string
}

export const InitialPublishForm: FC<InitialPublishFormProps> = ({
  onModalClose,
  publish,
  selfPacedContentId,
  title,
}) => {
  const { t } = useTranslation()
  const notification = useNotif()

  const clusterState = useOrganizationCluster()
  const isClusterMember = !clusterState.loading && clusterState.cluster.length > 0
  const clusterOrganizations = useMemo(() => {
    return clusterState.loading ? [] : clusterState.cluster
  }, [clusterState])

  const manageSkillsAllowed = useHasOrganizationPermission('MANAGE_SKILLS')
  const skillsEnabled = manageSkillsAllowed && !isClusterMember

  const contentId = `course:${selfPacedContentId}`

  const draftSettings = useSelector(settingsSelectors.selectDraftSettings)
  const { coursePermissionSettings, updateVisibilityInOrg } = useCoursePermissionSettings()

  const [contentTitle, setContentTitle] = useState(title)
  const [contentDescription, setContentDescription] = useState(draftSettings?.description ?? '')

  const [contentImage, setContentImage] = useState<ImageUnion | undefined>(draftSettings?.image ?? undefined)

  const [smartCards, setSmartCards] = useState<Card[] | undefined>(
    draftSettings?.nativeCourseSettings?.smartCards?.map(card => ({
      id: card.id,
      title: card.title,
      description: card.description,
      image: card.image,
      theme: card.theme?.name,
      sourceCourseId: card.sourceCourseId,
    }))
  )

  const [selectedTags, setSelectedTags] = useState<Tag[]>(draftSettings?.tags ?? [])

  const [rootVisibility, setRootVisibility] = useState<CourseVisibilityInOrg>()
  const [granularVisibility, setGranularVisibility] = useState<GranularVisibilityState>({
    parent: undefined,
    distributions: {},
  })

  // Update the root visibility when the course metadata is loaded
  useOnChanged(() => {
    if (coursePermissionSettings.status === 'done' && rootVisibility === undefined) {
      setRootVisibility(coursePermissionSettings.visibilityInOrg)
    }
  }, coursePermissionSettings)

  // Update the granular visibility as the root visibility changes
  useEffect(() => {
    setGranularVisibility(currentGranularVisibility => {
      const patch = iife(() => {
        if (rootVisibility === undefined) {
          return
        }

        switch (rootVisibility) {
          case 'private': {
            return visibilityPatch(currentGranularVisibility, clusterOrganizations, 'private')
          }
          case 'visible-admins': {
            return visibilityPatch(currentGranularVisibility, clusterOrganizations, 'visible-admins')
          }
          case 'visible-everyone': {
            return visibilityPatch(currentGranularVisibility, clusterOrganizations, 'visible-everyone')
          }
          case 'separate-visibility': {
            return visibilityPatch(currentGranularVisibility, clusterOrganizations, 'visible-admins')
          }
          default:
            return assertNever(rootVisibility)
        }
      })

      if (isDefined(patch)) {
        return patch
      } else {
        return currentGranularVisibility
      }
    })
  }, [rootVisibility, clusterOrganizations])

  const [contentTitleError, setContentTitleError] = useState<string | undefined>(undefined)

  const [isGenerating, setIsGenerating] = useState(false)
  const [generateAttempt, setGenerateAttempt] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const contentLength = useCachedQuery(XRealtimeAuthorCourseContentLength, { contentId: selfPacedContentId })
    .data?.length

  const disableDescriptionGeneration =
    contentDescription.length > 0 || isGenerating || (contentLength !== undefined && contentLength < 500)

  const imageFromContent = useCachedQuery(
    XRealtimeAuthorExtractFirstImageInContent,
    { contentId: selfPacedContentId },
    { enabled: contentImage === undefined }
  ).data?.image

  const dispatch = useDispatch()

  const [skillsToAssign, setSkillsToAssign] = useState<ContentSkill[]>([])
  const [skillIdsToUnassign, setSkillIdsToUnassign] = useState<SkillId[]>([])

  const courseVisibilityDropdownAllowedItems = useCourseVisibilityDropdownAllowedItems(selfPacedContentId, {
    privateEnabled: Object.values(granularVisibility.distributions).length === 0,
    privateAvailable: true,
    separateVisibilityEnabled: getFlag('multi-tenancy/granular-visibility'),
  })

  const updateCourseTitle = async (): Promise<void> => {
    await dispatch(
      settingsActions.setCourseTitle({
        title: contentTitle,
        courseId: selfPacedContentId,
      })
    )
  }

  const { mutateAsync: assignSkillsToContentMutation } = useAssignSkillsToContentMutation(contentId)

  const { mutate: unassignSkillsMutation } = useMutation({
    mutationFn: ({ skillIds }: { skillIds: SkillId[] }) => {
      return graphQuery(unassignSkillsFromContentMutation, {
        contentIds: [contentId],
        skillIds: skillIds,
      })
    },
    onError() {
      notification.push({ type: 'error' })
    },
  })

  const handleSaveSettingsAndPublish = async (): Promise<void> => {
    dispatch(
      actions.updateCourseSettings({
        description: contentDescription,
        tags: selectedTags,
        title: contentTitle,
        image: contentImage,
        nativeCourseSettings: {
          ...draftSettings?.nativeCourseSettings,
          smartCards: smartCards?.map(card => ({
            id: card.id,
            title: card.title,
            description: card.description,
            image: card.image,
            theme: card.theme !== undefined ? { type: 'preset', name: card.theme } : undefined,
            sourceCourseId: card.sourceCourseId,
          })),
        },
      })
    )
    await updateCourseTitle()
    await dispatch(settingsActions.saveSettings({ notify: true }))

    await publish(granularVisibility)

    if (isNonEmptyArray(skillIdsToUnassign)) {
      unassignSkillsMutation({ skillIds: skillIdsToUnassign })
    }

    if (isNonEmptyArray(skillsToAssign)) {
      await assignSkillsToContentMutation(
        {
          contentId,
          skills: skillsToAssign.map(skill => ({
            skillId: skill.id,
            skillLevelSettingId: skill.levelSettingId,
          })),
        },
        {
          onError() {
            notification.push({ type: 'error' })
          },
        }
      )
    }
  }

  const handleGenerateDescription = async (): Promise<void> => {
    setContentDescription('')
    setIsGenerating(true)
    setGenerateAttempt(prev => prev + 1)
    await subscribeSse({
      route: SSEXRealtimeAuthorGenerateDescription,
      input: { courseId: selfPacedContentId },
      onError: e => console.error(e),
      onMessage: event => {
        setContentDescription(prev => prev.concat(event.data.text))
      },
    })
    setIsGenerating(false)
    void dispatch(
      generativeFeatureUsed({
        contentId: selfPacedContentId,
        contentType: 'self-paced',
        generativeFeature: 'generate-course-description',
      })
    )
  }

  const handleSkillAddOrUpdate = (skill: ContentSkill): void => {
    const newSkillsToAssign = skillsToAssign.filter(s => s.id !== skill.id)
    setSkillsToAssign([...newSkillsToAssign, skill])
  }

  const { setScrollviewRef, isScrollbarVisible } = useIsVerticalScrollbarVisible()

  const [showImageModal, setShowImageModal] = useState(false)

  function handleUpload(image: ImageUnion): void {
    setContentImage(image)
    setShowImageModal(false)
  }

  const onVisibilityChange = useCallback((newVisibility: CourseVisibilityInOrg) => {
    setRootVisibility(newVisibility)
  }, [])

  const assetContext = { type: 'course' as const, courseId: selfPacedContentId }
  const thumbnailImageSrc = useResolveAsset({
    assetContext,
    image: contentImage ?? imageFromContent,
    size: 'default',
  })

  return (
    <Form
      onSubmit={async event => {
        event.preventDefault()

        if (contentTitle.trim() === '') {
          setContentTitleError(t('author.publish-popup.error'))
          return
        }

        setIsSubmitting(true)

        if (isDefined(rootVisibility) && isDefined(granularVisibility.parent)) {
          await updateVisibilityInOrg(selfPacedContentId, granularVisibility.parent)
        }

        await handleSaveSettingsAndPublish()

        setIsSubmitting(false)
      }}
    >
      <FormScrollView ref={setScrollviewRef}>
        <TopContainer>
          <ThumbnailImageContainer>
            {showImageModal && (
              <UploadImageModal
                open={showImageModal}
                onUploadDone={image => handleUpload(image)}
                onClose={() => setShowImageModal(false)}
                assetContext={assetContext}
              />
            )}
            <ThumbnailImg
              aria-label='course-image-picker'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setShowImageModal(true)
              }}
              src={thumbnailImageSrc}
            />
          </ThumbnailImageContainer>

          <View direction='column' gap='4'>
            <Text bold size='regular'>
              {t('author.publish-popup.title')}
            </Text>
            <Text size='regular' color='foreground/muted'>
              {t('author.publish-popup.sub-title')}
            </Text>
          </View>
        </TopContainer>
        <View direction='column' gap='24'>
          <FormElement label={t('dictionary.title')} helper={contentTitleError}>
            <InputPrimitive
              value={contentTitle}
              onChange={event => {
                setContentTitle(event.target.value)
                setContentTitleError(undefined)
              }}
              placeholder={t('teamspaces.add-name-placeholder')}
              required
            />
          </FormElement>

          <FormElement
            label={
              <View justifyContent='space-between'>
                {t('dictionary.description')}
                <Text color='grey25'>{t('input.optional')}</Text>
              </View>
            }
          >
            <DescriptionWrapper>
              <TextAreaPrimitive
                value={contentDescription}
                onChange={event => {
                  setContentDescription(event.target.value)
                }}
                placeholder={t('event-groups.add-a-description')}
                rows={4}
                autoExpand
              />
              {!disableDescriptionGeneration && (
                <GenerateButton
                  type='button'
                  variant='ghost'
                  loading={isGenerating}
                  icon='glitter'
                  onClick={handleGenerateDescription}
                  disabled={isGenerating}
                >
                  {generateAttempt > 0
                    ? t('author.generate-from-doc.re-generate')
                    : t('author.slate.generate')}
                </GenerateButton>
              )}
            </DescriptionWrapper>
          </FormElement>
        </View>

        {clusterState.loading ? null : (
          <>
            <PermissionFormSection
              title={t('share.visibility-permission.title')}
              subtitle={t('share.visibility-permission.subtitle')}
            >
              {isDefined(rootVisibility) && (
                <CourseVisibilityDropdown
                  selectedVisibility={rootVisibility}
                  onSelect={onVisibilityChange}
                  options={courseVisibilityDropdownAllowedItems}
                />
              )}
            </PermissionFormSection>
            {isDefined(rootVisibility) && granularVisibility.parent !== undefined && (
              <OrganizationClusterVisibilitySettings
                courseId={selfPacedContentId}
                rootVisibility={rootVisibility}
                granularVisibility={granularVisibility}
                onVisibilityChanges={updatedGranularVisibility => {
                  setGranularVisibility(updatedGranularVisibility)
                }}
              />
            )}
          </>
        )}

        {isDefined(rootVisibility) && rootVisibility !== 'private' && (
          <FormElement
            label={
              <View justifyContent='space-between'>
                {t('table.skills')}
                <Text color='foreground/muted'>{t('input.optional')}</Text>
              </View>
            }
          >
            <CourseTagsSelector selectedTags={selectedTags} onChange={setSelectedTags} />
          </FormElement>
        )}

        {isDefined(rootVisibility) && rootVisibility === 'visible-everyone' && skillsEnabled && (
          <View direction='column' gap='12'>
            <View direction='column' gap='2'>
              <View justifyContent='space-between'>
                <Text bold>{_.capitalize(t('dictionary.skills'))}</Text>
                <Text color='foreground/muted' bold>
                  {t('input.optional')}
                </Text>
              </View>
              <Text color='foreground/muted'>{t('skills.publish-modal.description')}</Text>
            </View>

            <ContentSkillSelectionWithRecommendation
              courseId={selfPacedContentId}
              onSkillRemove={skill => {
                if (!skillsToAssign.some(s => s.id === skill.id)) {
                  setSkillIdsToUnassign(prev => [...prev, skill.id])
                } else {
                  setSkillsToAssign(prev => prev.filter(s => s.id !== skill.id))
                }
              }}
              onSkillAdd={skill => {
                handleSkillAddOrUpdate(skill)
                if (skillIdsToUnassign.includes(skill.id)) {
                  setSkillIdsToUnassign(prev => prev.filter(skillId => skillId !== skill.id))
                }
              }}
              onSkillLevelChange={handleSkillAddOrUpdate}
              onSkillRecommendationAccepted={handleSkillAddOrUpdate}
            />
          </View>
        )}

        <SmartCardsGenerator
          smartCards={smartCards}
          setSmartCards={setSmartCards}
          closeModal={onModalClose}
          selfPacedContentId={selfPacedContentId}
          layout='standard'
          assetContext={assetContext}
        />
      </FormScrollView>

      <ButtonGroup elevated={isScrollbarVisible}>
        <Button variant='secondary' type='button' disabled={isSubmitting} onClick={onModalClose}>
          {t('dictionary.cancel')}
        </Button>
        <Button type='submit' loading={isSubmitting}>
          {t('author.save-and-publish')}
        </Button>
      </ButtonGroup>
    </Form>
  )
}
