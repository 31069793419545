import React from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { UserId } from 'sierra-domain/api/uuid'
import { RoundAvatar } from 'sierra-ui/components'

export const Avatar: React.FC<{ userId?: UserId }> = ({ userId }) => {
  const myUser = useSelector(selectUser)
  const otherUser = useUserLegacy(userId)
  const user = userId === undefined ? myUser : otherUser

  return (
    <div style={{ marginTop: '6px' }}>
      <RoundAvatar
        size='small'
        firstName={user?.firstName}
        lastName={user?.lastName}
        src={getAvatarImage(user?.uuid, user?.avatar)}
        color={user?.avatarColor}
      />
    </div>
  )
}
