import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { RecurrencePeriodDialog } from 'sierra-client/components/recurrent-assignments/recurrence-dialog'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { UpdateCourseGroupModal } from 'sierra-client/views/manage/courses/course-groups/modals/update-course-group-modal'
import { UseCourseGroupDetailsData } from 'sierra-client/views/manage/courses/use-manage-course-group-details'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { DueDate, Interval } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'

export type CourseGroupModalActions =
  | (UserModalActionsProps['action'] & { targetUserId: UserId })
  | {
      modal: 'delete'
    }
  | {
      modal: 'unassign-users'
      userIds: UserId[]
    }
  | {
      modal: 'enroll-to-group' | 'enroll-to-edition'
      isDefaultRequiredAssignment: boolean
      courseId: string
    }
  | {
      modal: 'detach-edition'
      courseEditionId: string
    }
  | {
      modal: 'update-course-group'
    }
  | {
      modal: 'set-direct-due-date-course-group'
      isDirectRecurrentAssignment: boolean
      currentDirectDueDate?: DueDate
      userId: UserId
    }
  | {
      modal: 'set-direct-recurrence-course-group'
      userId: UserId
      currentDirectRecurrence: Interval | undefined
    }

export type CourseGroupActionModalControl = {
  open: (modalState: CourseGroupModalActions | undefined) => void
  close: () => void
  state: CourseGroupModalActions | undefined
}

export const useCourseGroupActionModals = (): CourseGroupActionModalControl => {
  const [action, setAction] = useState<CourseGroupModalActions | undefined>()

  return {
    open: setAction,
    close: () => setAction(undefined),
    state: action,
  }
}

type Props = {
  control: CourseGroupActionModalControl
  courseGroupId: CourseId
  courseGroupDetails: UseCourseGroupDetailsData
}

export const CourseGroupActionModals: React.FC<Props> = ({
  control: { state, close },
  courseGroupId,
  courseGroupDetails,
}) => {
  const {
    fetchCourseGroup,
    fetchUsersData,
    courseEditionsData,
    deleteCourseGroup,
    unassignUsers,
    detachEdition,
  } = courseGroupDetails

  const notifications = useNotif()
  const { setUsersDueDate, setUsersRecurrence, assignWithDueDate } = useDueDate()
  const dispatch = useDispatch()

  const detachCourseEdition = useCallback(
    async (editionId: string) => {
      if (courseEditionsData.length <= 2) {
        await deleteCourseGroup() // Remove all editions and delete group
        void getGlobalRouter().navigate({ to: '/manage/content' }) // Go back to content
      } else {
        await detachEdition(editionId)
        await fetchCourseGroup() // refresh
      }
    },
    [courseEditionsData, detachEdition, deleteCourseGroup, fetchCourseGroup]
  )

  return (
    <>
      {(state?.modal === 'enroll-to-group' || state?.modal === 'enroll-to-edition') && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'course',
            panes: 'user-and-user-group',
            activePane: 'user',
            showDueDates: true,
            subjectsSupportAssignmentSettings: false,
            autoAssignmentAvailable: false,
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment(
                  [
                    {
                      id: state.courseId,
                      type: 'course',
                      isDefaultRequiredAssignment: state.isDefaultRequiredAssignment,
                    },
                  ],
                  selections
                )
              )
              if (result?.error !== undefined) return

              selections.forEach(selection => {
                void dispatch(
                  assignmentPriorityLogger({
                    contentType: 'course',
                    assignmentPriority: selection.assignmentPriority,
                    hasDueDate: selection.dueDate !== undefined,
                    contentId: state.courseId,
                    userId: selection.id,
                  })
                )
              })

              notifications.push({ type: 'assigned' })
              close()
              void Promise.all([fetchCourseGroup(), fetchUsersData()])
            },
          }}
          subjects={state.courseId}
          title={
            state.modal === 'enroll-to-edition'
              ? t('manage.course-groups.assignments.assign-to-edition')
              : t('manage.course-groups.assignments.assign-to-group')
          }
          onClose={() => {
            close()
          }}
        />
      )}
      {state?.modal === 'unassign-users' && (
        <ActionModal
          open
          onClose={close}
          primaryAction={async (): Promise<void> => {
            await unassignUsers(state.userIds)
            close()
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', { count: state.userIds.length })}
          deleteAction
        />
      )}
      <ActionModal
        title={t('course-editions.create.delete.title')}
        open={state?.modal === 'delete'}
        onClose={close}
        deleteAction
        primaryActionLabel={t('course-editions.create.delete.title')}
        primaryAction={async () => {
          await deleteCourseGroup()
          close()
        }}
      >
        {t('manage.course-groups.delete-group.confirm-body')}
      </ActionModal>
      <ActionModal
        title={t('manage.course-groups.detach-edition.title')}
        open={state?.modal === 'detach-edition'}
        onClose={close}
        primaryActionLabel={t('manage.course-groups.detach-edition.confirm-action')}
        primaryAction={async () => {
          const editionId = state?.modal === 'detach-edition' ? state.courseEditionId : undefined

          if (editionId === undefined) return

          await detachCourseEdition(editionId)
        }}
      >
        {t('manage.course-groups.detach-edition.confirm-body')}
      </ActionModal>
      <NewCourseSettingsModal onSave={fetchCourseGroup} />
      {state?.modal === 'set-direct-due-date-course-group' && (
        <DueDateDialog
          open={true}
          value={state.currentDirectDueDate}
          fallbackDueDateType={state.isDirectRecurrentAssignment ? 'relative' : 'absolute'}
          onChange={async value => {
            // Set due date only on the group since the edition is not technically assigned
            const courseGroupAssignment = {
              dueDate: value,
              content: { type: 'course' as const, id: courseGroupId },
            }
            await setUsersDueDate([state.userId], [courseGroupAssignment])
            await Promise.all([fetchCourseGroup(), fetchUsersData()])

            close()
          }}
          onClose={close}
        />
      )}
      {state?.modal === 'set-direct-recurrence-course-group' && (
        <RecurrencePeriodDialog
          open={true}
          value={state.currentDirectRecurrence}
          // Set recurrence only on the group since the edition is not technically assigned
          onSave={async newRecurrence => {
            const courseGroupAssignment = {
              content: { type: 'course' as const, id: courseGroupId },
              recurrence: newRecurrence,
            }
            await setUsersRecurrence([state.userId], [courseGroupAssignment])
            await Promise.all([fetchCourseGroup(), fetchUsersData()])
            close()
          }}
          onClear={async () => {
            const courseGroupAssignment = {
              content: { type: 'course' as const, id: courseGroupId },
              recurrence: undefined,
            }
            await setUsersRecurrence([state.userId], [courseGroupAssignment])
            await Promise.all([fetchCourseGroup(), fetchUsersData()])
            close()
          }}
          onClose={close}
        />
      )}
      <UpdateCourseGroupModal
        courseGroupId={courseGroupId}
        open={state?.modal === 'update-course-group'}
        onClose={close}
        onSave={fetchCourseGroup}
      />

      {state && 'targetUserId' in state && (
        <UserModalActions
          targetUserId={state.targetUserId}
          action={state}
          onClose={close}
          onDone={() => {
            void Promise.all([fetchCourseGroup(), fetchUsersData()])
          }}
        />
      )}
    </>
  )
}
