import { useQuery } from '@tanstack/react-query'
import React, { FC, ReactNode } from 'react'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { config } from 'sierra-client/config/global-config'
import { isFurtherAuthenticationRequired } from 'sierra-client/config/use-is-further-authentication-required'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { Authenticate } from 'sierra-client/views/authentication/flows/authenticate'
import { ExternalAuthenticate } from 'sierra-client/views/authentication/flows/external-authenticate'

type Options = {
  allowScorm?: boolean
  allowGuestAccess?: boolean
}

type Props = {
  children?: ReactNode
} & Options

const NativeRequireLoggedIn: FC<Props> = ({ children, allowScorm = false, allowGuestAccess = false }) => {
  if (config.scorm.isScorm && !allowScorm) throw new Error('SCORM is not supported for this location')

  const { data: authStatus, isPending: authIsPending } = useQuery(getAuthClient().authQuery)
  const isLoggedIn = authStatus?.status === 'ok'

  const { data: userData, isPending: userIsPending } = useQuery({
    ...currentUserQuery,
    enabled: isLoggedIn,
    select: user => ({
      isGuestUser: user.isGuestUser,
      isFurtherAuthRequired: isFurtherAuthenticationRequired(user),
    }),
  })

  const waitingForUser = isLoggedIn && userIsPending
  const authenticationRequired = !isLoggedIn || userData?.isFurtherAuthRequired !== false

  if (authIsPending || waitingForUser)
    return (
      <SanaPage mode='dark' page={PageIdentifier.Empty({ message: 'Initializing NativeRequiredLoggedIn' })} />
    )

  if (userData?.isGuestUser === true && !allowGuestAccess)
    throw new Error('Guest access not supported for this location')

  return authenticationRequired ? (
    <SanaPage mode='dark' page={PageIdentifier.AuthenticationLogin()}>
      {config.scorm.isScormNative ? <ExternalAuthenticate /> : <Authenticate />}
    </SanaPage>
  ) : (
    <>{children}</>
  )
}

export const requireLoggedIn =
  <T extends Record<string, unknown>>(
    Component: React.ComponentType<T>,
    options: Options = {}
  ): React.ComponentType<T> =>
  (props1: T) => {
    return (
      <NativeRequireLoggedIn {...options}>
        <Component {...props1} />
      </NativeRequireLoggedIn>
    )
  }
