import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { courseGenerationButtonClicked } from 'sierra-client/features/course-generation/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { CourseGenCloseButton } from 'sierra-client/views/flexible-content/generate-from-file-modal/course-gen-close-button'
import { GenerateWithVision } from 'sierra-client/views/flexible-content/generate-with-vision/generate-with-vision'
import { MAX_PDF_SIZE_MB, usePdfData } from 'sierra-client/views/flexible-content/use-pdf-convert'
import { CourseGenPdfUploader } from 'sierra-client/views/v3-author/common/media-uploader/pdf-uploader'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { XRealtimeAuthorPdfImportListUserPdfs } from 'sierra-domain/routes'
import { Modal } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const HelpCenterLink = styled(RouterLink)`
  font-weight: 500;
`

type State = { type: 'upload-pdf' } | { type: 'pdf-uploaded'; pdfId: NanoId12 }

export const GenerateWithVisionModal: React.FC<{
  open: boolean
  onClose: () => void
  assetContext: AssetContext
}> = ({ open, onClose, assetContext }) => {
  const { t } = useTranslation()
  const abortControllerRef = useRef<AbortController>()
  const { uploadPdfFile } = usePdfData()

  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(courseGenerationButtonClicked())
  }, [dispatch])

  const handleOnClose = useCallback(() => {
    abortControllerRef.current?.abort()
    onClose()
  }, [onClose])

  const [state, setState] = useState<State>({ type: 'upload-pdf' })

  const pdfList = useCachedQuery(XRealtimeAuthorPdfImportListUserPdfs, {})

  return (
    <Modal
      size={state.type === 'upload-pdf' ? { width: 476, height: 560 } : 'full-screen'}
      open={open}
      onClose={handleOnClose}
      disableScrollbarGutter
    >
      <View gap='16' wrap='wrap' padding='32' paddingBottom='none'>
        <Button
          variant='primary'
          onClick={() => {
            setState({ type: 'upload-pdf' })
          }}
        >
          Reset PDF
        </Button>
        {pdfList.data
          ?.filter(pdf => NanoId12.safeParse(pdf.pdfId).success)
          .slice(0, 5)
          .map(pdf => (
            <Button
              key={pdf.pdfId}
              variant='ghost'
              onClick={() => {
                setState({ type: 'pdf-uploaded', pdfId: NanoId12.parse(pdf.pdfId) })
              }}
            >
              {pdf.filename}
            </Button>
          ))}
      </View>
      {state.type === 'upload-pdf' ? (
        <CourseGenPdfUploader
          uploadFile={uploadPdfFile}
          maxFileSizeMb={MAX_PDF_SIZE_MB}
          startGenerating={result => {
            setState({ type: 'pdf-uploaded', pdfId: result.pdfId })
          }}
          title={t('author.generate-from-doc.upload-modal.title')}
          description={t('author.generate-from-doc.upload-modal.description')}
          disclaimer={
            <Trans
              i18nKey={'author.generate-from-doc.upload-modal.disclaimer' satisfies TranslationKey}
              components={{
                bold: <HelpCenterLink href={'https://help.sana.ai/en/articles/104553-generate-from-file'} />,
              }}
            />
          }
          assetContext={assetContext}
        />
      ) : (
        <GenerateWithVision pdfId={state.pdfId} />
      )}
      <CourseGenCloseButton onClick={handleOnClose} />
    </Modal>
  )
}
