import { useCallback } from 'react'
import { BoundingBox } from 'sierra-client/domain/commenting/types'
import { iife } from 'sierra-domain/utils'
import { ColorBuilder } from 'sierra-ui/color'
import { TransientProps } from 'sierra-ui/types'
import { unit } from 'sierra-ui/utils'
import styled from 'styled-components'

const LayerContainer = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`

type TextHighlightRectangleProps = TransientProps<BoundingBox> & {
  color: string
}

const TextHighlightRectangle = styled.div.attrs<TextHighlightRectangleProps>(p => ({
  style: {
    // Note: These values can change often, so we set them directly here instead of in the template block.
    // Otherwise we'll end up generating very many classnames.
    width: unit(p.$width),
    height: unit(p.$height),
    transform: `translate(${unit(p.$x)}, ${unit(p.$y)})`,
  },
}))<TextHighlightRectangleProps>`
  transition: background-color 90ms;
  position: absolute;
  background-color: ${p => p.color};
`

export type InlineTextIndicator = {
  id: string
  opacity: number
  rectangles: DOMRect[]
}

type RangesLayerProps = {
  color: ColorBuilder
  indicator: InlineTextIndicator
  firstIndicatorRef?: React.MutableRefObject<HTMLDivElement | null> | ((el: HTMLDivElement | null) => void)
  lastIndicatorRef?: React.MutableRefObject<HTMLDivElement | null> | ((el: HTMLDivElement | null) => void)
}

export const TextHighlightsLayer: React.FC<RangesLayerProps> = ({
  color,
  indicator,
  firstIndicatorRef,
  lastIndicatorRef,
}) => {
  const padding = 1.5

  const combinedFirstAndLastRefs = useCallback(
    (el: HTMLDivElement | null) => {
      if (firstIndicatorRef !== undefined) {
        if (typeof firstIndicatorRef === 'function') {
          firstIndicatorRef(el)
        } else {
          firstIndicatorRef.current = el
        }
      }
      if (lastIndicatorRef !== undefined) {
        if (typeof lastIndicatorRef === 'function') {
          lastIndicatorRef(el)
        } else {
          lastIndicatorRef.current = el
        }
      }
    },
    [firstIndicatorRef, lastIndicatorRef]
  )

  return (
    <LayerContainer>
      {indicator.rectangles.map((rect, rectangleIndex) => (
        <TextHighlightRectangle
          ref={iife(() => {
            const isFirstRect = rectangleIndex === 0
            const isLastRect = rectangleIndex === indicator.rectangles.length - 1

            if (isFirstRect && isLastRect) {
              return combinedFirstAndLastRefs
            } else if (isFirstRect) {
              return firstIndicatorRef
            } else if (isLastRect) {
              return lastIndicatorRef
            } else {
              return undefined
            }
          })}
          key={`${indicator.id}-${rectangleIndex}`}
          $x={rect.x}
          $y={rect.y - padding}
          $width={rect.width}
          $height={rect.height + 2 * padding}
          color={color.opacity(indicator.opacity).shift(0.1).toString()}
        />
      ))}
    </LayerContainer>
  )
}
