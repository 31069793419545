import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useMemo, useRef } from 'react'
import { useIsGuestUser } from 'sierra-client/api/hooks/use-user'
import { Divider } from 'sierra-client/components/liveV2/right-sidebar/components'
import { config } from 'sierra-client/config/global-config'
import { useIsFurtherAuthenticationRequired } from 'sierra-client/config/use-is-further-authentication-required'
import {
  CollapsableCreateNewButtonViewer,
  CollapsableSidebar,
  Header as CollapsableSidebarHeader,
  MobileTopbar,
  sidebarVersionAtom,
  useInitialSidebarStateReady,
  useUpdateInitialSidebarState,
} from 'sierra-client/features/collapsable-sidebar'
import { collapsableSidebarBooleanStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { useShouldHideOldSidebarIcon } from 'sierra-client/features/collapsable-sidebar/hooks/use-mobile-topbar-shown'
import { Fade } from 'sierra-client/features/collapsable-sidebar/items/shared/fade'
import { MobileSidebar } from 'sierra-client/features/collapsable-sidebar/mobile-sidebar'
import {
  GlobalSidebarIdAtom,
  GlobalSidebarOpenAtom,
  appMainContainerAtom,
} from 'sierra-client/features/global-sidebar/atoms'
import {
  BottomSidebarViewer,
  NestedSidebarButtonViewer,
  NestedSidebarViewer,
  nestedSidebarContext,
} from 'sierra-client/features/global-sidebar/components/nested-sidebar'
import { SidebarShortcut } from 'sierra-client/features/global-sidebar/components/shortcut-component'
import { SidebarCourseNavigation } from 'sierra-client/features/global-sidebar/components/sidebar-course-navigation'
import { SidebarFoldingColumn } from 'sierra-client/features/global-sidebar/components/sidebar-folding-column'
import { SidebarMainNavigation } from 'sierra-client/features/global-sidebar/components/sidebar-navigation'
import { useIsSidebarNavigationAllowed } from 'sierra-client/features/global-sidebar/hooks/use-is-sidebar-navigation-allowed'
import { SidebarLocation } from 'sierra-client/features/global-sidebar/types'
import { RouteMatchItem, useActiveRouteMatch } from 'sierra-client/hooks/router/use-active-route'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useSafeRouterSelfPacedFlexibleContentId } from 'sierra-client/hooks/use-router-ids'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { FCC } from 'sierra-client/types'
import { GlobalAssistantComponent } from 'sierra-client/views/global-assistant'
import { hideGlobalAssistantAtom } from 'sierra-client/views/global-assistant/atoms'
import {
  speakerNotesExtraSidebarWidth,
  speakerNotesSidebarWidthAtom,
} from 'sierra-client/views/liveV2/speaker-notes-sidebar-width-atom'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'
import { Column, ColumnContainer } from 'sierra-ui/components'
import { IconButton, Spacer, View } from 'sierra-ui/primitives'
import { spacing, token, zIndex } from 'sierra-ui/theming'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${token('border/strong')};
    border-radius: 12px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  body {
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
  }
`

const SidebarContainer = styled(View)`
  position: relative;
`

const SidebarButtonContainer = styled.div`
  position: fixed;
  z-index: ${zIndex.ON_MODAL};
  padding: 20px ${spacing[16]} ${spacing[16]};
`
const OpenSidebarButton = styled(IconButton).attrs({
  iconId: 'open-sidebar',
  variant: 'transparent',
  size: 'small',
  color: 'foreground/muted',
})``

const StyledColumn = styled(Column)`
  flex-shrink: 1;
`

const Line = styled.hr`
  flex: 0 0 1px;
  height: 1px;
  margin: 0px;
  transition: background-color 100ms ease;
  background-color: ${token('border/default')};
  margin-top: 24px;
  margin-bottom: 24px;
`

const HeaderContainer = styled(View)`
  background-color: ${token('surface/default')};
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
`

const parseSplatCourseId = (_splatParam: string | undefined): CourseId | undefined => {
  return CourseId.safeParse(_splatParam).data
}

function getSidebarLocationByRoute(route: RouteMatchItem): SidebarLocation {
  const { routeId, pathname, params, search } = route
  const searchStr = new URLSearchParams(search as Record<string, string>).toString()
  const href = pathname + (searchStr ? `?${searchStr}` : '')
  const defaults = { mode: 'main' as const, routeId, href, ignored: false }

  switch (routeId) {
    case '/create/s/$':
    case '/create/l/$': {
      const courseId = parseSplatCourseId(params._splat)
      const courseType = routeId === '/create/s/$' ? 'native:self-paced' : 'native:live'
      return { ...defaults, mode: 'editor', courseType, courseId }
    }

    case '/s/$flexibleContentId/$fileId':
    case '/path/$pathId/s/$flexibleContentId/$fileId':
    case '/program/$programId/s/$flexibleContentId/$fileId':
    case '/program/$programId/path/$pathId/s/$flexibleContentId/$fileId': {
      return {
        ...defaults,
        mode: 'learner',
        courseType: 'native:self-paced',
        courseId: params.flexibleContentId,
      }
    }
    case '/room/$liveRoomId/':
    case '/l/$liveSessionId/': {
      return { ...defaults, mode: 'learner', courseType: 'native:live', courseId: undefined }
    }
    case '/create/s/new':
    case '/create/l/new':
    case '/accept-invite':
    case '/access-denied':
    case '/authenticate':
    case '/404':
      return { ...defaults, ignored: true }

    default:
      return defaults
  }
}

type SidebarHistory = {
  previousLocation?: SidebarLocation | undefined
  lastMainLocation?: SidebarLocation | undefined
  lastCourseLocation?: SidebarLocation | undefined
}

const sidebarHistory: SidebarHistory = {}

const SidebarNavigation: React.FC = () => {
  const sidebarLocation = useActiveRouteMatch({ select: getSidebarLocationByRoute })
  const { data: isGuestUser } = useIsGuestUser()

  if (isGuestUser === true) return null

  if (sidebarLocation.mode !== 'main') {
    if (sidebarLocation.ignored !== true) {
      sidebarHistory.previousLocation = sidebarLocation
      sidebarHistory.lastCourseLocation = sidebarLocation
    }

    return (
      <SidebarCourseNavigation location={sidebarLocation} exitLocation={sidebarHistory.lastMainLocation} />
    )
  }

  let shortcutComponent: React.ReactNode | undefined = undefined

  const { previousLocation } = sidebarHistory
  if (previousLocation?.mode === 'main' && previousLocation.routeId !== sidebarLocation.routeId) {
    // we only want to keep the Resume button around on the first page the user goes to after exiting a course
    sidebarHistory.lastCourseLocation = undefined
  }

  const { lastCourseLocation } = sidebarHistory
  if (
    lastCourseLocation !== undefined &&
    lastCourseLocation.mode !== 'main' &&
    lastCourseLocation.courseId !== undefined
  ) {
    const { mode, courseId, href } = lastCourseLocation
    shortcutComponent = <SidebarShortcut courseMode={mode} courseId={courseId} href={href} />
  }

  if (sidebarLocation.ignored !== true) {
    sidebarHistory.previousLocation = sidebarLocation
    sidebarHistory.lastMainLocation = sidebarLocation
  }

  return <SidebarMainNavigation shortcutComponent={shortcutComponent} />
}

const Sidebar: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const allowNavigation = useIsSidebarNavigationAllowed()

  return (
    <SidebarContainer
      direction='column'
      gap='none'
      ref={containerRef}
      id='tour-global-sidebar'
      data-intercom-target='global-sidebar'
    >
      <HeaderContainer padding='16' direction='column' gap='12'>
        <CollapsableSidebarHeader />
        <Fade direction='down' />
      </HeaderContainer>
      <View direction='column' grow gap='none' paddingLeft='12' paddingRight='12'>
        {allowNavigation && (
          <>
            <SidebarNavigation />
            <Line id='tour-global-sidebar-divider' data-intercom-target='global-sidebar-divider' />
          </>
        )}

        <NestedSidebarViewer />
      </View>

      {allowNavigation && <NestedSidebarButtonViewer />}
    </SidebarContainer>
  )
}

const SidebarSwitch: React.FC = () => {
  const { t } = useTranslation()
  const mobileSidebarAvailable = useIsMobile()
  const dividerMargin = mobileSidebarAvailable ? '16' : '24'

  const containerRef = useRef<HTMLDivElement | null>(null)
  const level = useAtomValue(nestedSidebarContext).level
  const [open, setOpen] = useAtom(GlobalSidebarOpenAtom)

  const [globalSidebarId, setGlobalSidebarId] = useAtom(GlobalSidebarIdAtom)
  const generatedGlobalSidebarId = useGenerateDomId()
  const { isScorm } = config.scorm

  const sidebarVersion = useAtomValue(sidebarVersionAtom)

  const speakerNotesSidebarWidth = useAtomValue(speakerNotesSidebarWidthAtom)

  useEffect(() => {
    setGlobalSidebarId(generatedGlobalSidebarId)
  }, [generatedGlobalSidebarId, setGlobalSidebarId])

  const sidebarContent = (
    <>
      {!isScorm ? (
        <>
          {!mobileSidebarAvailable && (
            <>
              <SidebarNavigation />
              <Divider $marginTop={dividerMargin} $marginBottom={dividerMargin} $grow />
            </>
          )}
        </>
      ) : (
        <Spacer size={dividerMargin} />
      )}
      <NestedSidebarViewer />
    </>
  )

  if (mobileSidebarAvailable) {
    return (
      <MobileSidebar
        renderBottomComponent={() => <BottomSidebarViewer />}
        containerRef={containerRef}
        renderTopComponent={() => <SidebarNavigation />}
      >
        {sidebarContent}
      </MobileSidebar>
    )
  }

  if (sidebarVersion === 'new-sidebar') {
    return (
      <CollapsableSidebar
        containerRef={containerRef}
        bottomComponent={level === 'root' ? <CollapsableCreateNewButtonViewer /> : <BottomSidebarViewer />}
      >
        {sidebarContent}
      </CollapsableSidebar>
    )
  }

  return (
    <SidebarFoldingColumn
      id={globalSidebarId}
      role='navigation'
      aria-label={t('sidebar.main-label')}
      preferredState={'closed'}
      open={open}
      setOpen={setOpen}
      aria-hidden={!open}
      aria-expanded={open}
      width={237 + speakerNotesExtraSidebarWidth[speakerNotesSidebarWidth]}
    >
      <Sidebar />
    </SidebarFoldingColumn>
  )
}

const OldSidebarIcon: React.FC<{ globalSidebarId: string | undefined; onClick: () => void }> = ({
  globalSidebarId,
  onClick,
}) => {
  const hideOldSidebarIcon = useShouldHideOldSidebarIcon()

  if (hideOldSidebarIcon) {
    return <></>
  }

  return (
    <SidebarButtonContainer>
      <OpenSidebarButton aria-expanded={false} aria-controls={globalSidebarId} onClick={onClick} />
    </SidebarButtonContainer>
  )
}

const Container: FCC = ({ children }) => {
  const [open, setOpen] = useAtom(collapsableSidebarBooleanStateAtom)
  const globalSidebarId = useAtomValue(GlobalSidebarIdAtom)

  const setHideGlobalAssistant = useSetAtom(hideGlobalAssistantAtom)
  const setAppMainContainerAtom = useSetAtom(appMainContainerAtom)

  useEffect(() => {
    setHideGlobalAssistant(false)
  }, [setHideGlobalAssistant])

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent): void {
      if ((event.metaKey || event.ctrlKey) && event.key === '.') {
        setOpen(prev => !prev)
      }
    }

    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [setOpen])

  useUpdateInitialSidebarState()
  const isSidebarReady = useInitialSidebarStateReady()

  if (isSidebarReady === false) {
    return <></>
  }

  return (
    <>
      <MobileTopbar />
      <GlobalStyle />
      <ColumnContainer gap='none'>
        <SidebarSwitch />
        <StyledColumn
          ref={ref => {
            setAppMainContainerAtom(ref)
          }}
          role='main'
          // Scrolling on touch screens does not work without this
          disableScrollbarGutter={true}
        >
          {!open && (
            <OldSidebarIcon globalSidebarId={globalSidebarId} onClick={() => setOpen(prev => !prev)} />
          )}
          {children}
        </StyledColumn>
      </ColumnContainer>
      <GlobalAssistantComponent />
    </>
  )
}

export const GlobalSidebar: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const isAuthenticating = useIsFurtherAuthenticationRequired()
  const pathname = usePathname()

  const flexibleContentId = useSafeRouterSelfPacedFlexibleContentId()
  const isSelfPacedPage = isDefined(flexibleContentId)
  const isLiveSession = pathname.startsWith('/l/') || pathname.startsWith('/room/')
  const isRecaps = pathname.startsWith('/r/')
  const isHistory = pathname.startsWith('/history')
  const isExport = pathname.startsWith('/create/export')
  const isHome = pathname === '/'
  const isExportTranscript = pathname.includes('/export-transcript')
  const isSelfReportAttendance = pathname.includes('/check-in')

  const isAllowedRoute = useMemo(() => {
    const startsWith = [
      '/course',
      '/path',
      '/create',
      '/t/',
      '/program',
      '/manage',
      '/content',
      '/404',
      '/learn',
      '/access-denied',
      '/event',
      '/_app',
      '/browse',
      '/skill',
      '/create?tab=shared-with-me',
      '/create?tab=created-by-me',
      '/settings',
    ]

    return (
      !(isExportTranscript || isSelfReportAttendance) &&
      (startsWith.some(it => pathname.startsWith(it)) ||
        isSelfPacedPage ||
        isLiveSession ||
        isRecaps ||
        isHistory ||
        isHome)
    )
  }, [
    isExportTranscript,
    isSelfReportAttendance,
    isSelfPacedPage,
    isLiveSession,
    isRecaps,
    isHistory,
    isHome,
    pathname,
  ])

  const enabled = !isAuthenticating && !isExport && isAllowedRoute

  if (enabled) return <Container>{children}</Container>
  else return <>{children}</>
}
