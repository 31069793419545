import { PrimitiveAtom, useSetAtom } from 'jotai'
import { FC } from 'react'
import { HeaderButtonColorCSS, HeaderItemCSS } from 'sierra-client/components/liveV2/header-buttons'
import { aiTutorTodoT } from 'sierra-client/views/course-helper/tutor/ai-tutor-todo-t'
import { RightSidebarState } from 'sierra-client/views/flexible-content/create-page-right-sidebar-state'
import { Button } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AiNarrationButton = styled(Button)`
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`
export const CourseLearningOutcomesControls: FC<{
  rightSidebarControlStateAtom: PrimitiveAtom<RightSidebarState>
}> = ({ rightSidebarControlStateAtom }) => {
  const setState = useSetAtom(rightSidebarControlStateAtom)

  return (
    <AiNarrationButton
      icon='sana-symbol'
      onClick={() => {
        setState(prev =>
          prev.type === 'learning-outcomes' ? { type: 'closed' } : { type: 'learning-outcomes' }
        )
      }}
    >
      {aiTutorTodoT('Course outcomes')}
    </AiNarrationButton>
  )
}
