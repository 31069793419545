import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import QRCode from 'react-qr-code'
import { getNamespacedImage } from 'sierra-client/api/content'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { NowButtonCSS, NowButtonCSSProps } from 'sierra-client/features/sana-now/header/buttons'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useCachedQuery } from 'sierra-client/state/api'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { XRealtimeStrategyLiveSessionGetQuickJoinCode } from 'sierra-domain/routes'
import { Drawer, Icon, Tooltip } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { ButtonShapeStyles } from 'sierra-ui/primitives/button/button'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import { useOnClickOutside } from 'sierra-ui/utils/use-on-click-outside'
import styled from 'styled-components'

const InnerContainer = styled(View)`
  background-color: ${token('elevated/background')};
  padding: 64px 64px 56px 64px;
  gap: 40px;
  max-width: 560px;
  border-radius: ${p => p.theme.borderRadius['xlarge']};
  mask-image: radial-gradient(10px at 50% 10px, #0000 98%, red);
  mask-position: 0px -10px;
  mask-repeat: repeat-y;
`

const StyledSanaLogo = styled(SanaLogo)`
  & svg {
    width: 28px;
    color: ${token('foreground/primary')};
  }
`

const BrandImage = styled.img`
  width: 28px;
`

const TextWrapper = styled.div`
  max-width: 90%;
`

const ModalCloseButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  right: 24px;
  top: 24px;
`

const DottedSeparatorLineContainer = styled(View)`
  & svg {
    color: ${token('foreground/primary')};
    overflow: hidden;
    width: 100%;
  }
`

const StyledMotionDiv = styled(motion.div)`
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 2;
`

const DottedSeparatorLine = (): JSX.Element => (
  <svg width='480' height='2' viewBox='0 0 480 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 1H547'
      x1='0'
      y1='0'
      x2='400'
      y2='0'
      stroke='currentColor'
      opacity='0.5'
      strokeLinecap='round'
      strokeDasharray='0.1 6'
      strokeWidth={'1px'}
    />
  </svg>
)

const AccentText = styled.strong`
  color: ${token('foreground/primary')};
`

const CopyLinkButton: React.FC<{ url: string }> = ({ url }) => {
  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()
  const { t } = useTranslation()

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(url)
    setRecentlyClicked()
  }

  return (
    <Tooltip title={t('share.copy-link')}>
      <Button
        variant='transparent'
        onClick={handleClick}
        icon={recentlyClicked ? 'checkmark' : 'clipboard'}
        decoratorPosition='right'
      >
        {t('sana-now.share-modal.copy-session-link-button-text')}
      </Button>
    </Tooltip>
  )
}

const CodeLetterBox = styled.div`
  width: 64px;
  height: 72px;
  border: 1px solid ${token('form/border/2')};
  border-radius: 20px;
  color: ${token('foreground/primary')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
`

const ModalOverlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  z-index: 2;
  background: linear-gradient(250deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
`

export const QuickJoinQRModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const orgConfig = config.organization
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const liveSession = useLiveSessionContext()
  const modalRef = React.useRef<HTMLDivElement>(null)

  useOnClickOutside({ ref: modalRef, onClickOutside: onClose })

  useEffect(() => {
    if (open) {
      void dispatch(
        Logging.liveSession.sanaNowShareModalOpened({
          liveSessionId: liveSession.liveSessionId,
        })
      )
    }
  }, [open, dispatch, liveSession.liveSessionId])

  const linkResult = useCachedQuery(
    XRealtimeStrategyLiveSessionGetQuickJoinCode,
    {
      liveSessionId: liveSession.liveSessionId,
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  if (!linkResult.isSuccess) return null

  const host = new URL(linkResult.data.fullUrl).host

  const quickJoinCode = {
    code: linkResult.data.code,
    fullUrl: window.location.href,
    host,
  }

  const brandLogo =
    orgConfig.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, orgConfig.settings.brand.logo, 'image', {
          width: 80,
        })

  return createPortal(
    <AnimatePresence>
      {open && (
        <LightTokenProvider>
          <ModalOverlay initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <StyledMotionDiv
              ref={modalRef}
              initial={{ opacity: 0, y: 200 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  type: 'spring',
                  damping: 25,
                  stiffness: 500,
                  bounce: 1,
                  duration: 0.2,
                  ease: [0.25, 0.1, 0.25, 1],
                },
              }}
              exit={{ opacity: 0, y: 200, transition: { duration: 0.15, ease: [0.25, 0.1, 0.25, 1] } }}
            >
              <ModalCloseButton variant='transparent' iconId='close' onClick={onClose} />
              <InnerContainer grow justifyContent='center' alignItems='center' direction='column' gap='none'>
                <View direction='column' alignItems='center' gap='24'>
                  {brandLogo === undefined ? <StyledSanaLogo /> : <BrandImage src={brandLogo} />}
                  <Heading size='h3' bold avoidHanging={false} color='foreground/muted'>
                    <Trans
                      i18nKey='sana-now.share-modal.title'
                      values={{ url: quickJoinCode.host }}
                      components={{
                        bold: <AccentText />,
                      }}
                    />
                  </Heading>
                  <View>
                    {quickJoinCode.code.split('').map((letter, index) => (
                      <CodeLetterBox key={index}>
                        <div>{letter}</div>
                      </CodeLetterBox>
                    ))}
                  </View>
                  <CopyLinkButton url={quickJoinCode.fullUrl} />
                </View>

                <DottedSeparatorLineContainer>
                  <DottedSeparatorLine />
                </DottedSeparatorLineContainer>

                <View direction='column' alignItems='center' gap='24'>
                  <QRCode
                    size={200}
                    value={quickJoinCode.fullUrl}
                    viewBox={`0 0 256 256`}
                    bgColor='rgb(255, 255, 255)'
                    fgColor='rgb(24, 24, 24)'
                  />
                </View>

                <TextWrapper>
                  <Text size='small' color={'foreground/secondary'} align='center'>
                    {t('sana-now.share-modal.body-text')}
                  </Text>
                </TextWrapper>
              </InnerContainer>
            </StyledMotionDiv>
          </ModalOverlay>
        </LightTokenProvider>
      )}
    </AnimatePresence>,
    document.body
  )
}

const ShareButton = styled(motion.button)`
  ${NowButtonCSS};
  ${ButtonShapeStyles};
  white-space: nowrap;
`

const OpacitySpan = styled.span<NowButtonCSSProps>`
  ${p =>
    p.$backgroundColor.brightness() > 0.9
      ? ' color: rgba(0, 0, 0, 0.36)'
      : ' color: rgba(255, 255, 255, 0.6)'};
`

const StrongStyle = styled.strong<NowButtonCSSProps>`
  color: ${p => (p.$backgroundColor.brightness() > 0.9 ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 1) ')};
`

export const VerticalSeparator = styled.div`
  height: 1rem;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.16);
`

export const QRCodeButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const liveSession = useLiveSessionContext()
  const [hovered, setHover] = useState(false)
  const isMobile = useIsMobile()

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const linkResult = useCachedQuery(
    XRealtimeStrategyLiveSessionGetQuickJoinCode,
    {
      liveSessionId: liveSession.liveSessionId,
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  if (!linkResult.isSuccess) return null

  const host = new URL(linkResult.data.fullUrl).host

  return (
    <>
      <ShareButton
        $backgroundColor={backgroundColor}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <View>
          {hovered && !isMobile ? (
            <Icon iconId='maximize' size='size-14' color={'currentColor'} />
          ) : (
            <Icon iconId='qr-code' size='size-14' color={'currentColor'} />
          )}
          {!isMobile && (
            <>
              <OpacitySpan $backgroundColor={backgroundColor}>
                <Trans
                  i18nKey='sana-now.header.share-button.join-at-url'
                  values={{ url: host }}
                  components={{
                    bold: <StrongStyle $backgroundColor={backgroundColor} />,
                  }}
                />
              </OpacitySpan>
              <VerticalSeparator />
              <OpacitySpan $backgroundColor={backgroundColor}>
                <Trans
                  i18nKey='sana-now.header.share-button.join-code'
                  values={{ code: linkResult.data.code }}
                  components={{
                    bold: <StrongStyle $backgroundColor={backgroundColor} />,
                  }}
                />
              </OpacitySpan>
            </>
          )}
        </View>
      </ShareButton>
    </>
  )
}

const DrawerCloseButton = styled(IconButton).attrs({
  iconId: 'close',
  variant: 'transparent',
})`
  z-index: 3;
  position: absolute;
  top: 18px;
  right: 18px;
`

export const QRCodeDrawer: React.FC<{ open: boolean; setOpen: (newOpen: boolean) => void }> = ({
  open,
  setOpen,
}) => {
  const orgConfig = config.organization
  const dispatch = useDispatch()
  const liveSession = useLiveSessionContext()

  useEffect(() => {
    if (open) {
      void dispatch(
        Logging.liveSession.sanaNowShareModalOpened({
          liveSessionId: liveSession.liveSessionId,
        })
      )
    }
  }, [open, dispatch, liveSession.liveSessionId])

  const linkResult = useCachedQuery(
    XRealtimeStrategyLiveSessionGetQuickJoinCode,
    {
      liveSessionId: liveSession.liveSessionId,
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  )

  if (!linkResult.isSuccess) return null

  const host = new URL(linkResult.data.fullUrl).host

  const quickJoinCode = {
    code: linkResult.data.code,
    fullUrl: window.location.href,
    host,
  }

  const brandLogo =
    orgConfig.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, orgConfig.settings.brand.logo, 'image', {
          width: 80,
        })

  return (
    <Drawer disableScrollbarGutter overlayVariant='none' onClose={() => setOpen(false)} open={open}>
      <View
        grow
        justifyContent='center'
        alignItems='center'
        direction='column'
        gap='none'
        position='relative'
      >
        <DrawerCloseButton onClick={() => setOpen(false)} />
        <View direction='column' alignItems='center' gap='24'>
          {brandLogo === undefined ? <StyledSanaLogo /> : <BrandImage src={brandLogo} />}
          <Heading size='h3' bold avoidHanging={false} color='foreground/muted'>
            <Trans
              i18nKey='sana-now.share-modal.title'
              values={{ url: quickJoinCode.host }}
              components={{
                bold: <AccentText />,
              }}
            />
          </Heading>
          <View>
            {quickJoinCode.code.split('').map((letter, index) => (
              <CodeLetterBox key={index}>
                <div>{letter}</div>
              </CodeLetterBox>
            ))}
          </View>

          <CopyLinkButton url={quickJoinCode.fullUrl} />

          <Spacer size='16' />
        </View>

        <View direction='column' alignItems='center' gap='24'>
          <QRCode
            size={200}
            value={quickJoinCode.fullUrl}
            viewBox={`0 0 256 256`}
            bgColor='rgb(255, 255, 255)'
            fgColor='rgb(24, 24, 24)'
          />
        </View>

        <Spacer size='32' />
      </View>
    </Drawer>
  )
}
