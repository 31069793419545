import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import {
  ContentGroupsTable,
  ContentGroupsTableProps,
} from 'sierra-client/views/manage/components/content-groups-table'
import {
  ContentUsersTable,
  ContentUsersTableProps,
} from 'sierra-client/views/manage/components/content-users-table'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { queryKeyTabularContentUsers } from 'sierra-client/views/manage/utils/query-keys'
import { UserId } from 'sierra-domain/api/uuid'
import { Icon } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

type EmptySectionProps = {
  openEnrollment: () => void
  contentType?: 'course' | 'path'
  canEditAssignments: boolean
}

type EmptyGroupsSectionProps = {
  contentType?: 'course' | 'path'
}

const EmptyUserAndGroupSection: React.FC<EmptySectionProps> = ({ openEnrollment, canEditAssignments }) => {
  const { t } = useTranslation()
  return (
    <EmptySection>
      <Icon iconId='user--group' size='size-16' color='foreground/primary' />
      <Spacer size='xsmall' />
      <Text size='regular' bold>
        {t('manage.courses.no-enrollments.title')}
      </Text>
      {canEditAssignments && (
        <>
          <Spacer size='4' />
          <EmptyBody size='small' align='center'>
            {t('manage.courses.no-enrollments.body')}
          </EmptyBody>
          <Spacer size='small' />
          <Button variant='ghost' onClick={openEnrollment}>
            {t('admin.organization.paths.enroll')}
          </Button>
        </>
      )}
    </EmptySection>
  )
}

const EmptyGroupsSection: React.FC<EmptyGroupsSectionProps> = ({ contentType }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='none'>
      <Spacer size='xxlarge' />
      <Text size='large' bold>
        {t('dictionary.program-plural')}
      </Text>
      <Spacer size='medium' />
      <EmptySection>
        <Icon iconId='path' size='size-16' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.courses.no-programs.title')}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center'>
          {t('manage.courses.no-programs.body', { contentType: contentType })}
        </EmptyBody>
      </EmptySection>
    </View>
  )
}

type ContentEnrollmentSectionProps = Pick<
  ContentUsersTableProps,
  | 'contentId'
  | 'assignedCount'
  | 'contentType'
  | 'onRemoveUser'
  | 'onSetUserDueDate'
  | 'onSetUserRecurrence'
  | 'openEnrollUsers'
  | 'onChangeRequiredAssignments'
> &
  ContentGroupsTableProps & {
    contentType: 'course' | 'path'
    canEditAssignments: boolean
  }

export const ContentEnrollmentSection: React.FC<ContentEnrollmentSectionProps> = ({
  assignedPrograms,
  contentId,
  assignedCount,
  openEnrollUsers,
  onRemoveUser,
  onSetUserDueDate,
  onSetUserRecurrence,
  contentType,
  canEditAssignments,
  onChangeRequiredAssignments,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [userAction, setUserAction] = useState<
    (UserModalActionsProps['action'] & { targetUserId: UserId }) | null
  >(null)

  const noAssignedUsers = assignedCount === 0
  const showEmptyGroups = assignedPrograms.length === 0

  const completeUserContent = (userId: UserId): void => {
    setUserAction({
      contentId,
      contentType,
      modal: 'complete-content',
      targetUserId: userId,
    })
  }

  const openResetCourse = (userId: UserId): void => {
    setUserAction({
      modal: 'reset-course',
      contentId,
      contentType,
      targetUserId: userId,
    })
  }

  return (
    <View direction='column' gap='none'>
      <Text size='large' bold>
        {t('admin.analytics.learners')}
      </Text>
      <Spacer size='xsmall' />
      {noAssignedUsers ? (
        <EmptyUserAndGroupSection openEnrollment={openEnrollUsers} canEditAssignments={canEditAssignments} />
      ) : (
        <ContentUsersTable
          canEditAssignments={canEditAssignments}
          contentId={contentId}
          contentType={contentType}
          assignedCount={assignedCount}
          onRemoveUser={onRemoveUser}
          openEnrollUsers={openEnrollUsers}
          onSetUserDueDate={onSetUserDueDate}
          onSetUserRecurrence={onSetUserRecurrence}
          openCompleteUserContent={completeUserContent}
          openResetUserContent={openResetCourse}
          onChangeRequiredAssignments={onChangeRequiredAssignments}
        />
      )}
      {showEmptyGroups && <EmptyGroupsSection contentType={contentType} />}
      {assignedPrograms.length > 0 && (
        <>
          <Spacer size='xxlarge' />
          <ContentGroupsTable assignedPrograms={assignedPrograms} />
        </>
      )}

      {userAction !== null && (
        <UserModalActions
          targetUserId={userAction.targetUserId}
          action={userAction}
          onClose={() => setUserAction(null)}
          onDone={() => {
            setUserAction(null)
            void queryClient.invalidateQueries({ queryKey: queryKeyTabularContentUsers(contentId) })
          }}
        />
      )}
    </View>
  )
}
