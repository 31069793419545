import { getNamespacedImage } from 'sierra-client/api/content'
import { Brand } from 'sierra-domain/api/private'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const LogoImg = styled.img.attrs({
  alt: '', // decorative image
})`
  width: 20px;
  height: 20px;
`

const IconView = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  flex: 0 0 auto;
  color: ${token('button/foreground')};
  background: ${token('button/background')};
  border-radius: 6px;
  width: 20px;
  height: 20px;
  transition: all 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const getFirstLetter = (orgName: string | undefined): string => {
  if (!isDefined(orgName)) return ''
  const firstCodePoint = orgName.codePointAt(0)
  const firstLetterOrEmoji = isNonNullable(firstCodePoint) ? String.fromCodePoint(firstCodePoint) : ''

  return firstLetterOrEmoji.toUpperCase()
}

const IconLetterView = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
`

const FallBackLogoIcon: React.FC<{ orgName: string | undefined }> = ({ orgName }) => {
  return (
    <IconView aria-hidden='true'>
      <IconLetterView>
        <Text color='currentColor' size='technical'>
          {getFirstLetter(orgName)}
        </Text>
      </IconLetterView>
    </IconView>
  )
}

export const OrganizationLogo: React.FC<{
  orgName: string | undefined
  brandSettings: Brand | undefined
}> = ({ orgName, brandSettings }) => {
  const favIcon = brandSettings?.favicon
  const squareIcon = brandSettings?.squareLogo

  if (isDefined(squareIcon)) {
    const squareIconSrc = getNamespacedImage({ type: 'organization-settings' }, squareIcon, 'image', {
      width: 32,
      format: 'png',
    })

    return <LogoImg src={squareIconSrc} />
  }

  if (isDefined(favIcon)) {
    const favIconSrc = getNamespacedImage({ type: 'organization-settings' }, favIcon, 'image', {
      width: 32,
      format: 'png',
    })

    return <LogoImg src={favIconSrc} />
  }

  return <FallBackLogoIcon orgName={orgName} />
}
