import React from 'react'
import {
  Cell,
  HeaderGroup,
  TableProps as RTTableProps,
  Row,
  TableCellProps,
  TableFooterProps,
  TableHeaderProps,
  TableInstance,
  TableRowProps,
} from 'react-table'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const TableTh = styled.th<{ width?: string | number }>`
  position: sticky;
  top: 0;
  inset-block-start: 0;
  z-index: 2;
  ${fonts.body.small}
  height: 3.25rem;
  padding: 0.875rem 1rem 0.875rem 0;
  vertical-align: middle;
  font-weight: ${fonts.weight.bold};
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  text-align: start;
  box-shadow: inset 0 -1px 0 ${palette.grey[5]};
`

const TableTd = styled.td`
  ${fonts.body.small}
  padding: 1rem 1rem 1rem 0;
  vertical-align: middle;
  background-color: transparent;
  color: ${p => p.theme.color.grey70};
  border: none;
  border-top: 1px solid ${palette.grey[5]};
  transition:
    background-color 0.2s ease,
    color 0.2s ease,
    border-color 0.2s ease;
`

const hoverCss = css`
  ${TableTd} {
    background-color: ${palette.grey[2]};
    color: ${palette.primitives.black};

    &:first-child::before,
    &:last-child::after {
      background-color: ${palette.grey[2]};
    }
  }
`

const TableTr = styled.tr<{ isFocused?: boolean }>`
  ${TableTd},
  ${TableTh} {
    &:last-child {
      padding-right: 0;
    }

    &:first-child::before,
    &:last-child::after {
      content: '';
      position: absolute;
      top: -1px;
      height: calc(100% + 2px);
      background-color: transparent;
      transition: background-color 0.2s ease;
    }
  }

  ${TableTh} {
    &:first-child::before,
    &:last-child::after {
      height: 100%;
      background-color: ${palette.primitives.white};
    }
  }

  ${TableTd} {
    &:first-child,
    &:last-child {
      position: relative;
    }

    &:first-child::before {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child::after {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  &:not(:first-child:last-child) {
    border-bottom: 1px solid ${palette.grey[5]};
  }

  ${p => p.isFocused === true && hoverCss}
  &:hover {
    ${hoverCss}
  }
`

const TableTable = styled.table`
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  border-collapse: separate;

  ${TableTr} {
    ${TableTd},
    ${TableTh} {
      &:first-child {
        &::before {
          width: 1rem;
          left: -1rem;
        }
      }

      &:last-child {
        &::after {
          width: 1rem;
          right: -1rem;
        }
      }
    }
  }
`

interface TableProps<T extends Record<string, unknown> = Record<string, unknown>> {
  tableInstance: TableInstance<T>
  headerOverride?: JSX.Element

  // Customize behavior: https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles
  getTableProps?: (tableInstance: TableInstance<T>) => Partial<RTTableProps> & React.ComponentProps<'table'>
  getTHeadProps?: (tableInstance: TableInstance<T>) => React.ComponentProps<'thead'>
  getHeaderGroupProps?: (
    headerGroup: HeaderGroup<T>
  ) => Partial<TableHeaderProps> & React.ComponentProps<'tr'>
  getHeaderProps?: (column: HeaderGroup<T>) => Partial<TableHeaderProps> & React.ComponentProps<'th'>
  getFooterProps?: (column: HeaderGroup<T>) => Partial<TableFooterProps> & React.ComponentProps<'th'>
  getTableBodyProps?: (propGetter: TableInstance<T>) => Partial<RTTableProps> & React.ComponentProps<'tbody'>
  getRowProps?: (propGetter: Row<T>) => Partial<TableRowProps> & React.ComponentProps<'tr'>
  getCellProps?: (propGetter: Cell<T>) => Partial<TableCellProps> & React.ComponentProps<'td'>
  extraBodyRow?: JSX.Element
}

export const BasicTable = <T extends Record<string, unknown> = Record<string, unknown>>({
  tableInstance,
  headerOverride,
  getTableProps = () => ({}),
  getTHeadProps = () => ({}),
  getHeaderGroupProps = () => ({}),
  getHeaderProps = () => ({}),
  // getTFootProps = () => ({}),
  // getFooterGroupProps = () => ({}),
  getFooterProps = () => ({}),
  getTableBodyProps = () => ({}),
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  extraBodyRow,
}: TableProps<T>): JSX.Element => {
  const { headerGroups, footerGroups, prepareRow, rows, visibleColumns } = tableInstance

  const { key, ...tHeadProps } = getTHeadProps(tableInstance)

  return (
    <TableTable {...tableInstance.getTableProps(getTableProps(tableInstance))}>
      <thead key={key} {...tHeadProps}>
        {headerOverride !== undefined ? (
          <TableTr>
            <TableTh colSpan={visibleColumns.length}>{headerOverride}</TableTh>
          </TableTr>
        ) : (
          headerGroups.map(headerGroup => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps(
              getHeaderGroupProps(headerGroup)
            )

            return (
              <TableTr key={key} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...headerProps } = column.getHeaderProps(getHeaderProps(column))

                  return (
                    <TableTh key={key} {...headerProps} width={column.width}>
                      {column.render('Header')}
                    </TableTh>
                  )
                })}
              </TableTr>
            )
          })
        )}
      </thead>
      <tbody {...tableInstance.getTableBodyProps(getTableBodyProps(tableInstance))}>
        {rows.map(row => {
          prepareRow(row)

          const { key, ...rowProps } = row.getRowProps(getRowProps(row))

          return (
            <TableTr key={key} {...rowProps}>
              {row.cells.map(cell => {
                const { key, ...cellProps } = cell.getCellProps(getCellProps(cell))

                return (
                  <TableTd key={key} {...cellProps} width={cell.column.width}>
                    {cell.render('Cell')}
                  </TableTd>
                )
              })}
            </TableTr>
          )
        })}
        {extraBodyRow !== undefined && (
          <TableTr key='additional-row'>
            <TableTd colSpan={visibleColumns.length}>{extraBodyRow}</TableTd>
          </TableTr>
        )}
      </tbody>
      <tfoot>
        {footerGroups.map(group => {
          const { key, ...footerGroupProps } = group.getFooterGroupProps()

          return (
            <tr key={key} {...footerGroupProps}>
              {group.headers.map(column => {
                const { key, ...footerProps } = column.getFooterProps(getFooterProps(column))

                return (
                  <td key={key} {...footerProps}>
                    {column.render('Footer')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tfoot>
    </TableTable>
  )
}
