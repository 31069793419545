import { useAtomValue } from 'jotai'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { mapXApiToCsv } from 'sierra-client/views/manage/api/use-manage-x-api'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { XApiServerResponse } from 'sierra-domain/api/x-api'
import { Button, Spacer, View } from 'sierra-ui/primitives'

type XApiTableDefinition = TableDefinitionOf<
  XApiServerResponse,
  [
    { type: 'strings'; ref: 'name' },
    { type: 'strings'; ref: 'description' },
    { type: 'strings'; ref: 'id' },
    { type: 'strings'; ref: 'actorType' },
    { type: 'strings'; ref: 'statementsUrl' },
  ]
>

type XApiTableData = TableDataFromDefinition<XApiServerResponse, XApiTableDefinition>
type XApiTableMeta = StaticDataLoaderMeta<XApiTableData>

const searchKey: StaticLoaderSearchKeyBy<XApiTableData> = (tableData, row) => {
  const rawData = tableData.rows[row]?.rawData
  return rawData ? [rawData.name, rawData.description].join(' ') : ''
}

const tableDefinition = (): XApiTableDefinition => {
  return {
    columns: [
      stringsColumn({
        sortable: true,
        getData: r => r.name,
        header: translatedLabel('table.name'),
        ref: 'name',
      }),
      stringsColumn({
        getData: r => r.description,
        header: translatedLabel('dictionary.description'),
        ref: 'description',
      }),
      stringsColumn({
        getData: r => r.clientId,
        header: translatedLabel('manage.api.client-id'),
        ref: 'id',
      }),
      stringsColumn({
        getData: r => r.actorType,
        header: translatedLabel('manage.x-api.actor-type'),
        ref: 'actorType',
      }),
      stringsColumn({
        getData: r => r.statementsUrl,
        header: translatedLabel('manage.x-api.statements-url'),
        ref: 'statementsUrl',
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.serverId,
    },
  }
}

const useXApiTable = ({
  data,
}: {
  data: XApiServerResponse[]
}): UseTableAPI<XApiTableData, XApiTableMeta> => {
  const loader = staticDataLoader(definition2Data(tableDefinition(), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [],
      right: [],
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.SECOND,
    },
  })
}

const SearchSection: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const query = useAtomValue(api.atoms.query)

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => api.action.setQuery({ query })}
        placeholder={t('dictionary.search')}
      />
    </View>
  )
}

const Footer: React.FC<
  { fetchCsv: () => Promise<Record<string, string>[]> } & Pick<XApiTableProps, 'createApiClient'>
> = ({ fetchCsv, createApiClient }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='space-between'>
      <Button onClick={createApiClient}>{t('manage.x-api.create-new')}</Button>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('manage.x-api.title')} />
    </View>
  )
}
export type XApiTableProps = {
  data: XApiServerResponse[]
  createApiClient: () => void
}

export const XApiTable: React.FC<XApiTableProps> = ({ data, createApiClient }) => {
  const tableAPI = useXApiTable({ data })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/x-api/${rawData.serverId}` })
        },
      }}
    >
      <SearchSection />
      <Spacer size='xsmall' />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(data.map(mapXApiToCsv))} createApiClient={createApiClient} />
    </TabularProviderFromTableAPI>
  )
}
