import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import { ScenarioCardChatFeedback } from 'sierra-client/api/graphql/gql/graphql'
import { useDateTimeFormatter } from 'sierra-client/core/format'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ComplianceCard,
  ErrorCard,
  StaticFeedbackCard,
  TerminationCard,
} from 'sierra-client/views/v3-author/scenario/chat/completion'
import { DebugMenu } from 'sierra-client/views/v3-author/scenario/chat/debug'
import { ScenarioHeader } from 'sierra-client/views/v3-author/scenario/chat/header'
import { InitialChat, useChat } from 'sierra-client/views/v3-author/scenario/chat/hooks'
import { Input } from 'sierra-client/views/v3-author/scenario/chat/input'
import { MessageType, ScenarioChatState } from 'sierra-client/views/v3-author/scenario/chat/state'
import { GenerationBackground } from 'sierra-client/views/v3-author/scenario/generation-background'
import { MessageLoaderDots } from 'sierra-client/views/v3-author/scenario/loading-views'
import { useTracking } from 'sierra-client/views/v3-author/scenario/tracking'
import { getScenarioBackgroundColor, ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScenarioCharacter } from 'sierra-domain/flexible-content/types'
import { iife, isDefined, OneOf } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { CloseModalButton, Icon, Modal } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const MessageContainer = styled(View)<{
  $sender: 'user' | 'assistant'
}>`
  align-self: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  align-items: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  max-width: 536px;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 8px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    max-width: 75%;
  }
`

const MessageText = styled(View)<{
  $sender: 'user' | 'assistant'
}>`
  position: relative;
  padding: 10px 18px;
  border-radius: 20px;
  align-self: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  width: fit-content;
  white-space: pre-line;
`

const AnimationView = styled(motion.div)<{
  $sender: 'user' | 'assistant'
}>`
  min-width: 74px;
  min-height: 42px;
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  width: 100%;
  height: 100%;

  background-color: ${p =>
    p.$sender === 'user'
      ? resolveTokenOrColor('button/background', p.theme)
      : resolveTokenOrColor('surface/strong', p.theme)};
`

const ChatContainer = styled(motion.div)`
  margin: 48px;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    padding: 12px;
    margin: 0;
  }
`

const DebugWrapper = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
`

const LoadingChatMessage: React.FC<{ character: ScenarioCharacter }> = ({ character }) => {
  return (
    <MessageContainer $sender='assistant'>
      <View padding='none 6'>
        <Text size='micro' bold color='foreground/primary'>
          {character.name}
        </Text>
      </View>
      <MessageLoaderDots />
    </MessageContainer>
  )
}
const ChatMessage: React.FC<{
  message: MessageType
  character: ScenarioCharacter
  onAnimationStart: () => void
}> = ({ message, character, onAnimationStart }) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ hour: 'numeric', minute: 'numeric' })
  const MSG_ANIMATION_DELAY = 0.2

  const whenSent = dateFormatter.format(new Date(message.timestamp))

  return (
    <MessageContainer $sender={message.sender}>
      {message.sender === 'assistant' && (
        <View padding='none 6'>
          <Text size='micro' bold color='foreground/primary'>
            {character.name}
          </Text>
        </View>
      )}
      <MessageText $sender={message.sender}>
        <AnimationView
          key={`message_animation_${message.index}`}
          $sender={message.sender}
          variants={{
            hidden:
              message.sender === 'assistant'
                ? {
                    scaleX: 0.3,
                    scaleY: 0.5,
                  }
                : {
                    opacity: 0,
                  },
            visible:
              message.sender === 'assistant'
                ? { scaleX: 1.0, scaleY: 1.0, minWidth: 0, minHeight: 0 }
                : { opacity: 1, minWidth: 0, minHeight: 0 },
          }}
          transition={{
            duration: MSG_ANIMATION_DELAY,
            ease: [0.22, 0.61, 0.36, 1],
          }}
          onAnimationStart={onAnimationStart}
        />
        <motion.div
          key={`message_text_animation_${message.index}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: message.sender === 'assistant' ? MSG_ANIMATION_DELAY : 0,
            duration: 0.4,
          }}
          style={{ zIndex: 2 }}
        >
          <Text size='large' color={message.sender === 'user' ? 'button/foreground' : undefined}>
            {message.content}
          </Text>
        </motion.div>
      </MessageText>
      <View
        justifyContent={message.sender === 'assistant' ? 'flex-start' : 'flex-end'}
        gap='4'
        padding='none 6'
      >
        <Icon iconId='checkmark--outline' size='size-12' color='foreground/muted' />
        <Text size='micro' capitalize='first' color='foreground/muted'>{`${t(
          'dictionary.sent'
        )} ${whenSent}`}</Text>
      </View>
    </MessageContainer>
  )
}

const InputWrapper = styled(View)`
  position: sticky;
  display: flex;
  bottom: 16px;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 1110px) {
    bottom: 65px;
  }
`

const ScenarioChatWrapper = styled(View)`
  background-color: ${p => getScenarioBackgroundColor(resolveTokenOrColor('surface/default', p.theme))};
  scrollbar-gutter: stable;
  overflow-y: scroll;

  > * {
    max-width: 760px;
  }

  @media screen and (max-width: 1110px) {
    padding-bottom: 50px;
  }
`

const FullWidthView = styled(View)`
  width: 100%;
`

type ScenarioChatProps = {
  character: ScenarioCharacter
  courseId: CreateContentId
  file: ScenarioFile
} & OneOf<
  {
    previous?: {
      chat: InitialChat
      state: ScenarioChatState
      feedback?: ScenarioCardChatFeedback
      resetPreviousData: () => void
    }
  },
  { previewMode?: true }
>

export const ScenarioChat: React.FC<ScenarioChatProps> = ({
  character,
  courseId,
  file,
  previewMode = false,
  previous,
}) => {
  const isDebugMode = useIsDebugMode()
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const tracking = useTracking({ courseId, fileId: file.id })

  const {
    chatId,
    onAskForFirstMessage,
    completeChat,
    revertLastMessage,
    onAddMessage,
    messages,
    chatState,
    resetChat,
    importChat,
  } = useChat({
    previewMode,
    file,
    courseId,
    startupChat: previous?.chat,
    startupState: previous?.state,
  })

  const resetEverything = (): void => {
    previous?.resetPreviousData()
    resetChat()
  }

  return (
    <ScenarioChatWrapper
      grow
      direction='column'
      position='relative'
      alignItems='center'
      paddingTop={chatState.type === 'init' ? 'none' : '80'}
      justifyContent={chatState.type === 'init' ? 'center' : 'flex-start'}
    >
      <ScenarioHeader
        file={file}
        chatState={chatState}
        onStart={() => {
          void onAskForFirstMessage()
          tracking.learner.startScenario()
        }}
      />

      {chatState.type !== 'init' && (
        <>
          <ChatContainer
            key='chat'
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: { opacity: 1 },
            }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <View direction='column' grow>
              {messages.map(message => (
                <ChatMessage
                  key={message.index}
                  message={message}
                  character={character}
                  onAnimationStart={() => {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }}
                />
              ))}
              {chatState.type === 'waiting-for-assistant' && <LoadingChatMessage character={character} />}
            </View>
          </ChatContainer>

          <AnimatePresence mode='popLayout'>
            {chatState.type === 'completed' && (
              <FullWidthView
                animated
                layout
                key='completion_card'
                initial='before_visible'
                animate='visible'
                exit='after_visible'
                variants={{
                  before_visible: {
                    opacity: 0,
                    y: 50,
                  },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.2,
                      delay: 0.1,
                    },
                  },
                  after_visible: {
                    opacity: 0,
                    y: -50,
                    transition: { ease: 'easeIn', duration: 0.3, opacity: { delay: 0.1, duration: 0.2 } },
                  },
                }}
                transition={{ duration: 0.4 }}
                onAnimationStart={def => {
                  if (def === 'visible') {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
                onAnimationComplete={def => {
                  if (def === 'after_visible') {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                {iife(() => {
                  // If we have previous feedback, show that instead
                  if (isDefined(previous?.feedback)) {
                    return <StaticFeedbackCard feedback={previous.feedback} onRestart={resetEverything} />
                  }
                  if (chatState.reason === 'error') {
                    return (
                      <ErrorCard
                        onMount={() => {
                          tracking.learner.sentMessages({ messages: messages.length })
                        }}
                      />
                    )
                  }
                  if (chatState.reason === 'compliance-fail') {
                    return (
                      <ComplianceCard
                        onUndo={() => {
                          revertLastMessage()
                          tracking.learner.undoLastMessage()
                        }}
                        onRestart={() => {
                          resetEverything()
                          tracking.learner.restartScenario()
                        }}
                        onMount={() => {
                          tracking.learner.complianceFailure()
                          tracking.learner.sentMessages({ messages: messages.length })
                        }}
                        reason={chatState.failReason}
                        chatId={chatId}
                        fileId={file.id}
                        courseId={courseId}
                        messageHistory={messages}
                        onFullyLoaded={() => {
                          bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                        }}
                        previewMode={previewMode}
                      />
                    )
                  }
                  return (
                    <TerminationCard
                      chatId={chatId}
                      fileId={file.id}
                      courseId={courseId}
                      selfTerminated={chatState.reason === 'self-termination'}
                      messageHistory={messages}
                      onTryAgain={() => {
                        resetEverything()
                        tracking.learner.tryAgain()
                      }}
                      onFullyLoaded={() => {
                        bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                      }}
                      onMount={() => {
                        if (chatState.reason === 'goal-reached-termination') {
                          tracking.learner.completedSuccess()
                        } else {
                          tracking.learner.completedFailure()
                        }
                        tracking.learner.sentMessages({ messages: messages.length })
                      }}
                      previewMode={previewMode}
                    />
                  )
                })}
              </FullWidthView>
            )}

            {chatState.type !== 'completed' && (
              <InputWrapper
                key='input'
                animated
                initial='before_visible'
                animate='visible'
                exit='after_visible'
                variants={{
                  before_visible: {
                    opacity: 0,
                    y: 20,
                  },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.2,
                      delay: 0.1,
                    },
                  },
                  after_visible: {
                    opacity: 0,
                    y: -20,
                    transition: {
                      ease: 'easeIn',
                      delay: 0.1,
                      duration: 0.2,
                    },
                  },
                }}
              >
                <Input
                  onSubmit={onAddMessage}
                  onConversationEnd={() => {
                    completeChat(messages.length < 4 ? 'self-termination' : 'goal-reached-termination')
                    tracking.learner.endConversation()
                  }}
                  assistantName={character.name}
                  chatState={chatState}
                />
              </InputWrapper>
            )}
          </AnimatePresence>

          <div ref={bottomRef} style={{ width: 5, height: 5 }} />
        </>
      )}

      {isDebugMode && (
        <DebugWrapper>
          <DebugMenu
            messageHistory={messages}
            onImport={msgs => {
              resetEverything()
              importChat(msgs)
            }}
          />
        </DebugWrapper>
      )}
    </ScenarioChatWrapper>
  )
}

const CloseTestModalButton = styled(CloseModalButton)`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
`
export const ScenarioChatModal: React.FC<{
  open: boolean
  file: ScenarioFile
  courseId: CreateContentId
  onClose: () => void
}> = ({ open, file, courseId, onClose }) => {
  const { character } = file.data.input
  const { userContext, assistantContext } = file.data.generated ?? {}
  return (
    <Modal onClose={onClose} size='full-screen' open={open} disableScrollbarGutter>
      <View position='relative' direction='column' grow>
        {isDefined(userContext) && isDefined(assistantContext) && isDefined(character) ? (
          <ScenarioChat character={character} courseId={courseId} file={file} previewMode />
        ) : (
          <GenerationBackground />
        )}
      </View>
      <CloseTestModalButton ariaLabel='close' onClick={onClose} />
    </Modal>
  )
}
