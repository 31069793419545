import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ContentAttributesForCourseQuery,
  ContentAttributesQuery,
  CustomContentAttributeSchemaInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQueryFn, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { MOCKED_ATTRIBUTE_VALUES } from 'sierra-client/views/manage/content-attributes/tmp.mocks'
import {
  ContentAttribute,
  ContentAttributeWithValue,
  GQL2CAT,
} from 'sierra-client/views/manage/content-attributes/types'

const CONTENT_ATTRIBUTE_GQL = graphql(`
  query ContentAttributes {
    contentAttributesSchema {
      customAttributeDefs {
        ...ContentAttributeDef
      }
      tagsDef {
        id
        image
        name
        description
      }
    }
  }
`)

const CONTENT_ATTRIBUTE_FOR_COURSE_GQL = graphql(`
  query ContentAttributesForCourse($id: CourseId!) {
    course(id: $id) {
      ... on Course {
        attributes {
          ...ContentAttributeValue
        }
        tags {
          ...ContentTagInfo
        }
      }
    }
  }
`)

const CONTENT_ATTRIBUTE_UPSERT_GQL = graphql(`
  mutation UpsertContentAttribute($input: CustomContentAttributeSchemaInput!) {
    upsertCustomContentAttributesSchema(schemaDef: $input)
  }
`)

const upsertGQLContentAttribute = async (input: CustomContentAttributeSchemaInput): Promise<boolean> => {
  return graphQueryFn(CONTENT_ATTRIBUTE_UPSERT_GQL, { input }).then(
    x => x.upsertCustomContentAttributesSchema
  )
}

export const useContentAttributes = (): {
  query: UseQueryResult<ContentAttributesQuery, unknown>
  attributes: ContentAttribute[]
  upsertContentAttribute: (_: CustomContentAttributeSchemaInput) => void
} => {
  const contentAttributes = useGraphQuery({
    document: CONTENT_ATTRIBUTE_GQL,
    queryOptions: {},
  })

  const upsert = useMutation({
    mutationFn: (data: CustomContentAttributeSchemaInput) => {
      return upsertGQLContentAttribute(data)
    },
    onSuccess: () => {
      void contentAttributes.refetch()
    },
  })

  const upsertContentAttribute = useCallback(
    (ca: CustomContentAttributeSchemaInput) => upsert.mutate(ca),
    [upsert]
  )

  const transformedAttributes: ContentAttribute[] = useMemo(() => {
    const attributes =
      contentAttributes.data?.contentAttributesSchema.customAttributeDefs.map(attr => GQL2CAT(attr)) ?? []
    return attributes
  }, [contentAttributes])

  return useMemo(
    () => ({
      query: contentAttributes,
      attributes: transformedAttributes,
      upsertContentAttribute,
    }),
    [contentAttributes, transformedAttributes, upsertContentAttribute]
  )
}

export const useContentAttributesForCourse = (
  courseId: string
): {
  query: UseQueryResult<ContentAttributesForCourseQuery, unknown>
  attributes: ContentAttributeWithValue[]
} => {
  const contentAttributes = useGraphQuery(
    {
      document: CONTENT_ATTRIBUTE_FOR_COURSE_GQL,
    },
    { id: courseId }
  )

  const transformedAttributes: ContentAttributeWithValue[] = useMemo(() => {
    const attributes =
      contentAttributes.data?.course?.attributes.map(attr => {
        const { values, def } = attr
        return { value: values[0] ?? '', ...GQL2CAT(def) }
      }) ?? []
    return attributes
  }, [contentAttributes])

  return useMemo(
    () => ({
      query: contentAttributes,
      attributes: transformedAttributes,
    }),
    [contentAttributes, transformedAttributes]
  )
}

// TODO(CATs): Removed mock data

const SELECTED_QUERY_KEY = ['selected-content-attributes']

const mockDataValues = (): Promise<Array<ContentAttributeWithValue>> =>
  Promise.resolve(MOCKED_ATTRIBUTE_VALUES)

export const useContentAttributeValues = (): {
  query: UseQueryResult<Array<ContentAttributeWithValue>, Error>
} => {
  const query = useQuery({
    queryKey: SELECTED_QUERY_KEY,
    queryFn: mockDataValues,
  })

  return { query }
}
