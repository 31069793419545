export const null2Undefined = <T>(value: T | null | undefined): T | undefined =>
  value === null ? undefined : value

type NonNullProperties<T> = T extends null
  ? undefined
  : T extends Record<string, unknown>
    ? { [K in keyof T]: NonNullProperties<T[K]> }
    : T

// Helper to remove all null values deep. Check test (that should be in the same folder as this file) for examples
export const null2UndefinedDeep = <T>(value: T): NonNullProperties<T> => {
  // Check if primitive or is null value
  if (typeof value !== 'object' || value === null) {
    return null2Undefined(value) as NonNullProperties<T>
  }

  if (Array.isArray(value)) {
    return value.map(null2UndefinedDeep) as NonNullProperties<T>
  }

  return Object.fromEntries(
    Object.entries(value).map(([k, v]) => [k, null2UndefinedDeep(v)])
  ) as NonNullProperties<T>
}
