import { useEffect } from 'react'
import { useHasUser } from 'sierra-client/api/hooks/use-user'
import { config } from 'sierra-client/config/global-config'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { selectCurrentCourseId, selectHasLoadedCourseContent } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchCourseDataById, fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'

// Fetch new course data whenever the current course changes

export const CourseContentFetcher = (): null => {
  const pathname = usePathname()
  const dispatch = useDispatch()
  const currentCourseId = useSelector(selectCurrentCourseId)
  const courseContentIsLoaded = useSelector(selectHasLoadedCourseContent)
  const hasUser = useHasUser()

  useEffect(() => {
    if (!hasUser || currentCourseId === undefined || pathname.startsWith('/create')) return

    void dispatch(fetchCourseStatusById({ courseId: currentCourseId }))

    if (courseContentIsLoaded) return

    void (async () => {
      // If fetching fails (404 or other), redirect elsewhere.
      const courseDataResult = await dispatch(fetchCourseDataById({ courseId: currentCourseId }))

      if (courseDataResult.meta.requestStatus === 'rejected') {
        if (config.scorm.isScorm) {
          // We can't redirect to the home page for SCORM users. Just throw an error.
          throw new Error("Couldn't load course for SCORM user")
        }
      }
    })()
  }, [courseContentIsLoaded, currentCourseId, dispatch, pathname, hasUser])

  return null
}
