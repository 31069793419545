import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { getFlag } from 'sierra-client/config/global-config'

export function useDeveloperToolsEnabled(): boolean {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const isOwner = useIsOwnerUser()
  const developerToolsEnabled = getFlag('developer-tools')

  return isDevelopment || isOwner || developerToolsEnabled
}
