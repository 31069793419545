import { useState } from 'react'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ContentAttributeCard,
  CreateAttributeCard,
  SystemDefaultCard,
} from 'sierra-client/views/manage/content-attributes/card'
import { useContentAttributes } from 'sierra-client/views/manage/content-attributes/hooks'
import { ContentAttributeForm } from 'sierra-client/views/manage/content-attributes/side-panels/form'
import { UpsertAttributeSidePanel } from 'sierra-client/views/manage/content-attributes/side-panels/upsert'
import {
  ContentAttribute,
  EditedCAT2GQL,
  tags2Categories,
} from 'sierra-client/views/manage/content-attributes/types'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { isDefined } from 'sierra-domain/utils'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ViewWrapper = styled(View).attrs({ direction: 'column', grow: true, gap: 'none' })`
  height: 100%;
  width: 100%;
`

const VerticalDivider = styled.hr`
  transform: rotate(90deg);
  background-color: ${token('border/strong')};
  height: 1px;
  width: 20px;
  margin: 0px;
`

export const ContentAttributesPage: React.FC = () => {
  const { t } = useTranslation()
  const [isCreating, setCreating] = useState(false)
  const [attributeToEdit, setEditing] = useState<ContentAttribute | null>(null)
  const { query: data, attributes, upsertContentAttribute } = useContentAttributes()

  // TODO(HUY, FANNY)
  if (data.isLoading) {
    return <div>Loading...</div>
  }
  if (data.isError) {
    return <div>Error...</div>
  }

  return (
    <>
      <ViewWrapper>
        <PageTitle title={t('dictionary.content-attributes')} />
        <PageHeader title='dictionary.content-attributes' withoutMargin />
        <View direction='column' gap='24'>
          <View justifyContent='space-between'>
            <View gap='none'>
              <IconButton iconId='search' variant='secondary' />
              <VerticalDivider />
              <Button icon='filter' variant='secondary'>
                {t('dictionary.filter')}
              </Button>
            </View>
            <Button onClick={() => setCreating(true)}>{t('manage.content-attributes.cards.new.text')}</Button>
          </View>

          <View wrap='wrap' gap='16'>
            <SystemDefaultCard
              onClick={() => setEditing(tags2Categories(data.data?.contentAttributesSchema.tagsDef ?? []))}
              attribute={{
                name: 'Category',
                description: 'All categories',
                options: data.data?.contentAttributesSchema.tagsDef.map(tag => tag.name) ?? [],
              }}
            />
            {attributes
              .filter(attribute => attribute.type !== 'categories')
              .map(attribute => (
                <ContentAttributeCard
                  onClick={a => setEditing(a)}
                  key={attribute.name}
                  attribute={attribute}
                />
              ))}
            <CreateAttributeCard onClick={() => setCreating(true)} />
          </View>
        </View>
      </ViewWrapper>
      <UpsertAttributeSidePanel
        key='create'
        title={t('manage.content-attributes.cards.new.text')}
        onClose={() => {
          setCreating(false)
        }}
        isOpen={isCreating}
      >
        <ContentAttributeForm
          onCancel={() => setCreating(false)}
          onSubmit={data => {
            setCreating(false)
            upsertContentAttribute(EditedCAT2GQL(data))
          }}
        />
      </UpsertAttributeSidePanel>
      <UpsertAttributeSidePanel
        key='edit'
        title={t('manage.content-attributes.cards.edit.text')}
        isOpen={isDefined(attributeToEdit)}
        onClose={() => setEditing(null)}
      >
        {isDefined(attributeToEdit) && (
          <ContentAttributeForm
            onCancel={() => setEditing(null)}
            attributeToEdit={attributeToEdit}
            onSubmit={data => {
              setEditing(null)
              upsertContentAttribute(EditedCAT2GQL(data))
            }}
          />
        )}
      </UpsertAttributeSidePanel>
    </>
  )
}
