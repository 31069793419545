import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { CourseFallback } from 'sierra-client/views/course-helper/content/fallback/fallback'
import { NextUpCourseInPathOrProgram } from 'sierra-client/views/course-helper/content/next-course-in-path-or-program'
import { HelperContent } from 'sierra-client/views/course-helper/shared/shared'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StartButtonIcon = styled(Icon)`
  opacity: 0.6;
`

export const NextCourseInPath: React.FC<{
  tutorState: TutorState
}> = ({ tutorState }) => {
  const { t } = useTranslation()
  const { nextUp, goToNextUp } = useSelfPacedFiles()
  if (nextUp.type !== 'next-course-in-path') return <CourseFallback />

  const pathTitle = nextUp.pathTitle

  const goToPathIdPage = (): void =>
    void getGlobalRouter().navigate({
      to: '/path/$pathId',
      params: { pathId: nextUp.pathId },
    })

  return (
    <HelperContent tutorState={tutorState} title={t('helper.program-pending')} icon='racing--flag'>
      <Text color='foreground/secondary' bold>
        {t('helper.next-up-in', {
          title: pathTitle,
        })}
      </Text>

      <NextUpCourseInPathOrProgram
        course={nextUp.course}
        courseTitle={nextUp.courseTitle}
        goToNextUp={() => goToNextUp(nextUp)}
        goToOverViewPage={goToPathIdPage}
        buttons={
          <View grow justifyContent='flex-end' marginTop='48' gap='8'>
            <Button variant='secondary' onClick={goToPathIdPage}>
              {t('helper.path-overview')}
            </Button>
            <Button
              onClick={() => goToNextUp(nextUp)}
              decoratorPosition='right'
              customDecorator={<StartButtonIcon iconId='next--filled' color='button/foreground' />}
            >
              {t('helper.start')}
            </Button>
          </View>
        }
      />
    </HelperContent>
  )
}
