import { useEffect } from 'react'
import { useIsCurrentUserCourseCollaborator } from 'sierra-client/api/queries/course-collaborators'
import { SelfPacedVideoCard } from 'sierra-client/components/liveV2/cards/video-player-card'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { useDispatch } from 'sierra-client/state/hooks'
import { SelfPacedCardEndReached } from 'sierra-client/views/course-helper/shared/self-paced-card-end-reached'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { LearnerImageCard } from 'sierra-client/views/flexible-content/image-card'
import { ShuffleElementsProvider } from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { SelfPacedSlate } from 'sierra-client/views/self-paced/self-paced-slate'
import { SelfPacedCard } from 'sierra-client/views/self-paced/use-self-paced-card'
import { SelfPacedDropAWordDataProvider } from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { SelfPacedDropAWordCard } from 'sierra-client/views/v3-author/drop-a-word/self-paced-drop-a-word-card'
import { SelfPacedHomeworkCard } from 'sierra-client/views/v3-author/homework/homework-card'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-learner-view'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { ScenarioStarter } from 'sierra-client/views/v3-author/scenario/scenario-starter'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { assertNever } from 'sierra-domain/utils'

const Switch: React.FC<{
  card: SelfPacedCard
  courseId: SelfPacedContentId
  tutorState: TutorState
}> = ({ card, courseId, tutorState }) => {
  const isCollaborator = useIsCurrentUserCourseCollaborator({ courseId })

  if (card.withEditor) {
    switch (card.file.data.type) {
      case 'poll':
      case 'flip-cards':
      case 'reflections':
      case 'question-card':
      case 'assessment-card':
      case 'slate-card':
      case 'bullet':
      case 'general':
      case 'sliding-scale':
        return (
          <>
            <ShuffleElementsProvider>
              <SelfPacedSlate
                tutorState={tutorState}
                courseId={courseId}
                file={card.file}
                editor={card.editor}
              />
            </ShuffleElementsProvider>
            <SelfPacedCardEndReached file={card.file} />
          </>
        )

      case 'homework':
        return (
          <>
            <ShuffleElementsProvider>
              <SelfPacedHomeworkCard courseId={courseId} fileId={card.file.id} data={card.file.data}>
                <SelfPacedSlate
                  tutorState={tutorState}
                  courseId={courseId}
                  file={card.file}
                  editor={card.editor}
                />
              </SelfPacedHomeworkCard>
            </ShuffleElementsProvider>
            <SelfPacedCardEndReached file={card.file} />
          </>
        )
      default:
        assertNever(card.file.data)
    }
  } else {
    switch (card.file.data.type) {
      case 'stupid-questions':
        return (
          <>
            <QACard location='learner' fileId={card.file.id} courseId={courseId} />
            <SelfPacedCardEndReached file={card.file} />
          </>
        )
      case 'scenario':
        return (
          <ShuffleElementsProvider>
            <ScenarioStarter file={card.file} courseId={courseId} />
            <SelfPacedCardEndReached file={card.file} />
          </ShuffleElementsProvider>
        )
      case 'video':
        return (
          <>
            <SelfPacedVideoCard video={card.file.data.video} />
            <SelfPacedCardEndReached file={card.file} />
          </>
        )
      case 'image':
        return (
          <>
            <LearnerImageCard data={card.file.data} assetContext={{ type: 'course', courseId }} />
            <SelfPacedCardEndReached file={card.file} />
          </>
        )

      case 'embed':
        return (
          <>
            <EmbedCard readOnly data={card.file.data} />
            <SelfPacedCardEndReached file={card.file} />
          </>
        )

      case 'drop-a-word':
        return (
          <SelfPacedDropAWordDataProvider
            contentId={courseId}
            fileId={card.file.id}
            isCollaborator={isCollaborator}
            isInteractive
          >
            <SelfPacedDropAWordCard fileData={card.file.data} />
          </SelfPacedDropAWordDataProvider>
        )

      default:
        assertNever(card.file.data)
    }
  }
}

export const SelfPacedCardRenderer: React.FC<{
  card: SelfPacedCard
  scopedCreateContentId: ScopedSelfPacedContentId
  tutorState: TutorState
}> = ({ card, scopedCreateContentId, tutorState }) => {
  const dispatch = useDispatch()

  const courseId = ScopedSelfPacedContentId.extractId(scopedCreateContentId)
  const { file } = card
  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: courseId,
        fileId: file.id,
        cardType: file.data.type,
        mode: 'self-paced',
      })
    )
  }, [courseId, dispatch, file.data.type, file.id])

  return (
    <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
      <Switch card={card} courseId={courseId} tutorState={tutorState} />
    </FileContext>
  )
}
