/* eslint-disable no-restricted-imports */
// Translation of the app
//
// This uses the library over at "../translations". At module-load time,
// it sets the language to use as the first available of 1) the logged-in
// user's language, 2) the first language in the browser's accept-language
// list (navigator.languages[0]), 3) the browser's "preferred" language
// (navigator.language), 4) English.
//
// It then default exports a function t(path, variables = {}) that translates
// the given path into that language.
//
// It also exports a method resetLanguage for running the whole language
// detection again (for example after having changed language settings).
//
// Usage in a component:
//
// import { t } from 'i18n'
// const SomeComponent = ({ name }) =>
//   <h1>{t('homepage.greeting', { name })}</h1>

import { useQuery } from '@tanstack/react-query'
import { Settings as LuxonSettings } from 'luxon'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { config } from 'sierra-client/config/global-config'
import { sierraI18n } from 'sierra-client/i18n/i18n'

export const LanguageUpdater = (): null => {
  const {
    i18n: { language },
  } = useTranslation()

  const orgConfig = config.organization
  const { data: userData } = useQuery({
    ...currentUserQuery,
    select: user => ({ language: user.language, isScormUser: user.isScormUser }),
  })
  const isScormUser = userData?.isScormUser

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const targetLanguage = userData?.language || orgConfig.defaultLanguage || language || 'en'

  useEffect(() => {
    // SCORM language is derived from the URL only.
    if (targetLanguage !== language && isScormUser === false) {
      document.documentElement.lang = targetLanguage
      LuxonSettings.defaultLocale = targetLanguage
      void sierraI18n.changeLanguage(targetLanguage)
    }
  }, [targetLanguage, language, isScormUser])

  return null
}
