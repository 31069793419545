import { createFileRoute } from '@tanstack/react-router'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { queryClient } from 'sierra-client/api/query-client'
import { getFlag } from 'sierra-client/config/global-config'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ContentAttributesPage } from 'sierra-client/views/manage/content-attributes'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageContentAttributesPage(): JSX.Element | null {
  const showContentAttributes = getFlag('content-attributes')

  if (!showContentAttributes) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageContentAttributes()}>
      <ContentAttributesPage />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageContentAttributesPage

export const Route = createFileRoute('/manage/content-attributes/')({
  component: RouteComponent as React.FC,
  loader: () => {
    void queryClient.prefetchQuery(currentUserQuery)
  },
})
