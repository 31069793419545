import { FC } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategySelfPacedCreateCourseLearningOutcomes } from 'sierra-domain/routes'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Outcome = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`

export const LearningOutcomesPanel: FC<{ courseId: CreateContentId }> = ({ courseId }) => {
  const createLearningOutcomesMutation = useCachedQuery(
    XRealtimeStrategySelfPacedCreateCourseLearningOutcomes,
    {
      courseId,
    },
    {
      enabled: false,
    }
  )

  return (
    <View overflow='auto' gap='xsmall' direction='column'>
      <Text size='large' bold>
        Learning Outcomes
      </Text>
      <Button
        onClick={() => createLearningOutcomesMutation.refetch()}
        loading={createLearningOutcomesMutation.isLoading || createLearningOutcomesMutation.isRefetching}
      >
        Generate Learning Outcomes
      </Button>
      <View gap='xsmall' direction='column'>
        {createLearningOutcomesMutation.data?.outcomes.map(outcome => (
          <Outcome key={outcome.title}>
            <View gap='4'>
              {outcome.fileIds.map(id => (
                <Text key={id} size='micro'>
                  {id}
                </Text>
              ))}
            </View>
            <Text bold>{outcome.title}</Text>
            <Text>{outcome.description}</Text>
          </Outcome>
        ))}
      </View>
    </View>
  )
}
