import { FCC } from 'sierra-client/types'
import { isDefined } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const SlotContainer = styled.div``

const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex-direction: row;

    ${SlotContainer} {
      margin-left: auto;
    }
  }
`

const StyledIcon = styled(Icon)`
  padding: 12px;
  border: 1px solid ${token('border/default')};
  border-radius: 10px;
`

export const PermissionFormSection: FCC<{ iconId?: IconId; title: string; subtitle: string }> = ({
  children,
  iconId,
  title,
  subtitle,
}) => {
  return (
    <Container>
      <View alignItems='center'>
        {isDefined(iconId) && (
          <View>
            <StyledIcon color='foreground/primary' iconId={iconId} size='size-14' />
          </View>
        )}
        <View direction='column' gap='none'>
          <Text size='small' bold>
            {title}
          </Text>
          <Text size='small' color='foreground/muted'>
            {subtitle}
          </Text>
        </View>
      </View>
      <SlotContainer>{children}</SlotContainer>
    </Container>
  )
}
