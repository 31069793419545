import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { Empty } from 'sierra-client/core/logging/page/logger'
import { SanaPage } from 'sierra-client/layout/sana-page'
import { isOwnerUser } from 'sierra-domain/access-level'

export function requireOwner<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>
): React.ComponentType<T> {
  return (props: T) => {
    const { data: isOwner, isLoading } = useQuery({ ...currentUserQuery, select: isOwnerUser })

    if (isLoading || isOwner === undefined) {
      return <SanaPage page={Empty({ message: 'Checking if the user is an owner' })} />
    }

    if (!isOwner) {
      alert('You do not have permission to view this page')
      return <Navigate to='/' />
    }

    return <Component {...props} />
  }
}
