import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-dom-props */
import {
  closestCorners,
  DndContext,
  DraggableAttributes,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { CSSProperties, FC, forwardRef, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useReward } from 'react-rewards'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { StickyNoteEditor } from 'sierra-client/views/v3-author/project-card/editor'
import { getSectionNoteIds, useNote } from 'sierra-client/views/v3-author/project-card/helpers'
import {
  Avatar,
  BaseNoteContainer,
  KanbanContainer,
  NoteBody,
  NoteFooter,
  SectionGrid,
  SectionHeader,
} from 'sierra-client/views/v3-author/project-card/shared-styles'
import { Data } from 'sierra-client/views/v3-author/project-card/types'
import {
  arrayMoveSafeguard,
  NoteId,
  SectionId,
  StickyNotesCardYjsApi,
} from 'sierra-domain/card/sticky-notes-card'
import { assert } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { IconButton, Spacer, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'
import { Awareness } from 'y-protocols/awareness'

type NoteProps = {
  $isActive?: boolean
  $isOverlay?: boolean
  $isDragging?: boolean
  $isCompleted?: boolean
}
const Note = styled(BaseNoteContainer)<NoteProps>`
  cursor: ${p => (p.$isOverlay === true ? 'grabbing' : 'grab')};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: white;

  ${p =>
    !(p.$isActive === true) &&
    !(p.$isOverlay === true) &&
    css`
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    `};

  ${p =>
    p.$isActive === true &&
    css`
      outline: 1px solid rgba(0, 0, 0, 0.08);
      outline-offset: -1px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    `};

  ${p =>
    p.$isDragging === true &&
    css`
      opacity: 0;
    `};

  ${p =>
    p.$isOverlay === true &&
    css`
      box-shadow: 0px 16px 28px 0px rgba(0, 0, 0, 0.08);
    `};

  &:hover {
    background-color: ${() => color('white').opacity(0.97).toString()};
  }

  ${p =>
    p.$isCompleted === true &&
    css`
      transition: opacity 0.2s ease;
      opacity: 0.4;
    `}
`

const NewNoteContainer = styled(BaseNoteContainer)`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem;

  ${fonts.body.small};
  color: rgba(0, 0, 0, 0.25);
  background-color: ${palette.grey[5]};

  &:hover {
    background-color: ${p => p.theme.color.grey10};
    color: rgba(0, 0, 0, 0.4);
  }
`

const AddIcon = styled(Icon).attrs({ iconId: 'add--alt' })`
  color: inherit;
`

const NewNote: FC<{ onClick: () => void }> = ({ onClick }) => (
  <NewNoteContainer onClick={onClick} tabIndex={-1}>
    <AddIcon />
    <Spacer size='xxsmall' />
    <p>Add task</p>
  </NewNoteContainer>
)
const useNoteIdToSectionIdMap = (api: StickyNotesCardYjsApi): Record<NoteId, SectionId> | undefined => {
  const [map, setMap] = useState<Record<NoteId, SectionId>>()

  useEffect(() => {
    const handler = (): void =>
      setMap(Object.fromEntries(api.getPositionArray().map(pos => [pos.noteId, pos.sectionId])))

    handler()

    api.yPositionArray.observe(handler)

    return () => api.yPositionArray.unobserve(handler)
  }, [api])

  return map
}
const Item = forwardRef<
  HTMLDivElement,
  {
    noteId: NoteId
    awareness: Awareness
    api: StickyNotesCardYjsApi
    isOverlay?: boolean
    isDragging?: boolean
    style?: CSSProperties
    attributes?: DraggableAttributes
    listeners?: SyntheticListenerMap
    rewardIsAnimating: boolean
    setRewardIsAnimating: React.Dispatch<React.SetStateAction<boolean>>
  }
>(
  (
    {
      noteId,
      awareness,
      api,
      isOverlay,
      isDragging,
      attributes,
      listeners,
      style,
      rewardIsAnimating,
      setRewardIsAnimating,
    },
    ref
  ) => {
    const [metadata, setMetadata] = useNote(api, noteId)
    const currentUser = useSelector(selectUser)
    const [isActive, setIsActive] = useState(false)

    const { reward: rewardLeft } = useReward('confetti-left', 'confetti', {
      angle: 50,
      elementCount: 100,
      startVelocity: 70,
      lifetime: 300,
      onAnimationComplete: () => setRewardIsAnimating(false),
    })

    const isCompleted = metadata === undefined ? undefined : Object.keys(metadata.reactions).length > 0

    useOnChanged((prev, current) => {
      if (prev === false && current === true && !rewardIsAnimating) {
        setRewardIsAnimating(true)
        rewardLeft()
      }
    }, isCompleted)

    if (!metadata || !currentUser) return null

    // menuItems.sort(u1 => (u1.id === currentUser.uuid ? -1 : u1.id === metadata.userId ? -1 : 0))

    return (
      <Note
        ref={ref}
        style={style}
        $isActive={isActive}
        $isCompleted={isCompleted}
        $isOverlay={isOverlay}
        $isDragging={isDragging}
        {...attributes}
        {...listeners}
        tabIndex={-1}
      >
        <NoteBody className='NoteBody' id={`note-${noteId}`}>
          <StickyNoteEditor
            noteId={noteId}
            awareness={awareness}
            api={api}
            user={currentUser}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
          />
        </NoteBody>
        <NoteFooter>
          <Avatar userId={metadata.userId} />

          <View grow />
          {isActive ? (
            <>
              <IconButton
                variant='transparent'
                iconId='trash-can'
                onClick={() => {
                  api.deleteNote(noteId)
                }}
                tabIndex={-1}
                tooltip='Delete task'
              />
            </>
          ) : (
            <>
              <Spacer size='4' />
              <IconButton
                variant='transparent'
                iconId={isCompleted === true ? 'checkmark--filled' : 'checkmark--outline'}
                onClick={() => {
                  setMetadata(previous => {
                    if (isCompleted === true) {
                      return { ...previous, reactions: {} }
                    } else {
                      return {
                        ...previous,
                        reactions: {
                          [currentUser.uuid]: { timestamp: new Date().toISOString() },
                        },
                      }
                    }
                  })
                }}
                tabIndex={-1}
                tooltip='Mark as complete'
              />
            </>
          )}
        </NoteFooter>
      </Note>
    )
  }
)

const SortableItem: FC<{
  noteId: string
  awareness: Awareness
  api: StickyNotesCardYjsApi
  rewardIsAnimating: boolean
  setRewardIsAnimating: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ noteId, awareness, api, rewardIsAnimating, setRewardIsAnimating }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: noteId,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      noteId={noteId}
      awareness={awareness}
      api={api}
      style={style}
      attributes={attributes}
      listeners={listeners}
      isDragging={isDragging}
      rewardIsAnimating={rewardIsAnimating}
      setRewardIsAnimating={setRewardIsAnimating}
    />
  )
}

const OverlayItem: FC<{
  noteId: string
  awareness: Awareness
  api: StickyNotesCardYjsApi
  rewardIsAnimating: boolean
  setRewardIsAnimating: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ noteId, awareness, api, rewardIsAnimating, setRewardIsAnimating }) => {
  return (
    <Item
      noteId={noteId}
      awareness={awareness}
      api={api}
      isOverlay
      rewardIsAnimating={rewardIsAnimating}
      setRewardIsAnimating={setRewardIsAnimating}
    />
  )
}

/**
 * Yjs doesn't yet have native array move functionality. Kevin says he will release it in a few months.
 * Meanwhile, this observer ensures that content is not duplicated when two clients move the same item at the same time.
 * The easiest implementation is to keep the last unique item and delete the rest:
 */
const useArrayMoveSafeguard = (api: StickyNotesCardYjsApi): void => {
  useEffect(() => {
    const handler = (): void => arrayMoveSafeguard(api)

    api.yPositionArray.observe(handler)

    return () => api.yPositionArray.unobserve(handler)
  }, [api])
}

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

const DroppableContainer: FCC<{ noteIds: NoteId[]; id: SectionId }> = ({ noteIds, id, children }) => {
  const { setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: noteIds,
    },
    animateLayoutChanges,
  })

  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      {children}
    </div>
  )
}

export const LiveProjectCardView: React.FC<{
  awareness: Awareness
  api: StickyNotesCardYjsApi
  sections: Data['sections']
}> = ({ awareness, api, sections }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const noteIdToSectionIdMap = useNoteIdToSectionIdMap(api)
  const [draggingId, setDraggingId] = useState<UniqueIdentifier>()
  const recentlyMovedToNewContainer = useRef(false)
  const [rewardIsAnimating, setRewardIsAnimating] = useState(false)

  const liveSession = useLiveSessionContext()
  const contentId = liveSession.data.flexibleContentId

  useEffect(() => {
    if (user?.accessLevel !== 'guest') {
      void dispatch(settingsActions.fetch({ courseId: contentId }))
    }
  }, [dispatch, contentId, user])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  )

  useArrayMoveSafeguard(api)

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [noteIdToSectionIdMap])

  if (!user) return <>Loading user...</>
  if (!noteIdToSectionIdMap) return <>Loading notes...</>

  const sectionIds = sections.map(section => section.id)

  /* We are basing the dnd functionality this on the following example from dnd-kita
   * https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/presets-sortable-multiple-containers--vertical-grid
   * The code is here: https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx
   */
  return (
    <KanbanContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        // measuring={{
        //   droppable: {
        //     strategy: MeasuringStrategy.Always,
        //   },
        // }}
        onDragStart={event => {
          setDraggingId(event.active.id)
        }}
        onDragOver={({ active, over }) => {
          const overId = over?.id
          if (overId === undefined) return

          const activeId = active.id
          assert(typeof activeId === 'string' && typeof overId === 'string')

          const activeSectionId = noteIdToSectionIdMap[activeId]
          const isOverSection = overId.startsWith('section:')
          const overSectionId = isOverSection ? overId.replace(/^section:/, '') : noteIdToSectionIdMap[overId]

          assert(activeSectionId !== undefined && overSectionId !== undefined)

          const activeSection = sections.find(section => section.id === activeSectionId)
          const overSection = sections.find(section => section.id === overSectionId)

          if (!activeSection || !overSection) return
          if (activeSection === overSection) return
          let newIndex: number

          const overSectionNoteIds = getSectionNoteIds(api, overSectionId, sectionIds)
          const overIndex = overSectionNoteIds.indexOf(overId)
          if (isOverSection) {
            newIndex = overSectionNoteIds.length + 1
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height

            const modifier = isBelowOverItem === true ? 1 : 0

            newIndex = overIndex >= 0 ? overIndex + modifier : overSectionNoteIds.length + 1
          }

          console.debug('[onDragOver]', activeSectionId, overSectionId, activeId, overId, newIndex)

          recentlyMovedToNewContainer.current = true

          const noteIdBeforeNewIndex = overSectionNoteIds[newIndex - 1]

          // TODO: Consider having a local copy, so that we only propagate to remote when we drop the note
          const positionArray = api.getPositionArray()
          const activeGlobalIndex = positionArray.findIndex(pos => pos.noteId === activeId)
          assert(activeGlobalIndex >= 0)

          const newGlobalIndex = positionArray.findIndex(pos => pos.noteId === noteIdBeforeNewIndex) + 1

          api.yData.doc?.transact(() => {
            api.yPositionArray.delete(activeGlobalIndex)
            const insertIndex = Math.min(newGlobalIndex, api.yPositionArray.length)
            api.yPositionArray.insert(insertIndex, [{ sectionId: overSection.id, noteId: activeId }])
          })
        }}
        onDragEnd={({ active, over }) => {
          setDraggingId(undefined)

          const activeId = active.id
          assert(typeof activeId === 'string')

          const overId = over?.id
          if (overId === undefined) return
          assert(typeof overId === 'string')

          const activeSectionId = noteIdToSectionIdMap[activeId]
          const overSectionId = overId.startsWith('section:')
            ? overId.replace(/^section:/, '')
            : noteIdToSectionIdMap[overId]

          assert(overSectionId !== undefined && activeSectionId !== undefined)

          if (activeSectionId === overSectionId) {
            api.moveNotePos(activeId, overId)
          } else {
            throw new Error('Not implemented')
          }
        }}
      >
        {sections.map(section => {
          const sectionNoteIds = getSectionNoteIds(api, section.id, sectionIds)

          const addNote = (pos: 'start' | 'end'): void => {
            if (api.yMetadataMap.size >= 200) {
              const msg = "You've reached the limit of 200 sticky notes"
              console.warn(msg)
              alert(msg)
              return
            }

            const noteId = api.addNote({
              pos,
              userId: user.uuid,
              sectionId: section.id,
              color: 'white',
            })

            setTimeout(() => {
              document.querySelector<HTMLElement>(`#note-${noteId} .ProseMirror`)?.focus()
            }, 0)
          }

          return (
            <DroppableContainer key={section.id} noteIds={sectionNoteIds} id={`section:${section.id}`}>
              <SectionGrid>
                <SectionHeader>
                  <span>{section.title}</span>
                  <View grow />
                  <IconButton
                    iconId='add--alt'
                    variant='transparent'
                    tabIndex={-1}
                    onClick={() => addNote('start')}
                    tooltip='Add task'
                  />
                </SectionHeader>
                <SortableContext items={sectionNoteIds} strategy={rectSortingStrategy}>
                  {sectionNoteIds.map(noteId => (
                    <SortableItem
                      key={noteId}
                      noteId={noteId}
                      awareness={awareness}
                      api={api}
                      rewardIsAnimating={rewardIsAnimating}
                      setRewardIsAnimating={setRewardIsAnimating}
                    />
                  ))}
                </SortableContext>
                <NewNote onClick={() => addNote('end')} />
              </SectionGrid>
            </DroppableContainer>
          )
        })}
        {createPortal(
          <DragOverlay>
            {typeof draggingId === 'string' ? (
              <OverlayItem
                noteId={draggingId}
                awareness={awareness}
                api={api}
                rewardIsAnimating={rewardIsAnimating}
                setRewardIsAnimating={setRewardIsAnimating}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </KanbanContainer>
  )
}
