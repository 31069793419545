import React, { useEffect, useRef, useState } from 'react'
import { subscribeSse } from 'sierra-client/state/api'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SSEXRealtimeCreateEli5 } from 'sierra-domain/routes-sse'

import { useMutation } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import { motion } from 'framer-motion'
import { shuffle, take, uniq } from 'lodash'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { Button } from 'sierra-ui/primitives/button/button'
import { View } from 'sierra-ui/primitives/views/view'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 16px;
  scrollbar-gutter: stable;
  overflow-x: scroll;
  background: linear-gradient(0deg, #e7d38c, #e7d38c);
`

const Content = styled.div`
  font-size: 17px;
  color: ${token('foreground/primary')};
  display: flex;
  flex-direction: column;

  & {
    h1 {
      font-size: 72px;
      font-weight: 800;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.2;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      font-family: 'SF Pro Rounded';
    }
    p {
      margin-bottom: 1em;
    }
    img {
      border-radius: 32px;
      padding: 4px;
      max-height: 300px;
      margin: 26px auto;
      max-width: 100%;
    }
    video {
      aspect-ratio: 16 / 9;
      width: 100%;
      background-color: black;
      border-radius: 32px;
      margin: 26px auto;
    }
    /* Lists */
    ul,
    ol {
      padding-left: 1.5em;
      margin-bottom: 1em;
    }
    li {
      margin-bottom: 0.5em;
    }
    li:last-child {
      margin-bottom: 0;
    }
    /* Code */
    code {
      font-family: monospace;
      padding: 0.2em 0.4em;
      border-radius: 6px;
      background-color: ${token('foreground/primary').opacity(0.2)};
      display: block;
      overflow-x: auto;
      max-width: 70ch;
    }
    /* Tables */
    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1em;
    }
    th,
    td {
      border: 1px solid ${token('foreground/primary').opacity(0.2)};
      padding: 8px 12px;
      text-align: left;
    }
    th {
      background-color: ${token('foreground/primary').opacity(0.05)};
      font-weight: 500;
    }
    tr:nth-child(even) {
      background-color: ${token('foreground/primary').opacity(0.02)};
    }
    a {
      color: ${token('foreground/primary')};
      text-decoration: underline;
    }
  }
`

const BackgroundImageStack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const BackgroundImage = styled(motion.img)<{ $index: number }>`
  --overlap: -30%;
  border-radius: 32px;
  width: 170px;
  height: 220px;
  object-fit: cover;
  transform: translateX(calc(var(--overlap) * ${p => p.$index}))
    rotate(calc(6.34deg * ${p => (p.$index % 2 === 0 ? -1 : 1)}));
`

export const QACardEli5: React.FC<{
  courseId: CreateContentId
  fileId: FileId
  switchToQaCard: (() => void) | undefined
  location: 'editor' | 'learner'
  applyToFile: 'current-file' | 'most-recent-page-card'
}> = ({ courseId, fileId, switchToQaCard, location, applyToFile }) => {
  const [eli5Content, setEli5Content] = useState<string>('')
  const [backgroundImages, setBackgroundImages] = useState<string[]>([])
  const abortControllerRef = useRef<AbortController | undefined>(undefined)
  useOnUnmount(() => {
    abortControllerRef.current?.abort()
  })

  const { mutate: generateEli5, error: generateEli5Error } = useMutation({
    mutationFn: async () => {
      abortControllerRef.current?.abort()
      const abortController = new AbortController()
      abortControllerRef.current = abortController

      setEli5Content('')
      setBackgroundImages([])
      await subscribeSse({
        route: SSEXRealtimeCreateEli5,
        input: {
          courseId,
          fileId,
          location,
          applyToFileId: applyToFile === 'current-file' ? fileId : undefined,
        },
        onMessage: event => {
          if (abortController.signal.aborted) {
            return
          }

          const data = event.data
          switch (data.type) {
            case 'result-event':
              setEli5Content(currentContent => currentContent + data.text)
              break
            case 'background-image-event':
              setBackgroundImages(curr => take(shuffle(uniq([...curr, ...data.urls])), 4))
              break
          }
        },
        signal: abortController.signal,
      })
      return {}
    },
  })

  if (generateEli5Error) {
    throw generateEli5Error
  }

  useEffect(() => {
    generateEli5()
  }, [generateEli5])

  return (
    <LightTokenProvider>
      <Container>
        {switchToQaCard !== undefined && (
          <View>
            <Button variant='ghost' onClick={switchToQaCard}>
              Switch to QA
            </Button>
          </View>
        )}
        <View justifyContent='center'>
          <View direction='column' grow gap={'16'} style={{ maxWidth: 720 }}>
            <BackgroundImageStack>
              {backgroundImages.map((image, index) => (
                <BackgroundImage
                  key={image}
                  src={image}
                  alt='background'
                  $index={index}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeIn',
                    delay: index * 0.4,
                  }}
                />
              ))}
            </BackgroundImageStack>
            <Content
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  eli5Content
                    .trim()
                    .replaceAll('```html', '')
                    .replaceAll('```', '')
                    .trim()
                    .replaceAll(/(>)\s*\n\s*(<)/g, '$1$2')
                    .replaceAll('<video', '<video controls')
                ),
              }}
            />
          </View>
        </View>
      </Container>
    </LightTokenProvider>
  )
}
