import { useQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'
import {
  listTeamspacesQuery,
  useBrowseTeamspaces,
  useMoveCoursesMutation,
  useTeamspaceById,
} from 'sierra-client/api/hooks/use-teamspace'
import { useNotif } from 'sierra-client/components/common/notifications'
import { TeamspaceIcon } from 'sierra-client/features/teamspace'
import { teamspaceContentMovedLogger } from 'sierra-client/features/teamspace/logger'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  TeamspaceName,
  useTeamspaceName,
} from 'sierra-client/views/flexible-content/editor/topbar/teamspace-name'
import { CourseKind } from 'sierra-domain/api/common'
import { NonEditorTeamspaceCourseType } from 'sierra-domain/api/editable-content'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { TeamspaceRoleWithOwner } from 'sierra-domain/api/teamspace'
import { ExtractFrom, assertNever, iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { MenuItem } from 'sierra-ui/components/menu/types'
import { Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const TeamspaceWrapper = styled(View)`
  max-width: 100%;
  overflow: hidden;
`

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  max-width: 25ch;
`

const getIsNonEditorCourseType = (courseType: string): boolean => {
  return NonEditorTeamspaceCourseType.safeParse(courseType).success
}

const TeamspaceDropdownCanvas: React.FC<{ teamspaceId: NanoId12 }> = ({ teamspaceId }) => {
  const teamspaceQuery = useTeamspaceById(teamspaceId)
  const teamspace = teamspaceQuery.data

  if (teamspace === undefined) return <></>
  return (
    <TeamspaceWrapper paddingTop='10' paddingBottom='10' gap='8'>
      <TeamspaceIcon small={true} displayName={teamspace.displayName} themeName={teamspace.iconTheme} />
      <EllipsisText color='foreground/primary' size='small' bold>
        {teamspace.displayName}
      </EllipsisText>
    </TeamspaceWrapper>
  )
}

const DisabledPrivateContentDropdown: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <DefaultDropdownTrigger grow disabled={true}>
        <Text as='span'>
          <View padding='none 4'>
            <Icon color='foreground/primary' iconId='view--off' />
            {t('teamspace.manage.private-teamspace')}
          </View>
        </Text>
      </DefaultDropdownTrigger>
      <Text color='foreground/muted'>{t('teamspace.manage.tooltip.private-content')}</Text>
    </>
  )
}

const LoadingContentDropdown: React.FC = () => {
  const { t } = useTranslation()
  return (
    <DefaultDropdownTrigger grow disabled={true}>
      <Text as='span'>
        <View padding='none 4'>
          <Icon color='foreground/primary' iconId='loading' />
          {t('admin.loading')}
        </View>
      </Text>
    </DefaultDropdownTrigger>
  )
}

const DropDownWithTeamspaceOptions: React.FC<{
  teamspaceDropDownItems: MenuItem[]
  nonTeamspaceDropDownItems: MenuItem[]
  moveContent: (
    targetTeamspaceId: NanoId12 | undefined,
    notificationBody: string | undefined,
    contentType: 'editable-content' | 'non-editor-content'
  ) => void
  currentItem: MenuItem | undefined
  itemName: string
  canMoveContent: boolean
  contentType: 'editable-content' | 'non-editor-content'
}> = ({
  teamspaceDropDownItems,
  nonTeamspaceDropDownItems,
  moveContent,
  currentItem,
  itemName,
  canMoveContent,
  contentType,
}) => {
  const { t } = useTranslation()
  const notification = useNotif()
  const isTeamspaceItem = teamspaceDropDownItems.find(item => item.id === currentItem?.id)

  return (
    <>
      <motion.div
        key='dropdown'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.1 }}
        transition={{ delay: 0.03 }}
      >
        <SingleSelectDropdown
          disabled={currentItem?.hidden === true && !canMoveContent}
          searchPlaceholder={t('menu.search.placeholder')}
          selectedItem={currentItem}
          menuItems={nonTeamspaceDropDownItems.concat(teamspaceDropDownItems)}
          onSelect={item => {
            if (item.id === currentItem?.id) {
              notification.push({
                type: 'custom',
                level: 'info',
                body: t('teamspace.move.already-in-teamspace') + ' ' + itemName,
              })
            } else {
              const targetId = item.id === 'my-content' ? undefined : NanoId12.parse(item.id)
              const notificationBody =
                item.id === 'my-content'
                  ? t('teamspace.move.already-in-teamspace') + ' ' + t('author.content.my-content')
                  : t('teamspace.move.successful') +
                    ' ' +
                    (item.label !== undefined ? item.label : t('teamspace.manage.teamspace'))
              moveContent(targetId, notificationBody, contentType)
            }
          }}
          withSearch
          renderTrigger={() => {
            if (currentItem === undefined) return <></>
            return (
              <DefaultDropdownTrigger grow>
                {isTeamspaceItem ? (
                  <TeamspaceDropdownCanvas teamspaceId={NanoId12.parse(currentItem.id)} />
                ) : (
                  <Text as='span'>
                    <View padding='none 4'>
                      {currentItem.type === 'label' && currentItem.icon !== undefined && (
                        <Icon color='foreground/primary' iconId={currentItem.icon} />
                      )}
                      {currentItem.type === 'label' && currentItem.label}
                    </View>
                  </Text>
                )}
              </DefaultDropdownTrigger>
            )
          }}
        />
      </motion.div>
      {currentItem?.hidden === true && !canMoveContent && (
        <Text color='foreground/muted'>{t('teamspace.manage.tooltip.private-content')}</Text>
      )}
    </>
  )
}

const EditableContentDropDown: React.FC<{
  teamspaceDropDownItems: MenuItem[]
  moveContent: (
    targetTeamspaceId: NanoId12 | undefined,
    notificationBody: string | undefined,
    contentType: 'editable-content' | 'non-editor-content'
  ) => void
  initialTeamspace: TeamspaceName
  canMoveContent: boolean
}> = ({ teamspaceDropDownItems, moveContent, initialTeamspace, canMoveContent }) => {
  const { t } = useTranslation()

  const nonTeamspaceDropDownItems = useMemo<MenuItem[]>(
    () => [
      {
        id: 'shared-with-me',
        value: 'shared-with-me',
        type: 'label',
        label: t('teamspace.manage.private-teamspace'),
        icon: 'view--off',
        hidden: true,
      },
      {
        id: 'my-content',
        value: 'my-content',
        type: 'label',
        label: t('author.content.my-content'),
        icon: 'locked',
        hidden: true,
      },
      {
        id: 'no-teamspace',
        value: 'no-teamspace',
        type: 'label',
        label: t('teamspace.manage.private-teamspace'),
        icon: 'view--off',
        hidden: true,
      },
    ],
    [t]
  )

  const currentItem =
    initialTeamspace.type === 'found' || initialTeamspace.type === 'not-found'
      ? nonTeamspaceDropDownItems.concat(teamspaceDropDownItems).find(item => item.id === initialTeamspace.id)
      : undefined

  return (
    <AnimatePresence mode='wait' initial={false}>
      {iife(() => {
        switch (initialTeamspace.type) {
          case 'loading':
            return <LoadingContentDropdown />
          case 'private-teamspace':
            return <DisabledPrivateContentDropdown />
          case 'found':
          case 'not-found': {
            if (initialTeamspace.type === 'not-found' && !canMoveContent) {
              return <DisabledPrivateContentDropdown />
            } else {
              return (
                <DropDownWithTeamspaceOptions
                  canMoveContent={canMoveContent}
                  nonTeamspaceDropDownItems={nonTeamspaceDropDownItems}
                  teamspaceDropDownItems={teamspaceDropDownItems}
                  currentItem={currentItem}
                  itemName={initialTeamspace.name}
                  moveContent={moveContent}
                  contentType='editable-content'
                />
              )
            }
          }
          default:
            assertNever(initialTeamspace)
        }
      })}
    </AnimatePresence>
  )
}

const NonEditorCoursesDropDown: React.FC<{
  teamspaceDropDownItems: MenuItem[]
  moveContent: (
    targetTeamspaceId: NanoId12 | undefined,
    notificationBody: string | undefined,
    contentType: 'editable-content' | 'non-editor-content'
  ) => void
  initialTeamspace: TeamspaceName
  canMoveContent: boolean
}> = ({ teamspaceDropDownItems, moveContent, initialTeamspace, canMoveContent }) => {
  const { t } = useTranslation()

  const nonTeamspaceDropDownItems = useMemo<MenuItem[]>(
    () => [
      {
        id: 'no-teamspace',
        value: 'no-teamspace',
        type: 'label',
        label: t('teamspace.manage.add-to-teamspace'),
        icon: 'folder--shared',
        hidden: true,
      },
      {
        id: 'shared-with-me',
        value: 'shared-with-me',
        type: 'label',
        label: t('teamspace.manage.add-to-teamspace'),
        icon: 'folder--shared',
        hidden: true,
      },
      {
        id: 'my-content',
        value: 'my-content',
        type: 'label',
        label: t('teamspace.manage.add-to-teamspace'),
        icon: 'folder--shared',
        hidden: true,
      },
    ],
    [t]
  )

  const currentItem =
    initialTeamspace.type === 'found' || initialTeamspace.type === 'not-found'
      ? nonTeamspaceDropDownItems.concat(teamspaceDropDownItems).find(item => item.id === initialTeamspace.id)
      : undefined

  return (
    <AnimatePresence mode='wait' initial={false}>
      {iife(() => {
        switch (initialTeamspace.type) {
          case 'loading':
            return <LoadingContentDropdown />
          case 'private-teamspace':
            return <DisabledPrivateContentDropdown />
          case 'found':
          case 'not-found': {
            if (initialTeamspace.type === 'not-found' && !canMoveContent) {
              return <DisabledPrivateContentDropdown />
            } else {
              return (
                <DropDownWithTeamspaceOptions
                  canMoveContent={canMoveContent}
                  nonTeamspaceDropDownItems={nonTeamspaceDropDownItems}
                  teamspaceDropDownItems={teamspaceDropDownItems}
                  currentItem={currentItem}
                  itemName={initialTeamspace.name}
                  moveContent={moveContent}
                  contentType='non-editor-content'
                />
              )
            }
          }
          default:
            assertNever(initialTeamspace)
        }
      })}
    </AnimatePresence>
  )
}

export const TeamspaceDropdown: React.FC<{
  courseId: CourseId
  courseType: ExtractFrom<
    CourseKind,
    | 'scorm'
    | 'link'
    | 'linkedin'
    | 'native:live'
    | 'native:self-paced'
    | 'native:event-group'
    | 'native:course-group'
    | 'scorm:course-group'
  >
}> = ({ courseId, courseType }) => {
  const { t } = useTranslation()
  const teamspacesQuery = useQuery(listTeamspacesQuery)
  const publicTeamspacesQuery = useBrowseTeamspaces()
  const initialTeamspace = useTeamspaceName(courseId)

  const { mutate } = useMoveCoursesMutation()
  const notification = useNotif()
  const dispatch = useDispatch()

  const permissions = useContentKindPermissions(courseId)
  const canMoveContent = permissions.has('ATTACH_TO_TEAMSPACE') && permissions.has('DEATTACH_FROM_TEAMSPACE')

  const moveContent = useCallback(
    (
      targetTeamspaceId: NanoId12 | undefined,
      notificationBody: string | undefined,
      contentType: 'editable-content' | 'non-editor-content'
    ): void => {
      mutate(
        { courseIds: [courseId], targetTeamspaceId },
        {
          onSuccess: () => {
            if (notificationBody !== undefined) {
              notification.push({
                type: 'custom',
                level: 'info',
                body: notificationBody,
              })

              void dispatch(
                teamspaceContentMovedLogger({
                  teamspaceId: targetTeamspaceId,
                  contentId: courseId,
                  movedBy: 'manage',
                  contentType: contentType,
                  parentFolderId: undefined,
                })
              )
            }
          },
        }
      )
    },
    [courseId, dispatch, mutate, notification]
  )

  type TeamspaceDropdownItem = {
    id: NanoId12
    displayName: string
    iconTheme: string | undefined
    effectiveRole: TeamspaceRoleWithOwner | undefined
  }

  const getTeamspaceDropDownItem = useCallback((teamspace: TeamspaceDropdownItem): MenuItem => {
    return {
      id: teamspace.id,
      type: 'canvas',
      label: teamspace.displayName,
      hidden: !(teamspace.effectiveRole === 'owner' || teamspace.effectiveRole === 'editor'),
      render: () => {
        return (
          <TeamspaceWrapper padding='none' gap='8'>
            <TeamspaceIcon small={true} displayName={teamspace.displayName} themeName={teamspace.iconTheme} />
            <EllipsisText color='foreground/primary' size='small' bold>
              {teamspace.displayName}
            </EllipsisText>
          </TeamspaceWrapper>
        )
      },
    }
  }, [])

  const userTeamspaceDropDownItems = useMemo<MenuItem[]>(
    () =>
      teamspacesQuery.data !== undefined
        ? teamspacesQuery.data.map(teamspace => {
            const teamspaceItem: TeamspaceDropdownItem = {
              id: teamspace.teamspaceRow.id,
              iconTheme: teamspace.teamspaceRow.iconTheme,
              displayName: teamspace.teamspaceRow.displayName,
              effectiveRole: teamspace.teamspaceRow.effectiveRole,
            }

            return getTeamspaceDropDownItem(teamspaceItem)
          })
        : [],
    [getTeamspaceDropDownItem, teamspacesQuery.data]
  )

  const availbleTeamspaceDropDownItems = useMemo<MenuItem[]>(
    () =>
      publicTeamspacesQuery.data !== undefined
        ? publicTeamspacesQuery.data.available.map(teamspace => {
            const teamspaceItem: TeamspaceDropdownItem = {
              id: teamspace.id,
              iconTheme: teamspace.iconTheme,
              displayName: teamspace.displayName,
              effectiveRole: undefined,
            }

            return getTeamspaceDropDownItem(teamspaceItem)
          })
        : [],
    [getTeamspaceDropDownItem, publicTeamspacesQuery.data]
  )

  const teamspaceDropDownItems = userTeamspaceDropDownItems.concat(availbleTeamspaceDropDownItems)

  const isNonEditorCourseType = getIsNonEditorCourseType(courseType)

  return (
    <View direction='column'>
      <Text size='regular' bold>
        {t('teamspace.manage.teamspace')}
      </Text>
      {isNonEditorCourseType ? (
        <NonEditorCoursesDropDown
          teamspaceDropDownItems={teamspaceDropDownItems}
          moveContent={moveContent}
          initialTeamspace={initialTeamspace}
          canMoveContent={canMoveContent}
        />
      ) : (
        <EditableContentDropDown
          teamspaceDropDownItems={teamspaceDropDownItems}
          moveContent={moveContent}
          initialTeamspace={initialTeamspace}
          canMoveContent={canMoveContent}
        />
      )}
    </View>
  )
}
