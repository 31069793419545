import React, { FC, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SelfEnrollSessionListLayout, StepSubContent } from 'sierra-client/views/learner/program/atoms'
import { EnrolledStepContent } from 'sierra-client/views/learner/program/components/enrolled-step-content'
import { StepUpcomingLiveSession } from 'sierra-client/views/learner/program/types'
import { EnrollmentStepType, getStepImage } from 'sierra-client/views/learner/program/utils/step-graphql'
import { daysFromNow, mapLiveSessionLocation } from 'sierra-client/views/learner/program/utils/utils'

import { SelfEnrollCalendarEvents } from 'sierra-client/views/learner/self-enroll-calendar-events/self-enroll-calendar-events'
import { SelfEnrollLiveSessionList } from 'sierra-client/views/learner/self-enroll-live-session/self-enroll-live-session-list'
import { Tooltip } from 'sierra-ui/components'
import { isDefined } from 'sierra-ui/utils/is-defined'

type ProgramPathStepProps = {
  locked: boolean
  pathId: string
  programId: string
  step: EnrollmentStepType
  isNextStep: boolean
}

export const ProgramPathStep: FC<ProgramPathStepProps> = ({
  locked,
  pathId,
  programId,
  step,
  isNextStep,
}) => {
  const { t } = useTranslation()

  const [upcomingSelfEnrollmentLiveSessions, setUpcomingSelfEnrollmentLiveSessions] = useState<
    StepUpcomingLiveSession[] | undefined
  >(
    step.__typename === 'UserProgramCourseStep' && step.content.__typename === 'NativeLiveCourse'
      ? (step.upcomingSelfEnrollmentLiveSessions ?? undefined)
      : undefined
  )

  const showLiveSessionList =
    !locked &&
    step.__typename === 'UserProgramCourseStep' &&
    (upcomingSelfEnrollmentLiveSessions?.length ?? 0) > 0

  const showCalendarEventList =
    !locked &&
    step.__typename === 'UserProgramCourseStep' &&
    step.content.__typename === 'NativeEventGroup' &&
    step.content.upcomingSelfEnrollmentCalendarEvents.length > 0

  const isPathStepWithSubSteps = step.__typename === 'UserProgramPathStep' && step.subSteps.length > 0

  const courseImage = getStepImage(step)

  const setIsEnrolled = React.useCallback(
    (sessionId: string, enroll: boolean): void => {
      if (step.__typename === 'UserProgramCourseStep' && upcomingSelfEnrollmentLiveSessions) {
        const upcomingSelfEnrollmentLiveSessionsToSet = upcomingSelfEnrollmentLiveSessions.map(s =>
          s.liveSession.liveSessionId === sessionId ? { ...s, isEnrolled: enroll } : s
        )
        setUpcomingSelfEnrollmentLiveSessions(upcomingSelfEnrollmentLiveSessionsToSet)
      }
    },
    [upcomingSelfEnrollmentLiveSessions, step.__typename]
  )

  const { availableAt } = step

  const tooltip = locked
    ? isDefined(availableAt)
      ? t('program-overview.details.available.on-date.tooltip-2', {
          count: daysFromNow(availableAt),
        })
      : t('program-overview.details.available.on.previous.completions.tooltip')
    : null

  return (
    <>
      <Tooltip title={tooltip}>
        <EnrolledStepContent
          step={step}
          programId={programId}
          locked={locked}
          parentPathId={pathId}
          upcomingSelfEnrollmentLiveSessions={upcomingSelfEnrollmentLiveSessions}
          subContentControls={{ togglePathSubContent: () => {}, collapseButton: false }}
          isNextStep={isNextStep}
        />
      </Tooltip>

      {(showLiveSessionList || showCalendarEventList || isPathStepWithSubSteps) && (
        <StepSubContent>
          {showLiveSessionList && (
            <SelfEnrollSessionListLayout>
              <SelfEnrollLiveSessionList
                courseId={step.courseId}
                courseImage={courseImage ?? undefined}
                setIsEnrolled={setIsEnrolled}
                sessions={
                  upcomingSelfEnrollmentLiveSessions?.map(userLiveSession => ({
                    title: userLiveSession.liveSession.title,
                    startTime: userLiveSession.liveSession.startTime,
                    endTime: userLiveSession.liveSession.endTime,
                    allDay: userLiveSession.liveSession.allDay,
                    isEnrolled: userLiveSession.isEnrolled,
                    usedSpots: userLiveSession.usedSpots ?? 0,
                    totalSpots: userLiveSession.liveSession.totalSpots ?? undefined,
                    sessionId: userLiveSession.liveSession.liveSessionId,
                    location: mapLiveSessionLocation(userLiveSession.liveSession.location),
                  })) ?? []
                }
              />
            </SelfEnrollSessionListLayout>
          )}
          {showCalendarEventList && (
            <SelfEnrollSessionListLayout>
              <SelfEnrollCalendarEvents
                sessions={
                  step.content.__typename === 'NativeEventGroup'
                    ? step.content.upcomingSelfEnrollmentCalendarEvents
                    : undefined
                }
                courseId={step.courseId}
                eventGroupId={step.courseId}
                courseImage={courseImage ?? undefined}
              />
            </SelfEnrollSessionListLayout>
          )}
        </StepSubContent>
      )}
    </>
  )
}
