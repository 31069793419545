import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/index'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { MicrosoftCalendarAccessResult } from 'sierra-client/api/graphql/gql/graphql'
import { useCoursePermissionSettings } from 'sierra-client/api/hooks/use-course-permission'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useCurrentUser, usePreferredTimezone } from 'sierra-client/api/hooks/use-user'
import { mapUserToIdentity } from 'sierra-client/components/common/identities-selector/identities-utils'
import {
  useAdminIdentitiesFetcher,
  useFacilitatorIdentitiesFetcher,
} from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { Link } from 'sierra-client/components/common/link'
import { config, getFlag } from 'sierra-client/config/global-config'
import {
  CalendarIntegrationPicker,
  MicrosoftCalendarError,
} from 'sierra-client/features/calendar-integrations'
import { ListSessions } from 'sierra-client/features/sana-now/create-session-panel/list-sessions'
import { MeetingIntegrationPicker } from 'sierra-client/features/sana-now/create-session-panel/meeting-integration-picker'
import type { PanelState } from 'sierra-client/features/sana-now/create-session-panel/panel-state'
import { savedVideoCallSettingTypeAtom } from 'sierra-client/features/sana-now/create-session-panel/saved-video-call-setting-type-atom'
import {
  SessionFormData,
  useSessionFormData,
} from 'sierra-client/features/sana-now/create-session-panel/session-form-state'
import { canAccessMicrosoftCalendarMutation } from 'sierra-client/hooks/can-access-microsoft-calendar-mutation'
import { useAuthenticatedUserIntegrationsQuery } from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { getMaxParticipantsInLive } from 'sierra-client/hooks/use-max-participants-in-live'
import { useContentKindPermissions, useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { setUnion } from 'sierra-client/lib/querystate/utils'
import { typedPost, useInvalidateCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { normalizeAllDayEventTimes } from 'sierra-client/utils/date-utils'
import {
  CalendarEventUserSelector,
  EventParticipantSelectorProps,
} from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { EventScheduleForm } from 'sierra-client/views/manage/event-groups/components/event-schedule-form'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorLiveSessionsDeleteLiveSession,
  XRealtimeAuthorLiveSessionsListLiveSessions,
  XRealtimeAuthorLiveSessionsUpsertLiveSession,
  XRealtimeAuthorSetCoursePermissionSettings,
} from 'sierra-domain/routes'
import { assertIsNonNullable, assertNever, iife, isDefined } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Button, Heading, IconButton, InputPrimitive, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled(View).attrs({
  direction: 'column',
  gap: 'none',
})`
  position: relative;
  padding: 32px 0 0;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 16px;
`

const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${token('border/default')};
  flex-shrink: 0;
`

const ActionButtonsContainer = styled(View).attrs({ direction: 'row', justifyContent: 'space-between' })`
  position: sticky;
  background: ${token('surface/default')};
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 40px;
  border-top: 1px solid ${token('border/default')};
`

const LearnMoreLink = styled(Link)`
  color: ${token('foreground/muted')};
`

const DeleteView = styled(View)``

type SessionPanelProps = {
  onClose: () => void
  liveContentId: LiveContentId
  liveSessionId?: LiveSessionId
  canDelete: boolean
  canEditMetadata: boolean
  canEditAssignment: boolean
  canEditSharingSettings: boolean
  initialData?: Partial<SessionFormData>
  onSessionCreated?: (liveSessionId: LiveSessionId) => void
  timezone: string
}

const CreateOrUpdateSessionPanelContentInner: FC<SessionPanelProps> = ({
  liveContentId,
  liveSessionId,
  onClose,
  initialData,
  canDelete,
  canEditAssignment,
  canEditSharingSettings,
  canEditMetadata,
  onSessionCreated,
  timezone,
}) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser().data
  assertIsNonNullable(currentUser)
  const sanaNowFeatureEnabled = getFlag('sana-now')

  const userTimezoneQuery = usePreferredTimezone()
  const userTimezone = userTimezoneQuery.isSuccess === true ? userTimezoneQuery.data : null

  const isEditing = initialData !== undefined
  const savedVideoCallSettingTypeAtomValue = useAtomValue(savedVideoCallSettingTypeAtom)
  const savedVideoCallSettingType = isEditing ? undefined : savedVideoCallSettingTypeAtomValue

  const maxParticipants = getMaxParticipantsInLive()

  const invalidateListQuery = useInvalidateCachedQuery(XRealtimeAuthorLiveSessionsListLiveSessions, {
    flexibleContentId: liveContentId,
  })

  const { updateValue, values, validationResult, runValidate } = useSessionFormData(
    sanaNowFeatureEnabled,
    liveContentId,
    timezone,
    initialData
  )

  const authenticatedUserIntegrationsQuery = useAuthenticatedUserIntegrationsQuery()
  const isCalendarIntegrationAuthenticated = iife(() => {
    const integrations = authenticatedUserIntegrationsQuery.data?.viewer.integrations

    switch (values.calendarIntegrationSetting.type) {
      case 'microsoft':
        return (
          integrations !== undefined && integrations.microsoft.capabilities.canCreateMicrosoftCalendarEvent
        )

      case 'google':
        return integrations !== undefined && integrations.google.capabilities.canCreateGoogleCalendarEvent

      case 'sana':
        return true
      default:
        assertNever(values.calendarIntegrationSetting)
    }
  })

  const [moreSettingsOpen, setMoreSettingsOpen] = useState(false)
  const [isMeetingToolAuthenticated, setIsMeetingToolAuthenticated] = useState(true)
  const [isMeetingToolLoading, setIsMeetingToolLoading] = useState(false)

  const setSelectedParticipantIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(values.participants)
    updateValue('participants', updatedIdentities)
  }

  const setSelectedFacilitatorIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(values.facilitators)
    if (updatedIdentities.length === 0) {
      updatedIdentities.push(mapUserToIdentity(currentUser))
    }
    updateValue('facilitators', updatedIdentities)
  }

  const fetchParticipantIdentities = useAdminIdentitiesFetcher()
  const fetchFacilitatorIdentities = useFacilitatorIdentitiesFetcher()

  const courseVisibilityQuery = useCoursePermissionSettings(liveContentId)

  const updateCoursePermissionsMutation = useTypedMutation(XRealtimeAuthorSetCoursePermissionSettings)
  const deleteSessionMutation = useTypedMutation(XRealtimeAuthorLiveSessionsDeleteLiveSession)

  const rsvpEnabled = getFlag('rsvp')
  const hasExternalCalendarIntegrationEnabled =
    config.organization.settings.calendarIntegrationSettings.type !== 'sana'

  const moreSettingsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (moreSettingsOpen) {
      moreSettingsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [moreSettingsOpen])

  type HandleScheduleMutationResponse =
    | {
        type: 'success'
        liveSessionId: LiveSessionId
      }
    | {
        type: 'error-validation-failed'
      }
    | {
        type: 'error-course-visibility-query-not-loaded'
      }
    | {
        type: 'error-cannot-access-microsoft-calendar'
        access: Exclude<MicrosoftCalendarAccessResult, 'HasAccess'>
      }

  const handleScheduleMutation = useMutation({
    mutationFn: async (): Promise<HandleScheduleMutationResponse> => {
      const result = runValidate()
      if (Object.values(result).some(r => !r.isValid)) {
        console.error('Form validation failed', result)
        return { type: 'error-validation-failed' }
      }

      if (courseVisibilityQuery.data === undefined) {
        return { type: 'error-course-visibility-query-not-loaded' }
      }

      const { startISOString, endISOString } =
        values.schedule.type === 'all-day'
          ? (() => {
              const { formattedStart, formattedEnd } = normalizeAllDayEventTimes(
                values.schedule.startDate,
                values.schedule.endDate
              )
              return {
                startISOString: formattedStart.toISO(),
                endISOString: formattedEnd.toISO(),
              }
            })()
          : {
              startISOString: values.schedule.startTime.toISO(),
              endISOString: values.schedule.endTime.toISO(),
            }

      const facilitatorIds = values.facilitators.flatMap(f =>
        f.identity.type === 'user' ? [f.identity.id] : []
      )
      const participantIds = values.participants.map(p => p.identity.id)

      if (participantIds.length > 0 && courseVisibilityQuery.data.visibilityInOrg === 'private') {
        updateCoursePermissionsMutation.mutate({
          courseId: liveContentId,
          visibilityInOrg: 'visible-admins',
          shareLinkAccessLevel: courseVisibilityQuery.data.shareLinkAccessLevel,
        })
      }

      if (values.calendarIntegrationSetting.type === 'microsoft') {
        const microsoftCalendarAccess = (await graphQuery(canAccessMicrosoftCalendarMutation))
          .canAccessMicrosoftCalendar

        if (microsoftCalendarAccess !== 'HasAccess') {
          // If we can't create the integration event, we should not create the session
          return { type: 'error-cannot-access-microsoft-calendar', access: microsoftCalendarAccess }
        }
      }

      const response = await typedPost(XRealtimeAuthorLiveSessionsUpsertLiveSession, {
        liveSessionId: liveSessionId,
        liveSessionType: 'scheduled',
        participantIds: Array.from(setUnion(new Set(participantIds), new Set(facilitatorIds))),
        data: {
          facilitatorIds: facilitatorIds,
          allDay: values.schedule.type === 'all-day',
          endTime: endISOString,
          startTime: startISOString,
          allowGuestAccess: values.allowGuestAccess,
          disallowEarlyJoin: values.disallowEarlyJoin,
          enableRecap: values.enableRecap,
          hideTranscription: values.hideTranscription,
          hideFromSelfEnrollment: !values.enableSelfEnrollment,
          location: values.location !== undefined ? { type: 'physical', value: values.location } : undefined,
          maxNumberOfUsers: values.maxNumberOfUsers,
          title: values.title,
          flexibleContentId: liveContentId,
          learnerLedSession: values.learnerLedSession,
          transcribeSession: values.transcribeSession,
          videoCallSetting: values.videoCallSetting,
          type: 'scheduled',
          calendarIntegrationSetting: values.calendarIntegrationSetting,
        },
      })

      return { type: 'success', liveSessionId: response.liveSessionId }
    },
    onSuccess: async res => {
      switch (res.type) {
        case 'success': {
          await invalidateListQuery()
          onSessionCreated?.(res.liveSessionId)
          onClose()
          break
        }
        case 'error-course-visibility-query-not-loaded':
        case 'error-cannot-access-microsoft-calendar':
        case 'error-validation-failed':
          return
        default:
          assertNever(res)
      }
    },
  })

  return (
    <Container>
      <InnerContainer>
        <Heading size='h5' bold>
          {isEditing ? t('admin.author.sessions.edit') : t('admin.author.sessions.create')}
        </Heading>
        <Spacer size='24' />
        <FormElement
          helper={!validationResult.title.isValid ? validationResult.title.errorMessage : undefined}
          isError={!validationResult.title.isValid}
          grow={false}
          label={t('dictionary.title')}
        >
          <InputPrimitive
            placeholder={t('manage.events.session-name')}
            id='session-title'
            value={values.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => updateValue('title', e.target.value)}
            disabled={!canEditMetadata}
          />
        </FormElement>
        <Spacer size='16' />

        {isDefined(userTimezone) && (
          <EventScheduleForm
            disabled={!canEditMetadata}
            schedule={values.schedule}
            updateSchedule={updated => updateValue('schedule', updated)}
            timezone={userTimezone}
          />
        )}

        {sanaNowFeatureEnabled && (
          <>
            <Spacer size='16' />
            <MeetingIntegrationPicker
              onChange={videoCallSetting => updateValue('videoCallSetting', videoCallSetting)}
              videoCallSetting={values.videoCallSetting}
              defaultVideoCallSettingChoice={savedVideoCallSettingType}
              isValid={validationResult.videoCallSetting.isValid}
              isLoading={isMeetingToolLoading}
              disabled={!canEditMetadata}
              onAuthStatusChange={setIsMeetingToolAuthenticated}
              onLoadingChange={setIsMeetingToolLoading}
            />
          </>
        )}

        <Spacer size='16' />
        <FormElement grow={false} label={t('dictionary.facilitators')}>
          <CalendarEventUserSelector
            selectedIdentities={values.facilitators}
            setSelectedIdentities={setSelectedFacilitatorIdentities}
            fetchIdentities={fetchFacilitatorIdentities}
            disabled={!canEditMetadata}
          />
        </FormElement>
        <Spacer size='16' />
        <FormElement grow={false} label={t('dictionary.invite-people')}>
          {!canEditAssignment && canEditSharingSettings && (
            <View justifyContent='space-between'>
              <View>
                <Icon iconId='building' color='foreground/muted' />
                <Text size='micro' color='foreground/muted'>
                  {t(
                    'admin.author.create-session-panel.inviting-user-will-make-visible-in-manage-explanation'
                  )}
                </Text>
              </View>
              <LearnMoreLink
                target='_blank'
                rel='noopener noreferrer'
                href='https://help.sana.ai/'
                size='micro'
                bold
              >
                {t('content.learn-more')}
              </LearnMoreLink>
            </View>
          )}
          <CalendarEventUserSelector
            selectedIdentities={values.participants}
            setSelectedIdentities={setSelectedParticipantIdentities}
            fetchIdentities={fetchParticipantIdentities}
            disabled={!canEditSharingSettings && !canEditAssignment}
          />
        </FormElement>

        {rsvpEnabled && hasExternalCalendarIntegrationEnabled && (
          <>
            <Spacer size='8' />
            <CalendarIntegrationPicker
              onChange={calendarIntegrationSetting =>
                updateValue('calendarIntegrationSetting', calendarIntegrationSetting)
              }
              calendarIntegrationSetting={values.calendarIntegrationSetting}
              isValid={validationResult.calendarIntegrationSetting.isValid}
              disabled={!canEditMetadata || isEditing}
            />
            {iife(() => {
              if (values.calendarIntegrationSetting.type !== 'microsoft') {
                return null
              }

              const microsoftError =
                handleScheduleMutation.data?.type === 'error-cannot-access-microsoft-calendar'
                  ? handleScheduleMutation.data.access
                  : undefined

              if (microsoftError !== undefined) {
                return (
                  <>
                    <Spacer size='16' />
                    <MicrosoftCalendarError accessResult={microsoftError} />
                  </>
                )
              } else {
                return null
              }
            })}
          </>
        )}
        <Spacer size='32' />
        <HorizontalDivider />
        <Spacer size='32' />
        <View direction='column' gap='16'>
          <View direction='row' justifyContent={'space-between'}>
            <View direction='column' gap='2'>
              <Text size='small' bold>
                {t('admin.author.create-session-panel.learned-led-session-toggle--title')}
              </Text>
              <Text size='micro' color='foreground/muted'>
                {t('admin.author.create-session-panel.learned-led-session-toggle--description')}
              </Text>
            </View>
            <div>
              <Switch
                checked={values.learnerLedSession}
                onChange={() => updateValue('learnerLedSession', !values.learnerLedSession)}
                ariaLabel={t('admin.author.create-session-panel.learned-led-session-toggle--title')}
                disabled={!canEditMetadata}
              />
            </div>
          </View>

          <View direction='row' justifyContent={'space-between'}>
            <View direction='column' gap='2'>
              <Text size='small' bold>
                {t('admin.author.create-session-panel.allow-guest-access--title')}
              </Text>
              <Text size='micro' color='foreground/muted'>
                {t('admin.author.create-session-panel.allow-guest-access--description')}
              </Text>
            </View>
            <div>
              <Switch
                checked={values.allowGuestAccess}
                onChange={checked => updateValue('allowGuestAccess', checked)}
                ariaLabel={t('admin.author.create-session-panel.allow-guest-access--title')}
                disabled={!canEditMetadata}
              />
            </div>
          </View>

          <View direction='row' justifyContent={'space-between'}>
            <View direction='column' gap='2'>
              <Text size='small' bold>
                {t('admin.author.create-session-panel.transcribe-session--title')}
              </Text>
              <Text size='micro' color='foreground/muted'>
                {t('admin.author.create-session-panel.transcribe-session--description')}
              </Text>
            </View>
            <div>
              <Switch
                checked={values.transcribeSession}
                onChange={checked => updateValue('transcribeSession', checked)}
                ariaLabel={t('admin.author.create-session-panel.transcribe-session--title')}
                disabled={!canEditMetadata}
              />
            </div>
          </View>
        </View>
        {moreSettingsOpen && (
          <>
            <Spacer size='32' />
            <HorizontalDivider />
            <Spacer size='32' />

            <View ref={moreSettingsRef} direction='column' gap='32'>
              <View>
                <Icon color='foreground/muted' iconId='settings' />
                <Text color='foreground/muted' bold>
                  {t('admin.author.create-session-panel.more-options')}
                </Text>
              </View>

              <FormElement grow={false} label={t('admin.author.sessions.location')}>
                <InputPrimitive
                  placeholder={t('admin.author.sessions.location-placeholder')}
                  id='session-location'
                  value={values.location ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateValue('location', e.target.value)}
                  disabled={!canEditMetadata}
                />
              </FormElement>

              <View direction='column' gap='16'>
                <View direction='row' justifyContent={'space-between'}>
                  <View direction='column' gap='2'>
                    <Text size='small' bold>
                      {t('admin.author.create-session-panel.session-capacity--title')}
                    </Text>
                    <Text size='micro' color='foreground/muted'>
                      {t('admin.author.create-session-panel.session-capacity--description')}
                    </Text>
                  </View>
                  <div>
                    <InputPrimitive
                      disabled={!canEditMetadata}
                      type='number'
                      placeholder={t('admin.author.create-session-panel.participant-limit', {
                        count: maxParticipants,
                      })}
                      id='session-capacity'
                      value={`${values.maxNumberOfUsers}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === '') {
                          updateValue('maxNumberOfUsers', undefined)
                          return
                        }

                        const newLimit = Number.parseInt(e.target.value)
                        if (!Number.isNaN(newLimit) && newLimit > 0 && newLimit <= maxParticipants) {
                          updateValue('maxNumberOfUsers', newLimit)
                        }
                      }}
                    />
                  </div>
                </View>

                <View direction='row' justifyContent={'space-between'}>
                  <View direction='column' gap='2'>
                    <Text size='small' bold>
                      {t('admin.author.create-session-panel.enable-self-enrollment--title')}
                    </Text>
                    <Text size='micro' color='foreground/muted'>
                      {t('admin.author.create-session-panel.enable-self-enrollment--description')}
                    </Text>
                  </View>
                  <div>
                    <Switch
                      checked={values.enableSelfEnrollment}
                      onChange={checked => updateValue('enableSelfEnrollment', checked)}
                      ariaLabel={t('admin.author.create-session-panel.enable-self-enrollment--title')}
                      disabled={!canEditMetadata}
                    />
                  </div>
                </View>

                <View direction='row' justifyContent={'space-between'}>
                  <View direction='column' gap='2'>
                    <Text size='small' bold>
                      {t('admin.author.create-session-panel.enable-recap--title')}
                    </Text>
                    <Text size='micro' color='foreground/muted'>
                      {t('admin.author.create-session-panel.enable-recap--description')}
                    </Text>
                  </View>
                  <div>
                    <Switch
                      checked={values.enableRecap}
                      onChange={checked => updateValue('enableRecap', checked)}
                      ariaLabel={t('admin.author.create-session-panel.enable-recap--title')}
                      disabled={!canEditMetadata}
                    />
                  </div>
                </View>
              </View>

              {liveSessionId !== undefined && (
                <DeleteView
                  alignItems='center'
                  padding='8'
                  radius='regular'
                  borderColor='border/default'
                  gap='24'
                >
                  <View direction='column'>
                    <Text size='small' bold>
                      {t('admin.author.sessions.delete-session')}
                    </Text>
                    <Text size='small' color='foreground/muted'>
                      {t('admin.author.sessions.deleting-a-session-info')}
                    </Text>
                  </View>
                  <Button
                    variant='destructive'
                    disabled={!canDelete}
                    loading={deleteSessionMutation.isPending}
                    onClick={() =>
                      deleteSessionMutation.mutate(
                        {
                          liveSessionId: liveSessionId,
                        },
                        {
                          onSuccess: async () => {
                            await invalidateListQuery()
                            onClose()
                          },
                        }
                      )
                    }
                  >
                    {t('dictionary.delete')}
                  </Button>
                </DeleteView>
              )}
            </View>
          </>
        )}
      </InnerContainer>

      <ActionButtonsContainer>
        {!moreSettingsOpen ? (
          <Button onClick={() => setMoreSettingsOpen(true)} variant={'secondary'}>
            {t('dictionary.more-options')}
          </Button>
        ) : (
          // Empty div to keep the other buttons aligned to the right
          <div />
        )}
        <View>
          <Button variant={'secondary'} onClick={onClose}>
            {t('dictionary.cancel')}
          </Button>

          <Button
            loading={
              updateCoursePermissionsMutation.isPending ||
              handleScheduleMutation.isPending ||
              isMeetingToolLoading
            }
            variant='success'
            onClick={() => handleScheduleMutation.mutate()}
            disabledWithReason={iife(() => {
              const reasons = []
              if (!isMeetingToolAuthenticated) {
                reasons.push(t('admin.author.create-session-panel.meeting-tool-auth'))
              }
              if (isMeetingToolLoading) {
                reasons.push(t('admin.author.create-session-panel.meeting-tool-loading'))
              }
              if (!isCalendarIntegrationAuthenticated && !isEditing) {
                reasons.push(t('admin.author.create-session-panel.calendar-invites-auth'))
              }
              return reasons.length > 0 ? reasons.join('\n') : undefined
            })}
          >
            {isEditing ? t('modal.save-changes') : t('dictionary.schedule-singular')}
          </Button>
        </View>
      </ActionButtonsContainer>
    </Container>
  )
}

const CreateOrUpdateSessionPanelContent: FC<Omit<SessionPanelProps, 'timezone'>> = props => {
  const timezoneQuery = usePreferredTimezone()
  const loading = timezoneQuery.isSuccess !== true

  return loading ? null : <CreateOrUpdateSessionPanelContentInner {...props} timezone={timezoneQuery.data} />
}

const CreatePanelContent = ({
  onClose,
  liveContentId,
  state,
}: {
  onClose: () => void
  liveContentId: LiveContentId
  state: PanelState & { type: 'create-session' }
}): JSX.Element => {
  const liveContentPermissions = useContentKindPermissions(liveContentId)

  return (
    <CreateOrUpdateSessionPanelContent
      onClose={onClose}
      liveContentId={liveContentId}
      canDelete={true}
      canEditMetadata={true}
      canEditAssignment={liveContentPermissions.has('EDIT_ASSIGNMENTS')}
      canEditSharingSettings={liveContentPermissions.has('EDIT_SHARING_SETTINGS')}
      initialData={state.initialData}
      onSessionCreated={state.onSessionCreated}
    />
  )
}

const EditPanelContent = ({
  onClose,
  liveContentId,
  state,
}: {
  onClose: () => void
  liveContentId: LiveContentId
  state: PanelState & { type: 'update-session' }
}): JSX.Element => {
  const liveContentPermissions = useContentKindPermissions(liveContentId)
  const liveSessionPermission = useLiveSessionPermissions(state.liveSessionId)
  const canDelete = liveSessionPermission.has('DELETE')
  const canEditMetadata = liveSessionPermission.has('EDIT_METADATA')
  const canEditAssignment = liveSessionPermission.has('EDIT_ASSIGNMENTS')

  return (
    <CreateOrUpdateSessionPanelContent
      onClose={onClose}
      liveContentId={liveContentId}
      canDelete={canDelete}
      canEditMetadata={canEditMetadata}
      canEditAssignment={canEditAssignment}
      canEditSharingSettings={liveContentPermissions.has('EDIT_SHARING_SETTINGS')}
      initialData={state.initialData}
      liveSessionId={state.liveSessionId}
    />
  )
}

const CloseIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const ManageSessionsPanel = withPanel<{
  liveContentId: LiveContentId
  state: PanelState
  setState: (newState: PanelState) => void
}>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  ({ onClose: onClosePanel, liveContentId, state, setState }) => {
    const onCloseEditPanel = (): void => setState({ type: 'list-sessions' })

    return (
      <>
        {state.type === 'create-session' && (
          <CreatePanelContent onClose={onCloseEditPanel} liveContentId={liveContentId} state={state} />
        )}

        {state.type === 'update-session' && (
          <EditPanelContent onClose={onCloseEditPanel} liveContentId={liveContentId} state={state} />
        )}

        {state.type === 'list-sessions' && <ListSessions liveContentId={liveContentId} setState={setState} />}

        <CloseIconContainer>
          <IconButton variant='transparent' size='small' iconId='close' onClick={onClosePanel} />
        </CloseIconContainer>
      </>
    )
  }
)
