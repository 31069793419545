import _ from 'lodash'
import { DateTime } from 'luxon'
import { Control, Controller, ControllerFieldState, FieldPathByValue, FieldValues } from 'react-hook-form'
import { TimezonePicker } from 'sierra-client/components/common/pickers/timezone-picker'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  FileInputWithUpload,
  FilePill,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared'
import { IssueByProxySelector } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared/by-proxy-selector'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isNonEmptyString, isNonNullable } from 'sierra-domain/utils'
import { FormElement, InputDatePicker, MenuItem } from 'sierra-ui/components'
import { LabelMenuItem, MenuItemPrimitive } from 'sierra-ui/components/menu/types'
import { InputPrimitive, Switch, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { InputPrimitiveProps } from 'sierra-ui/primitives/form/input-primitive'
import { SwitchProps } from 'sierra-ui/primitives/form/switch'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { TextColor } from 'sierra-ui/primitives/typography/text'

type TypedField<T> = {
  onChange: (t: T) => void
  onBlur: () => void // React.FocusEventHandler
  value: T
  name: string
  disabled?: boolean
  ref: any
}

type BaseExtraProps = { name?: never; control?: never } & Record<string, unknown>

type FieldRenderer<T, ExtraProps extends BaseExtraProps> = React.FC<{
  field: TypedField<T>
  fieldState: ControllerFieldState
  props: ExtraProps
}>

// Note: `ExtraProps` should not contain a `name` or `control` parameter.
type TypedControllerProps<T, ExtraProps extends BaseExtraProps, V extends FieldValues> = {
  control: Control<V>
  name: FieldPathByValue<V, T>
} & Omit<ExtraProps, 'name' | 'control'>

export const makeTypedController = <T, ExtraProps extends BaseExtraProps = Record<string, unknown>>(
  Field: FieldRenderer<T, ExtraProps>
) => {
  return function TypedController<V extends FieldValues>({
    control,
    name,
    ...extraProps
  }: TypedControllerProps<T, ExtraProps, V>): JSX.Element {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <Field field={field} fieldState={fieldState} props={extraProps as unknown as ExtraProps} />
        )}
      />
    )
  }
}

export const FormSwitch = makeTypedController<
  boolean,
  { textPos?: SwitchProps['textPos']; text?: string; disabled?: boolean }
>(({ field, props }) => {
  return (
    <Switch
      onChange={field.onChange}
      disabled={props.disabled ?? false}
      checked={field.value}
      text={props.text ?? ''}
      textPos={props.textPos}
    />
  )
})

type FileInputValue = { id: string; file: File } | null | undefined

export const FormFileInput = makeTypedController<FileInputValue, { mixed?: boolean }>(
  ({ field, fieldState, props }) => {
    const { t } = useTranslation()

    return (
      <View>
        {
          // Only show the mixed file pill if the field is not dirty and mixed. Pressing the mixed will remove the field value thus making it dirty
          props.mixed === true && !fieldState.isDirty ? (
            <FilePill onRemove={() => field.onChange(null)} name={_.capitalize(t('dictionary.mixed'))} />
          ) : (
            <FileInputWithUpload
              value={field.value ?? undefined}
              onChange={f => {
                if (isNonNullable(f)) {
                  field.onChange(f)
                } else {
                  field.onChange(null)
                }
              }}
            />
          )
        }
        {isNonNullable(fieldState.error) && (
          <Text capitalize='first' color='destructive/background' size='small'>
            {fieldState.error.message}
          </Text>
        )}
      </View>
    )
  }
)

export const FormInput = makeTypedController<
  string | undefined,
  {
    placeholder?: string
    label?: string
    labelColor?: TextColor
    optional?: boolean
    autoFocus?: boolean
    autoComplete?: boolean
    helper?: string
    type?: InputPrimitiveProps['type']
  }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const { label, labelColor, optional = false, placeholder, autoFocus, autoComplete, helper, type } = props

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : helper}
      label={
        isNonEmptyString(label) ? (
          <View>
            <Text capitalize='first' bold color={labelColor}>
              {label}
            </Text>
            {optional && (
              <Text capitalize='first' bold color='foreground/muted'>
                {t('input.optional')}
              </Text>
            )}
          </View>
        ) : undefined
      }
    >
      <InputPrimitive
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={e => field.onChange(e.target.value)}
        onBlur={field.onBlur}
        value={field.value ?? ''}
        disabled={field.disabled}
        name={field.name}
        type={type}
        ref={field.ref}
        autoComplete={autoComplete === false ? 'off' : 'on'}
      />
    </FormElement>
  )
})

export const FormDateInput = makeTypedController<
  Date | undefined,
  {
    placeholder?: string
    label?: string
    labelColor?: TextColor
    optional?: boolean
    autoFocus?: boolean
    helper?: string
  }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const { label, labelColor, optional = false, placeholder, autoFocus, helper } = props
  const dt = field.value ? DateTime.fromJSDate(field.value) : DateTime.now()

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : helper}
      label={
        isNonEmptyString(label) ? (
          <View>
            <Text capitalize='first' bold color={labelColor}>
              {label}
            </Text>
            {optional && (
              <Text capitalize='first' bold color='foreground/muted'>
                {t('input.optional')}
              </Text>
            )}
          </View>
        ) : undefined
      }
    >
      <InputDatePicker
        onChange={dt => field.onChange(dt?.toJSDate())}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onBlur={field.onBlur}
        value={dt}
        disabled={field.disabled}
      />
    </FormElement>
  )
})

export const FormSingleSelectDropdown = makeTypedController<
  string | undefined,
  {
    placeholder?: string
    label?: string
    labelColor?: TextColor
    optional?: boolean
    renderTrigger?: (open: boolean) => JSX.Element
    maxWidth?: number
    menuItems: Array<MenuItemPrimitive>
  }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()

  const { value = '' } = field
  const { placeholder, label, labelColor, optional, renderTrigger, menuItems, maxWidth } = props

  const unknownSelectedItem = {
    type: 'label',
    id: value,
    label: value,
  } satisfies LabelMenuItem

  // Set the selectedItem in the dropdown to just a label if we cant find the current item
  const selectedItem =
    menuItems.find(m => m.id === value) ?? (unknownSelectedItem as unknown as MenuItemPrimitive)

  const onChange = (item: MenuItem): void => {
    field.onChange(item.id)
  }
  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : undefined}
      label={
        isNonEmptyString(label) ? (
          <View>
            <Text capitalize='first' bold color={labelColor}>
              {label}
            </Text>
            {optional === true && (
              <Text capitalize='first' bold color='foreground/muted'>
                {t('input.optional')}
              </Text>
            )}
          </View>
        ) : undefined
      }
    >
      <SingleSelectDropdown
        disabled={field.disabled}
        onSelect={onChange}
        selectedItem={selectedItem}
        placeholder={placeholder}
        menuItems={menuItems}
        maxWidth={maxWidth}
        renderTrigger={renderTrigger}
      />
    </FormElement>
  )
})

export const FormTextArea = makeTypedController<
  string | undefined,
  {
    placeholder?: string
    label?: string
    labelColor?: TextColor
    optional?: boolean
    autoFocus?: boolean
    autoExpand?: boolean
    rows?: number
    helper?: string
  }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const {
    placeholder,
    label,
    labelColor,
    optional = false,
    autoFocus,
    autoExpand = false,
    rows,
    helper,
  } = props

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : helper}
      label={
        isNonEmptyString(label) && (
          <View>
            <Text capitalize='first' bold color={labelColor}>
              {label}
            </Text>
            {optional && (
              <Text capitalize='first' bold color='foreground/muted'>
                {t('input.optional')}
              </Text>
            )}
          </View>
        )
      }
    >
      <TextAreaPrimitive
        onChange={e => field.onChange(e.target.value)}
        onBlur={field.onBlur}
        value={field.value ?? ''}
        disabled={field.disabled}
        name={field.name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoExpand={autoExpand}
        rows={rows}
      />
    </FormElement>
  )
})

export const FormDatePicker = makeTypedController<
  DateTime | undefined,
  { placeholder?: string; label: string; optional?: boolean; autoFocus?: boolean }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const { placeholder, label, optional = false } = props

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : undefined}
      label={
        <View>
          <Text capitalize='first' bold>
            {label}
          </Text>
          {optional && (
            <Text capitalize='first' bold color='foreground/muted'>
              {t('input.optional')}
            </Text>
          )}
        </View>
      }
    >
      <InputDatePicker
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        disabled={field.disabled}
      />
    </FormElement>
  )
})

export const FormProxySelector = makeTypedController<
  IdentityWithMetadata | null | undefined,
  { userIds: UserId[]; placeholder?: string }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const { userIds, placeholder } = props

  const value = field.value ?? undefined

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : undefined}
      label={
        <View>
          <Text capitalize='first' bold>
            {t('manage.certificates.issue.issuer')}
          </Text>
        </View>
      }
    >
      <IssueByProxySelector
        userIds={userIds}
        placeholder={placeholder}
        onSelect={identity => {
          field.onChange(identity)
        }}
        onUnselect={() => {
          field.onChange(null)
        }}
        value={value !== undefined ? { ...value, id: value.identity.id } : undefined}
      />
    </FormElement>
  )
})

export const FormTimezoneInput = makeTypedController<
  string | null,
  {
    label?: string
    labelColor?: TextColor
    optional?: boolean
    helper?: string
  }
>(({ field, fieldState, props }) => {
  const { t } = useTranslation()
  const { label, labelColor, optional = false, helper } = props

  return (
    <FormElement
      isError={isNonNullable(fieldState.error)}
      helper={fieldState.error ? fieldState.error.message : helper}
      label={
        isNonEmptyString(label) ? (
          <View>
            <Text capitalize='first' bold color={labelColor}>
              {label}
            </Text>
            {optional && (
              <Text capitalize='first' bold color='foreground/muted'>
                {t('input.optional')}
              </Text>
            )}
          </View>
        ) : undefined
      }
    >
      <TimezonePicker
        timezoneId={field.value ?? undefined}
        onChange={timezoneId => field.onChange(timezoneId ?? null)}
      />
    </FormElement>
  )
})
