import 'array-flat-polyfill'
import { FC, PropsWithChildren } from 'react'
import {
  CustomInterfaceThemeProvider,
  GlobalOrganizationThemingProvider,
} from 'sierra-client/components/common/custom-interface-theme-provider'
import { DomainThemeProvider } from 'sierra-client/config/domain-theme'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { KeyboardMode } from 'sierra-ui/a11y/keyboard-mode'

export const ApplicationThemeProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <GlobalOrganizationThemingProvider>
      <AppThemeTokenProvider>
        <DomainThemeProvider defaultMode={'light'}>
          <CustomInterfaceThemeProvider>
            <KeyboardMode />
            {children}
          </CustomInterfaceThemeProvider>
        </DomainThemeProvider>
      </AppThemeTokenProvider>
    </GlobalOrganizationThemingProvider>
  )
}
