import React, { useMemo } from 'react'
import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { FCC } from 'sierra-client/types'

type ShowCorrectAnswersContext = {
  toggle: () => void
  shouldShowCorrectAnswer: boolean
}

const defaultShowCorrectAnswersContext: ShowCorrectAnswersContext = {
  toggle: () => {},
  shouldShowCorrectAnswer: false,
}

const ReactShowCorrectAnswersContext = React.createContext<ShowCorrectAnswersContext>(
  defaultShowCorrectAnswersContext
)

export const ShowCorrectAnswersContext: FCC<{
  override?: boolean
  showAllVariations?: boolean
  showExplanations?: boolean
}> = ({ children, override }) => {
  const isOwner = useIsOwnerUser()

  const [shouldShowCorrectAnswer, { toggle }] = useToggle(false)
  const value = useMemo(
    (): ShowCorrectAnswersContext => ({
      shouldShowCorrectAnswer: override ?? (shouldShowCorrectAnswer && isOwner),
      toggle,
    }),
    [override, shouldShowCorrectAnswer, isOwner, toggle]
  )
  return (
    <ReactShowCorrectAnswersContext.Provider value={value}>
      {children}
    </ReactShowCorrectAnswersContext.Provider>
  )
}

export function useShowCorrectAnswersContext(): ShowCorrectAnswersContext {
  return React.useContext(ReactShowCorrectAnswersContext)
}

export function useShowCorrectAnswers(): boolean {
  return useShowCorrectAnswersContext().shouldShowCorrectAnswer
}
