import React from 'react'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { Domain, Predicate, Value, setPred } from 'sierra-client/lib/filter'
import {
  CardAutoComplete,
  CardAutoCompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/card-auto-complete'
import {
  CertificateAutoComplete,
  CertificateAutoCompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/certificate-auto-complete'
import {
  ContentAutoComplete,
  ContentAutocompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/content-auto-complete'
import {
  ExerciseAutoComplete,
  ExerciseAutoCompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/exercise-auto-complete'
import {
  GroupAutoComplete,
  GroupAutocompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/group-auto-complete'
import {
  SkillAutoComplete,
  SkillAutocompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/skill-auto-complete'
import {
  TagAutoComplete,
  TagAutoCompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/tag-auto-complete'
import {
  UserAutoComplete,
  UserAutocompleteLabel,
} from 'sierra-client/lib/filter/components/auto-complete/user-auto-complete'
import { Context } from 'sierra-client/lib/filter/components/common'
import { predicateIsEmpty } from 'sierra-client/lib/filter/components/predicate-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { AutoCompleteEntity } from 'sierra-domain/filter/datatype/domain'
import { assertNever } from 'sierra-domain/utils'
import { Popover } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type EntityAutoCompleteProps = {
  entity: AutoCompleteEntity
  ctx: Context
  predicate: Predicate
  assetContext: AssetContext
}

const EntityAutoComplete: React.FC<EntityAutoCompleteProps> = ({ entity, ctx, predicate, assetContext }) => {
  switch (entity) {
    case 'entity.user':
      return <UserAutoComplete ctx={ctx} predicate={predicate} />
    case 'entity.group':
      return <GroupAutoComplete ctx={ctx} predicate={predicate} />
    case 'entity.content':
      return (
        <ContentAutoComplete
          ctx={ctx}
          predicate={predicate}
          contentTypes={['self-paced', 'path', 'external', 'live', 'program']}
          formLabel='dictionary.content-plural'
        />
      )
    case 'entity.course':
      return (
        <ContentAutoComplete
          ctx={ctx}
          predicate={predicate}
          contentTypes={['self-paced', 'external', 'live']}
          formLabel={'dictionary.course-plural'}
        />
      )
    case 'entity.program':
      return (
        <ContentAutoComplete
          ctx={ctx}
          predicate={predicate}
          contentTypes={['program']}
          formLabel='dictionary.program-plural'
        />
      )
    case 'entity.path':
      return (
        <ContentAutoComplete
          ctx={ctx}
          predicate={predicate}
          contentTypes={['path']}
          formLabel='dictionary.path-plural'
        />
      )
    case 'entity.skill':
      return <SkillAutoComplete ctx={ctx} predicate={predicate} />
    case 'entity.file':
    case 'entity.assessment':
    case 'entity.homework':
      return <CardAutoComplete ctx={ctx} predicate={predicate} entity={entity} assetContext={assetContext} />
    case 'entity.question':
    case 'entity.poll':
      return <ExerciseAutoComplete ctx={ctx} predicate={predicate} entity={entity} />
    case 'entity.certificate':
      return <CertificateAutoComplete ctx={ctx} predicate={predicate} />
    case 'entity.tag':
      return <TagAutoComplete ctx={ctx} predicate={predicate} />
    default:
      assertNever(entity)
  }
}

export const EntityAutoCompleteLabel = React.forwardRef<
  HTMLDivElement,
  {
    entity: AutoCompleteEntity
    predicate: Predicate
    readOnly?: boolean
  }
>(({ entity, ...rest }, ref) => {
  switch (entity) {
    case 'entity.user':
      return <UserAutocompleteLabel ref={ref} {...rest} />
    case 'entity.group':
      return <GroupAutocompleteLabel ref={ref} {...rest} />
    case 'entity.content':
    case 'entity.course':
    case 'entity.program':
    case 'entity.path':
      return <ContentAutocompleteLabel ref={ref} {...rest} />
    case 'entity.skill':
      return <SkillAutocompleteLabel ref={ref} {...rest} />
    case 'entity.file':
    case 'entity.assessment':
    case 'entity.homework':
      return <CardAutoCompleteLabel ref={ref} {...rest} />
    case 'entity.question':
    case 'entity.poll':
      return <ExerciseAutoCompleteLabel ref={ref} {...rest} />
    case 'entity.certificate':
      return <CertificateAutoCompleteLabel ref={ref} {...rest} />
    case 'entity.tag':
      return <TagAutoCompleteLabel ref={ref} {...rest} />
    default:
      assertNever(entity)
  }
})

const AutocompleteContainer = styled(View)`
  width: 400px;
`

const isValidValueForEntity = (value: Value, entity: AutoCompleteEntity): boolean => {
  switch (entity) {
    case 'entity.assessment':
    case 'entity.homework':
    case 'entity.file':
      return value.type === 'value.file-id'
    case 'entity.content':
      return value.type === 'value.content-id'
    case 'entity.course':
      return value.type === 'value.course-id'
    case 'entity.group':
      return value.type === 'value.group-id'
    case 'entity.path':
      return value.type === 'value.path-id'
    case 'entity.poll':
    case 'entity.question':
      return value.type === 'value.exercise-id'
    case 'entity.program':
      return value.type === 'value.program-id'
    case 'entity.skill':
      return value.type === 'value.skill-id'
    case 'entity.user':
      return value.type === 'value.user-id'
    case 'entity.certificate':
      return value.type === 'value.certificate-id'
    case 'entity.tag':
      return value.type === 'value.tag-id'
    default:
      assertNever(entity)
  }
}

export const filterInvalidValuesForEntity = (predicate: Predicate, entity: AutoCompleteEntity): Predicate => {
  switch (predicate.type) {
    case 'predicate.and':
    case 'predicate.or':
      return {
        ...predicate,
        values: predicate.values.filter(value => isValidValueForEntity(value, entity)),
      }
    case 'predicate.none':
      return predicate
    case 'predicate.single':
      return {
        ...predicate,
        value: isValidValueForEntity(predicate.value, entity)
          ? predicate.value
          : { type: 'value.none', value: null },
      }
    default:
      assertNever(predicate)
  }
}

export const DomainAutoComplete: React.FC<{
  ctx: Context
  domain: Extract<Domain, { type: 'domain.auto-complete' }>
  predicate: Predicate
  assetContext: AssetContext
  readOnly: boolean
}> = React.memo(({ ctx, domain, predicate, assetContext, readOnly }) => {
  const [open, setOpen] = React.useState(predicateIsEmpty(predicate))

  useOnMount(() => {
    const filteredPredicate = filterInvalidValuesForEntity(predicate, domain.entity)
    ctx.update(f => setPred(f, filteredPredicate))
  })

  return (
    <Popover
      align='start'
      isOpen={!readOnly && open}
      onOpenChange={setOpen}
      renderTrigger={() => (
        <EntityAutoCompleteLabel entity={domain.entity} predicate={predicate} readOnly={readOnly} />
      )}
    >
      <AutocompleteContainer padding='16'>
        <EntityAutoComplete
          entity={domain.entity}
          ctx={ctx}
          predicate={predicate}
          assetContext={assetContext}
        />
      </AutocompleteContainer>
    </Popover>
  )
})
