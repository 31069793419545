import { Modal } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { zIndex } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const TopRight = styled.div`
  top: 16px;
  right: 16px;
  position: absolute;
  z-index: ${zIndex.ON_MODAL};
`

const FullHeight = styled.div`
  height: 100%;
`

export const AITutorOverlay: FCC<{
  onClose: () => void
}> = ({ children, onClose }) => {
  return (
    <Modal size='full-screen' open onClose={onClose}>
      <FullHeight>
        <TopRight>
          <IconButton iconId='close' onClick={onClose} />
        </TopRight>
        {children}
      </FullHeight>
    </Modal>
  )
}
