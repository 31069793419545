import { TargetAndTransition, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import {
  helperInlineStates,
  mobileHelperConfig,
  useHelperVisibility,
} from 'sierra-client/views/course-helper/config'
import { RenderHelperContent } from 'sierra-client/views/course-helper/content'
import { containerStyles, seeThroughStyles } from 'sierra-client/views/course-helper/shared/containers'
import { useHelperSize } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { isSecondaryHelperStyling } from 'sierra-client/views/course-helper/shared/should-show-box-shadow'
import { HelperSize, HelperVisibility } from 'sierra-client/views/course-helper/types'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { ColorBuilder, color } from 'sierra-ui/color'
import styled, { useTheme } from 'styled-components'

const { fadeDuration, bubbleDuration, borderRadius } = mobileHelperConfig

const LayerIsolation = styled(motion.div)<{ $isFullWidthState?: boolean }>`
  position: relative;
  isolation: isolate;

  z-index: 1;
  min-height: 48px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 0;

  flex: 1 1 ${p => (p.$isFullWidthState === true ? 'min-content' : '100%')};
`

export const ContentWrapper = styled(motion.div)<{ $isFullWidthState?: boolean }>`
  position: relative;
  z-index: 1;
  min-height: 48px;
  overflow: hidden;
  width: ${p => (p.$isFullWidthState === true ? '100%' : 'auto')};

  flex: 0 1 min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

type BubbleVariantCustom = { size: HelperSize; duration: number; borderRadius: number }

const bubbleVariants: Record<HelperVisibility, (custom: BubbleVariantCustom) => TargetAndTransition> = {
  hidden: (custom: BubbleVariantCustom) => ({
    opacity: 0,
    width: 0,
    height: 0,
    borderRadius: custom.borderRadius,
    transition: { duration: custom.duration },
  }),
  visible: (custom: BubbleVariantCustom) => ({
    opacity: 1,
    width: custom.size.width,
    height: custom.size.height,
    borderRadius: custom.borderRadius,
    transition: { duration: custom.duration },
  }),
}

const Bubble = styled(ContentWrapper).attrs({ variants: bubbleVariants })<{
  $seeThrough: undefined | { backgroundColor: ColorBuilder }
}>`
  ${containerStyles};

  position: absolute;
  bottom: 0;
  right: 0;

  z-index: -1; /* The order is important for the hover to work */

  ${seeThroughStyles};
`

const contentVariants: Record<HelperVisibility, (delay: number) => TargetAndTransition> = {
  hidden: (delay: number) => ({
    opacity: 0,
    transition: {
      delay: delay,
      duration: fadeDuration,
    },
  }),
  visible: (delay: number) => ({
    opacity: 1,
    transition: {
      delay,
      duration: fadeDuration,
    },
  }),
}

const Inner = styled(motion.div).attrs({
  variants: contentVariants,
})<{ $isFullWidthState: boolean }>`
  overflow: hidden;
  pointer-events: auto;
  flex: ${p => (p.$isFullWidthState === true ? '1' : '0')} 1 auto;
  height: 100%;
  display: flex;
`

export const MobileHelperInternal: React.FC<{
  tutorState: TutorState
}> = ({ tutorState }) => {
  const helperState = useAtomValue(helperStateAtom)
  const visibility: HelperVisibility = useHelperVisibility()
  const { size, ref, animationEnabled } = useHelperSize()
  const isInlineState = helperInlineStates.includes(helperState.type)
  const isSecondaryStyling = isSecondaryHelperStyling(helperState)
  const theme = useTheme()
  const backgroundColor = color(theme.home.backgroundColor)

  return (
    <LayerIsolation>
      <ContentWrapper $isFullWidthState={!isInlineState}>
        <Inner
          ref={ref}
          key={helperState.type}
          custom={animationEnabled ? bubbleDuration : 0}
          initial='hidden'
          animate={visibility}
          $isFullWidthState={!isInlineState}
        >
          <RenderHelperContent tutorState={tutorState} />
        </Inner>
      </ContentWrapper>
      <Bubble
        $seeThrough={isSecondaryStyling ? { backgroundColor: backgroundColor } : undefined}
        custom={{ size, duration: animationEnabled ? bubbleDuration : 0, borderRadius }}
        initial='hidden'
        animate={visibility}
      />
    </LayerIsolation>
  )
}
