import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  useOnDrillDownDimension,
  useOnFilterOutTableValue,
} from 'sierra-client/features/insights/display-widgets/data-viewers/utils'
import { useNormalizedTableResult } from 'sierra-client/features/insights/display-widgets/normalize-data/normalize-data'
import {
  DownloadTableDataButton,
  TableToolbar,
} from 'sierra-client/features/insights/display-widgets/table/table-toolbar'
import { resultDataLoader } from 'sierra-client/features/insights/display-widgets/table/table-utils'
import { doFilterContainType } from 'sierra-client/features/insights/utils'
import { WidgetSortHandler } from 'sierra-client/features/insights/widget-builder/types'
import {
  WidgetBuilderState,
  buildWidgetBuilderStateFromWidget,
} from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { TableAPIOptions, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import {
  AggregationTableWidget,
  BarChartWidget,
  Dimension,
  DimensionArray,
  DimensionRef,
  LineChartWidget,
  ListTableWidget,
  MeasureArray,
  SortBy,
  TableResult,
  TableValue,
  ViewRef,
} from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { isDefined } from 'sierra-domain/utils'
import { InternalTruncatedText, Text, View } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'
import { DrillDownMenuItem, useDrillDownMenuItems } from '../../hooks/use-drill-down-menu-items'

const TableWrapper = styled(View).attrs({ grow: true, alignItems: 'stretch' })`
  overflow: hidden;
  margin: 0;
  width: 100%;

  * > ${InternalTruncatedText} {
    font-weight: ${fonts.weight.regular};
  }
  * > a:hover {
    ${InternalTruncatedText}:first-child {
      text-decoration: underline;
    }
  }
`

const useVirtualColumns = (
  addVirtualColumn: boolean,
  cellActions: CellActions
): VirtualColumns<TableData> => {
  const {
    selectedMeasures,
    selectedDimensions,
    selectedView,
    visualisation,
    drillDownDisabledByOrFilter,
    onDrillDownRow,
    onFilterOutRow,
  } = cellActions
  const menuItems = useDrillDownMenuItems({
    selectedMeasures,
    selectedDimension: selectedDimensions[0],
    selectedView,
    visualisation,
    disabledByOrFilter: drillDownDisabledByOrFilter,
  })

  const virtualColumns = useMemo(
    () => ({
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          options: { columnToggle: false },
          getProps: ({ pos }) => {
            return {
              onSelect: (item: DrillDownMenuItem) => {
                switch (item.id) {
                  case 'filter-out':
                    return onFilterOutRow(Number(pos.row))
                  default:
                    if (item.ref === undefined) return
                    return onDrillDownRow(Number(pos.row), item.ref)
                }
              },
              menuItems: menuItems,
            }
          },
        }),
      ],
    }),
    [menuItems, onDrillDownRow, onFilterOutRow]
  )

  if (!addVirtualColumn) {
    return { left: [], right: [] }
  } else {
    return virtualColumns
  }
}

type InsightsTableProps = {
  tableResult: TableResult
  showToolbar?: boolean
  onSort?: WidgetSortHandler
  sortBy?: SortBy
  onSetLimit?: (newLimit?: number) => void
  limit?: number
  selectedDimensions: DimensionArray
  selectedMeasures?: MeasureArray
  selectedView?: ViewRef
  onDrillDown?: (filter: Filter, newDimension: Dimension) => void
  onFilterOut?: (filter: Filter) => void
  widget: AggregationTableWidget | ListTableWidget | BarChartWidget | LineChartWidget
  disableNormalizeTableResult?: boolean
}

type CellActions = {
  onFilterOutRow: (rowPosition: number) => void
  onDrillDownRow: (rowPosition: number, dimensionRef: DimensionRef) => void
  selectedDimensions: DimensionArray
  selectedMeasures?: MeasureArray
  selectedView?: ViewRef
  visualisation?: WidgetBuilderState['visualisation']
  drillDownDisabledByOrFilter: boolean
}

const _TableWidget = styled(View)`
  height: 100%;
`

export const InsightsTable: React.FC<InsightsTableProps> = ({
  tableResult,
  showToolbar = true,
  onSort,
  sortBy,
  onSetLimit,
  limit,
  selectedDimensions,
  selectedMeasures,
  selectedView,
  onFilterOut,
  onDrillDown,
  widget,
  disableNormalizeTableResult = false,
}) => {
  const { t } = useTranslation()

  const normalizedTableResult = useNormalizedTableResult(
    tableResult,
    widget.type !== 'widget.list-table' ? widget : undefined,
    disableNormalizeTableResult
  )

  const onFilterOutTableValue = useOnFilterOutTableValue({
    onFilterOut,
    widget:
      widget.type === 'widget.aggregation-table' || widget.type === 'widget.bar-chart' ? widget : undefined,
  })
  const onDrillDownDimension = useOnDrillDownDimension({
    onDrillDown,
    widget:
      widget.type === 'widget.aggregation-table' || widget.type === 'widget.bar-chart' ? widget : undefined,
  })

  const tableSorting: TableAPIOptions['sorting'] = useMemo(() => {
    if (sortBy === undefined) return undefined

    const columns = normalizedTableResult.schema.columns
    const initialSorting = sortBy
      .map(sortByColumn => {
        const column = columns.find(column => _.isEqual(column.ref, sortByColumn.column))
        if (column === undefined) {
          console.error(
            'Could not convert insights sorting to table sorting for column ref',
            sortByColumn.column
          )
          return undefined
        }
        return {
          direction: sortByColumn.order === 'asc' ? ('ascending' as const) : ('descending' as const),
          column: column.name,
        }
      })
      .filter(isDefined)

    return { initialSorting }
  }, [normalizedTableResult.schema.columns, sortBy])

  const addVirtualColumn = onFilterOutTableValue !== undefined || onDrillDownDimension !== undefined
  const getTableValue = useCallback(
    (rowPosition: number): TableValue | undefined => {
      const columnName = normalizedTableResult.schema.columns[0]?.name
      const tableValue = normalizedTableResult.rows[rowPosition]?.values[columnName ?? '']
      return tableValue
    },
    [normalizedTableResult.schema.columns, normalizedTableResult.rows]
  )

  const onFilterOutRow = useCallback(
    (rowPosition: number): void => {
      if (onFilterOutTableValue === undefined) return

      const tableValue = getTableValue(rowPosition)
      if (tableValue === undefined) return

      onFilterOutTableValue(tableValue)
    },
    [onFilterOutTableValue, getTableValue]
  )

  const onDrillDownRow = useCallback(
    (rowPosition: number, dimensionRef: DimensionRef) => {
      if (onDrillDownDimension === undefined) return

      const tableValue = getTableValue(rowPosition)
      if (tableValue === undefined) return

      onDrillDownDimension(tableValue, dimensionRef)
    },
    [onDrillDownDimension, getTableValue]
  )

  const drillDownDisabledByOrFilter = doFilterContainType(widget.filter, 'filter.or')

  const visualisation = buildWidgetBuilderStateFromWidget(widget).visualisation
  const cellActions: CellActions = useMemo(
    () => ({
      onFilterOutRow,
      onDrillDownRow,
      selectedDimensions,
      selectedMeasures,
      selectedView,
      visualisation,
      drillDownDisabledByOrFilter,
    }),
    [
      onFilterOutRow,
      onDrillDownRow,
      selectedDimensions,
      selectedMeasures,
      selectedView,
      visualisation,
      drillDownDisabledByOrFilter,
    ]
  )

  const virtualColumns = useVirtualColumns(addVirtualColumn, cellActions)

  const tableAPI = useTableAPI({
    dataLoader: useMemo(
      () => ({ loader: resultDataLoader(normalizedTableResult, t, onSort !== undefined) }),
      [t, normalizedTableResult, onSort]
    ),
    virtualColumns: virtualColumns,
    options: { sorting: tableSorting, queryStateKeyPrefix: null },
  })

  const { api } = tableAPI

  useEffect(() => {
    if (onSort === undefined) return

    const unsubscribe = tableAPI.store.sub(tableAPI.api.atoms.sorting, () => {
      const sorting = tableAPI.api.query.sorting().sorting
      const columns = normalizedTableResult.schema.columns
      const insightsSorting = sorting
        .map(sortByColumn => {
          const column = columns.find(column => column.name === sortByColumn.column)
          if (column === undefined) {
            console.error(
              'Could not convert table sorting to insights sorting for column name',
              sortByColumn.column
            )
            return undefined
          }
          return {
            order: sortByColumn.direction === 'ascending' ? ('asc' as const) : ('desc' as const),
            column: column.ref,
          }
        })
        .filter(isDefined) satisfies SortBy
      onSort(insightsSorting)
    })

    return () => {
      unsubscribe()
    }
  }, [
    onSort,
    normalizedTableResult.schema.columns,
    tableAPI.api.atoms.sorting,
    tableAPI.api.query,
    tableAPI.api.query.sorting,
    tableAPI.store,
  ])

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <_TableWidget grow direction='column' data-testid='table-widget'>
        {showToolbar && (
          <TableToolbar
            api={api}
            widget={widget}
            limit={limit}
            onSetLimit={onSetLimit}
            dbSofLimitReached={tableResult.dbSoftLimitReached}
          />
        )}
        <TableWrapper>
          <BasicTabular
            footer={
              tableResult.dbSoftLimitReached ? (
                <tfoot>
                  <View>
                    <Text color='foreground/muted'>
                      {t('manage.insights.widgetbuilder.db-soft-limit-use-download', {
                        count: tableResult.rows.length,
                      })}
                    </Text>
                    <DownloadTableDataButton widget={widget} />
                  </View>
                </tfoot>
              ) : null
            }
          />
        </TableWrapper>
      </_TableWidget>
    </TabularProviderFromTableAPI>
  )
}
