import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Domain, Predicate, Single, Value, setPred, stringValue } from 'sierra-client/lib/filter'
import { DomainAutoComplete } from 'sierra-client/lib/filter/components/auto-complete/auto-complete'
import { Context, labelToString, labelsForPredicate } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  predicateIsEmpty,
  removePredValue,
  valueId,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { DomainRep, DomainType as DomainTypeType } from 'sierra-domain/filter/datatype/domain'
import { assertNever, isDefined, isNonEmptyString } from 'sierra-domain/utils'
import { FormElement, Popover } from 'sierra-ui/components'
import { MenuContainer, MenuItem } from 'sierra-ui/components/menu'
import { InputPrimitive } from 'sierra-ui/primitives'
import { MultiSelectDropdown } from 'sierra-ui/primitives/menu-dropdown/multi-select-dropdown'
import { useWasRenderedOnce } from 'sierra-ui/utils'

const Int = (value: number): Value => ({ type: 'value.integer', value })
const Time = (value: string): Value => ({ type: 'value.time', value })
const Date = (value: string): Value => ({ type: 'value.date', value })

export type MenuItemWithValue = MenuItem & {
  value?: Value
}

const DomainChoices: React.FC<{
  ctx: Context
  domain: Extract<Domain, { type: 'domain.choices' }>
  domainRep: DomainRep
  predicate: Predicate
  readOnly: boolean
}> = React.memo(({ ctx, domain, domainRep, predicate, readOnly }) => {
  const { dynamicT, t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const renderedOnce = useWasRenderedOnce()
  useEffect(() => {
    if (!renderedOnce) {
      if (predicateIsEmpty(predicate)) {
        setOpen(true)
      }
    }
  }, [predicate, renderedOnce])

  const labels = useMemo(
    () => labelsForPredicate(domainRep, predicate, dynamicT),
    [domainRep, predicate, dynamicT]
  )

  const label = useMemo(() => labels.join(', '), [labels])

  const ids = useMemo(() => {
    switch (predicate.type) {
      case 'predicate.none':
        return []
      case 'predicate.single':
        return [valueId(predicate.value)]
      case 'predicate.and':
      case 'predicate.or':
        return predicate.values.map(value => valueId(value))
      default:
        assertNever(predicate)
    }
  }, [predicate])

  const items: MenuItemWithValue[] = useMemo(
    () => [
      {
        id: 'predicate',
        type: 'group' as const,
        label: labelToString(domainRep.label, dynamicT),
        menuItems: domain.choices.map(valueRep => {
          const id = valueId(valueRep.value)
          return {
            id,
            type: 'checkbox' as const,
            label: labelToString(valueRep.label, dynamicT),
            value: valueRep.value,
            checked: ids.includes(id),
            onToggleChange: () => {},
          }
        }),
      },
    ],
    [domain.choices, domainRep.label, dynamicT, ids]
  )

  const onSelect = useCallback(
    (item: MenuItemWithValue) => {
      if (item.value !== undefined) {
        const value = item.value
        ctx.update(f => setPred(f, addPredValue(predicate, value)))
      }
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (item: MenuItemWithValue) => {
      if (item.value !== undefined) {
        const value = item.value
        ctx.update(f => setPred(f, removePredValue(predicate, value)))
      }
    },
    [ctx, predicate]
  )

  const selectedItems = useMemo(() => {
    const allItems = items.flatMap(item => ('menuItems' in item ? item.menuItems : [item]))
    return ids.map(id => allItems.find(item => item.id === id)).filter(isDefined)
  }, [ids, items])

  return (
    <MultiSelectDropdown
      onOpenChange={setOpen}
      isOpen={open}
      withSearch={domain.choices.length > 8}
      searchPlaceholder={t('menu.search.placeholder')}
      selectedItems={selectedItems}
      menuItems={items}
      onSelect={onSelect}
      onUnselect={onUnselect}
      renderTrigger={() => <ValueAnchor label={label} readOnly={readOnly} />}
      disabled={readOnly}
    />
  )
})

const DomainTypeInput: React.FC<{
  ctx: Context
  domain: DomainTypeType
  domainRep: DomainRep
  predicate: Predicate
}> = React.memo(({ ctx, domain, domainRep, predicate }) => {
  const { dynamicT } = useTranslation()

  switch (domain.typeOf.typeOf.type) {
    case 'type.string':
      return (
        <FormElement label={labelToString(domainRep.label, dynamicT)} helper={'Enter value to filter on'}>
          <InputPrimitive
            id={labelToString(domainRep.label, dynamicT)}
            type='text'
            autoFocus={true}
            value={
              predicate.type === 'predicate.single' &&
              predicate.value.type !== 'value.none' &&
              predicate.value.type !== 'value.exercise-id' &&
              predicate.value.type !== 'value.file-id'
                ? predicate.value.value.toString()
                : ''
            }
            onChange={e => ctx.update(f => setPred(f, Single(stringValue(e.target.value))))}
          />
        </FormElement>
      )
    case 'type.date':
      return (
        <FormElement label={labelToString(domainRep.label, dynamicT)} helper={'Enter value to filter on'}>
          <InputPrimitive
            type='date'
            autoFocus={true}
            max='9999-12-31'
            value={
              predicate.type === 'predicate.single' &&
              predicate.value.type !== 'value.none' &&
              predicate.value.type !== 'value.exercise-id' &&
              predicate.value.type !== 'value.file-id'
                ? predicate.value.value.toString()
                : ''
            }
            onChange={e => {
              if (isNonEmptyString(e.target.value)) {
                ctx.update(f => setPred(f, Single(Date(e.target.value))))
              }
            }}
          />
        </FormElement>
      )
    case 'type.time':
      return (
        <FormElement label={labelToString(domainRep.label, dynamicT)} helper={'Enter value to filter on'}>
          <InputPrimitive
            id={labelToString(domainRep.label, dynamicT)}
            type='time'
            autoFocus={true}
            value={
              predicate.type === 'predicate.single' &&
              predicate.value.type !== 'value.none' &&
              predicate.value.type !== 'value.exercise-id' &&
              predicate.value.type !== 'value.file-id'
                ? predicate.value.value.toString()
                : ''
            }
            onChange={e => ctx.update(f => setPred(f, Single(Time(e.target.value))))}
          />
        </FormElement>
      )
    case 'type.integer':
      return (
        <FormElement label={labelToString(domainRep.label, dynamicT)} helper={'Enter value to filter on'}>
          <InputPrimitive
            id={labelToString(domainRep.label, dynamicT)}
            type='number'
            autoFocus={true}
            value={
              predicate.type === 'predicate.single' &&
              predicate.value.type !== 'value.none' &&
              predicate.value.type !== 'value.exercise-id' &&
              predicate.value.type !== 'value.file-id'
                ? predicate.value.value.toString()
                : ''
            }
            onChange={e => ctx.update(f => setPred(f, Single(Int(parseInt(e.target.value)))))}
          />
        </FormElement>
      )
    case 'type.boolean':
    case 'type.uuid':
    case 'type.nanoid12':
    case 'type.phone-number':
      return <></>
    default:
      assertNever(domain.typeOf.typeOf)
  }
})

const DomainType: React.FC<{
  ctx: Context
  domain: Extract<Domain, { type: 'domain.type' }>
  domainRep: DomainRep
  predicate: Predicate
  readOnly: boolean
}> = React.memo(({ ctx, domain, domainRep, predicate, readOnly }) => {
  const { dynamicT } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const renderedOnce = useWasRenderedOnce()
  useEffect(() => {
    if (!renderedOnce) {
      if (predicateIsEmpty(predicate)) {
        setOpen(true)
      }
    }
  }, [predicate, renderedOnce])

  const labels = useMemo(
    () => labelsForPredicate(domainRep, predicate, dynamicT),
    [domainRep, predicate, dynamicT]
  )

  const label = useMemo(() => labels.join(', '), [labels])

  return (
    <Popover
      isOpen={!readOnly && open}
      align='start'
      onOpenChange={setOpen}
      renderTrigger={() => <ValueAnchor label={label} readOnly={readOnly} />}
    >
      <MenuContainer padding='xsmall' marginTop={'xxsmall'}>
        <DomainTypeInput ctx={ctx} domain={domain} domainRep={domainRep} predicate={predicate} />
      </MenuContainer>
    </Popover>
  )
})

export const PredicateComponent: React.FC<{
  ctx: Context
  domainRep: DomainRep
  predicate: Predicate
  assetContext: AssetContext
  readOnly: boolean
}> = React.memo(({ ctx, domainRep, predicate, assetContext, readOnly }) => {
  if (predicate.type === 'predicate.none') {
    return null
  }
  switch (domainRep.domain.type) {
    case 'domain.choices':
      return (
        <DomainChoices
          ctx={ctx}
          domainRep={domainRep}
          domain={domainRep.domain}
          predicate={predicate}
          readOnly={readOnly}
        />
      )
    case 'domain.type':
      return (
        <DomainType
          ctx={ctx}
          domainRep={domainRep}
          domain={domainRep.domain}
          predicate={predicate}
          readOnly={readOnly}
        />
      )
    case 'domain.auto-complete':
      return (
        <DomainAutoComplete
          ctx={ctx}
          domain={domainRep.domain}
          predicate={predicate}
          assetContext={assetContext}
          readOnly={readOnly}
        />
      )
    case 'domain.with-parent-choices':
    case 'domain.phone-number':
      return <></>
    default:
      assertNever(domainRep.domain)
  }
})
