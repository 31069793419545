import React, { useState } from 'react'
import { RecurrencePeriodDialog } from 'sierra-client/components/recurrent-assignments/recurrence-dialog'
import { intervalToString } from 'sierra-client/components/recurrent-assignments/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Interval } from 'sierra-domain/api/manage'
import { Button } from 'sierra-ui/primitives'

export interface RecurrenceButtonProps {
  recurrencePeriod?: Interval
  onChangeRecurrencePeriod: (recurrencePeriod: Interval) => void
  onClearRecurrencePeriod: () => void
}

export const RecurrenceButton: React.FC<RecurrenceButtonProps> = ({
  recurrencePeriod,
  onChangeRecurrencePeriod,
  onClearRecurrencePeriod,
}) => {
  const { t } = useTranslation()
  const [showCustomCadenceDialog, setShowCustomCadenceDialog] = useState(false)
  const [selectedRecurrencePeriod, setSelectedRecurrencePeriod] = useState<Interval | undefined>(
    recurrencePeriod
  )

  const changeRecurrencePeriod = (recurrencePeriod: Interval): void => {
    setSelectedRecurrencePeriod(recurrencePeriod)
    onChangeRecurrencePeriod(recurrencePeriod)
  }

  const clearRecurrencePeriod = (): void => {
    setSelectedRecurrencePeriod(undefined)
    onClearRecurrencePeriod()
  }

  return (
    <>
      <Button
        key='recurrence-button'
        icon='restart'
        variant='secondary'
        onClick={() => setShowCustomCadenceDialog(true)}
      >
        {selectedRecurrencePeriod === undefined
          ? t('recurrence.set-recurrence')
          : intervalToString(selectedRecurrencePeriod, t)}
      </Button>
      <RecurrencePeriodDialog
        open={showCustomCadenceDialog}
        value={selectedRecurrencePeriod}
        onSave={changeRecurrencePeriod}
        onClear={clearRecurrencePeriod}
        onClose={() => setShowCustomCadenceDialog(false)}
      />
    </>
  )
}
