import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { GoogleCalendarLogo } from 'sierra-client/components/common/logos/google-calendar-logo'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { OutlookLogo } from 'sierra-client/components/common/logos/outlook-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { getDisabledReason, useDisableInput } from 'sierra-client/components/disabling'
import { GoogleMeetOauth } from 'sierra-client/components/sana-now-integration-oauth/google-meet-oauth'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import { ZoomOauth } from 'sierra-client/components/sana-now-integration-oauth/zoom-oauth'
import { config, getFlag } from 'sierra-client/config/global-config'
import {
  useAuthenticatedUserIntegrationsQuery,
  useInvalidateAuthenticatedUserIntegrationsQuery,
} from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DisconnectAlert } from 'sierra-client/views/user-settings/components/disconnect-alert'
import { NotificationHeader } from 'sierra-client/views/user-settings/components/notification-input'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Button, LoadingSpinner, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/default')};
  height: 1px;
`

const revokeTeamsIntegrationMutation = graphql(`
  mutation RevokeTeamsIntegration {
    revokeMicrosoftTeamsToken {
      __typename
    }
  }
`)

const revokeMeetIntegrationMutation = graphql(`
  mutation RevokeMeetIntegration {
    revokeGoogleMeetToken {
      __typename
    }
  }
`)

const revokeZoomIntegrationMutation = graphql(`
  mutation RevokeZoomIntegration {
    revokeZoomToken {
      __typename
    }
  }
`)

const ApplicationIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${token('border/strong')};
  background-color: ${token('surface/default')};
  flex-shrink: 0;
`

const ShrinkView = styled(View)`
  flex-shrink: 0;
`

const IntegrationRow: React.FC<{
  logo: JSX.Element
  title: string
  description: string
  button: JSX.Element
}> = ({ logo, title, description, button }) => (
  <View grow>
    <ApplicationIcon>{logo}</ApplicationIcon>
    <Spacer size='4' />
    <View gap='2' direction='column' grow>
      <Text size='small' bold>
        {title}
      </Text>
      <Text size='small' color='foreground/muted'>
        {description}
      </Text>
    </View>
    <ShrinkView>{button}</ShrinkView>
  </View>
)

const StyledIntegrationWrapper = styled(View)`
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 #00000014;
  border: 1px solid ${token('border/default')};
`

const IntegrationWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StyledIntegrationWrapper padding='24' justifyContent='space-between' gap='24' direction='column'>
    {children}
  </StyledIntegrationWrapper>
)

const ConnectedDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${token('success/background')};
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${token('success/background').opacity(0.2)};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(calc(14 / 8));
  }
`

type Service = 'microsoft' | 'google' | 'zoom'

const typeToLabel = (type: Service): string => {
  switch (type) {
    case 'google':
      return 'Google'
    case 'microsoft':
      return 'Microsoft'
    case 'zoom':
      return 'Zoom'
    default:
      assertNever(type)
  }
}

const ConnectIntegrationButton: React.FC<
  {
    clientId: string
    type: Service
    integration: string
    isAuthenticated: boolean
    isLoading: boolean
    scope: string[]
  } & ({ asToggle: true; revoke?: () => void } | { asToggle?: never })
> = ({ type, clientId, isAuthenticated, isLoading, scope, integration, ...props }) => {
  const { t } = useTranslation()
  const invalidate = useInvalidateAuthenticatedUserIntegrationsQuery()
  const oauthRef = React.useRef<ReactSimpleOauth2LoginRef | null>(null)
  const disableInputContext = useDisableInput()
  const [alertOpen, setAlertOpen] = useState(false)

  return (
    <>
      {props.asToggle ? (
        <>
          <Switch
            ariaLabel={t('calendar-integrations.user-settings.integration-toggle-aria-label')}
            checked={isAuthenticated}
            onChange={() => {
              // connect when not enabled
              if (isAuthenticated) {
                setAlertOpen(true)
              } else {
                oauthRef.current?.onBtnClick()
              }
            }}
            disabled={disableInputContext.disabled}
          />
          <DisconnectAlert
            open={alertOpen}
            primaryAction={() => {
              // disconnect when enabled
              if (isAuthenticated) {
                props.revoke?.()
              }
            }}
            secondaryAction={() => setAlertOpen(false)}
            service={typeToLabel(type)}
            integrations={[integration]}
            loading={isLoading}
          />
        </>
      ) : isAuthenticated ? (
        <View direction='row' alignItems='center' gap='8'>
          <Text bold>{t('search.integrations.connected')}</Text>
          <ConnectedDot />
        </View>
      ) : (
        <Button
          variant='secondary'
          loading={isLoading}
          onClick={() => {
            oauthRef.current?.onBtnClick()
          }}
          disabledWithReason={getDisabledReason(disableInputContext)}
        >
          {t('settings.live-meeting-tools-button--connect')}
        </Button>
      )}
      {iife(() => {
        if (isAuthenticated) {
          return
        }

        switch (type) {
          case 'microsoft':
            return (
              <MicrosoftTeamsOauth ref={oauthRef} clientId={clientId} onSuccess={invalidate} scope={scope} />
            )
          case 'google':
            return <GoogleMeetOauth ref={oauthRef} clientId={clientId} onSuccess={invalidate} scope={scope} />
          case 'zoom':
            return <ZoomOauth ref={oauthRef} clientId={clientId} onSuccess={invalidate} scope={scope} />
          default:
            assertNever(type)
        }
      })}
    </>
  )
}

type IntegrationProps = {
  isEnabled: boolean
  isAuthenticated: boolean
  scope: string[]
}

const GoogleIntegrationView: React.FC<{
  clientId: string
  gcal: IntegrationProps
  meet: IntegrationProps
}> = ({ clientId, meet, gcal }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeGoogleIntegration = useMutation({
    mutationFn: () => graphQuery(revokeMeetIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })

  const disableInputContext = useDisableInput()
  const hasOnlyOneOption = [gcal.isEnabled, meet.isEnabled].filter(s => s).length === 1

  const [alertOpen, setAlertOpen] = useState(false)
  const integrations = [t('dictionary.google-calendar'), t('dictionary.google-meet')]

  return (
    <IntegrationWrapper>
      {meet.isEnabled && (
        <IntegrationRow
          logo={<MeetLogo width={32} height={32} />}
          title={t('dictionary.google-meet')}
          description={t('settings.live-meeting-tools-provider-subtitle--meet')}
          button={
            <ConnectIntegrationButton
              type='google'
              isAuthenticated={meet.isAuthenticated}
              isLoading={revokeGoogleIntegration.isPending}
              clientId={clientId}
              integration={t('dictionary.google-meet')}
              scope={meet.scope}
              {...(hasOnlyOneOption
                ? { asToggle: true, revoke: () => revokeGoogleIntegration.mutate() }
                : {})}
            />
          }
        />
      )}

      {meet.isEnabled && gcal.isEnabled && <Divider />}

      {gcal.isEnabled && (
        <IntegrationRow
          logo={<GoogleCalendarLogo width={32} height={32} />}
          title={t('dictionary.google-calendar')}
          description={t('calendar-integrations.user-settings.google-info')}
          button={
            <ConnectIntegrationButton
              type='google'
              integration={t('dictionary.google-calendar')}
              isAuthenticated={gcal.isAuthenticated}
              isLoading={revokeGoogleIntegration.isPending}
              clientId={clientId}
              scope={gcal.scope}
              {...(hasOnlyOneOption
                ? { asToggle: true, revoke: () => revokeGoogleIntegration.mutate() }
                : {})}
            />
          }
        />
      )}

      {(meet.isAuthenticated || gcal.isAuthenticated) && !hasOnlyOneOption && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeGoogleIntegration.isPending}
              onClick={() => setAlertOpen(true)}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('calendar-integrations.user-settings.disconnect-all')}
            </Button>
            <DisconnectAlert
              open={alertOpen}
              service='Google'
              integrations={integrations}
              primaryAction={() => revokeGoogleIntegration.mutate()}
              secondaryAction={() => setAlertOpen(false)}
              loading={revokeGoogleIntegration.isPending}
            />
          </View>
        </>
      )}
    </IntegrationWrapper>
  )
}

const MicrosoftIntegrationView: React.FC<{
  clientId: string
  outlook: IntegrationProps
  teams: IntegrationProps
}> = ({ clientId, outlook, teams }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeTeamsIntegration = useMutation({
    mutationFn: () => graphQuery(revokeTeamsIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })
  const disableInputContext = useDisableInput()

  const hasOnlyOneOption = [outlook.isEnabled, teams.isEnabled].filter(s => s).length === 1

  const [alertOpen, setAlertOpen] = useState(false)
  const integrations = [t('dictionary.microsoft-teams'), t('dictionary.microsoft-outlook')]

  return (
    <IntegrationWrapper>
      {teams.isEnabled && (
        <IntegrationRow
          logo={<TeamsLogo width={32} height={32} />}
          title={t('dictionary.microsoft-teams')}
          description={t('settings.live-meeting-tools-provider-subtitle--teams')}
          button={
            <ConnectIntegrationButton
              type='microsoft'
              isAuthenticated={teams.isAuthenticated}
              isLoading={revokeTeamsIntegration.isPending}
              clientId={clientId}
              integration={t('dictionary.microsoft-teams')}
              scope={teams.scope}
              {...(hasOnlyOneOption ? { asToggle: true, revoke: () => revokeTeamsIntegration.mutate() } : {})}
            />
          }
        />
      )}

      {outlook.isEnabled && (
        <IntegrationRow
          logo={<OutlookLogo width={32} height={32} />}
          title={t('dictionary.microsoft-outlook')}
          description={t('calendar-integrations.user-settings.outlook-info')}
          button={
            <ConnectIntegrationButton
              type='microsoft'
              isAuthenticated={outlook.isAuthenticated}
              isLoading={revokeTeamsIntegration.isPending}
              integration={t('dictionary.microsoft-outlook')}
              clientId={clientId}
              scope={outlook.scope}
              {...(hasOnlyOneOption ? { asToggle: true, revoke: () => revokeTeamsIntegration.mutate() } : {})}
            />
          }
        />
      )}

      {(teams.isAuthenticated || outlook.isAuthenticated) && !hasOnlyOneOption && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeTeamsIntegration.isPending}
              onClick={() => setAlertOpen(true)}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('dictionary.remove')}
            </Button>
          </View>
          <DisconnectAlert
            open={alertOpen}
            service='Microsoft'
            integrations={integrations}
            primaryAction={() => revokeTeamsIntegration.mutate()}
            secondaryAction={() => setAlertOpen(false)}
            loading={revokeTeamsIntegration.isPending}
          />
        </>
      )}
    </IntegrationWrapper>
  )
}

const ZoomIntegrationView: React.FC<{
  clientId: string
  isAuthenticated: boolean
  scope: string[]
}> = ({ clientId, isAuthenticated, scope }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeZoomIntegration = useMutation({
    mutationFn: () => graphQuery(revokeZoomIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })
  const disableInputContext = useDisableInput()

  return (
    <IntegrationWrapper>
      <IntegrationRow
        logo={<ZoomLogo width={32} height={32} />}
        title='Zoom'
        description={t('settings.live-meeting-tools-provider-subtitle--zoom')}
        button={
          <ConnectIntegrationButton
            type='zoom'
            integration='Zoom'
            isAuthenticated={isAuthenticated}
            isLoading={revokeZoomIntegration.isPending}
            clientId={clientId}
            scope={scope}
          />
        }
      />

      {isAuthenticated && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeZoomIntegration.isPending}
              onClick={() => {
                revokeZoomIntegration.mutate()
              }}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('dictionary.remove')}
            </Button>
          </View>
        </>
      )}
    </IntegrationWrapper>
  )
}

export const UserIntegrationSettingsForm: React.FC = () => {
  const { t } = useTranslation()
  const { data, isSuccess } = useAuthenticatedUserIntegrationsQuery()

  const meetingToolsSettings = config.organization.settings.meetingToolsSettings
  const meetEnabled = meetingToolsSettings.meetSettings.enabled
  const teamsEnabled = meetingToolsSettings.teamsSettings.enabled
  const zoomEnabled = meetingToolsSettings.zoomSettings.enabled

  const calendarIntegrationSettings = config.organization.settings.calendarIntegrationSettings
  const outlookEnabled = getFlag('rsvp') && calendarIntegrationSettings.type === 'microsoft'
  const gcalEnabled = getFlag('rsvp') && calendarIntegrationSettings.type === 'google'

  if (!isSuccess) return <LoadingSpinner />

  return (
    <View direction='column' alignItems='stretch' gap='16' paddingBottom='32'>
      <NotificationHeader label={t('settings.live-meeting-tools-title')} />
      {isDefined(data.viewer.integrations.google.clientId) && (meetEnabled || gcalEnabled) && (
        <GoogleIntegrationView
          clientId={data.viewer.integrations.google.clientId}
          meet={{
            isEnabled: meetEnabled,
            isAuthenticated: data.viewer.integrations.google.capabilities.canCreateMeetingUrl,
            scope: data.viewer.integrations.google.createMeetingUrlScope.map(scope => scope.value),
          }}
          gcal={{
            isEnabled: gcalEnabled,
            isAuthenticated: data.viewer.integrations.google.capabilities.canCreateGoogleCalendarEvent,
            scope: data.viewer.integrations.google.createGoogleCalendarEventScope.map(scope => scope.value),
          }}
        />
      )}

      {isDefined(data.viewer.integrations.microsoft.clientId) && (outlookEnabled || teamsEnabled) && (
        <MicrosoftIntegrationView
          clientId={data.viewer.integrations.microsoft.clientId}
          teams={{
            isEnabled: teamsEnabled,
            isAuthenticated: data.viewer.integrations.microsoft.capabilities.canCreateMeetingUrl,
            scope: data.viewer.integrations.microsoft.createMeetingUrlScope.map(scope => scope.value),
          }}
          outlook={{
            isEnabled: outlookEnabled,
            isAuthenticated: data.viewer.integrations.microsoft.capabilities.canCreateMicrosoftCalendarEvent,
            scope: data.viewer.integrations.microsoft.createMicrosoftCalendarEventScope.map(
              scope => scope.value
            ),
          }}
        />
      )}

      {isDefined(data.viewer.integrations.zoom.clientId) && zoomEnabled && (
        <ZoomIntegrationView
          clientId={data.viewer.integrations.zoom.clientId}
          isAuthenticated={data.viewer.integrations.zoom.capabilities.canCreateMeetingUrl}
          scope={data.viewer.integrations.zoom.createMeetingUrlScope.map(scope => scope.value)}
        />
      )}
    </View>
  )
}
