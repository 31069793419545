import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { color } from 'sierra-ui/color'
import { palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

interface HotspotContainerProps {
  isOpen: boolean
  $x: number
  $y: number
}

export const HotspotContainer = styled.div.attrs<HotspotContainerProps>(p => ({
  // Avoid forcing styled-components to create a bunch of classes
  style: {
    left: `${p.$x * 100}%`,
    top: `${p.$y * 100}%`,
  },
}))<HotspotContainerProps>`
  position: absolute;
  text-align: left;
`

const hotspotCircleStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
`

interface HotspotIconProps {
  isOpen?: boolean
}

export const HotspotIcon = styled.div<HotspotIconProps>`
  ${hotspotCircleStyle};

  width: 24px;
  height: 24px;
  background-color: ${color(palette.primitives.black).opacity(0.5).toString()};

  cursor: pointer;

  &::before {
    content: '';
    ${hotspotCircleStyle};
    width: 8px;
    height: 8px;
    background-color: white;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
  }

  &::after {
    display: ${p => ((p.isOpen ?? false) ? 'block' : 'none')};
  }

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    width: 48px;
    height: 48px;

    &::before {
      width: 16px;
      height: 16px;
    }

    &::after {
      content: '';
      ${hotspotCircleStyle};
      width: 8px;
      height: 8px;
      background: ${p => p.theme.color.grey90};
    }
  }
`

const MobileCloseIconContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  pointer-events: none;

  display: block;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    display: none;
  }
`

const MobileCheckmarkSVG = styled.svg.attrs({
  viewBox: '0 0 12 12',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
})`
  color: ${p => p.theme.color.grey90};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 8px;
  height: 8px;
`

export const MobileCloseIcon: React.FC<unknown> = () => {
  return (
    <MobileCloseIconContainer>
      <MobileCheckmarkSVG>
        <path
          d='M6.7 6L11.5 1.2L10.8 0.5L6 5.3L1.2 0.5L0.5 1.2L5.3 6L0.5 10.8L1.2 11.5L6 6.7L10.8 11.5L11.5 10.8L6.7 6Z'
          fill='currentColor'
        />
      </MobileCheckmarkSVG>
    </MobileCloseIconContainer>
  )
}

export const HotspotArrow = styled.div<{ $placement?: 'above' | 'below' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  ${p =>
    p.$placement === 'below'
      ? css`
          bottom: -36px;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
          border-bottom: 8px solid white;
          z-index: 2;

          @media screen and (min-width: ${v2_breakpoint.phone}) {
            border-bottom: 10px solid white;
          }
        `
      : css`
          top: -36px;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-top: 12px solid white;

          @media screen and (min-width: ${v2_breakpoint.phone}) {
            border-top: 10px solid white;
          }
        `}
`

export const HotspotText = styled.div<{ $placement?: 'above' | 'below' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  width: max-content;
  max-width: 300px;
  border-radius: 8px;
  padding: 16px;
  line-height: 1.5;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
  z-index: 1;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${p =>
    p.$placement === 'below'
      ? css`
          top: 32px;
        `
      : css`
          bottom: 32px;
        `}
`

type ViewportAwareHotspotTextInnerProps = {
  $hotspotOffsetX?: number
}

const ViewportAwareHotspotTextInner = styled(HotspotText).attrs<ViewportAwareHotspotTextInnerProps>(p => ({
  style: {
    '--hotspot-text-offset-x': `${p.$hotspotOffsetX ?? 0}px`,
  },
}))<ViewportAwareHotspotTextInnerProps>`
  /* Don't show until we've calculated the offset to avoid jarring movement */
  visibility: ${p => (p.$hotspotOffsetX !== undefined ? 'visible' : 'hidden')};
  transform: translateX(-50%) translateX(var(--hotspot-text-offset-x));

  @media screen and (max-width: calc(300px + 1rem)) {
    max-width: calc(100vw - 1rem);
  }
`

type ViewportAwareHotspotTextProps = {
  parentElement: HTMLDivElement | null
}

export const ViewportAwareHotspotText: FCC<ViewportAwareHotspotTextProps> = ({ children, parentElement }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useSlateStatic()
  const container = ReactEditor.toDOMNode(editor, editor)
  const containerRect = container.getBoundingClientRect()
  const containerWidth = containerRect.width

  const [offsetX, setOffsetX] = useState<number | undefined>(undefined)
  const [ownWidth, setOwnWidth] = useState<number | undefined>(undefined)
  const [position, setPosition] = useState<'above' | 'below'>('above')

  const containerCenter = Math.floor(containerRect.left + containerRect.width / 2)

  // Parent element's left position -- in this case it's the same as this element's center position.
  const centerX = useMemo(
    () => parentElement?.getBoundingClientRect().x,
    [parentElement, containerWidth] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Measures and sets own width
  const measureSelf = useCallback(() => {
    if (ref.current) {
      setOwnWidth(ref.current.getBoundingClientRect().width)
    }
  }, [])

  // Sets ref and triggers a measurement
  const handleRef = useCallback(
    (el: HTMLDivElement) => {
      ref.current = el
      measureSelf()
    },
    [measureSelf, ref]
  )

  // Trigger measurement when children or window size changes
  useEffect(measureSelf, [children, containerWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (ownWidth === undefined || centerX === undefined || ref.current === null || parentElement === null)
      return

    const spaceAbove = parentElement.getBoundingClientRect().top
    const hotSpotTextHeight = ref.current.getBoundingClientRect().height
    const textFitAbove = spaceAbove - hotSpotTextHeight > 37 //distance between hotspot and where the text starts

    const actualLeft = centerX - ownWidth / 2
    const actualRight = centerX + ownWidth / 2

    const MARGIN = 8 // 0.5 rem
    const leftLimit = containerRect.left + MARGIN
    const rightLimit = containerRect.right - MARGIN
    const widthLimit = rightLimit - leftLimit

    if (!textFitAbove) {
      setPosition('below')
    } else setPosition('above')

    if (ownWidth > widthLimit) {
      // Move to center
      setOffsetX(containerCenter - centerX)
    } else if (actualLeft < leftLimit) {
      // Move to right
      setOffsetX(leftLimit - actualLeft)
    } else if (actualRight > rightLimit) {
      // Move to left
      setOffsetX(rightLimit - actualRight)
    } else {
      // You're perfect!
      setOffsetX(0)
    }
  }, [
    ref,
    containerCenter,
    containerWidth,
    ownWidth,
    centerX,
    containerRect.left,
    containerRect.right,
    parentElement,
  ])

  return (
    <>
      <ViewportAwareHotspotTextInner $placement={position} ref={handleRef} $hotspotOffsetX={offsetX}>
        {children}
      </ViewportAwareHotspotTextInner>
      <HotspotArrow $placement={position} />
    </>
  )
}

export const SimpleHotspot = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${palette.primitives.white};
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
`
