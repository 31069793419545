import React from 'react'
import { CertificatesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  certificatesColumn,
  numbersColumn,
  stringsColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { StaticLoaderSearchKeyBy, staticDataLoader } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

type CertificateData = CertificatesQuery['certificates'][number]

// -- columns --

type CertificateTableDefinition = TableDefinitionOf<
  CertificateData,
  [
    { type: 'certificates'; ref: 'certificate' },
    { type: 'numbers'; ref: 'active' },
    { type: 'numbers'; ref: 'expired' },
    { type: 'strings'; ref: 'createdBy' },
  ]
>

type CertificateTableData = TableDataFromDefinition<CertificateData, CertificateTableDefinition>

export const certificatesToTableDefinition = (t: TranslationLookup): CertificateTableDefinition => ({
  nested: {},
  rows: {
    getId: c => c.id,
  },
  columns: [
    certificatesColumn({
      ref: 'certificate',
      header: translatedLabel('dictionary.name'),
      sortable: true, // note: local sort
      getData: c => c,
    }),
    numbersColumn({
      ref: 'active',
      header: translatedLabel('manage.certificates.active-certificates'),
      sortable: true, // note: local sort
      getData: c => c.analytics.active,
    }),
    numbersColumn({
      ref: 'expired',
      header: translatedLabel('manage.certificates.expired-certificates'),
      sortable: true, // note: local sort
      getData: c => c.analytics.expired,
    }),
    stringsColumn({
      ref: 'createdBy',
      header: translatedLabel('manage.certificates.created-by'),
      sortable: true, // note: local sort
      getData: c => c.creator?.displayName ?? `[${t('dictionary.unknown')}]`,
    }),
  ],
})

// -- actions --

export type ManageCertificatesTabularActions = {
  onEditCertificate: (id?: RowRef) => void
  onDeleteCertificate: (id: RowRef, api: TableAPI<CertificateTableData>) => void
}

const virtualColumns = ({
  actions,
  hasWriteAccess,
  t,
}: {
  actions: ManageCertificatesTabularActions
  hasWriteAccess: boolean
  t: TranslationLookup
}): VirtualColumns<CertificateTableData> => ({
  left: [],
  right: [
    defaultMenuActionVirtualColumn<CertificateTableData>({
      getProps: ({ api, pos }) => {
        return {
          menuItems: (hasWriteAccess
            ? [
                {
                  id: pos.row + '-label',
                  type: 'label',
                  label: t('dictionary.edit'),
                  onClick: () => actions.onEditCertificate(pos.row),
                },
                {
                  id: pos.row + '-label2',
                  type: 'label',
                  label: t('manage.certificates.actions.delete'),
                  color: 'destructive/background',
                  onClick: () => actions.onDeleteCertificate(pos.row, api),
                },
              ]
            : []) satisfies Array<MenuItem>,
        }
      },
    }),
  ],
})

const searchKey: StaticLoaderSearchKeyBy<CertificateTableData> = (tableData, row) =>
  tableData.rows[row]?.data.certificate?.data?.title ?? ''

const queryKey = ['manage-certificates-tabular']
export const useManageCertificateTableAPI = (
  actions: ManageCertificatesTabularActions,
  certificates: CertificatesQuery['certificates'] | undefined
): UseTableAPI<CertificateTableData, any> => {
  const { t } = useTranslation()

  const orgPermissions = useOrganizationPermissions()
  const hasWriteAccess = orgPermissions.has('ACCESS_ALL_CERTIFICATES')

  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({
        loader: staticDataLoader<CertificateTableData>(
          definition2Data(certificatesToTableDefinition(t), certificates ?? []),
          searchKey
        ),
        options: { queryKey },
      }),
      [certificates, t]
    ),
    virtualColumns: React.useMemo(
      () => virtualColumns({ actions, hasWriteAccess, t }),
      [actions, hasWriteAccess, t]
    ),
    options: {
      queryStateKeyPrefix: TabularQsKey.SOLO,
    },
  })
}

const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.certificates' }

export const ManageCertificatesTabular: React.FC<{
  actions: ManageCertificatesTabularActions
  tableAPI: UseTableAPI<CertificateTableData, any>
  /** TODO: Remove this prop once we've either:
   * 1. Fixed the static dataloader to handle nullable data
   * 2. Implemented a remote dataloader for this table
   *
   * Until then we'll use this prop so that we don't show "0 items" on the initial render.
   */
  hideCounts: boolean
}> = React.memo(({ actions, tableAPI, hideCounts }) => {
  const { t } = useTranslation()
  const orgPermissions = useOrganizationPermissions()
  const canCreateCertificate = orgPermissions.has('CREATE_CERTIFICATES')

  const memoizedCallbacks = React.useMemo<TableCallbacks<CertificateTableData>>(
    () => ({ onRow: row => getGlobalRouter().navigate({ to: `/manage/certificates/${row.ref}` }) }),
    []
  )

  return (
    <>
      <PageTitle title={t('dictionary.certificate-plural')} />

      <ColumnLayout>
        <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
          <PageHeader
            title='dictionary.certificate-plural'
            withoutMargin
            helpLink='https://help.sana.ai/en/articles/79376-certificates'
          />

          <ManageHeader
            api={tableAPI.api}
            search={searchBar}
            actionElement={
              canCreateCertificate && (
                <Button variant='secondary' onClick={() => actions.onEditCertificate(undefined)}>
                  {t('manage.certificates.new-certificate')}
                </Button>
              )
            }
          />

          <TabularToolbar
            countsTranslationKeys={
              hideCounts
                ? undefined
                : {
                    totalKey: 'manage.certificates.n-certificates',
                    filterKey: 'manage.certificates.n-filtered',
                    selectedKey: 'manage.certificates.n-selected',
                  }
            }
            api={tableAPI.api}
            clearFilters={false}
          />
          <BasicTabular />
        </TabularProviderFromTableAPI>
      </ColumnLayout>
    </>
  )
})
