import { useMemo } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import {
  GetSkillsQuery,
  SkillAttribute,
  SkillSortAttributeInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useIsSkillAutoAssignAllowed } from 'sierra-client/features/skills/components/recommendation/gql'
import { AIThemedPill, SkillTitle } from 'sierra-client/features/skills/components/tabular/atoms'
import {
  SkillsTableData,
  SkillsTableDefinition,
} from 'sierra-client/features/skills/components/tabular/types'
import { getSkillsQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { canvasColumn, numbersColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { DataLoaderStateMachine, createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { capitalizedLabel, translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import { definition2Data } from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { isDefined, isNonEmptyArray, isNullable } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { InternalTruncatedText, LoaderAnimation, View } from 'sierra-ui/primitives'
import { QUERY_KEY } from '../actions/skills'

const SkillTitleDescription: React.FC<{
  title: string
  subtitle: string
  recommendedContent: GetSkillsQuery['skills']['data'][number]['recommendedContent']
}> = ({ title, subtitle, recommendedContent }) => {
  const { t } = useTranslation()

  const isSkillAutoAssignAllowed = useIsSkillAutoAssignAllowed()
  const suggestions = recommendedContent.length
  const showSuggestionNotifications = isSkillAutoAssignAllowed.allowed === true && suggestions > 0

  return (
    <View alignItems='flex-start' gap='4'>
      <View direction='column' gap='2'>
        <View justifyContent='flex-start' gap='12'>
          <SkillTitle>{title}</SkillTitle>

          {showSuggestionNotifications && (
            <AIThemedPill>
              <LoaderAnimation color='currentColor' size={12} disableAnimation />
              {t('dictionary.n-suggestions', { count: suggestions })}
            </AIThemedPill>
          )}
        </View>

        <InternalTruncatedText lines={3} size='small' color='foreground/secondary'>
          {subtitle}
        </InternalTruncatedText>
      </View>
    </View>
  )
}

const skillsTableDefinition = (): SkillsTableDefinition => ({
  nested: {},
  rows: {
    getId: r => r.id,
  },
  columns: [
    canvasColumn({
      ref: 'NAME',
      header: capitalizedLabel(translatedLabel('dictionary.skills')),
      sortable: true,
      getData: r => ({
        view: (
          <SkillTitleDescription
            title={r.name}
            subtitle={r.description}
            recommendedContent={r.recommendedContent}
          />
        ),
        meta: { sorts: () => r.name, exports: () => r.name },
      }),
    }),
    stringsColumn({
      ref: 'related-content',
      header: capitalizedLabel(translatedLabel('skills.table.content-items.text')),
      sortable: false,
      getData: r =>
        r.assignedContentsCount.autoAssociationInProgress
          ? // TODO: make this text cooler
            '✨ Analyzing…'
          : `${r.assignedContentsCount.count}`,
    }),
    numbersColumn({
      ref: 'SUBSCRIBERS',
      header: capitalizedLabel(translatedLabel('dictionary.learners')),
      sortable: true,
      getData: r => r.uniqueSubscribersCount,
    }),
  ],
})

export const skillsDataLoader = (
  skillsQuery: typeof getSkillsQuery
): DataLoaderStateMachine<SkillsTableData, { cursor: string | undefined | null }> => {
  return createDataLoader({
    async fetchInit({ modifier, control, predicate }) {
      const sortBy = modifier.sorting?.map(
        sort =>
          ({
            // todo: fix cast
            key: sort.column as SkillAttribute,
            order: toGQLSortOrder(sort.direction),
          }) satisfies SkillSortAttributeInput
      )

      const r = await graphQuery(skillsQuery, {
        limit: control.limit,
        query: predicate.query,
        sortBy: isDefined(sortBy) && isNonEmptyArray(sortBy) ? sortBy : undefined,
      })

      return {
        meta: { cursor: r.skills.cursor },
        data: r.skills.data,
        totalCount: r.skills.totalCount,
        done: isNullable(r.skills.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const r = await graphQuery(skillsQuery, {
        limit: control.limit,
        cursor: meta.cursor,
      })

      return {
        meta: { cursor: r.skills.cursor },
        data: r.skills.data,
        totalCount: r.skills.totalCount,
        done: isNullable(r.skills.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(skillsTableDefinition(), data)]
    },
  })
}

const virtualColumns = (actions: {
  delete: (t: TableAPI<SkillsTableData>) => (ref: string) => void
  edit: (t: TableAPI<SkillsTableData>) => (ref: string) => void
  t: TranslationLookup
}): VirtualColumns<SkillsTableData> => ({
  left: [],
  right: [
    defaultMenuActionVirtualColumn({
      getProps: ({ api, pos }) => ({
        iconId: 'overflow-menu--vertical',
        menuItems: [
          {
            id: pos.row + 'label',
            type: 'label',
            label: actions.t('dictionary.edit'),
            onClick: () => actions.edit(api)(pos.row),
            selected: true,
            icon: 'edit',
          },
          {
            id: pos.row + 'label2',
            type: 'label',
            label: actions.t('dictionary.delete'),
            onClick: () => actions.delete(api)(pos.row),
            color: 'destructive/background',
            selected: true,
            icon: 'trash-can',
          },
        ] satisfies Array<MenuItem>,
      }),
    }),
  ],
})

export const useSkillsTableAPI = ({
  onEdit,
  onDelete,
}: {
  onEdit: (skillId: SkillId) => void
  onDelete: (skillId: SkillId) => void
}): UseTableAPI<SkillsTableData, any> => {
  const { t } = useTranslation()

  return useTableAPI({
    dataLoader: useMemo(
      () => ({ loader: skillsDataLoader(getSkillsQuery), options: { queryKey: QUERY_KEY } }),
      []
    ),
    virtualColumns: useMemo(
      () =>
        virtualColumns({
          delete: _api => ref => {
            onDelete(SkillId.parse(ref))
          },
          edit: _api => ref => {
            onEdit(SkillId.parse(ref))
          },
          t,
        }),
      [t, onDelete, onEdit]
    ),
    options: { limit: 7, queryStateKeyPrefix: TabularQsKey.SOLO },
  })
}
