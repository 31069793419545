import { LayoutGroup } from 'framer-motion'
import { atom, useAtom } from 'jotai'
import { useMemo } from 'react'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { getFlag } from 'sierra-client/config/global-config'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import { temporalUnits } from 'sierra-client/features/insights/constants'
import { getRoleTranslationKey } from 'sierra-client/features/insights/utils'
import { WidgetDataSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-data-selector'
import { QueryHistory } from 'sierra-client/features/insights/widget-builder/query-history'
import { PartialDashboardWidget } from 'sierra-client/features/insights/widget-builder/types'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Filter, isEmptyFilter } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import { FilterRoot } from 'sierra-client/lib/filter/components/root'
import { Dashboard, DashboardWidget, FilterDomain, areMeasureRefsEqual } from 'sierra-domain/api/insights'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, MenuButton, MenuItem, Tooltip } from 'sierra-ui/components'
import { Button, Switch, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'
import { VisualisationTypeSelector } from './data-selectors'

const WidgetSettingsHeadline = styled(Text).attrs({ size: 'medium', bold: true })`
  margin-bottom: 1rem;
`

const Container = styled(View).attrs({
  grow: true,
  direction: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
})`
  height: 100%;
`

const FullWidthHorizontalLineDivider = styled(HorizontalLineDivider)`
  width: 100%;
  height: 1px;
  margin: ${spacing[8]} 0;
`
export const isDashboardFiltersEnabledAtom = atom<boolean>(true)

export type WidgetBuilderAction =
  | { type: 'open-new' }
  | { type: 'open-edit'; dashboardWidget: DashboardWidget }
  | { type: 'open-duplicate'; dashboardWidget: PartialDashboardWidget }
  | { type: 'open-template-from-home'; dashboardWidget: PartialDashboardWidget }
  | { type: 'open-template-from-dashboard'; dashboardWidget: PartialDashboardWidget }
  | { type: 'open-template-from-dashboard-viewer'; dashboardWidget: PartialDashboardWidget }
  | { type: 'open-generate-from-home'; query: string }
  | { type: 'open-generate-from-dashboard'; query: string }
  | { type: 'open-generate-from-dashboard-viewer'; query: string }
  | { type: 'open-viewer'; dashboardWidget: DashboardWidget }

const isViewerAction = (action: WidgetBuilderAction): boolean => {
  return (
    action.type === 'open-generate-from-dashboard-viewer' ||
    action.type === 'open-viewer' ||
    action.type === 'open-template-from-dashboard-viewer'
  )
}

export const WidgetBuilderSettings: React.FC<{
  action: WidgetBuilderAction
  widgetBuilderState: WidgetBuilderState
  onChange: (widget: WidgetBuilderState) => void
  onSubmit: () => void
  onSubmitSaveAsNew: (dashboardId?: Dashboard['id']) => void
  isSubmitting: boolean
  submitDisabled: { tooltip: string; disabled: true } | { tooltip?: never; disabled: false }
  errorText?: string
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
  dashboardFilter?: Filter
  filterDomains?: FilterDomain[]
}> = ({
  action,
  widgetBuilderState,
  onChange,
  onSubmit,
  onSubmitSaveAsNew,
  isSubmitting,
  submitDisabled,
  errorText,
  undo,
  redo,
  canUndo,
  canRedo,
  dashboardFilter,
  filterDomains,
}) => {
  const { t } = useTranslation()

  const widgetDimensions = widgetBuilderState.selections.dimensions

  const { data } = useInsightsViews()
  const dashboardsList = useListDashboards()

  const editorDashboards = dashboardsList.data?.dashboards.filter(dashboard => dashboard.role !== 'viewer')

  const editorDashboardsMenuItems = (
    editorDashboards !== undefined
      ? editorDashboards.map(dashboard => ({
          id: dashboard.dashboard.id,
          type: 'label',
          label: dashboard.dashboard.title,
          description: t(getRoleTranslationKey(dashboard.role)),
        }))
      : []
  ) satisfies MenuItem[]

  const handleSelectType = (visualisation: WidgetBuilderState['visualisation']): void => {
    if (visualisation.type === 'line-chart') {
      const [firstDimension, ...rest] = widgetDimensions

      // Filter all non-enabled dimensions for line-chart
      const filteredDimensions =
        firstDimension !== undefined && temporalUnits.includes(firstDimension.unit as string)
          ? widgetDimensions
          : rest

      onChange({
        ...widgetBuilderState,
        visualisation,
        selections: {
          ...widgetBuilderState.selections,
          dimensions: filteredDimensions,
        },
      })
    } else if (visualisation.type === 'progress-bar') {
      // Filter out first measures that are not supported by progress bar
      const allMeasures = data?.views.flatMap(view => view.measures) ?? []
      const allProgressBarMeasures = allMeasures.filter(measure => measure.type.type === 'type.progress')
      const firstSupportedIndex = widgetBuilderState.selections.measures.findIndex(measure => {
        const isMeasureSupported = allProgressBarMeasures.some(availableMeasure => {
          return areMeasureRefsEqual(measure, availableMeasure.ref)
        })
        return isMeasureSupported
      })

      const measures =
        firstSupportedIndex === -1
          ? []
          : widgetBuilderState.selections.measures.slice(
              firstSupportedIndex,
              widgetBuilderState.selections.measures.length
            )

      onChange({
        ...widgetBuilderState,
        visualisation,
        selections: { ...widgetBuilderState.selections, measures },
      })
    } else {
      onChange({ ...widgetBuilderState, visualisation })
    }
  }

  const ctx = useMemo<Context>(
    () => ({ domainReps: filterDomains ?? [], update: () => {}, remove: () => {} }),
    [filterDomains]
  )

  const isDashboardFiltersInWidgetEnabled = getFlag('dashboard-filters-in-widget')

  const [isDashboardFiltersEnabled, setIsDashboardFiltersEnabled] = useAtom(isDashboardFiltersEnabledAtom)

  return (
    <LayoutGroup>
      <Container gap='24'>
        <View direction='column' gap='24'>
          <View direction='column'>
            <WidgetSettingsHeadline>{t('manage.insights.widgetbuilder.options')}</WidgetSettingsHeadline>
            <VisualisationTypeSelector
              selectedType={widgetBuilderState.visualisation.type}
              onChange={handleSelectType}
            />
          </View>
          <View direction='column' gap='24'>
            <WidgetDataSelector widgetBuilderState={widgetBuilderState} onChange={onChange} />
          </View>
        </View>
        {isDashboardFiltersInWidgetEnabled &&
          isDefined(dashboardFilter) &&
          !isEmptyFilter(dashboardFilter) && (
            <>
              <FullWidthHorizontalLineDivider />
              <View direction='column'>
                <View justifyContent='space-between'>
                  <Text bold color='foreground/primary'>
                    {t('manage.insights.widgetbuilder.dashboard-filter')}
                  </Text>
                  <Switch
                    ariaLabel={t('manage.insights.widgetbuilder.dashboard-filter')}
                    checked={isDashboardFiltersEnabled}
                    onChange={value => setIsDashboardFiltersEnabled(value)}
                  />
                </View>
                <FilterRoot ctx={ctx} filter={dashboardFilter} assetContext={{ type: 'unknown' }} readOnly />
              </View>
            </>
          )}
        <View padding='none small'>
          {errorText !== undefined && <Text color='destructive/background'>{errorText}</Text>}
        </View>
        <View grow alignItems='flex-end' justifyContent='space-between'>
          <View grow>
            <View>
              <QueryHistory undo={undo} canUndo={canUndo} redo={redo} canRedo={canRedo} />
            </View>

            <View direction='column' alignItems='flex-end' grow>
              <ConditionalWrapper
                condition={submitDisabled.disabled}
                renderWrapper={children => <Tooltip title={submitDisabled.tooltip}>{children}</Tooltip>}
              >
                {action.type === 'open-edit' ? (
                  <MenuButton
                    variant='primary'
                    onPrimaryClick={onSubmit}
                    withSearch
                    menuItems={[
                      {
                        id: 'save-as-new',
                        type: 'label',
                        label: t('manage.insights.widgetbuilder.save-as-new'),
                      },
                    ]}
                    menuLabel='More options'
                    onSelect={menuItem => {
                      switch (menuItem.id) {
                        case 'save-as-new': {
                          return onSubmitSaveAsNew()
                        }
                        default:
                          assertNever(menuItem.id)
                      }
                    }}
                    disabled={submitDisabled.disabled}
                  >
                    {t('manage.insights.widgetbuilder.save')}
                  </MenuButton>
                ) : action.type === 'open-generate-from-home' ||
                  action.type === 'open-template-from-home' ||
                  isViewerAction(action) ? (
                  <MenuButton
                    disabled={editorDashboards === undefined}
                    menuItems={editorDashboardsMenuItems}
                    withSearch
                    searchPlaceholder={t('dictionary.search')}
                    onSelect={item => {
                      onSubmitSaveAsNew(item.id)
                    }}
                    menuHeight={500}
                  >
                    {t('manage.insights.widgetbuilder.save')}
                  </MenuButton>
                ) : (
                  <Button onClick={onSubmit} loading={isSubmitting} disabled={submitDisabled.disabled}>
                    {t('manage.insights.widgetbuilder.save')}
                  </Button>
                )}
              </ConditionalWrapper>
              {isViewerAction(action) && (
                <View>
                  <Icon iconId='view' color='foreground/muted' />
                  <Text color='foreground/muted'>{t('manage.insights.widgetbuilder.view-description')}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </Container>
    </LayoutGroup>
  )
}
