import { useAtomValue } from 'jotai'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { mapApiClientToCsv } from 'sierra-client/views/manage/api/use-manage-client-api'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { ApiClientWithStatus } from 'sierra-domain/api/public-api'
import { Button, Spacer, View } from 'sierra-ui/primitives'

type ApiClientsTableDefinition = TableDefinitionOf<
  ApiClientWithStatus,
  [
    { type: 'strings'; ref: 'name' },
    { type: 'strings'; ref: 'description' },
    { type: 'strings'; ref: 'id' },
    { type: 'strings'; ref: 'status' },
  ]
>

type ApiClientsTableData = TableDataFromDefinition<ApiClientWithStatus, ApiClientsTableDefinition>
type ApiClientsTableMeta = StaticDataLoaderMeta<ApiClientsTableData>

const searchKey: StaticLoaderSearchKeyBy<ApiClientsTableData> = (tableData, row) => {
  const rawData = tableData.rows[row]?.rawData
  return rawData ? [rawData.name, rawData.description].join(' ') : ''
}

const tableDefinition = (t: TranslationLookup): ApiClientsTableDefinition => {
  return {
    columns: [
      stringsColumn({
        sortable: true,
        getData: r => r.name,
        header: translatedLabel('table.name'),
        ref: 'name',
      }),
      stringsColumn({
        getData: r => r.description,
        header: translatedLabel('dictionary.description'),
        ref: 'description',
      }),
      stringsColumn({
        getData: r => r.clientId,
        header: translatedLabel('manage.api.client-id'),
        ref: 'id',
      }),
      stringsColumn({
        getData: r => (r.status === 'enabled' ? t('manage.api.enabled') : t('manage.api.disabled')),
        header: translatedLabel('table.status'),
        ref: 'status',
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.clientId,
    },
  }
}

const useApiClientsTable = ({
  data,
}: {
  data: ApiClientWithStatus[]
}): UseTableAPI<ApiClientsTableData, ApiClientsTableMeta> => {
  const { t } = useTranslation()
  const loader = staticDataLoader(definition2Data(tableDefinition(t), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [],
      right: [],
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.FIRST,
    },
  })
}

const SearchSection: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const query = useAtomValue(api.atoms.query)

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => api.action.setQuery({ query })}
        placeholder={t('dictionary.search')}
      />
    </View>
  )
}

const Footer: React.FC<
  { fetchCsv: () => Promise<Record<string, string>[]> } & Pick<ApiClientsTableProps, 'createApiClient'>
> = ({ fetchCsv, createApiClient }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='space-between'>
      <Button onClick={createApiClient}>{t('manage.api.create-new')}</Button>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.organization.tab.api')} />
    </View>
  )
}
export type ApiClientsTableProps = {
  data: ApiClientWithStatus[]
  createApiClient: () => void
}

export const ApiClientsTable: React.FC<ApiClientsTableProps> = ({ data, createApiClient }) => {
  const tableAPI = useApiClientsTable({ data })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/settings/api/${rawData.clientId}` }) //TODO
        },
      }}
    >
      <SearchSection />
      <Spacer size='xsmall' />
      <UserDetailsTabularTable />
      <Footer
        fetchCsv={() => Promise.resolve(data.map(mapApiClientToCsv))}
        createApiClient={createApiClient}
      />
    </TabularProviderFromTableAPI>
  )
}
