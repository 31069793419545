import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LSFilter } from 'sierra-client/views/manage/courses/utils/live-session-utils'
import { GroupRow } from 'sierra-domain/api/manage'
import { BaseGroupInfo } from 'sierra-domain/base-group-info'
import { MenuItem } from 'sierra-ui/components'
import { MultiSelectDropdown, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const MinWidth = styled.div`
  min-width: 160px;
`

type LiveSessionUserGroupFilterProps = {
  title: string
  groupsData: GroupRow[]
  selectedGroupIds: string[]
  setGroupIds: (groupIds: string[]) => void
}

export const LiveSessionUserGroupFilter: React.FC<LiveSessionUserGroupFilterProps> = ({
  title,
  groupsData,
  selectedGroupIds,
  setGroupIds,
}) => {
  const { groupOptions, selectedGroups } = useMemo<{
    selectedGroups: BaseGroupInfo[]
    groupOptions: BaseGroupInfo[]
  }>(() => {
    const groupOptions = groupsData.map(g => g.groupInfo)
    const selectedGroups = groupOptions.filter(g => selectedGroupIds.includes(g.groupId))
    return { groupOptions, selectedGroups }
  }, [selectedGroupIds, groupsData])

  return (
    <MinWidth>
      <MultiSelectDropdown
        placeholder={title}
        withSearch
        menuItems={groupOptions.map(group => ({
          id: group.groupId,
          label: group.groupName ?? 'Group',
          type: 'label',
          selected: selectedGroupIds.includes(group.groupId),
        }))}
        selectedItems={selectedGroups.map(group => ({
          id: group.groupId,
          label: group.groupName ?? 'Group',
          type: 'label',
        }))}
        onSelect={item => setGroupIds([...selectedGroupIds, item.id])}
        onUnselect={item => setGroupIds(selectedGroupIds.filter(id => id !== item.id))}
      />
    </MinWidth>
  )
}

const IGNORE_FILTER = 'all' as const
export const LiveSessionAssignedFilter: React.FC<{
  value: LSFilter['assignedToLiveSession']
  onChange: (value: LSFilter['assignedToLiveSession']) => void
}> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const items: MenuItem<Exclude<LSFilter['assignedToLiveSession'], undefined> | typeof IGNORE_FILTER>[] = [
    {
      type: 'label',
      id: 'all',
      label: t('manage.live-session.filter.any-assignment'),
    },
    {
      type: 'label',
      id: 'assigned',
      label: t('manage.live-session.filter.assigned'),
    },
    {
      type: 'label',
      id: 'not-assigned',
      label: t('manage.live-session.filter.not-assigned'),
    },
  ]
  const selectedItem = items.find(item => item.id === value) ?? items.find(item => item.id === IGNORE_FILTER)

  return (
    <MinWidth>
      <SingleSelectDropdown
        selectedItem={selectedItem}
        onSelect={item => {
          if (item.id === IGNORE_FILTER) {
            onChange(undefined)
            return
          } else {
            onChange(item.id)
          }
        }}
        menuItems={items}
      />
    </MinWidth>
  )
}
