import React, { useCallback, useMemo, useState } from 'react'
import { downloadBlob } from 'sierra-client/api'
import { useSelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { DeleteBox } from 'sierra-client/components/common/delete-box'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import {
  exportTabCourseDuplicated,
  exportTabExportCourseAsPDF,
  exportTabExportCourseAsSCORM,
} from 'sierra-client/core/logging/authoring/logger'
import { useDeleteCourse } from 'sierra-client/hooks/use-delete-course'
import { usePost } from 'sierra-client/hooks/use-post'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { ScormUpdateDialog } from 'sierra-client/views/manage/scorm/scorm-update-dialog'
import { openPdfViewer } from 'sierra-client/views/v3-author/export-pdf/export-flexible-content'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeAdminCoursesDeleteCourseGroup } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Button as SanaButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Button = styled(SanaButton)`
  width: fit-content;
`

const DuplicateCourse: React.FC<{ courseId: CourseId; courseKind: CourseKind }> = ({ courseId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const duplicate = async (): Promise<void> => {
    setLoading(true)
    await dispatch(settingsActions.duplicateFlexibleContent({ originalFlexibleContentId: courseId }))
    await dispatch(exportTabCourseDuplicated({ courseId }))
    setLoading(false)
  }
  return (
    <Button variant='secondary' loading={loading} onClick={duplicate}>
      {t('dictionary.duplicate')}
    </Button>
  )
}

const downloadScormPackage = async ({
  courseId,
  version,
  courseTitle,
}: {
  courseId: CourseId
  version: '1_2' | '2004'
  courseTitle: string | undefined
}): Promise<void> => {
  const url = `/x-realtime/scorm/generate-secure/${courseId}?version=${version}`

  const response = await fetch(url)

  if (!response.ok) {
    const text = await response.text()
    throw new RequestError('GET', url, {}, {}, response.status, text)
  }

  const blob = new Blob([await response.blob()])

  const filename = `${courseTitle ?? courseId}_${version}.zip`

  downloadBlob(blob, filename)
}

const ExportAsScorm: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const courseKind = useSelector(settingsState.selectors.selectCourseKind)
  const originalSettings = useSelector(settingsState.selectors.selectOriginalSettings)
  const selfPacedPublishState = useSelfPacedPublishState(
    courseKind === 'native:self-paced' ? (courseId as SelfPacedContentId) : undefined
  )

  const courseTitle = originalSettings !== 'loading' ? originalSettings.title : undefined

  // Publish state is not relevant for course groups
  const isCourseGroup = courseKind === 'native:course-group'
  const disabled = !isCourseGroup && selfPacedPublishState.state?.published !== true

  const items = useMemo(
    () =>
      [
        {
          type: 'label',
          id: 'scorm_12' as const,
          label: 'SCORM 1.2',
        },
        {
          type: 'label',
          id: 'scorm_2004' as const,
          label: 'SCORM 2004',
        },
      ] satisfies MenuItem[],
    []
  )

  return (
    <View direction={'column'} alignItems='flex-end'>
      <MenuButton
        variant='secondary'
        grow
        data-testid='export-scorm-button'
        menuItems={items}
        disabled={disabled}
        onSelect={item => {
          switch (item.id) {
            case 'scorm_12': {
              void downloadScormPackage({ courseId, version: '1_2', courseTitle })
              void dispatch(exportTabExportCourseAsSCORM({ courseId, version: '1_2' }))
              break
            }

            case 'scorm_2004': {
              void downloadScormPackage({ courseId, version: '2004', courseTitle })
              void dispatch(exportTabExportCourseAsSCORM({ courseId, version: '2004' }))
              break
            }

            default:
              assertNever(item.id)
          }
        }}
      >
        {t('dictionary.export')}
      </MenuButton>

      {disabled ? (
        <Text size='micro' color='foreground/muted'>
          {t('content.settings-scorm-require-publish')}
        </Text>
      ) : null}
    </View>
  )
}

const ExportAsPdfShortcut: React.FC<{ courseId: CourseId; contentType: ContentType }> = ({
  courseId,
  contentType,
}) => {
  const dispatch = useDispatch()
  return (
    <ShortcutMenu.Action
      label='content.settings-export-as-pdf'
      iconId='export'
      group='create'
      permission='ACCESS_EDITOR'
      run={() => {
        void dispatch(exportTabExportCourseAsPDF({ courseId }))
        openPdfViewer(contentType, courseId)
      }}
    />
  )
}

const ExportAsPdf: React.FC<{ courseId: CourseId; contentType: ContentType }> = ({
  courseId,
  contentType,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <Button
      variant='secondary'
      data-testId='export-pdf'
      onClick={() => {
        openPdfViewer(contentType, courseId)
        void dispatch(exportTabExportCourseAsPDF({ courseId }))
      }}
    >
      {t('dictionary.export')}
    </Button>
  )
}

const UpdateScormPackage: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const [showUpdate, { on, off }] = useToggle(false)

  return (
    <>
      <Button variant='secondary' onClick={on}>
        {t('course-settings.update-scorm-package')}
      </Button>
      <ScormUpdateDialog isOpen={showUpdate} courseId={courseId} onClose={off} onAfterUpdate={off} />
    </>
  )
}

const DeleteCourse: React.FC<{ courseId: CourseId; onDeleted: () => void }> = ({ courseId, onDeleted }) => {
  const { t } = useTranslation()

  const { remove } = useDeleteCourse({
    courseId,
    onDeleted,
  })

  return (
    <DeleteBox
      confirm
      title={t('course-settings.delete-course')}
      description={t('course-settings.delete-course-body')}
      deleteLabel={t('dictionary.delete')}
      onDelete={remove}
    />
  )
}

const DetachAllEditionsFromCourseGroup: React.FC<{ courseGroupId: CourseId }> = ({ courseGroupId }) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()

  const deleteCourseGroup = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAdminCoursesDeleteCourseGroup, {
      courseGroupId,
    })

    void getGlobalRouter().navigate({ to: '/manage/content', replace: true })
  }, [postWithUserErrorException, courseGroupId])

  return (
    <>
      <DeleteBox
        confirm
        title={t('course-editions.create.delete.title')}
        description={t('manage.course-groups.delete-group.confirm-body')}
        deleteLabel={t('dictionary.delete')}
        onDelete={deleteCourseGroup}
      />
    </>
  )
}

export const CourseSettingsButton = {
  DeleteCourse,
  UpdateScormPackage,
  DetachAllEditionsFromCourseGroup,
  DuplicateCourse,
  ExportAsScorm,
  ExportAsPdf,
  ExportAsPdfShortcut,
}
