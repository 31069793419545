import React, { useState } from 'react'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { queryClient } from 'sierra-client/api/query-client'
import { SelectSkillsModal } from 'sierra-client/features/skills'
import { usePost } from 'sierra-client/hooks/use-post'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserSetRegistrationStep } from 'sierra-domain/routes'
import { Text, View } from 'sierra-ui/primitives'

export const SelectNewSkills: React.FC = () => {
  const { postWithUserErrorCode } = usePost()
  const [error, setError] = useState<string | undefined>(undefined)

  const onDone = async (): Promise<void> => {
    setError(undefined)

    const res = await postWithUserErrorCode(XRealtimeUserSetRegistrationStep, {
      step: 'done',
    })

    if (isLeft(res)) {
      setError(getUserErrorTranslationKey(res.left))
    } else {
      await queryClient.invalidateQueries({ queryKey: currentUserQuery.queryKey, exact: true })
    }
  }

  return (
    <View>
      {error !== undefined ? (
        <Text size='micro' color='redBright'>
          {error}
        </Text>
      ) : (
        <SelectSkillsModal variant='onboarding' onComplete={onDone} onCancel={onDone} open={true} />
      )}
    </View>
  )
}
