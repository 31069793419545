import { queryOptions } from '@tanstack/react-query'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { queryClient } from 'sierra-client/api/query-client'
import { AuthClient, AuthClientQueryResult } from 'sierra-client/auth/auth-client'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createCheckTokenAuthStatusQuery() {
  // For token auth it's enough to just check if the user gets returned.
  return queryOptions({
    ...currentUserQuery,
    select: user => ({ status: 'ok', userId: user.uuid }) satisfies AuthClientQueryResult,
  })
}

export type CheckTokenAuthStatusQuery = ReturnType<typeof createCheckTokenAuthStatusQuery>

export class TokenAuthClient implements AuthClient {
  #token: string | undefined
  #checkAuthStatusQuery: CheckTokenAuthStatusQuery

  constructor(token: string | undefined) {
    this.#token = token
    this.#checkAuthStatusQuery = createCheckTokenAuthStatusQuery()
  }

  async synchronize(): Promise<void> {
    await queryClient.refetchQueries({ queryKey: this.#checkAuthStatusQuery.queryKey, exact: true })
  }

  signOut(): Promise<void> {
    return Promise.reject('Tried to sign out of token auth')
  }

  getToken(): string | undefined {
    return this.#token
  }

  get authQuery(): CheckTokenAuthStatusQuery {
    return this.#checkAuthStatusQuery
  }
}
