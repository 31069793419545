import { useMemo } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { DebugToast, useDebugToast } from 'sierra-client/layout/sana-page/components/debug-toast'

type ReportInDev = (message: string, options?: { variant?: DebugToast['variant'] }) => void

export function useDevelopmentSnackbar(): {
  reportInDev: ReportInDev
} {
  const isDev = process.env.NODE_ENV === 'development'
  const isDebug = useIsDebugMode()
  const shouldLog = isDev || isDebug

  const { addToast } = useDebugToast()

  const reportInDev: ReportInDev = useStableFunction((message, { variant = 'info' } = {}) => {
    if (shouldLog) {
      addToast({
        title: message,
        variant,
      })
    }
  })

  return useMemo(() => ({ reportInDev }), [reportInDev])
}
