import React from 'react'
import { ReadOnlyFilter } from 'sierra-client/lib/filter/components/read-only-filter'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { Popover } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type FilterPopoverProps = {
  filter: Filter
  domainReps: DomainRep[]
  renderTrigger: () => React.ReactNode
}

const Container = styled(View)`
  max-width: 310px;
`

export const FilterPopover: React.FC<FilterPopoverProps> = ({ filter, domainReps, renderTrigger }) => {
  return (
    <Popover renderTrigger={renderTrigger} align='start' openOnHover>
      <Container padding='12' direction='column'>
        <Text bold>Filters</Text>
        <View direction='row' wrap='wrap'>
          <ReadOnlyFilter domainReps={domainReps} filter={filter} />
        </View>
      </Container>
    </Popover>
  )
}
