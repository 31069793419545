import React from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ScenarioCardChatFeedback } from 'sierra-client/api/graphql/gql/graphql'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ScenarioChat } from 'sierra-client/views/v3-author/scenario/chat'
import { InitialChat } from 'sierra-client/views/v3-author/scenario/chat/hooks'
import { ScenarioChatState } from 'sierra-client/views/v3-author/scenario/chat/state'
import { assertScenarioCard } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { File } from 'sierra-domain/flexible-content/types'
import { assert, isDefined, isEmptyArray, toLowercase } from 'sierra-domain/utils'
import { LoaderAnimation, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledView = styled(View)`
  height: 100%;
`

const ChatContainer = styled(View).attrs({ alignItems: 'stretch' })`
  height: 100%;
  width: 100%;
`

export const myLatestScenarioCardChat = graphql(`
  query LatestScenarioCardChat($courseFileId: CourseFileIdInput!) {
    getMyLatestScenarioCardChat(id: $courseFileId) {
      chatId
      title
      description
      assistantName
      completedAt
      messages {
        id
        index
        sender
        content
        context
        sentAt
      }
      feedback {
        description
        positives {
          description
        }
        improvements {
          description
        }
      }
    }
  }
`)

export const ScenarioStarter: React.FC<{
  file: File
  courseId: CreateContentId
}> = ({ courseId, file }) => {
  assertScenarioCard(file)
  assert(file.data.input.character !== undefined)
  const { t } = useTranslation()

  const [hasReset, reset] = React.useState(false)

  const chatDefaults = useGraphQuery(
    {
      document: myLatestScenarioCardChat,
      queryOptions: {
        enabled: !hasReset,
        select: d => {
          if (hasReset) {
            return undefined
          }
          const chatData = null2Undefined(d.getMyLatestScenarioCardChat)
          if (!chatData) {
            return undefined
          }
          const feedback = (): ScenarioCardChatFeedback | undefined => {
            return null2Undefined(chatData.feedback)
          }
          const chat = (): InitialChat => {
            const messages = chatData.messages.map(m => ({
              content: m.content,
              context: isDefined(m.context)
                ? [
                    {
                      type: 'previous-context' as const,
                      value: m.context,
                    },
                  ]
                : [],
              sender: toLowercase(m.sender),
              timestamp: m.sentAt,
              index: m.index,
            }))
            const id = chatData.chatId
            return { chatId: id, messages }
          }
          const state = (): ScenarioChatState => {
            const messages = chatData.messages
            if (isEmptyArray(messages)) {
              return { type: 'init', showStartButton: true }
            }

            if (isDefined(chatData.completedAt)) {
              return { type: 'completed', reason: 'goal-reached-termination' }
            }

            const lastMessage = messages.at(-1)
            if (lastMessage?.sender === 'ASSISTANT') {
              return { type: 'waiting-for-user-message' }
            }
            if (lastMessage?.sender === 'USER') {
              return { type: 'waiting-for-user-message' }
            }

            return { type: 'init', showStartButton: true }
          }
          return { feedback: feedback(), chat: chat(), state: state(), resetPreviousData: () => reset(true) }
        },
      },
    },
    { courseFileId: { courseId, fileId: ScopedFileId.extractId(file.id) } }
  )

  if (chatDefaults.isPending) {
    return (
      <StyledView alignItems='stretch' grow justifyContent='center'>
        <View grow direction='column' alignItems='center' justifyContent='center' position='relative'>
          <LoaderAnimation />
          <Text size='large'>{t('dictionary.loading')}</Text>
        </View>
      </StyledView>
    )
  }

  if (chatDefaults.isError) {
    return (
      <StyledView alignItems='stretch' grow justifyContent='center'>
        <View grow direction='column' alignItems='center' justifyContent='center' position='relative'>
          <Text size='large'>{t('scenario-card.chat.error.generic-fail')}...</Text>
        </View>
      </StyledView>
    )
  }

  return (
    <StyledView alignItems='stretch' grow justifyContent='center'>
      <View grow direction='column' alignItems='center' justifyContent='center' position='relative'>
        <ChatContainer
          key='chat'
          grow
          animated
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <ScenarioChat
            character={file.data.input.character}
            file={file}
            courseId={courseId}
            previous={chatDefaults.data}
          />
        </ChatContainer>
      </View>
    </StyledView>
  )
}
