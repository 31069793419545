import { queryOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { queryClient } from 'sierra-client/api/query-client'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { CookieAuthClient } from 'sierra-client/auth/clients/cookie-auth-client'
import { getFlag } from 'sierra-client/config/global-config'
import { typedPost } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { statusChanged } from 'sierra-client/state/user/actions'
import { isOwnerUser } from 'sierra-domain/access-level'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUserMe } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'

// TODO: remove once we no longer need to keep user state in Redux
// Keeps the user state in Redux synced with the user state in the React query cache.
queryClient.getQueryCache().subscribe(async event => {
  const isUserEvent = event.query.queryKey[0] === XRealtimeUserMe.path
  if (!isUserEvent) return

  switch (event.type) {
    case 'added':
    case 'updated': {
      const user = event.query.state.data
      await store.dispatch(statusChanged({ type: 'user', user: user }))
      break
    }
    case 'removed': {
      await store.dispatch(statusChanged({ type: 'loading', loaded: false }))
      break
    }
  }
})

export const currentUserQuery = queryOptions({
  queryKey: [XRealtimeUserMe.path],
  queryFn: () => {
    const authClient = getAuthClient()
    if (authClient instanceof CookieAuthClient) {
      // Don't try to fetch user if we haven't verified the session yet.
      // Session queries invalidate the user query, so this will be retried if the session is verified.
      const session = queryClient.getQueryData(authClient.authQuery.queryKey)
      if (session?.status !== 'ok') throw new Error('Session not verified')
    }

    return typedPost(XRealtimeUserMe, {})
  },
  staleTime: Infinity,
  gcTime: Infinity,
})

export const useCurrentUser = (): UseQueryResult<User | undefined> => {
  return useQuery(currentUserQuery)
}

export const useCurrentUserId = (): UseQueryResult<UserId, unknown> => {
  return useQuery({ ...currentUserQuery, select: user => user.uuid })
}

export const useHasUser = (): boolean => {
  const { data: auth } = useQuery(getAuthClient().authQuery)
  const { data } = useQuery({
    ...currentUserQuery,
    enabled: auth?.status === 'ok',
    select: user => Boolean(user),
  })
  return data ?? false
}

export const useIsOwnerUser = (): boolean => {
  const { data } = useQuery({ ...currentUserQuery, select: isOwnerUser })
  return data ?? false
}

export const selectIsUserImpersonated = (user: User): boolean => user.impersonatorId !== undefined
export const useIsImpersonated = (): boolean => {
  const { data } = useQuery({ ...currentUserQuery, select: selectIsUserImpersonated })
  return data ?? false
}

export const useIsGuestUser = (): UseQueryResult<boolean> => {
  return useQuery({ ...currentUserQuery, select: user => user.isGuestUser })
}

export const useIsRestrictedUser = (): boolean => {
  const { data: isGuestOrScorm, isLoading } = useQuery({
    ...currentUserQuery,
    select: user => user.isScormUser || user.isGuestUser,
  })

  return !isLoading && Boolean(isGuestOrScorm)
}

export const useUserLanguage = (): UseQueryResult<string | undefined> => {
  return useQuery({ ...currentUserQuery, select: user => user.language })
}

export const usePreferredTimezone = (): UseQueryResult<User['preferredTimezone']> => {
  const userTimezoneEnabled = getFlag('user-preferred-timezone')

  return useQuery({
    ...currentUserQuery,
    select: data => {
      // eslint-disable-next-line no-restricted-syntax
      return userTimezoneEnabled === true ? data.preferredTimezone : DateTime.local().zoneName
    },
  })
}
