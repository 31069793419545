import { FC } from 'react'
import { getAssetContextFromLearnEntity } from 'sierra-client/components/util/asset-contex'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { LearnEntity } from 'sierra-domain/api/entities'
import { HomeLiveSession } from 'sierra-domain/api/learn'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

type ImageSize = 'small' | 'medium' | 'large'

export const PlainEntityImage = styled.img.attrs({
  alt: '', // decorative image
})<{ size?: ImageSize; $muted?: boolean }>`
  width: auto;
  object-fit: cover;
  opacity: ${p => (p.$muted === true ? 0.2 : 1)};
  ${p =>
    iife(() => {
      switch (p.size) {
        case 'small':
          return css`
            height: 18px;
            width: 24px;
            border-radius: 6px;
          `
        case undefined:
        case 'medium':
          return css`
            height: 40px;
            width: 64px;
            border-radius: 10px;
          `
        case 'large':
          return css`
            height: 64px;
            width: 100px;
            border-radius: 12px;
          `
        default:
          assertNever(p.size)
      }
    })}
`

type BaseProps = {
  size?: ImageSize
  muted?: boolean
}
type FallbackTypes = 'event'
type Props = BaseProps &
  (
    | {
        entity: LearnEntity
      }
    | {
        fallback: FallbackTypes
      }
  )

function fallbackIcon(type: FallbackTypes): IconId {
  switch (type) {
    case 'event':
      return 'calendar'
    default:
      assertNever(type)
  }
}

const FallbackContainer = styled.div<{ $size?: ImageSize }>`
  background: ${token('surface/soft')};
  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    iife(() => {
      switch (p.$size) {
        case 'small':
          return css`
            height: 18px;
            width: 24px;
            border-radius: 6px;
          `
        case undefined:
        case 'medium':
          return css`
            height: 40px;
            width: 64px;
            border-radius: 10px;
          `
        case 'large':
          return css`
            height: 64px;
            width: 100px;
            border-radius: 12px;
          `
        default:
          assertNever(p.$size)
      }
    })}
`

const EntityImageWithResource: FC<{ entity: LearnEntity; size?: ImageSize; muted?: boolean }> = ({
  entity,
  size,
  muted,
}) => {
  const assetResolver = useAssetResolver({ size: 'default' })
  const assetContext = getAssetContextFromLearnEntity(entity)
  const imgSrc = assetResolver(entity.image, assetContext)

  return <PlainEntityImage src={imgSrc} size={size} $muted={muted} />
}

export const EntityImage: FC<Props> = props => {
  if ('fallback' in props) {
    return (
      <FallbackContainer $size={props.size}>
        <Icon iconId={fallbackIcon(props.fallback)} color='foreground/muted' />
      </FallbackContainer>
    )
  }

  return <EntityImageWithResource {...props} />
}

export const LiveSessionImage: FC<{
  liveSession: HomeLiveSession
  size?: ImageSize
  muted?: boolean
}> = ({ liveSession, size, muted }) => {
  const assetResolver = useAssetResolver({ size: 'default' })
  const imgSrc = assetResolver(liveSession.image, {
    type: 'course',
    courseId: liveSession.courseId,
  })

  return <PlainEntityImage src={imgSrc} size={size} $muted={muted} />
}
