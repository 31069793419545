import 'array-flat-polyfill'
import React from 'react'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { Layout } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const _RootLayout = styled(Layout)`
  background-color: ${token('surface/default')};
  transition: background-color 100ms ease;
`

export const RootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathname = usePathname()
  const isLiveSession = pathname.startsWith('/l/') || pathname.startsWith('/room/')

  return (
    <AppThemeTokenProvider followSystemColorScheme={isLiveSession}>
      <_RootLayout>{children}</_RootLayout>
    </AppThemeTokenProvider>
  )
}
