import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'sierra-client/components/common/link'
import { getFlag } from 'sierra-client/config/global-config'
import { EXERCISES_HELP_PAGE_URL } from 'sierra-client/config/links'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import {
  NotifyAdminSelector,
  ReviewerSelector,
  ReviewerTypeSelector,
  useClearHomeworkAdminsToNotifyMutation,
} from 'sierra-client/features/homework'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { Box, LightText } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { useHomeworkCardCreateContext } from 'sierra-client/views/v3-author/homework/homework-card-create-context'
import { useHomeworkReviewInstructionsCreateEditor } from 'sierra-client/views/v3-author/homework/homework-review-instructions-editor'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { SubmissionType } from 'sierra-domain/flexible-content/types'
import { assertNever, isDefined, isEmptyArray, isNonEmptyString } from 'sierra-domain/utils'
import { FormElement, Icon, MenuItem, TruncatedText } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Heading, IconButton, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.08);
  height: 1px;
  width: 100%;
`

export const HomeworkCardSettingsContainer = styled(Box).attrs({
  direction: 'column',
  margin: 'none 32',
})`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: ${p => p.theme.borderRadius['size-16']};
`

const ReviewInstructionsEditorContainer = styled(View).attrs({
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})``

const ReviewInstructions: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { file } = useFileContext()
  const { yDoc, awareness } = useCreatePageYDocContext()
  const { t } = useTranslation()
  const { extensions, onChange } = useHomeworkReviewInstructionsCreateEditor({
    editable: canEdit,
    fileId: file.id,
    yDoc,
    awareness,
  })

  return (
    <FormElement label={t('author.homework.settings.review-instructions')}>
      <ReviewInstructionsEditorContainer>
        {canEdit ? (
          <FreeTextEditor
            content={undefined}
            editable
            extraExtensions={extensions}
            onChange={onChange}
            placeholder={t('author.homework.settings.help-reviewers-evaluate-submissions')}
            ariaLabel={t('author.homework.settings.review-instructions')}
            menuTranslations={{
              list: t('dictionary.list'),
              alignment: t('create.toolbar.text-alignment'),
              text: t('dictionary.text'),
              heading: t('font.heading'),
            }}
          />
        ) : (
          <FreeTextEditor content={undefined} editable={false} />
        )}
      </ReviewInstructionsEditorContainer>
    </FormElement>
  )
}

const MutedIconButton = styled(IconButton)`
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const CriteriaDragHandleContainer = styled.div<{ $hide: boolean }>`
  position: absolute;
  right: calc(100% + 4px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.25, 0.5, 0.25, 1);

  ${p =>
    p.$hide &&
    css`
      visibility: hidden;
    `}
`

type CriteriaRowProps = {
  $isDragging?: boolean
}

const CriteriaRow = styled(View).attrs({
  gap: '8',
  position: 'relative',
})<CriteriaRowProps>`
  ${p =>
    p.$isDragging === true &&
    css`
      ${CriteriaDragHandleContainer} {
        opacity: 1;
      }
    `};

  &:hover {
    ${CriteriaDragHandleContainer} {
      opacity: 1;
    }
  }
`

const CriteriaSettings: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { t } = useTranslation()
  const { criteria, createCriteria, updateCriteria, deleteCriteria, moveCriteria } =
    useHomeworkCardCreateContext()

  return (
    <View direction='column' gap='12' grow>
      <View direction='column' gap='2' animated layout>
        <Text size='small' bold>
          {t('author.homework.settings.criteria-section-title')}
        </Text>
        <LightText size='small'>{t('author.homework.settings.criteria-section-description')}</LightText>
      </View>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (destination === null) return undefined

          moveCriteria({ from: source.index, to: destination.index })
        }}
      >
        {isEmptyArray(criteria) ? null : (
          <Droppable droppableId='homework-criteria'>
            {(provided, droppableSnapshot) => (
              <View direction='column' gap='8' grow {...provided.droppableProps} ref={provided.innerRef}>
                {criteria.map((c, i) => (
                  <Draggable key={c.id} draggableId={c.id} index={i}>
                    {(provided, snapshot) => (
                      <CriteriaRow
                        key={c.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        $isDragging={snapshot.isDragging}
                      >
                        <CriteriaDragHandleContainer
                          $hide={
                            droppableSnapshot.draggingFromThisWith !== null &&
                            droppableSnapshot.draggingFromThisWith !== c.id
                          }
                        >
                          <Icon iconId='draggable' color='foreground/muted' {...provided.dragHandleProps} />
                        </CriteriaDragHandleContainer>
                        <View
                          grow
                          animated={!snapshot.isDragging && !snapshot.isDropAnimating}
                          layout={!snapshot.isDragging && !snapshot.isDropAnimating}
                        >
                          <InputPrimitive
                            disabled={!canEdit}
                            autoFocus
                            value={c.title}
                            onChange={e => {
                              updateCriteria(c.id, { ...c, title: e.target.value })
                            }}
                            // NOTE: We could use onKeyDown to create new criteria on enter, but we'd need to make sure
                            //  that it behaves as expected (only if cursor is at end of input, move focus to new input etc.)
                            // NOTE: We could also use onKeyDown to delete the item if pressing backspace while it is empty.
                            //  We would need to make sure that it is actually empty though, and we'd have situations where the
                            //  user deletes the criteria by accident and then recreates it with a new ID. That would mean that
                            //  existing reviews would be linked to a different criteria.
                          />
                        </View>
                        {canEdit && (
                          <View
                            animated={!snapshot.isDragging && !snapshot.isDropAnimating}
                            layout={!snapshot.isDragging && !snapshot.isDropAnimating}
                          >
                            <MutedIconButton
                              iconId='trash-can'
                              variant='secondary'
                              onClick={() => deleteCriteria(c.id)}
                            />
                          </View>
                        )}
                      </CriteriaRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </View>
            )}
          </Droppable>
        )}
      </DragDropContext>

      <View animated layout>
        <Button variant='ghost' grow disabled={!canEdit} onClick={() => createCriteria()}>
          {criteria.length === 0
            ? t('author.homework.settings.add-criteria-action')
            : t('author.homework.settings.add-more-criteria-action')}
        </Button>
      </View>
    </View>
  )
}

type RetryMenuItem = MenuItem<'custom-limit' | 'no-limit' | 'set-limit-1' | 'set-limit-2' | 'set-limit-3'>

// limit 2 means that you have 1 retry
const getMenuItemId = (limit?: number): RetryMenuItem['id'] => {
  if (limit === undefined) {
    return 'no-limit'
  }
  if (limit === 1) {
    return 'set-limit-1'
  }
  if (limit === 2) {
    return 'set-limit-2'
  }

  if (limit === 3) {
    return 'set-limit-3'
  }

  return 'custom-limit'
}

// LimitOfSubmissions is off by one compared to retry limits
const fromMenuItemId = (id: RetryMenuItem['id']): number | undefined => {
  switch (id) {
    case 'no-limit':
      return undefined
    case 'set-limit-1':
      return 1
    case 'set-limit-2':
      return 2
    case 'set-limit-3':
      return 3
    default:
      return undefined
  }
}

const CustomLimitMenuItem: React.FC<{
  disabled: boolean
  value?: number
  onChange: (n?: number) => void
  selected: boolean
}> = ({ disabled, value, onChange, selected }) => {
  const { t } = useTranslation()
  const inputFieldRef = React.useRef<HTMLInputElement>(null)
  const [isFocus, onFocus] = useState(selected)

  return (
    <View
      grow
      paddingRight='4'
      direction='column'
      onClick={e => {
        e.stopPropagation()
        inputFieldRef.current?.focus()
      }}
    >
      <Text bold={selected}>{t('author.homework.settings.resubmissions.custom-limit-label')}</Text>
      <InputPrimitive
        ref={inputFieldRef}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onFocus={() => onFocus(true)}
        placeholder={t('author.homework.settings.resubmissions.custom-limit-placeholder')}
        disabled={disabled}
        type='number'
        // do not allow for limitSubmissions to be less than 1
        min={1}
        value={isFocus && isDefined(value) ? `${value}` : ''}
        autoFocus={isFocus}
        onChange={e => {
          const value = e.target.value
          const validValue = isNonEmptyString(value) && !isNaN(Number(value)) ? Number(value) : undefined
          if (validValue !== undefined && validValue >= 1) {
            onChange(validValue)
          } else {
            onChange(undefined)
          }
        }}
      />
    </View>
  )
}

const LimitSubmissionsWrapper = styled(View)`
  flex-wrap: nowrap;
  gap: 8px;
  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    gap: 12px;
  }
`
const SelectWrapper = styled(View)`
  width: 200px;
  min-width: 200px;
`
const LimitSubmissionSettings: React.FC<{
  courseId: CreateContentId
  fileId: NanoId12
  canEdit: boolean
}> = ({ courseId, fileId, canEdit }) => {
  const { t } = useTranslation()
  const { limitOfSubmissions: limit, setLimitOfSubmissions: _setLimitOfSubmissions } =
    useHomeworkCardCreateContext()

  const clearUsersToBeNotifiedMutation = useClearHomeworkAdminsToNotifyMutation({ courseId, fileId })

  const [selectedId, onSelectId] = useState<RetryMenuItem['id']>(getMenuItemId(limit))
  const setLimit = useCallback(
    // Only allow for using LimitOfSubmissions to avoid off by one errors
    (limit?: number) => {
      _setLimitOfSubmissions(limit)
      if (limit === undefined) {
        clearUsersToBeNotifiedMutation.mutate({ courseId, fileId })
      }
    },
    [_setLimitOfSubmissions, clearUsersToBeNotifiedMutation, courseId, fileId]
  )

  const selectId = useCallback(
    (id: RetryMenuItem['id']) => {
      switch (id) {
        case 'custom-limit':
          // This specific item does not work as the regular selected items. Look at the `custom-limit` menu
          // item above which has its own logic for setting the chosen id
          break
        case 'no-limit':
        case 'set-limit-1':
        case 'set-limit-2':
        case 'set-limit-3':
          setLimit(fromMenuItemId(id))
          onSelectId(id)
          break
        default:
          assertNever(id)
      }
    },
    [setLimit]
  )

  const menuItems: Array<RetryMenuItem> = useMemo(() => {
    return [
      {
        id: 'no-limit',
        type: 'label',
        selected: selectedId === 'no-limit',
        label: t('author.homework.settings.resubmissions.no-limit'),
      },
      {
        id: 'separator' as any, // any cast here is fine as its just being used as id and is a separator that is non clickable
        type: 'separator',
      },
      {
        id: 'set-limit-1',
        type: 'label',
        selected: selectedId === 'set-limit-1',
        label: t('author.homework.settings.submissions.custom-limit', { count: 1 }),
      },
      {
        id: 'set-limit-2',
        type: 'label',
        selected: selectedId === 'set-limit-2',
        label: t('author.homework.settings.submissions.custom-limit', { count: 2 }),
      },
      {
        id: 'set-limit-3',
        type: 'label',
        selected: selectedId === 'set-limit-3',
        label: t('author.homework.settings.submissions.custom-limit', { count: 3 }),
      },
      {
        id: 'custom-limit',
        type: 'canvas',
        label: t('author.homework.settings.submissions.custom-limit', {
          count: limit,
        }),
        render: () => (
          <CustomLimitMenuItem
            key='custom-limit'
            selected={selectedId === 'custom-limit'}
            disabled={!canEdit}
            value={isDefined(limit) ? limit : undefined}
            onChange={limit => {
              const item = getMenuItemId(limit)
              onSelectId(item)
              setLimit(limit)
            }}
          />
        ),
      },
    ] satisfies Array<RetryMenuItem>
  }, [canEdit, limit, selectedId, setLimit, t])

  const selected = useMemo(() => {
    return menuItems.find(item => item.id === selectedId)
  }, [menuItems, selectedId])

  return (
    <LimitSubmissionsWrapper grow>
      <View direction='column' alignItems='flex-start' wrap='wrap' gap='2 12' grow>
        <Text size='small' bold>
          {t('author.homework.settings.submissions-limit.title')}
        </Text>
        <TruncatedText lines={1} color='foreground/muted' size='small'>
          {t('author.homework.settings.submission-limit.description')}
        </TruncatedText>
      </View>
      <SelectWrapper>
        <SingleSelectDropdown
          onSelect={s => selectId(s.id)}
          grow
          selectedItem={selected}
          menuItems={menuItems}
        />
      </SelectWrapper>
    </LimitSubmissionsWrapper>
  )
}

const SubmissionSelectorWrapper = styled(View)`
  min-width: 150px;
  width: 50%;

  & > button {
    min-height: 40px;
  }
`

const SubmissionTypeSelector: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { t } = useTranslation()
  const { submissionType, fileSubmissionSubtype, setSubmissionType } = useHomeworkCardCreateContext()

  const items: MenuItem<SubmissionType | 'video'>[] = [
    {
      type: 'label',
      id: 'file',
      label: t('author.homework.settings.file-submission'),
      description: t('author.homework.settings.file-submission-description'),
    },
    {
      type: 'label',
      id: 'text',
      label: t('author.homework.settings.text-submission'),
      description: t('author.homework.settings.text-submission-description'),
    },
    {
      type: 'label',
      id: 'video',
      label: t('author.homework.settings.video-submission'),
      description: t('author.homework.settings.video-submission-description'),
    },
  ]

  const selectedItem =
    items.find(item => item.id === fileSubmissionSubtype) ?? items.find(item => item.id === submissionType)

  return (
    <SubmissionSelectorWrapper direction='column' gap='xxsmall'>
      <Text size='small' bold>
        {t('author.homework.settings.homework-submission-type')}
      </Text>
      <SingleSelectDropdown
        disabled={!canEdit}
        menuItems={items}
        selectedItem={selectedItem}
        grow
        bold
        onSelect={item => {
          if (item.id === 'video') {
            setSubmissionType('file', 'video')
          } else {
            setSubmissionType(item.id)
          }
        }}
      />
    </SubmissionSelectorWrapper>
  )
}

const HelpCenterLink = styled(Link).attrs({
  color: 'foreground/muted',
  target: '_blank',
  size: 'small',
})`
  display: flex;
  gap: 6px;

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const HomeworkSettings: React.FC<{ courseId: CreateContentId; fileId: NanoId12 }> = React.memo(
  ({ courseId, fileId }) => {
    const { t } = useTranslation()
    const hasManageAccess = useHasManageAccess()
    const { assignedReviewerType, limitOfSubmissions, setAssignedReviewerType } =
      useHomeworkCardCreateContext()
    const isHomeworkManagerReviewersEnabled = getFlag('homework-manager-reviewers')

    return (
      <AppThemeTokenProvider>
        <View paddingTop='16' paddingBottom='16'>
          <HomeworkCardSettingsContainer animated layout>
            <View overflow='scroll' direction='column' gap='none' padding='24 32' grow>
              <View direction='column' gap='small' grow>
                <View animated layout>
                  <Heading size='h5' bold>
                    {t('author.homework.settings.title')}
                  </Heading>
                </View>
                {isHomeworkManagerReviewersEnabled ? (
                  <>
                    <View animated layout>
                      <SubmissionTypeSelector canEdit={hasManageAccess} />
                      <ReviewerTypeSelector
                        fileId={fileId}
                        courseId={courseId}
                        canEdit={hasManageAccess}
                        reviewerType={assignedReviewerType}
                        setReviewerType={setAssignedReviewerType}
                      />
                    </View>
                    <AnimatePresence initial={false} mode={'popLayout'}>
                      {assignedReviewerType === 'users' && (
                        <View
                          animated
                          layout
                          transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                        >
                          <ReviewerSelector courseId={courseId} fileId={fileId} canEdit={hasManageAccess} />
                        </View>
                      )}
                    </AnimatePresence>
                  </>
                ) : (
                  <View alignItems='flex-start'>
                    <SubmissionTypeSelector canEdit={hasManageAccess} />
                    <ReviewerSelector courseId={courseId} fileId={fileId} canEdit={hasManageAccess} />
                  </View>
                )}
                <View animated layout>
                  <ReviewInstructions canEdit={hasManageAccess} />
                </View>
                <View animated layout>
                  <CriteriaSettings canEdit={hasManageAccess} />
                </View>
                <View animated layout>
                  <LimitSubmissionSettings courseId={courseId} fileId={fileId} canEdit={hasManageAccess} />
                </View>
                {isDefined(limitOfSubmissions) && (
                  <View animated layout initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <NotifyAdminSelector courseId={courseId} fileId={fileId} canEdit={hasManageAccess} />
                  </View>
                )}
              </View>
              <Spacer size='32' />
              <Divider />
              <Spacer size='16' />
              <View animated layout>
                <HelpCenterLink href={EXERCISES_HELP_PAGE_URL}>
                  <Icon iconId='information' color='currentColor' />
                  {t('author.homework.settings.help-center-link')}
                </HelpCenterLink>
              </View>
            </View>
          </HomeworkCardSettingsContainer>
        </View>
      </AppThemeTokenProvider>
    )
  }
)
