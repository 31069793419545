import {
  ContentAttributeDefFragment,
  ContentAttributesQuery,
  ContentEnumValue,
  CustomContentAttributeSchemaInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { uuid, UUID } from 'sierra-domain/api/uuid'
import { assertNever, ExtractFrom, iife } from 'sierra-domain/utils'
import { z } from 'zod'

export type GQLContentAttribute = ContentAttributeDefFragment
export type GQLContentAttributeInput = CustomContentAttributeSchemaInput

const noDuplicateCheck = (items: Array<{ label: string }>, ctx: z.RefinementCtx): void => {
  // Keep track of all labels and the indices where they appear
  const occurrences: Record<string, number[]> = {}

  items.forEach((item, index) => {
    const { label } = item
    occurrences[label] = occurrences[label] || []
    occurrences[label].push(index)
  })

  // If a labels is duplicated, mark each duplicate occurrence with an error
  Object.entries(occurrences).forEach(([label, indices]) => {
    if (indices.length > 1) {
      indices.forEach(i => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Duplicate label: "${label}"`,
          path: [i, 'label'], // highlight the specific item that’s duplicated
        })
      })
    }
  })
}

export const NumberAttribute = z.object({
  type: z.literal('number'),
})

export type NumberAttribute = z.infer<typeof NumberAttribute>

export const TextAttribute = z.object({
  type: z.literal('text'),
})

export type TextAttribute = z.infer<typeof TextAttribute>

export const DateAttribute = z.object({
  type: z.literal('date'),
})

export type DateAttribute = z.infer<typeof DateAttribute>

export const SelectItem = z.object({
  id: z.string(),
  label: z.string().min(1),
})

export type SelectItem = z.infer<typeof SelectItem>
export type SelectItemInput = z.input<typeof SelectItem>
export const SelectAttribute = z.object({
  type: z.literal('select'),
  options: z.array(SelectItem).min(2).superRefine(noDuplicateCheck),
  allowMultiSelect: z.boolean(),
})

export type SelectAttribute = z.infer<typeof SelectAttribute>

export const CategoriesAttribute = z.object({
  type: z.literal('categories'),
  options: z.array(SelectItem).min(2).superRefine(noDuplicateCheck),
})
export type CategoriesAttribute = z.infer<typeof CategoriesAttribute>

const CommonContentAttributeFields = z.object({
  id: UUID,
  index: z.number(),
  name: z.string().min(2),
  description: z.string().min(2).optional(),
  learnerVisible: z.boolean(),
})

export const ContentAttribute = CommonContentAttributeFields.and(
  z.discriminatedUnion('type', [
    SelectAttribute,
    DateAttribute,
    TextAttribute,
    NumberAttribute,
    CategoriesAttribute,
  ])
)

export type ContentAttribute = z.infer<typeof ContentAttribute>
export type ContentAttributeInternalType = ContentAttribute['type']

export const ContentAttributeWithValue = ContentAttribute.and(
  z.object({
    value: z.string(),
  })
)

export type ContentAttributeWithValue = z.infer<typeof ContentAttributeWithValue>

export const getAttributeType = (
  type: ContentAttributeDefFragment['type']
): Exclude<ContentAttributeInternalType, 'categories'> => {
  switch (type.__typename) {
    case 'Enum':
      return 'select'
    case 'ContentDate':
      return 'date'
    case 'Number':
      return 'number'
    case 'Str':
      return 'text'
    default:
      assertNever(type)
  }
}

export const GQL2CAT = (gql: GQLContentAttribute): ContentAttribute => {
  const { id, index, name, description, type, learnerVisible } = gql
  const common = {
    id,
    index,
    name,
    description: null2Undefined(description),
    learnerVisible: learnerVisible,
  }
  switch (type.__typename) {
    case 'Enum':
      return {
        ...common,
        type: 'select',
        // TODO: fix allow multiselect?
        allowMultiSelect: false,
        options: type.values
          .map(value => ({ label: value.label, id: value.id }))
          .map(x => SelectItem.parse(x)),
      }
    case 'ContentDate':
      return {
        ...common,
        type: 'date',
      }
    case 'Number':
      return {
        ...common,
        type: 'number',
      }
    case 'Str':
      return {
        ...common,
        type: 'text',
      }
    default:
      assertNever(type)
  }
}

// TODO: Should we really have this as hard types? Can we generate? could possibly be ContentAttributeType
type AllowedContentAttributes =
  | {
      type: 'content-attribute.date'
      format: string
    }
  | {
      type: 'content-attribute.number'
    }
  | {
      type: 'content-attribute.string'
    }
  | {
      type: 'content-attribute.enum'
      allowMultiSelect: boolean
      values: ContentEnumValue[]
    }

export const EditableSelectItem = SelectItem.extend({
  id: SelectItem.shape.id.optional(),
  _tempId: z.string(),
})

export type EditableSelectItem = z.infer<typeof EditableSelectItem>

export const EditableSelectAttribute = SelectAttribute.extend({
  options: z.array(EditableSelectItem).min(2).superRefine(noDuplicateCheck),
})
export type EditableSelectAttribute = z.infer<typeof EditableSelectAttribute>

export const EditableCategoriesAttribute = CategoriesAttribute.extend({
  options: z.array(EditableSelectItem).min(2).superRefine(noDuplicateCheck),
})
export type EditableCategoriesAttribute = z.infer<typeof EditableCategoriesAttribute>

export const EditableContentAttribute = CommonContentAttributeFields.extend({
  id: CommonContentAttributeFields.shape.id.optional(),
}).and(
  z.discriminatedUnion('type', [
    EditableSelectAttribute,
    DateAttribute,
    TextAttribute,
    NumberAttribute,
    EditableCategoriesAttribute,
  ])
)

export type EditableContentAttribute = z.infer<typeof EditableContentAttribute>

z.intersection(
  ContentAttribute._def.left.extend({
    id: ContentAttribute._def.left.shape.id.optional(),
  }),
  ContentAttribute._def.right
)

export const CATtoEditable = (cat: ContentAttribute): EditableContentAttribute => {
  if (cat.type === 'select' || cat.type === 'categories') {
    return {
      ...cat,
      options: cat.options.map(option => ({ label: option.label, id: option.id, _tempId: option.id })),
    }
  } else {
    return cat
  }
}

// TODO(Fanny): add support for tags
const toGQLType = (type: AllowedContentAttributes): string => JSON.stringify(type)

export const EditedCAT2GQL = (cat: EditableContentAttribute): GQLContentAttributeInput => {
  const { id, index, name, description, type, learnerVisible } = cat
  const common = {
    id,
    index,
    name,
    description,
    learnerVisible,
  }
  const customAttributes = iife(() => {
    switch (type) {
      case 'select':
        return [
          {
            ...common,
            type: toGQLType({
              type: 'content-attribute.enum',
              values: cat.options.map(option => ({
                label: option.label,
                id: 'id' in option ? NanoId12.parse(option.id) : null,
              })),
              // TODO: fix allow multiselect?
              allowMultiSelect: false,
            }),
          },
        ]
      case 'date':
        return [
          {
            ...common,
            type: toGQLType({ type: 'content-attribute.date', format: 'YYYY-MM-DD' }),
          },
        ]
      case 'number':
        return [
          {
            ...common,
            type: toGQLType({ type: 'content-attribute.number' }),
          },
        ]
      case 'text':
        return [
          {
            ...common,
            type: toGQLType({ type: 'content-attribute.string' }),
          },
        ]
      case 'categories':
        return []
      default:
        assertNever(type)
    }
  })

  const tags: GQLContentAttributeInput['tagsDef'] = iife(() => {
    if (type === 'categories') {
      return cat.options.map(option => ({
        description: option.label,
        id: 'id' in option ? option.id : undefined,
        name: option.label,
        // TODO: should add image
      }))
    } else {
      return []
    }
  })
  return {
    customAttributeDefsInput: customAttributes,
    tagsDef: tags,
  }
}

export const tags2Categories = (
  tags: ContentAttributesQuery['contentAttributesSchema']['tagsDef']
): ExtractFrom<ContentAttribute, { type: 'categories' }> => {
  // TODO: translate
  return {
    id: uuid(),
    type: 'categories',
    name: 'Category',
    description: 'All categories',
    // TOOD(Fanny): implement this
    learnerVisible: false,
    index: -1,
    options: tags
      // Fix ID as each and every options should include ID
      .map(tag => ({ label: tag.name, id: tag.id }))
      .map(x => SelectItem.parse(x)),
  }
}
