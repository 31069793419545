import React from 'react'
import { UsePaginationResult } from 'sierra-client/hooks/use-enhanced-pagination'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { defaultEasing } from 'sierra-ui/theming/animations'
import styled, { css } from 'styled-components'

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: ${p => p.theme.color.grey25};
`

const PaginationItem = styled.a<{ $disabled: boolean; $selected: boolean }>`
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 45px;
  min-height: 45px;

  color: ${token('foreground/muted')};

  cursor: pointer;

  ${defaultEasing};

  ${p =>
    p.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  ${p =>
    p.$selected &&
    css`
      pointer-events: none;
      color: ${token('foreground/primary')};
    `};

  &:hover {
    color: ${p => p.theme.color.grey90};
  }
`

interface SanaPaginationItemProps {
  item: UsePaginationResult['items'][0]
}

const SanaPaginationItem: React.FC<SanaPaginationItemProps> = ({ item }) => {
  const { page, type, selected, onClick, disabled = false } = item

  switch (type) {
    case 'previous':
    case 'next':
      return (
        <PaginationItem $selected={false} $disabled={disabled} onClick={disabled ? undefined : onClick}>
          <Icon
            color='currentColor'
            iconId={type === 'next' ? 'arrow--right' : 'arrow--left'}
            size='size-24'
          />
        </PaginationItem>
      )
    case 'page':
      return (
        <PaginationItem $selected={selected} $disabled={disabled} onClick={disabled ? undefined : onClick}>
          {page}
        </PaginationItem>
      )
    default:
      assertNever(type)
  }
}

interface SanaPaginationProps {
  paginationItems: UsePaginationResult['items']
}

export const SanaPagination: React.FC<SanaPaginationProps> = ({ paginationItems }) => {
  // Hide pagination if there is only one item
  if (paginationItems.length <= 1) return null

  return (
    <PaginationContainer>
      {paginationItems.map((item, index) => (
        <SanaPaginationItem key={index} item={item} />
      ))}
    </PaginationContainer>
  )
}
