import _ from 'lodash'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectCurrentPath } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { OverviewHeader } from 'sierra-client/views/learner/components/overview/header'
import { CourseList } from 'sierra-client/views/learner/path/components/course-list'
import { PathSummary } from 'sierra-client/views/learner/path/components/summary'
import { PathId } from 'sierra-domain/api/nano-id'
import { StrategyPathResponse } from 'sierra-domain/api/strategy-v2'
import { XRealtimeContentGetUserPathIsRequiredAssignment } from 'sierra-domain/routes'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const GlobalStylesHome = createGlobalStyle`
  #__next {
    /* This is to allow sticky behavior on the left column on desktop */
    @media screen and (min-width: ${v2_breakpoint.tablet}) {
      overflow: visible !important;
    }
  }
`

interface PathPageProps {
  pathData: StrategyPathResponse & { id: PathId }
}

export const PathPageContent = ({ pathData }: PathPageProps): JSX.Element => {
  const {
    id: pathId,
    path: { title, image, settings },
    courses,
  } = pathData

  const imageUrl = useResolveAsset({
    image,
    assetContext: { type: 'path', pathId },
    size: 'default',
  })

  const isRequiredAssignment =
    useCachedQuery(
      XRealtimeContentGetUserPathIsRequiredAssignment,
      { pathId: pathData.id },
      {
        retry: false,
      }
    ).data?.isRequiredAssignment === true

  const availableCourses = settings.sequentialLearning
    ? courses.filter((_, index) => courses.slice(0, index).every(course => course.status.passedTimestamp))
    : courses
  const lockedCourses = courses.filter(course => !availableCourses.includes(course))
  const inheritedSkills = _.uniq(
    courses.flatMap(course =>
      course.type === 'native:self-paced' || course.type === 'native:live' ? course.tags : []
    )
  )

  const totalReadingTime = courses.reduce((total, course) => total + course.readingTimes.total, 0)
  const totalReadingTimeLeft = courses.reduce((total, course) => total + course.status.timeLeft.total, 0)
  // TODO(rafael) Fix the title tag??
  return (
    <>
      <GlobalStylesHome />
      <OverviewHeader
        image={imageUrl}
        title={title}
        titleTag={undefined}
        isRequiredAssignment={isRequiredAssignment}
      />
      <PathSummary
        pathData={pathData}
        inheritedSkills={inheritedSkills}
        totalReadingTime={totalReadingTime}
        totalReadingTimeLeft={totalReadingTimeLeft}
      >
        <CourseList
          sequentialLearning={settings.sequentialLearning}
          availableCourses={availableCourses}
          lockedCourses={lockedCourses}
          pathId={pathId}
        />
      </PathSummary>
    </>
  )
}

export const PathPage: React.FC<{ pathId: PathId }> = ({ pathId }) => {
  const pathData = useSelector(selectCurrentPath)
  const learnerTheme = useLearnerTheme()

  return (
    <SanaPage mode='light' showIntercomLauncher={true} page={PageIdentifier.LearnerPath({ pathId })}>
      <ThemeProvider theme={learnerTheme}>
        {pathData !== undefined ? <PathPageContent pathData={pathData} /> : null}
      </ThemeProvider>
    </SanaPage>
  )
}
