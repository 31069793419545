import { GranularVisibilityState } from 'sierra-client/features/multi-tenancy/granular-visibility/types'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { isDefined } from 'sierra-domain/utils'

/**
 * The state shape is a bit gnarly, this helper makes it a bit easier
 * to build a patch for the state update.
 */
export function visibilityPatch(
  currentGranularVisibilityState: GranularVisibilityState,
  clusterOrganizations: AccessibleOrganization[],
  visibility: CourseVisibilityInOrg
): GranularVisibilityState {
  const distributionOrganizations = clusterOrganizations.filter(org => !org.isClusterParent)

  return {
    parent: visibility,
    distributions: distributionOrganizations.reduce(
      (acc: GranularVisibilityState['distributions'], org: AccessibleOrganization) => {
        const currentState = currentGranularVisibilityState.distributions[org.namespaceId]
        const enabled = isDefined(currentState) ? currentState.enabled : false

        acc[org.namespaceId] = {
          enabled,
          visibility,
        }

        return acc
      },
      {}
    ),
  }
}
