import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai'
import { FC } from 'react'
import { HeaderButtonColorCSS, HeaderItemCSS } from 'sierra-client/components/liveV2/header-buttons'
import { aiNarrationPanelClosed, aiNarrationPanelOpened } from 'sierra-client/core/logging/authoring/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { AINarrationNudgeState } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { RightSidebarState } from 'sierra-client/views/flexible-content/create-page-right-sidebar-state'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { Button } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const AiNarrationButton = styled(Button)`
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`
export const AINarrationsControls: FC<{
  fileId: FileId
  contentType: ContentType
  contentId: CreateContentId
  rightSidebarControlStateAtom: PrimitiveAtom<RightSidebarState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
}> = ({
  fileId,
  rightSidebarControlStateAtom,
  legacyNarrationStateAtom,
  contentType,
  contentId,
  aiNarrationNudgeStateAtom,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useAtom(rightSidebarControlStateAtom)
  const setNudgeState = useSetAtom(aiNarrationNudgeStateAtom)
  const dispatch = useDispatch()

  const isOpen = state.type === 'ai-narrations'
  useOnChanged((wasOpenBefore, isOpenNow) => {
    if (wasOpenBefore === undefined) {
      return
    }

    if (!wasOpenBefore && isOpenNow) {
      void dispatch(
        aiNarrationPanelOpened({
          contentType,
          contentId,
          fileId: fileId,
        })
      )
    }

    if (!isOpenNow && wasOpenBefore) {
      void dispatch(
        aiNarrationPanelClosed({
          contentType,
          contentId,
          fileId: fileId,
        })
      )
    }
  }, isOpen)

  // Close narrations when the card changes
  useOnChanged(() => {
    setState({ type: 'closed' })
  }, fileId)

  const setLegacyNarrationState = useSetAtom(legacyNarrationStateAtom)

  return (
    <AiNarrationButton
      icon='user'
      onClick={() => {
        setNudgeState(prev => ({ ...prev, [contentId]: { shown: true } }))

        setState(previous =>
          previous.type === 'ai-narrations' ? { type: 'closed' } : { type: 'ai-narrations' }
        )
        // Close the old menu in case it was open
        setLegacyNarrationState(false)
      }}
    >
      {t('dictionary.narration')}
    </AiNarrationButton>
  )
}
