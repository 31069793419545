import { Tooltip } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const IconWrapper = styled.div`
  display: flex;
`
const HoverIconWrapper = styled.div`
  display: none;
`

const ToolbarItemRoot = styled.button<{
  $active: boolean
  $activeColor?: string
  $disabled?: boolean
}>`
  /* HERE IS JUST CRAPPY MUI STYLES THAT I ADDED TO GET RID OF THE MUI DEPENDENCY, FEEL FREE TO FIX */
  color: inherit;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  /* HERE IS JUST CRAPPY MUI STYLES THAT I ADDED TO GET RID OF THE MUI DEPENDENCY, FEEL FREE TO FIX */

  background-color: ${props =>
    props.$active ? (props.$activeColor ?? 'rgba(77, 77, 77, 0.8)') : 'rgba(255, 255, 255, 0.9)'};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin: 0 2px;
  cursor: ${p => (p.$disabled === true ? 'not-allowed' : 'pointer')};
  transition: background-color 0.06s;

  border: 1px solid;
  border-color: ${palette.grey[5]};

  border-color: ${props => (props.$active ? (props.$activeColor ?? 'transparent') : '#F2F2F2')};

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(40px);

  &:hover {
    background-color: ${props =>
      props.$active ? (props.$activeColor ?? 'rgba(77, 77, 77, 1)') : 'rgba(255, 255, 255, 1)'};
  }

  &:hover > ${IconWrapper} {
    display: none;
  }

  &:hover > ${HoverIconWrapper} {
    display: flex;
  }
`

type ToolbarItemProps = {
  active: boolean
  activeColor?: string
  color?: string
  icon: JSX.Element
  activeIcon?: JSX.Element | undefined
  hoverIcon?: JSX.Element | undefined
  onClick?: () => void
  tooltip?: string
  activeTooltip?: string
  pfcid?: string
  className?: string
  disabled?: boolean
}

export const ToolbarItem: React.FC<ToolbarItemProps> = ({
  active,
  icon,
  activeIcon,
  hoverIcon,
  activeColor,
  onClick,
  tooltip,
  activeTooltip,
  pfcid,
  className,
  disabled,
}) => {
  const iconToShow = active ? (activeIcon ?? icon) : icon
  const iconWhenHover = hoverIcon ?? iconToShow
  const tooltipToShow = active ? (activeTooltip ?? tooltip) : tooltip

  return (
    <Tooltip title={tooltipToShow}>
      <ToolbarItemRoot
        className={className}
        data-pfcid={pfcid}
        onClick={disabled === true ? undefined : onClick}
        $active={active}
        $activeColor={activeColor}
        $disabled={disabled}
      >
        <IconWrapper>{iconToShow}</IconWrapper>
        <HoverIconWrapper>{iconWhenHover}</HoverIconWrapper>
      </ToolbarItemRoot>
    </Tooltip>
  )
}
