import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useCourseCollaborators } from 'sierra-client/api/queries/course-collaborators'
import { invalidateCourseCollaborators } from 'sierra-client/api/queries/invalidate-course-collaborators'
import { usePotentialCourseCollaboratorUsersFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { currentCourseId as currentCourseIdSelector } from 'sierra-client/state/author-course-settings/selectors'
import { selectors as settingsSelectors } from 'sierra-client/state/author-course-settings/slice'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  CourseCollaboratorIdentitiesSelector,
  CourseCollaboratorIdentitiesSelectorProps,
} from 'sierra-client/views/course-settings/course-collaborator-identities-selector'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { CourseRole, IdentityWithMetadata } from 'sierra-domain/api/manage'
import { XRealtimeAuthorTransferContentOwnership } from 'sierra-domain/routes'
import { assertNever, getUserName, iife, isDefined, isNotNull } from 'sierra-domain/utils'
import { Icon, MenuItem, RoundAvatar, Tooltip } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const collaboratorSortValues: Record<CourseRole, number> = {
  owner: 3,
  editor: 2,
  commenter: 1,
}

const sortCollaboratorsFn = (a: Collaborator, b: Collaborator): number =>
  isDefined(a.role) && isDefined(b.role) ? collaboratorSortValues[b.role] - collaboratorSortValues[a.role] : 0

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const MaxWidthContainer = styled.div`
  max-width: 150px;
`

const CollaboratorList = styled.ul`
  list-style: none;
  position: relative;

  li {
    border-bottom: 1px solid ${palette.grey[5]};
  }

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const CollaboratorEmailText = styled(Text).attrs({ size: 'small', color: 'grey40' })``

const CollaboratorTextContainer = styled(View)`
  & ${CollaboratorEmailText} {
    display: none;
  }

  &:hover {
    & ${CollaboratorEmailText} {
      display: block;
    }
  }
`
const StyledView = styled(View)`
  margin-bottom: 42px;
`
const ReadOnlyCollaborators: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledView
      grow
      direction='row'
      justifyContent='center'
      alignItems='center'
      background='surface/soft'
      radius='size-16'
      gap='12'
    >
      <View direction='column' alignItems='center' gap='2'>
        <Icon iconId='error--stop' size='size-16' />
        <Spacer size='4' />
        <Text size='small' color='foreground/secondary' bold>
          {t('author.collaborators.read-only')}
        </Text>
        <Text size='small' color='foreground/muted'>
          {t('author.content.course-managed-externally')}
        </Text>
      </View>
    </StyledView>
  )
}

const getOptionLabel = (option: Collaborator): string => getUserName(option) ?? ''

const CollaboratorDropdown: React.FC<{
  hasOwnerAndCommenterRoles: boolean
  showTransferOwnershipAction: boolean
  currentRole: CourseRole
  onSelect: (item: MenuItem<CourseRole | 'remove'>) => void
  disabled: boolean
}> = ({ hasOwnerAndCommenterRoles, showTransferOwnershipAction, currentRole, onSelect, disabled }) => {
  const { t } = useTranslation()
  const permissionDropdownOptions: MenuItem<CourseRole | 'remove'>[] = [
    {
      type: 'label' as const,
      label: t('author.can-edit'),
      id: 'editor' as const,
      selected: currentRole === 'editor',
    },
    hasOwnerAndCommenterRoles
      ? {
          type: 'label' as const,
          label: t('author.can-view-and-comment'),
          id: 'commenter' as const,
          selected: currentRole === 'commenter',
        }
      : undefined,
    showTransferOwnershipAction
      ? {
          type: 'label' as const,
          label: t('author.owner-transfer.transfer-ownership'),
          id: 'owner' as const,
        }
      : undefined,
    {
      type: 'label' as const,
      label: t('admin.remove'),
      id: 'remove' as const,
      color: 'destructive/background' as const,
    },
  ].filter(isDefined)
  const selectedItem = permissionDropdownOptions.find(item => item.id === currentRole)
  return (
    <SingleSelectDropdown
      variant='ghost'
      selectedItem={selectedItem}
      menuItems={permissionDropdownOptions}
      onSelect={onSelect}
      disabled={disabled}
    />
  )
}

export const CollaboratorsTab: SettingsTabComponent = ({ courseId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()
  const { postWithUserErrorException } = usePost()

  const [collaboratorRole, setCollaboratorRole] = useState<CourseRole>('editor')

  const [selectedCollaboratorIdentities, setSelectedCollaboratorIdentitiesState] = useState<
    IdentityWithMetadata[]
  >([])

  const setSelectedCollaboratorIdentities: CourseCollaboratorIdentitiesSelectorProps['setSelectedIdentities'] =
    update => {
      const updatedIdentities = update(selectedCollaboratorIdentities)
      setSelectedCollaboratorIdentitiesState(updatedIdentities)
    }

  const courseCollaborators = useCourseCollaborators({ courseId })
  const fetchCollaboratorIdentities = usePotentialCourseCollaboratorUsersFetcher(courseId)

  const courseKind = useSelector(settingsSelectors.selectCourseKind)
  const readOnly = useSelector(settingsSelectors.selectReadOnly)
  const permissions = useContentKindPermissions(courseId)
  const canTransferOwnership = permissions.has('TRANSFER_OWNERSHIP')
  const canEditCollaborators = iife(() => {
    switch (courseKind) {
      case 'native:live':
      case 'native:self-paced':
      case 'native:course-group':
      case 'scorm:course-group':
      case 'linkedin':
      case 'link':
      case 'scorm':
        return permissions.has('EDIT_COLLABORATORS')
      case 'native:event-group':
        return permissions.has('EDIT')
      case null:
        return false
    }
  })

  const handleInviteSelections = async (): Promise<void> => {
    await dispatch(
      settingsActions.updateCollaborators({
        previousCollaborators: courseCollaborators,
        changedCollaborators: selectedCollaboratorIdentities.map(u => {
          switch (u.identity.type) {
            case 'user':
              return {
                uuid: u.identity.id,
                role: collaboratorRole,
              }
            case 'userGroup':
              throw new Error('User groups are not supported in user collaborator tab')
            default:
              assertNever(u.identity)
          }
        }),
      })
    )
    setSelectedCollaboratorIdentities(() => [])

    notifications.push({
      type: 'custom',
      level: 'success',
      body: t('notifications.collaborator-invited-singular'),
    })
  }

  const handleRemoveCollaborator = async (collaborator: Collaborator): Promise<void> => {
    await dispatch(
      settingsActions.updateCollaborators({
        previousCollaborators: courseCollaborators,
        changedCollaborators: [collaborator],
      })
    )
  }

  const updateCollaborator = useCallback(
    async (collaborator: Collaborator): Promise<void> => {
      await dispatch(
        settingsActions.updateCollaborators({
          previousCollaborators: courseCollaborators,
          changedCollaborators: [collaborator],
        })
      )
    },
    [dispatch, courseCollaborators]
  )

  const courseKindsWithOnlyEditor: Array<string | null> = [
    'native:course-group',
    'scorm:course-group',
    'linkedin',
    'link',
    'scorm',
    'native:event-group',
  ]

  const hasOwnerAndCommenterRoles = !courseKindsWithOnlyEditor.includes(courseKind)

  const [
    showOwnerTransferConfirmation,
    { on: ownerTransferConfirmationOn, off: ownerTransferConfirmationOff },
  ] = useToggle()
  const [userIdForOwnerTransfer, setUserIdForOwnerTransfer] = useState<string | undefined>(undefined)

  const currentCourseId = useSelector(currentCourseIdSelector)

  const handleTransferOfOwnership = async (id: string): Promise<void> => {
    if (isNotNull(currentCourseId)) {
      await postWithUserErrorException(XRealtimeAuthorTransferContentOwnership, {
        contentId: currentCourseId,
        userId: id,
      })
      void invalidateCourseCollaborators({ courseId: currentCourseId })
    }
  }

  const items: MenuItem<CourseRole>[] = hasOwnerAndCommenterRoles
    ? [
        { type: 'label', label: t('author.can-edit'), id: 'editor', selected: collaboratorRole === 'editor' },
        {
          type: 'label',
          label: t('author.can-view-and-comment'),
          id: 'commenter',
          selected: collaboratorRole === 'commenter',
        },
      ]
    : [{ type: 'label', label: t('author.can-edit'), id: 'editor', selected: collaboratorRole === 'editor' }]

  const selectedItem = items.find(item => item.id === collaboratorRole)

  return readOnly === true ? (
    <ReadOnlyCollaborators />
  ) : (
    <Container>
      <Text bold size='small'>
        {t('author.invite-collaborators')}
      </Text>
      <Spacer size='8' />
      <InputContainer>
        <CourseCollaboratorIdentitiesSelector
          selectedIdentities={selectedCollaboratorIdentities}
          setSelectedIdentities={setSelectedCollaboratorIdentities}
          fetchIdentities={fetchCollaboratorIdentities}
          type='user'
        />

        <MaxWidthContainer>
          <SingleSelectDropdown
            selectedItem={selectedItem}
            menuItems={items}
            onSelect={item => setCollaboratorRole(item.id)}
            disabled={!canEditCollaborators}
          />
        </MaxWidthContainer>
        <Button
          variant='secondary'
          onClick={handleInviteSelections}
          disabled={selectedCollaboratorIdentities.length === 0}
        >
          {t('dictionary.invite')}
        </Button>
      </InputContainer>
      <Spacer size='16' />
      <CollaboratorList>
        {courseCollaborators.length === 0 ? (
          <li>
            <Text size='small' color='grey35'>
              {t('author.no-collaborators')}
            </Text>
          </li>
        ) : (
          [...courseCollaborators].sort(sortCollaboratorsFn).map((collaborator: Collaborator) => (
            <li key={collaborator.uuid} className='grid'>
              <RoundAvatar
                firstName={collaborator.firstName}
                lastName={collaborator.lastName}
                src={getAvatarImage(collaborator.uuid, collaborator.avatar)}
                color={collaborator.avatarColor}
              />
              <CollaboratorTextContainer>
                <Text bold size='small'>
                  {getOptionLabel(collaborator)}
                </Text>
                {isDefined(collaborator.email) && (
                  <CollaboratorEmailText>{collaborator.email}</CollaboratorEmailText>
                )}
              </CollaboratorTextContainer>

              {collaborator.role === 'owner' ? (
                <View marginRight='xxsmall'>
                  <Tooltip title={t('author.owner-transfer.tooltip')}>
                    <Text color='grey40' size='small'>
                      {t('dictionary.owner')}
                    </Text>
                  </Tooltip>
                </View>
              ) : (
                <CollaboratorDropdown
                  currentRole={collaborator.role ?? 'editor'}
                  showTransferOwnershipAction={canTransferOwnership}
                  hasOwnerAndCommenterRoles={hasOwnerAndCommenterRoles}
                  disabled={!canEditCollaborators}
                  onSelect={(item: MenuItem<CourseRole | 'remove'>) => {
                    switch (item.id) {
                      case 'owner':
                        setUserIdForOwnerTransfer(collaborator.uuid)
                        ownerTransferConfirmationOn()
                        break

                      case 'remove':
                        void handleRemoveCollaborator(collaborator)
                        break

                      case 'editor':
                      case 'commenter': {
                        const previousCollaborator = collaborator
                        const newCollaborator = { ...collaborator, role: CourseRole.parse(item.id) }
                        if (!_.isEqual(previousCollaborator, newCollaborator)) {
                          void updateCollaborator(newCollaborator)
                        }
                        break
                      }

                      default:
                        assertNever(item.id)
                    }
                  }}
                />
              )}
            </li>
          ))
        )}
        <ActionModal
          open={showOwnerTransferConfirmation}
          title={t('author.owner-transfer.transfer-ownership')}
          onClose={ownerTransferConfirmationOff}
          primaryAction={() => {
            if (isDefined(userIdForOwnerTransfer)) void handleTransferOfOwnership(userIdForOwnerTransfer)
          }}
          primaryActionLabel={t('dictionary.transfer')}
        >
          {t('author.owner-transfer.body')}
        </ActionModal>
      </CollaboratorList>
    </Container>
  )
}
