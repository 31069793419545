import React, { useCallback, useMemo, useState } from 'react'
import { useDuplicateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Thumbnail as CommonThumbnail } from 'sierra-client/components/common/thumbnail'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import {
  IconMenuWithMoveToTeamspaces,
  IconMenuWithMoveToTeamspacesProps,
  TeamspaceContentTypeName,
} from 'sierra-client/features/teamspace'
import { teamspaceContentClickedLogger } from 'sierra-client/features/teamspace/logger'
import { UseDeleteCourse, useDeleteCourse } from 'sierra-client/hooks/use-delete-course'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { CTAActions } from 'sierra-client/views/workspace/components/content-table-cta-buttons'
import {
  CoursePublishStateLabel,
  LastUpdateLabel,
  MetadataWrapper,
  RowContainer,
  TruncatedAutoWidth,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { continueUrl, Linkable, manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { EditableContent, editUrl, UserRole } from 'sierra-domain/api/editable-content'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { PinnableContentType } from 'sierra-domain/api/user'
import {
  XRealtimeAdminPathsDeletePath,
  XRealtimeUserAddPins,
  XRealtimeUserRemovePins,
} from 'sierra-domain/routes'
import { assert, assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { IconMenu, IconMenuProps } from 'sierra-ui/primitives/menu-dropdown/icon-menu'
import styled from 'styled-components'

const pinnableContentType = (createContentKind: EditableContent['type']): PinnableContentType => {
  switch (createContentKind) {
    case 'native:self-paced':
    case 'native:live':
      return 'course'
    case 'path':
      return 'path'
    default:
      assertNever(createContentKind)
  }
}

const NoWrapText = styled(Text)`
  text-wrap: nowrap;
`

const ContentDisplay: React.FC<{ content: EditableContent; isPinned: boolean }> = ({ content, isPinned }) => {
  const isDesktop = !useIsSmallDesktop()
  const { t } = useTranslation()

  const isCourse = content.type === 'native:self-paced'
  const isTemplate =
    (content.type === 'native:self-paced' || content.type === 'native:live') &&
    content.templateSettings !== undefined

  return (
    <>
      <View gap='8'>
        {isPinned && (
          <Tooltip title={t('workspace.create.pinned')}>
            <Icon iconId='pin--filled' color='foreground/muted' />
          </Tooltip>
        )}
        <TruncatedAutoWidth lines={2} size='small' bold>
          {content.title}
        </TruncatedAutoWidth>
      </View>
      <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6' grow>
        <NoWrapText color='foreground/muted'>
          <TeamspaceContentTypeName isTemplate={isTemplate} type={content.type} />
        </NoWrapText>
        <LastUpdateLabel lastEditorId={content.lastEditedBy} updatedAt={content.timestamp} />
        {isCourse && !isTemplate && (
          <CoursePublishStateLabel published={content.published} pending={content.pending} />
        )}
      </MetadataWrapper>
    </>
  )
}

const isCollabRoleAtLeast = (role: UserRole | undefined, compareTo: UserRole): boolean =>
  role !== undefined && isCollaboratorRoleAboveOrEqual(role, compareTo)

const Actions: React.FC<{
  content: EditableContent
  reloadContent: () => void
  link: string
  linkable: Linkable
  isPinned: boolean
  onDelete: () => void
}> = ({ content, reloadContent, link, linkable, isPinned, onDelete }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const duplicateEditableContentMutation = useDuplicateEditableContentMutation()
  const hasManageAccess = useHasManageAccess()

  const contentKindPermissions = useContentKindPermissions(content.id, { enabled: isMenuOpen })
  const hasEditAccess =
    content.highestCollaboratorRole === 'editor' || content.highestCollaboratorRole === 'owner'
  const isMoveableContent = content.type === 'native:live' || content.type === 'native:self-paced'
  const showItemMenuWithMoveTeamspaceOption = hasEditAccess && isMoveableContent

  const items: MenuItem[] = useMemo(() => {
    const roleIsAtLeastEditor = isCollabRoleAtLeast(content.highestCollaboratorRole, 'editor')
    const roleIsAtLeastCommenter = isCollabRoleAtLeast(content.highestCollaboratorRole, 'commenter')
    const isPath = content.type === 'path'
    const isCourse = content.type === 'native:self-paced'
    const isUnpublishedCourse = isCourse && !content.published

    return [
      {
        id: 'edit',
        type: 'label',
        label: t('dictionary.edit'),
        icon: 'edit',
        hidden: !roleIsAtLeastCommenter,
      },
      {
        id: 'view',
        type: 'label',
        label: t('dictionary.view'),
        icon: 'view',
        hidden: isUnpublishedCourse,
      },
      {
        id: 'duplicate',
        type: 'label',
        icon: 'duplicate',
        animateIcon: false,
        label: t('dictionary.duplicate'),
        hidden: isPath || !roleIsAtLeastEditor,
      },
      {
        id: 'manage',
        type: 'label',
        label: t('admin.author.manage'),
        icon: 'trend--up',
        hidden: !hasManageAccess || isUnpublishedCourse,
      },
      {
        id: 'settings',
        type: 'label',
        label: t('settings.settings'),
        icon: 'settings',
        hidden: !(content.type !== 'path' && roleIsAtLeastEditor),
        disabled: !contentKindPermissions.has('EDIT_METADATA'),
      },
      {
        id: 'pin',
        type: 'label',
        label: isPinned ? t('live.unpin') : t('dictionary.pin'),
        icon: 'pin',
        hidden: !roleIsAtLeastEditor,
      },
      {
        id: 'delete',
        type: 'label',
        label: t('dictionary.delete'),
        icon: 'trash-can',
        color: 'destructive/background',
        hidden: !roleIsAtLeastEditor,
        disabled: !contentKindPermissions.has('DELETE'),
      },
    ]
  }, [content, t, hasManageAccess, contentKindPermissions, isPinned])

  const handleMenuItemSelect = useCallback(
    (item: MenuItem) => {
      const addPin = async (): Promise<void> => {
        await postWithUserErrorException(XRealtimeUserAddPins, {
          items: [{ id: content.id, type: pinnableContentType(content.type) }],
        }).then(() => reloadContent())
      }

      const removePin = async (): Promise<void> => {
        await postWithUserErrorException(XRealtimeUserRemovePins, {
          items: [{ id: content.id, type: pinnableContentType(content.type) }],
        }).then(() => reloadContent())
      }

      switch (item.id) {
        case 'edit': {
          void getGlobalRouter().navigate({ to: editUrl(content.type, content.id) })
          break
        }
        case 'view': {
          void getGlobalRouter().navigate({ to: continueUrl(linkable) as string })
          break
        }
        case 'duplicate': {
          assert(content.type !== 'path', 'Can only duplicate courses')

          duplicateEditableContentMutation.mutate(
            {
              originalFlexibleContentId: content.id,
              title: t('content.copy-of-title', { title: content.title }),
            },
            {
              onSuccess: () => {
                reloadContent()
              },
            }
          )
          break
        }
        case 'manage': {
          void getGlobalRouter().navigate({ to: manageUrl(linkable) as string })
          break
        }
        case 'settings': {
          assert(content.type !== 'path', 'Can only edit settings for courses')

          void dispatch(settingsActions.fetch({ courseId: content.id })).then(() => {
            void dispatch(settingsActions.setView('course-settings'))
          })
          break
        }
        case 'pin': {
          if (isPinned) {
            void removePin()
          } else {
            void addPin()
          }
          break
        }
        case 'delete': {
          onDelete()
          break
        }
      }
    },
    [
      content.id,
      content.title,
      content.type,
      dispatch,
      duplicateEditableContentMutation,
      isPinned,
      linkable,
      onDelete,
      postWithUserErrorException,
      reloadContent,
      t,
    ]
  )

  const sharedMenuProps = {
    variant: 'transparent',
    color: 'foreground/muted',
    onSelect: handleMenuItemSelect,
    isOpen: isMenuOpen,
    onOpenChange: setIsMenuOpen,
  } satisfies Partial<IconMenuProps> & Partial<IconMenuWithMoveToTeamspacesProps>

  return (
    <>
      <CTAActions canEdit={true} content={content} linkable={linkable} link={link} />
      {!showItemMenuWithMoveTeamspaceOption ? (
        <IconMenu menuItems={items} iconId='overflow-menu--vertical' {...sharedMenuProps} />
      ) : (
        <IconMenuWithMoveToTeamspaces
          onMoveSuccess={reloadContent}
          defaultItems={items}
          content={content}
          {...sharedMenuProps}
        />
      )}
    </>
  )
}

const DeleteAlertModal: React.FC<{ content: EditableContent; onDeleted: () => void } & UseDeleteCourse> = ({
  content,
  onDeleted,
  remove,
  loading,
  showDeleteConfirm,
  closeDeleteConfirm,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()

  const deletePath = useCallback(
    async (deletePathId: PathId): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminPathsDeletePath, { pathId: deletePathId })
    },
    [postWithUserErrorException]
  )

  const assetContext = useMemo(() => getAssetContextFromEditableContent(content), [content])
  const thumbnailSrc = useResolveAsset({
    image: content.image,
    size: 'admin-sm',
    assetContext,
  })

  return (
    <ActionModal
      open={showDeleteConfirm}
      isLoading={loading}
      onClose={closeDeleteConfirm}
      primaryAction={async () => {
        if (content.type === 'path') {
          await deletePath(content.id)
          onDeleted()
        } else {
          void remove()
        }
      }}
      title={t('content.delete-headline')}
      primaryActionLabel={t('content.delete-button')}
      deleteAction
    >
      <View marginTop='8' marginBottom='8'>
        <Text size='regular'>{t('content.delete-confirm')}</Text>
      </View>
      <View marginBottom='32'>
        <CommonThumbnail image={thumbnailSrc} radius={0.5} width={2} height={2} />
        <Text size='regular' bold>
          {content.title}
        </Text>
      </View>
    </ActionModal>
  )
}

export const CreateContentTableRow: React.FC<{
  content: EditableContent
  isPinned: boolean
  onDeleted: () => void
  reloadContent: () => void
  isOver?: boolean
}> = ({ content, isPinned, onDeleted, reloadContent, isOver }) => {
  const dispatch = useDispatch()

  const deleteProps = useDeleteCourse({
    courseId: CourseId.parse(content.id),
    onDeleted,
  })

  const { highestCollaboratorRole } = content

  const roleIsAtLeastCommenter =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')

  const linkable: Linkable = useMemo(
    () => ({ id: content.id, type: content.type }),
    [content.id, content.type]
  )

  const assetContext = useMemo(() => getAssetContextFromEditableContent(content), [content])

  const { drag, isDragging, canDrag } = useContentDrag(content, assetContext)

  const onRowClick = useCallback(() => {
    if (content.teamspaceId !== undefined) {
      void dispatch(
        teamspaceContentClickedLogger({
          contentId: content.id,
          teamspaceId: content.teamspaceId,
          clickedFrom: 'page',
        })
      )
    }
  }, [content.id, content.teamspaceId, dispatch])

  const link = roleIsAtLeastCommenter ? editUrl(content.type, content.id) : continueUrl(linkable)

  const thumbnailSrc = useResolveAsset({
    image: content.image,
    assetContext,
    size: 'thumbnail',
  })

  return (
    <>
      <RowContainer
        // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
        ref={drag as (el: HTMLDivElement) => void}
        role='listitem'
        isDroppable={false}
        isOver={isOver ?? false}
        isDragging={isDragging}
      >
        <ContentTableRow
          href={link}
          onRowClick={onRowClick}
          isOver={isOver}
          canDrag={canDrag}
          left={<ContentDisplay isPinned={isPinned} content={content} />}
          thumbnail={<StyledThumbnail lazy={true} image={thumbnailSrc} />}
          right={<div></div>}
          actions={
            <Actions
              content={content}
              reloadContent={reloadContent}
              link={link}
              linkable={linkable}
              isPinned={isPinned}
              onDelete={deleteProps.openDeleteConfirm}
            />
          }
        />
        <DeleteAlertModal content={content} onDeleted={onDeleted} {...deleteProps} />
      </RowContainer>
    </>
  )
}
