import i18n from 'i18next'
import i18nResourcesToBackend from 'i18next-resources-to-backend'
import { Settings as LuxonSettings } from 'luxon'
import { initReactI18next } from 'react-i18next'
import { config } from 'sierra-client/config/global-config'
import { isSupportedAppLanguage } from 'sierra-client/i18n/languages.utils'

const DEFAULT_NAMESPACE = 'common'
let lng = config.organization.defaultLanguage ?? 'en'

const urlLocale = window.location.pathname.split('/')[1]

// Remove locale from URL, but use it as the initial language if it's valid. Needed for SCORM.
if (urlLocale !== undefined && isSupportedAppLanguage(urlLocale)) {
  const pathnameWithoutLocale = window.location.pathname.replace(`/${urlLocale}`, '')
  // console.log({ ...window.location })
  // console.log({ fullpathname: window.location.pathname, urlWithoutLocale: pathnameWithoutLocale })
  // const pathnameWithSearchQuery = pathnameWithoutLocale + window.location.search
  history.replaceState(null, '', pathnameWithoutLocale + window.location.search || '/')
  lng = urlLocale
}

document.documentElement.lang = lng
LuxonSettings.defaultLocale = lng

void i18n
  .use(initReactI18next)
  .use(
    i18nResourcesToBackend(
      (lang: string, namespace: string) => import(`sierra-client/assets/locales/${lang}/${namespace}.json`)
    )
  )
  .init({
    lng,
    fallbackLng: 'en',
    keySeparator: false,
    ns: DEFAULT_NAMESPACE,
    defaultNS: DEFAULT_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export const sierraI18n = i18n
