import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { layoutValues } from 'sierra-client/config/layout-values'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSiblings } from 'sierra-client/views/v3-author/hooks'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import {
  TakeawaysDataLayerProvider,
  useTakeawayDataLayer,
} from 'sierra-client/views/v3-author/takeaways/takeaways-data-layer'
import { color, deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { Text as SlateText } from 'slate'
import styled, { css } from 'styled-components'

const HoverIcon = styled(Icon)`
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
`

const TakeawayIndicatorBase = styled.div<{ showNumberIndication: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  background-color: none;
  border: 1px solid ${color(palette.primitives.black).opacity(0).toString()};
  ${p =>
    p.showNumberIndication &&
    css`
      &::after {
        transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
        font-size: 3rem;
        left: 2.75rem;
        position: absolute;
        color: ${p => deriveTheme(p.theme).textColor};
        counter-increment: takeaway-counter;
        content: counter(takeaway-counter);
      }
    `}

  ${HoverIcon} {
    color: ${p => deriveTheme(p.theme).textColor};
  }
`

const TakeawayBase = styled(motion.div)`
  will-change: transform;
  transform: translate3d(0, 0, 0);
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  gap: 2rem;
  padding: 2rem;

  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  background-color: ${p => color(deriveTheme(p.theme).textColor).opacity(0.15).toString()};
  margin-bottom: 1rem;
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    grid-column: 2 / span 11;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    grid-column: 3 / span 9;
  }

  @media screen and (min-width: ${layoutValues.LESSON_WIDTH}) {
    grid-column: 4 / span 8;
  }
`

const SingleTakeawayBase = styled(TakeawayBase)`
  ${TakeawayIndicatorBase} {
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    border: 1px solid ${color(palette.primitives.black).opacity(0.1).toString()};
    cursor: pointer;
  }

  &:hover {
    ${TakeawayIndicatorBase} {
      transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
      border: 1px solid ${color(palette.primitives.black).opacity(0.5).toString()};

      &::after {
        transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0;
      }
    }
  }
`

const MultipleTakeawayBase = styled(TakeawayBase)`
  &:hover {
    ${TakeawayIndicatorBase} {
      transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
      border: 1px solid ${color(palette.primitives.black).opacity(0.1).toString()};

      &::after {
        transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0;
      }
    }

    ${HoverIcon} {
      transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 1;
    }
  }
`

const TakeawaysWrapper = styled.div`
  padding: 0;
  counter-reset: takeaway-counter 0;
`

export const Takeaways: SlateFC = ({ children, element, mode }) => {
  assertElementType('takeaways', element)

  return (
    <>
      <TextToSpeech element={element} />
      <TakeawaysWrapper data-block-inner={element.id}>
        <TakeawaysDataLayerProvider element={element} mode={mode}>
          {children}
        </TakeawaysDataLayerProvider>
      </TakeawaysWrapper>
    </>
  )
}

export const Wrapper = styled.div`
  position: relative;
`

const Placeholder = styled(Text).attrs({ contentEditable: false, black: true })`
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.5;
`

const TakeawayInfoBody = styled.div<{ $readOnly: boolean; $active: boolean | undefined }>`
  position: relative;
  align-self: center;
  color: ${p => deriveTheme(p.theme).textColor};
  pointer-events: ${p => (p.$readOnly && !(p.$active === true) ? 'none' : 'auto')};

  cursor: text;

  ${p =>
    p.$active === true &&
    `
    p {
      color: ${palette.primitives.black};
    }
    span {
      color: ${palette.primitives.black};
    }
  `}
`

const MultipleLessonTakeaway = styled(MultipleTakeawayBase)<{ $active: boolean | undefined }>`
  background-color: ${p => color(deriveTheme(p.theme).textColor).opacity(0.15).toString()};
  cursor: pointer;

  ${p =>
    p.$active === true &&
    `
      background-color: ${palette.primitives.white};
      cursor: unset;
  `}
`

const SingleLessonTakeaway = styled(SingleTakeawayBase)<{ $active: boolean | undefined }>`
  background-color: ${p => color(deriveTheme(p.theme).textColor).opacity(0.15).toString()};
  cursor: pointer;

  ${p =>
    p.$active === true &&
    css`
      background-color: ${palette.primitives.white};
      cursor: unset;
    `}
`

const LessonTakeawayIndicator = styled(TakeawayIndicatorBase)<{
  $active: boolean | undefined
  showNumberIndication: boolean
}>`
  background-color: ${p => (p.$active === true ? p.theme.color.greenBright : 'none')};

  cursor: unset;

  ${p =>
    p.showNumberIndication &&
    css`
      &::after {
        transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
        font-size: 3rem;
        left: 2.75rem;
        position: absolute;
        color: ${p => deriveTheme(p.theme).textColor};
        counter-increment: takeaway-counter;
        content: counter(takeaway-counter);
        opacity: ${p.$active === true ? '0' : '1'};
      }
    `}

  ${p =>
    p.$active === true &&
    css`
      border: none !important;
    `}
`

export const TakeawaysItem: SlateFC = ({ children, element, readOnly, mode }) => {
  if (element.type !== 'takeaway-item')
    throw new Error(`Expected to find a key takeaway, but instead got a ${JSON.stringify(element)}`)

  const blockId = element.id
  const { t } = useTranslation()
  const isEmpty = element.children.every(it => SlateText.isText(it) && it.text === '')
  const placeholder = isEmpty ? t('content.takeaways.add-item') : undefined
  const { id } = element
  const dataLayer = useTakeawayDataLayer()
  const [isCompleted, setIsCompleted] = useState(dataLayer?.hasPreviousInteractions() ?? false)
  const siblings = useSiblings({ nodeId: id })
  const showNumberIndication = siblings.length > 1

  const handleItemClick = useCallback(() => {
    if (dataLayer !== undefined) {
      dataLayer.submitInteraction(blockId)
    }

    setIsCompleted(true)
  }, [dataLayer, blockId])

  return (
    <RenderingContext withGrid={false} disableMenu={true} allowBlockComments={false} preventDrag={true}>
      {
        //  We specifically want `mode === 'create'` here and not `!readOnly` in order to support commenting
        // on takeaway blocks for users who have comment permission but not edit permissions.
        mode === 'create' ? (
          // In editor
          showNumberIndication ? (
            <MultipleTakeawayBase key={id} data-block-inner={element.id}>
              <TakeawayIndicatorBase contentEditable={false} showNumberIndication={showNumberIndication}>
                <HoverIcon iconId='checkmark' size='size-16' />
              </TakeawayIndicatorBase>
              <TakeawayInfoBody $readOnly={readOnly} $active={isCompleted}>
                {children}
                {placeholder !== undefined && (
                  <Placeholder color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
                    {placeholder}
                  </Placeholder>
                )}
              </TakeawayInfoBody>
            </MultipleTakeawayBase>
          ) : (
            <SingleTakeawayBase key={id} data-block-inner={element.id}>
              <TakeawayIndicatorBase contentEditable={false} showNumberIndication={showNumberIndication}>
                <Icon iconId='checkmark' size='size-16' />
              </TakeawayIndicatorBase>
              <TakeawayInfoBody $readOnly={readOnly} $active={isCompleted}>
                {children}
                {placeholder !== undefined && (
                  <Placeholder color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
                    {placeholder}
                  </Placeholder>
                )}
              </TakeawayInfoBody>
            </SingleTakeawayBase>
          )
        ) : // In content
        showNumberIndication ? (
          <MultipleLessonTakeaway
            contentEditable={false}
            $active={isCompleted}
            onClick={handleItemClick}
            whileHover={{ scale: isCompleted === true ? 1 : 1.01 }}
            whileTap={{ scale: isCompleted === true ? 1 : 0.99 }}
            role='button'
            aria-label='Takeaway'
          >
            <LessonTakeawayIndicator $active={isCompleted} showNumberIndication={showNumberIndication}>
              {isCompleted ? (
                <Icon color='white' iconId='checkmark' size='size-16' />
              ) : (
                <HoverIcon color='black' iconId='checkmark' size='size-16' />
              )}
            </LessonTakeawayIndicator>
            <TakeawayInfoBody $readOnly={readOnly} $active={isCompleted}>
              {isCompleted === true ? (
                <>{children}</>
              ) : (
                <View direction='column' gap='none'>
                  {children}
                  <Text color='foreground/muted' size='micro'>
                    {t('author.slate.takeaways-click-to-complete')}
                  </Text>
                </View>
              )}
            </TakeawayInfoBody>
          </MultipleLessonTakeaway>
        ) : (
          <SingleLessonTakeaway
            contentEditable={false}
            $active={isCompleted}
            onClick={handleItemClick}
            whileHover={{ scale: isCompleted === true ? 1 : 1.01 }}
            whileTap={{ scale: isCompleted === true ? 1 : 0.99 }}
            role='button'
            aria-label='Takeaway'
          >
            <LessonTakeawayIndicator $active={isCompleted} showNumberIndication={showNumberIndication}>
              {isCompleted ? (
                <Icon color='white' iconId='checkmark' size='size-16' />
              ) : (
                <Icon color='black' iconId='checkmark' size='size-16' />
              )}
            </LessonTakeawayIndicator>
            <TakeawayInfoBody $readOnly={readOnly} $active={isCompleted}>
              {isCompleted === true ? (
                <>{children}</>
              ) : (
                <View direction='column' gap='none'>
                  {children}
                  <Text color='foreground/muted' size='micro'>
                    {t('author.slate.takeaways-click-to-complete')}
                  </Text>
                </View>
              )}
            </TakeawayInfoBody>
          </SingleLessonTakeaway>
        )
      }
    </RenderingContext>
  )
}
