import { getFlag } from 'sierra-client/config/global-config'
import { MediaUploader } from 'sierra-client/views/v3-author/common/media-uploader/media-uploader'
import { deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { TextAreaPrimitive } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

export const MediaUploadWrapper = styled.div<{ $selected: boolean }>`
  height: 400px;
  background-color: ${p => deriveTheme(p.theme).secondaryBackgroundColor};
  color: ${p => deriveTheme(p.theme).textColor};
  overflow: hidden;
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};

  ${p =>
    p.$selected &&
    css`
      box-shadow: 0 0 0 3px #b4d5ff;
    `}

  display: flex;
  flex-direction: column;
`

export const MediaUploadContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`

export const Trash = styled(Icon).attrs({ iconId: 'trash-can', size: 'size-16', color: 'currentColor' })`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
`

export const Upload = styled(MediaUploader)`
  flex: 1;
  width: 100%;
` as typeof MediaUploader

export const CreditInput = styled(TextAreaPrimitive).attrs({ rows: 1, resize: 'none', autoExpand: true })<{
  disabled?: boolean
}>`
  width: 100%;
  display: block;
  font-size: 0.875rem;
  margin: 2px auto 0;
  color: ${p => deriveTheme(p.theme).textColor};
  background-color: ${p => deriveTheme(p.theme).backgroundColor};
  outline: none;

  &:hover {
    outline: 1px solid
      ${p => (p.disabled !== true ? `${deriveTheme(p.theme).secondaryTextColor}` : 'transparent')};
  }

  &:focus {
    outline: 1px solid ${p => deriveTheme(p.theme).textColor};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${p => deriveTheme(p.theme).secondaryTextColor};
  }

  &[disabled],
  &[aria-disabled='true'] {
    background-color: ${p => deriveTheme(p.theme).backgroundColor};
    color: ${p => deriveTheme(p.theme).textColor};
  }
`
