import { slatePointToRelativePosition, YjsEditor } from '@slate-yjs/core'
import _ from 'lodash'
import { postZod } from 'sierra-client/api'
import { ReadOnlyYjsEditor } from 'sierra-client/views/v3-author/collaboration/with-read-only-yjs-editor'
import { ModifyDocumentAttributesRequest } from 'sierra-domain/api/common'
import { toBase64 } from 'sierra-domain/base-64'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeCollaborationModifyDocumentAttributes } from 'sierra-domain/routes'
import { approximatelyEquals, approximatelyLessThanOrEquals, serializeDomRect } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { BaseRange, Editor, Node, Path, Point, Range, Text } from 'slate'
import { ReactEditor } from 'slate-react'
import * as Y from 'yjs'
import { z } from 'zod'
import DOMRange = globalThis.Range

/*
 * Deep equality on two DOMRect instances
 */
export function compareDOMRects(first: DOMRect, second: DOMRect): boolean {
  return _.isEqual(serializeDomRect(first), serializeDomRect(second))
}

/**
 * Convert a Slate range to a DOM range.
 */
export function rangeToDOMRange(editor: SanaEditor, range: BaseRange): DOMRange | undefined {
  if (Range.isCollapsed(range)) return undefined

  try {
    return ReactEditor.toDOMRange(editor, range)
  } catch (e) {
    return undefined
  }
}

export function getLastRectInSlateRange(editor: SanaEditor, range: BaseRange): DOMRect | undefined {
  const domRange = rangeToDOMRange(editor, range)

  if (domRange === undefined) return undefined

  const clientRects = domRange.getClientRects()

  return clientRects[clientRects.length - 1]
}

/*
 * Create a collection of DOMRect given a slate range
 */
function rangeToRectanglesUnsafe(editor: SanaEditor, range: BaseRange): DOMRect[] {
  if (Range.isCollapsed(range)) return []

  const [start, end] = Range.edges(range)
  const domRange = ReactEditor.toDOMRange(editor, range)

  const rectangles: DOMRect[] = []
  const nodeIterator = Editor.nodes(editor, { at: range, match: Text.isText })

  for (const [node, path] of nodeIterator) {
    const domNode = ReactEditor.toDOMNode(editor, node)

    const isStartNode = Path.equals(path, start.path)
    const isEndNode = Path.equals(path, end.path)

    let clientRects: DOMRectList | null = null

    if (isStartNode || isEndNode) {
      const nodeRange = document.createRange()
      nodeRange.selectNode(domNode)

      if (isStartNode) {
        nodeRange.setStart(domRange.startContainer, domRange.startOffset)
      }
      if (isEndNode) {
        nodeRange.setEnd(domRange.endContainer, domRange.endOffset)
      }

      clientRects = nodeRange.getClientRects()
    } else {
      clientRects = domNode.getClientRects()
    }

    for (const clientRect of clientRects) {
      rectangles.push(clientRect)
    }
  }

  return rectangles
}

function rangeToRectangles(editor: SanaEditor, range: BaseRange): DOMRect[] {
  try {
    return rangeToRectanglesUnsafe(editor, range)
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * Translate the coordinates of a DOMRect inside another DOMRect.
 *
 * Both input rects are expected to be relative to the viewport.
 *
 * The result is a rect that is relative to the outer rect.
 */
export function calculateRelativeRect(inner: DOMRect, outer: DOMRect): DOMRect {
  return DOMRect.fromRect({
    x: inner.x - outer.left,
    y: inner.y - outer.top,
    width: inner.width,
    height: inner.height,
  })
}

/**
 * Merge DOMRects that are adjacent and have the same top and bottom coordinates. If we don't
 * merge rectangles we can end up with several rectangles for the same line that slightly
 * overlap and look out of place.
 */
function mergeRects(rects: DOMRect[]): DOMRect[] {
  const sorted = _.sortBy(
    rects,
    rect => _.round(rect.top, 1),
    rect => rect.left
  )

  let prev = sorted[0]

  if (prev === undefined) return []

  const mergedRects: DOMRect[] = []
  for (const curr of sorted.slice(1)) {
    if (
      // Check if top and bottom coordinates are approximately equal
      approximatelyEquals(curr.top, prev.top) &&
      approximatelyEquals(curr.bottom, prev.bottom) &&
      // Check horizontal overlap
      approximatelyLessThanOrEquals(curr.left, prev.right)
    ) {
      // We've sorted the rects so that curr.left >= prev.left, so
      // we don't have to check that.
      prev = DOMRect.fromRect({
        x: prev.x,
        y: prev.y,
        width: curr.right - prev.left,
        height: prev.height,
      })
    } else {
      mergedRects.push(prev)
      prev = curr
    }
  }

  mergedRects.push(prev)

  return mergedRects
}

/**
 * Returns merged DOMRects that represent the given Slate range using coordinates relative
 * to the given container.
 */
export function rangeToMergedRelativeRectangles({
  editor,
  containerRect,
  range,
}: {
  editor: SanaEditor
  containerRect: DOMRect
  range: Range
}): DOMRect[] {
  const viewportRectangles = rangeToRectangles(editor, range)
  const relativeRectangles = viewportRectangles.map(rect => calculateRelativeRect(rect, containerRect))

  return mergeRects(relativeRectangles)
}

// ---

function getRangeKeys(key: string): { anchor: string; focus: string } {
  return {
    anchor: `${key}_anchor`,
    focus: `${key}_focus`,
  }
}

export const getRelativeRange = (
  editor: YjsEditor | ReadOnlyYjsEditor,
  key: string
): BaseRange | undefined => {
  const rangeKeys = getRangeKeys(key)

  try {
    const anchor = YjsEditor.position(editor as YjsEditor, rangeKeys.anchor) ?? undefined
    const focus = YjsEditor.position(editor as YjsEditor, rangeKeys.focus) ?? undefined

    if (anchor === undefined || focus === undefined) {
      return undefined
    }

    return {
      anchor,
      focus,
    }
  } catch (e) {
    console.warn('[getRelativeRange] Failed to resolve range.')
    return undefined
  }
}

function prefixPositionAttributeKey(key: string): string {
  /**
   * @slate-yjs/core uses a special prefix to know what attributes are stored positions
   * https://github.com/BitPhinix/slate-yjs/blob/main/packages/core/src/utils/position.ts#L8
   */
  const SLATEYJS_POSITION_PREFIX = '__slateYjsStoredPosition_'

  return `${SLATEYJS_POSITION_PREFIX}${key}`
}

function encodePoint(editor: YjsEditor | ReadOnlyYjsEditor, point: Point): Uint8Array {
  const relativePosition = slatePointToRelativePosition(editor.sharedRoot, editor as Node, point)
  return Y.encodeRelativePosition(relativePosition)
}

type StoreRelativeRangeLocalInput = {
  editor: YjsEditor
  key: string
  range: BaseRange
}

export function storeRelativeRangeLocal({ editor, key, range }: StoreRelativeRangeLocalInput): void {
  if (editor.sharedRoot instanceof Y.Array) {
    throw Error('Stored positions are not supported in Y.Array')
  }

  const rangeKeys = getRangeKeys(key)

  YjsEditor.storePosition(editor, rangeKeys.anchor, range.anchor)
  YjsEditor.storePosition(editor, rangeKeys.focus, range.focus)
}

type RemoveRelativeRangeLocalInput = {
  editor: YjsEditor
  key: string
}

export function removeRelativeRangeLocal({ editor, key }: RemoveRelativeRangeLocalInput): void {
  if (editor.sharedRoot instanceof Y.Array) {
    throw Error('Stored positions are not supported in Y.Array')
  }

  const rangeKeys = getRangeKeys(key)

  YjsEditor.removeStoredPosition(editor, rangeKeys.anchor)
  YjsEditor.removeStoredPosition(editor, rangeKeys.focus)
}

type StoreRelativeRangeRemoteInput = {
  editor: YjsEditor | ReadOnlyYjsEditor
  yDocId: string
  fileId: string
  key: string
  range: BaseRange
}

export async function storeRelativeRangeRemote({
  yDocId,
  editor,
  fileId,
  key,
  range,
}: StoreRelativeRangeRemoteInput): Promise<void> {
  return new Promise(resolve => {
    if (editor.sharedRoot instanceof Y.Array) {
      throw Error('Stored positions are not supported in Y.Array')
    }

    const anchorAttributeKey = prefixPositionAttributeKey(`${key}_anchor`)
    const focusAttributeKey = prefixPositionAttributeKey(`${key}_focus`)

    // Wait for the attribute changes to propagate
    const onChange = (event: Y.YTextEvent): void => {
      if ([anchorAttributeKey, focusAttributeKey].every(k => event.keysChanged.has(k))) {
        editor.sharedRoot.unobserve(onChange)
        resolve()
      }
    }
    editor.sharedRoot.observe(onChange)

    const payload: z.infer<typeof ModifyDocumentAttributesRequest> = {
      yDocId,
      slateDocumentId: fileId,
      operations: [
        {
          type: 'insert',
          key: anchorAttributeKey,
          value: {
            type: 'position',
            data: toBase64(encodePoint(editor, range.anchor)),
          },
        },
        {
          type: 'insert',
          key: focusAttributeKey,
          value: {
            type: 'position',
            data: toBase64(encodePoint(editor, range.focus)),
          },
        },
      ],
    }

    postZod(XRealtimeCollaborationModifyDocumentAttributes, payload).catch(error => {
      console.error('Commenting@storeRelativeRange', error)
    })
  })
}

type RemoveRelativeRangeRemoteInput = {
  yDocId: string
  fileId: string
  key: string
}

export async function removeRelativeRangeRemote({
  yDocId,
  fileId,
  key,
}: RemoveRelativeRangeRemoteInput): Promise<void> {
  const rangeKeys = getRangeKeys(key)

  const payload: z.infer<typeof ModifyDocumentAttributesRequest> = {
    yDocId,
    slateDocumentId: fileId,
    operations: [
      {
        type: 'remove',
        key: prefixPositionAttributeKey(rangeKeys.anchor),
      },
      {
        type: 'remove',
        key: prefixPositionAttributeKey(rangeKeys.focus),
      },
    ],
  }

  try {
    await postZod(XRealtimeCollaborationModifyDocumentAttributes, payload)
  } catch (error) {
    console.error('Commenting@storeRelativeRange', error)
  }
}

export function rangeEnclosesOtherRange(outer: BaseRange, inner: BaseRange): boolean {
  const [outerStart, outerEnd] = Range.edges(outer)
  const [innerStart, innerEnd] = Range.edges(inner)

  if (Point.isAfter(outerStart, innerStart) || Point.isBefore(outerEnd, innerEnd)) {
    return false
  }

  return true
}

export function getRangeTextPreview(editor: SanaEditor, range: BaseRange): string | undefined {
  const [start, end] = Range.edges(range)

  const textEntries = Editor.nodes(editor, {
    match: node => Text.isText(node),
    at: range,
  })

  const nodePreviews = Array.from(textEntries).flatMap(([node]) => {
    return Text.isText(node) && node.text !== '' ? [node.text] : []
  })

  return nodePreviews.join(' ').slice(start.offset, end.offset).slice(0, 60)
}

/*
 * Construct a deep link to a chat message
 */
type DeeplinkConstructor = (args: {
  contentId: ScopedCreateContentId
  unitId: string
  messageId: string
}) => string

export const buildDeeplink: DeeplinkConstructor = ({ contentId, unitId, messageId }) => {
  const scope = ScopedCreateContentId.urlSubPathForId(contentId)

  return `/create/${scope}/${ScopedCreateContentId.extractId(contentId)}/${unitId}?chat_message=${messageId}`
}
