import log from 'loglevel'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { PageInstance, makePageInstance } from 'sierra-client/core/logging/page/types'
import { courseSelectors, selectCurrentPath } from 'sierra-client/state/content/selectors'
import { Category } from 'sierra-client/state/logging/types'
import { RootState } from 'sierra-client/state/types'
import {
  CourseId as CourseNanoId,
  LiveContentId,
  PathId,
  SelfPacedContentId,
} from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

const pageWithProps = <P extends Record<string, string>>(
  category: Category,
  id: string,
  userIdRequired = true
): ((props: P) => PageInstance<P, P>) => {
  return props => makePageInstance({ category, id, props, enrich: input => input, userIdRequired })
}

const pageWithoutProps = (
  category: Category,
  id: string,
  userIdRequired = true
): (() => PageInstance<Record<string, never>, Record<string, never>>) => {
  return () => makePageInstance({ category, id, props: {}, enrich: () => ({}), userIdRequired })
}

const pageWithPropsAndExtra = <
  InputArgs extends Record<string, string>,
  OutputArgs extends Record<string, string>,
>(
  category: Category,
  id: string,
  enrich: (input: InputArgs, state: RootState) => OutputArgs,
  userIdRequired = true
): ((props: InputArgs) => PageInstance<InputArgs, OutputArgs>) => {
  return props => makePageInstance({ category, id, props, enrich, userIdRequired })
}

export type PathIdObject = {
  pathId: PathId
}
export type CourseId = {
  courseId: CourseNanoId
}
export type ProgramId = {
  programId: string
}
export type LiveSessionId = CourseId & {
  liveSessionId: string
}
export type EventGroupId = {
  eventGroupId: string
}

type PathMetadata = {
  pathTitle?: string
}
type CourseMetadata = {
  courseTitle?: string
}

export const Error = pageWithoutProps(Category.other, 'error', false)

export const LearnerNewHome = pageWithoutProps(Category.learner, 'new-home')
export const LearnerCertificate = pageWithPropsAndExtra<CourseId, CourseId & CourseMetadata>(
  Category.learner,
  'certificate',
  (input, state) => {
    const course = courseSelectors.selectById(state, input.courseId)

    if (course === undefined) {
      log.warn('course not found')
    }

    return {
      ...input,
      courseTitle: course?.settings.title,
    }
  }
)
export const LearnerPath = pageWithPropsAndExtra<PathIdObject, PathIdObject & PathMetadata>(
  Category.learner,
  'path',
  (input, state) => {
    const path = selectCurrentPath(state)

    return {
      ...input,
      pathTitle: path?.path.title,
    }
  }
)
export const LearnerCourse = pageWithPropsAndExtra<CourseId, CourseId & CourseMetadata>(
  Category.learner,
  'course',
  (input, state) => {
    const course = courseSelectors.selectById(state, input.courseId)
    return {
      ...input,
      courseTitle: course?.settings.title,
    }
  }
)
export const LearnerSelfPaced = pageWithPropsAndExtra<CourseId, CourseId & CourseMetadata>(
  Category.learner,
  'self-paced',
  (input, state) => {
    const course = courseSelectors.selectById(state, input.courseId)
    return {
      ...input,
      courseTitle: course?.settings.title,
    }
  }
)

export const LearnerSelfPacedLesson = pageWithProps<{
  courseId: string
  fileId?: FileId | 'next'
}>(Category.learner, 'self-paced-lesson')

export const LearnerSelectEditionPage = pageWithProps<{
  courseGroupId?: string
}>(Category.learner, 'select-edition')

export const LiveSessionPage = pageWithPropsAndExtra<
  {
    liveSessionId?: string
    sessionTitle?: string
  },
  {
    liveSessionId?: string
    sessionTitle?: string
  }
>(Category.learner, 'live-session', ({ liveSessionId, sessionTitle }) => {
  return { liveSessionId, sessionTitle }
})

export const SkillDetail = pageWithProps<{
  skillId: SkillId
}>(Category.other, 'skill-detail')

export const LiveSessionRecapPage = pageWithPropsAndExtra<
  {
    liveSessionId: string
    sessionTitle?: string
  },
  {
    liveSessionId: string
    sessionTitle?: string
  }
>(Category.learner, 'live-session-recap', ({ liveSessionId, sessionTitle }) => {
  return { liveSessionId, sessionTitle }
})

export const LearnerEventGroupPage = pageWithPropsAndExtra<EventGroupId, EventGroupId>(
  Category.learner,
  'event-group-page',
  input => input
)

export const LearnerEventSelfCheckInPage = pageWithPropsAndExtra<
  { eventGroupId: string; eventId: string },
  { eventGroupId: string; eventId: string }
>(Category.learner, 'event-self-check-in-page', input => input)

export const AuthenticationLogin = pageWithoutProps(Category.authentication, 'login', false)

export const Authenticate = pageWithoutProps(Category.authentication, 'authenticate', false)

export const CreateFlexibleContent = pageWithProps<{
  flexibleContentId: LiveContentId
  nodeId?: string
}>(Category.authoring, 'flexible-content')

export const CreateSelfPacedContent = pageWithProps<{
  selfPacedContentId: SelfPacedContentId
  nodeId?: string
}>(Category.authoring, 'self-paced-content-id')

export const NewLearnPage = pageWithoutProps(Category.learner, 'new-learn-page')
export const NewCreatePage = pageWithoutProps(Category.learner, 'new-create-page')

export const FourZeroFour = pageWithoutProps(Category.other, '404')

export const Empty = pageWithProps<{
  message: string
}>(Category.other, `Empty page`, false)

export const Manage = pageWithProps<{
  name?: string
}>(Category.manage, 'manage-page')

export const ManageOverview = pageWithoutProps(Category.manage, 'overview')

export const ManageReports = pageWithoutProps(Category.manage, 'reports')
export const ManageUserDetail = pageWithProps<{
  userId: string
}>(Category.manage, 'user-detail')
export const ManageUsers = pageWithoutProps(Category.manage, 'users')
export const ManageCourseDetail = pageWithProps<{
  courseId: string
}>(Category.manage, 'course-detail')
export const ManagePathDetail = pageWithProps<{
  pathId: PathId
}>(Category.manage, 'path-detail')
export const ManageProgramDetail = pageWithProps<{
  programId: string
}>(Category.manage, 'program-detail')
export const ManagePrograms = pageWithoutProps(Category.manage, 'programs')
export const ManageCourseGroupDetail = pageWithProps<{
  courseGroupId: string
}>(Category.manage, 'course-group-detail')
export const ManageLiveSessionDetail = pageWithProps<{
  liveSessionId: string
}>(Category.manage, 'live-session-detail')
export const ManageCertificateDetail = pageWithProps<{
  certificateId: string
}>(Category.manage, 'certificate-detail')
export const ManageCertificates = pageWithoutProps(Category.manage, 'certificates')
export const ManageContent = pageWithoutProps(Category.manage, 'content')

export const ManageTagDetail = pageWithProps<{
  tagId: string
}>(Category.manage, 'tag-detail')
export const ManageTags = pageWithoutProps(Category.manage, 'tags')
export const ManageSkills = pageWithoutProps(Category.manage, 'skills')
export const ManageRoleDetail = pageWithProps<{
  roleId: string
}>(Category.manage, 'role-detail')
export const ManageSettings = pageWithoutProps(Category.manage, 'settings')
export const ManageInsights = pageWithoutProps(Category.manage, 'insights')
export const ManageInsightsDashboard = pageWithProps<{
  dashboardId: string
}>(Category.manage, 'insights-dashboard')

export const ManageInsightsDashboardTemplates = pageWithProps<{
  templateId: string
}>(Category.manage, 'insights-dashboard-template')

export const ManageHomeworks = pageWithoutProps(Category.manage, 'homeworks')
export const ManageHomeworkDetail = pageWithProps<{
  homeworkId: string
}>(Category.manage, 'homework-detail')
export const ManageApiClientDetail = pageWithProps<{
  apiClientId: string
}>(Category.manage, 'api-client-detail')
export const ManageApiClients = pageWithoutProps(Category.manage, 'api-clients')
export const ManageApiNewClient = pageWithoutProps(Category.manage, 'api-new-client')
export const ManageXApiNewServer = pageWithoutProps(Category.manage, 'xapi-new-server')
export const ManageXApiServerDetail = pageWithProps<{
  xApiServerId: string
}>(Category.manage, 'xapi-server-detail')
export const ManageUserGroupDetail = pageWithProps<{
  userGroupId: string
}>(Category.manage, 'user-group-detail')
export const ManageUserGroups = pageWithoutProps(Category.manage, 'user-groups')
export const ManageEventGroups = pageWithoutProps(Category.manage, 'event-groups')
export const ManageEventGroupDetail = pageWithProps<{
  eventGroupId: string
}>(Category.manage, 'event-group-detail')
export const ManageCalendarEventDetail = pageWithProps<{
  eventGroupId: string
  calendarEventId: string
}>(Category.manage, 'calendar-event-detail')

export const ManageContentAttributes = pageWithoutProps(Category.manage, 'content-attributes')

export const LearnerProgram = pageWithProps<ProgramId>(Category.learner, 'program')
