import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { ClosePanelButton, Panel } from 'sierra-ui/components'
import { Heading, View } from 'sierra-ui/primitives'

export const UpsertAttributeSidePanel: FCC<{
  title: string
  onClose: () => void
  isOpen: boolean
}> = ({ onClose, isOpen, title, children }) => {
  const { t } = useTranslation()

  return (
    <Panel
      size={{ width: 650 }}
      animation='slide-right'
      onClose={onClose}
      open={isOpen}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={onClose} ariaLabel={t('dictionary.close')} />
      <View paddingTop='32' paddingLeft='40' paddingRight='40'>
        <Heading size='h5'>{title}</Heading>
      </View>
      {children}
    </Panel>
  )
}
