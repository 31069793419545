import React from 'react'
import { DataViewer } from 'sierra-client/features/insights/display-widgets/data-viewers/data-viewer'
import {
  WidgetBuilderState,
  buildWidgetBuilderStateFromWidget,
} from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { getHardcodedTemplates } from 'sierra-client/features/insights/widget-templates/hardcoded-templates'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Filter } from 'sierra-client/lib/filter'
import { Dimension, Widget } from 'sierra-domain/api/insights'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { WidgetSortHandler } from './types'

const DataViewerContainer = styled(View).attrs({ direction: 'column', alignItems: 'stretch' })<{
  $widgetHeight: number | undefined
}>`
  flex: ${p => (p.$widgetHeight === undefined ? '1' : `0 0 ${p.$widgetHeight}px`)};
  max-width: 100%;
`

const StyledButton = styled(Button).attrs({ variant: 'secondary' })`
  color: ${token('foreground/secondary')};
`

export const WidgetBuilderDataViewer: React.FC<{
  widget?: Widget
  onDrillDown: (filter: Filter, newDimension: Dimension) => void
  onSetFilter: (filter: Filter) => void
  onSort: WidgetSortHandler
  onSetLimit: (limit?: number) => void
  setWidget: (widget: WidgetBuilderState) => void
  setTitle: (title: string) => void
  dashboardFilter?: Filter
}> = ({ onSetFilter, onDrillDown, onSort, widget, onSetLimit, setWidget, setTitle, dashboardFilter }) => {
  const { t } = useTranslation()

  const widgetHasAllRequiredFields = widget !== undefined

  const hardcodedTemplates = getHardcodedTemplates(t)

  return (
    <DataViewerContainer $widgetHeight={undefined}>
      {widgetHasAllRequiredFields ? (
        <DataViewer
          widget={widget}
          onDrillDown={onDrillDown}
          onFilterOut={onSetFilter}
          onSort={onSort}
          onSetLimit={onSetLimit}
          previewMode={true}
          dashboardFilter={dashboardFilter}
        />
      ) : (
        <View direction='column' justifyContent='center' alignItems='center' grow>
          <Icon iconId='chart--column' size='size-16' color='foreground/muted' />
          <Text color='foreground/muted' bold align='center'>
            {t('manage.insights.widgetbuilder.preview.start.header')}
          </Text>
          <Text color='foreground/muted' align='center'>
            {t('manage.insights.widgetbuilder.preview.start.text')}
          </Text>
          <View wrap='wrap' paddingTop={'16'}>
            {hardcodedTemplates.map(template => (
              <StyledButton
                key={template.widget.title}
                onClick={() => {
                  setWidget(buildWidgetBuilderStateFromWidget(template.widget.widget))
                  setTitle(template.widget.title)
                }}
              >
                {template.widget.title}
              </StyledButton>
            ))}
          </View>
        </View>
      )}
    </DataViewerContainer>
  )
}
