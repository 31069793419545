import { useState } from 'react'
import { TagInput } from 'sierra-client/components/tags/tag-input'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedInvalidateQuery } from 'sierra-client/state/api'
import { CreateTagModal } from 'sierra-client/views/manage/tags/create-tag-modal'
import { TagId } from 'sierra-domain/api/nano-id'
import { XRealtimeContentListTags } from 'sierra-domain/routes'
import { InputGroup } from 'sierra-ui/components'
import { Spacer, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const CreateTagButton = styled(Text).attrs({
  as: 'button',
  color: 'foreground/secondary',
  size: 'micro',
})`
  align-self: flex-start;
  background: none;
  cursor: pointer;
  transition: color 200ms;
  margin: 0;
  padding: 0;

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const TagsInput: React.FC<{
  initialSelectionTagIds: TagId[]
  onChange: (_: TagId[]) => void
}> = ({ initialSelectionTagIds, onChange }) => {
  const { t } = useTranslation()

  const [openCreateTagModal, setOpenCreateTagModal] = useState(false)

  const [selectedTagIds, _setSelectedTagIds] = useState<TagId[]>(initialSelectionTagIds)
  const setSelectedTagIds = (tagIds: TagId[]): void => {
    _setSelectedTagIds(tagIds)
    onChange(tagIds)
  }

  return (
    <>
      <InputGroup $spacing='none'>
        <TagInput currentTags={selectedTagIds} onTagChange={setSelectedTagIds} disabled={false} />
      </InputGroup>
      <Spacer size='8' />
      <CreateTagButton
        onClick={() => {
          setOpenCreateTagModal(true)
        }}
      >
        {t('course-settings.create-skill')}
      </CreateTagButton>
      <CreateTagModal
        open={openCreateTagModal}
        onClose={() => setOpenCreateTagModal(false)}
        onAfterSave={newTagId => {
          setSelectedTagIds([...selectedTagIds, newTagId])
          setOpenCreateTagModal(false)

          void typedInvalidateQuery(XRealtimeContentListTags, {})
        }}
      />
    </>
  )
}
