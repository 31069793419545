import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

export const TabRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${spacing['24']} ${spacing['32']} ${spacing['16']};
`

export const BottomContainer = styled.div`
  flex: 1;
  display: flex;

  > * {
    flex: 1;
  }
`

export const ExistingAssignedUsersContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  max-height: 330px;
  overflow-x: visible;
  overflow-y: hidden;

  padding-right: 24px;
  margin-right: -24px;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`
