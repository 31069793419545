import { motion } from 'framer-motion'
import React from 'react'
import { useTemplatePicker } from 'sierra-client/components/templates'
import { iconVariants, textVariants } from 'sierra-client/features/collapsable-sidebar/animation-config'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  ButtonStyles,
  ButtonStylesProps,
  usePrimitiveButtonPseudoStyles,
} from 'sierra-ui/primitives/button/button'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledCreateNewButton = styled.button<ButtonStylesProps>`
  ${ButtonStyles};
  background: ${token('button/background')};
  position: relative;
`

const IconText = styled(motion.div)`
  position: absolute;
  margin: auto;
  color: ${token('button/foreground')};
`

const WideModeContents = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${token('button/foreground')};
`

const CreateNewButton: React.FC = () => {
  const { templatePickerOn } = useTemplatePicker()
  const pseudoStyles = usePrimitiveButtonPseudoStyles('primary')
  const { t } = useTranslation()
  return (
    <StyledCreateNewButton
      id='tour-create-new'
      $pseudoStyles={pseudoStyles}
      $grow
      onClick={() => {
        templatePickerOn({ teamspaceState: undefined })
      }}
    >
      <IconText variants={iconVariants}>+</IconText>
      <WideModeContents variants={textVariants}>{t('create.home.create-new')}</WideModeContents>
    </StyledCreateNewButton>
  )
}

const CollapsableCreateNewButton: React.FC = () => <CreateNewButton />

export const CollapsableCreateNewButtonViewer: React.FC = () => {
  const isCreateAccessible = useIsCreateAccessible()
  const user = useSelector(selectUser)

  if (!isCreateAccessible || user?.impersonatorId !== undefined) return null
  else return <CollapsableCreateNewButton />
}
