import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export type ContentOption = { id: string; title: string; type: string }

const AutocompleteItemGroupLabel = styled.li`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid ${token('border/strong')};
`

type Props = {
  currentContent: string[]
  contentOptions: ContentOption[]
  onContentChange: (content: string[]) => void
  inputPlaceholder?: string
}

//TODO(ludvig): Sort options so they are grouped correctly
export const FilterInput: React.FC<Props> = ({
  currentContent,
  contentOptions,
  onContentChange,
  inputPlaceholder,
}) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')

  const selectedContent = useMemo(() => {
    const allContentMap = new Map(contentOptions.map(item => [item.id, item]))

    return currentContent.flatMap(id => {
      const item = allContentMap.get(id)

      return item !== undefined ? [item] : []
    })
  }, [contentOptions, currentContent])

  const filteredContent = useMemo(() => {
    const allContentExceptSelected = contentOptions.filter(item => !currentContent.includes(item.id))

    return fuzzysort.go(inputValue, allContentExceptSelected, { key: 'title', all: true }).map(it => it.obj)
  }, [contentOptions, currentContent, inputValue])

  const getTranslationString = (type: string): string => {
    switch (type) {
      case 'course':
        return t('dictionary.course-plural')
      case 'path':
        return t('dictionary.path-plural')
      case 'program':
        return t('dictionary.program-plural')
      case 'usergroup':
        return t('dictionary.group-plural')
      default:
        return ''
    }
  }

  return (
    <Autocomplete
      selectedItems={selectedContent}
      matchingItems={filteredContent}
      query={inputValue}
      onQueryChange={setInputValue}
      maxLines={5}
      onSelect={item => {
        const newItemIds = new Set(currentContent)

        newItemIds.add(item.id)

        onContentChange(Array.from(newItemIds))
      }}
      onUnselect={item => {
        onContentChange(currentContent.filter(itemId => itemId !== item.id))
      }}
      placeholder={inputPlaceholder}
      renderSelectedItem={(item, { onUnselect, ...props }) => (
        <SelectedPill key={item.id} {...props}>
          <Text color='foreground/primary' bold>
            {item.title}
          </Text>
          <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
        </SelectedPill>
      )}
      renderMatchingItemList={({ getItemProps }) => {
        const groups = _.groupBy(filteredContent, 'type')

        return (
          <ListContainer>
            {_.map(groups, (items, group) => (
              <>
                <AutocompleteItemGroupLabel>
                  <Text bold color='foreground/secondary'>
                    {getTranslationString(group) || group}
                  </Text>
                </AutocompleteItemGroupLabel>
                {items.map((item, index) => (
                  <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
                    <Text bold>{item.title}</Text>
                  </DefaultStyledAutocompleteOption>
                ))}
              </>
            ))}
          </ListContainer>
        )
      }}
    />
  )
}
