import { forwardRef, useImperativeHandle, useRef } from 'react'
import { Icon } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  text-indent: 2.25rem;
  border: none;
  ${fonts.body.small}

  transition: all 150ms cubic-bezier(0.25, 0.5, 0.25, 1);
  border-radius: ${p => p.theme.borderRadius['size-10']};
  border: 1px solid ${token('border/strong')};

  &:hover {
    border-color: ${token('border/default')};
  }

  color: ${token('foreground/primary')};

  &::placeholder {
    color: ${token('foreground/muted')};
  }

  &:focus {
    border-color: ${token('border/default')};

    &::placeholder {
      opacity: 1;
    }
  }
`

const Delete = styled(Icon).attrs({
  color: 'black',
  iconId: 'close--circle--filled',
  size: 'size-16',
})`
  position: absolute;
  right: 14px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  opacity: 0.2;
  transition: opacity 150ms cubic-bezier(0.25, 0.5, 0.25, 1);
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: opacity 150ms cubic-bezier(0.25, 0.5, 0.25, 1);
  }
`

type FilterInputProps = {
  value?: string
  defaultValue?: string
  onChange: (value: string) => void
  placeholder: string
  autoFocus: boolean
}

type FilterInputRef = {
  focus: () => void
}

export const FilterInput = forwardRef<FilterInputRef, FilterInputProps>(
  ({ value, defaultValue, onChange, placeholder, autoFocus }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus()
      },
      blur: () => {
        inputRef.current?.blur()
      },
    }))

    return (
      <>
        <Input
          color='foreground/primary'
          ref={inputRef}
          value={value}
          defaultValue={defaultValue}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          id='filter-input'
          autoFocus={autoFocus}
        />
        {value !== undefined && value !== '' && (
          <Delete
            onClick={() => {
              onChange('')
            }}
          />
        )}
      </>
    )
  }
)
