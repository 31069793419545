import { RouterLink } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { percentage } from 'sierra-client/core/format'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  canvasColumn,
  dateTimesColumn,
  numbersColumn,
  stringsColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader, StaticDataLoaderMeta } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import { CourseGroupActionModalControl } from 'sierra-client/views/manage/courses/course-group-action-modals'
import { CourseGroupDetailRow } from 'sierra-domain/api/manage'
import { ItemOf } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type CourseGroupEditionsTableDefinition = TableDefinitionOf<
  CourseGroupDetailRow,
  [
    { type: 'canvas'; ref: 'edition' },
    { type: 'dateTimes'; ref: 'added' },
    { type: 'numbers'; ref: 'learners' },
    { type: 'strings'; ref: 'progress' },
    { type: 'numbers'; ref: 'duration' },
  ]
>

export type CourseGroupEditionsTableData = TableDataFromDefinition<
  CourseGroupDetailRow,
  CourseGroupEditionsTableDefinition
>

const UnderscoreOnHoverA = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

type CourseNameCellProps = {
  row: ItemOf<CourseGroupEditionsTableData['rows']>['rawData']
}

const CourseNameCell: React.FC<CourseNameCellProps> = ({ row }) => {
  const { dynamicT } = useTranslation()

  const contentDetailsHref = getHrefForContentDetails({
    contentType: 'course',
    courseKind: row.kind,
    id: row.courseId,
  })
  const imgSrc = useResolveAsset({
    image: row.image,
    size: 'admin-sm',
    assetContext: { type: 'course', courseId: row.courseId },
  })
  return (
    <View alignItems='flex-start' gap='xsmall'>
      <Thumbnail image={imgSrc} />
      <View direction='column' gap='6'>
        <RouterLink href={contentDetailsHref}>
          <UnderscoreOnHoverA>{row.title}</UnderscoreOnHoverA>
        </RouterLink>
        {row.language !== undefined && (
          <Text color='foreground/muted' size='small'>
            {dynamicT(`language.${row.language}`)}
          </Text>
        )}
      </View>
    </View>
  )
}

const tableDefinition = (): CourseGroupEditionsTableDefinition => ({
  columns: [
    canvasColumn({
      getData: r => ({
        view: <CourseNameCell row={r} />,
        meta: {
          sorts: () => r.title,
          exports: () => r.title,
        },
      }),
      sortable: true,
      header: translatedLabel('table.name'),
      ref: 'edition',
    }),
    dateTimesColumn({
      getData: r => (r.publishedAt ? { date: r.publishedAt } : undefined),
      header: translatedLabel('table.added'),
      ref: 'added',
      sortable: true,
    }),
    numbersColumn({
      getData: r => r.assignedOrStartedUsersCount,
      header: translatedLabel('table.learners'),
      ref: 'learners',
      sortable: true,
    }),
    stringsColumn({
      getData: r => percentage(r.completionRate),
      header: translatedLabel('admin.analytics.table.progress'),
      tooltip: translatedLabel('manage.groups.tooltip.group-completion'),
      ref: 'progress',
      sortable: true,
    }),
    numbersColumn({
      getData: r => r.timeTotal,
      header: translatedLabel('table.duration'),
      ref: 'duration',
      sortable: true,
      hints: ['duration'],
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.courseId,
  },
})

export const useCourseGroupEditionsTableAPI = ({
  data,
  courseGroupId,
  modal,
}: {
  data: CourseGroupDetailRow[]
  courseGroupId: string
  modal: CourseGroupActionModalControl
}): UseTableAPI<CourseGroupEditionsTableData, StaticDataLoaderMeta<CourseGroupEditionsTableData>> => {
  const { t } = useTranslation()
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)

  const loader = staticDataLoader(definition2Data(tableDefinition(), data))

  return useTableAPI({
    virtualColumns: {
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const { rawData } = row

            return {
              menuItems: [
                {
                  id: 'view-details',
                  type: 'label',
                  label: t('manage.view-details'),
                  onClick: () =>
                    getGlobalRouter().navigate({
                      to: getHrefForContentDetails({
                        contentType: 'course',
                        courseKind: rawData.kind,
                        id: rawData.courseId,
                      }),
                    }),
                },
                {
                  type: 'label',
                  id: 'assign-learner',
                  label: t('manage.course-groups.assignments.assign-learners'),
                  hidden: !courseGroupPermissions.has('EDIT_ASSIGNMENTS'),
                  onClick: () => {
                    modal.open({
                      modal: 'enroll-to-edition',
                      isDefaultRequiredAssignment: rawData.isDefaultRequiredAssignment,
                      courseId: rawData.courseId,
                    })
                  },
                },
                {
                  type: 'label',
                  id: 'detach-edition',
                  label: t('manage.course-groups.detach-edition.title'),
                  hidden: !(rawData.canEdit === true),
                  onClick: () => {
                    modal.open({
                      modal: 'detach-edition',
                      courseEditionId: rawData.courseId,
                    })
                  },
                },
              ],
            }
          },
        }),
      ],
    },
    dataLoader: {
      loader,
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.CONTENT,
    },
  })
}
