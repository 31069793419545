import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationForm } from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCreateSessionWithCode } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const NumberInput = styled.input`
  background: ${token('surface/default')};
  border: 1px solid ${token('form/border/1')};
  height: 52px;
  width: 41px;
  border-radius: 10px;

  font-size: 16px;
  text-align: center;

  padding: 0px 12px 0px 12px;
  gap: 8px;
  opacity: 0px;

  :focus {
    border: 1px solid ${token('form/border/3')};
  }
`
const Wrapper = styled(View)`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const FormSchema = z.object({
  code: z
    .object({ value: z.string().length(1) })
    .array()
    .length(6),
})

type FormSchema = z.infer<typeof FormSchema>

export const VerifyCode = (): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [postError, setPostError] = useState<undefined | string>(undefined)

  const handler = useForm<FormSchema>({
    resolver: zodResolver(FormSchema),
    values: {
      code: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
    },
  })

  const codePositionHandler = useFieldArray({ control: handler.control, name: 'code' })

  const inputRefs = useRef<
    [
      HTMLInputElement | null,
      HTMLInputElement | null,
      HTMLInputElement | null,
      HTMLInputElement | null,
      HTMLInputElement | null,
      HTMLInputElement | null,
    ]
  >([null, null, null, null, null, null])

  const onSubmit = useCallback(
    async (formData: FormSchema): Promise<void> => {
      setPostError(undefined)

      const result = await postWithUserErrorCode(XRealtimeAuthCreateSessionWithCode, {
        code: formData.code.map(code => code.value).join(''),
      })

      if (isLeft(result)) {
        setPostError(t(getUserErrorTranslationKey(result.left)))
        inputRefs.current.at(-1)?.focus()
        return
      }

      void getAuthClient().synchronize()

      setPostError(undefined)
    },
    [postWithUserErrorCode, t]
  )

  const onCancel = useCallback((): void => {
    void getAuthClient().signOut()
  }, [])

  const { isValid, errors } = handler.formState

  return (
    <AuthenticationContent message={t('verify-code.heading')}>
      <AuthenticationForm onSubmit={handler.handleSubmit(onSubmit)} key='verify-code-form'>
        <Wrapper>
          <Text color='foreground/primary' size='small' lineLength={45} align='center'>
            {t('verify-code.body')}
          </Text>
          <Spacer size='small' />
          <View grow>
            <FormElement label='' helper={errors.code?.message} isError={!isValid}>
              <View justifyContent='center'>
                {iife(() => {
                  const codes = codePositionHandler.fields
                  const input = (index: number): JSX.Element => (
                    <Controller
                      key={index}
                      control={handler.control}
                      name={`code.${index}.value`}
                      render={({ field }) => (
                        <NumberInput
                          type='text'
                          maxLength={1}
                          inputMode='numeric'
                          ref={el => {
                            inputRefs.current[index] = el
                          }}
                          value={field.value}
                          onChange={e => {
                            const value = e.currentTarget.value
                            if (/^\d$/.test(value) || value === '') {
                              field.onChange(e)
                              setPostError(undefined)
                              if (value !== '') {
                                inputRefs.current[index + 1]?.focus()
                              }
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Backspace' && field.value === '') {
                              inputRefs.current[index - 1]?.focus()
                            }
                          }}
                          onPaste={(e: React.ClipboardEvent<HTMLInputElement>): void => {
                            e.preventDefault()
                            const pasteData = e.clipboardData
                              .getData('text')
                              .replaceAll(/[^0-9]/g, '')
                              .slice(0, 6)

                            const newCode = pasteData.split('')

                            newCode.forEach((c, index) => {
                              handler.setValue(`code.${index}.value`, c)
                            })
                            void handler.trigger('code')

                            inputRefs.current.at(-1)?.focus()
                          }}
                        />
                      )}
                    />
                  )
                  return <>{codes.map((_, index) => input(index))}</>
                })}
              </View>
            </FormElement>
          </View>

          {postError !== undefined && (
            <>
              <Spacer size='medium' />
              <Text size='small' color='redMedium'>
                {postError}
              </Text>
            </>
          )}
          <Spacer size='small' />

          <View style={{ width: '100%' }} grow alignItems='center' direction='column'>
            <Button type='submit' variant='primary' id={'submit-button'} disabled={!isValid} grow>
              {t('dictionary.submit')}
            </Button>
            <SecondaryButton onClick={() => onCancel()}>{t('dictionary.cancel')}</SecondaryButton>
          </View>
        </Wrapper>
      </AuthenticationForm>
    </AuthenticationContent>
  )
}
