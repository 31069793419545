import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useOutlineReducer } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { ManageHeader } from 'sierra-client/views/manage/components/manage-header'
import {
  OutlineEditPanels,
  useOutlineEditPanels,
} from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit-panels'
import { ProgramPanelCommon } from 'sierra-client/views/manage/programs/staggered-assignments/panels/common'
import {
  AddStepTableData,
  AddStepTableMeta,
  addStepDataLoader,
  fetchStepContentByIds,
  selectionButtonVirtualColumns,
} from 'sierra-client/views/manage/programs/staggered-assignments/tables/add-steps/add-step-table-common'
import {
  createCourseStep,
  createPathStep,
  createTimeScheduleOnPreviousComplete,
  graphQLContentKindToCourseKind,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramStep } from 'sierra-domain/api/manage'
import { assertNever, iife } from 'sierra-domain/utils'
import { PanelInPanel, PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Heading, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const TableHeight = styled(View).attrs({ direction: 'column', alignItems: 'stretch' })`
  min-height: 60vh;
`

const PanelLayout = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  alignItems: 'stretch',
  paddingLeft: '40',
  paddingRight: '40',
})`
  position: relative;
`

const HeaderWrapper = styled(View).attrs({
  direction: 'column',
  gap: '40',
  paddingTop: '40',
  paddingBottom: '8',
})`
  position: sticky;
  background: linear-gradient(to bottom, ${token('surface/default').opacity(1)} 90%, transparent);
  top: 0;
  left: 0;
  z-index: 10;
`

const Header: React.FC<{ api: TableAPI<AddStepTableData>; close: () => void }> = ({ api, close }) => {
  const { t } = useTranslation()
  return (
    <HeaderWrapper>
      <View direction='row' justifyContent='flex-start' alignItems='center' gap='8'>
        <PanelInPanelCloseButton onClose={close} label={t('dictionary.close')} />
        <Heading size='h4' bold>
          {t('manage.program.program-details.steps.add-steps-header')}
        </Heading>
      </View>
      <ManageHeader api={api} search={{ placeholder: 'menu.search.placeholder', initialOpen: true }} />
    </HeaderWrapper>
  )
}

const Footer: React.FC<{ api: TableAPI<AddStepTableData>; outlineEdit: OutlineEditPanels }> = ({
  api,
  outlineEdit,
}) => {
  const { t } = useTranslation()
  const sel = useAtomValue(api.atoms.selection)

  const { outline, dispatch } = useOutlineReducer()

  const {
    setPanels: {
      addContent: { off: closeAddStep },
    },
  } = outlineEdit

  const outlineLength = outline.steps.length

  const cancel = useCallback(() => {
    closeAddStep()
    api.action.setSelection({ type: 'none' })
  }, [api.action, closeAddStep])

  const save = useCallback(() => {
    // If some are selected
    switch (sel.type) {
      case 'manual': {
        void iife(async () => {
          // As a query might reset, the table data won't include all content anymore. We'll need to query the data manually
          const selectedContent = await fetchStepContentByIds(Array.from(sel.rows))

          const steps: ProgramStep[] = _.values(selectedContent.content).map(v => {
            const d = v
            const image = convertGQLImage(d.image)

            switch (d.__typename) {
              case 'Program':
                throw new Error('Programs cannot be added as steps')
              case 'Path':
                return createPathStep({
                  pathId: d.pathId,
                  title: d.title,
                  image,
                  schedule: outlineLength > 0 ? createTimeScheduleOnPreviousComplete() : undefined,
                })
              default:
                return createCourseStep({
                  courseId: d.courseId,
                  title: d.title,
                  image,
                  courseKind: graphQLContentKindToCourseKind(d.courseKind),
                  schedule: outlineLength > 0 ? createTimeScheduleOnPreviousComplete() : undefined,
                })
            }
          })

          dispatch({ type: 'ADD_STEPS', steps })
          closeAddStep()
          api.action.setSelection({ type: 'none' })
        })
        break
      }
      case 'all':
      case 'none':
        break
      default:
        assertNever(sel)
    }
  }, [closeAddStep, dispatch, api, sel, outlineLength])

  return (
    <ProgramPanelCommon.InnerPanelFooterWrapper>
      <View>
        <Button variant='secondary' onClick={cancel}>
          {t('dictionary.cancel')}
        </Button>
        <Button variant='primary' onClick={save}>
          {t('dictionary.save')}
        </Button>
      </View>
    </ProgramPanelCommon.InnerPanelFooterWrapper>
  )
}

export const AddStepPanel: React.FC<{ open: boolean }> = ({ open }) => {
  const { t } = useTranslation()
  const dataLoader = useMemo(
    () => ({ loader: addStepDataLoader(t), options: { queryKey: ['add-step-tabular'] } }),
    [t]
  )

  const tableAPI = useTableAPI<AddStepTableData, AddStepTableMeta>({
    dataLoader,
    virtualColumns: selectionButtonVirtualColumns,
    options: {
      queryStateKeyPrefix: null,
    },
  })

  const outlineEdit = useOutlineEditPanels()

  /* We are destructuring from the hook as the jotai store we pass to the provider does not share the same atoms */
  const {
    setPanels: {
      addContent: { off: closeAddStep },
    },
  } = outlineEdit

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <PanelInPanel open={open} onEscapeKeyDown={closeAddStep}>
        <PanelLayout>
          <Header api={tableAPI.api} close={closeAddStep} />
          <TableHeight>
            <BasicTabular />
          </TableHeight>
          <Footer api={tableAPI.api} outlineEdit={outlineEdit} />
        </PanelLayout>
      </PanelInPanel>
    </TabularProviderFromTableAPI>
  )
}
