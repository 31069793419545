import { UseQueryResult } from '@tanstack/react-query'
import _ from 'lodash'
import React from 'react'
import { LeaderboardQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { RoundAvatarFromAvatar } from 'sierra-client/views/manage/components/round-avatar-from-avatar'
import { Avatar } from 'sierra-domain/api/manage'
import { ItemOf, getUserName, isDefined } from 'sierra-domain/utils'
import { SkillIconId, TruncatedText } from 'sierra-ui/components'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'
import { SkillColor } from '../../colors'
import { BadgeStack, BadgeStackItem } from '../badge-stack'

const PositionIcon = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  background-color: ${token('foreground/primary')};
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  left: -8px;
  width: 20px;
  height: 20px;
  border: 2px solid white;
`

const LeaderAvatarContainer = styled.div`
  position: relative;
`

const PositionText = styled(Text)`
  white-space: nowrap;
  line-height: unset;
`

export const WithPosition: FCC<{ position: number | undefined }> = ({ position, children }) => {
  return (
    <LeaderAvatarContainer>
      {isDefined(position) && (
        <PositionIcon>
          <PositionText color='white' size='micro'>
            {position}
          </PositionText>
        </PositionIcon>
      )}
      {children}
    </LeaderAvatarContainer>
  )
}

const Grid = styled(View)`
  display: grid;
  row-gap: 0px;
  column-gap: 12px;
  grid-template-columns: 32px 1fr minmax(80px, max-content);
  grid-template-areas:
    'avatar name badges'
    'avatar badgeNumber badges';
  height: 100%;
  width: 100%;
`

const OthersListItem = styled(Grid)`
  height: auto;
  padding: 16px 0;
`

const _List = styled(View).attrs({ direction: 'column', gap: 'none' })<{
  verticalPadding: number
}>`
  width: 100%;

  > * + * {
    border-top: 1px solid ${token('border/default')};
    padding: ${p => p.verticalPadding}px 0px;
  }

  > *:first-child {
    padding-bottom: ${p => p.verticalPadding}px;
  }

  > *:last-child {
    padding-bottom: 0;
  }
`

const GridItem = styled(View)<{ area: string; placeItems?: 'start' | 'end' }>`
  grid-area: ${({ area }) => area};
  width: 100%;
  height: 100%;
  place-items: ${({ placeItems }) => placeItems ?? 'inherit'};
`

export type ListItemProps = {
  position?: number
  avatar: Avatar
  userId: string
  userName: string
  badges: Array<BadgeStackItem>
  show?: 'badges' | 'level'
}

const ListItem: React.FC<ListItemProps> = ({
  position,
  avatar,
  userId,
  userName,
  badges,
  show = 'badges',
}) => {
  const { t } = useTranslation()
  return (
    <Grid>
      <GridItem area='avatar'>
        <WithPosition position={position}>
          <RoundAvatarFromAvatar userId={userId} size='medium' avatar={avatar} />
        </WithPosition>
      </GridItem>
      <GridItem area='name' placeItems='end'>
        <TruncatedText lines={1} size='small' bold>
          {userName}
        </TruncatedText>
      </GridItem>
      <GridItem area='badgeNumber' placeItems='start'>
        <TruncatedText lines={1} color='foreground/muted'>
          {show === 'badges'
            ? t('dictionary.n-badges', { count: badges.length })
            : (_.maxBy(badges, b => b.skillLevel.index)?.skillLevel.name ?? '-')}
        </TruncatedText>
      </GridItem>
      <GridItem area='badges' justifyContent='flex-end'>
        <BadgeStack stackSize={5} badges={badges} />
      </GridItem>
    </Grid>
  )
}

const PlaceholderListItem = Grid

const MeListItem = styled(View)`
  width: 100%;
  height: 100%;
`

const List = styled(_List).attrs({ verticalPadding: 12 })<{
  $topLearners?: number
  $withOtherListItem?: boolean
}>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;

  > * + * {
    border-top: 1px solid ${token('border/default')};
  }

  ${props =>
    (props.$withOtherListItem ?? false) &&
    props.$topLearners !== undefined &&
    `
    grid-template-rows: repeat(${props.$topLearners}, 1fr) auto 1fr;
  `}
  > ${OthersListItem} {
    border-top: 2px dotted ${token('border/strong')};
  }

  > ${MeListItem} {
    border-top: 2px dotted ${token('border/strong')};
  }
`

const gqlBadgeToBadge = (
  b: ItemOf<ItemOf<LeaderboardQuery['topSkillLearners']['skillLearners']>['badges']>
): BadgeStackItem => {
  return {
    skill: {
      id: b.skillId,
      name: b.skillName,
    },
    skillLevel: {
      name: b.skillLevelName,
      index: b.skillLevelIndex,
    },
    color: SkillColor.catch('bronze').parse(b.badgeTheme),
    icon: SkillIconId.catch('skill--graduation--cap').parse(b.badgeIcon),
  }
}

const SkeletonContainer = styled(View)`
  width: 100%;
  height: 100%;
  gap: 32px;

  > ${Skeleton}:last-child {
    margin-top: auto;
    align-self: flex-end;
  }
`

export const LeaderboardSkeleton: React.FC<{ items?: number }> = ({ items = 5 }) => {
  return (
    <SkeletonContainer direction='column'>
      {Array.from({ length: items }).map((_, i) => (
        <Skeleton key={i} $height={50} $width='100%' />
      ))}
    </SkeletonContainer>
  )
}

export const ErrorLeaderboard: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View padding='128'>
      <Text>{t('error-page.explanation')}</Text>
    </View>
  )
}

export type LeaderboardViewProps = {
  res: UseQueryResult<LeaderboardQuery, unknown>
  top: number
  separateMe?: boolean
} & Pick<ListItemProps, 'show'>

export const LeaderboardView: React.FC<LeaderboardViewProps> = ({ res, top, show, separateMe }) => {
  const { t } = useTranslation()

  const debug = useDebug('Leaderboard', { loading: false, error: false })

  if (debug.loading || res.isLoading) {
    return <LeaderboardSkeleton items={top} />
  }

  if (debug.error || res.isError) {
    return <ErrorLeaderboard />
  }

  if (!isDefined(res.data)) {
    return <ErrorLeaderboard />
  }

  const { me, topSkillLearners } = res.data

  const nOthers = topSkillLearners.totalUsersWithBadges - topSkillLearners.skillLearners.length
  const showMeInTop =
    separateMe !== true && topSkillLearners.skillLearners.some(leader => leader.userInfo.id === me?.id)

  const listItems = topSkillLearners.skillLearners.map(leader => (
    <ListItem
      key={leader.userInfo.id}
      show={show}
      position={leader.rank ?? 0}
      avatar={convertGQLAvatar(leader.userInfo.avatar)}
      userId={leader.userInfo.id}
      userName={
        leader.userInfo.id === me?.id
          ? _.capitalize(t('dictionary.you'))
          : (getUserName(leader.userInfo) ?? '-')
      }
      badges={leader.badges.map(gqlBadgeToBadge)}
    />
  ))

  const placeHolderItems = Array.from({
    length: top - topSkillLearners.skillLearners.length,
  }).map((_, i) => <PlaceholderListItem key={i} />)

  const showOthersListItem = nOthers > 0 && !showMeInTop

  return (
    <List paddingLeft='8' paddingRight='16' $topLearners={top} $withOtherListItem={showOthersListItem}>
      {listItems}
      {placeHolderItems}
      {showOthersListItem && (
        <OthersListItem>
          <GridItem area='name'>
            <Text bold color='foreground/muted'>
              {t('dictionary.n-others', { count: nOthers })}
            </Text>
          </GridItem>
        </OthersListItem>
      )}
      {isDefined(me) && !showMeInTop && (
        <MeListItem>
          <ListItem
            show={show}
            position={null2Undefined(me.skillRanking?.rank)}
            avatar={convertGQLAvatar(me.avatar)}
            userId={me.id}
            userName={_.capitalize(t('dictionary.you'))}
            badges={me.skillRanking?.badges.map(gqlBadgeToBadge) ?? []}
          />
        </MeListItem>
      )}
    </List>
  )
}
