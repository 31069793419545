import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { TimezonePicker } from 'sierra-client/components/common/pickers/timezone-picker'
import { getDisabledReason, useDisableInput } from 'sierra-client/components/disabling'
import { TagInput } from 'sierra-client/components/tags/tag-input'
import { getFlag } from 'sierra-client/config/global-config'
import { useLanguageItems } from 'sierra-client/hooks/use-language-items'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { FileButtonInput } from 'sierra-client/views/manage/settings/components/file-button-input'
import {
  AccountSettingsFormConfig,
  HandleAccountSettingsFormChange,
  UserSettingsFormErrors,
} from 'sierra-client/views/user-settings/user-settings-utils'
import { AccountSettings } from 'sierra-domain/api/user'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { IconButton, InputPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 1rem;
`
const SpanFullGrid = styled.div`
  grid-column: 1 / -1;
`

type UserSettingsFormProps = {
  values: AccountSettings
  errors: UserSettingsFormErrors
  formConfig: AccountSettingsFormConfig
  email?: string
  onChange: HandleAccountSettingsFormChange
}

export const UserSettingsForm: React.FC<UserSettingsFormProps> = ({
  values,
  errors,
  email,
  formConfig,
  onChange,
}) => {
  const { t } = useTranslation()
  const languageMenuItems = useLanguageItems({ labelInItemLanguage: true })
  const selectedLanguage = languageMenuItems.find(item => item.id === values.userAccountSettings.language)
  const disableInputContext = useDisableInput()

  const { data: userData } = useQuery({
    ...currentUserQuery,
    select: user => ({ userId: user.uuid, avatarColor: user.avatarColor }),
  })

  if (userData?.userId === undefined) return <></>

  const { userId, avatarColor } = userData
  const { userAccountSettings: settingsValues } = values
  const { userAccountSettings: settingsErrors } = errors

  const userTimezoneEnabled = getFlag('user-preferred-timezone')

  return (
    <View direction='column' gap='24'>
      <View>
        <RoundAvatar
          firstName={settingsValues.firstName}
          lastName={settingsValues.lastName}
          src={getAvatarImage(userId, settingsValues.avatar)}
          color={avatarColor}
        />
        <FileButtonInput
          buttonProps={{
            variant: 'ghost',
            children: t('settings.fields.choose-profile-image'), // @TODO change copy for new/replace
            disabledWithReason: getDisabledReason(disableInputContext),
          }}
          maxSizeInMB={1}
          fileInputProps={{ accept: 'image/png, image/jpeg' }}
          onChange={fileUrl => onChange('userAccountSettings', 'avatar', `${fileUrl}.jpeg`)}
          fileType='user'
        />
        {settingsValues.avatar !== undefined && !disableInputContext.disabled && (
          <IconButton
            variant='transparent'
            onClick={() => onChange('userAccountSettings', 'avatar', undefined)}
            iconId='trash-can'
          />
        )}
      </View>
      <FormWrapper>
        <FormElement
          label={t('settings.fields.first-name')}
          isError={settingsErrors.firstName !== undefined}
          helper={settingsErrors.firstName ? t(...settingsErrors.firstName) : undefined}
        >
          <InputPrimitive
            id='first-name'
            type='text'
            value={settingsValues.firstName}
            onChange={e => onChange('userAccountSettings', 'firstName', e.target.value)}
            disabled={formConfig.disableName || disableInputContext.disabled}
          />
        </FormElement>
        <FormElement
          label={t('settings.fields.last-name')}
          isError={settingsErrors.lastName !== undefined}
          helper={settingsErrors.lastName ? t(...settingsErrors.lastName) : undefined}
        >
          <InputPrimitive
            id='last-name'
            type='text'
            value={settingsValues.lastName}
            onChange={e => onChange('userAccountSettings', 'lastName', e.target.value)}
            disabled={formConfig.disableName || disableInputContext.disabled}
          />
        </FormElement>
        <FormElement label={t('settings.fields.email')}>
          <InputPrimitive
            id='email'
            type='text'
            value={email ?? ''}
            onChange={() => {}}
            disabled={formConfig.disableEmail || disableInputContext.disabled}
          />
        </FormElement>

        <FormElement label={t('settings.sections.language')} htmlFor='language'>
          <SingleSelectDropdown
            menuItems={languageMenuItems}
            selectedItem={selectedLanguage}
            onSelect={item => onChange('userAccountSettings', 'language', item.id)}
            disabled={disableInputContext.disabled}
          />
        </FormElement>

        {userTimezoneEnabled === true && (
          <SpanFullGrid>
            <FormElement label={t('manage.settings.customization.timezone')}>
              <TimezonePicker
                timezoneId={values.userAccountSettings.preferredTimezone}
                onChange={value => onChange('userAccountSettings', 'preferredTimezone', value)}
                disabled={disableInputContext.disabled}
              />
            </FormElement>
          </SpanFullGrid>
        )}
      </FormWrapper>
      <TagInput
        currentTags={settingsValues.tags}
        onTagChange={tags => onChange('userAccountSettings', 'tags', tags)}
        disabled={disableInputContext.disabled}
      />
    </View>
  )
}
