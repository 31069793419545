import { AnimatePresence } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { collapsableSidebarStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { selectCourseProgressState } from 'sierra-client/state/card-progress/selectors'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'
import { Icon, ProgressBar } from 'sierra-ui/components'
import { ProgressCircle } from 'sierra-ui/missions/foundation'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const circleVariants = {
  visible: { opacity: 1, transition: { duration: 0.2, delay: 0.2 } },
  hidden: { opacity: 0, transition: { duration: 0.2 } },
}

const barVariants = {
  visible: { opacity: 1, transition: { duration: 0.3, delay: 0.15 } },
  hidden: { opacity: 0, transition: { duration: 0.2 } },
}

const ProgressBarContainer = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '16',
  animated: true,
  variants: barVariants,
  grow: true,
})`
  overflow: hidden;
  width: calc(100% - 12px);
`

const ProgressCircleContainer = styled(View).attrs({
  variants: circleVariants,
  animated: true,
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  top: 0;
  left: 12px;
  width: 36px;

  overflow: hidden;
  position: absolute;
`

const StyledProgressBar = styled(ProgressBar).attrs({
  strokeWidth: 4,
  variant: 'blueBright',
})``

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

export const ProgressBarItem: React.FC<{ flexibleContentId: SelfPacedContentId }> = ({
  flexibleContentId,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)
  const course = useSelector(state => selectCourseById(state, flexibleContentId))

  const progressState = useSelector(state => selectCourseProgressState(state, flexibleContentId))
  const cardStatuses = isDefined(progressState?.cardStatuses)
    ? Object.entries(progressState.cardStatuses)
    : []
  const completedCards = cardStatuses.filter(
    ([, status]) => isDefined(status) && isDefined(status.completed)
  ).length

  const progress = completedCards / cardStatuses.length
  const timeTotal = course?.readingTimes?.total ?? 0
  const timeLeft = timeTotal * (1 - progress)
  const formattedTimeLeft = useGetFormattedTime(timeLeft, true)
  const progressInPercent = Math.round(progress * 100)
  const courseCompleted = progressState?.courseStatus.completed !== undefined

  if (isMobile)
    return (
      <View
        paddingRight='24'
        paddingBottom='16'
        paddingTop='16'
        paddingLeft='24'
        gap='64'
        grow
        direction='row'
      >
        <StyledProgressBar percent={progressInPercent} />
        <View direction='row' justifyContent='flex-start' alignItems='center' gap='8'>
          <Icon iconId={courseCompleted ? 'checkmark' : 'timer'} color='foreground/muted' />
          <NoWrapText bold size='small' color='foreground/secondary'>
            {courseCompleted ? t('course-overview.course-completed') : formattedTimeLeft}
          </NoWrapText>
        </View>
      </View>
    )

  return (
    <AnimatePresence>
      <ProgressBarContainer
        key='container'
        initial={sidebarState === 'collapsed' ? 'hidden' : 'visible'}
        animate={sidebarState === 'collapsed' ? 'hidden' : 'visible'}
      >
        <StyledProgressBar percent={progressInPercent} />
        <View direction='row' justifyContent='flex-start' alignItems='center' gap='8'>
          <Icon iconId={courseCompleted ? 'checkmark' : 'timer'} color='foreground/muted' />
          <NoWrapText bold size='small' color='foreground/secondary'>
            {courseCompleted ? t('course-overview.course-completed') : formattedTimeLeft}
          </NoWrapText>
        </View>
      </ProgressBarContainer>

      <ProgressCircleContainer
        key='circle'
        initial={sidebarState === 'collapsed' ? 'visible' : 'hidden'}
        animate={sidebarState === 'collapsed' ? 'visible' : 'hidden'}
      >
        <ProgressCircle
          key={progressInPercent}
          size={20}
          strokeColor='blueBright'
          strokeBackground={{ opacity: 0.05 }}
          startProgress
          strokeWidth={8}
          strokeShape='round'
          percents={progressInPercent}
        />
      </ProgressCircleContainer>
    </AnimatePresence>
  )
}
