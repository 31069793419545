import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDebouncedAction } from 'sierra-client/hooks/use-debounced-action'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Pagination } from 'sierra-client/lib/tabular/datatype/pagination'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { FCC } from 'sierra-client/types'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActualGhostButton } from 'sierra-client/views/workspace/learn/components'
import { identity } from 'sierra-domain/filter/datatype/filter'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, Menu, Popover, type MenuItem } from 'sierra-ui/components'
import type { CheckboxMenuItem } from 'sierra-ui/components/menu/types'
import { Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, MultiSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

export const TabularToolbarButton = styled(ActualGhostButton)`
  padding: 10px 12px;
`

export const ToolbarRightContainer = styled(View).attrs({ gap: '2' })`
  flex-wrap: wrap;
`

export const ToolbarLeftContainer = styled(View)`
  min-width: fit-content;
`

// translation keys to handle three different scenarios, e.g.:
// count with filters and selections --> "y of x selected" (selectedKey. Generic key: 'manage.tables.n-selected')
// count with filters and without selections --> "x matching user(s)" (filterKey)
// count without filters and selections --> "x user(s)" (totalKey)
type CountsTranslationKeys = {
  totalKey?: TranslationKey
  filterKey?: TranslationKey
  selectedKey?: TranslationKey
}

export const countSelected = (pag: Pagination, sel: Selection): number => {
  switch (sel.type) {
    case 'none':
      return 0
    case 'manual':
      return sel.rows.size
    case 'all':
      return pag.total - sel.excluded.size
    default:
      assertNever(sel)
  }
}

export const RenderCountsPrimitive: FCC = ({ children }) => {
  return (
    // Note: We only animate when the element appears or disappears, not when its content changes.
    // That's because it otherwise looks a bit distracting when the count changes several times, for instance
    // when typing a search query.
    <AnimatePresence mode='wait' initial={false}>
      {children !== undefined && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
        >
          <Text size='small' bold>
            {children}
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const RenderCounts = ({
  api,
  translationKeys,
}: {
  api: TableAPI
  translationKeys: CountsTranslationKeys
}): JSX.Element => {
  const { t } = useTranslation()
  const loaderState = useAtomValue(api.atoms.loaderState)
  const sel = useAtomValue(api.atoms.selection)
  const pag = useAtomValue(api.atoms.pagination)

  const totalCount = pag.total
  const selectedCount = countSelected(pag, sel)

  const copy = (() => {
    if (loaderState === 'init') {
      return undefined
    } else if (sel.type === 'none' && translationKeys.totalKey !== undefined) {
      return t(translationKeys.totalKey, { count: totalCount })
    } else if (translationKeys.selectedKey !== undefined) {
      return t(translationKeys.selectedKey, { selected: selectedCount, count: totalCount })
    } else {
      return undefined
    }
  })()

  return <RenderCountsPrimitive>{copy}</RenderCountsPrimitive>
}

type TabularToolbarProps = {
  api: TableAPI
  clearFilters: boolean
  countsTranslationKeys?: CountsTranslationKeys
  actions?: React.ReactNode
  enableAllSelection?: boolean
}

const Divider = styled.div`
  display: flex;
  width: auto;
  color: ${token('border/strong')};
  background-color: inherit;
  align-self: stretch;
  align-items: center;
`

export const TabularToolbarDownloadButton: React.FC<{
  onDownloadCSV: () => Promise<void>
  onDownloadXLSX: () => Promise<void>
  loading?: boolean
}> = ({ onDownloadCSV, onDownloadXLSX, loading = false }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  return (
    <Popover
      isOpen={open}
      onOpenChange={setOpen}
      renderTrigger={() => (
        <TabularToolbarButton
          icon='download'
          loading={loading}
          onClick={ev => (ev.stopPropagation(), setOpen(o => !o))}
        >
          {t('manage.reports.download')}
        </TabularToolbarButton>
      )}
    >
      <Menu
        items={[
          {
            id: 'csv',
            type: 'label',
            label: t('admin.analytics.download-csv'),
            onClick: async () => {
              await onDownloadCSV()
              setOpen(false)
            },
          },
          {
            id: 'xlsx',
            type: 'label',
            label: t('admin.analytics.download-xlsx'),
            onClick: async () => {
              await onDownloadXLSX()
              setOpen(false)
            },
          },
        ]}
      />
    </Popover>
  )
}

const ToolbarSearchContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const ToolbarSearch: FC<{
  onQueryChange: (query: string) => void
  initialQuery?: string
}> = ({ onQueryChange, initialQuery }) => {
  const { t } = useTranslation()

  return (
    <ToolbarSearchContainer>
      <AnimatedSearch
        onChange={value => onQueryChange(value)}
        placeholder={`${t('dictionary.search')}`}
        defaultValue={initialQuery}
        initialOpen={Boolean(initialQuery)}
      />
    </ToolbarSearchContainer>
  )
}

export const ToolbarContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${token('border/strong')};
  background-color: ${token('surface/default')};
  border-radius: ${p => p.theme.borderRadius['size-10']};
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  z-index: 1;
  /* We'll use a height that works both with and without action buttons. */
  min-height: 46px;
`
const SelectionButton = styled.button`
  &:hover {
    cursor: pointer;
  }
`

const AllSelectedRow: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const selected = useAtomValue(api.atoms.selection)
  const page = useAtomValue(api.atoms.pagination)

  if (selected.type !== 'all') {
    return null
  }

  return (
    <>
      <Text color='foreground/secondary'>
        <Trans
          i18nKey={
            selected.excluded.size > 0
              ? 'tabular.selection.all-selected.with-excluded.text'
              : 'tabular.selection.all-selected.text'
          }
          values={{
            count: page.total,
            excluded: selected.excluded.size,
          }}
          components={{
            bold: <Text color='currentColor' bold as='span' />,
          }}
        />
      </Text>
      <SelectionButton
        onClick={() => {
          api.action.setSelection({ type: 'none' })
        }}
      >
        <Text bold>{t('tabular.selection.clear-all.button')}</Text>
      </SelectionButton>
    </>
  )
}

const AllSelectionRow: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const selected = useAtomValue(api.atoms.selection)
  const page = useAtomValue(api.atoms.pagination)

  const shouldShow = selected.type === 'manual'

  if (!shouldShow) {
    return null
  }

  return (
    <>
      <Text color='foreground/secondary'>
        <Trans
          i18nKey={'tabular.selection.currently-selected.text'}
          values={{
            count: selected.rows.size,
          }}
          components={{
            bold: <Text color='currentColor' bold as='span' />,
          }}
        />
      </Text>
      <SelectionButton
        onClick={() => {
          api.action.setSelection({ type: 'all', excluded: new Set<string>() })
        }}
      >
        <Text bold>
          {t('tabular.selection.select-all.button', {
            count: page.total,
          })}
        </Text>
      </SelectionButton>
    </>
  )
}

const SelectionContainer = styled(View).attrs({
  grow: true,
  background: 'surface/strong',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  gap: '2',
  padding: '10 16',
})`
  height: 58px;
  border-radius: 0 0 12px 12px;
`

export const TabularToolbar: React.FC<TabularToolbarProps> = ({
  api,
  clearFilters,
  countsTranslationKeys,
  actions,
  enableAllSelection = false,
}) => {
  const { t } = useTranslation()
  const showCount = countsTranslationKeys !== undefined

  const { pages } = useTabularContext()
  const pagination = useAtomValue(api.atoms.pagination)
  const selected = useAtomValue(api.atoms.selection)
  const rows = useMemo(() => pages.flat(), [pages])

  const showAllSelection =
    enableAllSelection &&
    iife(() => {
      switch (selected.type) {
        case 'all':
          return true
        case 'manual': {
          const selectedRows = selected.rows.size
          const hasSelectedCurrentPage = selectedRows === pagination.loaded
          const selectedLessThanTotal = selectedRows < pagination.total

          return hasSelectedCurrentPage && selectedLessThanTotal
        }
        case 'none':
          return false
      }
    })

  return (
    <View direction='column' gap='none'>
      <ToolbarContainer>
        {showCount ? (
          <ToolbarLeftContainer>
            <RenderCounts api={api} translationKeys={countsTranslationKeys} />
          </ToolbarLeftContainer>
        ) : (
          <div />
        )}
        <ToolbarRightContainer>
          {actions}
          {clearFilters && (
            <>
              <Divider>{'|'}</Divider>
              <TabularToolbarButton icon='clean' onClick={() => api.action.setFilter({ filter: identity })}>
                {t('manage.clear-filters')}
              </TabularToolbarButton>
            </>
          )}
        </ToolbarRightContainer>
      </ToolbarContainer>
      <AnimatePresence>
        <motion.div layout>
          {showAllSelection && (
            <SelectionContainer
              animated
              layout
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: -15, opacity: 1 }}
              exit={{ y: -30, opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              {selected.type === 'manual' && selected.rows.size === rows.length && <AllSelectionRow />}
              {selected.type === 'all' && <AllSelectedRow />}
            </SelectionContainer>
          )}
        </motion.div>
      </AnimatePresence>
    </View>
  )
}

export const SearchableToolbar: React.FC<{ onQuery: (q: string) => void; initialQuery?: string }> = ({
  onQuery,
  initialQuery,
}) => {
  const setTabularQuery = useDebouncedAction(onQuery, { wait: 300 })

  return <ToolbarSearch onQueryChange={setTabularQuery} initialQuery={initialQuery} />
}

export const SearchableTabularToolbar: React.FC<Omit<TabularToolbarProps, 'actions' | 'clearFilters'>> = ({
  api,
}) => {
  const setQuery = useCallback((query: string) => api.action.setQuery({ query }), [api.action])
  const [initialQuery] = useState(api.query.query().query)

  return <SearchableToolbar onQuery={setQuery} initialQuery={initialQuery} />
}

const TextNoWrap = styled(Text)`
  white-space: nowrap;
`

export type FilterMultiSelectMenuItem<TMenuItemId = string> = Omit<
  CheckboxMenuItem<TMenuItemId>,
  'checked' | 'type'
>

export const FilterMultiSelectTabular = <TMenuItemId extends string>({
  menuItems,
  onFilterChange,
}: {
  menuItems: FilterMultiSelectMenuItem<TMenuItemId>[]
  onFilterChange: (selectedItemIds: TMenuItemId[]) => void
}): JSX.Element => {
  const { t } = useTranslation()

  const [currentItems, setCurrentItems] = useState<MenuItem<TMenuItemId>[]>([])

  const allMenuItems: MenuItem<'any-type' | TMenuItemId>[] = [
    {
      id: 'any-type',
      type: 'checkbox',
      label: t('filter.content.any-type'),
      checked: currentItems.length === 0,
    },
    ...menuItems.map(
      (menuItem): MenuItem<TMenuItemId> => ({
        ...menuItem,
        type: 'checkbox',
        checked: currentItems.some(currentItem => currentItem.id === menuItem.id),
      })
    ),
  ]

  useOnChanged((_, items) => {
    onFilterChange(items.map(item => item.id))
  }, currentItems)

  const handleSelect = (item: MenuItem<'any-type' | TMenuItemId>): void => {
    if (item.id === 'any-type') {
      setCurrentItems([])
    } else {
      setCurrentItems(current => [...current, item as MenuItem<TMenuItemId>])
    }
  }

  const handleUnselect = (item: MenuItem): void => {
    setCurrentItems(current => current.filter(currenItem => currenItem.id !== item.id))
  }

  return (
    <MultiSelectDropdown
      placeholder={t('dictionary.filter')}
      menuItems={allMenuItems}
      selectedItems={currentItems}
      onSelect={handleSelect}
      onUnselect={handleUnselect}
      renderTrigger={() => {
        return (
          <DefaultDropdownTrigger variant='transparent' grow>
            <View padding='none'>
              <Icon iconId='filter' color='foreground/primary' />
              <TextNoWrap as='span' color='foreground/primary'>
                {currentItems.length === 0 ? (
                  t('dictionary.filter')
                ) : (
                  <TextNoWrap>{currentItems.map(item => item.label).join(', ')}</TextNoWrap>
                )}
              </TextNoWrap>
            </View>
          </DefaultDropdownTrigger>
        )
      }}
    />
  )
}
