import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SizeCss } from 'sierra-client/views/author/components/block-editor/v2/blocks/image'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { defaultToPlainEmbed, getEmbeddableUrl } from 'sierra-client/views/flexible-content/embed-card-utils'
import { Toolbar, ToolbarIcon, ToolbarSeparator } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { EmbedDataProvider } from 'sierra-client/views/v3-author/embed/embed-data-layer'
import {
  ConfigModal,
  EmbedWrapper,
  UrlEmbedWrapper,
  V3EmbedBlock,
} from 'sierra-client/views/v3-author/embed/utils'
import { EmbedInfo, getEmbedInfo } from 'sierra-client/views/v3-author/embed/utils/handlers'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { getUrlFromText } from 'sierra-domain/utils'
import { Embed as SlateEmbed } from 'sierra-domain/v3-author'
import { Button, InputPrimitive, Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const TextAreaWrapper = styled.div`
  width: 100%;
`

const Title = styled(Text).attrs({ bold: true, size: 'large' })``

const Content = styled.div`
  background-color: ${palette.grey[5]};
  border-radius: ${p => p.theme.borderRadius['size-10']};
  height: fit-content;
  position: relative;
  display: block;
  padding: 3rem;

  &&& textarea {
    margin: 1rem 0;
    padding: 1rem 0;
    background-color: ${palette.grey[5]};
    color: black;
    box-shadow: 0 1px 0 0 ${p => p.theme.color.grey10};
    border-radius: 0;

    &::placeholder {
      color: ${p => p.theme.color.grey35};
    }
  }
`

const EmbedToolbar: React.FC<{
  element: Entity<SlateEmbed>
  isEditing: boolean
  setIsEditing: (_: boolean) => void
  isUrlBlock?: boolean
}> = ({ element, setIsEditing, isEditing, isUrlBlock }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()

  const variant = element.variant ?? 'narrow'
  const readOnly = useEditorReadOnly()
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false)

  return (
    <>
      <Toolbar elementId={element.id} ignoreEditorFocus={true}>
        <ToolbarIcon
          tooltip={t('author.block-editor.image-center')}
          selected={variant === 'center'}
          iconId='resize--mini'
          onClick={() => {
            updateNodeWithId(editor, element.id, { variant: 'center' })
          }}
        />

        <ToolbarIcon
          tooltip={t('author.block-editor.image-narrow')}
          selected={variant === 'narrow'}
          iconId='resize--small'
          onClick={() => {
            updateNodeWithId(editor, element.id, { variant: 'narrow' })
          }}
        />

        <ToolbarIcon
          tooltip={t('author.block-editor.image-wide')}
          selected={variant === 'wide'}
          iconId='resize--medium'
          onClick={() => {
            updateNodeWithId(editor, element.id, { variant: 'wide' })
          }}
        />

        <ToolbarIcon
          tooltip={t('author.block-editor.image-fullWidth')}
          selected={variant === 'full-width'}
          iconId='resize--large'
          onClick={() => {
            updateNodeWithId(editor, element.id, { variant: 'full-width' })
          }}
        />

        <ToolbarSeparator />

        {!isEditing && isUrlBlock !== true && (
          <ToolbarIcon
            tooltip={t('author.block-editor.embed-edit')}
            iconId='edit'
            onClick={() => {
              setIsEditing(!isEditing)
            }}
          />
        )}
        {element.url !== '' && isUrlBlock === true && (
          <ToolbarIcon
            tooltip={t('author.block-editor.embed-edit')}
            iconId='unlink'
            onClick={() => {
              updateNodeWithId(editor, element.id, {
                url: '',
              })
            }}
          />
        )}
        {isUrlBlock !== true && (
          <ToolbarIcon
            tooltip={t('author.block-editor.embed-use-plain')}
            selected={element.data.type === 'plain'}
            iconId='code'
            onClick={() => {
              if (element.data.type === 'plain') {
                updateNodeWithId(editor, element.id, {
                  isMandatory: false,
                  data: {
                    type: 'legacy',
                  },
                })
              } else {
                updateNodeWithId(editor, element.id, {
                  data: {
                    type: 'plain',
                    height: undefined,
                  },
                })
              }
            }}
          />
        )}

        {element.data.type !== 'plain' && (
          <ToolbarIcon
            tooltip={t('author.block-editor.embed-disable-skipping')}
            selected={element.preventSeeking}
            iconId='view--off'
            onClick={() => {
              updateNodeWithId(editor, element.id, {
                preventSeeking: element.preventSeeking === undefined ? true : !element.preventSeeking,
              })
            }}
          />
        )}

        <ToolbarIcon
          tooltip={t('settings.settings')}
          selected={settingsModalIsOpen}
          iconId='settings'
          onClick={() => {
            setSettingsModalIsOpen(!settingsModalIsOpen)
          }}
        />

        <ToolbarSeparator />

        <ToolbarIcon
          tooltip={t('author.block-editor.remove')}
          iconId='trash-can'
          onClick={() => removeNodeWithId(editor, element.id)}
        />
      </Toolbar>

      {!readOnly && (
        <ConfigModal
          open={settingsModalIsOpen}
          onClose={() => setSettingsModalIsOpen(false)}
          block={element}
          update={block => updateNodeWithId(editor, element.id, block)}
        />
      )}
    </>
  )
}

const EmbedToolbarClickArea = styled.div<{
  customSize?: number
  variant?: SlateEmbed['variant']
  maxWidth?: number
  $withGrid: boolean
}>`
  position: relative;
  /* Workaround to be able to show the toolbar when we embed a URL (e.g. Figma) */
  padding: 16px 0;

  && {
    ${SizeCss}
  }
`

const EmbedInput: React.FC<{
  element: Entity<SlateEmbed>
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  handleUpdate: (input: string) => Promise<void>
  localUrl: string
  setLocalUrl: React.Dispatch<React.SetStateAction<string>>
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
}> = ({ element, setIsEditing, handleUpdate, localUrl, setLocalUrl, error, setError }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  const blockId = element.id

  return (
    <Content contentEditable={false} data-block-inner={blockId}>
      <Title>{t('author.block-editor.embed-content')}</Title>
      <TextAreaWrapper>
        <InputPrimitive
          value={localUrl}
          placeholder={t('author.block-editor.embed-placeholder')}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              void handleUpdate(event.currentTarget.value)
              event.preventDefault()
              return
            }

            if (event.key === 'Backspace' && !element.url && !localUrl) {
              removeNodeWithId(editor, element.id)
            }
          }}
          onChange={event => {
            setError('')
            setLocalUrl(event.currentTarget.value)
          }}
        />
        <Text size='small' color='redMedium' spacing='xsmall'>
          {error}
        </Text>
      </TextAreaWrapper>
      <Button
        variant='primary'
        onClick={() => {
          setIsEditing(false)
          void handleUpdate(localUrl)
        }}
      >
        {t('dictionary.save')}
      </Button>
    </Content>
  )
}

export const Embed: SlateFC = ({ mode, children, element, readOnly }) => {
  assertElementType('embed', element)

  const focused = useFocused()
  const selected = useSelected()
  const editor = useSlateStatic()
  const { withGrid } = useRenderingContext()

  const [isEditing, setIsEditing] = useState(element.url === '')
  const [error, setError] = useState<string>('')
  const [localUrl, setLocalUrl] = useState<string>(element.url)
  // NOTE: We want to re-render when the element is set, so don't use a ref here.
  const [embedEl, setEmbedEl] = useState<HTMLDivElement | null>(null)

  const editing = !readOnly && isEditing
  const isUrlDefined = element.url
  const blockId = element.id

  const handleUpdate = async (input: string): Promise<void> => {
    const urlFromText = getUrlFromText(input)
    const url = urlFromText !== undefined ? getEmbeddableUrl(urlFromText, element.urlType) : ''
    const isPlainEmbed = await defaultToPlainEmbed(url)
    if (isPlainEmbed) {
      updateNodeWithId(editor, blockId, {
        url,
        data: {
          type: 'plain',
        },
      })
      setLocalUrl(url)
      setIsEditing(false)
      setError('')

      return
    }

    try {
      const info: EmbedInfo = (await getEmbedInfo(url)) ?? { html: '' }

      updateNodeWithId(editor, element.id, {
        url,
        html: info.html,
        meta: { durationInSeconds: info.duration },
        data: {
          type: 'legacy',
        },
      })
      setLocalUrl(url)
      setIsEditing(false)
      setError('')
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
    }
  }

  if (element.urlType !== undefined) {
    return (
      <EmbedToolbarClickArea $withGrid={withGrid} variant={element.variant}>
        {children}
        <UrlEmbedWrapper
          customHeight={element.data.type === 'plain' ? element.data.height : undefined}
          contentEditable={false}
          showSelectionBorder={focused && selected}
          isLegacyEmbed={element.url !== '' && element.data.type === 'legacy'}
        >
          <EmbedCard
            data={{
              url: element.url,
              embedType: element.data.type,
              urlType: element.urlType,
              type: 'embed',
            }}
            onUploadDone={(url, embedType: 'legacy' | 'plain') => {
              updateNodeWithId(editor, element.id, {
                url,
                data: {
                  type: embedType,
                },
              })
            }}
            readOnly={readOnly}
          />
          <EmbedToolbar
            element={element}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isUrlBlock={true}
          />
        </UrlEmbedWrapper>
      </EmbedToolbarClickArea>
    )
  }

  return (
    <EmbedDataProvider element={element} mode={mode}>
      <EmbedToolbarClickArea $withGrid={withGrid} variant={element.variant}>
        <EmbedWrapper ref={setEmbedEl} data-block-inner={blockId} showSelectionBorder={focused && selected}>
          {children}
          <div contentEditable={false}>
            {editing ? (
              <EmbedInput
                element={element}
                handleUpdate={handleUpdate}
                setIsEditing={setIsEditing}
                error={error}
                setError={setError}
                localUrl={localUrl}
                setLocalUrl={setLocalUrl}
              />
            ) : isUrlDefined ? (
              <V3EmbedBlock embedEl={embedEl} element={element} />
            ) : null}
          </div>
          <BlockCommentIndicator element={element} />
        </EmbedWrapper>

        <EmbedToolbar element={element} isEditing={isEditing} setIsEditing={setIsEditing} />
      </EmbedToolbarClickArea>
    </EmbedDataProvider>
  )
}
