import React, { useCallback } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { SlidingScaleDataProvider } from 'sierra-client/views/v3-author/sliding-scale-card/data-layer'
import { CreateSlider, Slider } from 'sierra-client/views/v3-author/sliding-scale-card/slider'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { color } from 'sierra-ui/color'
import { Spacer, Text } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { height_100dvh } from 'sierra-ui/utils'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const AddParagraphArea = styled.div`
  ${height_100dvh}
  width: 100%;
  position: absolute;
  bottom: 100%;
  cursor: text;
`
const interactiveCardGrid = css`
  row-gap: 0;
  padding: 3rem 0rem;

  & {
    --middle: minmax(1rem, 6rem);
    --gutter: 1fr;

    @media screen and (max-width: ${v2_breakpoint.phone}) {
      --gutter: 4%;
    }
  }
`

const SlidingInteractiveCardWrapper = styled(BlockWrapper)<{ isInGeneralCard: boolean }>`
  ${p => !p.isInGeneralCard && interactiveCardGrid}
`

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > * {
    width: 240px;
    max-width: 45%;

    :last-of-type {
      text-align: right;
    }
  }
`

const SliderContainer = styled.div<{ $mobile: boolean }>`
  background-color: ${p => color(p.theme.home.textColor).opacity(0.03)};
  padding-inline: ${p => (p.$mobile ? 16 : 32)}px;
  padding-top: 104px;
  padding-bottom: 72px;
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
`

const TopInstructionsContainer = styled.div`
  position: absolute;
  bottom: 100%;
  margin-bottom: 20px;
  left: 0;
`

const Instructions: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TopInstructionsContainer>
      <Text size='regular' bold>
        {t('learner.sliding-scale.place-yourself-on-the-scale')}
      </Text>
    </TopInstructionsContainer>
  )
}

export const SlidingScaleCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element, readOnly } = props
  assertElementType('sliding-scale-card', element)
  const userId = useSelector(selectUserId)
  const editor = useSlateStatic()
  const editOrResponse = useEditOrResponsesStateSafe()
  const { t } = useTranslation()

  const isMobile = useIsMobile()

  const isInGeneralCard = props.file?.data.type === 'general'

  const firstNode = editor.children[0]
  const hasNoParagraph = isElementType('sliding-scale-card', firstNode)

  const addParagraph = useCallback(() => {
    if (readOnly) return

    Transforms.insertNodes(editor, createParagraph(), { at: [0] })
    Transforms.setSelection(editor, {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    })
  }, [editor, readOnly])

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>
  else
    return (
      <RenderingContext preventDrag={true} disableMenu={false} withGrid={true}>
        {hasNoParagraph && !isInGeneralCard && <div contentEditable={false} style={{ height: '24px' }}></div>}

        <SlidingInteractiveCardWrapper isInGeneralCard={isInGeneralCard} {...props} {...attributes} ref={ref}>
          {hasNoParagraph && (
            <AddParagraphArea contentEditable={false} onClick={addParagraph}></AddParagraphArea>
          )}
          <RenderingContext withGrid={false}>
            <SlidingScaleDataProvider element={element} mode={mode}>
              {isInGeneralCard ? (
                <>
                  <Text contentEditable={false} size='small' bold>
                    {t('learner.sliding-scale.place-yourself-on-the-scale')}
                  </Text>
                  <Spacer size='8' contentEditable={false} />
                  <SliderContainer $mobile={isMobile}>
                    {editOrResponse?.type === 'edit' || mode === 'template' || mode === 'version-history' ? (
                      <CreateSlider />
                    ) : (
                      <Slider />
                    )}
                    {children}
                  </SliderContainer>
                </>
              ) : (
                <>
                  <div contentEditable={false}>
                    {editOrResponse?.type === 'edit' || mode === 'template' || mode === 'version-history' ? (
                      <CreateSlider instructions={<Instructions />} />
                    ) : (
                      <Slider instructions={<Instructions />} />
                    )}
                  </div>
                  {children}
                </>
              )}
            </SlidingScaleDataProvider>
          </RenderingContext>
        </SlidingInteractiveCardWrapper>
      </RenderingContext>
    )
})

export const SlidingScaleCard: SlateFC = ({ element, children }) => {
  assertElementType('sliding-scale-card', element)

  return <OptionsContainer>{children}</OptionsContainer>
}
