import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { DueDateRelativeInterval, Interval, IntervalUnit, RecurrenceSource } from 'sierra-domain/api/manage'
import { assertNever } from 'sierra-domain/utils'

export const intervalToString = (interval: Interval, t: TranslationLookup): string => {
  const count = interval.value
  const unit = interval.unit
  switch (unit) {
    case 'days':
      return t('recurrence.cadence-days', { count })
    case 'months':
      return t('recurrence.cadence-months', { count })
    case 'years':
      return t('recurrence.cadence-years', { count })
    default:
      assertNever(unit)
  }
}

const intervalUnitToISODuration = (unit: IntervalUnit): 'D' | 'M' | 'Y' => {
  switch (unit) {
    case 'days':
      return 'D'
    case 'months':
      return 'M'
    case 'years':
      return 'Y'
    default:
      return unit
  }
}

export const recurrencePeriodToISODuration = (recurrencePeriod: Interval): string =>
  `${recurrencePeriod.value}${intervalUnitToISODuration(recurrencePeriod.unit)}`

export const assignmentRecurrenceSourceTitle = (
  assignmentRecurrenceSource: RecurrenceSource
): string | undefined => {
  switch (assignmentRecurrenceSource.type) {
    case 'direct':
      return assignmentRecurrenceSource.courseTitle
    case 'program':
      return assignmentRecurrenceSource.programTitle
    default:
      assertNever(assignmentRecurrenceSource)
  }
}

export const assignmentRecurrenceSourceContentType = (
  assignmentRecurrenceSource: RecurrenceSource,
  t: TranslationLookup
): string => {
  switch (assignmentRecurrenceSource.type) {
    case 'direct':
      return t('content-table.types.course').toLowerCase()
    case 'program':
      return t('content-table.types.program').toLowerCase()
    default:
      assertNever(assignmentRecurrenceSource)
  }
}

export const assignmentRecurrenceSourceHref = (assignmentRecurrenceSource: RecurrenceSource): string => {
  switch (assignmentRecurrenceSource.type) {
    case 'direct':
      return `/manage/courses/${assignmentRecurrenceSource.courseId}`
    case 'program':
      return `/manage/programs/${assignmentRecurrenceSource.programId}`
    default:
      assertNever(assignmentRecurrenceSource)
  }
}

const daysToInterval = (days: number): Interval => {
  if (days < 30) {
    return { unit: 'days', value: days }
  }
  if (days < 365) {
    return { unit: 'months', value: Math.floor(days / 30) }
  }
  return { unit: 'years', value: Math.floor(days / 365) }
}

export const closestRelativeDate = (to: Date, from: Date = new Date()): DueDateRelativeInterval => {
  const diff = to.getTime() - from.getTime()
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
  return { type: 'relative', interval: daysToInterval(days) }
}

const compareIntervalUnit = (a: IntervalUnit, b: IntervalUnit): number => {
  const order = ['days', 'months', 'years']
  return order.indexOf(a) - order.indexOf(b)
}

export const compareInterval = (a: Interval, b: Interval): number => {
  if (a.unit === b.unit) {
    return a.value - b.value
  }
  return compareIntervalUnit(a.unit, b.unit)
}
