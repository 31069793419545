import React from 'react'
import {
  assignmentRecurrenceSourceContentType,
  assignmentRecurrenceSourceHref,
  assignmentRecurrenceSourceTitle,
  intervalToString,
  recurrencePeriodToISODuration,
} from 'sierra-client/components/recurrent-assignments/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { AssignmentRecurrence, Interval, RecurrenceSource } from 'sierra-domain/api/manage'
import { assertNever } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Button, Pill, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const DividerVertical = styled.div`
  width: 1px;
  height: 1rem;
  background-color: white;
  opacity: 0.3;
`

const UnstyledButton = styled(Button)`
  padding: 0;
  height: 0;
`

const SmallPill = styled(Pill)`
  padding: 0.08rem 0.3rem;
`

const SmallText = styled(TruncatedText)`
  font-size: 0.625rem;
`

export const repeatViaThisString = (
  cadence: Interval,
  source: RecurrenceSource['type'],
  t: TranslationLookup
): string => {
  switch (source) {
    case 'direct':
      return t('recurrence.tooltip.repeats-via-this-course', {
        cadence: intervalToString(cadence, t).toLowerCase(),
      })
    case 'program':
      return t('recurrence.tooltip.repeats-via-this-program', {
        cadence: intervalToString(cadence, t).toLowerCase(),
      })
    default:
      assertNever(source)
  }
}

/*
 displayedInSource is used to inform this component where is going to be displayed. So for
 example, if it's displayed in a course and the recurrence comes from the same course, we
 don't need to show a link to the course in the tooltip because we are already there.
 */
export const RecurrentAssignmentIcon: React.FC<{
  assignmentRecurrence: AssignmentRecurrence
  displayedInSource: RecurrenceSource['type']
}> = ({ assignmentRecurrence, displayedInSource }) => {
  const { t } = useTranslation()
  const assignmentRecurrenceSource = assignmentRecurrence.recurrenceSource
  const sourceTitle = assignmentRecurrenceSourceTitle(assignmentRecurrenceSource)
  return (
    <Tooltip
      side='top'
      title={
        <>
          <Text color='white'>
            {assignmentRecurrenceSource.type !== displayedInSource
              ? t('recurrence.tooltip.repeats-via', {
                  cadence: intervalToString(assignmentRecurrence.recurrence, t).toLowerCase(),
                  contentType: assignmentRecurrenceSourceContentType(assignmentRecurrenceSource, t),
                })
              : repeatViaThisString(assignmentRecurrence.recurrence, displayedInSource, t)}
          </Text>
          {sourceTitle !== undefined && assignmentRecurrenceSource.type !== displayedInSource && (
            <>
              <DividerVertical />
              <UnstyledButton
                // We only expect to find 'direct' if it is the same course as this
                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                href={assignmentRecurrenceSourceHref(assignmentRecurrenceSource)}
                rel='noopener noreferrer'
                target='_blank'
              >
                {sourceTitle}
              </UnstyledButton>
            </>
          )}
        </>
      }
    >
      <SmallPill variant={'ghost'}>
        <Icon iconId={'restart'} size={'size-10'} color={'foreground/muted'} />
        <SmallText size={'technical'} color={'foreground/muted'} bold>
          {recurrencePeriodToISODuration(assignmentRecurrence.recurrence)}
        </SmallText>
      </SmallPill>
    </Tooltip>
  )
}
