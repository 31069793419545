import { isNaN, parseInt } from 'lodash'
import { DateTime, Duration } from 'luxon'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { TimerButton, TimerState, TimerText } from 'sierra-client/components/liveV2/timer-button'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { Logging } from 'sierra-client/core/logging'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getServerTimeNow, selectClock } from 'sierra-client/state/collaboration/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { startTimer } from 'sierra-client/state/live-session/actions'
import { selectTimerEndTime } from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { color } from 'sierra-ui/color'
import { Icon, Modal, Tooltip } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, useTheme } from 'styled-components'

const ringingAnimation = {
  initial: {
    rotate: 0,
  },
  ringing: {
    rotate: [
      0, 1, 0, -1, 0, 2, 0, -2, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3,
      0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3,
      0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 2, 0, -2, 0, 1, 0, -1, 0,
    ],
    transition: {
      rotate: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
    },
  },
}

const DismissView = styled(View).attrs({ justifyContent: 'center' })`
  border-top: 1px solid ${p => p.theme.color.grey10};
  align-self: stretch;
  padding-top: 0.5rem;
  cursor: pointer;
`

export const CardTimer = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const card = useSelectCurrentCard()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const endTime = useSelector(selectTimerEndTime)

  const [timesUpModalSent, setTimesUpModalSent] = useState<boolean>(false)
  const clock = useSelector(selectClock)
  const { t } = useTranslation()

  const breakoutInSession = useBreakoutInSession()
  const wasInBreakout = usePrevious(breakoutInSession)

  const [time, setTime] = useState<string>('')

  const [modalOpen, setModalOpen] = useState(false)
  const [breakoutRoomModalOpen, setBreakoutRoomModalOpen] = useState(false)
  const [countDownTimeRemaining, setCountDownTimeRemaining] = useState<Duration | undefined>()

  const previousCardId = useRef<string | undefined>(card?.id)

  const handleStartTimer = useCallback((): void => {
    const [minutes, seconds] = time.split(':').map(parseInt)

    let duration = minutes !== undefined && !isNaN(minutes) ? minutes * 60 : 0
    duration += seconds !== undefined && !isNaN(seconds) ? seconds : 0

    void dispatch(startTimer({ durationSeconds: duration }))
    void dispatch(Logging.liveSession.timerStarted())

    setModalOpen(false)
    setTimesUpModalSent(false)
  }, [dispatch, time])

  const handleResetTimer = useCallback((): void => {
    void dispatch(liveSessionSlice.actions.resetTimer())
    setCountDownTimeRemaining(undefined)
    setModalOpen(false)
  }, [dispatch])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (endTime === undefined) {
      setCountDownTimeRemaining(undefined)
    } else {
      interval = setInterval(() => {
        const now = DateTime.fromISO(getServerTimeNow(clock))
        const diff = endTime.diff(now)
        if (diff.as('seconds') < 0) {
          setCountDownTimeRemaining(Duration.fromMillis(0))
          clearInterval(interval)
          if (breakoutInSession && timesUpModalSent === false) {
            setBreakoutRoomModalOpen(true)
            setTimesUpModalSent(true)
          }
        } else {
          setCountDownTimeRemaining(diff)
          setBreakoutRoomModalOpen(false)
          setTimesUpModalSent(false)
        }
      }, 200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [breakoutInSession, clock, endTime, timesUpModalSent])

  const handleCloseBreakoutRooms = useCallback((): void => {
    setBreakoutRoomModalOpen(false)
    void dispatch(liveSessionSlice.actions.stopBreakoutSession())
    handleResetTimer()
  }, [dispatch, handleResetTimer])

  // Switching card should reset the timer
  useEffect(() => {
    if (card?.id !== previousCardId.current && countDownTimeRemaining?.as('milliseconds') === 0) {
      handleResetTimer()
      previousCardId.current = card?.id
      setBreakoutRoomModalOpen(false)
    }
  }, [card, countDownTimeRemaining, handleResetTimer])

  const handleTimeChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value

    if (newValue === '') {
      setTime(newValue)
      return
    }

    const match = /^[0-9]{1,2}(:[0-5]?[0-9]?)?$/gi.test(newValue)
    if (!match) return

    setTime(newValue)
  }

  useEffect(() => {
    if (wasInBreakout === true && breakoutInSession === false) {
      void dispatch(liveSessionSlice.actions.resetTimer())
      setBreakoutRoomModalOpen(false)
    }
  }, [breakoutInSession, wasInBreakout, dispatch])

  const theme = useTheme()
  const iconColor = color(theme.home.textColor)

  const showModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const setTimerText = t('dictionary.set-timer')
  const editTimerText = t('dictionary.edit-timer')
  const active = countDownTimeRemaining

  const getTimerState = (): TimerState => {
    if (countDownTimeRemaining === undefined) return 'normal'
    if (countDownTimeRemaining.as('seconds') <= 1) return 'done'
    if (countDownTimeRemaining.as('seconds') <= 10) return 'warning'
    return 'normal'
  }

  // If no timer is set, don't show anything for a learner
  if (!isFacilitatorOrLearnerLedSession && countDownTimeRemaining === undefined) return null

  return (
    <>
      <Tooltip title={isFacilitatorOrLearnerLedSession ? (active ? editTimerText : setTimerText) : ''}>
        <TimerButton
          id='tour-live-timer'
          $clickable={isFacilitatorOrLearnerLedSession}
          onClick={isFacilitatorOrLearnerLedSession ? () => showModal() : undefined}
          $timerState={getTimerState()}
          initial='initial'
          animate={getTimerState() === 'done' ? 'ringing' : 'initial'}
          variants={ringingAnimation}
        >
          <Icon iconId='timer' color='foreground/primary' />
          {countDownTimeRemaining !== undefined && (
            <TimerText size='small'>{countDownTimeRemaining.toFormat('mm:ss')}</TimerText>
          )}
        </TimerButton>
      </Tooltip>
      {isFacilitatorOrLearnerLedSession && (
        <ShortcutMenu.Action
          group='live'
          permission='ACCESS_LMS'
          iconId='timer'
          label={{ type: 'untranslated', value: setTimerText }}
          run={showModal}
        />
      )}
      <AppThemeTokenProvider followSystemColorScheme={false}>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)} size={{ width: 400 }} padding='24'>
          <View grow paddingBottom='8' gap='4' alignItems='center'>
            <Icon color={iconColor} iconId='timer' />
            <Text color='foreground/primary' size='regular' style={{ flexShrink: 0 }}>
              Timer
            </Text>
            <Icon color={iconColor} iconId='chevron--right--small'></Icon>
            <Text size='regular' color='grey50'>
              {card?.title}
            </Text>
          </View>
          <View direction='column'>
            <InputPrimitive
              id='timer-input'
              type='text'
              onChange={handleTimeChanged}
              value={time}
              placeholder='02:00'
              onKeyDown={e => {
                if (e.key === 'Enter') handleStartTimer()
              }}
            />
            <View gap='xsmall'>
              <Button disabled={time === ''} onClick={handleStartTimer}>
                {t('dictionary.start')}
              </Button>
              <Button variant='secondary' onClick={handleResetTimer}>
                {t('dictionary.reset')}
              </Button>
            </View>
          </View>
        </Modal>
      </AppThemeTokenProvider>
      {isFacilitatorOrLearnerLedSession && (
        <Modal
          size={'fit-content'}
          open={breakoutRoomModalOpen}
          onClose={() => setBreakoutRoomModalOpen(false)}
        >
          <ThemeProvider theme={legacyLight}>
            <View
              alignItems='center'
              direction='column'
              gap='medium'
              paddingTop='large'
              paddingLeft='small'
              paddingRight='small'
              paddingBottom='xsmall'
            >
              <Icon iconId='timer' />
              <Text color='foreground/primary' size='regular' bold>
                {' '}
                {"Time's up!"}
              </Text>
              <View padding='none medium'>
                <Button onClick={handleCloseBreakoutRooms}>Close rooms</Button>
                <Button
                  variant='secondary'
                  onClick={() => {
                    setModalOpen(true)
                    setBreakoutRoomModalOpen(false)
                  }}
                >
                  Extend time
                </Button>
              </View>
              <DismissView onClick={() => setBreakoutRoomModalOpen(false)}>
                <Text bold color='foreground/muted'>
                  Dismiss
                </Text>
              </DismissView>
            </View>
          </ThemeProvider>
        </Modal>
      )}
    </>
  )
}
