import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import {
  getDaysDiff,
  toLocalTimeFormat,
  useGetDaysLeft,
  useGetFormattedTime,
} from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { OverdueLabel } from 'sierra-client/views/learner/home/components/overdue-label'
import { getEnrollmentStepTypeId } from 'sierra-client/views/learner/program/program-page'
import { useGetLearnerProgram } from 'sierra-client/views/learner/program/use-get-learner-program'
import { EnrollmentData } from 'sierra-client/views/learner/program/utils/step-graphql'
import { LearnEntity, LearnProgram } from 'sierra-domain/api/entities'
import { isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CalendarWrapper = styled(View)`
  border-radius: 10px;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #00000014;
  box-shadow: 0px 0px 0px 1px #0000000a;
`
type PotentialNextUpSession =
  | {
      type: 'enrolled'
      date: string
      startTime: string
      rawStartTime: string
    }
  | {
      type: 'not-enrolled'
    }
  | undefined

type NextUpInProgramMetaData = {
  title: string | undefined
  potentialNextUpSession: PotentialNextUpSession
  stepNumber?: number
}

const getNextUpCalendarEventInformation = ({
  contentId,
  contentTitle,
  enrollment,
  stepNumber,
  t,
}: {
  contentId: string
  contentTitle: string
  enrollment: EnrollmentData | undefined
  stepNumber: number | undefined
  t: TranslationLookup
}): NextUpInProgramMetaData | undefined => {
  if (enrollment === undefined || enrollment === null) {
    return undefined
  }

  const enrollmentStep = enrollment.steps.find(s => getEnrollmentStepTypeId(s) === contentId)

  if (enrollmentStep === undefined) {
    return undefined
  }

  const assignedCalendarEvents =
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    enrollmentStep.content.__typename === 'NativeEventGroup'
      ? enrollmentStep.content.assignedCalendarEvents
      : undefined

  if (assignedCalendarEvents !== undefined && assignedCalendarEvents.length > 0) {
    const assignedCalendarEvent = assignedCalendarEvents[0]

    if (assignedCalendarEvent?.schedule.__typename === 'CalendarEventScheduleWithTime') {
      return {
        title: contentTitle,
        potentialNextUpSession: {
          type: 'enrolled',
          date: DateTime.fromISO(assignedCalendarEvent.schedule.startTime).toFormat('MMM d'),
          startTime: toLocalTimeFormat(DateTime.fromISO(assignedCalendarEvent.schedule.startTime)),
          rawStartTime: assignedCalendarEvent.schedule.startTime,
        },
        stepNumber,
      }
    } else if (assignedCalendarEvent?.schedule.__typename === 'CalendarEventScheduleAllDay') {
      return {
        title: contentTitle,
        potentialNextUpSession: {
          type: 'enrolled',
          date: DateTime.fromISO(assignedCalendarEvent.schedule.startDate).toFormat('MMM d'),
          startTime: t('dictionary.all-day'),
          rawStartTime: assignedCalendarEvent.schedule.startDate,
        },
        stepNumber,
      }
    }
  }

  const upcomingSelfEnrollmentCalendarEvents =
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    enrollmentStep.content.__typename === 'NativeEventGroup'
      ? enrollmentStep.content.upcomingSelfEnrollmentCalendarEvents
      : undefined

  if (upcomingSelfEnrollmentCalendarEvents === undefined) {
    return undefined
  }

  const enrolledCalendarEvent = upcomingSelfEnrollmentCalendarEvents.find(it => it.userAssignment !== null)

  if (enrolledCalendarEvent === undefined && upcomingSelfEnrollmentCalendarEvents.length > 0) {
    return {
      title: contentTitle,
      potentialNextUpSession: {
        type: 'not-enrolled',
      },
      stepNumber,
    }
  }

  if (enrolledCalendarEvent === undefined) {
    return undefined
  }

  if (enrolledCalendarEvent.schedule.__typename === 'CalendarEventScheduleWithTime') {
    return {
      title: enrolledCalendarEvent.title,
      potentialNextUpSession: {
        type: 'enrolled',
        date: DateTime.fromISO(enrolledCalendarEvent.schedule.startTime).toFormat('MMM d'),
        startTime: toLocalTimeFormat(DateTime.fromISO(enrolledCalendarEvent.schedule.startTime)),
        rawStartTime: enrolledCalendarEvent.schedule.startTime,
      },
      stepNumber,
    }
  } else {
    return {
      title: enrolledCalendarEvent.title,
      potentialNextUpSession: {
        type: 'enrolled',
        date: DateTime.fromISO(enrolledCalendarEvent.schedule.startDate).toFormat('MMM d'),
        startTime: t('dictionary.all-day'),
        rawStartTime: enrolledCalendarEvent.schedule.startDate,
      },
      stepNumber,
    }
  }
}

const getNextUpSessionInformation = ({
  contentId,
  contentTitle,
  enrollment,
  stepNumber,
}: {
  contentId: string
  contentTitle: string
  enrollment: EnrollmentData | undefined
  stepNumber: number | undefined
}): NextUpInProgramMetaData | undefined => {
  if (enrollment === undefined || enrollment === null) {
    return undefined
  }

  const enrollmentStep = enrollment.steps.find(s => getEnrollmentStepTypeId(s) === contentId)

  if (enrollmentStep === undefined) {
    return undefined
  }

  const assignedSessions =
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    enrollmentStep.content.__typename === 'NativeLiveCourse'
      ? (enrollmentStep.assignedLiveSessions ?? undefined)
      : undefined

  if (assignedSessions !== undefined && assignedSessions.length > 0) {
    const assignedSession = assignedSessions[0]
    return assignedSession?.liveSession.__typename === 'ScheduledLiveSession'
      ? {
          title: assignedSession.liveSession.title,
          potentialNextUpSession: {
            type: 'enrolled',
            date: DateTime.fromISO(assignedSession.liveSession.startTime).toFormat('MMM d'),
            startTime: toLocalTimeFormat(DateTime.fromISO(assignedSession.liveSession.startTime)),
            rawStartTime: assignedSession.liveSession.startTime,
          },
          stepNumber,
        }
      : undefined
  }

  const upcomingSelfEnrollmentLiveSessions =
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    enrollmentStep.content.__typename === 'NativeLiveCourse'
      ? (enrollmentStep.upcomingSelfEnrollmentLiveSessions ?? undefined)
      : undefined

  if (upcomingSelfEnrollmentLiveSessions === undefined) {
    return undefined
  }

  const enrolledSession = upcomingSelfEnrollmentLiveSessions.find(it => it.isEnrolled)

  if (enrolledSession === undefined && upcomingSelfEnrollmentLiveSessions.length > 0) {
    return {
      title: contentTitle,
      potentialNextUpSession: {
        type: 'not-enrolled',
      },
      stepNumber,
    }
  }

  if (enrolledSession === undefined) {
    return undefined
  }

  return {
    title: enrolledSession.liveSession.title,
    potentialNextUpSession: {
      type: 'enrolled',
      date: DateTime.fromISO(enrolledSession.liveSession.startTime).toFormat('MMM d'),
      startTime: toLocalTimeFormat(DateTime.fromISO(enrolledSession.liveSession.startTime)),
      rawStartTime: enrolledSession.liveSession.startTime,
    },
    stepNumber,
  }
}

export const useProgramNextUpSessions = (
  programEntity: LearnProgram
): NextUpInProgramMetaData | undefined => {
  const programId = programEntity.id
  const enrollmentQuery = useGetLearnerProgram({ programId })
  const { t } = useTranslation()

  const nextStep = programEntity.steps[0]
  if (nextStep === undefined) return undefined

  const nextUpIsLiveContent = nextStep.type === 'course' && nextStep.course.entityType === 'native:live'
  const nextUpIsEventGroup = nextStep.type === 'course' && nextStep.course.entityType === 'native:event-group'

  const liveContentStepIds = programEntity.steps
    .filter(it => it.type === 'course' && it.course.entityType === 'native:live')
    .map(it => {
      return {
        id: it.id,
        title: it.type === 'course' ? it.course.title : it.path.title,
        stepNumber: programEntity.steps.map(s => s.id).indexOf(it.id) + 1,
      }
    })

  const eventStepIds = programEntity.steps
    .filter(it => it.type === 'course' && it.course.entityType === 'native:event-group')
    .map(it => {
      return {
        id: it.id,
        title: it.type === 'course' ? it.course.title : it.path.title,
        stepNumber: programEntity.steps.map(s => s.id).indexOf(it.id) + 1,
      }
    })

  // If next step is live content we want to return the enrolled session information
  if (nextUpIsLiveContent) {
    const nextUpSession = getNextUpSessionInformation({
      contentId: nextStep.id,
      contentTitle: nextStep.course.title,
      enrollment: enrollmentQuery.data?.me.enrollment,
      stepNumber: undefined,
    })
    return nextUpSession
  }

  if (nextUpIsEventGroup) {
    const nextUpCalendarEvent = getNextUpCalendarEventInformation({
      contentId: nextStep.id,
      contentTitle: nextStep.course.title,
      enrollment: enrollmentQuery.data?.me.enrollment,
      stepNumber: undefined,
      t,
    })
    return nextUpCalendarEvent
  }

  // If the next step is not a live content nor event group but we have a
  // live content/event group with a session/event within 24 hours
  // we want to return the session/event information
  const nextUpSessions = liveContentStepIds.map(it =>
    getNextUpSessionInformation({
      contentId: it.id,
      contentTitle: it.title,
      enrollment: enrollmentQuery.data?.me.enrollment,
      stepNumber: it.stepNumber,
    })
  )

  const nextUpCalendarEvents = eventStepIds.map(it =>
    getNextUpCalendarEventInformation({
      contentId: it.id,
      contentTitle: it.title,
      enrollment: enrollmentQuery.data?.me.enrollment,
      stepNumber: it.stepNumber,
      t,
    })
  )

  const sessionWithin24Hours = nextUpSessions.find(it => {
    return (
      it?.potentialNextUpSession?.type === 'enrolled' &&
      DateTime.fromISO(it.potentialNextUpSession.rawStartTime).hasSame(
        DateTime.now().plus({ days: 1 }),
        'day'
      )
    )
  })

  const calendarEventWithin24Hours = nextUpCalendarEvents.find(it => {
    return (
      it?.potentialNextUpSession?.type === 'enrolled' &&
      DateTime.fromISO(it.potentialNextUpSession.rawStartTime).hasSame(
        DateTime.now().plus({ days: 1 }),
        'day'
      )
    )
  })

  const mostRecentUpcoming = _.sortBy(
    [sessionWithin24Hours, calendarEventWithin24Hours],
    it =>
      it?.potentialNextUpSession?.type === 'enrolled' &&
      DateTime.fromISO(it.potentialNextUpSession.rawStartTime)
  ).reverse()[0]

  if (mostRecentUpcoming !== undefined) {
    return mostRecentUpcoming
  }

  return {
    title: nextStep.type === 'course' ? nextStep.course.title : nextStep.path.title,
    potentialNextUpSession: undefined,
  }
}

const calculateDateDifference = (dateString: string): number => {
  const today: Date = new Date()
  today.setHours(0, 0, 0, 0)

  const givenDate: Date = new Date(dateString + 'T00:00:00')
  givenDate.setHours(0, 0, 0, 0)

  const difference: number = Math.floor((givenDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
  return difference
}

export const AssignedProgramLiveContentMetadata: React.FC<{
  time: {
    date: string
    startTime: string
  }
}> = ({ time }) => {
  const { t } = useTranslation()
  const difference = calculateDateDifference(time.date)

  return (
    <CalendarWrapper direction='column' gap='none' alignItems='center' justifyContent='center'>
      <Text bold size='micro' color='redMedium'>
        {difference === 0 ? t('dictionary.today') : time.date}
      </Text>
      <Text bold size='micro'>
        {time.startTime}
      </Text>
    </CalendarWrapper>
  )
}

export const useGetMetaData = (
  entity: LearnEntity
): { labelContent: JSX.Element | null; formattedTimeLeft: string; dueDate: string | undefined } => {
  const { getFormattedDaysLeft } = useGetDaysLeft()
  const totalTime = entity.timeEstimate
  const progress = entity.learnerContext.progress
  const hasStarted = progress > 0
  const timeLeft = hasStarted ? Math.round((1 - progress) * totalTime) : totalTime
  const dueDate = entity.learnerContext.assignment?.dueDate
  const pendingActions = entity.learnerContext.pendingActions

  const formattedTimeLeft = useGetFormattedTime(timeLeft, hasStarted)
  const formattedDueDate = getFormattedDaysLeft(dueDate)
  const isOverdue = isDefined(dueDate) && new Date(dueDate) < new Date()

  const isDueToday =
    isDefined(dueDate) && new Date(dueDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  const daysLeft = isDefined(dueDate) ? getDaysDiff(DateTime.fromISO(dueDate)) : null
  const isDueWithinFiveDays = daysLeft !== null && daysLeft > 0 && daysLeft <= 5
  const hasPendingActions = isDefined(pendingActions) ? pendingActions > 0 : true

  const getLabelContent = (): JSX.Element | null => {
    if (!isDefined(formattedDueDate)) return null
    if (!hasPendingActions) return null

    if (isDueToday || isOverdue) {
      return <OverdueLabel $bgColor='redVivid'>{formattedDueDate}</OverdueLabel>
    }

    if (isDueWithinFiveDays) {
      return <OverdueLabel $bgColor='orangeVivid'>{formattedDueDate}</OverdueLabel>
    }

    return <Text color='foreground/secondary'>{formattedDueDate}</Text>
  }

  const labelContent = getLabelContent()

  return { labelContent, formattedTimeLeft, dueDate }
}

export const AssignedProgramContentMetadata: React.FC<{ entity: LearnProgram }> = ({ entity }) => {
  const { labelContent, formattedTimeLeft } = useGetMetaData(entity)
  const nextUpInformation = useProgramNextUpSessions(entity)

  return (
    <View gap='2'>
      {labelContent}
      {isDefined(labelContent) && isDefined(formattedTimeLeft) && (
        <Icon color='foreground/secondary' iconId='radio-button--dot' />
      )}
      <Text color='foreground/secondary'>{formattedTimeLeft}</Text>
      <Text color='foreground/secondary'>
        {nextUpInformation === undefined ? (
          <Trans
            i18nKey={'recommendation.program-step-completed-of-total' satisfies TranslationKey}
            values={{ completedCount: entity.completedStepCount + 1, totalCount: entity.totalStepCount }}
            components={{
              bold: <strong />,
            }}
          />
        ) : (
          <Trans
            i18nKey={'home.assigned.program.step-of-step' satisfies TranslationKey}
            values={{
              completedCount: nextUpInformation.stepNumber ?? entity.completedStepCount + 1,
              totalCount: entity.totalStepCount,
            }}
            components={{
              bold: <strong />,
            }}
          />
        )}
      </Text>
      {nextUpInformation !== undefined && (
        <>
          <Icon iconId='radio-button--dot' color='foreground/secondary' />
          <Text color='foreground/secondary'>{nextUpInformation.title}</Text>
        </>
      )}
    </View>
  )
}
