import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'sierra-client/state/types'
import { UserState } from 'sierra-client/state/user/user-types'
import { isOwner } from 'sierra-domain/access-level'

export const selectUserState = (state: RootState): UserState => state.user

export const selectUser = createSelector(selectUserState, ({ status }) => {
  if (status.type === 'user') {
    return status.user
  } else {
    return undefined
  }
})

export const selectStaffUser = createSelector(selectUser, user =>
  user && isOwner(user.accessRole, user.accessLevel) ? user : undefined
)
export const selectIsOwnerUser = createSelector(selectStaffUser, user => !!user)

export const selectAvatarColor = createSelector(selectUser, user => user?.avatarColor)
export const selectUserId = createSelector(selectUser, user => user?.uuid)
