import { useEffect, useState } from 'react'
import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { DebugJsonView } from 'sierra-client/components/common/debug-json-view'
import { Portal } from 'sierra-client/components/util/portal'
import { useSelector } from 'sierra-client/state/hooks'
import { selectNotificationErrors } from 'sierra-client/state/notifications/selectors'
import { Modal } from 'sierra-ui/components'
import { Heading, IconButton } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
`

const ReduxErrorsButton = (): JSX.Element | null => {
  const errors = useSelector(selectNotificationErrors)
  const [isOpen, setIsOpen] = useState(false)
  const [errorsHaveBeenSeen, setErrorsHaveBeenSeen] = useState(false)

  useEffect(() => {
    setErrorsHaveBeenSeen(false)
  }, [errors])

  const handleClick = (): void => {
    setErrorsHaveBeenSeen(true)
    setIsOpen(true)
  }

  const handleClose = (): void => {
    setErrorsHaveBeenSeen(true)
    setIsOpen(false)
  }

  const reduxHasErrors = errors.length > 0
  if (!reduxHasErrors) return null

  return (
    <>
      <IconButton
        tooltip={'Redux Errors'}
        onClick={handleClick}
        iconId={'warning'}
        variant={errorsHaveBeenSeen ? undefined : 'destructive'}
      />

      <Modal open={isOpen} onClose={handleClose} padding='32' size={'full-screen'}>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Heading size={'h4'} bold>
          Redux Errors
        </Heading>
        <DebugJsonView src={errors} />
      </Modal>
    </>
  )
}

export const ReduxErrors = (): JSX.Element | null => {
  const isOwner = useIsOwnerUser()

  if (!isOwner) return null

  return (
    <Portal>
      <Wrapper>
        <ReduxErrorsButton />
      </Wrapper>
    </Portal>
  )
}
