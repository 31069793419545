import { capitalize } from 'lodash'
import React, { useEffect } from 'react'
import { ScenarioCardChatId } from 'sierra-client/api/graphql/branded-types'
import { ScenarioCardChatFeedback } from 'sierra-client/api/graphql/gql/graphql'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useChatFeedback } from 'sierra-client/views/v3-author/scenario/chat/hooks'
import { MessageType } from 'sierra-client/views/v3-author/scenario/chat/state'
import { FeedbackLoader } from 'sierra-client/views/v3-author/scenario/loading-views'
import { CourseId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { ScenarioFeedbackV2Response } from 'sierra-domain/routes'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const RotatedIcon = styled(Icon)`
  border-radius: 16px;
  transform: rotate(-4deg);
`

const Card = styled(View).attrs({ grow: true })`
  padding: 32px;
  background-color: ${token('surface/default')};
  border-radius: 32px;
  box-shadow: 0px 8px 16px 0px #00000014;
  outline: 1px solid ${token('border/default')};
`

const CardFooter = styled(View)``

const FeedbackWrapper = styled(View).attrs({ direction: 'column' })`
  background-color: ${token('surface/soft').opacity(0.025)};
  width: 50%;
  padding: 16px 20px 16px 20px;
  gap: 12px;
  border-radius: 16px;
  flex-grow: 1;
  align-self: stretch;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: 100%;
  }
`

const FeedbackSection: React.FC<{
  feedback?: ScenarioFeedbackV2Response
  status: 'success' | 'warning'
}> = ({ feedback, status }) => {
  const isMobile = useIsMobile()

  if (!isDefined(feedback)) {
    return <FeedbackLoader status={status} />
  }

  return (
    <View direction='column' gap='24'>
      <Text size={isMobile ? 'regular' : 'large'}>{feedback.description}</Text>
      <View direction={isMobile ? 'column' : 'row'}>
        {isNonEmptyArray(feedback.positives) && (
          <FeedbackWrapper grow>
            {feedback.positives.map(pos => (
              <View key={pos.description}>
                <Icon iconId='checkmark--filled' color='success/background' />
                <Text size='small'>{pos.description}</Text>
              </View>
            ))}
          </FeedbackWrapper>
        )}
        {isNonEmptyArray(feedback.improvements) && (
          <FeedbackWrapper grow>
            {feedback.improvements.map(pos => (
              <View key={pos.description}>
                <Icon iconId='close--circle' color='foreground/muted' />
                <Text size='small'>{pos.description}</Text>
              </View>
            ))}
          </FeedbackWrapper>
        )}
      </View>
    </View>
  )
}

export const StaticFeedbackCard: React.FC<{ feedback: ScenarioCardChatFeedback; onRestart: () => void }> = ({
  feedback,
  onRestart,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  return (
    <Card direction='column' gap='8'>
      <View justifyContent='space-between' alignItems='flex-start'>
        <Heading bold size={isMobile ? 'h4' : 'h3'}>
          {t('scenario-card.completion.previous.feedback')}
        </Heading>
        <RotatedIcon size={isMobile ? 'size-32' : 'size-48'} iconId={'checkbox--checkmark--filled'} />
      </View>
      <FeedbackSection feedback={feedback} status={'success'} />
      <CardFooter marginTop='16'>
        <Button icon='restart' onClick={onRestart}>
          {capitalize(t('dictionary.restart'))}
        </Button>
      </CardFooter>
    </Card>
  )
}

export const TerminationCard: React.FC<{
  chatId?: ScenarioCardChatId
  fileId: FileId
  courseId: CourseId
  selfTerminated: boolean
  messageHistory: Array<MessageType>
  onTryAgain: () => void
  onFullyLoaded: () => void
  onMount: () => void
  previewMode: boolean
}> = ({
  chatId,
  fileId,
  courseId,
  selfTerminated,
  messageHistory,
  onTryAgain,
  onFullyLoaded,
  onMount,
  previewMode,
}) => {
  useOnMount(onMount)

  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const feedback = useChatFeedback({
    chatId,
    fileId,
    courseId,
    messageHistory,
    reason: selfTerminated ? 'termination-premature' : 'termination-success',
    previewMode,
  })

  useEffect(() => {
    if (feedback.isFetched) {
      onFullyLoaded()
    }
  }, [feedback, onFullyLoaded])

  return (
    <Card direction='column' gap='8'>
      <View justifyContent='space-between' alignItems='flex-start'>
        <Heading bold size={isMobile ? 'h4' : 'h3'}>
          {selfTerminated
            ? t('scenario-card.termination.early.title')
            : t('scenario-card.termination.success.title')}
        </Heading>
        <RotatedIcon
          size={isMobile ? 'size-32' : 'size-48'}
          color={selfTerminated ? 'warning/background' : 'success/background'}
          iconId={selfTerminated ? 'logout-square' : 'checkbox--checkmark--filled'}
        />
      </View>
      <FeedbackSection feedback={feedback.data} status={selfTerminated ? 'warning' : 'success'} />
      <CardFooter marginTop='16'>
        <Button icon='restart' onClick={onTryAgain}>
          {capitalize(t('dictionary.try-again'))}
        </Button>
      </CardFooter>
    </Card>
  )
}

export const ComplianceCard: React.FC<{
  onRestart: () => void
  onUndo: () => void
  onMount: () => void
  reason: string
  chatId?: ScenarioCardChatId
  fileId: FileId
  courseId: CourseId
  messageHistory: Array<MessageType>
  onFullyLoaded: () => void
  previewMode: boolean
}> = ({
  onRestart,
  onUndo,
  onMount,
  reason,
  chatId,
  fileId,
  courseId,
  messageHistory,
  onFullyLoaded,
  previewMode,
}) => {
  useOnMount(onMount)
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const feedback = useChatFeedback({
    chatId,
    fileId,
    courseId,
    messageHistory,
    reason: 'compliance-failure',
    previewMode,
  })

  useEffect(() => {
    if (feedback.isFetched) {
      onFullyLoaded()
    }
  }, [feedback, onFullyLoaded])

  return (
    <Card direction='column' gap='32'>
      <View direction='column' gap='8'>
        <View justifyContent='space-between' alignItems='flex-start'>
          <Heading bold size={isMobile ? 'h4' : 'h3'}>
            {t('scenario-card.compliance.fail.title')}
          </Heading>
          <RotatedIcon
            size={isMobile ? 'size-32' : 'size-48'}
            color='warning/background'
            iconId='warning-square'
          />
        </View>
        <Text size='large'>{`${reason} ${t('scenario-card.compliance.fail.subtitle')}`}</Text>
      </View>
      <FeedbackSection feedback={feedback.data} status={'warning'} />
      <CardFooter>
        <Button icon='undo' onClick={onUndo}>
          {t('scenario-card.compliance.buttons.unsend-last-message')}
        </Button>
        <Button icon='restart' variant='secondary' onClick={onRestart}>
          {t('dictionary.start-over')}
        </Button>
      </CardFooter>
    </Card>
  )
}

export const ErrorCard: React.FC<{ onMount: () => void }> = ({ onMount }) => {
  useOnMount(onMount)
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Card direction='column' gap='32'>
      <View direction='column' gap='8'>
        <View justifyContent='space-between' alignItems='flex-start'>
          <Heading bold size={isMobile ? 'h4' : 'h3'}>
            {t('dictionary.something-went-wrong')}
          </Heading>
          <RotatedIcon
            size={isMobile ? 'size-32' : 'size-48'}
            color='destructive/background'
            iconId='warning-square'
          />
        </View>
        <Text size='large'>{t('scenario-card.chat.error.generic-fail')}</Text>
      </View>
      <CardFooter>
        <Button icon='restart' variant='secondary' onClick={() => window.location.reload()}>
          {t('dictionary.reload')}
        </Button>
      </CardFooter>
    </Card>
  )
}
