import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { CookieAuthClient } from 'sierra-client/auth/clients/cookie-auth-client'
import { config } from 'sierra-client/config/global-config'
import { clearState } from 'sierra-client/state/actions'
import { store as reduxStore } from 'sierra-client/state/store'
import { CheckSessionResponse } from 'sierra-domain/api/auth'

const CheckAuthSessionInner: React.FC = () => {
  const timeout = config.organization.timeout
  const prevSessionRef = useRef<CheckSessionResponse | undefined>()
  const { data: session } = useQuery({
    ...getAuthClient().authQuery,
    refetchInterval: timeout !== null ? timeout / 4 : false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    const prevSession = prevSessionRef.current
    const wasLoggedIn = prevSession?.userId !== undefined
    const isNewUserSession = wasLoggedIn && prevSession.userId !== session?.userId

    if (isNewUserSession) {
      // Clear states of potential user data
      prevSessionRef.current = session
      queryClient.clear()
      reduxStore.dispatch(clearState())
    }

    prevSessionRef.current = session
  }, [session])

  return null
}

export const CheckAuthSession: React.FC = () => {
  const isCookieAuth = getAuthClient() instanceof CookieAuthClient
  return isCookieAuth ? <CheckAuthSessionInner /> : null
}
