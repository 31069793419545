import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { QueryClientProvider } from 'sierra-client/api/query-client'
import { CheckAuthSession } from 'sierra-client/auth/check-auth-session'
import { TranslationContextProvider } from 'sierra-client/hooks/use-translation'
import { SierraRouterProvider } from 'sierra-client/router-provider'
import { store } from 'sierra-client/state/store'

// TODO: always use StrictMode
const ConditionalStrict = import.meta.env.REACT_STRICT_MODE === 'true' ? React.StrictMode : React.Fragment

/**
 * The root React component. Should contain code dependent on the React lifecycle and that should apply to
 * every route, and not be dependent on routing.
 *
 * - Root code that can run outside of the React lifecycle, or that should only run once, should be defined in `client-entrypoint.tsx`.
 * - Root code with router dependencies should be defined in `routes/__root.tsx`, which is in the context of the router provider.
 */
export const ReactRoot = (): JSX.Element => {
  return (
    <ConditionalStrict>
      <HelmetProvider>
        <TranslationContextProvider>
          <ReduxProvider store={store}>
            <QueryClientProvider>
              <CheckAuthSession />
              <SierraRouterProvider />
            </QueryClientProvider>
          </ReduxProvider>
        </TranslationContextProvider>
      </HelmetProvider>
    </ConditionalStrict>
  )
}
