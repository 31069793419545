import { last } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { logger } from 'sierra-client/logger/logger'
import {
  FileTooBigError,
  FileTypes,
  UploadMediaWithProgress,
} from 'sierra-client/views/v3-author/common/media-uploader/types'
import { AssetContext } from 'sierra-domain/asset-context'
import { Icon, IconId, ProgressBar } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { CommonButtonProps } from 'sierra-ui/primitives/button/props'
import { palette, spacing } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
`

const UploadFileWrapper = styled.div<{ dragging: boolean }>`
  cursor: pointer;
  display: flex;

  overflow: hidden;
  padding: 1.5rem;
  height: 100%;
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  transition:
    background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1),
    color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

  * {
    user-select: none;
  }
  ${p =>
    p.dragging &&
    css`
      transition:
        background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1),
        color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
      background-color: ${p => p.theme.color.blueBright};
      * {
        color: ${palette.primitives.white};
      }
    `}
`

const DropZone = <T,>({
  children,
  processMedia,
  onClick,
  className,
  accept,
  onDroppedUnsupportedFileType,
}: {
  children: React.ReactNode
  processMedia: (_: File) => Promise<T>
  onClick: () => void
  onDroppedUnsupportedFileType?: (droppedFile: File) => void
  className?: string
  accept: FileTypes[]
}): JSX.Element => {
  const [enterCounter, setEnterCounter] = useState(0)
  const onEnter = (): void => setEnterCounter(s => s + 1)
  const onLeave = (): void => setEnterCounter(s => Math.max(s - 1, 0))
  const dragging = enterCounter > 0

  return (
    <UploadFileWrapper
      dragging={dragging}
      className={className}
      onClick={onClick}
      onDragOver={e => {
        e.preventDefault()
      }}
      onDragEnter={e => {
        e.preventDefault()
        onEnter()
      }}
      onDragLeave={e => {
        e.preventDefault()
        onLeave()
      }}
      onDrop={e => {
        e.preventDefault()
        e.stopPropagation()
        const droppedFiles = Array.from(e.dataTransfer.files)
        const [file] = droppedFiles.filter(file => accept.includes(file.type as FileTypes))

        if (file !== undefined) void processMedia(file)
        else if (droppedFiles[0]) onDroppedUnsupportedFileType?.(droppedFiles[0])

        onLeave()
      }}
    >
      {children}
    </UploadFileWrapper>
  )
}

const Info = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  grow: true,
})`
  color: ${p => p.theme.text.default};
`

const DetailText = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  & p {
    margin-bottom: 0.25rem;
    opacity: 0.4;
  }
`

const ErrorBanner = styled(View)`
  background-color: ${p => p.theme.color.redLight};
  padding: ${spacing['4']};
  border-radius: 6px;
  justify-content: center;
  align-items: middle;
  padding: ${spacing['6']};
`

const cleanFileType = (fileType: string): string => fileType.replace(/(.*\/)/, '.')
const guessFileType = (file: File): string | undefined => {
  if (file.type !== '') {
    return cleanFileType(file.type)
  }

  if (file.name.includes('.')) {
    return `.${last(file.name.split('.'))}`
  }

  return undefined
}

const UploadingContainer = styled(View)`
  width: 100%;
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  max-width: 230px;
  margin-block-start: 24px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 250ms ease-in-out forwards;
`

type UploadingState = { uploading: true; fileName: string } | { uploading: false }

export const MediaUploader = <T,>({
  accept,
  iconId,
  uploadMedia,
  onIsUploading,
  additionalInfo,
  onUploaded,
  className,
  buttonVariant,
  initialFile,
  assetContext,
  narrationButton,
  uploadTitle,
  uploadSupportBody,
}: {
  accept: FileTypes[]
  iconId: IconId
  additionalInfo?: string
  uploadMedia: UploadMediaWithProgress<T>
  onIsUploading?: (_: boolean) => void
  onUploaded: (_: T, filename?: string) => void
  className?: string
  buttonVariant?: CommonButtonProps['variant']
  initialFile?: File
  assetContext: AssetContext
  narrationButton?: JSX.Element
  uploadTitle?: TranslationKey
  uploadSupportBody?: TranslationKey
}): JSX.Element => {
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined)
  const { t } = useTranslation()
  const [error, setError] = useState<string | undefined>()
  const [uploadingState, setUploadingState] = useState<UploadingState>({
    uploading: false,
  })

  const setIsUploading = useCallback(
    (state: UploadingState) => {
      setUploadingState(state)
      onIsUploading?.(state.uploading)
    },
    [onIsUploading]
  )

  const processMedia = useCallback(
    async (file: File): Promise<void> => {
      try {
        setError(undefined)
        setIsUploading({ uploading: true, fileName: file.name })
        const media = await uploadMedia(file, assetContext, (type, progress) => {
          if (type === 'uploading') {
            setUploadProgress(progress)
          }
        })
        onUploaded(media, file.name)
      } catch (err) {
        logger.error('Error uploading media', { error: err })

        if (err instanceof FileTooBigError) {
          setError(err.message)
        } else {
          setError('We were unable to upload your file. Please try again.')
        }
      } finally {
        setIsUploading({ uploading: false })
      }
    },
    [setIsUploading, uploadMedia, onUploaded, setError, assetContext]
  )

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (initialFile !== undefined) {
      void processMedia(initialFile)
    }
  }, [initialFile, processMedia])

  if (uploadingState.uploading)
    return (
      <UploadingContainer grow direction='column' justifyContent='center' alignItems='center'>
        <LoadingSpinner size='48' padding='none' />

        <Text size='small'>{t('dictionary.uploading-file')}</Text>
        <Text color='foreground/muted' size='small'>
          {uploadingState.fileName}
        </Text>
        <ProgressBarWrapper>
          {uploadProgress !== undefined && <ProgressBar percent={uploadProgress} />}
        </ProgressBarWrapper>
      </UploadingContainer>
    )

  return (
    <>
      <DropZone
        className={className}
        processMedia={processMedia}
        onClick={() => ref.current?.click()}
        accept={accept}
        onDroppedUnsupportedFileType={file => {
          const uploadedType = guessFileType(file)
          if (uploadedType !== undefined) {
            setError(`${uploadedType} files are not supported`)
          } else {
            setError(`Files of this type are not supported`)
          }
        }}
      >
        <HiddenInput
          ref={ref}
          type='file'
          accept={accept.join(', ')}
          onChange={event => {
            const file = event.target.files?.[0]
            if (file !== undefined) {
              void processMedia(file)
            }
          }}
        />
        <View grow direction='column'>
          <Info>
            <Icon iconId={iconId} size='size-16' color='foreground/primary' />
            <Text size='small' bold>
              {uploadTitle !== undefined ? t(uploadTitle) : t('author.slate.image-upload-options')}
            </Text>
            <DetailText>
              <Text size='small'>
                {(uploadSupportBody !== undefined
                  ? t(uploadSupportBody)
                  : t('author.slate.supported-formats')) + ' '}
                {accept.map(fileType => fileType.replace(/(.*\/)/, '.')).join(' , ')}
                {additionalInfo ?? ''}
              </Text>
            </DetailText>
            <View>
              <Button variant={buttonVariant ?? 'primary'}>{t('dictionary.choose-file')}</Button>
              {narrationButton !== undefined && narrationButton}
            </View>
          </Info>
          {error !== undefined && (
            <ErrorBanner>
              <Text size='micro' bold color='white'>
                {error}
              </Text>
            </ErrorBanner>
          )}
        </View>
      </DropZone>
    </>
  )
}
