import { setAuthClient } from 'sierra-client/auth/auth-client'
import { AuthType } from 'sierra-client/auth/auth-types'
import { CookieAuthClient } from 'sierra-client/auth/clients/cookie-auth-client'
import { TokenAuthClient } from 'sierra-client/auth/clients/token-auth-client'
import { config } from 'sierra-client/config/global-config'

/**
 * Initializes the auth client. Separated from the main entry point to avoid circular dependencies.
 */
export function initAuth(): void {
  setAuthClient(
    config.auth.type === AuthType.Scorm ? new TokenAuthClient(config.auth.token) : new CookieAuthClient()
  )
}
