import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ExerciseType,
  ExercisesByIdsQuery,
  ExercisesByQueryQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AutoCompleteEntity } from 'sierra-domain/filter/datatype/domain'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { ValueExerciseId } from 'sierra-domain/filter/datatype/value'
import { setPred } from 'sierra-domain/filter/operations'
import { assertNever, isDefined, isNotNull } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

type AutoCompleteExercise = {
  id: NanoId12
  exerciseType: string
  title?: string
  value: ValueExerciseId
}

const emptyArray = [] as const satisfies AutoCompleteExercise[]

function exerciseToAutocompleteExercise(
  exercise: ExercisesByIdsQuery['exercisesWithIds'][number] | ExercisesByQueryQuery['exercises'][number]
): AutoCompleteExercise {
  return {
    id: exercise.id,
    title: isNotNull(exercise.title) ? exercise.title : undefined,
    exerciseType: exercise.exerciseType,
    value: {
      type: 'value.exercise-id',
      courseId: exercise.courseId,
      fileId: exercise.fileId,
      exerciseId: exercise.id,
    },
  }
}

const exercisesByIds = graphql(`
  query ExercisesByIds($ids: [CourseFileExerciseIdInput!]!) {
    exercisesWithIds(ids: $ids) {
      id
      title
      exerciseType
      courseId
      fileId
    }
  }
`)

const useExercisesFromPredicate = (predicate: Predicate): AutoCompleteExercise[] => {
  const exerciseIds = fromPredicate(predicate)
    // TODO: Using the NanoId12 type for 'value.nanoid12' caused type errors in the recursive Filter type.
    //  Seems like a bug in zod maybe? For now we'll cast the value here.

    .map(value =>
      value.type === 'value.exercise-id'
        ? {
            courseId: NanoId12.parse(value.courseId),
            fileId: NanoId12.parse(value.fileId),
            exerciseId: NanoId12.parse(value.exerciseId),
          }
        : undefined
    )
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: exercisesByIds,
      queryOptions: {
        enabled: exerciseIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: exerciseIds }
  )

  if (exerciseIds.length === 0) {
    return emptyArray
  }

  if (response.data === undefined) {
    return emptyArray
  }

  return response.data.exercisesWithIds.map(exerciseToAutocompleteExercise)
}

const exercisesByQuery = graphql(`
  query ExercisesByQuery($query: String!, $exerciseType: ExerciseType) {
    exercises(query: $query, exerciseType: $exerciseType) {
      id
      title
      exerciseType
      courseId
      fileId
    }
  }
`)

type SupportedEntity = Extract<AutoCompleteEntity, 'entity.question' | 'entity.poll'>

const getGraphQlExerciseType = (entity: SupportedEntity): ExerciseType | undefined => {
  switch (entity) {
    case 'entity.question':
      return 'QUESTION'
    case 'entity.poll':
      return 'POLL'
    default:
      assertNever(entity)
  }
}

const useExercisesFromQuery = (query: string, entity: SupportedEntity): AutoCompleteExercise[] => {
  const exerciseType = getGraphQlExerciseType(entity)
  const response = useGraphQuery(
    { document: exercisesByQuery, queryOptions: { staleTime: 60 * 1000, placeholderData: keepPreviousData } },
    { query, exerciseType }
  )

  if (response.data === undefined) {
    return emptyArray
  }

  return response.data.exercises.map(exerciseToAutocompleteExercise)
}

const getFormLabel = (entity: SupportedEntity): TranslationKey => {
  switch (entity) {
    case 'entity.question':
      return 'admin.analytics.content.label.questions'
    case 'entity.poll':
      return 'analytics.dimension.native.poll.poll'
    default:
      assertNever(entity)
  }
}

type ExerciseAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
  entity: SupportedEntity
}

export const ExerciseAutoComplete: React.FC<ExerciseAutoCompleteProps> = ({ ctx, predicate, entity }) => {
  const { t } = useTranslation()

  const selectedExercises = useExercisesFromPredicate(predicate)

  const [query, setQuery] = useState('')
  const matchingExercises = useExercisesFromQuery(query, entity)

  const onSelect = useCallback(
    (content: AutoCompleteExercise) => {
      ctx.update(f => setPred(f, addPredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (content: AutoCompleteExercise) => {
      ctx.update(f => setPred(f, removePredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  return (
    <FormElement label={t(getFormLabel(entity))}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedExercises}
        matchingItems={matchingExercises}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(card, { onUnselect, ...props }) => {
          return (
            <SelectedPill key={card.id} {...props}>
              <Text color='foreground/primary' bold>
                {card.title}
              </Text>

              <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
            </SelectedPill>
          )
        }}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingExercises.map((card, index) => {
              return (
                <DefaultStyledAutocompleteOption key={card.id} {...getItemProps(card, index)}>
                  <Text color='foreground/primary'>{card.title}</Text>
                </DefaultStyledAutocompleteOption>
              )
            })}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}

export const ExerciseAutoCompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const selectedExercises = useExercisesFromPredicate(predicate)
    const labels = selectedExercises.map(card => card.title)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
