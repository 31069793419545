import { UseQueryResult } from '@tanstack/react-query'
import _ from 'lodash'
import { FC, useMemo, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { IssuedCertificatesForContentCountsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { RouterLink } from 'sierra-client/components/common/link'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  useAddCertificateToCourse,
  useDeleteCertificateFromCourse,
} from 'sierra-client/views/course-settings/tabs/certificates-tab/use-connect-certificate-to-content'
import { AddContentPanel } from 'sierra-client/views/manage/certificates/issued-by-selector/add-content-panel'
import {
  CoursePreview,
  PreviewSkeleton,
  ProgramPreview,
} from 'sierra-client/views/manage/certificates/issued-by-selector/previews'
import {
  IssuedByCourse,
  IssuedByProgram,
  contentKindToCourseKind,
} from 'sierra-client/views/manage/certificates/issued-by-selector/types'
import { useCertificateDetails } from 'sierra-client/views/manage/certificates/use-issued-certificates'
import {
  useAddCertificateToProgram,
  useDeleteCertificateFromProgram,
} from 'sierra-client/views/manage/programs/hooks/use-connect-certificate-to-program'
import { ProgramId } from 'sierra-domain/api/uuid'
import { isNonNullable } from 'sierra-domain/utils'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const issuedCertificatesForContentCountsQuery = graphql(`
  query issuedCertificatesForContentCounts($certificateId: CertificateId!) {
    issuedCertificates(id: $certificateId) {
      issuedByUser {
        id
      }
      issuedByContent {
        ... on Course {
          courseId
        }
      }
      issuedByProgram {
        id
      }
    }
  }
`)

const useIssuedCertificatesForContentCounts = (
  certificateId: string
): UseQueryResult<IssuedCertificatesForContentCountsQuery, unknown> => {
  const queryResult = useGraphQuery(
    {
      document: issuedCertificatesForContentCountsQuery,
      queryOptions: { staleTime: 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: true, gcTime: 0 },
    },
    { certificateId }
  )
  return queryResult
}

const Container = styled(View)`
  width: 100%;
  max-width: 400px;
`

export const IssuedBySelector: FC<{
  certificateId: string
  afterSubmit: () => void
  disabled: boolean
}> = ({ certificateId, afterSubmit, disabled }) => {
  const { t } = useTranslation()
  const [addContentPanelOpen, setAddContentPanelOpen] = useState(false)

  const orgPermissions = useOrganizationPermissions()
  const hasWriteAccess = orgPermissions.has('ACCESS_ALL_CERTIFICATES')

  const addCertificateToCourse = useAddCertificateToCourse()
  const deleteCertificateFromCourse = useDeleteCertificateFromCourse()
  const addCertificateToProgram = useAddCertificateToProgram()
  const deleteCertificateFromProgram = useDeleteCertificateFromProgram()

  const certQuery = useCertificateDetails(certificateId)
  const issuedCertsQuery = useIssuedCertificatesForContentCounts(certificateId)

  const certDetails = certQuery.data?.certificate ?? undefined
  const issuedCerts = issuedCertsQuery.data?.issuedCertificates ?? undefined

  const isFetched = certQuery.isFetched && issuedCertsQuery.isFetched

  const connectedCourses = useMemo(
    () =>
      certDetails?.connectedCourses.map<IssuedByCourse>(c => ({
        ...c,
        image: convertGQLImage(c.image),
        id: c.courseId,
        courseKind: contentKindToCourseKind[c.courseKind],
        description: c.description ?? undefined,
      })) ?? [],
    [certDetails?.connectedCourses]
  )
  const connectedPrograms = useMemo(
    () =>
      certDetails?.connectedPrograms.map<IssuedByProgram>(p => ({
        ...p,
        image: convertGQLImage(p.image),
        description: p.description ?? undefined,
      })) ?? [],
    [certDetails?.connectedPrograms]
  )

  const numberOfIssuedCertificatesMap = useMemo<Record<string, number>>(() => {
    if (certDetails === undefined) {
      return {}
    }
    const connectedCourseIds = _.chain(certDetails.connectedCourses)
      .map(course => course.courseId)
      .thru(ids => new Set(ids))
      .value()

    const connectedProgramIds = _.chain(certDetails.connectedPrograms)
      .map(program => program.id)
      .thru(ids => new Set(ids))
      .value()

    return _.chain(issuedCerts)
      .flatMap(issue => {
        if (isNonNullable(issue.issuedByProgram) && connectedProgramIds.has(issue.issuedByProgram.id)) {
          return [issue.issuedByProgram.id]
        }
        if (!isNonNullable(issue.issuedByContent)) return []

        const courseId = 'courseId' in issue.issuedByContent ? issue.issuedByContent.courseId : undefined

        if (courseId !== undefined && connectedCourseIds.has(courseId)) {
          return [courseId]
        } else {
          return []
        }
      })
      .countBy()
      .value()
  }, [certDetails, issuedCerts])

  const handleClosePanel = (): void => {
    setAddContentPanelOpen(false)
  }

  const handleSubmit = async ({
    courseAdds,
    courseRemovals,
    programAdds,
    programRemovals,
  }: {
    courseAdds: IssuedByCourse['courseId'][]
    courseRemovals: IssuedByCourse['courseId'][]
    programAdds: IssuedByProgram['id'][]
    programRemovals: IssuedByProgram['id'][]
  }): Promise<void> => {
    const courseAddActions = courseAdds.map(courseId => addCertificateToCourse(courseId, certificateId))
    const courseDeleteActions = courseRemovals.map(courseId =>
      deleteCertificateFromCourse(courseId, certificateId)
    )

    const programAddActions = programAdds.map(programId => addCertificateToProgram(programId, certificateId))
    const programRemoveActions = programRemovals.map(programId =>
      deleteCertificateFromProgram(programId, certificateId)
    )
    await Promise.all([
      ...courseAddActions,
      ...courseDeleteActions,
      ...programAddActions,
      ...programRemoveActions,
    ])

    afterSubmit()
  }

  return (
    <Container direction='column'>
      <View justifyContent='space-between'>
        <Text bold size='regular'>
          {t('manage.certificates.issued-by-title')}
        </Text>
        {hasWriteAccess && (
          <Button variant='secondary' disabled={disabled} onClick={() => setAddContentPanelOpen(true)}>
            {t('admin.author.manage')}
          </Button>
        )}
      </View>
      <Container grow direction='column'>
        {isFetched ? (
          connectedCourses.length === 0 && connectedPrograms.length === 0 ? (
            <Container background='surface/soft' direction='column' padding='small' radius='size-8'>
              <Text bold color='foreground/muted' align='center'>
                {t('dictionary.not-issued')}
              </Text>
              <Text color='foreground/muted' align='center'>
                {t('manage.certificates.not-issued-helper-text')}
              </Text>
            </Container>
          ) : (
            <Container direction='column'>
              {connectedPrograms.map(program => (
                <RouterLink href={`/manage/programs/${program.id}`} key={program.id}>
                  <ProgramPreview
                    {...program}
                    description={t('manage.certificates.issued-by-info', {
                      count: numberOfIssuedCertificatesMap[program.id] ?? 0,
                    })}
                    assetContext={{ type: 'program', programId: ProgramId.parse(program.id) }}
                  />
                </RouterLink>
              ))}
              {connectedCourses.map(course => (
                <RouterLink href={`/manage/courses/${course.courseId}`} key={course.courseId}>
                  <CoursePreview
                    {...course}
                    description={t('manage.certificates.issued-by-info', {
                      count: numberOfIssuedCertificatesMap[course.courseId] ?? 0,
                    })}
                    assetContext={{ type: 'course' as const, courseId: course.courseId }}
                  />
                </RouterLink>
              ))}
            </Container>
          )
        ) : (
          <PreviewSkeleton />
        )}

        <AddContentPanel
          open={addContentPanelOpen}
          onClose={handleClosePanel}
          onSubmit={handleSubmit}
          connectedCourses={connectedCourses}
          connectedPrograms={connectedPrograms}
        />
      </Container>
    </Container>
  )
}
