import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'sierra-client/state/types'
import { UserAuthStatus } from 'sierra-client/state/user/user-types'

// Not possible to add actions here that depend from sierra-client/state/api
// since it creates a circular dependency. Actions can be added to user-slice.

export const statusChanged = createAsyncThunk<UserAuthStatus, UserAuthStatus, { state: RootState }>(
  'user/statusChanged',
  payload => payload
)
