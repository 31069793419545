import { motion } from 'framer-motion'
import React from 'react'
import { layoutValues } from 'sierra-client/config/layout-values'
import { DropdownMenuContainerSize } from 'sierra-client/layout/header/components/dropdown-menu/types'
import { horizontalPadding } from 'sierra-client/layout/styles/content'
import { palette, zIndex } from 'sierra-ui/theming'
import { animations } from 'sierra-ui/theming/animations'
import { v2_breakpoint, v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

// DROPDOWN
const dropdownHeight = 'auto'

type DropdownMenuProps = {
  animate: string
  className?: string
  children: React.ReactNode
  onFocus?: () => void
  onBlur?: () => void
  innerClassName?: string
  containerSize: DropdownMenuContainerSize
  mobileBreakpoint?: boolean
} & OptionalPaddings

const userMenuVariants = {
  initial: {
    height: 0,
    transition: {
      when: 'beforeChildren',
      easing: 'ease',
      delay: animations.dropdown_menu.disabled ? 0 : animations.dropdown_menu.delay_out,
      duration: animations.dropdown_menu.disabled ? 0 : animations.dropdown_menu.out,
    },
  },
  enter: {
    height: dropdownHeight,
    transition: {
      delay: animations.dropdown_menu.disabled ? 0 : animations.dropdown_menu.delay_in,
      easing: 'ease',
      duration: animations.dropdown_menu.disabled ? 0 : animations.dropdown_menu.in,
      when: 'beforeChildren',
    },
  },
}

type OptionalPaddings = { noVerticalPadding?: boolean; noHorizontalPadding?: boolean }

const InnerDropdownMenu = styled.div<{ containerSize: DropdownMenuContainerSize } & OptionalPaddings>`
  width: 100%;
  margin: 0 auto;
  ${horizontalPadding}

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    margin: 0;
  }

  @media screen and (min-width: ${v2_breakpoint_int.desktop + 2 * 16 * 2}px) {
    margin: 0 auto;
  }

  ${p =>
    p.containerSize === 'container' &&
    css`
      max-width: 94rem;
    `}

  ${p =>
    (p.noVerticalPadding ?? false) &&
    `
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  `}

  ${p =>
    (p.noHorizontalPadding ?? false) &&
    `
      padding-left: 0 !important;
      padding-right: 0 !important;
  `}
`

export const DropdownMenu = styled(
  ({
    animate,
    className,
    innerClassName,
    children,
    containerSize,
    onFocus,
    noVerticalPadding,
    noHorizontalPadding,
    onBlur,
  }: DropdownMenuProps) => (
    <motion.div
      variants={userMenuVariants}
      initial='initial'
      animate={animate}
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <InnerDropdownMenu
        noVerticalPadding={noVerticalPadding}
        noHorizontalPadding={noHorizontalPadding}
        className={`${innerClassName !== undefined ? `${innerClassName} ` : ''}dropdownInner`}
        containerSize={containerSize}
      >
        {children}
      </InnerDropdownMenu>
    </motion.div>
  )
)`
  position: absolute;
  top: calc(
    ${layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT} - 1px
  ); /* Safari renders a thin line between the header and menu if they are positioned in the same place */
  left: 0;
  z-index: ${zIndex.MODAL};

  width: 100%;
  max-height: calc(
    var(--VH, 1vh) * ${p => ((p.mobileBreakpoint ?? false) ? '100' : '85')} -
      ${layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT}
  );
  background-color: ${palette.primitives.white};
  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    top: calc(${layoutValues.TOP_BAR_SESSION_HEIGHT} - 1px);
    max-height: calc(
      var(--VH, 1vh) * ${p => ((p.mobileBreakpoint ?? false) ? '100' : '90')} -
        ${layoutValues.TOP_BAR_SESSION_HEIGHT}
    );
  }
`
