import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

export const DisconnectAlert: React.FC<{
  open: boolean
  primaryAction: () => void
  secondaryAction: () => void
  service: string
  integrations: string[]
  loading: boolean
}> = ({ open, primaryAction, secondaryAction, service, integrations, loading }) => {
  const { lang, t } = useTranslation()
  const concatenatedIntegrations = new Intl.ListFormat(lang, { style: 'long', type: 'conjunction' }).format(
    integrations
  )

  return (
    <ActionModal
      size={{ width: 450 }}
      open={open}
      deleteAction
      title={t('calendar-integrations.user-settings.disconnect-integration', { service })}
      onClose={secondaryAction}
      primaryAction={primaryAction}
      primaryActionLabel={t('dictionary.disconnect')}
      secondaryAction={secondaryAction}
      secondaryActionLabel={t('dictionary.cancel')}
      isLoading={loading}
    >
      {t('calendar-integrations.user-settings.disconnect-integration-info', {
        count: integrations.length,
        integrations: concatenatedIntegrations,
      })}
    </ActionModal>
  )
}
