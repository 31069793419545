import { Template } from 'sierra-client/state/templates/types'
import { FlexibleContentTemplate } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { assertNever } from 'sierra-domain/utils'

export const flexibleContentTemplateFromTemplate = (template: Template): FlexibleContentTemplate => {
  switch (template.templateType) {
    case 'sana':
      return {
        type: template.templateType,
        templateName: template.id,
      }
    case 'partner':
      return {
        type: template.templateType,
        templateId: NanoId12.parse(template.id),
      }
    default:
      assertNever(template)
  }
}
