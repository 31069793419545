export type AuthType = (typeof AuthType)[keyof typeof AuthType]
export const AuthType = {
  Scorm: 'scorm',
  ScormNative: 'scorm-native',
  Native: 'native',
} as const

export function toAuthType(value: string | undefined | null): AuthType {
  switch (value) {
    case AuthType.Scorm:
    case AuthType.ScormNative:
      return value
    default:
      return AuthType.Native
  }
}
