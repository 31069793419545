import { color } from 'sierra-ui/color'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const QuestionCardIconContainer = styled.div.attrs({
  contentEditable: false,
})<{ $isInline: boolean }>`
  display: flex;
  justify-self: flex-end;
  flex-direction: row;
  position: absolute;
  top: -1.5rem;
  right: 0;

  color: ${p => color(p.theme.home.textColor).opacity(0.6).toString()};
`

export const QuestionCardIconBar = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  contentEditable: false,
})`
  position: absolute;
  z-index: 1;
  width: 100%;
  right: 0;
  top: 0;
  padding: 1.5rem;
  color: ${p => color(p.theme.home.textColor).opacity(0.6).toString()};
`
