import _ from 'lodash'
import React, { ReactNode, useState } from 'react'
import { IconList, IconListItem } from 'sierra-client/components/common/icon-list'
import { RemoveIcon } from 'sierra-client/components/common/modals/multi-assign-modal/icons'
import { ItemUnion } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { CourseMark } from 'sierra-client/components/courses/mark'
import { RecurrenceButton } from 'sierra-client/components/recurrent-assignments/recurrence-button'
import {
  RequiredAssignmnentDropdown,
  RequiredAssignmnentSwitch,
} from 'sierra-client/components/required-assignments/required-assignment-switch'
import { getFlag } from 'sierra-client/config/global-config'
import { formatDateFromTo } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { PreviewImages } from 'sierra-client/views/learner/components/preview-card'
import { getDueDateString } from 'sierra-client/views/manage/components/due-date'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { getUserStatusTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { AssignmentPriority, DueDate, Interval } from 'sierra-domain/api/manage'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, Label, MenuButton, MenuItem, Tooltip, TruncatedText, UserDisplay } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DueDateButton = styled(Button)``

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 100%;
  padding: 1.5rem 0;

  border-bottom: 1px solid ${token('border/strong')};
`

export const SelectedItem: React.FC<{ onClick: () => void; children: ReactNode }> = ({
  onClick,
  children,
}) => (
  <ItemContainer>
    {children}
    <RemoveIcon onClick={onClick} />
  </ItemContainer>
)

export const Item: FCC<{
  onClick: () => void
  isSelected: boolean
  item: ItemUnion
}> = ({ onClick, isSelected, item, children }) => {
  const { t } = useTranslation()

  const text = iife(() => {
    if (item.type === 'live-session' && item.disabledReason === 'full') {
      return t('dictionary.full')
    }

    if (item.disabled) return t('table.assigned')

    return isSelected ? t('modal.selected') : t('modal.select')
  })

  return (
    <ItemContainer>
      {children}
      <Button
        data-testid={`assign-${item.title}`}
        variant={isSelected ? 'primary' : 'secondary'}
        onClick={onClick}
        disabled={item.disabled}
      >
        {text}
      </Button>
    </ItemContainer>
  )
}

const PreviewImageContainer = styled.div`
  width: 5rem;
  min-width: 5rem;
`

export type RequiredAssignmentProps = {
  type: 'required-assignment-with-default' | 'required-assignment-without-default'
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
}

export type RecurrencePeriodProps = {
  recurrencePeriod?: Interval
  setRecurrencePeriod: (recurrencePeriod: Interval) => void
  clearRecurrencePeriod: () => void
}

type ItemContentProps = {
  item: ItemUnion
  onDueDateClick?: (item: ItemUnion) => void
  onActualDueDateClick?: (item: ItemUnion, dueDate?: DueDate) => void
  dueDate?: DueDate
  isRequiredProps?: RequiredAssignmentProps
  recurrencePeriodProps?: RecurrencePeriodProps
}

type BaseItemProps = { left?: JSX.Element }
const BaseItem: FCC<BaseItemProps> = ({ left, children }) => (
  <View grow gap='16' alignItems='stretch'>
    {left}
    <View grow direction='column' alignItems='flex-start' justifyContent='center' gap='none'>
      {children}
    </View>
  </View>
)

const CourseEditionIndentation = styled.div`
  border-radius: 2px;
  width: 4px;
  margin-right: ${16 - 4}px;
  background-color: ${palette.grey[5]};
`

const RequiredAssignmentWrapper = styled(View)`
  max-width: 332px;
`

const DEFAULT_RELATIVE_DUE_DATES: Interval[] = [
  { unit: 'days', value: 7 },
  { unit: 'days', value: 14 },
  { unit: 'days', value: 30 },
]

const recurrencePeriodToString = (recurrencePeriod: Interval, t: TranslationLookup): string => {
  const count = recurrencePeriod.value
  const unit = recurrencePeriod.unit
  switch (unit) {
    case 'days':
      return t('due-date.cadence.days-assignment', { count })
    case 'months':
      return t('due-date.cadence.months-assignment', { count })
    case 'years':
      return t('due-date.cadence.years-assignment', { count })
    default:
      assertNever(unit)
  }
}

export const ItemContent: React.FC<ItemContentProps> = ({
  item,
  onDueDateClick,
  onActualDueDateClick,
  dueDate,
  isRequiredProps,
  recurrencePeriodProps,
}) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isRequiredAssignmentsEnabled = getFlag('required-assignments')

  const onCustomDueDateButtonClicked = (): void => {
    onDueDateClick?.(item)
    setIsMenuOpen(false)
  }

  const onRelativeDueDateClicked = (dueDate?: DueDate): void => {
    onActualDueDateClick?.(item, dueDate)
    setIsMenuOpen(false)
  }

  const relativeDueDateMenuItems: MenuItem[] = [
    ...DEFAULT_RELATIVE_DUE_DATES.map(cadence => ({
      id: `${cadence.value}-${cadence.unit}`,
      label: recurrencePeriodToString(cadence, t),
      type: 'label' as const,
      onClick: () => onRelativeDueDateClicked({ type: 'relative', interval: cadence }),
    })),
    ...[
      {
        id: 'separator-2',
        type: 'separator' as const,
      },
      {
        id: `custom`,
        label: t('recurrence.custom-cadence'),
        type: 'label' as const,
        onClick: onCustomDueDateButtonClicked,
      },
      ...(dueDate !== undefined
        ? [
            {
              id: 'separator-2',
              type: 'separator' as const,
            },
            {
              id: `clear`,
              label: t('due-date.modal.remove'),
              type: 'label' as const,
              icon: 'minus--circle' as const,
              color: 'destructive/background' as const,
              onClick: () => onRelativeDueDateClicked(undefined),
            },
          ]
        : []),
    ],
  ]

  const dueDateButton =
    onDueDateClick !== undefined ? (
      recurrencePeriodProps?.recurrencePeriod === undefined ? (
        <DueDateButton
          key='due-date-button'
          onClick={() => onDueDateClick(item)}
          icon='calendar'
          variant='secondary'
        >
          {dueDate !== undefined ? getDueDateString({ t, dueDate }) : t('due-date.set-due-date')}
        </DueDateButton>
      ) : (
        <MenuButton
          key='due-date-relative-button'
          iconId='calendar'
          variant='secondary'
          menuItems={[...relativeDueDateMenuItems]}
          onSelect={() => {}}
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
        >
          {dueDate !== undefined ? getDueDateString({ t, dueDate }) : t('due-date.set-due-date')}
        </MenuButton>
      )
    ) : null

  const requiredSwitch =
    isRequiredProps === undefined ? null : (
      <IconMenu
        variant='secondary'
        iconId='overflow-menu--horizontal'
        onSelect={() => {}}
        menuItems={[
          {
            type: 'canvas',
            id: 'required-assignment',
            preventClose: true,
            render() {
              return isRequiredProps.type === 'required-assignment-with-default' ? (
                <RequiredAssignmnentDropdown
                  assignmentPriority={isRequiredProps.assignmentPriority}
                  setAssignmentPriority={isRequiredProps.setAssignmentPriority}
                />
              ) : (
                <RequiredAssignmentWrapper direction='column'>
                  <Text bold color='foreground/muted' size='micro'>
                    {t('dictionary.more-options')}
                  </Text>
                  <RequiredAssignmnentSwitch
                    assignmentPriority={isRequiredProps.assignmentPriority}
                    setAssignmentPriority={isRequiredProps.setAssignmentPriority}
                  />
                </RequiredAssignmentWrapper>
              )
            },
          },
        ]}
      />
    )

  const recurrenceButton =
    getFlag('recurrent-assignments') === true && recurrencePeriodProps !== undefined ? (
      <RecurrenceButton
        recurrencePeriod={recurrencePeriodProps.recurrencePeriod}
        onChangeRecurrencePeriod={recurrencePeriodProps.setRecurrencePeriod}
        onClearRecurrencePeriod={recurrencePeriodProps.clearRecurrencePeriod}
      />
    ) : null

  const typeLabel = (() => {
    if (item.type !== 'course' && item.type !== 'path') return null

    const { iconId, translationKey } = getContentClassificationData({
      contentType: item.type,
      courseKind: item.type === 'course' ? item.kind : undefined,
      isCourseEdition: item.type === 'course' ? item.isCourseEdition : false,
    })

    return (
      <View gap='4'>
        <Icon iconId={iconId} color='grey40' size='size-14' />
        <Text size='small' color='grey40'>
          {t(translationKey)}
        </Text>
      </View>
    )
  })()

  switch (item.type) {
    case 'course':
      return (
        <BaseItem
          left={
            <>
              {item.isCourseEdition && <CourseEditionIndentation />}
              <PreviewImageContainer>
                <CourseMark kind={item.kind}>
                  <Thumbnail
                    width={4.375}
                    height={3.125}
                    image={item.image}
                    iconId={
                      isRequiredAssignmentsEnabled && item.isDefaultRequiredAssignment
                        ? 'arrow-up--filled'
                        : undefined
                    }
                  />
                </CourseMark>
              </PreviewImageContainer>
            </>
          }
        >
          <TruncatedText key='title' size='small' lines={2} bold>
            {item.title}
          </TruncatedText>
          {typeLabel}
          <Spacer axis='vertical' size='4' />
          <View direction='row' grow justifyContent='flex-start' alignItems='center'>
            {item.kind === 'native:live' || item.kind === 'native:event-group' ? null : (
              <>
                {dueDateButton}
                {recurrenceButton}
              </>
            )}
            {requiredSwitch}
          </View>
        </BaseItem>
      )
    case 'path':
      return (
        <BaseItem
          left={
            <PreviewImageContainer>
              <PreviewImages
                type='thumbnail'
                images={item.images}
                iconId={
                  isRequiredAssignmentsEnabled && item.isDefaultRequiredAssignment
                    ? 'arrow-up--filled'
                    : undefined
                }
              />
            </PreviewImageContainer>
          }
        >
          <TruncatedText key='path-title' size='small' lines={2} bold>
            {item.title}
          </TruncatedText>
          <View>
            {typeLabel}
            {dueDateButton}
            {requiredSwitch}
          </View>
        </BaseItem>
      )
    case 'user':
      return (
        <View direction='column' gap='16'>
          <View direction='row' grow justifyContent='flex-start' alignItems='center'>
            <UserDisplay
              primaryText={item.title}
              secondaryText={item.email}
              avatar={{
                firstName: item.firstName,
                lastName: item.lastName,
                src: getAvatarImage(item.id, item.image),
                color: item.color,
                size: 'medium',
              }}
              nameLabel={
                item.status === 'active' ? undefined : (
                  <Label $size='small' $bgColor='white' $color='grey30' $borderColor='grey30'>
                    {t(getUserStatusTranslationKey(item.status))}
                  </Label>
                )
              }
            />
          </View>
          <View direction='row' grow justifyContent='flex-start' alignItems='center'>
            {dueDateButton}
            {recurrenceButton}
            {requiredSwitch}
          </View>
        </View>
      )
    case 'user-group':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' key='group-title-and-user-count'>
            <Tooltip title={item.title}>
              <TruncatedText color='foreground/primary' size='small' lines={1} bold>
                {item.title}
              </TruncatedText>
            </Tooltip>
            <TruncatedText size='small' lines={1} color='grey50'>
              {t('manage.users.n-users', { count: item.userCount })}
            </TruncatedText>
          </View>
          <Spacer axis='vertical' size='4' />
          <View direction='row' grow justifyContent='flex-start' alignItems='center'>
            {dueDateButton}
            {recurrenceButton}
            {requiredSwitch}
          </View>
        </BaseItem>
      )
    case 'program':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' key='group-title-and-user-count'>
            <TruncatedText color='foreground/primary' size='small' lines={1} bold>
              {item.title}
            </TruncatedText>
            <TruncatedText size='small' lines={1} color='grey50'>
              {t('manage.users.n-users', { count: item.userCount })}
            </TruncatedText>
          </View>
          <View>
            {dueDateButton}
            {requiredSwitch}
          </View>
        </BaseItem>
      )
    case 'live-session':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' justifyContent='space-between' key='live-session-title'>
            <TruncatedText color='foreground/primary' size='small' lines={1} bold>
              {item.title}
            </TruncatedText>
            <Spacer axis='horizontal' size='4' />
            {item.startTime !== undefined && item.endTime !== undefined && (
              <TruncatedText size='small' lines={1} color='grey50'>
                {formatDateFromTo({ dateFrom: item.startTime, dateTo: item.endTime }) ?? ''}
              </TruncatedText>
            )}
          </View>
          <Spacer size='4' />
          <IconList>
            {_.compact([
              <IconListItem key='users' iconId='user--group' text={item.assignmentsText} />,
              item.locationValue !== undefined && item.locationValue.length > 0 && (
                <IconListItem key='location' iconId='location' text={item.locationValue} />
              ),
            ])}
          </IconList>
        </BaseItem>
      )
    default:
      assertNever(item)
  }
}

export const ItemRow: React.FC<{
  item: ItemUnion
  isSelected: boolean
  onClick: () => void
}> = ({ item, isSelected, onClick }) => (
  <Item key={item.id} isSelected={isSelected} item={item} onClick={onClick}>
    <ItemContent item={item} />
  </Item>
)

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow-y: auto;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
