import { useState } from 'react'

type QuizState = {
  currentQuestionIndex: number
  correctStreak: number
  totalAnswered: number
  answers: Array<{
    questionIndex: number
    selectedAnswer: string
    wasCorrect: boolean
  }>
  status: 'in-progress' | 'completed'
}

export const useQuiz = (
  questions: {
    alternatives: {
      text: string
      correct: boolean
    }[]
    questionTitle: string
  }[]
): {
  quizState: QuizState
  startQuiz: () => void
  restartQuiz: () => void
  handleAlternativeClick: (alternative: { text: string; correct: boolean }) => void
  changeQuestion: (direction: 'up' | 'down') => void
  currentQuestionAnswer: { selectedAnswer: string; wasCorrect: boolean } | undefined
} => {
  const [quizState, setQuizState] = useState<QuizState>({
    currentQuestionIndex: 0,
    correctStreak: 0,
    totalAnswered: 0,
    answers: [],
    status: 'in-progress',
  })

  const startQuiz = (): void => {
    setQuizState(prev => ({ ...prev, status: 'in-progress' }))
  }

  const restartQuiz = (): void => {
    setQuizState({
      currentQuestionIndex: 0,
      correctStreak: 0,
      totalAnswered: 0,
      answers: [],
      status: 'in-progress',
    })
  }

  const handleAlternativeClick = (alternative: { text: string; correct: boolean }): void => {
    setQuizState(prev => ({
      ...prev,
      correctStreak: alternative.correct ? prev.correctStreak + 1 : 0,
      totalAnswered: prev.totalAnswered + 1,
      answers: [
        ...prev.answers,
        {
          questionIndex: prev.currentQuestionIndex,
          selectedAnswer: alternative.text,
          wasCorrect: alternative.correct,
        },
      ],
    }))

    // We wait a little before we move to the next q
    setTimeout(() => {
      setQuizState(prev => {
        if (prev.currentQuestionIndex === questions.length - 1) {
          return { ...prev, status: 'completed' }
        }

        return {
          ...prev,
          currentQuestionIndex: prev.currentQuestionIndex + 1,
        }
      })
    }, 800)
  }

  const changeQuestion = (direction: 'up' | 'down'): void => {
    if (questions.length === 0) return

    setQuizState(prev => ({
      ...prev,
      currentQuestionIndex:
        direction === 'up'
          ? Math.min(prev.currentQuestionIndex + 1, questions.length - 1)
          : Math.max(prev.currentQuestionIndex - 1, 0),
    }))
  }

  const currentQuestionAnswer = quizState.answers.find(
    answer => answer.questionIndex === quizState.currentQuestionIndex
  )

  return { quizState, startQuiz, restartQuiz, handleAlternativeClick, changeQuestion, currentQuestionAnswer }
}
