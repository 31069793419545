import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Interval, IntervalUnit } from 'sierra-domain/api/manage'
import { MenuItem } from 'sierra-ui/components'
import { InputPrimitive, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const TextInput = styled(Text)`
  width: 50%;
`

const InputWrapper = styled(View)`
  width: 50%;
`

const PeriodInputPrimitive = styled(InputPrimitive)`
  width: 50%;
`

interface IntervalInputProps {
  value?: Interval | undefined
  leadingText: string
  onIntervalChange(interval: Interval): void
}

export const IntervalInput: React.FC<IntervalInputProps> = ({ value, leadingText, onIntervalChange }) => {
  const { t } = useTranslation()
  const [intervalUnit, setIntervalUnit] = useState<IntervalUnit | undefined>(value?.unit)
  const [intervalValue, setIntervalValue] = useState<number | undefined>(value?.value)

  const menuItems: MenuItem<'years' | 'months' | 'days'>[] = [
    { id: 'days', label: t('interval.days'), type: 'label' },
    { id: 'months', label: t('interval.months'), type: 'label' },
    { id: 'years', label: t('interval.years'), type: 'label' },
  ]

  return (
    <View direction='column'>
      <TextInput size='small' bold>
        {leadingText}
      </TextInput>
      <View direction='row' gap='8' justifyContent='space-between'>
        <InputWrapper>
          <PeriodInputPrimitive
            type='number'
            onChange={e => {
              const value = parseInt(e.target.value)
              setIntervalValue(value)
              if (intervalUnit !== undefined) {
                onIntervalChange({ value, unit: intervalUnit })
              }
            }}
            value={intervalValue?.toString() ?? ''}
          />
        </InputWrapper>
        <InputWrapper>
          <SingleSelectDropdown
            menuItems={menuItems}
            onSelect={item => {
              const unit = item.id
              setIntervalUnit(unit)
              if (intervalValue !== undefined) {
                onIntervalChange({ value: intervalValue, unit })
              }
            }}
            selectedItem={menuItems.find(item => item.id === intervalUnit)}
            bold={true}
          />
        </InputWrapper>
      </View>
    </View>
  )
}
