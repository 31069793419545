import React, { useContext, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { setAuthClient } from 'sierra-client/auth/auth-client'
import { TokenAuthClient } from 'sierra-client/auth/clients/token-auth-client'
import { getFlag } from 'sierra-client/config/global-config'
import { LiveSessionContext } from 'sierra-client/context/live-session-context'
import { errorLogger } from 'sierra-client/error/error-logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { RECAPTCHA_SITE_KEY, useRecaptcha } from 'sierra-client/hooks/use-recaptcha'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { Terms } from 'sierra-client/views/authentication/common'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCreateGuestUser } from 'sierra-domain/routes'
import { FormElement } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text } from 'sierra-ui/primitives'

export const LiveGuestAccess = (): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [name, setName] = useState<string>('')
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const liveConfig = useContext(LiveSessionContext)

  const enableRecaptcha = getFlag('live-guest-recaptcha')

  const { getRecaptcha, recaptchaRef } = useRecaptcha({
    errorCallback: (e: string) => {
      errorLogger.error(e)
      setError(e)
    },
  })

  const liveSessionId = liveConfig?.meta.allowGuestAccess === true ? liveConfig.liveSessionId : undefined

  const submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    if (liveSessionId === undefined) {
      setError(t('login.errors.invalid-live-session'))
      return
    }

    if (name.trim() === '') {
      setError(t('login.errors.no-name'))
      return
    }

    if (termsAccepted !== true) {
      setError(t('create-account.errors.tos'))
      return
    }

    let recaptcha: string | undefined = undefined

    if (enableRecaptcha === true) {
      recaptcha = (await getRecaptcha()) ?? undefined

      if (recaptcha === undefined) return
    }

    const result = await postWithUserErrorCode(XRealtimeAuthCreateGuestUser, {
      name,
      liveSessionId,
      recaptcha,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    const guestAuthClient = new TokenAuthClient(result.right.token)
    setAuthClient(guestAuthClient)

    await guestAuthClient.synchronize()
  }

  return (
    <AuthenticationContent message={t('live-guest-access.heading')}>
      {enableRecaptcha === true && (
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size={'invisible'} ref={recaptchaRef} />
      )}
      <form onSubmit={submit}>
        <FormElement label={t('live-guest-access.name')} isError={error !== undefined}>
          <InputPrimitive
            type='text'
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            autoComplete='username'
          />
        </FormElement>
        <Terms setTermsAccepted={setTermsAccepted} termsAccepted={termsAccepted} />

        <Spacer size='small' />
        {error !== undefined && (
          <Text size='small' color='redBright' spacing='xsmall'>
            {error}
          </Text>
        )}
        <Button
          data-pfcid={termsAccepted ? 'sign-in-button' : null}
          variant='primary'
          onClick={submit}
          disabled={!termsAccepted}
          grow
        >
          {t('login.action')}
        </Button>
      </form>
    </AuthenticationContent>
  )
}
