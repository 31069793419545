import React from 'react'
import { TutorStateAvailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { Button, View } from 'sierra-ui/primitives'

export const AITutorELI5Pill: React.FC<{
  tutorState: TutorStateAvailable
}> = ({ tutorState }) => {
  return (
    <View initial={{ opacity: 0 }} animate={{ opacity: 1 }} gap='4'>
      <Button
        variant='ghost'
        onClick={() =>
          tutorState.setTutorModalState({
            type: 'showing-eli5',
            forFileId: tutorState.qaState.startedFromFileId,
          })
        }
      >
        ELI5
      </Button>
    </View>
  )
}
