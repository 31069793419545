import { useParams } from '@tanstack/react-router'
import _ from 'lodash'
import { useEffect } from 'react'
import { useActiveRouteId } from 'sierra-client/hooks/router/use-active-route'
import { getGlobalRouter } from 'sierra-client/router'
import { ContentState } from 'sierra-client/state/content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { RootState } from 'sierra-client/state/types'
import { CurrentIds, locationChanged } from 'sierra-client/state/v2/navigation-actions'
import { createNanoId12FromUUID } from 'sierra-domain/nanoid-extensions'
import { isUUID } from 'sierra-domain/utils'

const selectContentState = (state: RootState): ContentState => state.content

// Set the user's location whenever the route changes
export const RouteWatcher = (): null => {
  const routeId = useActiveRouteId()
  const { courseId, pathId, programId, flexibleContentId } = useParams({
    strict: false,
    select: s => ({
      courseId: s.courseId,
      pathId: s.pathId,
      programId: s.programId,
      flexibleContentId: s.flexibleContentId,
    }),
  })

  const dispatch = useDispatch()
  // The live session id is set but the page itself, since the new /room route
  // doesn't have a live session id in the URL

  const { currentLiveSessionId, ...currentLocation } = useSelector(selectContentState)
  // TODO: plugins for admin routes
  // if a query param is called courseId, this plugin will update the redux
  // state and the CourseContentFetcher plugin will trigger a redirection
  // (we want to handle this separately for admin routes)
  const isAdminRoute = routeId.startsWith('/create') || routeId.startsWith('/manage')
  const isSelfPacedRoute = routeId.includes('/s/')

  useEffect(() => {
    const setLocationIfChanged = (newLocation: CurrentIds): void => {
      if (!_.isEqual(currentLocation, newLocation)) void dispatch(locationChanged(newLocation))
    }

    if (isAdminRoute) {
      // Reset all ids when exiting learner mode
      setLocationIfChanged({
        currentProgramId: undefined,
        currentPathId: undefined,
        currentCourseId: undefined,
      })
      return
    }

    const currentCourseId = isSelfPacedRoute ? flexibleContentId : courseId

    if (currentCourseId !== undefined && isUUID(currentCourseId)) {
      void getGlobalRouter().navigate({
        to: `/course/${createNanoId12FromUUID(currentCourseId)}`,
        replace: true,
      })
      return
    }

    setLocationIfChanged({
      currentProgramId: programId,
      currentPathId: pathId,
      currentCourseId,
    })
  }, [
    isAdminRoute,
    isSelfPacedRoute,
    currentLocation,
    courseId,
    pathId,
    programId,
    flexibleContentId,
    dispatch,
  ])

  return null
}
