import { motion, TargetAndTransition } from 'framer-motion'
import { useAtomValue } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import {
  desktopHelperConfig,
  helperInlineStates,
  useDesktopHelperBorderRadius,
  useHelperVisibility,
} from 'sierra-client/views/course-helper/config'
import { RenderHelperContent } from 'sierra-client/views/course-helper/content'
import { DesktopBubble, DesktopContentWrapper } from 'sierra-client/views/course-helper/shared/containers'
import { useHelperSize } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { ColoredIcon } from 'sierra-client/views/course-helper/shared/shared'
import { isSecondaryHelperStyling } from 'sierra-client/views/course-helper/shared/should-show-box-shadow'
import { AITutorBubble } from 'sierra-client/views/course-helper/tutor/ai-tutor-bubble'
import { AITutorELI5Pill } from 'sierra-client/views/course-helper/tutor/ai-tutor-eli5-pill'
import { AITutorSuggestQuestion } from 'sierra-client/views/course-helper/tutor/ai-tutor-suggest-questions'
import { HelperVisibility } from 'sierra-client/views/course-helper/types'
import { TutorState, TutorStateAvailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import { isSlateFile } from 'sierra-domain/flexible-content/types'
import { iife } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { View } from 'sierra-ui/primitives'
import styled, { css, useTheme } from 'styled-components'

const { bubbleDuration, fadeDuration } = desktopHelperConfig

const LayerIsolation = styled.div`
  position: relative;
  isolation: isolate;
`

const contentVariants: Record<HelperVisibility, (delay: number) => TargetAndTransition> = {
  hidden: (delay: number) => ({
    opacity: 0,
    transition: {
      delay: delay,
      duration: fadeDuration,
    },
  }),
  visible: (delay: number) => ({
    opacity: 1,
    transition: {
      delay,
      duration: fadeDuration,
    },
  }),
}

const Inner = styled(motion.div).attrs({
  variants: contentVariants,
})`
  overflow: hidden;
  pointer-events: auto;
`

export function useCanShowTutor(currentFile: SelfPacedFile | undefined): boolean {
  const isRightFileType = iife(() => {
    if (!getFlag('ai-tutor-helper') || currentFile === undefined) {
      return false
    }
    if (isSlateFile(currentFile)) {
      switch (currentFile.data.type) {
        case 'flip-cards':
        case 'assessment-card':
        case 'homework':
        case 'scenario':
        case 'question-card':
        case 'poll':
        case 'reflections':
          return false

        case 'general':
        case 'slate-card':
        case 'sliding-scale':
        case 'bullet':
          return true
      }
    }
    return false
  })

  return isRightFileType && getFlag('ai-tutor-helper')
}

const TutorBubbleContent: React.FC<{
  tutorState: TutorStateAvailable
}> = ({ tutorState }) => {
  return (
    <View gap='8'>
      <AITutorSuggestQuestion count={1} tutorState={tutorState} />
      {getFlag('ai-tutor-experimental') && <AITutorELI5Pill tutorState={tutorState} />}

      <ColoredIcon size='size-20' iconId='sana-symbol' $color={undefined} />
    </View>
  )
}

const ToggleDisplay = styled.div<{ $hidden: boolean }>`
  opacity: 0;
  ${p =>
    p.$hidden
      ? 'display: none;'
      : css`
          animation: fadeIn 0.1s ease-in-out forwards;
          animation-delay: 0.5s;
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
`

export const DesktopHelperInternal: React.FC<{
  tutorState: TutorState
  currentFile: SelfPacedFile
}> = ({ tutorState, currentFile }) => {
  const { t } = useTranslation()
  const helperState = useAtomValue(helperStateAtom)
  const visibility: HelperVisibility = useHelperVisibility()
  const borderRadius = useDesktopHelperBorderRadius()
  const { size, ref, animationEnabled } = useHelperSize()

  const theme = useTheme()
  const backgroundColor = color(theme.home.backgroundColor)
  const isSecondaryStyling = isSecondaryHelperStyling(helperState)
  const isInlineState = helperInlineStates.includes(helperState.type)
  const seeThrough = isSecondaryStyling ? { backgroundColor: backgroundColor } : undefined

  const canShowAITutor = useCanShowTutor(currentFile) && tutorState.type === 'available'

  return (
    <View gap='8'>
      {(helperState.type === 'forward' || helperState.type === 'text') && canShowAITutor && (
        <ToggleDisplay
          // Show the tutor bubble only when we are in the 'forward' state,
          // but render it otherwise in the tree to preload the state
          $hidden={helperState.type === 'text' && helperState.textType !== 'continue'}
        >
          <AITutorBubble
            role='complementary'
            aria-label={t('dictionary.ai-assistant')}
            $seeThrough={seeThrough}
            custom={{
              opacity:
                // Hide it until we get to the 'forward' state
                helperState.type === 'text' ? 0 : isInlineState ? 0.85 : undefined,
              size,
              duration: animationEnabled ? bubbleDuration : 0,
              borderRadius,
            }}
            initial='hidden'
            animate={visibility}
          >
            <TutorBubbleContent tutorState={tutorState} />
          </AITutorBubble>
        </ToggleDisplay>
      )}
      <LayerIsolation>
        <DesktopContentWrapper ref={ref} $seeThrough={seeThrough} $disableHover={!isInlineState}>
          <Inner
            key={helperState.type}
            custom={animationEnabled ? bubbleDuration : 0}
            initial='hidden'
            animate={visibility}
          >
            <RenderHelperContent tutorState={tutorState} />
          </Inner>
        </DesktopContentWrapper>
        <DesktopBubble
          $seeThrough={seeThrough}
          custom={{
            opacity: isInlineState ? 0.85 : undefined,
            size,
            duration: animationEnabled ? bubbleDuration : 0,
            borderRadius,
          }}
          initial='hidden'
          animate={visibility}
        />
      </LayerIsolation>
    </View>
  )
}
