import { produce } from 'immer'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useThrottledAndLiveState } from 'sierra-client/hooks/use-throttled-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  BrandLogo,
  Centered,
  Content,
  ItemTitle,
  ListContainer,
  ListItem,
  Wrapper,
} from 'sierra-client/views/learner/course/edition-page/components'
import { useEditions, useOnContinue } from 'sierra-client/views/learner/course/edition-page/hooks'
import { EditionWithTranslations } from 'sierra-client/views/learner/course/edition-page/types'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isAssaAbloy, isHM, isPiab, isSecuritas } from 'sierra-domain/organization'
import { Button, Checkbox, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider, createGlobalStyle, useTheme } from 'styled-components'

const useEditionPageTheme = (): Theme => {
  const theme = useTheme()

  return useMemo(() => {
    const orgUuid = config.organization.uuid

    if (isSecuritas(orgUuid)) {
      return produce(theme, draft => {
        draft.mode = 'dark'
        draft.home.backgroundColor = '#031F30'
        draft.home.textColor = palette.primitives.white
        draft.text.default = palette.primitives.white
      })
    } else if (isAssaAbloy(orgUuid)) {
      return produce(theme, draft => {
        draft.mode = 'dark'
        draft.home.backgroundColor = '#00A0D0'
        draft.home.textColor = palette.primitives.white
        draft.text.default = palette.primitives.white
      })
    } else if (isHM(orgUuid)) {
      return produce(theme, draft => {
        // #FCF4DA or #EBD7B9
        draft.home.backgroundColor = '#FCF4DA'
      })
    }
    return theme
  }, [theme])
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${token('surface/default')};
  }
`

type EditionPageCopy = {
  body: string
  bodySecondary?: string
  searchPlaceholder: string
  buttonText: string
}

const useEditionPageCopy = (): EditionPageCopy => {
  const { t } = useTranslation()

  return useMemo(() => {
    const defaultCopy = {
      body: t('course-editions.select-edition.body-select-country'),
      searchPlaceholder: t('course-editions.select-edition.search-placeholder'),
      buttonText: t('course-editions.select-edition.item-cta'),
    }

    const orgUuid = config.organization.uuid

    if (isAssaAbloy(orgUuid)) {
      return {
        body: t('course-editions.select-edition-assa-abloy.body'),
        bodySecondary: t('course-editions.select-edition-assa-abloy.body-secondary'),
        searchPlaceholder: t('course-editions.select-edition-assa-abloy.search-placeholder'),
        buttonText: t('course-editions.select-edition-assa-abloy.item-cta'),
      }
    }

    if (isHM(orgUuid)) {
      return {
        body: t('course-editions.select-edition-hm.body'),
        searchPlaceholder: t('course-editions.select-edition.search-placeholder'),
        buttonText: t('dictionary.continue'),
      }
    }

    if (isPiab(orgUuid)) {
      return {
        ...defaultCopy,
        body: t('course-editions.select-edition.body-select-language'),
      }
    }

    return defaultCopy
  }, [t])
}

type EditionPageProps = {
  scormCourseGroupId: CourseId
}

export const EditionPage = ({ scormCourseGroupId }: EditionPageProps): JSX.Element | null => {
  const editionTheme = useEditionPageTheme()
  const { dynamicT } = useTranslation()
  const [selectedEdition, setSelectedEdition] = React.useState<EditionWithTranslations | undefined>(undefined)
  const [throttledSearchTerm, searchTerm, setSearchTerm] = useThrottledAndLiveState('', 100, {
    leading: true,
    trailing: true,
  })
  const copy = useEditionPageCopy()

  const editions = useEditions(scormCourseGroupId)
  const onContinue = useOnContinue(scormCourseGroupId)

  const editionsWithTranslations = React.useMemo(() => {
    if (editions === undefined) return undefined

    const languageCounts = _.countBy(editions, 'country')

    return editions
      .map(edition => {
        const countryName = edition.country !== undefined ? dynamicT(`country.${edition.country}`) : undefined
        const languageName = dynamicT(`language.${edition.language}`)

        // If the country only has one edition, and the language of that edition is English, don't show
        // the language in the label.
        const label =
          edition.label !== undefined
            ? edition.label
            : edition.country === undefined || countryName === undefined
              ? languageName
              : languageCounts[edition.country] === 1 && edition.language === 'en'
                ? countryName
                : `${countryName} (${languageName})`

        return {
          ...edition,
          countryName,
          languageName,
          label,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [editions, dynamicT])

  const filteredEditions = React.useMemo(() => {
    if (throttledSearchTerm === '') return editionsWithTranslations

    const needle = throttledSearchTerm.toLowerCase()

    const hasNeedle = (s: string): boolean => s.toLowerCase().includes(needle)

    return editionsWithTranslations?.filter(ed => {
      const haystacks = [ed.countryName, ed.languageName].filter(Boolean) as string[]

      if (haystacks.some(hasNeedle)) return true

      // ...other filtering here?

      return false
    })
  }, [editionsWithTranslations, throttledSearchTerm])

  // Reset selection if not present in filtered results
  React.useEffect(() => {
    if (filteredEditions === undefined || selectedEdition === undefined) return

    if (!filteredEditions.includes(selectedEdition)) {
      setSelectedEdition(undefined)
    }
  }, [selectedEdition, filteredEditions])

  if (!filteredEditions) return null

  return (
    <ThemeProvider theme={editionTheme}>
      <GlobalStyle />
      <Wrapper>
        <Content>
          <Spacer size='xsmall' />
          <BrandLogo />
          <Spacer size='medium' />

          <Centered>
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
              {copy.body}
            </Text>
            {copy.bodySecondary !== undefined && (
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' italic>
                {copy.bodySecondary}
              </Text>
            )}
            <Spacer size='small' />
            <RoundedSearchBar
              value={searchTerm}
              placeholder={copy.searchPlaceholder}
              onChange={text => setSearchTerm(text)}
            />
            <Spacer size='medium' />
          </Centered>
          <ListContainer>
            {filteredEditions.map(edition => (
              <ListItem
                key={edition.id}
                onClick={() =>
                  setSelectedEdition(currEdition => (edition.id === currEdition?.id ? undefined : edition))
                }
              >
                <View
                  direction='column'
                  wrap='nowrap'
                  grow
                  overflow='hidden'
                  alignItems='flex-start'
                  gap='none'
                  justifyContent='center'
                >
                  <ItemTitle color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{edition.label}</ItemTitle>
                </View>
                <Checkbox checked={selectedEdition === edition} />
              </ListItem>
            ))}
          </ListContainer>
          <Spacer size='xsmall' />
          <Button disabled={selectedEdition === undefined} onClick={() => onContinue(selectedEdition)}>
            {copy.buttonText}
          </Button>
          <Spacer size='medium' />
        </Content>
      </Wrapper>
    </ThemeProvider>
  )
}
