import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { _RootLayout } from 'sierra-client/_rootLayout'
import { DomainThemeProvider } from 'sierra-client/config/domain-theme'
import { config } from 'sierra-client/config/global-config'
import * as format from 'sierra-client/core/format'
import { date, useGetTimeSpent } from 'sierra-client/core/format'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { HeaderLogo } from 'sierra-client/layout/header/home/components/logo'
import { GlobalStylesV2 } from 'sierra-client/layout/sana-page'
import { resolvePrintableTranscriptElements } from 'sierra-client/views/v3-author/export-pdf/print-resolver'
import {
  CourseStatusElement,
  ElementExtended,
  ExternalTrainingElement,
  UserInfoElement,
} from 'sierra-client/views/v3-author/export-pdf/types'
import { UserTranscriptResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUsersUserTranscript } from 'sierra-domain/routes'
import { Button, LoadingSpinner, Spacer } from 'sierra-ui/primitives'
import { theme } from 'sierra-ui/theming/legacy-theme'
import styled, { createGlobalStyle, css } from 'styled-components'

const SCALE_FACTOR = 3.77

const A4_HEIGHT = Math.floor(297 * SCALE_FACTOR)
const A4_WIDTH = Math.floor(210 * SCALE_FACTOR)

const PADDING_Y = 50
const PADDING_X = 40

const INNER_HEIGHT = A4_HEIGHT - 2 * PADDING_Y

const StyleA4Pagination = css`
  ${_RootLayout} {
    overflow: visible !important;
  }

  body,
  html {
    margin: 0;
    padding: 0;
    zoom: 100%;
  }

  @page {
    size: ${A4_WIDTH}px ${A4_HEIGHT}px;
    margin: 0;
  }

  @media print {
    html,
    body {
      width: ${A4_WIDTH}px;
      height: ${A4_HEIGHT}px;
    }
  }
`

const GlobalCss = createGlobalStyle`
  #__next {
    background: white;
  }

  ${StyleA4Pagination}
`

const Doc = styled.div`
  margin: 0 auto;
  padding: 0;
  width: ${A4_WIDTH}px;
  min-height: ${2 * A4_HEIGHT}px;

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  @media print {
    margin: 0;
    min-height: 0;
  }
`

const StyledA4Page = styled.div`
  width: ${A4_WIDTH}px;
  padding: ${PADDING_Y}px ${PADDING_X}px;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @media print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`

const PhantomPage = styled(StyledA4Page)`
  opacity: 0;
`

const RenderedPage = styled(StyledA4Page)`
  min-height: ${A4_HEIGHT}px;
`

const PrintButtonContainer = styled.div`
  width: ${A4_WIDTH}px;
`

const PrintButton = styled(Button)`
  margin: 2rem auto;
  display: block;

  @media print {
    display: none !important;
  }
`

const Table = styled.table`
  width: 100%;
`
const TableRow = styled.tr``
const TableCell = styled.td`
  border: 1px solid ${p => p.theme.color.grey10};
  padding: 0.5rem 0.75rem;
`
const TableCellHalf = styled(TableCell)`
  width: 50%;
`

const UserInfoRenderer: React.FC<{ user: UserInfoElement['user'] }> = ({ user }) => {
  return (
    <div>
      <HeaderLogo />
      <Spacer size='xlarge' />
      <h2>User Info</h2>
      <Spacer />
      <Table>
        <tbody>
          <TableRow>
            <TableCellHalf>
              <strong>Name</strong>
            </TableCellHalf>
            <TableCellHalf>{user.firstName + ' ' + user.lastName}</TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Email</strong>
            </TableCellHalf>
            <TableCellHalf>{user.email}</TableCellHalf>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

const CourseStatusRenderer: React.FC<{
  course: CourseStatusElement['course']
  selfStarted: boolean
}> = ({ course, selfStarted }) => {
  const { formatTimeSpent } = useGetTimeSpent()

  const { t } = useTranslation()
  return (
    <div>
      <Spacer size='xlarge' />
      <h2>{course.name}</h2>
      <Spacer />
      <h5 style={{ color: theme.color.grey45 }}>Internal training</h5>
      <Spacer />
      <Table>
        <tbody>
          <TableRow>
            <TableCellHalf>
              {selfStarted ? <strong>Self-started</strong> : <strong>Assigned via</strong>}
            </TableCellHalf>
            <TableCellHalf>
              {course.programs.length > 0
                ? course.programs.map(program => program.name).join(', ')
                : selfStarted
                  ? '-'
                  : 'Individual'}
            </TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Progress</strong>
            </TableCellHalf>
            <TableCellHalf>{format.percentage(course.progress, 1)}</TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Start date</strong>
            </TableCellHalf>
            <TableCellHalf>
              {course.startedTimestamp !== undefined
                ? date(course.startedTimestamp)
                : t('manage.users.status.not-started')}
            </TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Completion date</strong>
            </TableCellHalf>
            <TableCellHalf>
              {course.passedTimestamp !== undefined ? date(course.passedTimestamp) : 'Not completed'}
            </TableCellHalf>
          </TableRow>
          {course.timeSpentMinutes !== undefined && (
            <TableRow>
              <TableCellHalf>
                <strong>Time spent</strong>
              </TableCellHalf>
              <TableCellHalf>{formatTimeSpent(course.timeSpentMinutes)}</TableCellHalf>
            </TableRow>
          )}
          <TableRow>
            <TableCellHalf>
              <strong>Duration</strong>
            </TableCellHalf>
            <TableCellHalf>{formatTimeSpent(course.durationInMinutes)}</TableCellHalf>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

const ExternalTrainingRenderer: React.FC<{
  externalTraining: ExternalTrainingElement['externalTraining']
}> = ({ externalTraining }) => {
  const { formatTimeSpent } = useGetTimeSpent()

  return (
    <div>
      <Spacer size='xlarge' />
      <h2>{externalTraining.title}</h2>
      <Spacer />
      <h5 style={{ color: theme.color.grey45 }}>User reported training</h5>
      <Spacer />
      <Table>
        <tbody>
          <TableRow>
            <TableCellHalf>
              <strong>Training provider</strong>
            </TableCellHalf>
            <TableCellHalf>{externalTraining.provider}</TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Date</strong>
            </TableCellHalf>
            <TableCellHalf>{externalTraining.completionDate}</TableCellHalf>
          </TableRow>
          <TableRow>
            <TableCellHalf>
              <strong>Duration</strong>
            </TableCellHalf>
            <TableCellHalf>{formatTimeSpent(externalTraining.durationInMinutes)}</TableCellHalf>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

const CustomRenderer: React.FC<{ element: ElementExtended }> = ({ element }) => {
  switch (element.type) {
    case 'user-info':
      return <UserInfoRenderer user={element.user} />
    case 'external-training-status':
      return <ExternalTrainingRenderer externalTraining={element.externalTraining} />
    case 'course-status':
      return <CourseStatusRenderer course={element.course} selfStarted={false} />
    case 'self-started-course-status':
      return <CourseStatusRenderer course={element.course} selfStarted={true} />
  }
}

const PaginatedTranscriptRenderer: React.FC<{
  title?: string
  transcript?: UserTranscriptResponse
}> = ({ title, transcript }) => {
  const [loaded, setLoaded] = useState(false)
  const [ready, setReady] = useState(false)

  const [remainingElements, setRemainingElements] = useState<ElementExtended[]>([])
  const [currentPhantomElements, setCurrentPhantomElements] = useState<ElementExtended[]>([])
  const [pages, setPages] = useState<ElementExtended[][]>([])

  const phantomRef = useRef<HTMLDivElement>(null)

  // These hooks aggregate the rendered elements
  // into chunks that fit into A4 pages. Algorithm outline:

  // Init:
  // Put all elements in remainingElements
  useEffect(() => {
    if (loaded || transcript === undefined) return

    const elements = resolvePrintableTranscriptElements(transcript)

    setRemainingElements(elements)
    setCurrentPhantomElements([])
    setLoaded(true)
  }, [loaded, transcript])

  // While remainingElements is not empty || currentPhantomElements is not empty:
  //   1 - if currentPhantomElements is not empty:
  //         if height of PhantomPage >= A4_HEIGHT or last block requires breaking after (eg. lesson hero):
  //           Add contents of PhantomPage to registered pages
  //           Clear currentPhantomElements
  //   2 - if remainingElements is not empty:
  //         pop first element of remainingElements and add to end of currentPhantomElements
  useEffect(() => {
    if (!phantomRef.current || !loaded || ready) return

    if (remainingElements.length === 0 && currentPhantomElements.length === 0) {
      setReady(true)
      return
    }

    let phantomElements = currentPhantomElements

    if (phantomElements.length !== 0) {
      const height = phantomRef.current.clientHeight
      const shouldAddLastElementToNewPage = height > INNER_HEIGHT && phantomElements.length > 1
      const isLastElement = remainingElements.length === 0
      const isComputingHeightForOneElement = isLastElement && phantomElements.length === 1

      if (!isComputingHeightForOneElement && shouldAddLastElementToNewPage) {
        const beginning = _.dropRight(phantomElements, 1)
        phantomElements = _.takeRight(phantomElements, 1)

        setPages([...pages, beginning])
        setCurrentPhantomElements(phantomElements)
      } else if (
        isLastElement ||
        // Safeguard in case the element is bigger than a page
        height >= INNER_HEIGHT
      ) {
        setPages([...pages, phantomElements])
        setCurrentPhantomElements([])
        phantomElements = []
      }
    }

    const [first, ...rest] = remainingElements

    if (first === undefined) return

    setCurrentPhantomElements([...phantomElements, first])
    setRemainingElements(rest)
  }, [pages, remainingElements, currentPhantomElements, loaded, ready])

  useEffect(() => {
    if (ready) {
      // window.print()
      console.debug('Pages ready:', JSON.stringify({ pages }))
    }
  }, [pages, ready])

  return (
    <>
      <DomainThemeProvider defaultMode='light'>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <GlobalStylesV2 />
        <GlobalCss />
        <Doc>
          {ready ? (
            <PrintButtonContainer>
              <PrintButton onClick={window.print}>Print</PrintButton>
            </PrintButtonContainer>
          ) : (
            <LoadingSpinner size='large' padding='medium' />
          )}

          {ready &&
            pages.map(elements => (
              <RenderedPage key={elements.map(e => e.id).join('-')}>
                {elements.map((element, index) => (
                  <CustomRenderer key={index} element={element} />
                ))}
              </RenderedPage>
            ))}
          <PhantomPage ref={phantomRef}>
            {currentPhantomElements.map((element, index) => (
              <CustomRenderer key={index} element={element} />
            ))}
          </PhantomPage>
        </Doc>
      </DomainThemeProvider>
    </>
  )
}

export const PaginatedTranscriptFetcherAndRenderer: React.FC<{ userId?: UserId }> = ({ userId }) => {
  const { postWithUserErrorException } = usePost()
  const [userTranscript, setUserTranscript] = useState<UserTranscriptResponse>()

  useEffect(() => {
    void (async () => {
      if (userId === undefined) return

      const userTranscript = await postWithUserErrorException(XRealtimeUsersUserTranscript, {
        userId,
      })

      setUserTranscript(userTranscript)
    })()
  }, [postWithUserErrorException, userId])

  return <PaginatedTranscriptRenderer transcript={userTranscript} />
}

export const openTranscriptViewer = (userId: string): void => {
  const url = new URL(`${location.origin}/manage/user/${userId}/export-transcript`)
  const host = config.auth.host
  if (host !== undefined) url.searchParams.set('x-host', host)

  window.open(
    url,
    undefined,
    `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${A4_WIDTH + 40},height=${A4_HEIGHT}`
  )
}
