import fuzzysort from 'fuzzysort'
import React, { useMemo, useState } from 'react'
import { useOrderedTags } from 'sierra-client/hooks/use-ordered-tags'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TagId } from 'sierra-domain/api/nano-id'
import { TagData } from 'sierra-domain/content/tag'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

type SkillOption = TagData & { id: TagId }

type Props = {
  currentTags: TagId[]
  onTagChange: (skills: TagId[]) => void
  disabled: boolean
}

export const TagInput: React.FC<Props> = ({ currentTags, onTagChange, disabled }) => {
  const { t } = useTranslation()

  const [tagInputValue, setTagInputValue] = useState('')

  const orderedTags = useOrderedTags()

  const allTags: SkillOption[] = useMemo(() => orderedTags.filter(s => s.name !== ''), [orderedTags])

  const selectedTags = useMemo(() => {
    const allTagMap = new Map(allTags.map(tag => [tag.id, tag]))

    return currentTags.flatMap(id => {
      const tag = allTagMap.get(id)

      return tag !== undefined ? [tag] : []
    })
  }, [allTags, currentTags])

  const filteredTags = useMemo(() => {
    const allTagsExceptSelected = allTags.filter(tag => !currentTags.includes(tag.id))

    return fuzzysort.go(tagInputValue, allTagsExceptSelected, { key: 'name', all: true }).map(it => it.obj)
  }, [allTags, currentTags, tagInputValue])

  return (
    <FormElement label={t('table.skills')}>
      <Autocomplete
        disabled={disabled}
        query={tagInputValue}
        maxLines={5}
        onQueryChange={setTagInputValue}
        selectedItems={selectedTags}
        matchingItems={filteredTags}
        onSelect={item => {
          const newTagIds = new Set(currentTags)

          newTagIds.add(item.id)

          onTagChange(Array.from(newTagIds))
        }}
        onUnselect={item => {
          onTagChange(currentTags.filter(tag => tag !== item.id))
        }}
        renderSelectedItem={(tag, { onUnselect, ...props }) => (
          <SelectedPill key={tag.id} {...props}>
            <Text color='foreground/primary' bold>
              {tag.name}
            </Text>
            <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
          </SelectedPill>
        )}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {filteredTags.map((item, index) => (
              <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
                <Text bold>{item.name}</Text>
              </DefaultStyledAutocompleteOption>
            ))}
          </ListContainer>
        )}
        placeholder={t('author.course-settings.skill-placeholder')}
      />
    </FormElement>
  )
}
