import { useAtom, useAtomValue } from 'jotai'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { RequiredAssignmentMenuItem } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { getFlag } from 'sierra-client/config/global-config'
import { TrimFutureDateAsNow, useLocalizedFormatters } from 'sierra-client/core/format'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  assignedViaColumn,
  canvasColumn,
  detailedContentsColumn,
  progressColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { getDueDateFromComputedDueDate, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { ContentTypeFilter } from 'sierra-client/views/manage/content/components/content-filter'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import { LiveSessionMenuItem } from 'sierra-client/views/manage/courses/components/live-session-components'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import {
  UserContentFilter,
  UserContentRow,
  userContentRowToCsv,
  useUserAssignedContent,
} from 'sierra-client/views/manage/users/utils/use-user-assigned-content'
import { LiveSessionRow, UserDetailResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminCoursesListLiveSessions } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { isDefined } from 'sierra-ui/utils/is-defined'

type UserContentData = UserContentRow

type UserContentTableDefinition = TableDefinitionOf<
  UserContentData,
  [
    { type: 'detailedContents'; ref: 'content' },
    { type: 'progress'; ref: 'progress' },
    { type: 'assignedVia'; ref: 'assignedVia' },
    { type: 'canvas'; ref: 'availableAt' },
  ]
>

type UserContentTableData = TableDataFromDefinition<UserContentData, UserContentTableDefinition>

const AvailableAtCell: React.FC<{ row: UserContentData }> = ({ row }) => {
  const { formatTimestamp } = useLocalizedFormatters()
  const { t } = useTranslation()
  if (isDefined(row.assignmentData?.availableAt)) {
    return (
      <Text color='foreground/secondary'>
        {formatTimestamp(row.assignmentData.availableAt, undefined, undefined, TrimFutureDateAsNow.Disabled)}
      </Text>
    )
  } else {
    return (
      <Tooltip title={t('table.user-content.tooltip.pending.text')} delayDuration={400}>
        <View gap='4' cursor='default'>
          <Text color='foreground/secondary'>{t('table.available-from-pending')}</Text>
          <Icon iconId='information' color='foreground/secondary' />
        </View>
      </Tooltip>
    )
  }
}

const tableDefinition = ({
  data,
  isNested = false,
}: {
  data: UserContentData[]
  isNested?: boolean
}): UserContentTableDefinition => {
  const nested = data.reduce<Record<PropertyKey, UserContentTableData>>((acc, curr) => {
    if (curr.subComponentData !== undefined) {
      return {
        ...acc,
        [curr.id]: definition2Data(
          tableDefinition({ data: curr.subComponentData, isNested: true }),
          curr.subComponentData
        ),
      }
    }
    return acc
  }, {})

  return {
    columns: [
      detailedContentsColumn({
        sortable: true,
        ref: 'content',
        getData: row => ({
          ...row,
          variant: 'detailed' as const,
          isExpandable: Boolean(row.subComponentData),
        }),
        header: translatedLabel('table.name'),
        hints: isNested ? ['indent'] : [],
      }),
      progressColumn({
        sortable: true,
        ref: 'progress',
        header: translatedLabel('admin.analytics.table.progress'),

        getData: row => ({
          progress: row.progress,
          courseKind: row.courseKind,
          isSelfStarted: false,
          liveSessions: row.liveSessions,
        }),
      }),
      assignedViaColumn({
        sortable: true,
        ref: 'assignedVia',
        header: translatedLabel('table.assigned-via'),
        getData: row => ({
          assignmentData: row.assignmentData,
        }),
      }),
      canvasColumn({
        sortable: true,
        ref: 'availableAt',
        getData: row => ({
          view: <AvailableAtCell row={row} />,
          meta: {
            sorts: () => row.assignmentData?.availableAt ?? '',
            exports: () => row.assignmentData?.availableAt ?? '',
          },
        }),
        header: translatedLabel('table.available-from'),
      }),
    ],
    nested,
    rows: {
      getId: r => r.id,
    },
  }
}

const searchKey: StaticLoaderSearchKeyBy<UserContentTableData> = (tableData, row) =>
  tableData.rows[row]?.data.content.data?.title ?? ''

type UserContentTableMeta = StaticDataLoaderMeta<UserContentTableData>

const useUserContentTable = ({
  refetch,
  data,
  setUserAction,
  userId,
  userType,
}: {
  refetch: () => void
  data: UserContentData[]
} & Pick<UserContentTableProps, 'userId' | 'userType' | 'setUserAction'>): UseTableAPI<
  UserContentTableData,
  UserContentTableMeta
> => {
  const { t } = useTranslation()
  const loader = staticDataLoader(definition2Data(tableDefinition({ data }), data), searchKey)
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')
  const { setUsersIsRequiredContent } = useDueDate()
  const [openMenuRowData, setOpenMenuRowData] = useState<UserContentData>()
  const [courseLiveSessions, setCourseLiveSessions] = useState<LiveSessionRow[]>([])
  const [isMenuLoading, setIsMenuLoading] = useState(false)

  useEffect(() => {
    void iife(async () => {
      if (openMenuRowData?.courseKind !== 'native:live') return

      setIsMenuLoading(true)
      const response = await typedPost(XRealtimeAdminCoursesListLiveSessions, {
        flexibleContentId: openMenuRowData.id,
        skipPastSessions: true,
      })
      setIsMenuLoading(false)
      setCourseLiveSessions(response)
    })
    return () => setCourseLiveSessions([])
  }, [openMenuRowData?.id, openMenuRowData?.courseKind])

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const content = row.rawData
            const currentLiveSessionIds = content.liveSessions?.map(ls => ls.liveSessionId) ?? []

            const filteredLiveSessionsForEnrollment = courseLiveSessions.filter(ls => {
              const containsLs = currentLiveSessionIds.includes(ls.liveSessionId)
              return !containsLs
            })

            return {
              onOpenChange: newIsOpen => {
                setOpenMenuRowData(newIsOpen ? row.rawData : undefined)
              },
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  hidden: false,
                  label: t('manage.view-course-details'),
                  onClick: () => getGlobalRouter().navigate({ to: getHrefForContentDetails(content) }),
                  icon: 'course',
                },
                {
                  type: 'label',
                  id: 'set-direct-due-date-user',
                  hidden: !canSetContentDueDates,
                  label: t('due-date.set-due-date'),
                  onClick: () => {
                    const isDirectRecurrentAssignment =
                      content.recurrences?.find(r => r.recurrenceSource.type === 'direct') !== undefined
                    const currentDirectDueDate = content.dueDatesEffective?.dueDates['direct']
                    setUserAction({
                      modal: 'set-direct-due-date-user',
                      contentId: content.id,
                      contentType: content.contentType,
                      isDirectRecurrentAssignment: isDirectRecurrentAssignment,
                      currentDirectDueDate: getDueDateFromComputedDueDate(currentDirectDueDate),
                    })
                  },
                  icon: 'time',
                },
                {
                  type: 'label',
                  id: 'set-direct-recurrence-user',
                  label: t('recurrence.edit-recurrence'),
                  hidden: getFlag('recurrent-assignments') === false,
                  onClick: () => {
                    const currentDirectRecurrence = content.recurrences?.find(
                      r => r.recurrenceSource.type === 'direct'
                    )?.recurrence
                    setUserAction({
                      modal: 'set-direct-recurrence-user',
                      contentId: content.id,
                      contentType: content.contentType,
                      currentDirectRecurrence: currentDirectRecurrence,
                    })
                  },
                  icon: 'restart',
                },
                {
                  type: 'label',
                  id: 'complete-content',
                  hidden: userType === 'scorm' || !canSetContentCompletion,
                  label: t('manage.complete-progress'),
                  onClick: () => {
                    setUserAction({
                      modal: 'complete-content',
                      contentType: content.contentType,
                      contentId: content.id,
                    })
                  },
                  disabled: content.progress === 1,
                  icon: 'checkmark--outline',
                },
                {
                  type: 'canvas',
                  id: 'set-is-required',
                  hidden: getFlag('required-assignments') === false,
                  disabled: content.assignmentData?.type !== 'individual',
                  render() {
                    return (
                      <RequiredAssignmentMenuItem
                        disabled={content.assignmentData?.type !== 'individual'}
                        assignmentPriority={
                          content.assignmentData?.type === 'individual' &&
                          content.assignmentData.isRequiredAssignment === true
                            ? 'required'
                            : 'normal'
                        }
                        setAssignmentPriority={async val => {
                          if (content.contentType === 'course' || content.contentType === 'path') {
                            await setUsersIsRequiredContent(
                              [userId],
                              [
                                {
                                  content: { id: content.id, type: content.contentType },
                                  isRequired: val === 'required',
                                },
                              ]
                            )
                            refetch()
                          }
                        }}
                      />
                    )
                  },
                },
                {
                  type: 'nested',
                  id: 'assign-live-sessions',
                  loading: isMenuLoading,
                  hidden: content.courseKind !== 'native:live' || !canEditAssignments,
                  disabled: isMenuLoading || filteredLiveSessionsForEnrollment.length === 0,
                  label: t('manage.live-session.assign-session'),
                  icon: 'event--schedule',

                  menuItems: filteredLiveSessionsForEnrollment.map((ls, i) => ({
                    type: 'canvas',
                    id: `live-session${i}`,
                    render() {
                      return (
                        <LiveSessionMenuItem
                          liveSession={ls}
                          targetUser={userId}
                          onDone={() => setUserAction({ modal: 'reload' })}
                          action='assign'
                        />
                      )
                    },
                  })),
                },
                {
                  type: 'label',
                  id: 'reset-course',
                  hidden:
                    userType === 'scorm' ||
                    content.courseKind === 'scorm' ||
                    content.courseKind === 'scorm:course-group' ||
                    !canResetProgress,
                  label: t('manage.reset-progress'),
                  onClick: () => {
                    setUserAction({
                      modal: 'reset-course',
                      contentType:
                        content.courseKind === 'native:course-group' ||
                        content.courseKind === 'scorm:course-group'
                          ? 'course-group'
                          : content.contentType,
                      contentId: content.id,
                    })
                  },
                  disabled: content.progress === 0,
                  color: 'destructive/background',
                  icon: 'reset',
                },
                {
                  type: 'label',
                  id: 'unassign',
                  hidden: content.assignmentData?.type !== 'individual' || !canEditAssignments,
                  label: t('dictionary.unassign'),
                  onClick: () => {
                    setUserAction({
                      modal: 'unassign',
                      messageType: 'content',
                      targets: [{ id: content.id, type: content.contentType }],
                    })
                  },
                  color: 'destructive/background',
                  icon: 'user--remove',
                },
              ],
            }
          },
        }),
      ],
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.CONTENT,
    },
  })
}

type ToolbarProps = {
  tableAPI: UseTableAPI<UserContentTableData, UserContentTableMeta>
  mapCsv: (ids: string[]) => Record<string, string>[]
  canEditAssignments: boolean
  setUserAction: UserContentTableProps['setUserAction']
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, mapCsv, canEditAssignments, setUserAction }) => {
  const { api } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)
  const [tableData] = useAtom(api.atoms.tableData)

  const selectedRows =
    selection.type === 'manual'
      ? Array.from(selection.rows)
          .map(ref =>
            tableData.flatMap(({ rows }) => rows.map(({ rawData }) => rawData)).find(({ id }) => id === ref)
          )
          .filter(isDefined)
      : []

  const hasSelectedOnlyIndividualAssigned =
    selection.type === 'manual' && selectedRows.every(r => r.assignmentData?.type === 'individual')

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.courses'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            {hasSelectedOnlyIndividualAssigned && canEditAssignments && (
              <ActionButton
                color='redBright'
                onClick={() =>
                  setUserAction({
                    modal: 'unassign',
                    messageType: 'content',
                    targets: selectedRows.map(r => ({
                      id: r.id,
                      type: r.contentType,
                    })),
                  })
                }
              >
                {t('dictionary.unassign')}
              </ActionButton>
            )}
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const SearchAndFilter: React.FC<{
  filter: UserContentFilter
  setFilter: (state: React.SetStateAction<UserContentFilter>) => void
  tableAPI: UseTableAPI<UserContentTableData, UserContentTableMeta>
}> = ({ filter, setFilter, tableAPI }) => {
  const { t } = useTranslation()
  const query = useAtomValue(tableAPI.api.atoms.query)

  return (
    <View>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('manage.search.content')}
      />
      <UserContentProgressFilter
        value={filter.progress}
        onChange={value => setFilter(f => ({ ...f, progress: value }))}
      />
      <ContentTypeFilter
        value={filter.contentClassification}
        onChange={value => setFilter(f => ({ ...f, contentClassification: value }))}
      />
    </View>
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.courses')} />
    </View>
  )
}

export type UserContentTableProps = {
  courses: UserDetailResponse['courses']
  paths: UserDetailResponse['paths']
  liveSessions: UserDetailResponse['liveSessions']
  userId: UserId
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  userType: UserDetailResponse['type']
  refetch: () => void
}

export const UserContentTable: React.FC<UserContentTableProps> = ({
  courses,
  liveSessions,
  paths,
  setUserAction,
  userId,
  userType,
  refetch,
}) => {
  const { filter, setFilter, filteredItems } = useUserAssignedContent({
    courses,
    liveSessions,
    paths,
  })
  const orgPermissions = useOrganizationPermissions()
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')

  const tableAPI = useUserContentTable({ refetch, data: filteredItems, setUserAction, userId, userType })

  const { t } = useTranslation()
  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Spacer size='xsmall' />
      <View justifyContent='space-between'>
        <SearchAndFilter filter={filter} setFilter={setFilter} tableAPI={tableAPI} />
        {canEditAssignments && (
          <View>
            <Button onClick={() => setUserAction({ modal: 'assign-content' })}>
              {t('admin.organization.paths.enroll')}
            </Button>
          </View>
        )}
      </View>
      <Spacer size='xsmall' />
      <Toolbar
        setUserAction={setUserAction}
        tableAPI={tableAPI}
        mapCsv={(ids: string[]) =>
          filteredItems.filter(({ id }) => ids.includes(id)).map(userContentRowToCsv)
        }
        canEditAssignments={canEditAssignments}
      />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(filteredItems.map(userContentRowToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
