import _ from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { useTranslationForBaseRoute } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Divider = styled.div`
  display: flex;
  width: auto;
  color: ${token('border/strong')};
  background-color: ${token('surface/default')};
  align-self: stretch;
  align-items: center;
`

const FilterContainer = styled(View).attrs({ alignItems: 'flex-start' })``

const SearchAndFilterContainer = styled(View).attrs({ alignItems: 'flex-start', grow: true })`
  grid-area: 1 / 1 / 1 / 1;
`

const ActionContainer = styled(View)`
  grid-area: 1 / 2 / 1 / 2;
  align-self: flex-start;
`

const ManageHeaderContainer = styled(View).attrs({ gap: 'none' })`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: space-between;
  grid-row-gap: 0;
`

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

export type ManageHeaderSearchConfig = {
  placeholder: TranslationKey
  initialOpen?: boolean
}

type ManageHeaderProps = {
  api: TableAPI
  title?: TranslationKey
  search?: ManageHeaderSearchConfig
  filtering?: React.ReactNode
  actionElement?: React.ReactNode
}

const ManageHeaderSearchBar: React.FC<{
  api: TableAPI
  placeholder: TranslationKey
  initialOpen?: boolean
}> = ({ api, placeholder, initialOpen }) => {
  const onChange = React.useMemo(
    () => _.debounce((query: string) => api.action.setQuery({ query }), 250),
    [api]
  )
  const { t } = useTranslationForBaseRoute()
  const [initialValue] = useState(api.query.query().query)

  return (
    <View>
      <AnimatedSearch
        onChange={onChange}
        placeholder={t(placeholder)}
        initialOpen={Boolean(initialOpen) || Boolean(initialValue)}
        defaultValue={initialValue}
      />
    </View>
  )
}

export const ManageHeader: React.FC<ManageHeaderProps> = ({
  api,
  title,
  search,
  filtering,
  actionElement,
}) => {
  const showSearchbar = search !== undefined
  const showFiltering = filtering !== undefined
  const { t } = useTranslationForBaseRoute()

  return (
    <Wrapper>
      {title !== undefined && <ManageHeadline>{t(title)}</ManageHeadline>}
      <ManageHeaderContainer>
        <SearchAndFilterContainer>
          {showSearchbar && (
            <ManageHeaderSearchBar
              api={api}
              placeholder={search.placeholder}
              initialOpen={search.initialOpen}
            />
          )}
          {showSearchbar && showFiltering && <Divider>{'|'}</Divider>}
          {showFiltering && <FilterContainer>{filtering}</FilterContainer>}
        </SearchAndFilterContainer>
        <ActionContainer>{actionElement}</ActionContainer>
      </ManageHeaderContainer>
    </Wrapper>
  )
}
