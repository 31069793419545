import { useId, useState } from 'react'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  PathCollaboratorIdentitiesSelector,
  PathCollaboratorIdentitiesSelectorProps,
} from 'sierra-client/views/manage/paths/components/path-collaborator-identities-selector'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import { PathCollaborator } from 'sierra-domain/api/admin'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { assertNever, getUserName } from 'sierra-domain/utils'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, LoadingSpinner, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AutocompleteContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const CollaboratorList = styled.ul`
  list-style: none;
  position: relative;

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: PathCollaborator): string => getUserName(option) ?? ''

export const CollaboratorsTab: SettingsTabComponent = ({
  pathId,
  onSave,
  canEditCollaborators,
  collaborators,
  potentialCollaborators,
  updateCollaborators,
}) => {
  const inputId = useId()

  const { t } = useTranslation()
  const notifications = useNotif()

  const [selectedCollaboratorIdentities, setSelectedCollaboratorIdentitiesState] = useState<
    IdentityWithMetadata[]
  >([])

  const setSelectedCollaboratorIdentities: PathCollaboratorIdentitiesSelectorProps['setSelectedIdentities'] =
    update => {
      const updatedIdentities = update(selectedCollaboratorIdentities)
      setSelectedCollaboratorIdentitiesState(updatedIdentities)
    }

  const fetchCollaboratorIdentities = useAdminIdentitiesFetcher({ withTypes: ['user'] })

  const handleInviteSelections = async (): Promise<void> => {
    await updateCollaborators({
      pathId,
      add: selectedCollaboratorIdentities.map(i => {
        switch (i.identity.type) {
          case 'user':
            return i.identity.id
          case 'userGroup':
            throw new Error('Unsupported identity type')
          default:
            assertNever(i.identity)
        }
      }),
      remove: [],
    })
    setSelectedCollaboratorIdentities(() => [])
    onSave()

    notifications.push({
      type: 'custom',
      level: 'success',
      body: t('notifications.collaborator-invited-singular'),
    })
  }

  const handleRemoveCollaborator = async (collaborator: PathCollaborator): Promise<void> => {
    await updateCollaborators({ pathId, add: [], remove: [collaborator.userId] })
    onSave()
  }

  return (
    <FormElement label={t('author.invite-collaborators')} htmlFor={inputId}>
      {potentialCollaborators.length === 0 /* TODO: add loading state to potential collaborators hook */ ? (
        <LoadingSpinner padding='large' />
      ) : (
        <AutocompleteContainer>
          <PathCollaboratorIdentitiesSelector
            selectedIdentities={selectedCollaboratorIdentities}
            setSelectedIdentities={setSelectedCollaboratorIdentities}
            fetchIdentities={fetchCollaboratorIdentities}
            type='user'
          />
          <Button
            onClick={handleInviteSelections}
            disabled={!canEditCollaborators || selectedCollaboratorIdentities.length === 0}
          >
            {t('dictionary.invite')}
          </Button>
        </AutocompleteContainer>
      )}
      <CollaboratorList>
        {collaborators.length === 0 ? (
          <li>
            <Text color='grey35'>{t('author.no-collaborators')}</Text>
          </li>
        ) : (
          collaborators.map((collaborator: PathCollaborator) => (
            <li key={collaborator.userId} className='grid'>
              <RoundAvatar
                firstName={collaborator.firstName}
                lastName={collaborator.lastName}
                src={getAvatarImage(collaborator.userId, collaborator.avatar)}
                color={collaborator.avatarColor}
              />
              <Text bold>{getOptionLabel(collaborator)}</Text>
              {canEditCollaborators && (
                <IconButton
                  onClick={() => handleRemoveCollaborator(collaborator)}
                  iconId='close'
                  variant='transparent'
                />
              )}
            </li>
          ))
        )}
      </CollaboratorList>
    </FormElement>
  )
}
