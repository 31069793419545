import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseTab, ProgramTab } from 'sierra-client/views/manage/certificates/issued-by-selector/tabs'
import {
  IssuedByCourse,
  IssuedByProgram,
} from 'sierra-client/views/manage/certificates/issued-by-selector/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Panel, Tabs } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Column = styled(View).attrs({
  direction: 'column',
  padding: 'small',
  gap: 'xsmall',
  grow: true,
})`
  height: 100%;
`

const TabsContainer = styled(View).attrs({
  grow: true,
  gap: 'none',
  alignItems: 'flex-start',
  overflow: 'auto',
})`
  width: 100%;
`

type AddContentTab = 'courses' | 'programs'

export const AddContentPanel: FC<{
  open: boolean
  onClose: () => void
  onSubmit: (args: {
    courseAdds: IssuedByCourse['courseId'][]
    courseRemovals: IssuedByCourse['courseId'][]
    programAdds: IssuedByProgram['id'][]
    programRemovals: IssuedByProgram['id'][]
  }) => Promise<void>
  connectedCourses: IssuedByCourse[]
  connectedPrograms: IssuedByProgram[]
}> = ({ open, onClose, onSubmit, connectedCourses, connectedPrograms }) => {
  const { t } = useTranslation()
  const [courseIdsToBeAdded, setCourseIdsToBeAdded] = useState<CourseId[]>([])
  const [courseIdsToBeRemoved, setCourseIdsToBeRemoved] = useState<CourseId[]>([])
  const [programIdsToBeAdded, setProgramIdsToBeAdded] = useState<IssuedByProgram['id'][]>([])
  const [programIdsToBeRemoved, setProgramIdsToBeRemoved] = useState<IssuedByProgram['id'][]>([])
  const [query, setQuery] = useState('')
  const [currentTab, setCurrentTab] = useState<AddContentTab>('courses')

  const selectedCourseIds = useMemo(() => {
    return new Set([
      ...connectedCourses
        .filter(c => !courseIdsToBeRemoved.some(toRemove => toRemove === c.courseId))
        .map(c => c.id),
      ...courseIdsToBeAdded,
    ])
  }, [connectedCourses, courseIdsToBeAdded, courseIdsToBeRemoved])

  const selectedProgramIds = useMemo(() => {
    return new Set([
      ...connectedPrograms
        .filter(p => !programIdsToBeRemoved.some(toRemove => toRemove === p.id))
        .map(p => p.id),
      ...programIdsToBeAdded,
    ])
  }, [connectedPrograms, programIdsToBeAdded, programIdsToBeRemoved])

  const handleSelectCourse = (courseId: CourseId): void => {
    if (!connectedCourses.some(c => c.courseId === courseId)) {
      setCourseIdsToBeAdded(current => [...current, courseId])
    }
    setCourseIdsToBeRemoved(current => current.filter(toBeRemoved => toBeRemoved !== courseId))
  }

  const handleDeselectCourse = (courseId: CourseId): void => {
    setCourseIdsToBeAdded(current => current.filter(toBeAdded => toBeAdded !== courseId))
    if (connectedCourses.some(c => c.courseId === courseId)) {
      setCourseIdsToBeRemoved(current => [...current, courseId])
    }
  }

  const handleSelectProgram = (programId: string): void => {
    if (!connectedPrograms.some(p => p.id === programId)) {
      setProgramIdsToBeAdded(current => [...current, programId])
    }
    setProgramIdsToBeRemoved(current => current.filter(toBeRemoved => toBeRemoved !== programId))
  }

  const handleDeselectProgram = (programId: string): void => {
    setProgramIdsToBeAdded(current => current.filter(toBeAdded => toBeAdded !== programId))
    if (connectedPrograms.some(p => p.id === programId)) {
      setProgramIdsToBeRemoved(current => [...current, programId])
    }
  }

  const handleSubmit = async (): Promise<void> => {
    await onSubmit({
      courseAdds: courseIdsToBeAdded,
      courseRemovals: courseIdsToBeRemoved,
      programAdds: programIdsToBeAdded,
      programRemovals: programIdsToBeRemoved,
    })

    setCourseIdsToBeAdded([])
    setCourseIdsToBeRemoved([])
    setProgramIdsToBeRemoved([])
    setProgramIdsToBeAdded([])
    setQuery('')
    onClose()
  }

  return (
    <Panel open={open} onClose={onClose} disableScrollbarGutter size={{ width: 656 }}>
      <Column>
        <Text bold>{t('manage.certificates.manage-issued-by.add-to-content-header')}</Text>
        <InputPrimitive
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={t('manage.certificates.manage-issued-by.search-placeholder')}
        />
        <TabsContainer>
          <Tabs
            value={currentTab}
            onChange={newTab => setCurrentTab(newTab)}
            items={[
              {
                id: 'courses',
                label: t('dictionary.course-plural'),
                content: (
                  <CourseTab
                    query={query}
                    selectedIds={selectedCourseIds}
                    onSelect={handleSelectCourse}
                    onDeselect={handleDeselectCourse}
                  />
                ),
              },
              {
                id: 'programs',
                label: t('dictionary.program-plural'),
                content: (
                  <ProgramTab
                    query={query}
                    selectedIds={selectedProgramIds}
                    onSelect={handleSelectProgram}
                    onDeselect={handleDeselectProgram}
                  />
                ),
              },
            ]}
          />
        </TabsContainer>
        <View>
          <Button variant='secondary' onClick={onClose}>
            {t('dictionary.cancel')}
          </Button>
          <Button onClick={handleSubmit}>{t('dictionary.save')}</Button>
        </View>
      </Column>
    </Panel>
  )
}
