import React from 'react'
import { Link } from 'sierra-client/components/common/link'
import { REQUIRED_ASSIGNMENTS_HELP_PAGE } from 'sierra-client/config/links'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const DividerVertical = styled.div`
  width: 1px;
  height: 1rem;
  background-color: white;
  opacity: 0.3;
`

const UnstyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export const RequiredAssignmentIcon: React.FC<{ isUsedForRequiredByDefault?: boolean }> = ({
  isUsedForRequiredByDefault,
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      side='top'
      title={
        <>
          <Text color='white'>
            {isUsedForRequiredByDefault === true
              ? t('author.course-settings.required-by-default')
              : t('author.course-settings.required-assignment')}
          </Text>
          <DividerVertical />
          <UnstyledButton
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <Link target='_blank' href={REQUIRED_ASSIGNMENTS_HELP_PAGE} size='micro' bold>
              <Text color='white' bold>
                {t('content.learn-more')}
              </Text>
            </Link>
          </UnstyledButton>
        </>
      }
    >
      <Icon color='foreground/muted' iconId='arrow-up--filled' />
    </Tooltip>
  )
}
