import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit'
import { invalidateCourseCollaborators } from 'sierra-client/api/queries/invalidate-course-collaborators'
import { Logging } from 'sierra-client/core/logging'
import { collaboratorAdded } from 'sierra-client/core/logging/authoring/logger'
import { teamspaceContentCreatedLogger } from 'sierra-client/features/teamspace/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { postWithUserErrorCode, postWithUserErrorException } from 'sierra-client/state/api'
import { prefixAction } from 'sierra-client/state/author-course-settings'
import * as selectors from 'sierra-client/state/author-course-settings/selectors'
import { Collaborator, CourseSettingsValidation } from 'sierra-client/state/author-course-settings/types'
import { validateSettings } from 'sierra-client/state/author-course-settings/validations'
import { addNotification, toNotificationState } from 'sierra-client/state/notifications/actions'
import { RootState } from 'sierra-client/state/types'
import { TabID } from 'sierra-client/views/course-settings/types'
import { getFlexibleContentSubpath } from 'sierra-client/views/workspace/utils/urls'
import { FlexibleContentTemplate } from 'sierra-domain/api/author-v2'
import { CourseSettings, CourseSettingsResponse, SmartCard } from 'sierra-domain/api/common'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { isLeft, isRight } from 'sierra-domain/either'
import {
  XRealtimeAdminCoursesSetCourseRoles,
  XRealtimeAuthorConvertFlexibleContent,
  XRealtimeAuthorCreateFlexibleContent,
  XRealtimeAuthorDuplicateFlexibleContent,
  XRealtimeAuthorGetCourseSettings,
  XRealtimeAuthorSetCourseSettings,
  XRealtimeAuthorSetCourseTitle,
} from 'sierra-domain/routes'

type ThunkAPI = { state: RootState }

export const setView = createAction<TabID | null>(prefixAction(`set-view`))

export const fetchCourseSettings = createAsyncThunk<CourseSettingsResponse, { courseId: CourseId }, ThunkAPI>(
  prefixAction(`fetch-course-settings`),
  async ({ courseId }, { dispatch }) => {
    const response = await postWithUserErrorException(
      XRealtimeAuthorGetCourseSettings,
      { courseId },
      dispatch
    )

    return response
  }
)

export const fetch = createAsyncThunk<void, { courseId: CourseId }, { state: RootState }>(
  prefixAction(`fetch`),
  async ({ courseId }, { dispatch }) => {
    const action = await dispatch(fetchCourseSettings({ courseId }))

    if (isFulfilled(action)) {
      await invalidateCourseCollaborators({ courseId })
    }
  }
)

export const resetDraftSettings = createAction(prefixAction(`reset-draft-settings`))

export const updateCourseSettings = createAction<Partial<CourseSettings>>(
  prefixAction(`update-course-settings`)
)

export const updateCourseSmartCards = createAction<SmartCard[] | undefined>(
  prefixAction(`update-course-smart-cards`)
)

export const setSettingsValidationError = createAction<CourseSettingsValidation[]>(
  prefixAction(`set-settings-validation-error`)
)

export const setCourseTitle = createAsyncThunk<void, { title: string; courseId: NanoId12 }, ThunkAPI>(
  prefixAction(`set-course-title`),
  async ({ title, courseId }, { dispatch }) => {
    const result = await postWithUserErrorCode(XRealtimeAuthorSetCourseTitle, { title, courseId }, dispatch)

    if (isLeft(result)) {
      void dispatch(
        addNotification(
          toNotificationState({
            type: 'set-course-title-error',
          })
        )
      )
      throw new Error('Failed to update course title')
    }
  }
)

export const duplicateFlexibleContent = createAsyncThunk<
  void,
  {
    originalFlexibleContentId: NanoId12
    targetLanguage?: string
  },
  ThunkAPI
>(
  prefixAction(`duplicate-flexible-content`),
  async ({ originalFlexibleContentId, targetLanguage }, { dispatch }) => {
    const result = await postWithUserErrorCode(
      XRealtimeAuthorDuplicateFlexibleContent,
      { originalFlexibleContentId, targetLanguage },
      dispatch
    )
    if (isRight(result)) {
      const { newFlexibleContentId, type } = result.right

      const subPath = getFlexibleContentSubpath(type)

      void dispatch(
        addNotification(
          toNotificationState({
            type: 'course-duplicated',
            url: `/create/${subPath}/${newFlexibleContentId}`,
          })
        )
      )

      if (targetLanguage !== undefined) {
        void dispatch(
          Logging.authoring.courseTranslated({
            courseId: originalFlexibleContentId,
            language: targetLanguage,
            courseKind: type,
          })
        )
      } else {
        void dispatch(
          Logging.authoring.courseDuplicated({ courseId: originalFlexibleContentId, courseKind: type })
        )
      }
    }

    if (isLeft(result)) {
      void dispatch(
        addNotification(
          toNotificationState({
            type: 'duplication-error',
          })
        )
      )
    }
  }
)

type FlexibleContentKind = 'native:live' | 'native:self-paced'

export const convertFlexibleContent = createAsyncThunk<
  void,
  {
    originalFlexibleContentId: NanoId12
    convertToType: FlexibleContentKind
  },
  ThunkAPI
>(
  prefixAction(`convert-flexible-content`),
  async ({ originalFlexibleContentId, convertToType }, { dispatch }) => {
    const result = await postWithUserErrorCode(
      XRealtimeAuthorConvertFlexibleContent,
      { originalFlexibleContentId, convertToType },
      dispatch
    )
    if (isRight(result)) {
      const { newFlexibleContentId, newType } = result.right

      const subPath = getFlexibleContentSubpath(newType)

      void dispatch(
        addNotification(
          toNotificationState({
            type: 'course-converted',
            url: `/create/${subPath}/${newFlexibleContentId}`,
          })
        )
      )
    }

    if (isLeft(result)) {
      void dispatch(
        addNotification(
          toNotificationState({
            type: 'duplication-error',
          })
        )
      )
    }
  }
)

type FlexibleContentTemplateWithTeamspaceOption = {
  flexibleContentTemplate: FlexibleContentTemplate
  teamspaceId?: NanoId12
  folderId?: NanoId12
}

export const createFlexibleContentFromTemplate = createAsyncThunk<
  void,
  FlexibleContentTemplateWithTeamspaceOption,
  ThunkAPI
>(
  prefixAction(`create-flexible-content-from-template`),
  async (flexibleContentTemplateWithTeamspaceOption, { dispatch }) => {
    if (flexibleContentTemplateWithTeamspaceOption.flexibleContentTemplate.type === 'sana')
      void dispatch(
        Logging.authoring.createFromSanaTemplate({
          sanaTemplate: flexibleContentTemplateWithTeamspaceOption.flexibleContentTemplate.templateName,
        })
      )

    const result = await postWithUserErrorCode(
      XRealtimeAuthorCreateFlexibleContent,
      {
        flexibleContentTemplate: flexibleContentTemplateWithTeamspaceOption.flexibleContentTemplate,
        teamspaceId: flexibleContentTemplateWithTeamspaceOption.teamspaceId,
        folderId: flexibleContentTemplateWithTeamspaceOption.folderId,
      },
      dispatch
    )

    if (isRight(result)) {
      const { courseId, type } = result.right

      if (flexibleContentTemplateWithTeamspaceOption.teamspaceId !== undefined) {
        const templateName =
          flexibleContentTemplateWithTeamspaceOption.flexibleContentTemplate.type === 'sana'
            ? flexibleContentTemplateWithTeamspaceOption.flexibleContentTemplate.templateName
            : 'partner'

        void dispatch(
          teamspaceContentCreatedLogger({
            teamspaceId: flexibleContentTemplateWithTeamspaceOption.teamspaceId,
            templateName,
          })
        )
      }

      const subPath = getFlexibleContentSubpath(type)
      void getGlobalRouter().navigate({ to: `/create/${subPath}/${courseId}` })
    } else if (isLeft(result)) {
      void dispatch(
        addNotification(
          toNotificationState({
            type: 'duplication-error',
          })
        )
      )
    }
  }
)

export const updateCollaborators = createAsyncThunk<
  void,
  { previousCollaborators: Collaborator[]; changedCollaborators: Collaborator[] },
  { state: RootState }
>(
  prefixAction(`update-collaborators`),
  async ({ previousCollaborators, changedCollaborators }, { dispatch, getState }) => {
    const state = getState()
    const courseId = selectors.currentCourseId(state)

    if (courseId === null) return

    const assignments = changedCollaborators.map(({ uuid, role }) => {
      const previousCollaborator = previousCollaborators.find(c => c.uuid === uuid)
      const assign = previousCollaborator === undefined || previousCollaborator.role !== role
      return { userId: uuid, assign, role: role ?? 'editor' }
    })

    const result = await postWithUserErrorCode(
      XRealtimeAdminCoursesSetCourseRoles,
      { courseId, assignments },
      dispatch
    )

    void invalidateCourseCollaborators({ courseId })

    if (isLeft(result)) throw new Error(`Unable to update course collaborators.`)

    // Add segment logging that user has invited people to the content
    void dispatch(
      collaboratorAdded({
        props: assignments,
      })
    )
  }
)

export const saveSettings = createAsyncThunk<{ saved: boolean }, { notify: boolean }, ThunkAPI>(
  prefixAction('save-settings'),
  async ({ notify = true }, { getState, dispatch }) => {
    const state = getState()

    const courseId = selectors.currentCourseId(state)
    const draftSettings = selectors.selectDraftSettings(state)
    const courseKind = selectors.selectCourseKind(state)

    if (courseId === null || draftSettings === null || courseKind === null) {
      throw new Error('Can not save course settings with incomplete data')
    }

    const errors = validateSettings(draftSettings, courseKind)
    if (errors.length > 0) {
      void dispatch(setSettingsValidationError(errors))
      return { saved: false }
    }

    // update the course
    await postWithUserErrorException(
      XRealtimeAuthorSetCourseSettings,
      {
        courseId,
        settings: draftSettings,
      },
      dispatch,
      { notifications: true }
    )

    await dispatch(fetchCourseSettings({ courseId }))
    void dispatch(setView(null))

    if (notify) {
      void dispatch(addNotification(toNotificationState({ type: 'settings-saved' })))
    }

    return {
      saved: true,
    }
  }
)
