import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useUnassignContent } from 'sierra-client/components/sharing/hooks'
import { ExistingAssignedUsersContainer } from 'sierra-client/components/sharing/tabs/components/containers'
import { LiveSessionPicker } from 'sierra-client/components/sharing/tabs/components/live-session-picker'
import { SharingModalMenuItemWithAssignLiveControls } from 'sierra-client/components/sharing/tabs/components/sharing-modal-menu-item'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { fetchLiveSessionsForFlexibleContent } from 'sierra-client/state/flexible-content/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { manageSessionsPanelStateAtom } from 'sierra-client/views/flexible-content/editor/topbar/manage-sessions-panel-state'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import {
  CalendarEventUserSelector,
  EventParticipantSelectorProps,
} from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { assertNever, guardWith } from 'sierra-domain/utils'
import { Button, View } from 'sierra-ui/primitives'

export const AssignLiveParticipants: React.FC<{
  content: SharingModalContent
  canEditAssignments: boolean
}> = ({ content, canEditAssignments }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()
  const unassignContent = useUnassignContent()
  const setManageSessionsPanelState = useSetAtom(manageSessionsPanelStateAtom)

  const { assignWithDueDate } = useDueDate()

  const [selectedParticipantIdentities, setSelectedParticipantIdentitiesState] = useState<
    IdentityWithMetadata[]
  >([])

  const setSelectedParticipantIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(selectedParticipantIdentities)
    setSelectedParticipantIdentitiesState(updatedIdentities)
  }

  const fetchParticipantIdentities = useAdminIdentitiesFetcher()

  if (!guardWith(LiveContentId, content.id))
    throw Error('Could not find live content id for assigning to live session')

  const liveContentId = LiveContentId.parse(content.id)

  const futureSessionsQuery = useCachedQuery(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    {
      flexibleContentId: liveContentId,
    },
    {
      select: data =>
        data.liveSessions.filter(
          session =>
            session.data.type === 'scheduled' && DateTime.fromISO(session.data.endTime) > DateTime.now()
        ),
    }
  )
  const futureSessions = useMemo(() => futureSessionsQuery.data ?? [], [futureSessionsQuery.data])

  const firstSession = useMemo(() => _.first(futureSessions), [futureSessions])
  const [_selectedLiveSession, setSelectedLiveSession] = useState<LiveSessionWithParticipants | undefined>()
  const selectedLiveSession = _selectedLiveSession ?? firstSession

  // After creating a new session, listen to the new session being added, and select it.
  const [newlyCreatedSessionId, setNewlyCreatedSessionId] = useState<string | undefined>()
  useEffect(() => {
    const newlyCreatedSession = futureSessions.find(s => s.liveSessionId === newlyCreatedSessionId)
    if (newlyCreatedSession !== undefined) {
      setSelectedLiveSession(newlyCreatedSession)
      setNewlyCreatedSessionId(undefined)
    }
  }, [newlyCreatedSessionId, futureSessions])

  const onSessionPick = (
    pick:
      | { action: 'schedule-new-session' }
      | { action: 'schedule-existing-session'; session: LiveSessionWithParticipants }
  ): void => {
    switch (pick.action) {
      case 'schedule-new-session':
        setManageSessionsPanelState({
          type: 'create-session',
          initialData: {
            participants: selectedParticipantIdentities,
          },
          onSessionCreated: sessionId => {
            setNewlyCreatedSessionId(sessionId)
            setSelectedParticipantIdentities(() => [])
            setManageSessionsPanelState({ type: 'closed' })
          },
        })
        break
      case 'schedule-existing-session':
        setSelectedLiveSession(pick.session)
        break
      default:
        assertNever(pick)
    }
  }

  const noSelectedUsersOrSession =
    selectedParticipantIdentities.length === 0 || selectedLiveSession === undefined

  const onAssignClick = async (): Promise<void> => {
    if (noSelectedUsersOrSession) return
    await assignWithDueDate(
      selectedParticipantIdentities.map(user => {
        switch (user.identity.type) {
          case 'user':
            return {
              assignee: {
                type: 'user',
                id: user.identity.id,
                dueDate: undefined,
              },
              content: {
                id: selectedLiveSession.liveSessionId,
                type: content.type,
              },
              // TODO(required-content) live session
              isRequired: false,
            }
          case 'userGroup':
            throw Error('User groups are not supported for live sessions in the sharing modal')
          default:
            assertNever(user.identity)
        }
      })
    ).then(() => {
      void dispatch(fetchLiveSessionsForFlexibleContent({ liveContentId }))
      void futureSessionsQuery.refetch()
    })

    setSelectedParticipantIdentities(() => [])
    notifications.push({
      type: 'custom',
      level: 'info',
      icon: 'send--filled',
      body: 'Invite sent',
    })
  }

  const participantsToLiveSessions = _.chain(futureSessions)
    .flatMap(session => session.participantIds.map(pId => ({ participantId: pId, session: session })))
    .groupBy('participantId')
    .value()

  const participantUsers = useUsers(
    _.chain(futureSessions)
      .flatMap(session => session.participantIds)
      .uniq()
      .value()
  ).filter(isUserLoaded)

  const allParticipantsWithLiveSessions = participantUsers.map(participant => ({
    ...participant,
    type: 'user' as const,
    liveSessions: participantsToLiveSessions[participant.uuid]?.map(s => s.session) ?? [],
  }))

  return (
    <div>
      <View>
        <CalendarEventUserSelector
          selectedIdentities={selectedParticipantIdentities}
          setSelectedIdentities={setSelectedParticipantIdentities}
          fetchIdentities={fetchParticipantIdentities}
          grow
        />

        {canEditAssignments && (
          <LiveSessionPicker
            selectedSession={selectedLiveSession}
            sessions={futureSessions}
            onSessionPick={onSessionPick}
            liveContentId={liveContentId}
          />
        )}

        <Button disabled={noSelectedUsersOrSession} onClick={onAssignClick}>
          {t('dictionary.assign')}
        </Button>
      </View>
      <ExistingAssignedUsersContainer>
        {allParticipantsWithLiveSessions.map(participant => (
          <SharingModalMenuItemWithAssignLiveControls
            key={participant.uuid}
            user={participant}
            onUnassign={
              canEditAssignments
                ? async () => {
                    await Promise.all(
                      participant.liveSessions.map(async session => {
                        await unassignContent(participant.uuid, {
                          ...content,
                          id: CreateContentId.parse(session.liveSessionId),
                        })
                      })
                    ).then((): void => {
                      void futureSessionsQuery.refetch()
                    })
                  }
                : undefined
            }
          />
        ))}
      </ExistingAssignedUsersContainer>
    </div>
  )
}
