import { ItemType } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { closestRelativeDate } from 'sierra-client/components/recurrent-assignments/utils'
import { DueDate, DueDateAbsolute, DueDateRelativeInterval, Interval } from 'sierra-domain/api/manage'
import { assertNever } from 'sierra-domain/utils'

type AssignmentRuleDirect = {
  type: 'direct'
  dueDate?: DueDateAbsolute
}

type AssignmentRuleRelative = {
  type: 'recurrent'
  dueDate?: DueDateRelativeInterval
  recurrence?: Interval
}

type AssignmentRule = AssignmentRuleDirect | AssignmentRuleRelative | undefined

type AssignmentRuleState = {
  id: string
  type: ItemType
  rule: AssignmentRule
}

type Action =
  | { type: 'change-due-date'; id: string; itemType: ItemType; dueDate: DueDate }
  | { type: 'clear-due-date'; id: string }
  | { type: 'change-recurrence'; id: string; itemType: ItemType; interval: Interval }
  | { type: 'clear-recurrence'; id: string }
  | { type: 'clear'; id: string }

const createOrUpdateRule = (
  id: string,
  type: ItemType,
  state: AssignmentRuleState[],
  rule: AssignmentRule,
  update: (curr: NonNullable<AssignmentRule>) => AssignmentRule
): AssignmentRuleState[] => {
  const existing = state.find(assignment => assignment.id === id)
  if (existing === undefined) {
    return [
      ...state,
      {
        id,
        type,
        rule,
      },
    ]
  } else {
    return state.map(assignment =>
      assignment.id === id
        ? {
            ...assignment,
            rule: assignment.rule === undefined ? rule : update(assignment.rule),
          }
        : assignment
    )
  }
}

/**
 * This reducer defines the transitions between the different states of assignment rules. This
 * makes sure, for example, that a recurrent assignment does not have an absolute due date
 */
export function assignmentRulesReducer(
  assignments: AssignmentRuleState[],
  action: Action
): AssignmentRuleState[] {
  const current = assignments.find(assignment => assignment.id === action.id)
  const currentRule = current?.rule
  switch (action.type) {
    case 'change-due-date':
      switch (action.dueDate.type) {
        case 'absolute': {
          const newDueDate = action.dueDate
          const newRule = {
            type: 'direct' as const,
            dueDate: newDueDate,
          }
          // it's fine to set the newRule directly since there are no other fields to update
          return createOrUpdateRule(action.id, action.itemType, assignments, newRule, _ => newRule)
        }
        case 'relative': {
          const newDueDate = action.dueDate
          const newRule = {
            type: 'recurrent' as const,
            dueDate: newDueDate,
          }
          return createOrUpdateRule(action.id, action.itemType, assignments, newRule, curr => ({
            ...curr,
            type: 'recurrent',
            dueDate: newDueDate,
          }))
        }
        default:
          return assertNever(action.dueDate)
      }

    case 'change-recurrence': {
      const newRecurrence = action.interval
      const newRule = {
        type: 'recurrent' as const,
        recurrence: newRecurrence,
      }
      return createOrUpdateRule(action.id, action.itemType, assignments, newRule, curr => ({
        type: 'recurrent',
        // we need to clear the due date if it is absolute
        dueDate:
          curr.type === 'recurrent'
            ? curr.dueDate
            : curr.dueDate !== undefined
              ? closestRelativeDate(new Date(curr.dueDate.date))
              : undefined,
        recurrence: newRecurrence,
      }))
    }
    case 'clear-due-date':
      return assignments.map(assignment =>
        assignment.id === action.id
          ? {
              ...assignment,
              rule: currentRule !== undefined ? { ...currentRule, dueDate: undefined } : undefined,
            }
          : assignment
      )
    case 'clear-recurrence':
      return assignments.map(assignment =>
        assignment.id === action.id
          ? {
              ...assignment,
              // Due date is cleared when recurrence is cleared since it does not make
              // sense to have a relative due date without a recurrence
              rule: undefined,
            }
          : assignment
      )
    case 'clear':
      return assignments.filter(assignment => assignment.id !== action.id)
    default:
      return assertNever(action)
  }
}
