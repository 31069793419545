import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence, motion, Reorder, useDragControls } from 'framer-motion'
import { useState } from 'react'
import { useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import {
  CATtoEditable,
  ContentAttribute,
  ContentAttributeInternalType,
  EditableCategoriesAttribute,
  EditableContentAttribute,
  EditableSelectAttribute,
  EditableSelectItem,
} from 'sierra-client/views/manage/content-attributes/types'
import {
  FormInput,
  FormSingleSelectDropdown,
  FormSwitch,
  FormTextArea,
} from 'sierra-client/views/manage/shared/form'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { iife, isDefined, isNonNullable } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const schema = EditableContentAttribute
type Schema = z.infer<typeof schema>

const newItem = (label: string): EditableSelectItem => ({
  label: label,
  _tempId: nanoid12(),
})

const DescriptionText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${token('foreground/primary')};
  }
`

const HorizontalDivider = styled.hr`
  background-color: ${token('border/default')};
  height: 1px;
  width: 100%;
  margin: 0px;
`

const VisibilityIcon = styled(Icon).attrs({ iconId: 'view' })`
  padding: 12px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${token('border/default')};
  color: ${token('foreground/primary')};
`

const VisibilityView: React.FC<{
  handler: UseFormReturn<Schema, unknown>
}> = ({ handler }) => {
  const { t } = useTranslation()
  return (
    <View justifyContent='space-between'>
      <View gap='12'>
        <VisibilityIcon />
        <View direction='column' gap='none'>
          <Text size='small' bold>
            {t('manage.content-attributes.form.visible-to-learners.text')}
          </Text>
          <Text size='small' bold color='foreground/secondary'>
            {t('manage.content-attributes.form.visible-to-learners.subtext')}
          </Text>
        </View>
      </View>
      <FormSwitch control={handler.control} name='learnerVisible' />
    </View>
  )
}

const AddButton = styled(Button)`
  background-color: ${token('form/background/2')};
  color: ${token('foreground/secondary')};
`

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  cursor: grab;
  position: absolute;
  left: -20px;
  width: 46px;
  height: 46px;
`
const DragItem: FCC<{
  value: EditableSelectItem
  hasDragHandle: boolean
}> = ({ value, hasDragHandle, children }) => {
  const controls = useDragControls()
  return (
    <Reorder.Item
      as='div'
      dragListener={false}
      dragControls={controls}
      value={value}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <View
        padding='4 none'
        grow
        position='relative'
        gap='none'
        animated
        whileHover='show'
        initial='hide'
        animate='hide'
      >
        {hasDragHandle && (
          <View
            animated
            variants={{
              show: {
                opacity: 1,
              },
              hide: {
                opacity: 0,
              },
            }}
            transition={{ duration: 0.2 }}
          >
            <DragHandle onPointerDown={e => controls.start(e)}>
              <Icon iconId='draggable' />
            </DragHandle>
          </View>
        )}

        {children}
      </View>
    </Reorder.Item>
  )
}
const FormOptions: React.FC<{
  handler: UseFormReturn<
    EditableContentAttribute & (EditableSelectAttribute | EditableCategoriesAttribute),
    unknown
  >
}> = ({ handler }) => {
  const { t } = useTranslation()
  // We need to replacee all of the values here to allow rerendering.
  const { replace } = useFieldArray({ name: 'options', control: handler.control, keyName: '_unusedId' })
  // using fields here instead of the fields in useFieldArray. This is because useFieldArray does not update the fields immediately which is painful for us here
  const fields = useWatch({ control: handler.control, name: 'options' })

  const fieldState = handler.control.getFieldState('options')

  // remove from useFieldArray seems to work weirdly with our animation so using replace here instead
  const remove = (item: EditableSelectItem): void => {
    replace(
      fields.filter(i => {
        if ('id' in i && 'id' in item) {
          return i.id !== item.id
        }
        if ('_tempId' in i && '_tempId' in item) {
          return i._tempId !== item._tempId
        }
        return false
      })
    )
    handler.clearErrors('options')
  }

  const append = (label: string): void => {
    replace([...fields, newItem(label)])
    handler.clearErrors('options')
  }

  const error = fieldState.error?.root

  return (
    <View grow direction='column' gap='14'>
      <FormElement
        label={t('dictionary.options')}
        isError={isNonNullable(error)}
        helper={error ? error.message : undefined}
      >
        <Reorder.Group
          initial={false}
          axis='y'
          values={fields}
          onReorder={x => {
            replace(x)
            handler.clearErrors('options')
          }}
        >
          {fields.map((item, index) => {
            const id = 'id' in item ? item.id : item._tempId
            return (
              <DragItem key={id} value={item} hasDragHandle={fields.length > 1}>
                <View grow alignItems='flex-start'>
                  <FormInput key={id} control={handler.control} name={`options.${index}.label`} />
                  <IconMenu
                    variant='secondary'
                    iconId='overflow-menu--horizontal'
                    onSelect={() => {}}
                    menuItems={[
                      {
                        label: 'Delete',
                        onClick: () => remove(item),
                        type: 'label',
                        id: 'delete',
                        icon: 'trash-can',
                        color: 'destructive/background',
                      },
                    ]}
                  />
                </View>
              </DragItem>
            )
          })}
        </Reorder.Group>
      </FormElement>
      <AddButton icon='add' type='button' grow onClick={() => append('')}>
        {t('dictionary.add')}
      </AddButton>
    </View>
  )
}

// Useful for any reset we want to do
const EMPTY_FORM: Schema = {
  index: 0,
  name: '',
  type: 'select',
  options: [newItem('')],
  learnerVisible: false,
  allowMultiSelect: false,
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const ContentAttributeForm: React.FC<{
  attributeToEdit?: ContentAttribute
  onSubmit: (_: Schema) => void
  onCancel: () => void
}> = ({ attributeToEdit, onSubmit, onCancel }) => {
  const { t } = useTranslation()
  const handler = useForm<Schema>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    values: isDefined(attributeToEdit) ? CATtoEditable(attributeToEdit) : EMPTY_FORM,
  })

  const [_showDesc, setShowDesc] = useState(() => (attributeToEdit?.description ?? '').length > 0)
  const showDesc = _showDesc || (attributeToEdit?.description ?? '').length > 0

  const type = useWatch({ control: handler.control, name: 'type' })

  return (
    <Form onSubmit={handler.handleSubmit(onSubmit)}>
      <View padding='32 40' gap='32' direction='column'>
        <View direction='column'>
          <View alignItems='flex-start'>
            <View grow direction='column'>
              <FormInput label={t('dictionary.name')} control={handler.control} name='name' />
            </View>
            <View>
              <FormSingleSelectDropdown
                label={t('dictionary.type')}
                control={handler.control}
                name='type'
                // TODO: fix these types
                menuItems={[
                  {
                    label: 'Text',
                    id: 'text',
                    type: 'label',
                    selected: type === 'text',
                  },
                  { label: 'Number', id: 'number', type: 'label', selected: type === 'number' },
                  { label: 'Date', id: 'date', type: 'label', selected: type === 'date' },
                  { label: 'Select', id: 'select', type: 'label', selected: type === 'select' },
                  { label: 'Categories', id: 'categories', type: 'label', selected: type === 'categories' },
                ]}
              />
            </View>
          </View>

          <AnimatePresence initial={false} mode='popLayout'>
            {showDesc ? (
              <motion.div
                key='textArea'
                initial={{ height: 20 }}
                animate={{ height: 'auto' }}
                transition={{ duration: 0.2 }}
                style={{ marginTop: 8, paddingRight: 2, overflow: 'hidden' }}
              >
                <FormTextArea
                  label={t('dictionary.description')}
                  control={handler.control}
                  name='description'
                />
              </motion.div>
            ) : (
              <DescriptionText
                size='micro'
                bold
                color='foreground/muted'
                onClick={() => setShowDesc(o => !o)}
              >
                {t('manage.content-attributes.form.add-description.text')}
              </DescriptionText>
            )}
          </AnimatePresence>
        </View>

        {iife(() => {
          // Need to have a discriminated function here to get the right type
          const getHandlerOf = <T extends ContentAttributeInternalType>(
            _t: T
          ): UseFormReturn<EditableContentAttribute & { type: T }, unknown> => {
            return handler as unknown as UseFormReturn<EditableContentAttribute & { type: T }, unknown>
          }

          if (type === 'select' || type === 'categories') {
            const h = getHandlerOf(type)
            return <FormOptions handler={h} />
          }
          // TODO: add user etc
        })}
        <HorizontalDivider />
        <VisibilityView handler={handler} />
      </View>
      <View direction='column' alignItems='flex-end' gap='none'>
        <HorizontalDivider />

        <View padding='24 40'>
          <Button variant='secondary' type='button' onClick={onCancel}>
            {t('dictionary.cancel')}
          </Button>
          <Button type='submit'>{t('dictionary.save')}</Button>
        </View>
      </View>
    </Form>
  )
}
