import { useMemo } from 'react'
import { DynamicT } from 'sierra-client/hooks/use-translation/types'
import { useCachedQuery } from 'sierra-client/state/api'
import { EnrollmentRuleTimeDomain, EnrollmentStartTime } from 'sierra-domain/api/manage'
import { Label as LabelType } from 'sierra-domain/filter/datatype/label'
import { XRealtimeAdminProgramsEnrollmentRuleTimeDomain } from 'sierra-domain/routes'
import { color } from 'sierra-ui/color'
import { Text } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

export type UseTimeDomainProps = {
  timeDomains: EnrollmentRuleTimeDomain[]
  loadingDomains: boolean
}

export const useTimeDomains: () => UseTimeDomainProps = () => {
  const { data, isLoading } = useCachedQuery(XRealtimeAdminProgramsEnrollmentRuleTimeDomain, {})

  return useMemo(
    () => ({
      timeDomains: data?.domains ?? [],
      loadingDomains: isLoading,
    }),
    [data, isLoading]
  )
}

export type AfterDateProps = {
  afterDays: number
  setAfterDays: (days: number) => void
}

export const labelString = (label: LabelType, dynamicT: DynamicT): string => {
  switch (label.type) {
    case 'label.translation':
      return dynamicT(label.translationKey, undefined, label.defaultLabel)
    case 'label.default':
      return label.label
    case 'label.concatenation':
      return [labelString(label.first, dynamicT), labelString(label.second, dynamicT)].join(label.separator)
  }
}

export const Label = styled(Text).attrs({ size: 'small' })<{
  $pointer?: boolean
  $disabled?: boolean
}>`
  ${p =>
    p.$disabled === true &&
    css`
      color: ${color('black').opacity(0.4).toString()};
    `}

  ${p =>
    p.$pointer === true
      ? css`
          cursor: pointer;
        `
      : ''}
`

export const ClickableLabel = styled(Label).attrs({ $pointer: true, bold: true })``

// Format is YYYY-MM-DD
export const getCurrentDateString = (): EnrollmentStartTime => {
  const now = new Date()
  const date = now.toISOString().split('T')[0]

  if (date === undefined) {
    throw new Error('Could not get date for absolute start date.')
  }

  return {
    type: 'absolute',
    date,
  }
}

export type StartDateSelectorProps = {
  startDate: EnrollmentStartTime
  setStartDate: (attribute: EnrollmentStartTime) => void
  resetStartDate: () => void
  disabled?: boolean
}
