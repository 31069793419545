import { UseQueryOptions } from '@tanstack/react-query'
import { AuthSessionStatus } from 'sierra-domain/api/auth'
import { RequestError } from 'sierra-domain/error'

export type AuthClientQueryResult = {
  status: AuthSessionStatus
  userId?: string
}

export type AuthClient = {
  synchronize(): Promise<void>
  signOut(): Promise<void>
  getToken(): string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get authQuery(): UseQueryOptions<any, Error | RequestError, AuthClientQueryResult, any>
}

let authClient: AuthClient | undefined

export function getAuthClient(): AuthClient {
  if (authClient === undefined) {
    throw Error('Tried to access authClient before initializating it.')
  }

  return authClient
}

export function setAuthClient(client: AuthClient): void {
  authClient = client
}
