import { createFileRoute } from '@tanstack/react-router'

import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { requireOwner } from 'sierra-client/core/require-owner'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  XRealtimeAdminCoursesListRecentlyDeletedCourses,
  XRealtimeAdminCoursesUndeleteCourse,
} from 'sierra-domain/routes'
import { FormElement, InputDatePicker, TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const MaxWidthTruncatedText = styled(TruncatedText)`
  max-width: 80ch;
`

const Ul = styled.ul`
  li + li {
    margin-top: 16px;
  }
`

const HalfWidthContainer = styled.div`
  width: 50%;
`

const FormContainer = styled.div`
  margin: 16px;
`

export const Page: React.FC = () => {
  const [fromDate, setFromDate] = useState(() => DateTime.now().minus({ days: 90 }))
  const [toDate, setToDate] = useState(() => DateTime.now())
  const deletedCoursesQuery = useCachedQuery(XRealtimeAdminCoursesListRecentlyDeletedCourses, {
    from: fromDate.startOf('day').toJSDate(),
    to: toDate.endOf('day').toJSDate(),
  })
  const undeleteCourseMutation = useTypedMutation(XRealtimeAdminCoursesUndeleteCourse, {
    onSuccess: () => deletedCoursesQuery.refetch(),
  })
  const deletedCourses = deletedCoursesQuery.data?.courses

  return (
    <div>
      <FormContainer>
        <FormElement label='Dates'>
          <HalfWidthContainer>
            <label>From:</label>
            <InputDatePicker
              value={fromDate}
              disabled={false}
              autoFocus={false}
              onChange={date => {
                if (date && date.diff(toDate).toMillis() < 0) {
                  setFromDate(date)
                }
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <label>To:</label>
            <InputDatePicker
              value={toDate}
              disabled={false}
              autoFocus={false}
              onChange={date => {
                if (date && date.diff(fromDate).toMillis() > 0) {
                  setToDate(date)
                }
              }}
            />
          </HalfWidthContainer>
        </FormElement>
      </FormContainer>
      {deletedCoursesQuery.isPending && <>Loading deleted courses</>}
      {deletedCoursesQuery.isError && <>Error loading deleted courses</>}
      {deletedCourses?.length === 0 && <>No recently deleted courses found</>}
      {undeleteCourseMutation.isPending && <>Undeleting course...</>}
      {undeleteCourseMutation.isError && <>Error undeleting course</>}

      <View padding='48'>
        <Ul>
          {deletedCourses !== undefined &&
            deletedCourses.map(course => (
              <li key={course.id}>
                <View direction='column'>
                  <View gap='8'>
                    <Text size='large'>
                      {course.name} [{course.id}] - deleted at: {new Date(course.deletedAt).toLocaleString()}
                    </Text>
                    <Button
                      loading={undeleteCourseMutation.isPending}
                      onClick={() => {
                        if (confirm('Are you sure you want to undelete this course?')) {
                          undeleteCourseMutation.mutate({ courseId: course.id })
                        }
                      }}
                    >
                      Undelete
                    </Button>
                  </View>

                  <MaxWidthTruncatedText lines={2} size='small'>
                    {course.description ?? ''}
                  </MaxWidthTruncatedText>
                </View>
              </li>
            ))}
        </Ul>
      </View>
    </div>
  )
}

const RouteComponent = requireOwner(Page)

export const Route = createFileRoute('/recovery/courses')({
  component: RouteComponent as React.FC,
})
