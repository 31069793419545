import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
// Based on https://github.com/ianstormtaylor/slate/blob/main/site/examples/hovering-toolbar.tsx
import { YHistoryEditor } from '@slate-yjs/core'
import { AnimatePresence, motion } from 'framer-motion'
import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { withoutSavingToUndoStack } from 'sierra-client/editor'
import { previewColorAtom } from 'sierra-client/editor/text-color'
import { useOpenEditorTextActionsModalForSelection } from 'sierra-client/features/text-actions'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useThemes } from 'sierra-client/hooks/use-themes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useCommentingContext } from 'sierra-client/views/commenting/context'
import { aiTutorTodoT } from 'sierra-client/views/course-helper/tutor/ai-tutor-todo-t'
import {
  EditOrResponsesState,
  useCreatePageContextSafe,
  useEditOrResponsesStateSafe,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useLearnerNotes } from 'sierra-client/views/learner-notes/learner-notes-context'
import {
  isBlockActive,
  isInElement,
  isMarkActive,
  removeNodeWithId,
  replaceNodeWithId,
  toggleHeadingOrParagraph,
  toggleListLevel,
  toggleLists,
  toggleMark,
} from 'sierra-client/views/v3-author/command'
import {
  useEditorId,
  useEditorMode,
  useEditorReadOnly,
} from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useLatestSelection } from 'sierra-client/views/v3-author/hooks'
import { headingLevelToListItemLevel } from 'sierra-client/views/v3-author/list/utils'
import { TutorState, TutorStateAvailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { getCurrentlySelectedElements, isElementType } from 'sierra-client/views/v3-author/queries'
import {
  generateQuestionFromString,
  generateUnsplashImageFromString,
} from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { assertNever, iife, SerializedDOMRect, serializeDomRect } from 'sierra-domain/utils'
import { Link, Mark, SanaEditor } from 'sierra-domain/v3-author'
import {
  createCheckList,
  createOrderedList,
  createPlaceholder,
  createUnorderedList,
} from 'sierra-domain/v3-author/create-blocks'
import { dynamicColor } from 'sierra-ui/color'
import { Icon, IconId } from 'sierra-ui/components'
import { Text as TextUI, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import { BaseRange, Editor, Node, Range, Text } from 'slate'
import { ReactEditor, useFocused, useSlateSelection, useSlateSelector, useSlateStatic } from 'slate-react'
import styled, { css, useTheme } from 'styled-components'

const Menu = styled(View).attrs({ gap: '4' })<{ $isSelectingText: boolean; $isDebug: boolean }>`
  z-index: 1;
  position: absolute;
  display: flex;

  /* Remove pointer events while text is being selected.
     Otherwise, it is possible for the element to be rendered
     under the user's cursor while dragging to select text,
     which causes the selection to be lost
   */
  ${p =>
    p.$isSelectingText
      ? css`
          opacity: 0;

          ${p.$isDebug &&
          css`
            opacity: 1;
            ::after {
              position: absolute;
              z-index: 1;
              content: '[debug] selecting...';
              color: white;
              font-family: monospace;
              background: rgba(0, 0, 0, 0.8);
              inset: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              border-radius: 8px;
            }
          `}
        `
      : css`
          opacity: 0;
          animation: fadeIn 100ms ease forwards;
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
`

const onlyShowMarkAndLinkOption = (editor: Editor): boolean => {
  const { selection } = editor
  if (selection === null) return false
  if (Range.isCollapsed(selection)) return false

  if (
    (editor.string(selection) === '' && isInElement(editor, 'block-quote-subtitle')) ||
    isInElement(editor, ['block-quote', 'table-header-cell', 'table-cell', 'preamble'])
  ) {
    return true
  } else {
    return false
  }
}

type MenuGroupProps = { width?: string; usePadding?: boolean }
const MenuGroup = styled(View).attrs({ gap: 'none' })<MenuGroupProps>`
  background: ${palette.primitives.white};
  border-radius: ${p => p.theme.borderRadius['size-8']};
  border: 1px solid ${palette.grey[2]};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  padding: ${spacing['6']};

  ${p =>
    p.width !== undefined &&
    css`
      width: ${p.width};
    `}
  ${p =>
    p.usePadding === true &&
    css`
      padding: ${spacing['6']};
    `}
`

const ToolbarButtonContainer = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: middle;
  padding: ${spacing['4']};
  border-radius: ${p => p.theme.borderRadius['size-6']};
  cursor: pointer;
  background: ${p => (p.$active ? palette.grey[5] : palette.primitives.white)};
  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.1, 1);
  margin: 0 ${spacing['2']};

  &:hover {
    background: ${p => (p.$active ? p.theme.color.grey10 : palette.grey[5])};
  }
`

const ToolbarButton: React.FC<{
  active: boolean
  ariaLabel?: string
  iconId?: IconId
  textStyle?: string
  withChevron?: boolean
  open?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}> = ({ active, iconId, textStyle, onClick, withChevron, open, ariaLabel }) => {
  return (
    <ToolbarButtonContainer $active={active} onMouseDown={onClick} aria-label={ariaLabel}>
      <View gap='4'>
        {iconId !== undefined && <Icon iconId={iconId} size='size-16' color='black' />}
        {textStyle !== undefined && (
          <TextUI color='black' size='small' bold>
            {textStyle}
          </TextUI>
        )}
        {withChevron === true && (
          <Icon iconId={(open ?? false) ? 'chevron--up' : 'chevron--down'} color='grey40' size='size-12' />
        )}
      </View>
    </ToolbarButtonContainer>
  )
}

const getCurrentColors = (editor: Editor): string[] => {
  const colors = Array.from(
    editor.nodes({
      match: Text.isText,
      mode: 'all',
    })
  ).map(([text]) => text.color)

  return _.uniq(_.compact(colors))
}

const isWhite = (colorString: string): boolean => {
  const brightness = dynamicColor(colorString).brightness()
  return brightness >= 0.95
}

const getCurrentColor = (editor: Editor): string | undefined => {
  const [firstColor, ...rest] = getCurrentColors(editor)

  if (rest.length > 0) {
    // If there's more than one color in the selection, we'll treat it as if no color is selected.
    return undefined
  }

  return firstColor
}

const ColorPickerWrapper = styled(View)<{ $color: string | undefined }>`
  color: ${p => p.$color ?? palette.primitives.black};
`

const ColorPickerButton: React.FC<{
  open: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}> = ({ open, onClick }) => {
  const currentColor = useSlateSelector(getCurrentColor)
  // Ignore white text, because the toolbar background color is white.
  const currentColorNoWhite = currentColor !== undefined && isWhite(currentColor) ? undefined : currentColor

  return (
    <ToolbarButtonContainer $active={false} onMouseDown={onClick}>
      <ColorPickerWrapper gap='4' $color={currentColorNoWhite}>
        <Icon iconId='text--color' size='size-16' color='currentColor' />
        <Icon iconId={open ? 'chevron--up' : 'chevron--down'} color='grey40' size='size-12' />
      </ColorPickerWrapper>
    </ToolbarButtonContainer>
  )
}

const StyledMenuOption = styled(View)<{ $wide?: boolean }>`
  background: ${palette.primitives.white};
  ${p =>
    p.$wide === true &&
    css`
      width: 160px;
    `}
  padding: 0.675rem 0.75rem;
  border-radius: ${p => p.theme.borderRadius['size-6']};
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgba(242, 242, 242, 0.5);
  }
`

const StyledMenuOptionText = styled.span<{ $active: boolean }>`
  ${fonts.body.small};
  font-weight: ${fonts.weight.regular};

  ${p =>
    p.$active === true &&
    css`
      font-weight: ${fonts.weight.bold};
    `}
`

const TextMenuOption: React.FC<{
  active: boolean
  label: string
  open?: boolean
  showCustomFont?: boolean
  icon?: IconId
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({ active, label, onClick, showCustomFont, icon }) => {
  return (
    <StyledMenuOption $wide onMouseDown={onClick} grow>
      {icon !== undefined && <Icon color='black' iconId={icon} />}
      <StyledMenuOptionText $active={active}>
        {showCustomFont === true ? (
          <TextUI color='black' size='small'>
            {label}
          </TextUI>
        ) : (
          <>{label}</>
        )}
      </StyledMenuOptionText>
    </StyledMenuOption>
  )
}

const toggleFormat = (editor: Editor, format: Mark): void => {
  if (format === 'subscript') {
    toggleMark(editor, 'subscript')

    // We never want to have `subscript` and `supscript` active at the same time.
    editor.removeMark('supscript')
  } else if (format === 'supscript') {
    toggleMark(editor, 'supscript')

    // We never want to have `subscript` and `supscript` active at the same time.
    editor.removeMark('subscript')
  } else {
    toggleMark(editor, format)
  }
}

const toggleColor = (editor: Editor, color: string): void => {
  editor.addMark('color', color)
}

const unToggleColor = (editor: Editor): void => {
  editor.removeMark('color')
}

const FormatButton = ({ format, iconId }: { format: Mark; iconId: IconId }): JSX.Element => {
  const editor = useSlateStatic()

  const selectIsMarkActive = useCallback(
    (editor: Editor): boolean => {
      return isMarkActive(editor, format)
    },
    [format]
  )

  const isActive = useSlateSelector(selectIsMarkActive)

  return (
    <ToolbarButton
      iconId={iconId}
      active={isActive}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()

        if (format === 'link') {
          const link: Entity<Link> = { id: nanoid12(), type: 'link', url: '', children: [] }

          editor.wrapNodes(link, { split: true })
          if (editor.selection !== null) {
            editor.select({ anchor: editor.selection.focus, focus: editor.selection.focus })
          }
        } else {
          toggleFormat(editor, format)
        }
      }}
    />
  )
}

const DividerVertical = styled.div`
  width: 1px;
  height: 1rem;
  background: ${p => p.theme.color.grey10};
  margin: 0 ${spacing['6']};
`

const MenuWrapper = styled(motion.div)``

const isHeadingLevelActiveInList = (editor: SanaEditor, level: number): boolean => {
  const listLevel = headingLevelToListItemLevel(level)
  const [match] = editor.nodes({
    match: n =>
      (isElementType('list-item', n) || isElementType('check-list-item', n)) &&
      n.level === listLevel &&
      !hasOnlyEmptyTextInNodes([n]),
    mode: 'all',
  })

  return match !== undefined
}

const isHeadingLevelActive = (editor: SanaEditor, level: number): boolean => {
  const [match] = editor.nodes({
    match: n => isElementType('heading', n) && n.level === level && !hasOnlyEmptyTextInNodes([n]),
    mode: 'all',
  })
  return match !== undefined || isHeadingLevelActiveInList(editor, level)
}

const isParagraphLevelActiveInList = (editor: SanaEditor, level: number): boolean => {
  const [match] = editor.nodes({
    match: n =>
      (isElementType('list-item', n) || isElementType('check-list-item', n)) &&
      n.level === level &&
      !hasOnlyEmptyTextInNodes([n]),
    mode: 'all',
  })

  return match !== undefined
}

const isParagraphLevelActive = (editor: SanaEditor, level: number): boolean => {
  const [match] = editor.nodes({
    match: n => isElementType('paragraph', n) && n.level === level && !hasOnlyEmptyTextInNodes([n]),
    mode: 'all',
  })
  return match !== undefined || isParagraphLevelActiveInList(editor, level)
}

const turnIntoParagraph = (editor: SanaEditor, paragraphLevel: number): void => {
  const selectedElements = getCurrentlySelectedElements(editor)
  selectedElements.forEach(([node]) => {
    if (node.type === 'bulleted-list' || node.type === 'numbered-list' || node.type === 'check-list') {
      return toggleListLevel(editor, paragraphLevel)
    }
    toggleHeadingOrParagraph(editor, 'paragraph', paragraphLevel)
  })
}

export const turnIntoHeading = (editor: SanaEditor, headingLevel: number): void => {
  const selectedElements = getCurrentlySelectedElements(editor)

  selectedElements.forEach(([node]) => {
    if (node.type === 'bulleted-list' || node.type === 'numbered-list' || node.type === 'check-list') {
      return toggleListLevel(editor, headingLevelToListItemLevel(headingLevel))
    } else {
      toggleHeadingOrParagraph(editor, 'heading', headingLevel)
    }
  })
}

export const turnIntoList = (
  editor: Editor,
  listType: 'check-list' | 'bulleted-list' | 'numbered-list'
): void => {
  if (!editor.selection) return

  const list = iife(() => {
    switch (listType) {
      case 'check-list':
        return createCheckList()
      case 'numbered-list':
        return createOrderedList()
      case 'bulleted-list':
        return createUnorderedList()
    }
  })

  const selectedElements = getCurrentlySelectedElements(editor)
  const wrapperTypes = [
    'reflection-card',
    'flip-card',
    'flip-cards',
    'flip-cards-card-container',
    'flip-card-front',
    'flip-card-back',
    'question-card',
    'poll-card',
    'assessment-question',
    'assessment-card',
    'homework-card',
    'takeaways',
    'takeaway-item',
  ]

  const selectedElementTypes = selectedElements
    .map(ne => ne[0].type)
    .filter(type => !wrapperTypes.includes(type))

  if (
    selectedElementTypes.length === 1 &&
    selectedElementTypes.every(
      type => type === 'numbered-list' || type === 'bulleted-list' || type === 'check-list'
    )
  ) {
    return toggleLists(editor, listType)
  }

  editor.withoutNormalizing(() => {
    editor.unwrapNodes({
      match: n => isElementType(['check-list', 'bulleted-list', 'numbered-list'], n),
      split: false,
    })
    editor.wrapNodes(list, { split: false })
  })
}

const DropDownButton = ({
  onClick,
  open,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  open: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  const currentTextStyle = useSlateSelector(editor => {
    if (isParagraphLevelActive(editor, 0)) {
      return t('font.large-text')
    } else if (isHeadingLevelActive(editor, 5)) {
      return t('font.display')
    } else if (isHeadingLevelActive(editor, 0)) {
      return t('font.title')
    } else if (isHeadingLevelActive(editor, 2)) {
      return t('font.heading')
    } else if (isHeadingLevelActive(editor, 3)) {
      return t('font.subheading')
    } else if (isParagraphLevelActive(editor, 1)) {
      return t('font.text')
    } else if (isParagraphLevelActive(editor, 2)) {
      return t('font.footnote')
    }

    return t('font.text')
  })

  return (
    <ToolbarButton
      textStyle={currentTextStyle}
      active={false}
      onClick={onClick}
      withChevron={true}
      open={open}
    />
  )
}

const SizeDropDownMenu: FC<{ onAction: () => void }> = ({ onAction }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const isInTakeaways = isInElement(editor, 'takeaways')

  return (
    <>
      {!isInTakeaways && (
        <>
          <TextMenuOption
            label={t('font.display')}
            showCustomFont={true}
            active={isHeadingLevelActive(editor, 5)}
            onClick={() => {
              turnIntoHeading(editor, 5)
              onAction()
            }}
          />
          <TextMenuOption
            label={t('font.title')}
            active={isHeadingLevelActive(editor, 0)}
            showCustomFont={true}
            onClick={() => {
              turnIntoHeading(editor, 0)
              onAction()
            }}
          />
          <TextMenuOption
            label={t('font.heading')}
            active={isHeadingLevelActive(editor, 2)}
            showCustomFont={true}
            onClick={() => {
              turnIntoHeading(editor, 2)
              onAction()
            }}
          />
          <TextMenuOption
            label={t('font.subheading')}
            active={isHeadingLevelActive(editor, 3)}
            showCustomFont={true}
            onClick={() => {
              turnIntoHeading(editor, 3)
              onAction()
            }}
          />
        </>
      )}
      <TextMenuOption
        label={t('font.large-text')}
        active={isParagraphLevelActive(editor, 0)}
        showCustomFont={true}
        onClick={() => {
          turnIntoParagraph(editor, 0)
          onAction()
        }}
      />
      <TextMenuOption
        label={t('font.text')}
        active={isParagraphLevelActive(editor, 1)}
        showCustomFont={true}
        onClick={() => {
          turnIntoParagraph(editor, 1)
          onAction()
        }}
      />
      <TextMenuOption
        label={t('font.footnote')}
        active={isParagraphLevelActive(editor, 2)}
        showCustomFont={true}
        onClick={() => {
          turnIntoParagraph(editor, 2)
          onAction()
        }}
      />
    </>
  )
}

const DotDropdownWrapper = styled(motion.div)`
  position: absolute;
  right: 0px;
  top: 50px;
`

const DotMenuDropdownItemsContainer = styled(View)`
  min-width: 100px;
`

const DotMenuDropdownItems: FC<{ onAction: () => void }> = ({ onAction }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const showLists = !isInElement(editor, ['block-quote', 'table-cell', 'table-header-cell', 'takeaways'])

  return (
    <DotMenuDropdownItemsContainer direction='column' margin='6 6' gap='12 none'>
      {showLists && (
        <View direction='column' gap='4 none'>
          <View margin='none 4'>
            <TextUI color='grey25' size='micro' bold>
              {t('author.label.list')}
            </TextUI>
          </View>
          <View direction='row' gap='none 4'>
            <ToolbarButton
              iconId='list--bulleted'
              active={isBlockActive(editor, 'bulleted-list')}
              onClick={() => {
                turnIntoList(editor, 'bulleted-list')
                onAction()
              }}
            />
            <ToolbarButton
              iconId='list--numbered'
              active={isBlockActive(editor, 'numbered-list')}
              onClick={() => {
                turnIntoList(editor, 'numbered-list')
                onAction()
              }}
            />
            {!isInElement(editor, 'flip-card') && (
              <ToolbarButton
                iconId='list--checked'
                active={isBlockActive(editor, 'check-list')}
                onClick={() => {
                  turnIntoList(editor, 'check-list')
                  onAction()
                }}
              />
            )}
          </View>
        </View>
      )}
      <View direction='column' gap='4 none'>
        <View margin='none 4' gap='none 4'>
          <TextUI color='grey25' size='micro' bold>
            {t('author.label.formatting')}
          </TextUI>
        </View>
        <View direction='row' gap='none 4'>
          <FormatButton format='subscript' iconId='text--subscript' />
          <FormatButton format='supscript' iconId='text--superscript' />
          <FormatButton format='strikethrough' iconId='text--strikethrough' />
        </View>
      </View>
    </DotMenuDropdownItemsContainer>
  )
}

const AssistantDropdownMenu: React.FC<{ onAction: () => void }> = ({ onAction }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notification = useNotif()
  const isGeneralCard = useFileContext().file.data.type === 'general'
  const openEditorTextActionsModalForSelection = useOpenEditorTextActionsModalForSelection()

  const contentIdAndType = useCreatePageContextSafe()

  return (
    <DotDropdownWrapper
      key='AssistantMenuWrapper'
      initial={{ y: -8, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -8, opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
    >
      <MenuGroup width='fit-content' usePadding={false}>
        <View direction='column' gap='none' grow>
          <TextMenuOption
            icon='magic-wand--filled'
            label={t('create.text-actions.custom-instructions')}
            showCustomFont={true}
            active={false}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              openEditorTextActionsModalForSelection({ mode: 'replace' })

              if (contentIdAndType) {
                void dispatch(
                  generativeFeatureUsed({
                    contentId: contentIdAndType.createContentId,
                    contentType: contentIdAndType.contentType,
                    generativeFeature: 'custom-text-actions-inline-toolbar',
                  })
                )
              }
            }}
          />

          <TextMenuOption
            icon='text--align--left'
            label={t('assistant.summarize')}
            showCustomFont={true}
            active={false}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              openEditorTextActionsModalForSelection({
                mode: 'replace',
                initialInstructions: t('assistant.summarize'),
              })

              if (contentIdAndType) {
                void dispatch(
                  generativeFeatureUsed({
                    contentId: contentIdAndType.createContentId,
                    contentType: contentIdAndType.contentType,
                    generativeFeature: 'generate-summary-inline-toolbar',
                  })
                )
              }
            }}
          />

          <TextMenuOption
            icon='generate--paragraph'
            label={t('assistant.continue-writing')}
            showCustomFont={true}
            active={false}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              openEditorTextActionsModalForSelection({
                mode: 'extend',
                initialInstructions: t('assistant.continue-writing'),
              })

              if (contentIdAndType) {
                void dispatch(
                  generativeFeatureUsed({
                    contentId: contentIdAndType.createContentId,
                    contentType: contentIdAndType.contentType,
                    generativeFeature: 'generate-completion-inline-toolbar',
                  })
                )
              }
            }}
          />

          {isGeneralCard && (
            <>
              <TextMenuOption
                icon='generate--question'
                label={t('author.slate.generate-question')}
                showCustomFont={true}
                active={false}
                onClick={async e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onAction()
                  const selection = editor.selection
                  if (selection === null) return
                  const selectedText = editor.string(selection)
                  const placeholder = createPlaceholder({ blockType: 'question-card' })
                  const insideNodeAt = editor.selection !== null ? Range.end(editor.selection) : undefined

                  const insertIntoRootNodePath = insideNodeAt?.path[0]

                  const insertNodeAt =
                    insertIntoRootNodePath !== undefined ? [insertIntoRootNodePath + 1] : undefined

                  withoutSavingToUndoStack(editor, () => {
                    editor.insertNodes(placeholder, {
                      at: insertNodeAt,
                    })
                  })

                  if (contentIdAndType) {
                    void dispatch(
                      generativeFeatureUsed({
                        contentId: contentIdAndType.createContentId,
                        contentType: contentIdAndType.contentType,
                        generativeFeature: 'generate-question-inline-toolbar',
                      })
                    )
                  }

                  const question = await generateQuestionFromString(selectedText, notification)

                  if (question === undefined) {
                    withoutSavingToUndoStack(editor, () => {
                      removeNodeWithId(editor, placeholder.id)
                    })
                    return
                  }

                  replaceNodeWithId(editor, placeholder.id, question, false)
                }}
              />
              <TextMenuOption
                icon='image'
                label={t('assistant.add-image')}
                showCustomFont={true}
                active={false}
                onClick={async e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onAction()
                  const selection = editor.selection
                  if (selection === null) return
                  const selectedText = editor.string(selection)
                  const placeholder = createPlaceholder({ blockType: 'image' })
                  const insideNodeAt = editor.selection !== null ? Range.end(editor.selection) : undefined

                  const insertIntoRootNodePath = insideNodeAt?.path[0]

                  const insertNodeAt =
                    insertIntoRootNodePath !== undefined ? [insertIntoRootNodePath + 1] : undefined

                  withoutSavingToUndoStack(editor, () => {
                    editor.insertNodes(placeholder, {
                      at: insertNodeAt,
                    })
                  })

                  if (contentIdAndType) {
                    void dispatch(
                      generativeFeatureUsed({
                        contentId: contentIdAndType.createContentId,
                        contentType: contentIdAndType.contentType,
                        generativeFeature: 'generate-image-inline-toolbar',
                      })
                    )
                  }

                  const image = await generateUnsplashImageFromString(selectedText, notification)

                  if (image === undefined) {
                    withoutSavingToUndoStack(editor, () => {
                      removeNodeWithId(editor, placeholder.id)
                    })
                    return
                  }

                  replaceNodeWithId(editor, placeholder.id, image, false)
                }}
              />
            </>
          )}
        </View>
      </MenuGroup>
    </DotDropdownWrapper>
  )
}

const DotDropdownMenu: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <DotDropdownWrapper
      key='DotDropdownWrapper'
      initial={{ y: -8, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -8, opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
    >
      <MenuGroup width='fit-content' usePadding={false}>
        <View direction='column' gap='none' grow>
          <DotMenuDropdownItems onAction={onClose} />
        </View>
      </MenuGroup>
    </DotDropdownWrapper>
  )
}

const CustomThemeColorButton = styled.button<{ $color: string; isSelected: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background-color: ${p => p.$color};
  cursor: pointer;

  &:hover {
    outline: 1px solid ${p => dynamicColor(p.$color).opacity(0.5)};
  }

  ${p =>
    isWhite(p.$color) &&
    css`
      border: 1px solid ${palette.grey[5]};
    `}

  ${p =>
    p.isSelected &&
    css`
      box-shadow: 0px 0px 0px 2px rgba(5, 88, 249, 0.25);
    `}
`

const ColorPickerMenuDropdownItemsContainer = styled(View)`
  width: fit-content;
`

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
`

const sanaColors = {
  black: palette.primitives.black,
  yellowDarker: palette.yellow.darker,
  orangeDarker: palette.orange.darker,
  greenDarker: palette.green.darker,
  blueDarker: palette.blue.darker,
  purpleDarker: palette.purple.darker,
  pinkDarker: palette.pink.darker,
  redDarker: palette.red.darker,

  grey80: palette.grey[80],
  yellowDark: palette.yellow.dark,
  orangeDark: palette.orange.dark,
  greenDark: palette.green.dark,
  blueDark: palette.blue.dark,
  purpleDark: palette.purple.dark,
  pinkDark: palette.pink.dark,
  redDark: palette.red.dark,

  grey50: palette.grey[50],
  yellowBright: palette.yellow.bright,
  orangeBright: palette.orange.bright,
  greenBright: palette.green.bright,
  blueBright: palette.blue.bright,
  purpleBright: palette.purple.bright,
  pinkBright: palette.pink.bright,
  redBright: palette.red.bright,

  grey30: palette.grey[30],
  yellowVivid: palette.yellow.vivid,
  orangeVivid: palette.orange.vivid,
  greenVivid: palette.green.vivid,
  blueVivid: palette.blue.vivid,
  purpleVivid: palette.purple.vivid,
  pinkVivid: palette.pink.vivid,
  redVivid: palette.red.vivid,

  grey10: palette.grey[10],
  yellowLight: palette.yellow.light,
  orangeLight: palette.orange.light,
  greenLight: palette.green.light,
  blueLight: palette.blue.light,
  purpleLight: palette.purple.light,
  pinkLight: palette.pink.light,
  redLight: palette.red.light,

  white: palette.primitives.white,
  yellowPastel: palette.yellow.pastel,
  orangePastel: palette.orange.pastel,
  greenPastel: palette.green.pastel,
  bluePastel: palette.blue.pastel,
  purplePastel: palette.purple.pastel,
  pinkPastel: palette.pink.pastel,
  redPastel: palette.red.pastel,
}

const ColorMenuDropdownItems: React.FC<{ onPick: () => void }> = ({ onPick }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const { customThemes } = useThemes()
  const currentColor = getCurrentColor(editor)
  const customColors = _.chain(customThemes)
    .values()
    .compact()
    .flatMap(it => [it.backgroundColor, it.foregroundColor])
    .uniq()
    .value()

  const currentTheme = useTheme()
  const currentThemeTextColor = currentTheme.home.textColor
  const customThemesWithThemeTextColor = [currentThemeTextColor, ...customColors]

  const setPreviewColor = useSetAtom(previewColorAtom)

  useEffect(() => {
    return () => {
      setPreviewColor(undefined)
    }
  }, [setPreviewColor])

  return (
    <ColorPickerMenuDropdownItemsContainer direction='column' margin='6 6' gap='12 none'>
      <View direction='column' gap='8 none'>
        {customColors.length > 0 && (
          <>
            <TextUI color='grey25' size='micro' bold>
              {t('author.custom')}
            </TextUI>
            <ColorGrid>
              {customThemesWithThemeTextColor.map((color, index) => (
                <CustomThemeColorButton
                  isSelected={color === currentColor}
                  key={`${color}-${index}`}
                  $color={color}
                  onMouseDown={() => {
                    if (color === currentColor) {
                      unToggleColor(editor)
                    } else {
                      toggleColor(editor, color)
                    }
                    onPick()
                  }}
                  onMouseOver={() => setPreviewColor(color)}
                  onMouseOut={() => setPreviewColor(undefined)}
                />
              ))}
            </ColorGrid>
          </>
        )}
      </View>
      <View direction='column' gap='8 none'>
        <TextUI color='grey25' size='micro' bold>
          {t('author.preset')}
        </TextUI>
        <ColorGrid>
          {Object.values(sanaColors).map((color, index) => (
            <CustomThemeColorButton
              isSelected={color === currentColor}
              key={`${color}-${index}`}
              $color={color}
              onMouseDown={() => {
                if (color === currentColor) {
                  unToggleColor(editor)
                } else {
                  toggleColor(editor, color)
                }
                onPick()
              }}
              onMouseOver={() => setPreviewColor(color)}
              onMouseOut={() => setPreviewColor(undefined)}
            />
          ))}
        </ColorGrid>
      </View>
    </ColorPickerMenuDropdownItemsContainer>
  )
}

const ColorDropdownMenu: React.FC<{ onPick: () => void }> = ({ onPick }) => {
  return (
    <DotDropdownWrapper
      key='DotDropdownWrapper'
      initial={{ y: -8, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -8, opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
    >
      <MenuGroup width='fit-content' usePadding={false}>
        <View direction='column' gap='none' grow>
          <ColorMenuDropdownItems onPick={onPick} />
        </View>
      </MenuGroup>
    </DotDropdownWrapper>
  )
}

const ContextDropDownMenu: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <MenuWrapper
      key='ContextDropDownMenu'
      initial={{ y: -8, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -8, opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
    >
      <MenuGroup width='fit-content' usePadding={false}>
        <View direction='column' gap='none' grow>
          <SizeDropDownMenu onAction={onClose} />
        </View>
      </MenuGroup>
    </MenuWrapper>
  )
}

const NowrapView = styled(View)`
  white-space: nowrap;
`

function getContextMenuType(editor: Editor): 'text' | undefined {
  const selectedElementTypes = Array.from(getCurrentlySelectedElements(editor)).map(ne => ne[0].type)

  if (
    selectedElementTypes.every(
      type =>
        type === 'heading' ||
        type === 'paragraph' ||
        type === 'bulleted-list' ||
        type === 'numbered-list' ||
        type === 'list-item' ||
        type === 'check-list' ||
        type === 'check-list-item' ||
        type === 'question-card' ||
        type === 'question-variations' ||
        type === 'poll-card' ||
        type === 'sliding-scale-card' ||
        type === 'reflection-card' ||
        type === 'link' ||
        type === 'flip-cards' ||
        type === 'flip-cards-card-container' ||
        type === 'flip-card' ||
        type === 'flip-card-front' ||
        type === 'flip-card-back' ||
        type === 'homework-card' ||
        type === 'horizontal-stack' ||
        type === 'vertical-stack' ||
        type === 'tag' ||
        type === 'takeaway-item' ||
        type === 'takeaways'
    )
  ) {
    return 'text'
  } else {
    return undefined
  }
}

const ContextMenu: React.FC<{
  toggleDropDownMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
  open: boolean
}> = ({ open, toggleDropDownMenu }) => {
  const menuType = useSlateSelector(getContextMenuType)

  if (menuType === undefined) {
    return null
  }

  return <DropDownButton onClick={toggleDropDownMenu} open={open} />
}

type CurrentSelection = {
  range: BaseRange
}

function getCurrentToolbarSelection(editor: Editor): CurrentSelection | undefined {
  const { selection } = editor
  if (selection === null) return undefined
  if (Range.isCollapsed(selection)) return undefined

  if (editor.string(selection) === '') {
    return undefined
  }

  return { range: selection }
}

export function undo(editor: Editor): void {
  if (YHistoryEditor.isYHistoryEditor(editor)) editor.undo()
  else console.error("Cannot undo in an editor that doesn't support history")
}

const EditContentButton: React.FC<{
  editOrResponsesStateAtom: PrimitiveAtom<EditOrResponsesState>
}> = ({ editOrResponsesStateAtom }) => {
  const { t } = useTranslation()
  const [editOrResponsesState, setEditOrResponsesState] = useAtom(editOrResponsesStateAtom)
  const editorReadonly = useEditorReadOnly()
  const dispatch = useDispatch()
  const { scopedCreateContentId } = useFileContext()
  const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)

  if (
    editOrResponsesState.type === 'edit' ||
    // This button is only relevant if the editor is already in the readOnly state
    !editorReadonly
  ) {
    return null
  }

  return (
    <MenuGroup>
      <ToolbarButton
        ariaLabel={t('content.edit')}
        textStyle={t('content.edit')}
        iconId='edit'
        active={false}
        onClick={() => {
          void dispatch(
            Logging.authoring.dataHistorySwitchEditToggledInline({
              courseId: scopedCreateContentId,
              courseKind: contentType,
            })
          )
          setEditOrResponsesState(previous => ({ ...previous, type: 'edit' }))
        }}
      />
    </MenuGroup>
  )
}

const InlineToolbarMenu: React.FC<{
  currentRangedSelection: CurrentSelection | undefined
  currentEditOrResponsesSelection: BaseRange | undefined
}> = ({ currentRangedSelection, currentEditOrResponsesSelection }) => {
  const editor = useSlateStatic()
  const editorId = useEditorId()
  const readOnly = useEditorReadOnly()
  const { t } = useTranslation()
  const commenting = useCommentingContext()
  const [showDotMenu, setShowDotMenu] = useState(false)
  const [showAssistantMenu, setShowAssistantMenu] = useState(false)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const onlyMarkAndLinkOption = useSlateSelector(onlyShowMarkAndLinkOption)

  const onCommentClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (currentRangedSelection === undefined) {
        return
      }
      event.preventDefault()
      event.stopPropagation()
      const element = getCurrentlySelectedElements(editor)[0]

      if (element === undefined) {
        throw Error('Can not comment without an active selection')
      }

      void commenting?.initiateCommenting({
        contentReference: {
          type: 'range',
          unitId: editorId,
          range: currentRangedSelection.range,
        },
      })
    },
    [commenting, currentRangedSelection, editor, editorId]
  )

  const [showLevels, setShowLevels] = useState(false)

  const toggleColorMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setShowDotMenu(previous => previous === true && false)
    setShowAssistantMenu(previous => previous === true && false)
    setShowLevels(previous => previous === true && false)
    setShowColorPicker(!showColorPicker)
  }

  const toggleDropDownMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setShowDotMenu(previous => previous === true && false)
    setShowAssistantMenu(previous => previous === true && false)
    setShowColorPicker(previous => previous === true && false)
    setShowLevels(!showLevels)
  }

  const toggleDotMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setShowLevels(previous => previous === true && false)
    setShowAssistantMenu(previous => previous === true && false)
    setShowColorPicker(previous => previous === true && false)
    setShowDotMenu(!showDotMenu)
  }

  const toggleAssistantMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setShowLevels(previous => previous === true && false)
    setShowDotMenu(previous => previous === true && false)
    setShowColorPicker(previous => previous === true && false)
    setShowAssistantMenu(previous => !previous)
  }

  const editOrResponsesStateAtom = useCreatePageContextSafe()?.editOrResponsesStateAtom

  if (currentEditOrResponsesSelection && editOrResponsesStateAtom !== undefined) {
    return (
      <motion.div
        key='edit-content'
        style={{ transform: 'translateX(-50%)' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
      >
        <EditContentButton editOrResponsesStateAtom={editOrResponsesStateAtom} />
      </motion.div>
    )
  }

  return (
    <motion.div
      key='inline-toolbar'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
    >
      <NowrapView direction='column'>
        <View direction='row'>
          {readOnly === false && (
            <MenuGroup>
              <ContextMenu toggleDropDownMenu={toggleDropDownMenu} open={showLevels} />
              <FormatButton format='bold' iconId='text--bold' />
              <FormatButton format='italic' iconId='text--italic' />
              <FormatButton format='underline' iconId='text--underline' />
              <FormatButton format='link' iconId='link' />
              <ColorPickerButton open={showColorPicker} onClick={toggleColorMenu} />

              <>
                {onlyMarkAndLinkOption === false && (
                  <View>
                    <ToolbarButton
                      active={false}
                      iconId='overflow-menu--horizontal'
                      onClick={toggleDotMenu}
                    />
                  </View>
                )}
                <DividerVertical />
                <ToolbarButton
                  open={showAssistantMenu}
                  iconId={'glitter'}
                  active={false}
                  onClick={toggleAssistantMenu}
                />
              </>
            </MenuGroup>
          )}

          {onlyMarkAndLinkOption === false && currentRangedSelection !== undefined && (
            <MenuGroup>
              <ToolbarButton
                ariaLabel={t('dictionary.add-comment-placeholder')}
                iconId='add-comment'
                active={false}
                onClick={onCommentClick}
              />
            </MenuGroup>
          )}
        </View>

        <AnimatePresence>
          {showColorPicker && (
            <ColorDropdownMenu
              onPick={() => {
                setShowColorPicker(false)
                ReactEditor.focus(editor)
              }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showDotMenu && (
            <DotDropdownMenu
              onClose={() => {
                setShowDotMenu(false)
                ReactEditor.focus(editor)
              }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showAssistantMenu && (
            <AssistantDropdownMenu
              onAction={() => {
                setShowAssistantMenu(false)
              }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showLevels && (
            <ContextDropDownMenu
              onClose={() => {
                setShowLevels(false)
                ReactEditor.focus(editor)
              }}
            />
          )}
        </AnimatePresence>
      </NowrapView>
    </motion.div>
  )
}

const AITutorToolbarButton: React.FC<{
  editor: SanaEditor
  currentRangedSelection: CurrentSelection
  tutorState: TutorStateAvailable
}> = ({ editor, currentRangedSelection, tutorState }) => {
  return (
    <>
      <ToolbarButton
        textStyle={aiTutorTodoT('Explain this')}
        ariaLabel={aiTutorTodoT('Explain this')}
        iconId='skill--sparkles'
        active={false}
        onClick={() => {
          const text = editor
            .fragment(currentRangedSelection.range)
            .map(fragment => Node.string(fragment))
            .join(' ')

          const fileId = tutorState.qaState.startedFromFileId
          tutorState.setTutorModalState({
            type: 'showing-qa',
            withInitialQuestion: aiTutorTodoT(`Explain this: "${text}"`),
            knownSourceFileIds: [fileId],
          })
        }}
      />
    </>
  )
}

const SelfPacedInlineToolbar: React.FC<{
  currentRangedSelection: CurrentSelection | undefined
  tutorState: TutorState
}> = ({ currentRangedSelection, tutorState }) => {
  const editor = useSlateStatic()
  const mode = useEditorMode()
  const { t } = useTranslation()
  const learnerNotes = useLearnerNotes()

  const onLearnerNotesClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (currentRangedSelection === undefined) return
      event.preventDefault()
      event.stopPropagation()
      const element = getCurrentlySelectedElements(editor)[0]

      if (element === undefined) {
        throw Error('Can not add learner notes without an active selection')
      }

      learnerNotes?.setRange(currentRangedSelection.range)
    },
    [learnerNotes, currentRangedSelection, editor]
  )
  return (
    <AnimatePresence>
      <motion.div
        key='inline-toolbar'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
      >
        <NowrapView direction='column'>
          <View direction='row'>
            {mode === 'self-paced' && (getFlag('learner-notes') || tutorState.type === 'available') && (
              <MenuGroup>
                {getFlag('learner-notes') && (
                  <ToolbarButton
                    textStyle={t('dictionary.take-note')}
                    ariaLabel={'take note'}
                    iconId='notebook'
                    active={false}
                    onClick={onLearnerNotesClick}
                  />
                )}
                {tutorState.type === 'available' && currentRangedSelection !== undefined && (
                  <AITutorToolbarButton
                    editor={editor}
                    currentRangedSelection={currentRangedSelection}
                    tutorState={tutorState}
                  />
                )}
              </MenuGroup>
            )}
          </View>
        </NowrapView>
      </motion.div>
    </AnimatePresence>
  )
}

function useOnWindowResize(onResize: () => void): void {
  const stableOnResize = useStableFunction(onResize)

  useEffect(() => {
    let mounted = true
    function handleResize(): void {
      requestAnimationFrame(() => {
        if (mounted) stableOnResize()
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      mounted = false
      window.removeEventListener('resize', handleResize)
    }
  }, [stableOnResize])
}

// When showing responses in the editor, we want to detect if the user has clicked on
// text so that we can show a context menu prompting them to edit
function useCurrentEditOrResponsesSelection(): BaseRange | undefined {
  const isShowingResponses = useEditOrResponsesStateSafe()?.type === 'responses'
  const slateSelection = useSlateSelection()

  const [isShowingEdit, setIsShowingEdit] = useState(false)

  useOnChanged(
    (oldState, newState) => {
      // If we were already in the "showing responses" state and a new slate selection is picked,
      // we want to show the little edit icon
      setIsShowingEdit(oldState?.isShowingResponses === true && newState.slateSelection !== null)
    },
    { isShowingResponses, slateSelection }
  )

  if (!slateSelection || !isShowingEdit || !isShowingResponses || !Range.isCollapsed(slateSelection)) {
    return undefined
  }
  return slateSelection
}

const InlineToolbarImplementation: React.FC<{
  container: HTMLElement
  mode: EditorMode
  tutorState: TutorState
}> = ({ container, mode, tutorState }) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const currentRangedSelection = useSlateSelector(getCurrentToolbarSelection, _.isEqual)
  const editOrResponsesSlateSelection = useCurrentEditOrResponsesSelection()

  // Rerender the menu when the window size changes
  const [, setStaleKey] = useState(0)
  useOnWindowResize(() => setStaleKey(prev => prev + 1))
  const slateSelection = useLatestSelection()

  const domSelectionBoundingBox: SerializedDOMRect | undefined = useMemo(() => {
    if (slateSelection === null) return undefined
    const domSelection = window.getSelection()
    if (!domSelection || domSelection.rangeCount === 0) return undefined

    const range = domSelection.getRangeAt(0)
    const selectionRect = serializeDomRect(
      Array.from(range.getClientRects())[0] ?? range.getBoundingClientRect()
    )
    const containerRect = serializeDomRect(container.getBoundingClientRect())
    return {
      ...selectionRect,
      top: selectionRect.top - containerRect.top,
      left: selectionRect.left - containerRect.left,
    }
  }, [container, slateSelection])

  const menuWidth = menuRef.current?.getBoundingClientRect()?.width ?? 250

  const x = (() => {
    if (domSelectionBoundingBox === undefined) return undefined

    const x = domSelectionBoundingBox.left

    // Try to ensure that the menu does not stretch past the right side of the screen
    const leftMostStartingPoint = container.getBoundingClientRect().width - menuWidth
    return Math.min(leftMostStartingPoint, x)
  })()
  const y = (() => {
    if (domSelectionBoundingBox === undefined) return undefined
    else return domSelectionBoundingBox.top - 50
  })()

  const [isSelectingText, setIsSelectingText] = useState(false)
  useEffect(() => {
    const onMouseDown = (): void => {
      setIsSelectingText(true)
    }
    const onMouseUp = (): void => {
      setIsSelectingText(false)
    }

    container.addEventListener('mousedown', onMouseDown)
    container.addEventListener('mouseup', onMouseUp)
    return () => {
      container.removeEventListener('mousedown', onMouseDown)
      container.removeEventListener('mouseup', onMouseUp)
      setIsSelectingText(false)
    }
  }, [container])
  const isDebug = useIsDebugMode()

  if (currentRangedSelection === undefined && editOrResponsesSlateSelection === undefined) return null
  if (x === undefined || y === undefined) return null
  return (
    <Menu
      $isDebug={isDebug}
      animated
      style={{
        top: y,
        left: x,
      }}
      $isSelectingText={isSelectingText}
      ref={menuRef}
    >
      {mode === 'self-paced' || mode === 'live' ? (
        <SelfPacedInlineToolbar currentRangedSelection={currentRangedSelection} tutorState={tutorState} />
      ) : (
        <AnimatePresence>
          <InlineToolbarMenu
            currentRangedSelection={currentRangedSelection}
            currentEditOrResponsesSelection={editOrResponsesSlateSelection}
          />
        </AnimatePresence>
      )}
    </Menu>
  )
}

export const InlineToolbar: React.FC<{ container: HTMLElement; tutorState: TutorState }> = ({
  container,
  tutorState,
}) => {
  const mode = useEditorMode()
  const readOnly = useEditorReadOnly()
  const isEditable = !readOnly
  const focused = useFocused()

  // Show toolbar in self-paced mode or when in editable create/live mode

  switch (mode) {
    case 'template':
    case 'review':
    case 'placement-test':
    case 'recap':
    case 'version-history':
      return null
    case 'create':
    case 'self-paced':
    case 'live':
      break
    default:
      assertNever(mode)
  }

  if (isEditable) {
    // If the editor is editable, it must also be focused
    if (!focused) return null
  }

  return createPortal(
    <InlineToolbarImplementation container={container} mode={mode} tutorState={tutorState} />,
    container
  )
}
