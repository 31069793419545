import { useMutation } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useResetCurrentSessionForRoomQuery } from 'sierra-client/api/hooks/use-live-session'
import { useIsGuestUser } from 'sierra-client/api/hooks/use-user'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { Participants } from 'sierra-client/features/sana-now/post-session-page/participants'
import { Summary } from 'sierra-client/features/sana-now/post-session-page/summary'
import { TitleRow } from 'sierra-client/features/sana-now/post-session-page/title-row'
import {
  UseSessionBotDataQueryResult,
  useSessionBotDataQuery,
} from 'sierra-client/features/sana-now/post-session-page/use-session-bot-data'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { liveRoomChannel } from 'sierra-client/realtime-data/channels/live-room-channel'
import { useCachedQuery } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { LiveRoomId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategyLiveSessionGetRoomDetails } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'
import { DownloadDataButton } from './download-data-button'

const MainContentWrapper = styled.div`
  display: grid;
  column-gap: 64px;
  row-gap: 24px;
  grid-template-columns: 6fr 4fr;

  @media (max-width: ${v2_breakpoint.tablet}) {
    grid-template-columns: 1fr;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: ${token('border/default')};
`

const MainContent: FC<{
  summaryData: UseSessionBotDataQueryResult
  liveSessionId: LiveSessionId
  roomId: LiveRoomId | undefined
}> = ({ summaryData, liveSessionId }) => {
  return (
    <>
      <MainContentWrapper>
        <Summary summaryData={summaryData} />
        <Participants liveSessionId={liveSessionId} />
      </MainContentWrapper>
    </>
  )
}

const PageWrapper = styled(View)`
  --post-session-recap-page-padding: 48px;
  gap: 40px;

  @media (max-width: ${v2_breakpoint.tablet}) {
    --post-session-recap-page-padding: 16px;
    padding-top: 0;
  }
`

const ContentPadding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--post-session-recap-page-padding);
  gap: 40px;
`

const ListenToRoomChange: FC<{ roomId: LiveRoomId }> = ({ roomId }) => {
  const { t } = useTranslation()
  const [showJoinActiveSessionModal, setShowJoinActiveSessionModal] = useState(false)
  const doResetRootRoomQuery = useResetCurrentSessionForRoomQuery(roomId)
  const resetRootRoomQuery = useMutation({
    mutationFn: doResetRootRoomQuery,
  })

  const currentRoomQuery = useCachedQuery(
    XRealtimeStrategyLiveSessionGetRoomDetails,
    { roomId },
    {
      select: data => ({
        currentSessionId: data.currentSession.sessionId,
        startedAt: data.currentSession.startedAt,
        endedAt: data.currentSession.endedAt,
      }),
    }
  )

  liveRoomChannel.useChannelEvent({
    channelId: roomId,
    event: 'room-settings-updated',
    callback: () => {
      void currentRoomQuery.refetch()
    },
  })

  const sessionState = iife(() => {
    const data = currentRoomQuery.data
    if (data === undefined) return 'unknown' as const

    if (data.endedAt === undefined) {
      return 'active-session-available' as const
    }

    return 'no-active-session-available' as const
  })

  useOnChanged((prev, curr) => {
    if (prev === 'no-active-session-available' && curr === 'active-session-available') {
      setShowJoinActiveSessionModal(true)
    } else if (prev === 'active-session-available' && curr === 'no-active-session-available') {
      setShowJoinActiveSessionModal(false)
    }
  }, sessionState)

  return (
    <>
      <ActionModal
        open={showJoinActiveSessionModal}
        onClose={() => {
          setShowJoinActiveSessionModal(false)
        }}
        title={t('sana-now.post-session-page.new-session-has-started-modal--title')}
        primaryAction={async () => {
          await resetRootRoomQuery.mutateAsync()
        }}
        isLoading={resetRootRoomQuery.isPending}
        primaryActionLabel={t('sana-now.post-session-page.new-session-has-started-modal--button-text')}
      >
        {t('sana-now.post-session-page.new-session-has-started-modal--body')}
      </ActionModal>
    </>
  )
}

const Footer = (): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const { data: isGuestUser } = useIsGuestUser()
  const isFacilitator = useSelector(selectIsFacilitator)

  return (
    <>
      <View direction='row' justifyContent='flex-end'>
        {isGuestUser === false && isFacilitator === true && liveSession.data.endedAt !== undefined && (
          <DownloadDataButton />
        )}
      </View>
    </>
  )
}

export const SanaNowPostSessionRecap: FC = () => {
  const liveSession = useLiveSessionContext()
  const summaryData = useSessionBotDataQuery(liveSession.liveSessionId)

  return (
    <PageWrapper direction='column'>
      <TitleRow summaryData={summaryData} />
      <ContentPadding>
        <Divider />
        <MainContent
          summaryData={summaryData}
          liveSessionId={liveSession.liveSessionId}
          roomId={liveSession.data.roomId}
        />
        <Footer />
      </ContentPadding>
      {liveSession.data.roomId !== undefined && <ListenToRoomChange roomId={liveSession.data.roomId} />}
    </PageWrapper>
  )
}
