import React from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { ContentRow } from 'sierra-client/components/common/content-elements'
import { Table } from 'sierra-client/components/table/table'
import { useTimeFormatter } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import { CourseSummary } from 'sierra-domain/api/admin'
import { Icon } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const RemoveText = styled(Text)`
  cursor: pointer;
  padding: ${spacing['4']};

  &:hover {
    text-decoration: underline;
  }
`

const DragHandle = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin: 1rem;
  }
`

type CoursesTableProps = {
  courses: CourseSummary[]
  onChange: (updatedSelectedCourses: CourseSummary[]) => void
  openCourseModal: () => void
  readOnly?: boolean
}

export const CoursesTable: React.FC<CoursesTableProps> = ({
  courses,
  openCourseModal,
  onChange,
  readOnly,
}) => {
  const { t } = useTranslation()
  const { timeFormatter } = useTimeFormatter()

  const dotSeparator = ` ${narrowDotSeparator} `

  const columns = React.useMemo(
    () => {
      const columnsMemo: Column<CourseSummary>[] = [
        {
          accessor: 'title',
          Cell: p => {
            const { image, title, courseId, kind, timeTotal, isCourseEdition } = p.row.original
            return (
              <View
                alignItems='flex-start'
                gap='none'
                onClick={() =>
                  void getGlobalRouter().navigate({
                    to:
                      kind === 'native:course-group' || kind === 'scorm:course-group'
                        ? `/manage/course-group/${courseId}`
                        : `/manage/courses/${courseId}`,
                  })
                }
              >
                <ContentRow
                  assetContext={{ type: 'course', courseId }}
                  contentType='course'
                  courseKind={kind}
                  title={title}
                  image={image}
                  isCourseEdition={isCourseEdition}
                  pillAddon={
                    <>
                      <Text size='small' color='grey40'>
                        {dotSeparator}
                      </Text>
                      <Text size='small' color='grey40'>
                        {timeFormatter(timeTotal)}
                      </Text>
                      {readOnly !== true && (
                        <>
                          <Text size='small' color='grey40'>
                            {dotSeparator}
                          </Text>
                          <RemoveText
                            size='small'
                            color='grey40'
                            onClick={event => {
                              event.stopPropagation()
                              onChange(courses.filter(c => c.courseId !== courseId))
                            }}
                          >
                            {t('admin.remove')}
                          </RemoveText>
                        </>
                      )}
                    </>
                  }
                />
              </View>
            )
          },
          width: '95%',
        },
        {
          accessor: 'courseId',
          Cell: (props: CellProps<CourseSummary>) => {
            if (readOnly === true) return null

            return (
              <DragHandle {...props.dragHandleProps} aria-label='move'>
                <Icon iconId='draggable' size='size-16' />
              </DragHandle>
            )
          },
          width: '5%',
        },
      ]
      return columnsMemo
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onChange, courses, timeFormatter, readOnly]
  )

  const handleDrop = (sourceIndex: number, destinationIndex: number): void => {
    const updatedCourses = [...courses]
    const [movedCourse] = updatedCourses.splice(sourceIndex, 1)

    if (movedCourse !== undefined) {
      updatedCourses.splice(destinationIndex, 0, movedCourse)

      onChange(updatedCourses)
    }
  }

  const tableInstance = useTable<CourseSummary>({
    data: courses,
    columns,
  })

  if (tableInstance.rows.length === 0) {
    return (
      <EmptySection>
        <Icon iconId='add' size='size-16' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.paths.no-courses.title')}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center' color='foreground/secondary'>
          {t('manage.paths.no-courses.body')}
        </EmptyBody>
        <Spacer size='small' />
        <Button variant='ghost' onClick={openCourseModal}>
          {t('dictionary.add')}
        </Button>
      </EmptySection>
    )
  } else {
    return (
      <>
        <Table<CourseSummary>
          blockDrag={readOnly !== true}
          tableInstance={tableInstance}
          onDrop={
            readOnly !== true
              ? dropResult => {
                  if (!dropResult.destination) return
                  handleDrop(dropResult.source.index, dropResult.destination.index)
                }
              : undefined
          }
          getTHeadProps={() => ({
            style: {
              display: 'none',
            },
          })}
        />
        <Spacer size='small' />

        <Button onClick={openCourseModal} disabled={readOnly}>
          {t('manage.manage-courses')}
        </Button>
      </>
    )
  }
}
