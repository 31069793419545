import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentLabel } from 'sierra-ui/missions/foundation'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

type OverviewHeaderProps = {
  title: string
  titleTag?: JSX.Element
  image?: string
  isRequiredAssignment: boolean
}

const ImageContainer = styled.div<{ $largeMobilePadding?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 22rem;
  padding-inline: 100px;
  border-radius: 24px;
  margin: 48px;
  margin-bottom: 0;
  overflow: hidden;

  .overlay {
    position: absolute;
    inset: 0;
    background-color: black;
    opacity: 0.4;
    z-index: -1;
    border-radius: 24px;
  }

  @media screen and (max-width: ${v2_breakpoint_int.desktop_small}px) {
    margin: 0;
    border-radius: 0;

    .overlay {
      border-radius: 0;
    }
  }

  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    justify-content: flex-end;
    padding: 100px 24px 150px 24px;
    margin-top: 0;
  }

  @media screen and (min-width: ${v2_breakpoint_int.desktop_small}px) and (max-width: ${v2_breakpoint_int.desktop}px) {
    padding-inline: 48px;
  }
`

const HeadingContent = styled.div`
  width: 100%;

  @media screen and (min-width: ${v2_breakpoint_int.desktop_small}px) {
    width: 55%;
  }

  @media screen and (min-width: ${v2_breakpoint_int.desktop}px) {
    width: 65%;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

const OverviewTitleTag = styled(Text).attrs({
  bold: true,
  color: 'white',
})``

const OverviewHeading = styled(Heading).attrs({
  size: 'h1',
  bold: true,
  color: 'white',
})``

export const OverviewHeader: React.FC<OverviewHeaderProps> = ({
  title,
  titleTag, // = <LinkedInLearningChip variant='white' />,
  image,
  isRequiredAssignment,
}) => {
  const { t } = useTranslation()

  return (
    <ImageContainer>
      {image !== undefined && image !== '' && <Image src={image} loading='lazy' />}
      <div className='overlay'></div>

      <HeadingContent>
        <View>
          {isRequiredAssignment === true && getFlag('required-assignments') && (
            <ContentLabel iconId='arrow-up--filled' label={t('assignments.required')} highPriority={true} />
          )}
          {titleTag !== undefined && (
            <>
              {typeof titleTag === 'string' ? (
                <OverviewTitleTag size='regular'>{titleTag}</OverviewTitleTag>
              ) : (
                titleTag
              )}
            </>
          )}
        </View>
        <OverviewHeading>{title}</OverviewHeading>
      </HeadingContent>
    </ImageContainer>
  )
}
