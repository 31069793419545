import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { useAblyYDocWithAwareness } from 'sierra-client/collaboration/use-ably-ydoc'
import { DebugJsonView } from 'sierra-client/components/common/debug-json-view'
import { requireDeveloperToolsEnabled } from 'sierra-client/core/require-developer-tools-enabled'
import { serialize } from 'sierra-domain/collaboration/serialization/serialize'
import { ScopedYDocId } from 'sierra-domain/collaboration/types'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'
import { z } from 'zod'

const Pre = styled.pre`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: monospace;
  overflow: auto;
`

const PreData = styled(Pre)`
  right: 50%;
  border-right: 1px solid #ccc;
`

const PreAwareness = styled(Pre)`
  left: 50%;
`

declare let window: Window & {
  Y?: unknown
  yDoc?: Y.Doc
}

type ProviderData = { yData: unknown; awarenessStates: unknown }

const LoadedPageAbly: React.FC<{ yDoc: Y.Doc; awareness: Awareness }> = ({ yDoc, awareness }) => {
  const [data, setData] = useState<ProviderData>({ yData: 'loading', awarenessStates: 'loading' })

  useEffect(() => {
    window.Y = Y
    window.yDoc = yDoc
  }, [yDoc])

  useEffect(() => {
    function serializeAndSet(update: Uint8Array | undefined): void {
      if (update) console.debug('[Updated document]', [...Y.decodeStateVector(update).values()].join(', '))
      setData(data => ({ ...data, yData: serialize(yDoc) }))
    }

    serializeAndSet(undefined)
    yDoc.on('update', serializeAndSet)
    return () => yDoc.off('update', serializeAndSet)
  }, [yDoc])

  useEffect(() => {
    function setAwarenessStates(): void {
      console.debug('[Awarness changed]')
      const awarenessStates: unknown = Object.fromEntries(awareness.getStates())
      setData(data => ({ ...data, awarenessStates }))
    }

    setAwarenessStates()
    awareness.on('change', setAwarenessStates)
    return () => awareness.off('change', setAwarenessStates)
  }, [awareness])

  const { yData, awarenessStates } = data
  return (
    <>
      {yData === 'loading' ? (
        <View>Loading yData</View>
      ) : (
        <PreData>
          <DebugJsonView src={yData as object} />
        </PreData>
      )}
      {awarenessStates === 'loading' ? (
        <View>Loading awarenessStates</View>
      ) : (
        <PreAwareness>
          <DebugJsonView src={awarenessStates as object} />
        </PreAwareness>
      )}
    </>
  )
}

function Page(): JSX.Element | null {
  const documentId = Route.useSearch({ select: search => search.documentId })
  const context = useAblyYDocWithAwareness(ScopedYDocId.parse(documentId))

  if (context === undefined) return null
  else return <LoadedPageAbly yDoc={context.yDoc} awareness={context.awareness} />
}

export const Route = createFileRoute('/debug/collaboration')({
  component: requireDeveloperToolsEnabled(Page) as React.FC,
  validateSearch: z.object({
    documentId: z.string(),
  }),
})
