import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { RouterLink } from 'sierra-client/components/common/link'
import { ProgressLine } from 'sierra-client/components/common/progress-line'
import { TagsBasic } from 'sierra-client/components/common/tags-basic'
import { UsersToNotifyText } from 'sierra-client/components/homework/users-to-notify-text'
import { getFlag } from 'sierra-client/config/global-config'
import { useScormContext } from 'sierra-client/context/scorm-context'
import {
  useGetDaysLeft,
  useGetFormattedTime,
  useLiveSessionDateTimeFormatter,
} from 'sierra-client/core/format'
import { useContentKindPermissions, useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  selectCurrentCourseTheme,
  selectCurrentLinkCourse,
  selectCurrentPath,
  selectPrependUrlWithProgramAndPath,
} from 'sierra-client/state/content/selectors'
import { CourseEntity } from 'sierra-client/state/content/types'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectCurrentCoursePassedTimestamp,
  selectCurrentCourseProgressTimestamp,
  selectCurrentExercises,
  selectPassedTimestamps,
  selectProgressTimestamps,
} from 'sierra-client/state/v2/selectors'
import { selectDueDate } from 'sierra-client/state/v2/sidebar-selector'
import { OverviewDetails, OverviewGrid } from 'sierra-client/views/learner/components/overview/common'
import { EllipsedText } from 'sierra-client/views/learner/components/overview/ellipsed-text'
import {
  CourseGroupButtons,
  CourseHeaderButtons,
  ExerciseButton,
} from 'sierra-client/views/learner/course/components/buttons'
import { EditionPageLink } from 'sierra-client/views/learner/course/edition-page/utils'
import { useContentAttributesForCourse } from 'sierra-client/views/manage/content-attributes/hooks'
import { LearnerAttributesRenderer } from 'sierra-client/views/manage/content-attributes/renderers'
import { Separator } from 'sierra-client/views/showcase/common'
import { SmartCards } from 'sierra-client/views/showcase/sections/lil-stack'
import { ReviewComment } from 'sierra-client/views/v3-author/homework/homework-upload/review-comment'
import { EditionWithStatus, QueryCourseLiveResponse } from 'sierra-domain/api/content-v2'
import { editUrlFromScopedId } from 'sierra-domain/api/editable-content'
import { HomeworkState } from 'sierra-domain/api/homework'
import { CourseId, FileId, LiveContentId, TagId } from 'sierra-domain/api/nano-id'
import { ScopedLiveContentId } from 'sierra-domain/collaboration/types'
import { SelfEnrollSession } from 'sierra-domain/content/session'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import {
  asNonNullable,
  assertNever,
  ExtractFrom,
  iife,
  isDefined,
  isNonEmptyArray,
  isNonEmptyJsonString,
  isNonEmptyString,
  STATIC_EMPTY_ARRAY,
} from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { getTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

type CourseSummaryProps = {
  courseId: CourseId
  courseType: CourseEntity['type']
  description?: string
  tagIds: TagId[]
  timeLeft?: number
  timeTotal?: number

  // Only for live course (refactor?)
  scrollToSelfEnrollSessions?: () => void
  availableSelfEnrollSessions?: SelfEnrollSession[]
  upcomingAssignedSessions?: QueryCourseLiveResponse['upcomingAssignedSessions']

  // Only for course group (refactor??)
  availableEditions?: EditionWithStatus[]
  selectedEdition?: EditionWithStatus | undefined
  setSelectedEdition?: (edition: EditionWithStatus | undefined) => void
}

const CourseOutline = styled.div`
  margin-bottom: ${spacing['80']};
  margin-top: ${spacing['48']};
  grid-area: content;
`

const PartOfContainer = styled.div`
  margin-top: -1.5rem;
`

const Details = styled.div`
  padding: 1.5rem 1.5rem 1rem;
`

const FixedButtons = styled.div<{ $reveal: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  transform: ${p => (p.$reveal ? `translateY(0)` : 'translateY(101%)')};
  transition: transform 0.3s ease-out;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    transform: translateY(101%);
  }
`

const DaysLeft: React.FC = () => {
  const dueDate = useSelector(selectDueDate)
  const { daysLeft } = useGetDaysLeft(dueDate)

  if (!isDefined(daysLeft)) return null
  else
    return (
      <>
        <Spacer size='4' />
        <IconListItem iconId='event--schedule' text={daysLeft} />
      </>
    )
}

const LinkCourse: React.FC = () => {
  const { t } = useTranslation()
  const course = useSelector(selectCurrentLinkCourse)

  if (!isDefined(course)) return null
  else
    return (
      <>
        <Spacer size='4' />
        <IconListItem iconId='link' text={t('dictionary.external-course')} />
      </>
    )
}

const CourseDetailsBlock: React.FC<{
  timeLeft: number | undefined
  timeTotal: number | undefined
  passedTimestamp: string | undefined
}> = ({ timeLeft, timeTotal, passedTimestamp }) => {
  const { t } = useTranslation()

  let progress = 0
  if (passedTimestamp !== undefined) {
    progress = 1
  } else if (timeLeft !== undefined && timeTotal !== undefined) {
    progress = 1 - timeLeft / timeTotal
  }

  const formattedTime = useGetFormattedTime(timeLeft, progress > 0)

  return (
    <>
      {formattedTime !== undefined && (
        <>
          <Text size='regular' bold>
            {t('course-overview.details')}
          </Text>
          <Spacer size='small' />
          <ProgressLine $progress={progress} />
          <Spacer size='xsmall' />
          <IconListItem
            color='foreground/primary'
            iconId={progress === 1 ? 'checkmark' : 'time'}
            text={progress === 1 ? t('course-overview.course-completed') : formattedTime}
          />
        </>
      )}
      {!isDefined(passedTimestamp) && <DaysLeft />}
      <LinkCourse />
    </>
  )
}

const EditCourseButton: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const canEdit = useContentKindPermissions(courseId).has('EDIT_CONTENT')
  const editorUrl = editUrlFromScopedId(ScopedLiveContentId.fromId(courseId as LiveContentId))

  if (canEdit) {
    return (
      <Button href={editorUrl} variant='secondary' icon='edit'>
        {t('author.edit.edit-content')}
      </Button>
    )
  } else {
    return null
  }
}

// Used to merge `upcomingAssignedSessions` and `availableSelfEnrollSessions`.
// Should have keys that both of those have.
type BasicSession = Pick<SelfEnrollSession, 'startTime' | 'endTime' | 'sessionId' | 'allDay'>

const LiveCourseDetailsBlock: React.FC<{
  courseId: CourseId
  scrollToSelfEnrollSessions: CourseSummaryProps['scrollToSelfEnrollSessions']
  availableSelfEnrollSessions: CourseSummaryProps['availableSelfEnrollSessions']
  upcomingAssignedSessions: NonNullable<CourseSummaryProps['upcomingAssignedSessions']>
}> = ({ scrollToSelfEnrollSessions, availableSelfEnrollSessions, upcomingAssignedSessions, courseId }) => {
  const { t } = useTranslation()
  const formatLiveSessionTime = useLiveSessionDateTimeFormatter()

  // TODO: Refactor
  const upcomingAssignedSessionsMerged: BasicSession[] = useMemo(() => {
    const selfEnrollIds = new Set((availableSelfEnrollSessions ?? []).map(x => x.sessionId))

    // Self-enroll sessions will have more recent data, so keep those
    const upcomingAssignedSessionsMapped: BasicSession[] = _.chain(upcomingAssignedSessions)
      .pickBy((_value, key) => !selfEnrollIds.has(key))
      .toPairs()
      .map(([key, value]) => ({
        sessionId: key,
        startTime: value.startTime,
        endTime: value.endTime,
        allDay: value.allDay,
      }))
      .value()

    return [
      ...upcomingAssignedSessionsMapped,
      ...(availableSelfEnrollSessions ?? []).filter(x => x.isEnrolled),
    ].sort((a, b) => a.startTime.localeCompare(b.startTime))
  }, [upcomingAssignedSessions, availableSelfEnrollSessions])

  const sessionText = useMemo(() => {
    const numAssigned = upcomingAssignedSessionsMerged.length
    const numAvailable = availableSelfEnrollSessions?.length ?? 0

    if (numAssigned === 0) {
      if (numAvailable > 1) {
        return (
          <IconListItem
            color='foreground/primary'
            iconId='play--circle--outline'
            text='Multiple dates available'
          />
        )
      } else {
        return (
          <IconListItem
            color='foreground/primary'
            iconId='play--circle--outline'
            text='No assigned sessions'
          />
        )
      }
    } else if (numAssigned === 1) {
      const firstSession = asNonNullable(upcomingAssignedSessionsMerged[0])

      const dateTimeString = formatLiveSessionTime({
        startTime: firstSession.startTime,
        endTime: firstSession.endTime,
        allDay: firstSession.allDay,
      })

      return <IconListItem iconId='play--circle--filled' text={dateTimeString} />
    } else {
      return <IconListItem iconId='play--circle--filled' text='Multiple assigned sessions' />
    }
  }, [availableSelfEnrollSessions, upcomingAssignedSessionsMerged, formatLiveSessionTime])

  const button = useMemo(() => {
    const firstAssigned = upcomingAssignedSessionsMerged[0]

    if (firstAssigned === undefined) {
      const numAvailable = availableSelfEnrollSessions?.length ?? 0

      if (numAvailable > 0 && scrollToSelfEnrollSessions !== undefined) {
        return <Button onClick={scrollToSelfEnrollSessions}>{t('share.view-sessions')}</Button>
      }
      return null
    }

    const sessionUrl = `/l/${firstAssigned.sessionId}`

    return (
      <RouterLink href={sessionUrl}>
        <Button>{t('dictionary.join')}</Button>
      </RouterLink>
    )
  }, [upcomingAssignedSessionsMerged, t, availableSelfEnrollSessions?.length, scrollToSelfEnrollSessions])

  return (
    <>
      <Text size='regular' bold>
        {t('course-overview.details')}
      </Text>
      <Separator top='small' bottom='xsmall' />
      {sessionText}
      <Spacer size='large' />
      <View>
        {button}
        <EditCourseButton courseId={courseId} />
      </View>
    </>
  )
}

const CourseGroupButton: React.FC<{
  availableEditions: CourseSummaryProps['availableEditions']
  setSelectedEdition: NonNullable<CourseSummaryProps['setSelectedEdition']>
  selectedEdition: NonNullable<CourseSummaryProps['selectedEdition']>
  courseType: ExtractFrom<CourseEntity['type'], 'native:course-group' | 'scorm:course-group'>
}> = ({ availableEditions, setSelectedEdition, selectedEdition, courseType }) => {
  const { t, dynamicT } = useTranslation()
  const isLanguageBasedCourseGroup = iife(() => {
    if (!isNonEmptyArray(availableEditions)) {
      return false
    } else {
      const languages = availableEditions.map(edition => edition.data.language)
      const uniqueLanguages = new Set(languages)

      return uniqueLanguages.size === languages.length
    }
  })

  const editionOptions = useMemo<Array<EditionMenuItem & { language?: string }>>(() => {
    return (
      availableEditions?.map(edition => ({
        id: edition.id,
        type: 'label' as const,
        label: isLanguageBasedCourseGroup
          ? dynamicT(`language.${edition.data.language}`)
          : edition.data.title,
        value: edition,
        language: edition.data.language,
        description: isLanguageBasedCourseGroup ? edition.data.title : undefined,
      })) ?? STATIC_EMPTY_ARRAY
    )
  }, [availableEditions, isLanguageBasedCourseGroup, dynamicT])

  const selectedItem = editionOptions.find(item => selectedEdition.id === item.id)
  return (
    <View direction='column' gap='small'>
      <View direction='column' gap='xsmall'>
        <View gap='6'>
          {isLanguageBasedCourseGroup ? (
            <>
              <Icon size='size-16' color='foreground/muted' iconId='translate' />

              <Text color='foreground/muted' capitalize='first'>
                {t('dictionary.multiple-languages')}
              </Text>
            </>
          ) : (
            <>
              <Icon size='size-16' color='foreground/muted' iconId='bookmark--stack--outlined' />
              <Text color='foreground/muted' capitalize='first'>
                {t('dictionary.multiple-editions')}
              </Text>
            </>
          )}
        </View>
        <SingleSelectDropdown
          onSelect={(item: EditionMenuItem) => {
            setSelectedEdition(item.value)
          }}
          menuItems={editionOptions}
          selectedItem={selectedItem}
        />
      </View>
      <CourseGroupButtons courseId={selectedEdition.id} courseGroupType={courseType} />
    </View>
  )
}

const FailedProgressLine = styled(ProgressLine)`
  min-height: ${p => (p.$large === true ? '4px' : '2px')};
  ${p =>
    p.$progress > 0
      ? `background: linear-gradient(90deg, ${token('org/primary')(p)} ${p.$progress * 100}%, ${token(
          'org/primary'
        )(p)} ${p.$progress * 100}%, ${token('border/default')(p)} ${p.$progress * 100}%, ${token(
          'border/default'
        )(p)} 100%);`
      : `background-color: ${token('border/default')(p)}`}
`

type ActiveHomework = Omit<HomeworkState, 'grade'> & {
  grade: 'failed' | 'not-graded' | 'failed-with-no-retries'
}

const isActiveHomework = (homework: HomeworkState | undefined): homework is ActiveHomework => {
  if (homework === undefined) return false

  switch (homework.grade) {
    case 'not-graded':
    case 'failed':
    case 'failed-with-no-retries':
      return true
    case 'not-submitted':
    case 'passed':
    case 'dismissed':
      return false
    default:
      assertNever(homework.grade)
  }
}

const ExerciseGradeBlock: React.FC<{ homework: ActiveHomework }> = ({ homework }) => {
  const { t } = useTranslation()

  switch (homework.grade) {
    case 'not-graded':
      return (
        <View>
          <Icon iconId='time' color='foreground/secondary' size='size-16' />
          <Text size='small' color='foreground/secondary'>
            {t('my-library.items.homework.not-graded')}
          </Text>
        </View>
      )
    case 'failed':
    case 'failed-with-no-retries':
      return (
        <View>
          <Icon iconId='close--circle' color='foreground/secondary' size='size-16' />
          <Text size='small' bold color='foreground/secondary'>
            {t('homework.not-passed')}
          </Text>
        </View>
      )
    default:
      assertNever(homework.grade)
  }
}

const ExerciseDetailBlock: React.FC<{
  activeHomework: ActiveHomework
  timeLeft: number | undefined
  timeTotal: number | undefined
  passedTimestamp: string | undefined
  courseId: CourseId
}> = ({ activeHomework, passedTimestamp, timeLeft, timeTotal, courseId }) => {
  const { t } = useTranslation()

  let progress = 0
  if (passedTimestamp !== undefined) {
    progress = 1
  } else if (timeLeft !== undefined && timeTotal !== undefined) {
    progress = 1 - timeLeft / timeTotal
  }

  return (
    <View direction='column' gap='24'>
      <Text size='regular' bold>
        {t('course-overview.details')}
      </Text>
      <View direction='column' gap='16'>
        <FailedProgressLine $progress={progress} $large />
        <ExerciseGradeBlock homework={activeHomework} />
        {isNonEmptyJsonString(activeHomework.feedback) && (
          <View direction='column'>
            <ReviewComment
              feedback={activeHomework.feedback}
              reviewerId={activeHomework.reviewerId}
              maxHeight={100}
              readMoreUrl={`/s/${courseId}/file:${activeHomework.fileId}`}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const HardFailExerciseButtons: React.FC<{ courseId: CourseId }> = ({ courseId }) => {
  const { t } = useTranslation()
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)
  const url = prependUrlWithProgramAndPath(`/s/${courseId}/next`)

  return (
    <View grow>
      <Button grow variant='secondary' onClick={() => void getGlobalRouter().navigate({ to: url })}>
        {t('dictionary.review')}
      </Button>
    </View>
  )
}

const ExerciseButtons: React.FC<{ fileId: FileId; courseId: CourseId }> = ({ fileId, courseId }) => {
  const href = `/s/${courseId}/file:${fileId}`
  return (
    <View direction='column' gap='small'>
      <ExerciseButton href={href} />
    </View>
  )
}

type EditionMenuItem = MenuItem<EditionWithStatus['id']> & {
  value?: EditionWithStatus
}

// TODO(course-editions): This could be easier to work with if we split it up instead of making it polymorphic.
export const CourseSummary: React.FC<React.PropsWithChildren<CourseSummaryProps>> = ({
  courseId,
  courseType,
  description,
  tagIds,
  children,
  timeLeft,
  timeTotal,
  scrollToSelfEnrollSessions,
  availableSelfEnrollSessions,
  upcomingAssignedSessions,
  availableEditions,
  selectedEdition,
  setSelectedEdition,
}) => {
  const { t } = useTranslation()
  const showCompletionWarning =
    !useHasContentKindPermission(courseId, 'LEARN_MARK_AS_COMPLETED') &&
    timeLeft !== 0 &&
    courseType === 'link'
  const detailRef = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [negativeMargin, setNegativeMargin] = useState(0)
  const [revealButton, setRevealButton] = useState(false)
  const currentPath = useSelector(selectCurrentPath)
  const passedTimestamp = useSelector(selectCurrentCoursePassedTimestamp)
  const progressTimestamp = useSelector(selectCurrentCourseProgressTimestamp)
  const themeContext = useContext(ThemeContext)
  const courseTheme = useSelector(selectCurrentCourseTheme)
  const presetTheme = getTheme(themeContext, courseTheme?.name ?? 'white')
  const { scormCourseGroupId } = useScormContext()
  const currentExercises = useSelector(selectCurrentExercises) ?? STATIC_EMPTY_ARRAY
  const failedExercises = currentExercises.filter(exercise => exercise.grade === 'failed')
  const hardFailedExercises = currentExercises.filter(exercise => exercise.grade === 'failed-with-no-retries')
  const selectedHomework = getPrioritizedHomework(currentExercises)
  const { attributes } = useContentAttributesForCourse(courseId)
  const contentAttributesEnabled = getFlag('content-attributes')

  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)

  const selfpacedprogress = useSelector(selectProgressTimestamps)
  const selfpacedpassed = useSelector(selectPassedTimestamps)

  const started = progressTimestamp !== undefined || selfpacedprogress[courseId] !== undefined
  const completed = passedTimestamp !== undefined || selfpacedpassed[courseId] !== undefined

  const updateMarginAndOffset = useCallback((): void => {
    if (detailRef.current) {
      const height = detailRef.current.clientHeight / 2 + 112
      setOffsetHeight(height)
    }

    // Calulcate to see if inbetween smallest and largest hero height
    const heightSpan = _.clamp(window.innerWidth * 0.47, 576, 736)
    setNegativeMargin(heightSpan / 2 + 32)
  }, [])

  useEffect(() => {
    /**
     * NOTE: This fixes a problem we had with measurements not being calculated after the async data was coming in
     */
    updateMarginAndOffset()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEdition])

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return

    updateMarginAndOffset()
    window.addEventListener('resize', updateMarginAndOffset)

    return () => window.removeEventListener('resize', updateMarginAndOffset)
  }, [detailRef, setOffsetHeight, setNegativeMargin, updateMarginAndOffset])

  // This is for legacy SCORM-only course editions.
  const switchEditionButton = scormCourseGroupId !== undefined && (
    // We don't translate this string because we want to make it easier for the user to find the link
    // if they accidentally chose the wrong language.
    <EditionPageLink>
      <Button variant='secondary'>Change language</Button>
    </EditionPageLink>
  )

  const showHomeworkFeedback = isActiveHomework(selectedHomework) && !completed

  return (
    <>
      <SmartCards />
      <OverviewGrid>
        <OverviewDetails
          ref={detailRef}
          $offsetHeight={offsetHeight}
          $negativeMargin={negativeMargin}
          $largeMobileMargin={true}
        >
          <Details>
            {iife(() => {
              if (showHomeworkFeedback) {
                return (
                  <>
                    <ExerciseDetailBlock
                      activeHomework={selectedHomework}
                      timeLeft={timeLeft}
                      timeTotal={timeTotal}
                      passedTimestamp={passedTimestamp}
                      courseId={courseId}
                    />
                    <Spacer size='small' />
                  </>
                )
              }
              if (isDefined(upcomingAssignedSessions)) {
                return (
                  <LiveCourseDetailsBlock
                    courseId={courseId}
                    scrollToSelfEnrollSessions={scrollToSelfEnrollSessions}
                    availableSelfEnrollSessions={availableSelfEnrollSessions}
                    upcomingAssignedSessions={upcomingAssignedSessions}
                  />
                )
              }
              return (
                <>
                  <CourseDetailsBlock
                    timeLeft={timeLeft}
                    timeTotal={timeTotal}
                    passedTimestamp={passedTimestamp}
                  />
                  <Spacer size='xsmall' phone='xsmall' />
                </>
              )
            })}
            <InView as='div' onChange={InView => setRevealButton(!InView)}>
              <ThemeProvider theme={presetTheme}>
                {iife(() => {
                  if (showHomeworkFeedback) {
                    if (isNonEmptyArray(hardFailedExercises)) {
                      return <HardFailExerciseButtons courseId={courseId} />
                    }
                    if (isNonEmptyArray(failedExercises)) {
                      return <ExerciseButtons fileId={failedExercises[0].fileId} courseId={courseId} />
                    }
                  }
                  if (
                    selectedEdition !== undefined &&
                    setSelectedEdition !== undefined &&
                    (courseType === 'native:course-group' || courseType === 'scorm:course-group')
                  ) {
                    return (
                      <CourseGroupButton
                        availableEditions={availableEditions}
                        courseType={courseType}
                        selectedEdition={selectedEdition}
                        setSelectedEdition={setSelectedEdition}
                      />
                    )
                  }
                  return (
                    <CourseHeaderButtons
                      courseStarted={started}
                      courseCompleted={completed}
                      courseId={courseId}
                    />
                  )
                })}
              </ThemeProvider>
            </InView>
            <>
              <Spacer size='xxsmall' phone='xxsmall' />
              {iife(() => {
                if (!showHomeworkFeedback) {
                  return null
                }
                if ((selectedHomework.remainingSubmissions ?? 0) > 0) {
                  return (
                    <View justifyContent='center'>
                      <Text size='micro' color='foreground/muted'>
                        {t('homework.n-attempts-left', { count: selectedHomework.remainingSubmissions })}
                      </Text>
                    </View>
                  )
                }
                if (selectedHomework.grade === 'failed-with-no-retries') {
                  return <UsersToNotifyText centerAligned />
                }
              })}
            </>
            {showCompletionWarning && (
              <>
                <Spacer size='xxsmall' phone='xxsmall' />
                <Text align={'center'} size='small' color='foreground/secondary'>
                  {t('course-overview.link-wait-completion-line1')}
                </Text>
                <Text align={'center'} bold size='small' color='foreground/secondary'>
                  {t('course-overview.link-wait-completion-line2')}
                </Text>
              </>
            )}
            {tagIds.length > 0 && (
              <>
                <Separator top='xsmall' bottom='xsmall' />
                <Text size='regular' bold>
                  {t('course-overview.skills')}
                </Text>
                <Spacer size='xxsmall' axis='vertical' />
                <TagsBasic tagIds={tagIds} />
                <Spacer size='xxsmall' axis='vertical' />
              </>
            )}
            {switchEditionButton !== false && (
              <>
                <Separator top='small' bottom='xsmall' />
                <View gap='none' justifyContent='space-between'>
                  {switchEditionButton}
                </View>
              </>
            )}
          </Details>
        </OverviewDetails>

        <CourseOutline>
          {currentPath !== undefined && (
            <PartOfContainer>
              <View gap='none' alignItems='flex-start'>
                <Text size='regular' color='foreground/muted'>
                  {t('course-overview.part-of')}
                </Text>
                <Spacer size='4' />
                <RouterLink href={prependUrlWithProgramAndPath('/')}>
                  <Text size='regular' bold>
                    {currentPath.path.title}
                  </Text>
                </RouterLink>
              </View>
              <Separator top='xsmall' bottom='medium' />
            </PartOfContainer>
          )}
          {isNonEmptyString(description) && (
            <>
              <Text bold size='small' color='foreground/secondary'>
                {t('course-overview.description.title')}
              </Text>
              <EllipsedText
                color='foreground/primary'
                text={description}
                textSize='large'
                minimizeText={t('course-overview.description-show-less')}
                expandText={t('course-overview.description-show-more')}
              />
              <Spacer size='medium' phone='xlarge' />
            </>
          )}
          {children}
          {contentAttributesEnabled && isDefined(attributes) && (
            <>
              <Separator top='none' bottom='32' />
              <LearnerAttributesRenderer attributes={attributes} />
            </>
          )}
        </CourseOutline>
      </OverviewGrid>
      <FixedButtons $reveal={revealButton}>
        <ThemeProvider theme={presetTheme}>
          <CourseHeaderButtons courseStarted={started} courseCompleted={completed} courseId={courseId} />
        </ThemeProvider>
      </FixedButtons>
    </>
  )
}
