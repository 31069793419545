import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  AttributeOptions,
  AttributeOptionsSingleRow,
} from 'sierra-client/views/manage/content-attributes/common'
import { ContentAttributeWithValue } from 'sierra-client/views/manage/content-attributes/types'
import { Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const LearnerAttributeRenderer: React.FC<{ attribute: ContentAttributeWithValue }> = ({ attribute }) => {
  switch (attribute.type) {
    case 'number':
      return <Text bold>{attribute.value}</Text>
    case 'text':
      return <Text bold>{attribute.value}</Text>
    case 'date':
      return <Text bold>{attribute.value}</Text>
    case 'categories':
    case 'select':
      return <AttributeOptions options={[attribute.value]} />
  }
}

const LearnerAttributes = styled(View)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const LearnerAttributesRenderer: React.FC<{ attributes: ContentAttributeWithValue[] }> = ({
  attributes,
}) => {
  return (
    <LearnerAttributes>
      {attributes
        .filter(attribute => attribute.learnerVisible)
        .map(attribute => (
          <View key={`${attribute.name}_container`} direction='column'>
            <Text bold color='foreground/muted'>
              {attribute.name}
            </Text>
            <LearnerAttributeRenderer attribute={attribute} />
          </View>
        ))}
    </LearnerAttributes>
  )
}

const ManageAttributeRenderer: React.FC<{ attribute: ContentAttributeWithValue }> = ({ attribute }) => {
  switch (attribute.type) {
    case 'number':
      return (
        <Text bold color='foreground/secondary'>
          {attribute.value}
        </Text>
      )
    case 'text':
      return (
        <Text bold color='foreground/secondary'>
          {attribute.value}
        </Text>
      )
    case 'date':
      return (
        <Text bold color='foreground/secondary'>
          {attribute.value}
        </Text>
      )
    case 'categories':
    case 'select':
      return <AttributeOptionsSingleRow options={[attribute.value]} />
  }
}

const AttributeTitle = styled(Text)`
  flex-shrink: 0;
`

export const ManageAttributesRenderer: React.FC<{ attributes: ContentAttributeWithValue[] }> = ({
  attributes,
}) => {
  const { t } = useTranslation()

  return (
    <View direction='column' gap='12'>
      <Text bold size='regular'>
        {t('dictionary.attributes')}
      </Text>
      <View direction='column'>
        {attributes.map(attribute => (
          <View key={`${attribute.name}_container`} justifyContent='space-between'>
            <AttributeTitle color='foreground/secondary'>{attribute.name}</AttributeTitle>
            <ManageAttributeRenderer attribute={attribute} />
          </View>
        ))}
      </View>
    </View>
  )
}
