import { MicrosoftCalendarAccessResult } from 'sierra-client/api/graphql/gql/graphql'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${token('destructive/background').opacity(0.06)};
  color: ${token('destructive/background')};
  border-radius: 10px;
  padding: 12px;
`

const IconContainer = styled.div`
  display: flex;
  place-content: center;
  background-color: white;
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  border-radius: 8px;
`

export const MicrosoftCalendarError: React.FC<{
  accessResult: Exclude<MicrosoftCalendarAccessResult, 'HasAccess'>
}> = ({ accessResult }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <IconContainer>
        <Icon iconId='warning--filled' size='size-20' color='currentColor' />
      </IconContainer>
      {iife(() => {
        switch (accessResult) {
          case 'Error':
            return (
              <Text size='small' color='currentColor'>
                {t('admin.author.calendar-integration.microsoft-permission-error')}
              </Text>
            )
          case 'InsufficientAccess':
            return (
              <Text size='small' color='currentColor'>
                {t('admin.author.calendar-integration.microsoft-insufficient-access')}
              </Text>
            )
          default:
            assertNever(accessResult)
        }
      })}
    </Container>
  )
}
