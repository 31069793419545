import React, { useState } from 'react'
import { RecurrenceInput } from 'sierra-client/components/recurrent-assignments/recurrence-input'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Interval } from 'sierra-domain/api/manage'
import { Modal } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

interface RecurrencePeriodDialogProps {
  open: boolean
  value?: Interval | undefined
  onSave(recurrencePeriod: Interval): void
  onClear(): void
  onClose(): void
}

const DEFAULT_INTERVAL: Interval = { value: 1, unit: 'years' }

export const RecurrencePeriodDialog: React.FC<RecurrencePeriodDialogProps> = ({
  open,
  value,
  onSave,
  onClear,
  onClose,
}) => {
  const { t } = useTranslation()
  const [interval, setInterval] = useState<Interval>(value ?? DEFAULT_INTERVAL)

  return (
    <Modal open={open} onClose={onClose} size={{ width: 444 }}>
      <View direction='column' margin='small' gap='none'>
        <Text size='large' bold>
          {t('recurrence.modal.heading')}
        </Text>
        <Text size='regular'>{t('recurrence.modal.description')}</Text>
        <Spacer size='medium' />
        <RecurrenceInput value={interval} onIntervalChange={setInterval} />
        <Spacer size='small' />
        <View direction='row' gap='none' justifyContent='space-between'>
          <View margin='none' direction='row' gap='none' justifyContent='flex-start' alignItems='flex-end'>
            <Button
              icon='clean'
              disabled={value === undefined}
              variant='destructive'
              onClick={() => {
                onClear()
                onClose()
              }}
            >
              {t('dictionary.clear')}
            </Button>
          </View>
          <View margin='none' direction='row' gap='none' justifyContent='flex-end' alignItems='flex-end'>
            <Button variant='secondary' onClick={onClose}>
              {t('dictionary.cancel')}
            </Button>
            <Spacer />
            <Button
              onClick={() => {
                onSave(interval)
                onClose()
              }}
            >
              {t('dictionary.save')}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  )
}
