import { motion } from 'framer-motion'
import { DesktopBubbleStyling } from 'sierra-client/views/course-helper/shared/containers'
import styled from 'styled-components'

export const AITutorBubble = styled(motion.div)`
  ${DesktopBubbleStyling}

  display: flex;
  cursor: pointer;
  align-items: center;
  pointer-events: auto;
  padding-inline: 16px;
  padding-inline-start: 8px;
  height: 52px;

  opacity: 0.85;
  :hover {
    opacity: 1;
  }
`
