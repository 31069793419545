import { useAtom } from 'jotai/index'
import React, { ChangeEvent, FC, useId, useMemo } from 'react'
import { getNamespacedImage } from 'sierra-client/api/content'
import { CertificateTemplateId } from 'sierra-client/api/graphql/gql/graphql'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import {
  CertificateInput,
  selectedCertificateAtom,
  templateNames,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import { UploadFileImage } from 'sierra-client/views/manage/certificates/edit-certificate-panel/upload-file-image'
import { ColorInput, FormElement, LabelMenuItem } from 'sierra-ui/components'
import { Checkbox, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const HalfWidthContainer = styled.div`
  width: 50%;
`

const hexColorRegexPattern = /#[0-9A-Fa-f]{6}\b/

interface DesignTabProps {
  refetch: (certificate: CertificateInput) => void
}

const templateToTranslationKey: Record<CertificateTemplateId, TranslationKey> = {
  MINIMAL: 'certificates.template-type.minimal',
  EXTERNAL: 'certificates.template-type.external',
  CLASSIC: 'certificates.template-type.classic',
}

export const DesignTab: FC<DesignTabProps> = ({ refetch }) => {
  const [certificate, setCertificate] = useAtom(selectedCertificateAtom)
  const { templateData } = certificate
  const [bgError, setBgError] = React.useState(false)
  const [textError, setTextError] = React.useState(false)
  const { t } = useTranslation()
  const orgConfig = config.organization

  const brandLogo =
    orgConfig.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, orgConfig.settings.brand.logo, 'image', {
          width: 360,
        })

  const handleTemplateDataChange = (
    key: keyof CertificateInput['templateData'],
    value: CertificateInput['templateData'][typeof key]
  ): void => {
    const newCertificate: CertificateInput = {
      ...certificate,
      templateData: { ...certificate.templateData, [key]: value },
    }
    setCertificate(newCertificate)
    refetch(newCertificate)
  }

  const handleBgColorValidation = (): void => {
    if (templateData.backgroundColor === undefined || templateData.backgroundColor === null) {
      return
    }
    if (hexColorRegexPattern.test(templateData.backgroundColor)) {
      setBgError(false)
    } else {
      setBgError(true)
    }
  }

  const handleTextColorValidation = (): void => {
    if (hexColorRegexPattern.test(templateData.textColor)) {
      setTextError(false)
    } else {
      setTextError(true)
    }
  }

  const handleLogotypeChange = (showLogo: boolean): void => {
    handleTemplateDataChange('logotype', showLogo ? brandLogo : undefined)
  }

  const items = templateNames.map<LabelMenuItem<CertificateTemplateId>>(template => {
    const label = t(templateToTranslationKey[template])

    return {
      id: template,
      label,
      type: 'label',
    }
  })
  const selectedItem = items.find(item => item.id === templateData.templateId)

  const recommendedDimensions = useMemo(() => {
    const dimensions =
      templateData.templateId === 'EXTERNAL' ? { width: 1920, height: 1251 } : { width: 1920, height: 1357 }

    if (certificate.templateData.orientation === 'VERTICAL') {
      return {
        width: dimensions.height,
        height: dimensions.width,
      }
    } else {
      return dimensions
    }
  }, [certificate.templateData.orientation, templateData.templateId])

  const horizontalRadioId = useId()
  const verticalRadioId = useId()

  return (
    <ScrollView direction='column' gap='16'>
      <FormElement label={t('dictionary.template')}>
        <SingleSelectDropdown
          grow={false}
          selectedItem={selectedItem}
          menuItems={items}
          onSelect={item => handleTemplateDataChange('templateId', item.id)}
        />
      </FormElement>
      <View>
        <HalfWidthContainer>
          <FormElement label={t('dictionary.orientation')}>
            <View paddingTop={'xxsmall'} paddingBottom={'xxsmall'} gap='16'>
              <View>
                <Checkbox
                  id={horizontalRadioId}
                  checked={templateData.orientation === 'HORIZONTAL'}
                  radio
                  onCheckedChange={() => handleTemplateDataChange('orientation', 'HORIZONTAL')}
                />
                <label htmlFor={horizontalRadioId}>
                  <Text bold>{t('dictionary.horizontal')}</Text>
                </label>
              </View>
              <View>
                <Checkbox
                  id={verticalRadioId}
                  checked={templateData.orientation === 'VERTICAL'}
                  radio
                  onCheckedChange={() => handleTemplateDataChange('orientation', 'VERTICAL')}
                />
                <label htmlFor={verticalRadioId}>
                  <Text bold>{t('dictionary.vertical')}</Text>
                </label>
              </View>
            </View>
          </FormElement>
        </HalfWidthContainer>
        <HalfWidthContainer>
          {brandLogo !== undefined && (
            <FormElement label={t('dictionary.logotype')}>
              <View paddingTop='xxsmall' gap='16'>
                <View>
                  <Checkbox
                    checked={templateData.logotype !== undefined && templateData.logotype !== null}
                    radio
                    onCheckedChange={() => handleLogotypeChange(true)}
                  />
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
                    {t('dictionary.show')}
                  </Text>
                </View>
                <View>
                  <Checkbox
                    checked={templateData.logotype === undefined || templateData.logotype === null}
                    radio
                    onCheckedChange={() => handleLogotypeChange(false)}
                  />
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
                    {t('dictionary.hide')}
                  </Text>
                </View>
              </View>
            </FormElement>
          )}
        </HalfWidthContainer>
      </View>
      <View direction={'column'}>
        <Text bold>{t('dictionary.colors')}</Text>

        <View gap='16' alignItems='flex-start'>
          <HalfWidthContainer>
            <FormElement
              label={
                <Text bold color='grey25'>
                  {t('dictionary.background')}
                </Text>
              }
              helper={bgError ? 'Please enter a valid hex color code' : undefined}
            >
              <ColorInput
                value={
                  templateData.backgroundImage?.file !== undefined ? '' : (templateData.backgroundColor ?? '')
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleTemplateDataChange('backgroundColor', e.target.value)
                }
                onBlur={handleBgColorValidation}
                disabled={templateData.backgroundImage?.file !== undefined}
              />
            </FormElement>
          </HalfWidthContainer>
          <HalfWidthContainer>
            <FormElement
              label={
                <Text bold color='grey25'>
                  {t('dictionary.text')}
                </Text>
              }
              helper={textError ? 'Please enter a valid hex color code' : undefined}
            >
              <ColorInput
                value={templateData.textColor}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleTemplateDataChange('textColor', e.target.value)
                }
                onBlur={handleTextColorValidation}
              />
            </FormElement>
          </HalfWidthContainer>
        </View>
      </View>
      <View direction={'column'} gap='16'>
        <FormElement label={t('dictionary.background-image')}>
          <>
            <Spacer size='2' />
            <UploadFileImage
              value={templateData.backgroundImage ?? undefined}
              onChange={image => handleTemplateDataChange('backgroundImage', image)}
              recommendedDimensions={recommendedDimensions}
              assetContext={{ type: 'certificate' }}
            />
          </>
        </FormElement>
      </View>
    </ScrollView>
  )
}
