import { queryOptions } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { queryClient } from 'sierra-client/api/query-client'
import { AuthClient } from 'sierra-client/auth/auth-client'
import { config } from 'sierra-client/config/global-config'
import { typedPost } from 'sierra-client/state/api'
import { CheckSessionResponse } from 'sierra-domain/api/auth'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeAuthCheckSession, XRealtimeAuthExpireSession, XRealtimeUserMe } from 'sierra-domain/routes'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createCheckSessionQuery(authSessionChannel: BroadcastChannel) {
  const timeoutMs = config.organization.timeout !== null ? config.organization.timeout / 4 : Infinity
  const checkSessionQuery = queryOptions<CheckSessionResponse, Error | RequestError>({
    queryKey: ['check-session'],
    queryFn: async () => {
      const storedSession = queryClient.getQueryData(checkSessionQuery.queryKey)
      const session = await typedPost(XRealtimeAuthCheckSession, {})

      if (!isEqual(storedSession, session)) {
        // If the session has changed, broadcast it to other tabs to ensure they sync the session.
        authSessionChannel.postMessage(session)

        void queryClient.invalidateQueries({ queryKey: [XRealtimeUserMe.path] })
      }

      return session
    },
    staleTime: timeoutMs,
    gcTime: timeoutMs,
  })

  return checkSessionQuery
}

export type CheckSessionQuery = ReturnType<typeof createCheckSessionQuery>

export class CookieAuthClient implements AuthClient {
  #checkSessionQuery: CheckSessionQuery

  constructor() {
    const authSessionChannel = new BroadcastChannel('sierra-auth-session')
    const checkSessionQuery = createCheckSessionQuery(authSessionChannel)

    authSessionChannel.onmessage = (payload: MessageEvent<CheckSessionResponse>) => {
      queryClient.setQueryData(checkSessionQuery.queryKey, payload.data)
    }

    this.#checkSessionQuery = checkSessionQuery
  }

  async synchronize(): Promise<void> {
    await queryClient.refetchQueries({ queryKey: this.#checkSessionQuery.queryKey, exact: true })
  }

  async signOut(): Promise<void> {
    await typedPost(XRealtimeAuthExpireSession, {})
    await this.synchronize()
  }

  getToken(): string | undefined {
    return undefined
  }

  get authQuery(): CheckSessionQuery {
    return this.#checkSessionQuery
  }
}
