import { MentionOptions } from '@tiptap/extension-mention'
import { JSONContent, ReactRenderer } from '@tiptap/react'
import { SuggestionProps as TipTapSuggestionProps } from '@tiptap/suggestion'
import { useMemo } from 'react'
import { useAllAvailableCourseCollaboratorsMaybe } from 'sierra-client/api/queries/course-collaborators'
import { createCommentersFromMentions } from 'sierra-client/components/chat/mention/create-commenters-from-mentions'
import {
  MentionList,
  MentionListProps,
  MentionListRef,
} from 'sierra-client/components/chat/mention/mention-list'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { useSlashMenuCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CourseId } from 'sierra-domain/api/nano-id'
import tippy, { Instance } from 'tippy.js'

// This solves a type issue introduced with an update of tiptap. It handles a null case which
// tippy could not handle.
// New type: (() => DOMRect | null) | null
// Is converted to old type: (() => DOMRect) | null
const handleNullReturnFromTipTapClientRect = (
  clientRect: TipTapSuggestionProps['clientRect']
): (() => DOMRect) | null => {
  if (clientRect !== null && clientRect !== undefined) {
    const rect = clientRect()
    if (rect === null) return null
    return () => rect
  }
  return null
}

export type CustomMentionOptions = MentionOptions<Collaborator, { collaborator: Collaborator }>
export type CustomSuggestion = CustomMentionOptions['suggestion']

export const useMentionSuggestion: () =>
  | {
      customSuggestion: CustomSuggestion
      createCommentersFromCommentMentions: (_: { tiptapJsonData: JSONContent }) => void
    }
  | undefined = () => {
  const createPageContext = useSlashMenuCreatePageContext()
  const dispatch = useDispatch()

  const courseIdResult = CourseId.safeParse(createPageContext?.createContentId)
  const courseId = courseIdResult.success ? courseIdResult.data : undefined
  const collaborators = useAllAvailableCourseCollaboratorsMaybe({ courseId }).data
  const availableCollaborators = collaborators?.available
  const currentCollaborators = collaborators?.current

  const createCommentersFromCommentMentions = useStableFunction(
    ({ tiptapJsonData }: { tiptapJsonData: JSONContent }) => {
      createCommentersFromMentions({
        dispatch,
        previousCollaborators: currentCollaborators ?? [],
        tiptapJsonData,
      })
    }
  )

  const suggestion = useMemo(
    (): CustomSuggestion => ({
      items: ({ query }) => {
        if (availableCollaborators === undefined) return []

        return availableCollaborators
          .filter(collaborator =>
            `${collaborator.firstName} ${collaborator.lastName}`.toLowerCase().includes(query.toLowerCase())
          )
          .sort(({ role }) => {
            if (role === 'editor') return -1
            if (role === 'commenter') return 0
            return 1
          })
          .slice(0, 8)
      },

      command: ({ editor, range, props }) => {
        // increase range.to by one when the next node is of type "text"
        // and starts with a space character
        const nodeAfter = editor.view.state.selection.$to.nodeAfter
        const overrideSpace = nodeAfter?.text?.startsWith(' ')
        if (overrideSpace === true) range.to += 1

        const name = `${props.collaborator.firstName} ${props.collaborator.lastName}`

        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: 'mention',
              attrs: {
                uuid: props.collaborator.uuid,
                label: name,
                role: props.collaborator.role,
              },
            },
            { type: 'text', text: ' ' },
          ])
          .run()
      },

      render: () => {
        let component: ReactRenderer<MentionListRef, MentionListProps>
        let popup: Instance[]

        return {
          onStart: (props): void => {
            component = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            })

            popup = tippy('body', {
              getReferenceClientRect: handleNullReturnFromTipTapClientRect(props.clientRect),
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              animation: 'fade',
              placement: 'bottom-start',
            })
          },

          onUpdate: (props): void => {
            component.updateProps(props)

            popup[0]?.setProps({
              getReferenceClientRect: handleNullReturnFromTipTapClientRect(props.clientRect),
            })
          },

          onKeyDown: (props): boolean => {
            if (props.event.key === 'Escape') {
              popup[0]?.hide()

              return true
            }

            if (component.ref === null) return false

            return component.ref.onKeyDown(props)
          },

          onExit: (): void => {
            popup[0]?.destroy()
            component.destroy()
          },
        }
      },
    }),
    [availableCollaborators]
  )

  const result = useMemo(
    () => ({
      customSuggestion: suggestion,
      createCommentersFromCommentMentions,
    }),
    [suggestion, createCommentersFromCommentMentions]
  )

  if (courseId === undefined) {
    return undefined
  } else {
    return result
  }
}
