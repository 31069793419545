import { useAtomValue } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useQueryAnalyticsData } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import {
  DataViewerActionProps,
  WidgetMapper,
} from 'sierra-client/features/insights/display-widgets/data-viewers/widget-mapper'
import { useAllFilterDomainReps } from 'sierra-client/features/insights/hooks/use-insights-views'
import { isDashboardFiltersEnabledAtom } from 'sierra-client/features/insights/widget-builder/widget-builder-settings'
import { andAll, Filter } from 'sierra-client/lib/filter'
import { Widget } from 'sierra-domain/api/insights'
import { isDefined } from 'sierra-domain/utils'
import { LoadingSpinner, View } from 'sierra-ui/primitives'

export const DataViewer: React.FC<
  {
    widget: Widget
    previewMode?: boolean
    showToolbar?: boolean
    dashboardFilter?: Filter
  } & DataViewerActionProps
> = ({
  widget,
  previewMode = false,
  showToolbar = true,
  onDrillDown,
  onFilterOut,
  onSort,
  onSetLimit,
  dashboardFilter,
}) => {
  const allFilterDomainReps = useAllFilterDomainReps()

  const filters = [dashboardFilter, widget.filter].filter(isDefined)
  const filter = andAll(filters)
  const widgetWithDashboardFilter = { ...widget, filter }

  const isDashboardFiltersInWidgetEnabled = getFlag('dashboard-filters-in-widget')
  const isDashboardFiltersEnabled = useAtomValue(isDashboardFiltersEnabledAtom)
  const widgetToBeUsed =
    isDashboardFiltersInWidgetEnabled && isDashboardFiltersEnabled ? widgetWithDashboardFilter : widget

  const queryResponse = useQueryAnalyticsData({ widget: widgetToBeUsed, allFilterDomainReps })

  if (queryResponse.isPending) {
    return (
      <View direction='column' alignItems='center' justifyContent='center' grow>
        <LoadingSpinner />
      </View>
    )
  }

  if (queryResponse.isError) {
    throw new Error('Fetching failed', { cause: queryResponse.error.message })
  }

  const queryResult = queryResponse.data.result

  switch (queryResult.type) {
    case 'result.table':
      return (
        <WidgetMapper
          widget={widget}
          tableResult={queryResult}
          previewMode={previewMode}
          showToolbar={showToolbar}
          onDrillDown={onDrillDown}
          onFilterOut={onFilterOut}
          onSort={onSort}
          onSetLimit={onSetLimit}
        />
      )
  }
}
