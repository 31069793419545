import DOMPurify from 'dompurify'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'
import { getFlag } from 'sierra-client/config/global-config'
import { clickedQuestionQACard, wroteQuestionQACard } from 'sierra-client/core/logging/authoring/logger'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { selectCurrentPathId, selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useFileTitle, useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { getFileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { buildSelfPacedUrlPath } from 'sierra-client/views/self-paced/useNavigate'
import { Avatar } from 'sierra-client/views/v3-author/qa-card/qa-avatar'
import { QACardEli5 } from 'sierra-client/views/v3-author/qa-card/QA-card-eli5'
import { QACardQuestions } from 'sierra-client/views/v3-author/qa-card/QA-card-quiz'
import { SpinningIcon } from 'sierra-client/views/v3-author/qa-card/spinning-icon'
import { TutorTool } from 'sierra-client/views/v3-author/qa-card/types'
import { QACardState, QAEntry, QAState, useQAState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { editUrlFromScopedId } from 'sierra-domain/api/editable-content'
import { CreateContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import { assertWith, iife } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import smoothscroll from 'smoothscroll-polyfill'
import styled, { css } from 'styled-components'

const Paddings = css`
  padding-inline: 172px;
  padding-bottom: 8px;
  padding-top: 8px;
  @media (max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 48px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 24px;
  }
`

export const SearchBarContainer = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '32',
  gap: 'none',
})`
  position: absolute;
  padding-bottom: 68px;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  & > * {
    opacity: 0;
    animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
  }
`
export const FadeInView = styled(View)<{ fadeIn: boolean }>`
  ${p =>
    p.fadeIn &&
    css`
      & > * {
        opacity: 0;
        animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
      }
    `}
`
export const QuestionsContainer = styled.div<{ wordCloud: boolean }>`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: row;
  ${p => p.wordCloud && 'max-width: 120ch;'}
  margin: auto;
  align-items: center;
  ${p =>
    p.wordCloud
      ? css`
          flex-wrap: wrap;
          justify-content: center;
        `
      : css`
          flex-wrap: nowrap;

          & > *:first-child {
            margin-left: auto;
          }

          & > *:last-child {
            margin-right: auto;
          }
        `};
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  gap: 8px;
  scrollbar-gutter: stable;

  /* paddings */
  padding-inline: 128px;
  ${p =>
    p.wordCloud
      ? css`
          padding-bottom: 48px;
          padding-top: 48px;
        `
      : css`
          padding-bottom: 8px;
          padding-top: 8px;
        `} @media (
    max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 48px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 24px;
  }
`
export const AnswerContainer = styled(View).attrs({ direction: 'column' })`
  padding-inline: 160px;
  width: 100%;
  height: fit-content;

  @media (max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 64px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 32px;
  }
`
export const AnswerTextContainer = styled.div<{ width: string; fadeIn: boolean }>`
  white-space: pre-line;
  ${p => `max-width: ${p.width};`}
  ${p =>
    p.fadeIn &&
    css`
      opacity: 0;
      animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
    `}
    & {
    font-size: 17px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.2;
      margin-bottom: 0.5em;
      * + & {
        margin-top: 2em;
      }
    }
    p {
      margin-bottom: 1em;
    }
    a {
      color: ${token('foreground/link')};
      :visited {
        color: ${token('foreground/link').shift(0.1)};
      }
      :hover {
        color: ${token('foreground/link').opacity(0.8).shift(0.2)};
      }
    }
    img {
      border-radius: 15px;
      padding: 4px;
      max-width: 250px;
    }
    /* Lists */
    ul,
    ol {
      padding-left: 1.5em;
      margin-bottom: 1em;
    }
    li {
      margin-bottom: 0.5em;
    }
    li:last-child {
      margin-bottom: 0;
    }
    /* Code */
    code {
      font-family: monospace;
      padding: 0.2em 0.4em;
      border-radius: 6px;
      background-color: ${token('foreground/primary').opacity(0.2)};
      display: block;
      overflow-x: auto;
      max-width: 70ch;
    }
    /* Tables */
    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1em;
    }
    th,
    td {
      border: 1px solid ${token('foreground/primary').opacity(0.2)};
      padding: 8px 12px;
      text-align: left;
    }
    th {
      background-color: ${token('foreground/primary').opacity(0.05)};
      font-weight: 500;
    }
    tr:nth-child(even) {
      background-color: ${token('foreground/primary').opacity(0.02)};
    }
  }
`
export const BackgroundContainer = styled(View)`
  width: 100%;
  height: 100%;
`
const ClickMe = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  border-radius: 100px;
  flex-shrink: 0;
  flex-direction: column;
  background-color: white;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${color('white').darken(0.02)};
  }

  cursor: pointer;
  padding: 14px 24px 14px 24px;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
  white-space: nowrap;
  width: fit-content;

  /* opacity: 0;
  animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards; */
`
export const FadeInWord = styled.span<{ fadeIn: boolean }>`
  font-size: 17px;
  line-height: 25.5px;
  height: 26px;
  ${p =>
    p.fadeIn &&
    css`
      opacity: 0;
      animation: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
    `}
`

export const SourceContainer = styled.a`
  white-space: nowrap;
  cursor: pointer;
  padding: 8px;
  padding-right: 12px;
  background-color: ${token('form/background/2')};
  border-radius: 12px;
  gap: 6px;
  display: flex;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${token('form/background/3')};
  }
`

export const Input = styled.input.attrs({ type: 'text' })`
  height: auto;
  border: 0 !important;
  padding: 0;
  border-radius: 0;

  & input {
    font-size: 0.875rem;
    background: white;
    color: black;

    &::placeholder {
      color: ${color('grey35')};
    }
  }

  flex: 1;
`
export const AddWordInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;

  /* width: 348px; */
  width: 100%;
  height: 64px;
  border-radius: 40px;
  padding: 14px;
  padding-left: 18px;

  background: white;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
`
export const Root = styled.div`
  position: relative;
  max-height: 100%;
  height: 100%;
`

export const BottomContainer = styled(View)`
  padding-bottom: 8px;
`

export const InputWrapper = styled(View)`
  width: 100%;
  ${Paddings}
`
export const AnswerTextContainerPreLine = styled.span`
  max-width: 80ch;
  white-space: pre-line;
`
const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding-right: 10px;
`

const IconWithPadding = styled(Icon)`
  padding-left: 4px;
`

function scrollIntoView(element: HTMLElement, behavior: 'smooth' | 'instant'): void {
  smoothscroll.polyfill()
  element.scrollIntoView({ block: 'start', behavior })
}

const LearnerSource: React.FC<{ fileId: FileId; flexibleContentId: CreateContentId }> = ({
  fileId,
  flexibleContentId,
}) => {
  const { slateDocumentMap } = useSelfPacedBigContext()

  const getFileTitle = useGetFileTitle(slateDocumentMap)
  const currentPathId = useSelector(selectCurrentPathId)
  const currentProgramId = useSelector(selectCurrentProgramId)

  const {
    jsonData: { nodeMap },
  } = useSelfPacedBigContext()

  const file: File | undefined = iife(() => {
    const file = nodeMap[fileId]
    if (file === undefined) return undefined
    assertWith(File, file)
    return file
  })

  if (file === undefined) {
    return undefined
  }

  const href = buildSelfPacedUrlPath({
    fileId: file.id,
    flexibleContentId,
    programId: currentProgramId,
    pathId: currentPathId,
  })
  return (
    <SourceContainer key={file.id} href={href} target='_blank' rel='noopener noreferrer'>
      <IconWithPadding size='size-12' iconId={getFileIcon(file.data)} />

      <Text color={'foreground/primary'} bold>
        {getFileTitle(file)}
      </Text>
    </SourceContainer>
  )
}

const EditorSourceFileTitle: React.FC<{ file: File }> = ({ file }) => {
  const { yDoc } = useCreatePageYDocContext()
  const fileTitle = useFileTitle(yDoc, file)
  return (
    <TruncatedText color={'foreground/primary'} bold>
      {fileTitle}
    </TruncatedText>
  )
}
const EditorSource: React.FC<{ fileId: FileId }> = ({ fileId }) => {
  const { createContentId } = useCreatePageContext()
  const { scopedCreateContentId } = useCreatePageContext()
  const file = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))
  const editorUrl = editUrlFromScopedId(scopedCreateContentId, fileId)
  if (file === undefined) {
    return null
  }
  return (
    <SourceContainer key={file.id} href={editorUrl} target='_blank' rel='noopener noreferrer'>
      <IconWithPadding size='size-12' iconId={getFileIcon(file.data)} />
      <EditorSourceFileTitle file={file} />
    </SourceContainer>
  )
}

const DebugPre = styled.pre`
  max-width: 60ch;
  overflow-x: auto;
`

const QuestionAnswerBlock: React.FC<{
  isLatest: boolean
  containerHeight: number
  chat: QAEntry
  fileIds: FileId[]
  fadeIn: boolean
  mode: 'learner' | 'editor'
  flexibleContentId: CreateContentId
  scrollBehavior: 'smooth' | 'instant'
  paddingBottom: number
  paddingTop: number
}> = ({
  isLatest,
  containerHeight,
  chat,
  fileIds,
  fadeIn,
  mode,
  flexibleContentId,
  scrollBehavior,
  paddingBottom,
  paddingTop,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const { t } = useTranslation()

  const isAnswering = chat.status === 'answering'
  useOnMount(() => {
    if (!isAnswering) {
      return
    }
    if (scrollRef.current === null) {
      console.debug('>>> Unable to find scroll element')
      return
    }

    scrollIntoView(scrollRef.current, scrollBehavior)
  })

  const isMobile = useIsMobile()

  const spacer = isMobile ? ('8' as const) : ('32' as const)

  return (
    <div style={isLatest ? { minHeight: containerHeight, paddingBottom, paddingTop } : { paddingTop }}>
      <View ref={scrollRef} key={chat.query} gap={spacer} direction='row' alignItems='flex-start'>
        {!isMobile && <Avatar />}
        <AnswerTextContainer key={chat.query} fadeIn={fadeIn} width='60ch'>
          <p style={{ fontSize: '32px', fontWeight: 500, lineHeight: 1.15 }}>{chat.query}</p>
          <Debug>
            <Text size='technical'>[status]: {chat.status}</Text>
          </Debug>
        </AnswerTextContainer>
      </View>
      <Spacer size={spacer} />
      <BottomContainer gap={spacer} direction='row' alignItems='flex-start'>
        {!isMobile && (
          <SpinningIcon
            $isSpinning={chat.status === 'answering'}
            iconId='sana-symbol'
            size='size-24'
            color='foreground/primary'
          />
        )}
        <View direction='column'>
          <AnswerTextContainer
            fadeIn={fadeIn}
            style={{ gap: 'none' }}
            width='80ch'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                chat.answer
                  .trim()
                  .replace(/^```html/, '')
                  .replace(/```$/, '')
                  .trim()
                  .replaceAll(/(>)\s*\n\s*(<)/g, '$1$2')
              ),
            }}
          />
          <Debug>
            <DebugPre>{chat.answer}</DebugPre>
          </Debug>
          {fileIds.length > 0 && (
            <FadeInView fadeIn={fadeIn} direction='column' marginTop={'32'}>
              <Text color={'foreground/muted'} bold>
                {t('question-answers.sources-title')}
              </Text>
              <View marginTop={'4'} direction='row' wrap='wrap'>
                {_.take(fileIds, 3).map(fileId => (
                  <React.Fragment key={fileId}>
                    {mode === 'editor' && <EditorSource fileId={fileId} />}
                    {mode === 'learner' && (
                      <LearnerSource fileId={fileId} flexibleContentId={flexibleContentId} />
                    )}
                  </React.Fragment>
                ))}
              </View>
            </FadeInView>
          )}
        </View>
      </BottomContainer>
    </div>
  )
}

const DisclaimerContainer = styled(View)`
  left: 0;
  right: 0;
  margin-inline: auto;
  position: absolute;
  bottom: 0;
  height: 76px;
`

const RefreshButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 14px 16px;
  background-color: ${token('foreground/primary').opacity(0.04)};

  border-radius: 46px;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${token('foreground/primary').opacity(0.07)};
  }
`
const QuestionCloud: React.FC<{
  generatedQuestions: { fileIds: FileId[]; question: string }[]
  courseId: CreateContentId
  userId: UserId | undefined
  refetchQuestions: () => void
  findRelevantNodesAndStartAnswering: FindRelevantNodesAndStartAnswering
  fileId: FileId
  qaState: QAState
}> = ({
  generatedQuestions,
  courseId,
  userId,
  refetchQuestions,
  findRelevantNodesAndStartAnswering,
  fileId,
  qaState,
}) => {
  const renderedGeneratedQuestions = generatedQuestions.slice(0, 5)
  const dispatch = useDispatch()
  return (
    <QuestionsContainer wordCloud>
      {renderedGeneratedQuestions.map(({ question, fileIds }, index) => (
        <div style={{ display: 'flex', gap: '8px' }} key={index}>
          <ClickMe
            key={question + index}
            animated
            initial={{ opacity: 0, y: 20, rotateZ: (Math.random() - 0.5) * (400 / question.length) }}
            animate={{
              opacity: 1,
              y: 0,
              rotateZ: 0,
              transition: {
                duration: 0.3,
                delay: index * 0.2,
                y: { type: 'spring' },
                rotateZ: { type: 'spring' },
              },
            }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
            onClick={() => {
              void dispatch(
                clickedQuestionQACard({
                  contentId: courseId,
                  cardId: fileId,
                  activityId: createNanoId12FromString(courseId + fileId + userId),
                  where: 'word-cloud',
                  in: 'self-paced',
                })
              )
              findRelevantNodesAndStartAnswering({
                question,
                knownSourceFileIds: fileIds,
              })
            }}
          >
            <Text bold color={'black'}>
              {question}
            </Text>
          </ClickMe>
          {index === renderedGeneratedQuestions.length - 1 && (
            <>
              {getFlag('ai-tutor-experimental') && (
                <ClickMe
                  style={{ backgroundColor: '#8ACE00' }}
                  key={'quiz me'}
                  animated
                  initial={{ opacity: 0, y: 20, rotateZ: (Math.random() - 0.5) * (400 / question.length) }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    rotateZ: 0,
                    transition: {
                      duration: 0.3,
                      delay: (index + 1) * 0.2,
                      y: { type: 'spring' },
                      rotateZ: { type: 'spring' },
                    },
                  }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  onClick={() => {
                    qaState.startQuizMutation.mutate()
                  }}
                >
                  <div
                    style={{
                      transform: 'scale(0.9, 1.05)',
                      fontFamily: 'sans-serif',
                    }}
                  >
                    <Text bold color={'black'}>
                      quiz
                    </Text>
                  </div>
                </ClickMe>
              )}
              <RefreshButton
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3, delay: (index + 2) * 0.2 },
                }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                onClick={() => {
                  void refetchQuestions()
                }}
              >
                <Icon iconId='shuffle'></Icon>
              </RefreshButton>
            </>
          )}
        </div>
      ))}
    </QuestionsContainer>
  )
}

const Disclaimer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Icon color={'foreground/muted'} iconId='view--off'></Icon>
      <Text color={'foreground/muted'} size='micro'>
        {t('question-answers.private-conversation-disclaimer')}
      </Text>
    </>
  )
}

const ChatEntries: React.FC<{
  cardState: QACardState & { type: 'chat' }
  containerHeight: number
  mode: 'learner' | 'editor'
  courseId: SelfPacedContentId
}> = ({ cardState, containerHeight, mode, courseId }) => {
  const hasRenderedOnce = useRef(false)
  useEffect(() => {
    hasRenderedOnce.current = true
  }, [])

  const isMobile = useIsMobile()

  const paddingBottom = 250
  const paddingTop = isMobile ? 40 : 112

  return (
    <AnswerContainer>
      {[...cardState.previousChats, cardState.latestChatEntry].map((chat, index, { length }) =>
        chat.type === 'question-and-answer' ? (
          <QuestionAnswerBlock
            key={index}
            containerHeight={containerHeight}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            isLatest={index === length - 1}
            fadeIn={index === length - 1}
            chat={chat}
            fileIds={chat.fileIds}
            mode={mode}
            flexibleContentId={courseId}
            scrollBehavior={hasRenderedOnce.current ? 'smooth' : 'instant'}
          />
        ) : (
          <QACardQuestions
            key={index}
            containerHeight={containerHeight}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            isLatest={index === length - 1}
            quiz={chat}
          />
        )
      )}
    </AnswerContainer>
  )
}

type FindRelevantNodesAndStartAnswering = QAState['answerQuestionMutation']['mutate']

export const QAView: React.FC<{
  qaState: QAState
  setTutorTool: React.Dispatch<React.SetStateAction<TutorTool>>
}> = ({ qaState, setTutorTool }) => {
  const {
    answerQuestionMutation,
    generateQuestionsMutation,
    cardState,
    courseId,
    startedFromFileId,
    location,
  } = qaState
  const isGeneratingQuestions = generateQuestionsMutation.isPending

  const isFindingContextFiles = iife(() => {
    if (cardState.type !== 'chat') return false
    if (cardState.latestChatEntry.type !== 'question-and-answer') return false
    return cardState.latestChatEntry.status === 'answering' && cardState.latestChatEntry.fileIds.length === 0
  })

  const generatedQuestions = isFindingContextFiles ? undefined : generateQuestionsMutation.data?.questions

  const findRelevantNodesAndStartAnswering = answerQuestionMutation.mutate
  const regenerateQuestions = useStableFunction(() => {
    generateQuestionsMutation.mutate({})
  })

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [word, setWord] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const isTutorToolsEnabled = useIsDebugMode() && getFlag('ai-tutor-experimental')

  const me = useSelector(selectUser)
  const userId = me?.uuid

  const handleWrittenQuestion = useCallback(() => {
    if (word.trim() === '') {
      return
    }
    setWord('')
    findRelevantNodesAndStartAnswering({ question: word, knownSourceFileIds: [] })
    void dispatch(
      wroteQuestionQACard({
        contentId: courseId,
        cardId: startedFromFileId,
        activityId: createNanoId12FromString(courseId + startedFromFileId + userId),
      })
    )
  }, [courseId, dispatch, startedFromFileId, findRelevantNodesAndStartAnswering, userId, word])

  const [sizeRef, { height: containerHeight }] = useMeasure()

  return (
    <>
      <Root ref={sizeRef}>
        {cardState.type !== 'word-cloud' ? (
          containerHeight > 0 && (
            <ChatEntries
              cardState={cardState}
              mode={location}
              courseId={courseId}
              containerHeight={containerHeight}
            />
          )
        ) : (
          <BackgroundContainer alignItems='center' justifyContent='center' direction='column'>
            <View direction='column' gap='none'>
              <View alignItems='center' justifyContent='center' direction='column'>
                <motion.div
                  key='title-text'
                  layout='position'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Heading align='center' bold size='h4'>
                    {t('question-answers.prompt')}
                  </Heading>
                  <Heading bold size='h4'>
                    {t('question-answers.sub-prompt')}
                  </Heading>
                </motion.div>
              </View>

              {!isGeneratingQuestions && generatedQuestions !== undefined && generatedQuestions.length > 0 ? (
                <QuestionCloud
                  generatedQuestions={generatedQuestions}
                  courseId={courseId}
                  userId={userId}
                  refetchQuestions={() => regenerateQuestions()}
                  findRelevantNodesAndStartAnswering={findRelevantNodesAndStartAnswering}
                  fileId={startedFromFileId}
                  qaState={qaState}
                />
              ) : (
                <Spacer size='40' />
              )}

              <View justifyContent='center'>
                <motion.div key={'disclaimer'} layout='position' style={{ display: 'flex', gap: '8px' }}>
                  <Disclaimer />
                </motion.div>
              </View>
              {isTutorToolsEnabled === true && (
                <View direction='row' gap='8'>
                  <Button variant='transparent' onClick={() => setTutorTool('eli5')}>
                    Eli5
                  </Button>
                </View>
              )}
            </View>
          </BackgroundContainer>
        )}
      </Root>

      <SearchBarContainer>
        <AnimatePresence>
          {!isGeneratingQuestions &&
            cardState.type === 'chat' &&
            cardState.latestChatEntry.type === 'question-and-answer' &&
            generatedQuestions !== undefined &&
            generatedQuestions.length > 0 && (
              <QuestionsContainer wordCloud={false}>
                {generatedQuestions.slice(0, 3).map(({ question, fileIds }, index) => (
                  <ClickMe
                    key={question + index}
                    onClick={() => {
                      void dispatch(
                        clickedQuestionQACard({
                          contentId: courseId,
                          cardId: startedFromFileId,
                          activityId: createNanoId12FromString(courseId + startedFromFileId + userId),
                          where: 'search-bar',
                          in: 'self-paced',
                        })
                      )

                      findRelevantNodesAndStartAnswering({
                        question,
                        knownSourceFileIds: fileIds,
                      })
                    }}
                  >
                    <Text bold color={'black'}>
                      {question}
                    </Text>
                    <Debug>
                      <Text size='technical'>[fileIds: {fileIds.join(', ')}]</Text>
                    </Debug>
                  </ClickMe>
                ))}
                <RefreshButton
                  onClick={() => {
                    regenerateQuestions()
                  }}
                >
                  <Icon iconId='shuffle'></Icon>
                </RefreshButton>
              </QuestionsContainer>
            )}
        </AnimatePresence>
        <InputWrapper>
          <AddWordInputContainer>
            <Avatar />
            <Input
              value={word}
              placeholder={t('dictionary.write-something')}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleWrittenQuestion()
                }
              }}
              onChange={e => setWord(e.target.value)}
              ref={inputRef}
            />
            <StyledIcon
              size='size-20'
              onClick={handleWrittenQuestion}
              iconId='arrow-up--filled'
              color={word === '' ? 'grey25' : 'black'}
            />
          </AddWordInputContainer>
        </InputWrapper>
      </SearchBarContainer>
      {cardState.type !== 'word-cloud' && (
        <DisclaimerContainer justifyContent='center'>
          <Disclaimer />
        </DisclaimerContainer>
      )}
    </>
  )
}

export const QACard: React.FC<{
  courseId: SelfPacedContentId
  fileId: FileId
  location: QAState['location']
}> = ({ courseId, fileId, location }) => {
  const qaState = useQAState({ location, courseId, fileId })

  useOnMount(() => {
    qaState.generateQuestionsMutation.mutate({ latestQuestion: undefined })
  })

  const [tutorTool, setTutorTool] = useState<TutorTool>('qa')

  switch (tutorTool) {
    case 'qa':
      return <QAView qaState={qaState} setTutorTool={setTutorTool} />
    case 'eli5':
      return (
        <QACardEli5
          applyToFile='most-recent-page-card'
          courseId={courseId}
          fileId={fileId}
          switchToQaCard={() => setTutorTool('qa')}
          location={location}
        />
      )
  }
}
