import { useAtom } from 'jotai'
import { capitalize, debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useUserLanguage } from 'sierra-client/api/hooks/use-user'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { atomWithStorage } from 'sierra-client/state/storage'
import { iife, isDefined } from 'sierra-domain/utils'
import { z } from 'zod'

const NO_CAPTIONS_VALUE = 'no-captions'

const preferredSubtitleLanguageAtom = atomWithStorage(
  'preferredSubtitleLanguage',
  undefined,
  // language code or 'no-captions'
  z.string().optional()
)

const getSubtitleQuery = graphql(`
  query GetSubtitles($videoUrl: String!) {
    video(videoUrl: $videoUrl) {
      id
      subtitles {
        language
        text
      }
    }
  }
`)

export const useInvalidateSubtitleQuery = (videoUrl: string): (() => Promise<void>) => {
  return useInvalidateGraphQuery(getSubtitleQuery, { videoUrl })
}

export const useAddSubtitleTracks = (
  videoUrl?: string,
  enabled: boolean = false
): ((player: VideoJsPlayer) => void) => {
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null)

  const [preferredSubtitleLanguage, setPreferredSubtitleLanguage] = useAtom(preferredSubtitleLanguageAtom)
  const { data: userLanguage } = useUserLanguage()

  // We will use a ref here since this will only be used when initializing the subtitles.
  const subtitleLanguagePreferencesRef = useRef({ preferredSubtitleLanguage, userLanguage })
  subtitleLanguagePreferencesRef.current = { preferredSubtitleLanguage, userLanguage }

  const subtitleQuery = useGraphQuery(
    {
      document: getSubtitleQuery,
      queryOptions: {
        enabled: isDefined(player) && isDefined(videoUrl) && enabled,
      },
    },
    { videoUrl: videoUrl ?? '' }
  )

  useEffect(() => {
    if (subtitleQuery.data !== undefined && player !== null && enabled) {
      const preferredSubtitleLanguage = iife(() => {
        const { preferredSubtitleLanguage, userLanguage } = subtitleLanguagePreferencesRef.current

        // Do not show subtitles if the user has explicitly disabled them.
        if (preferredSubtitleLanguage === NO_CAPTIONS_VALUE) {
          return undefined
        }

        const subtitleLanguages = subtitleQuery.data.video.subtitles.map(subtitle => subtitle.language)

        return (
          // Use the user's explicit subtitle setting if available.
          subtitleLanguages.find(it => it === preferredSubtitleLanguage) ??
          // Else, use the user's language setting if available.
          subtitleLanguages.find(it => it === userLanguage)
        )
      })

      const subtitles = subtitleQuery.data.video.subtitles.map(subtitle => {
        const vttBlob = new Blob([subtitle.text], {
          type: 'text/vtt',
        })
        const src = URL.createObjectURL(vttBlob)
        const locale = new Intl.DisplayNames([subtitle.language], { type: 'language' })
        const languageLabel = capitalize(locale.of(subtitle.language) ?? subtitle.language)

        return {
          kind: 'captions',
          label: languageLabel,
          language: subtitle.language,
          id: subtitle.language,
          src,
          // This should set the track to enabled by default but doesn't seem to do anything
          // We manually enable the track below
          default: preferredSubtitleLanguage === subtitle.language,
        }
      })

      const tracks = subtitles.map(subtitle => {
        const track = player.addRemoteTextTrack(subtitle, true)
        return track
      })

      const trackList = player.textTracks()

      const trackWithPreferredLanguage = trackList.tracks_.find(
        track => track.language === preferredSubtitleLanguage
      )

      if (trackWithPreferredLanguage !== undefined) {
        trackWithPreferredLanguage.mode = 'showing'
      }

      const onLangChanged = debounce(() => {
        const tracks = player.textTracks()
        const currentLanguage = tracks.tracks_.find(
          track => track.kind === 'captions' && track.mode === 'showing'
        )?.language

        setPreferredSubtitleLanguage(currentLanguage ?? NO_CAPTIONS_VALUE)
      })

      // We use a timeout here since we would otherwise pick up the initial language change event.
      // We don't want that -- we only want to store the value when the user explicitly selects a language.
      const langChangedTimerId = setTimeout(() => {
        trackList.on('change', onLangChanged)
      }, 0)

      return () => {
        clearTimeout(langChangedTimerId)
        trackList.off('change', onLangChanged)

        subtitles.forEach(subtitle => {
          URL.revokeObjectURL(subtitle.src)
        })

        tracks.forEach(track => {
          player.removeRemoteTextTrack(track)
        })
      }
    }
  }, [enabled, player, setPreferredSubtitleLanguage, subtitleQuery.data])

  return useCallback((player: VideoJsPlayer) => {
    setPlayer(player)
  }, [])
}
