import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  useInvalidateEditableContentCache,
  useInvalidateLearnerContentCache,
} from 'sierra-client/api/hooks/queries-invalidation'
import { useListEditableContent } from 'sierra-client/api/hooks/use-editable-content'
import { useListLearnEntities } from 'sierra-client/api/hooks/use-learn-entities'
import { useListLearnerContent } from 'sierra-client/api/hooks/use-learner-content'
import { useIsGuestUser } from 'sierra-client/api/hooks/use-user'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { LearnCourse, LearnPath, LearnProgram } from 'sierra-domain/api/entities'
import { ContentKind } from 'sierra-domain/api/learn'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, isDefined } from 'sierra-domain/utils'

export type TaggableContent = { contentType: 'content'; access: 'edit' | 'view' } & {
  id: string
  image: ImageUnion | undefined
  title: string
  type: ContentKind | 'path' | 'program'
}

const unpackCourseGroupEditions = (course: LearnCourse): TaggableContent[] => {
  if (course.entityType !== 'native:course-group' && course.entityType !== 'scorm:course-group') {
    return []
  }

  return (course.editionInfo ?? []).map(
    (edition): TaggableContent => ({
      contentType: 'content',
      access: 'view',
      id: edition.id,
      image: edition.image,
      title: edition.title,
      type: edition.kind,
    })
  )
}

const unpackCourse = (course: LearnCourse): TaggableContent[] => {
  return [
    {
      contentType: 'content',
      access: 'view',
      type: course.entityType,
      image: course.image,
      id: course.id,
      title: course.title,
    },
    ...unpackCourseGroupEditions(course),
  ]
}

const unpackPath = (path: LearnPath): TaggableContent[] => [
  {
    contentType: 'content',
    access: 'view',
    type: path.entityType,
    image: path.image,
    id: path.id,
    title: path.title,
  },
  ...path.content.flatMap(unpackCourse),
]

const unpackProgram = (program: LearnProgram): TaggableContent[] => [
  {
    contentType: 'content',
    access: 'view',
    type: program.entityType,
    image: program.image,
    id: program.id,
    title: program.title,
  },
  ...program.steps.flatMap(step => {
    switch (step.type) {
      case 'path':
        return unpackPath(step.path)
      case 'course':
        return unpackCourse(step.course)
      default:
        assertNever(step)
    }
  }),
]

export const useTaggableContent = (enabled = true): TaggableContent[] | undefined => {
  const { data: isGuestUser } = useIsGuestUser()
  const enabledNotGuest = enabled !== false && isGuestUser === false
  const isCreateAccessible = useIsCreateAccessible()

  const refetchInterval = 300_000 + Math.floor(Math.random() * 600_000) // 5 min + 0-10 min jitter

  // TODO: Merge all 3 into 1 endpoint
  const editable = useListEditableContent({
    enabled: enabledNotGuest && isCreateAccessible,
    staleTime: 900_000,
    gcTime: 900_000,
    refetchInterval: refetchInterval,
  }).data

  const learner = useListLearnerContent({
    enabled: enabledNotGuest,
    staleTime: 900_000,
    gcTime: 900_000,
    refetchInterval: refetchInterval,
  }).data

  const allContent = useListLearnEntities({
    enabled: enabledNotGuest,
    staleTime: 900_000,
    gcTime: 900_000,
    refetchInterval: refetchInterval,
  }).data

  return useMemo(() => {
    if (isGuestUser === undefined) return undefined
    if (isGuestUser === true) return []
    const learnerContent: TaggableContent[] | undefined = learner?.content.flatMap(learnerContent => ({
      contentType: 'content',
      access: 'view',
      type: learnerContent.type,
      id: learnerContent.id,
      image: learnerContent.image,
      title: learnerContent.title,
    }))

    const editableContent: TaggableContent[] | undefined = editable?.content.map(editableContent => ({
      contentType: 'content',
      access: 'edit',
      type: editableContent.type,
      id: editableContent.id,
      image: editableContent.image,
      title: editableContent.title,
    }))

    const allEntities: TaggableContent[] | undefined = allContent?.entities.flatMap(
      (entity): TaggableContent[] => {
        // We can't tag live-sessions
        if (entity.entityType === 'live-session') {
          return []
        }

        if (entity.entityType === 'program') {
          return unpackProgram(entity)
        }

        if (entity.entityType === 'path') {
          return unpackPath(entity)
        }

        return unpackCourse(entity)
      }
    )

    return isDefined(editableContent) || isDefined(learnerContent) || isDefined(allEntities)
      ? _.uniqBy([...(editableContent ?? []), ...(learnerContent ?? []), ...(allEntities ?? [])], it => it.id)
      : undefined
  }, [isGuestUser, learner?.content, allContent?.entities, editable?.content])
}

export const useInvalidateTaggableContentCache = (): (() => Promise<void>) => {
  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const invalidateLearnerContentCache = useInvalidateLearnerContentCache()
  return useCallback<() => Promise<void>>(async () => {
    void invalidateEditableContentCache()
    void invalidateLearnerContentCache()
  }, [invalidateEditableContentCache, invalidateLearnerContentCache])
}
