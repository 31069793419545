import React, { useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { RequiredAssignmentIcon } from 'sierra-client/components/required-assignments/required-assignment-icon'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ReadOnlyFilter } from 'sierra-client/lib/filter/components/read-only-filter'
import { NotificationType } from 'sierra-client/state/notifications/types'
import { useInvalidateProgramMembersDataloader } from 'sierra-client/views/manage/programs/components/program-users-table'
import { EnrollmentRuleWrapper } from 'sierra-client/views/manage/programs/enrollment-rules/modals/common'
import { EnrollmentRuleModal } from 'sierra-client/views/manage/programs/enrollment-rules/modals/enrollment-rule-modal'
import { useInvalidateEnrollmentRuleList } from 'sierra-client/views/manage/programs/enrollment-rules/use-enrollment-rules-by-context'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { EnrollmentRule as EnrollmentRuleType } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { isLeft } from 'sierra-domain/either'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import {
  XRealtimeAdminProgramsDeleteEnrollmentRule,
  XRealtimeAdminProgramsUpdateEnrollmentRule,
} from 'sierra-domain/routes'
import { Icon } from 'sierra-ui/components'
import { IconButton, Switch, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Headline = styled(Text).attrs({ size: 'small', bold: true })``

const Filter = styled(View).attrs({ direction: 'row', wrap: 'wrap', gap: '4' })``

const StyledEnrollmentRuleWrapper = styled(EnrollmentRuleWrapper).attrs({ direction: 'column' })``

export const EnrollmentRule: React.FC<{
  programId: ProgramId
  rule: EnrollmentRuleType
  programImage?: ImageUnion
  domainReps: DomainRep[]
  onDelete: () => void
  readonly?: boolean
}> = ({ programId, rule, onDelete, readonly = false, domainReps, programImage }) => {
  const { t } = useTranslation()
  const invalidateEnrollmentRuleList = useInvalidateEnrollmentRuleList()
  const tracking = useTracking()

  const [loading, setLoading] = useState(false)
  const { postWithUserErrorCode, postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [wasOpenedThroughModal, setWasOpenedThroughModal] = useState(false)

  const invalidateProgramMembersTable = useInvalidateProgramMembersDataloader()

  const update = useCallback(
    async ({
      id,
      programId,
      name,
      enabled,
    }: {
      id: string
      programId: string
      name?: string
      enabled?: boolean
    }) => {
      setLoading(true)
      const result = await postWithUserErrorCode(XRealtimeAdminProgramsUpdateEnrollmentRule, {
        id,
        programId,
        name,
        enabled,
      })

      if (isLeft(result)) {
        if (result.left === 'enrollment-rule/duplicate-name') {
          const notificationType = 'enrollment-rule/duplicate-name' as NotificationType
          notifications.push({ type: notificationType })
        } else {
          throw Error(`An unknown error occured while creating an enrollment rule: ${result.left}.`)
        }
      } else {
        tracking.enrollment.rule.activate(id, Boolean(enabled))
        // Refetch list of rules
        await invalidateEnrollmentRuleList({ type: 'program', id: programId })

        // Invalidate the user table query
        void invalidateProgramMembersTable()
      }

      setLoading(false)
    },
    [
      postWithUserErrorCode,
      notifications,
      tracking.enrollment.rule,
      invalidateEnrollmentRuleList,
      invalidateProgramMembersTable,
    ]
  )

  const deleteRule = useCallback(
    async (id: string) => {
      setLoading(true)
      await postWithUserErrorException(XRealtimeAdminProgramsDeleteEnrollmentRule, {
        id,
      })
      notifications.push({ type: 'enrollment-rule/deleted' })
      setLoading(false)
      onDelete()
      tracking.enrollment.rule.deleted(id)
    },
    [postWithUserErrorException, notifications, onDelete, tracking.enrollment.rule]
  )

  return (
    <>
      <StyledEnrollmentRuleWrapper>
        <View direction='row'>
          <Headline color={Boolean(rule.invalid) ? 'warning/background' : 'foreground/primary'}>
            {Boolean(rule.invalid) && (
              <>
                <Icon iconId={'warning--filled'} color={'warning/background'} />
                &nbsp;&nbsp;
              </>
            )}
            {rule.name}
          </Headline>
          {rule.isRequiredAssignment && <RequiredAssignmentIcon />}
        </View>
        {domainReps.length > 0 && (
          <Filter>
            <ReadOnlyFilter domainReps={domainReps} filter={rule.filter} />
          </Filter>
        )}
        <View direction='row' justifyContent='space-between'>
          <Switch
            size='small'
            text={t('manage.programs.enrollment-rules.active')}
            onChange={() => update({ id: rule.id, programId: rule.programGroupId, enabled: !rule.enabled })}
            checked={rule.enabled}
            disabled={loading || readonly}
          />
          <View direction='row' justifyContent='flex-end'>
            <IconButton
              variant={'transparent'}
              iconId={readonly ? 'view' : 'edit'}
              tooltip={t(
                readonly ? 'manage.programs.enrollment-rules.view' : 'manage.programs.enrollment-rules.edit'
              )}
              onClick={() => setEditModalOpen(true)}
            />
            {!readonly && (
              <IconButton
                variant={'transparent'}
                iconId='trash-can'
                tooltip={t('manage.programs.enrollment-rules.delete')}
                onClick={() => setConfirmDeleteModal(true)}
              />
            )}
          </View>
        </View>
      </StyledEnrollmentRuleWrapper>
      {readonly ? (
        <EnrollmentRuleModal
          programId={programId}
          ruleId={rule.id}
          image={programImage}
          open={editModalOpen}
          domainReps={domainReps}
          initialState={{
            title: rule.name,
            enabled: rule.enabled,
            filter: rule.filter,
            time: rule.time,
            isRequiredAssignment: rule.isRequiredAssignment,
          }}
          onClose={() => setEditModalOpen(false)}
          mode='view'
        />
      ) : (
        <EnrollmentRuleModal
          programId={programId}
          ruleId={rule.id}
          image={programImage}
          open={editModalOpen}
          domainReps={domainReps}
          onClose={() => setEditModalOpen(false)}
          mode='edit'
          initialState={{
            title: rule.name,
            enabled: rule.enabled,
            filter: rule.filter,
            time: rule.time,
            isRequiredAssignment: rule.isRequiredAssignment,
          }}
          onSave={async () => {
            // Invalidate enrollment rule query
            await invalidateEnrollmentRuleList({ type: 'program', id: programId })
          }}
          onDelete={() => {
            setWasOpenedThroughModal(true)
            setEditModalOpen(false)
            setConfirmDeleteModal(true)
          }}
        />
      )}
      <ActionModal
        open={confirmDeleteModal}
        isLoading={loading}
        onClose={() => {
          if (wasOpenedThroughModal) {
            setEditModalOpen(true)
            setWasOpenedThroughModal(false)
          }
          setConfirmDeleteModal(false)
        }}
        primaryAction={() => deleteRule(rule.id)}
        primaryActionLabel={t('manage.programs.enrollment-rules.delete')}
        deleteAction
      >
        {t('manage.programs.enrollment-rules.delete-message')}
      </ActionModal>
    </>
  )
}
