import React from 'react'
import { InView } from 'react-intersection-observer'
import { IconList, IconListItem } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { RouterLink } from 'sierra-client/components/common/link'
import { ProgressLine } from 'sierra-client/components/common/progress-line'
import { formatDateFromTo, useGetFormattedTime } from 'sierra-client/core/format'
import { Logging } from 'sierra-client/core/logging'
import { ImpressionContainers } from 'sierra-client/core/logging/course/logger'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  selectCurrentProgramId,
  selectPrependUrlWithProgramAndPath,
} from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { getCourseLevelTranslationKey } from 'sierra-client/views/course-settings/tabs/utils'
import { getHrefToCourse, toCourseForHref } from 'sierra-client/views/learner/path/get-href-to-course'
import { StepContentLabel } from 'sierra-client/views/learner/program/components/step-content-label'
import { SelfEnrollLiveSessionList } from 'sierra-client/views/learner/self-enroll-live-session/self-enroll-live-session-list'
import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { CourseLevel } from 'sierra-domain/api/common'
import { CourseWithStatus } from 'sierra-domain/api/course-with-status'
import { CourseId } from 'sierra-domain/api/nano-id'
import { LinkedInDifficultyLevel } from 'sierra-domain/api/partner'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { v2_breakpoint, v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const ArrowIcon = styled(Icon)`
  position: absolute;
  top: 1.75rem;
  right: 1.5rem;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`

const CourseProgressLine = styled(ProgressLine)`
  flex-basis: 10rem;
  flex-shrink: 0;
  max-width: 10rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    order: 2;
    margin-top: 0.75rem;
    margin-left: 1rem;
  }
`

const InfoContainer = styled.div`
  flex: 1;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
`

const InfoContainerInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media screen and (max-width: ${v2_breakpoint_int.desktop_small - 1}px) {
    flex-wrap: wrap;
  }
`

const TextContainer = styled.div`
  @media screen and (max-width: ${v2_breakpoint_int.desktop_small - 1}px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`

const ImageContainer = styled.div`
  flex-basis: 6rem;
  max-width: 6rem;
  position: relative;

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    flex-basis: 9rem;
    max-width: 9rem;
  }
`

const ListItemContainer = styled.div<{ locked: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 1.5rem;
  background-color: transparent;
  border-radius: 1rem;
  transition: background-color 0.2s ease;

  ${p =>
    p.locked &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.5rem;
    right: 1.5rem;
    height: 1px;
    background-color: ${token('surface/default')};
  }

  &:hover {
    background-color: ${token('surface/default')};
  }
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const LockedContentContainer = styled(FlexContainer)`
  position: relative;
  padding: 1.5rem 0;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${palette.primitives.white};
  }
`

function LinkWrapper({ children, href }: { children: React.ReactNode; href: string | null }): JSX.Element {
  if (href === null) {
    return <>{children}</>
  }

  return <RouterLink href={href}>{children}</RouterLink>
}

type CourseListItemProps = {
  locked?: boolean
  completed?: boolean
  course: CourseWithStatus
  pathId: string
}

const CourseListItem = ({
  locked = false,
  completed = false,
  course,
  pathId,
}: CourseListItemProps): JSX.Element => {
  const { status, readingTimes } = course

  const { t } = useTranslation()
  const formattedTimeLeft = useGetFormattedTime(status.timeLeft.total, false)
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)

  const progress = readingTimes.total > 0 ? 1 - status.timeLeft.total / readingTimes.total : null

  const { image, title } = course
  const level: CourseLevel | LinkedInDifficultyLevel | undefined =
    course.type === 'native:self-paced'
      ? course.level
      : course.type === 'linkedin'
        ? course.record.difficultyLevel
        : undefined

  const levelTranslationKey = getCourseLevelTranslationKey(level)
  const programId = useSelector(selectCurrentProgramId)
  const href = locked ? null : getHrefToCourse({ course: toCourseForHref(course), pathId, programId })

  const assetContext = { type: 'course' as const, courseId: course.id }
  const imageSrc = useResolveAsset({ image, size: 'course-sm', assetContext })

  return (
    <ListItemContainer locked={locked}>
      <ImageContainer>
        <LinkWrapper href={href}>
          <StepContentLabel contentType={course.type} />
          <SanaImage src={imageSrc} ratio='4:3' rounded />
        </LinkWrapper>
      </ImageContainer>
      <InfoContainer>
        <LinkWrapper href={href}>
          <InfoContainerInner>
            {progress !== null && <CourseProgressLine $progress={progress} />}
            <TextContainer>
              <Text size='regular' bold>
                {title}
              </Text>
              <Spacer size='xxsmall' />
              <FlexContainer>
                {(() => {
                  if (course.type === 'native:live') {
                    if (course.completedSession !== undefined) {
                      return (
                        <IconList>
                          <IconListItem
                            iconId={'checkmark'}
                            color='foreground/primary'
                            text={t('course-overview.completed')}
                          />
                        </IconList>
                      )
                    }

                    const timeForNextLiveSession =
                      course.nextSession !== undefined && course.nextSession.data.type === 'scheduled'
                        ? formatDateFromTo({
                            dateFrom: course.nextSession.data.startTime,
                            dateTo: course.nextSession.data.endTime,
                          })
                        : undefined

                    return (
                      <IconList>
                        <IconListItem
                          iconId='play--circle--filled'
                          color='foreground/primary'
                          text={t('dictionary.live')}
                        />
                        <Text size='small' color='grey40'>
                          {timeForNextLiveSession ?? t('course-overview.live-course.not-scheduled')}
                        </Text>
                      </IconList>
                    )
                  }

                  if (course.type === 'native:event-group') {
                    if (completed) {
                      return (
                        <IconList>
                          <IconListItem
                            iconId={'checkmark'}
                            color='foreground/primary'
                            text={t('course-overview.completed')}
                          />
                        </IconList>
                      )
                    }

                    return (
                      <IconList>
                        <IconListItem
                          iconId='calendar'
                          color='foreground/primary'
                          text={t('dictionary.physical-event')}
                        />
                        <Text size='small' color='grey40'>
                          {t('course-overview.live-course.not-scheduled')}
                        </Text>
                      </IconList>
                    )
                  }

                  return (
                    <>
                      <IconList>
                        <IconListItem
                          iconId={completed ? 'checkmark' : locked ? 'locked' : 'time'}
                          color='foreground/primary'
                          text={completed ? t('course-overview.completed') : formattedTimeLeft}
                        />
                      </IconList>
                      {levelTranslationKey !== undefined && (
                        <>
                          <Text size='small' color='foreground/muted'>
                            {narrowDotSeparator}
                          </Text>
                          <Spacer size='xxsmall' />
                          <Text size='small' color='foreground/muted'>
                            {t(levelTranslationKey)}
                          </Text>
                        </>
                      )}
                    </>
                  )
                })()}
              </FlexContainer>
            </TextContainer>
          </InfoContainerInner>
        </LinkWrapper>

        {!locked && course.type === 'native:live' && course.selfEnrollSessions !== undefined ? (
          <>
            <Spacer size='xsmall' />
            <SelfEnrollLiveSessionList
              courseId={course.id}
              courseImage={image}
              sessions={course.selfEnrollSessions}
            />
          </>
        ) : !locked && course.type === 'native:live' ? (
          <>
            <Spacer size='4' />
            <RouterLink href={prependUrlWithProgramAndPath(`/course/${course.id}`)}>
              <LinkLabel>{t('dictionary.details')}</LinkLabel>
            </RouterLink>
          </>
        ) : null}
      </InfoContainer>
      {!locked && (
        <LinkWrapper href={href}>
          <ArrowIcon iconId='chevron--down' color='foreground/primary' size='size-16' />
        </LinkWrapper>
      )}
    </ListItemContainer>
  )
}

const Wrapper = styled.div`
  ${ListItemContainer} {
    &:last-of-type {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1.5rem;
        right: 1.5rem;
        height: 1px;
        background-color: ${palette.primitives.white};
      }
    }
  }
`

interface CourseListProps {
  sequentialLearning: boolean
  availableCourses: CourseWithStatus[]
  lockedCourses: CourseWithStatus[]
  pathId: string
}

export const CourseList = ({
  sequentialLearning,
  availableCourses,
  lockedCourses,
  pathId,
}: CourseListProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const firstNotPassedCourse = availableCourses.find(course => course.status.passedTimestamp === undefined)

  const createViewLog = (
    inView: boolean,
    courseId: CourseId,
    container: ImpressionContainers,
    containerPosition: number,
    itemPosition: number
  ): void => {
    if (inView) {
      void dispatch(
        Logging.path.pathCourseImpression({ courseId, container, containerPosition, itemPosition })
      )
    }
  }

  const title = firstNotPassedCourse?.title

  return (
    <Wrapper>
      <Text size='small' tablet='large' bold>
        {t('path-overview.outline')}
      </Text>
      <Spacer size='xsmall' phone='small' />
      {availableCourses.map((course, index) => {
        return (
          <InView
            key={course.id}
            onChange={inView => createViewLog(inView, course.id, 'course-list', 0, index)}
            triggerOnce
          >
            <CourseListItem
              key={course.id}
              course={course}
              completed={Boolean(course.status.passedTimestamp)}
              pathId={pathId}
            />
          </InView>
        )
      })}
      {sequentialLearning && firstNotPassedCourse && (
        <>
          <LockedContentContainer>
            <Text size='regular' color='grey35' align='center'>
              {t('path-overview.complete-to-unlock', {
                courseTitle: title,
              })}
            </Text>
          </LockedContentContainer>
          {lockedCourses.map(course => {
            return (
              <CourseListItem
                key={course.id}
                course={course}
                locked
                completed={Boolean(course.status.passedTimestamp)}
                pathId={pathId}
              />
            )
          })}
        </>
      )}
    </Wrapper>
  )
}
