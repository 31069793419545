import { useAtomValue } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { percentage as formatPercentage } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  numbersColumn,
  progressColumn,
  stringsColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  AnyColumnDefinition,
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ExportCSVButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { queryKeyTabularUserGroupMembers } from 'sierra-client/views/manage/user-groups/query-keys'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { getAccessLevelTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { UserAccessLevel } from 'sierra-domain/access-level'
import { UserGroupMember } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminGroupsListUserGroupMembers } from 'sierra-domain/routes'
import { CustomUserAttributeMetadata } from 'sierra-domain/user/custom-user-attribute-metadata'
import { asNonNullable, isNullable } from 'sierra-domain/utils'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// Access level is being phased out, so we don't want it in the general userInfo object.
// We still need it here though, so that's why we overwrite the type like this.
type LegacyUserGroupMember = UserGroupMember & { accessLevel: UserAccessLevel | undefined }

type UserGroupMemberTableDefinition = TableDefinitionOf<
  LegacyUserGroupMember,
  [
    { type: 'users'; ref: 'member' },
    { type: 'numbers'; ref: 'numberOfCourses' },
    { type: 'progress'; ref: 'progress' },
    { type: 'strings'; ref: 'role' },
    ...AnyColumnDefinition<LegacyUserGroupMember>[],
  ]
>

type UserGroupMemberTableData = TableDataFromDefinition<LegacyUserGroupMember, UserGroupMemberTableDefinition>
type UserGroupMemberTableMeta = {
  cursor: string | undefined
  customAttributes: CustomUserAttributeMetadata[]
}

const tableDefinition = (
  t: TranslationLookup,
  customAttributes: CustomUserAttributeMetadata[]
): UserGroupMemberTableDefinition => {
  return {
    columns: [
      usersColumn({
        sortable: false,
        getData: r => {
          const u = r.userInfo
          return {
            id: u.userId,
            email: u.email,
            status: u.status,
            avatar: getAvatarImage(u.userId, u.avatar),
            firstName: u.firstName !== undefined ? u.firstName : '',
            lastName: u.lastName !== undefined ? u.lastName : '',
            avatarColor: u.avatarColor,
            isRequiredAssignment: undefined,
            recurrences: undefined,
          }
        },
        header: translatedLabel('table.name'),
        ref: 'member',
      }),
      numbersColumn({
        sortable: false,
        getData: r => r.numAssignedCourses,
        header: translatedLabel('admin.analytics.courses'),
        ref: 'numberOfCourses',
      }),
      progressColumn({
        sortable: false,
        getData: r => {
          return {
            progress: r.progress,
          }
        },
        header: translatedLabel('admin.analytics.table.progress'),
        ref: 'progress',
        tooltip: translatedLabel('manage.users.tooltip.total-completion'),
      }),
      stringsColumn({
        sortable: false,
        getData: r => t(getAccessLevelTranslationKey(r.accessLevel ?? 'learner')),
        header: translatedLabel('table.role'),
        ref: 'role',
      }),
      ...customAttributes.map(attr =>
        stringsColumn({
          getData: (r: LegacyUserGroupMember) => {
            return r.customUserAttributes[attr.key] ?? ''
          },
          header: { type: 'untranslated', label: attr.label },
          ref: attr.key,
          enabled: false,
        })
      ),
    ],
    nested: {},
    rows: {
      getId: r => r.userInfo.userId,
    },
  }
}

const useUserGroupMemberTable = ({
  groupId,
  onRemove,
  canEditMembers,
}: Pick<UserGroupMembersTableProps, 'groupId' | 'onRemove' | 'canEditMembers'>): UseTableAPI<
  UserGroupMemberTableData,
  UserGroupMemberTableMeta
> => {
  const { t } = useTranslation()
  const loader = createDataLoader({
    async fetchInit({ control, predicate }) {
      const response = await typedPost(XRealtimeAdminGroupsListUserGroupMembers, {
        groupId,
        sortBy: { type: 'name', direction: 'asc' },
        query: predicate.query,
        limit: control.limit,
      })

      return {
        meta: { cursor: response.next, customAttributes: response.customUserAttributesMetadata },
        data: response.data.map(d => ({ ...d, accessLevel: response.accessLevels[d.userInfo.userId] })),
        totalCount: response.totalCount,
        done: isNullable(response.next),
      }
    },

    async fetchMore({ control, meta, predicate }) {
      const response = await typedPost(XRealtimeAdminGroupsListUserGroupMembers, {
        groupId,
        next: meta.cursor,
        sortBy: { type: 'name', direction: 'asc' },
        query: predicate.query,
        limit: control.limit,
      })

      return {
        meta: { cursor: response.next, customAttributes: response.customUserAttributesMetadata },
        data: response.data.map(d => ({ ...d, accessLevel: response.accessLevels[d.userInfo.userId] })),
        totalCount: response.totalCount,
        done: isNullable(response.next),
      }
    },
    transformResults(data, meta) {
      return [definition2Data(tableDefinition(t, meta.customAttributes), data)]
    },
  })

  return useTableAPI({
    dataLoader: useMemo(
      () => ({
        options: {
          queryKey: queryKeyTabularUserGroupMembers(groupId),
        },
        loader,
      }),
      [loader, groupId]
    ),
    options: {
      limit: 50,
      queryStateKeyPrefix: TabularQsKey.USERS,
    },
    virtualColumns: {
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  label: t('manage.view-details'),
                  onClick: () =>
                    getGlobalRouter().navigate({ to: `/manage/users/${row.rawData.userInfo.userId}` }),
                  icon: 'user',
                },
                {
                  type: 'label',
                  id: 'remove-user',
                  label: t('admin.remove'),
                  onClick: () => onRemove([row.rawData.userInfo.userId]),
                  icon: 'user--remove',
                  color: 'destructive/background',
                  hidden: !canEditMembers,
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

const userToCsv = (
  user: UserGroupMember,
  accessLevel: UserAccessLevel,
  customUserAttributesMetadata: CustomUserAttributeMetadata[]
): Record<string, unknown> => {
  return {
    firstName: user.userInfo.firstName,
    lastName: user.userInfo.lastName,
    email: user.userInfo.email,
    status: user.userInfo.status,
    progress: formatPercentage(user.progress),
    numAssignedCourses: user.numAssignedCourses,
    accessLevel: accessLevel,
    ...Object.fromEntries(
      customUserAttributesMetadata.map(attr => [attr.label, user.customUserAttributes[attr.key]])
    ),
  }
}

const fetchCsvData = async (groupId: string): Promise<Array<Record<string, unknown>>> => {
  const { data, accessLevels, customUserAttributesMetadata } = await typedPost(
    XRealtimeAdminGroupsListUserGroupMembers,
    {
      groupId,
      limit: 50_000,
      sortBy: { type: 'name', direction: 'asc' },
    }
  )

  return data.map(user =>
    userToCsv(user, asNonNullable(accessLevels[user.userInfo.userId]), customUserAttributesMetadata)
  )
}

type ToolbarProps = {
  tableAPI: UseTableAPI<UserGroupMemberTableData, UserGroupMemberTableMeta>
  fetchCsv: () => Promise<Record<string, unknown>[]>
} & Pick<UserGroupMembersTableProps, 'onAddUsers' | 'onShowHeatmap' | 'canEditMembers'>

const Toolbar: React.FC<ToolbarProps> = ({
  tableAPI,
  onAddUsers,
  onShowHeatmap,
  canEditMembers,
  fetchCsv,
}) => {
  const { t } = useTranslation()
  const query = useAtomValue(tableAPI.api.atoms.query)

  const [searchTermDebounce, searchTerm, setSearchTerm] = useDebouncedAndLiveState<string>(query ?? '')

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: searchTermDebounce })
  }, [searchTermDebounce, tableAPI.api.action, tableAPI.api.action.setQuery])

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar value={searchTerm} onChange={setSearchTerm} placeholder={t('manage.search.users')} />
      <View>
        {canEditMembers && (
          <Button icon='face--add' variant='secondary' onClick={onAddUsers}>
            {t('admin.add-users')}
          </Button>
        )}
        {onShowHeatmap !== undefined && (
          <Button icon='trend--up' variant='secondary' onClick={onShowHeatmap}>
            {t('manage.heatmap.title')}
          </Button>
        )}
        <ExportCSVButton fetchCsvData={fetchCsv} filename={t('admin.organization.users.users')} />
      </View>
    </View>
  )
}

export type UserGroupMembersTableProps = {
  groupId: string
  onRemove: (userIds: UserId[]) => void
  onAddUsers: () => void
  onShowHeatmap?: () => void
  canEditMembers: boolean
}

const Count = styled(View).attrs({
  radius: 'size-28',
  background: 'grey5',
  color: 'black',
  paddingLeft: '6',
  paddingRight: '6',
})``

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const pagination = useAtomValue(api.atoms.pagination)
  return (
    <View>
      <Text size='large' bold>
        {t('table.members')}
      </Text>
      <Count>
        <Text color='foreground/primary' size='micro' bold>
          {pagination.total}
        </Text>
      </Count>
    </View>
  )
}

export const UserGroupMembersTable: React.FC<UserGroupMembersTableProps> = ({
  groupId,
  canEditMembers,
  onAddUsers,
  onRemove,
  onShowHeatmap,
}) => {
  const tableAPI = useUserGroupMemberTable({ groupId, onRemove, canEditMembers })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/users/${rawData.userInfo.userId}` })
        },
      }}
    >
      <Header />
      <Toolbar
        fetchCsv={() => fetchCsvData(groupId)}
        tableAPI={tableAPI}
        canEditMembers={canEditMembers}
        onAddUsers={onAddUsers}
        onShowHeatmap={onShowHeatmap}
      />
      <UserDetailsTabularTable />
    </TabularProviderFromTableAPI>
  )
}
