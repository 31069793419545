import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import {
  SearchBar,
  SearchBarContainer,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { LightOrganization, ORGANIZATION_IDS } from 'sierra-domain/organization'
import { XRealtimeOwnerGetDomainAccess, XRealtimeOwnerListOrganizations } from 'sierra-domain/routes'

const SearchOrganizations = ({
  currentOrganization,
  onSelect,
  filterPredicate,
}: {
  currentOrganization?: string
  onSelect: (organization: LightOrganization) => Promise<void>
  filterPredicate: (organization: LightOrganization) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const [rawOrganizations, setRawOrganizations] = useState<LightOrganization[]>([])
  const [filter, setFilter] = useState<string>('')
  const { postWithUserErrorException } = usePost()

  const [selectedIndex, setSelectedIndex] = useState(0)

  useLayoutEffect(() => {
    if (!user) return

    void (async (): Promise<void> => {
      setRawOrganizations(await postWithUserErrorException(XRealtimeOwnerListOrganizations, {}))
    })()
  }, [postWithUserErrorException, user])

  const organizations = useMemo(
    () => _.chain(rawOrganizations).filter(filterPredicate).sortBy('name').value(),
    [rawOrganizations, filterPredicate]
  )

  const filteredOrganizations = useMemo(
    () =>
      filter.length === 0
        ? organizations
        : fuzzysort.go(filter, organizations, { keys: ['name', 'tenantId'] }).map(({ obj }) => obj),
    [filter, organizations]
  )

  return (
    <>
      <SearchBarContainer>
        <SearchBar
          value={filter}
          placeholder={t('admin.organization.search-organization')}
          label={'Switch organization'}
          onChange={text => {
            setFilter(text)
            setSelectedIndex(0)
          }}
          onIndexChanged={value => {
            setSelectedIndex(prev =>
              resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, filteredOrganizations)
            )
          }}
          onResultSelected={() => {
            const organization = filteredOrganizations[selectedIndex]
            if (organization !== undefined) void onSelect(organization)
          }}
        />
      </SearchBarContainer>
      <SearchResults>
        {filteredOrganizations.map((organization, idx) => (
          <SearchResult
            $selected={idx === selectedIndex}
            onClick={() => {
              void onSelect(organization)
            }}
            key={`org-${idx}`}
          >
            {organization.name} ({organization.tenantId})
            {organization.uuid === currentOrganization && <> (current)</>}
          </SearchResult>
        ))}
      </SearchResults>
    </>
  )
}

export const SwitchOrganization: React.FC = () => {
  const { postWithUserErrorException } = usePost()

  const filterPredicate = useCallback(
    (organization: LightOrganization) => organization.uuid !== ORGANIZATION_IDS.Sierra,
    []
  )

  const user = useSelector(selectUser)

  const onSwitchOrganization = async ({ uuid }: LightOrganization): Promise<void> => {
    if (user === undefined) return

    const response = await postWithUserErrorException(XRealtimeOwnerGetDomainAccess, {
      organizationId: uuid,
    })

    window.open(response.url, '_blank', 'noopener,noreferrer')
  }

  if (user === undefined) return null
  if (user.virtualAt !== undefined) return <>This action is not supported for virtual users</>
  return (
    <SearchOrganizations
      currentOrganization={user.organizationUuid}
      onSelect={onSwitchOrganization}
      // Prevent switching to Sierra organization.
      filterPredicate={filterPredicate}
    />
  )
}
