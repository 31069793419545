import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useState } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { floatingPill } from 'sierra-client/components/common/header-view'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Logging } from 'sierra-client/core/logging'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  LiveSessionModalActions,
  useLiveSessionModalActions,
} from 'sierra-client/views/manage/live-session/components/live-session-modal-actions'
import { SideBar } from 'sierra-client/views/manage/live-session/sections/side-bar'
import { LiveSessionRecordingsTable } from 'sierra-client/views/manage/live-session/tables/live-session-recordings-table'
import { LiveSessionUsersTable } from 'sierra-client/views/manage/live-session/tables/live-session-users-table'
import { useLiveSessionDetails } from 'sierra-client/views/manage/live-session/use-live-session-details'
import { liveSessionUsersQueryKey } from 'sierra-client/views/manage/live-session/util/query-keys'
import { LiveSessionRecording } from 'sierra-domain/api/admin'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { Label, Tooltip } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const VideoWrapper = styled(motion.div)`
  z-index: 1;
  width: 30rem;
  max-width: 90%;
  min-width: 16rem;
  aspect-ratio: 16 / 9;
  background: black;
  padding: ${spacing['4']};
  border-radius: ${p => p.theme.borderRadius.regular};
  resize: horizontal;
  overflow: auto;
`

const RecordingButton = styled(View)`
  ${floatingPill}
  width: fit-content;
  margin-top: 16px;
`

const BottomRightContainer = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 4.5rem;
`

export const ManageLiveSessionDetails: React.FC<{
  liveSessionId: LiveSessionId
  canEditAssignments: boolean
}> = ({ liveSessionId, canEditAssignments }) => {
  const { t } = useTranslation()
  const notif = useNotif()

  const liveSessionDetails = useLiveSessionDetails(liveSessionId)
  const { liveSession, liveSessionRecordings, setAttendance } = liveSessionDetails
  const queryClient = useQueryClient()
  const modal = useLiveSessionModalActions()
  const [recordingUrl, setRecordingUrl] = useState<string>()
  const [showRecordingPlayer, setShowRecordingPlayer] = useState<boolean>(false)
  const [recordingId, setRecordingId] = useState<string>()
  const canMarkAttendance = useOrganizationPermissions().has('MARK_LIVE_SESSION_ATTENDANCE')

  const dispatch = useDispatch()

  const onClickRecording = (clickedRecording: LiveSessionRecording): void => {
    if (recordingId === clickedRecording.id) {
      setShowRecordingPlayer(showRecordingPlayer => !showRecordingPlayer)
    } else {
      setRecordingId(clickedRecording.id)
      setRecordingUrl(clickedRecording.hlsUrl)
      setShowRecordingPlayer(false)
      setTimeout(() => {
        setShowRecordingPlayer(true)
      }, 500)
      void dispatch(
        Logging.liveSession.watchedRecording({ context: 'manage', recordingId: clickedRecording.id })
      )
    }
  }

  const onEnrollUsers = useCallback(() => {
    modal.open({ modal: 'enroll-users' })
  }, [modal])

  const onRemoveUsers = useCallback(
    (userIds: UserId[]) => {
      modal.open({ modal: 'unassign-users', ids: userIds })
    },
    [modal]
  )

  const onRemoveRecording = useCallback(
    (recordingId: string) => {
      modal.open({ modal: 'remove-recording', id: recordingId })
    },
    [modal]
  )

  const onSetAttendance = useCallback(
    async (userIds: UserId[], attendance: LiveSessionAttendanceStatus) => {
      await setAttendance({
        liveSessionId,
        attendance: userIds.map(userId => ({ userId, attendance })),
      })
      void queryClient.invalidateQueries({ queryKey: liveSessionUsersQueryKey(liveSessionId) })
      notif.push({
        type: 'custom',
        level: 'success',
        body: t('notifications.done'),
      })
    },
    [liveSessionId, notif, setAttendance, t, queryClient]
  )

  if (liveSession === undefined) return null

  const sessionIsClosed = liveSession.liveSession.data.endedAt !== undefined

  // TODO(seb): Add separate location

  return (
    <ColumnLayout>
      <DetailsHeader
        backlink={{
          href: `/manage/courses/${liveSession.liveSession.data.flexibleContentId}`,
          label: 'dictionary.back',
        }}
        titleAddon={
          <Label $size='small' $color='white' $bgColor='grey40'>
            {t('dictionary.session')}
          </Label>
        }
      />
      <ManageDetailContainer>
        <ManageDetailGrid>
          <ManageDetailSidebar>
            <SideBar liveSession={liveSession} />
          </ManageDetailSidebar>
          <ManageDetailContent>
            <LiveSessionUsersTable
              contentId={liveSessionId}
              onSetAttendance={onSetAttendance}
              facilitatorIds={liveSession.facilitatorsInfo.map(it => it.userId)}
              canMarkAttendance={canMarkAttendance}
              onRemoveUsers={onRemoveUsers}
              canEditAssignments={canEditAssignments}
              openEnrollUsers={onEnrollUsers}
              assignmentCount={liveSession.assignmentsCount}
            />
            <LiveSessionRecordingsTable
              data={liveSessionRecordings.data}
              onRemoveRecording={onRemoveRecording}
              onRecordingClicked={onClickRecording}
              sessionIsClosed={sessionIsClosed}
              sessionId={liveSessionId}
            />
          </ManageDetailContent>
        </ManageDetailGrid>
      </ManageDetailContainer>
      {recordingUrl !== undefined && (
        <AnimatePresence>
          {showRecordingPlayer && (
            <BottomRightContainer>
              <VideoWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <VideoPlayer
                  authSearchParams={new URL(recordingUrl).search}
                  options={{
                    autoplay: true,
                    bigPlayButton: true,
                    controls: true,
                    controlBar: { pictureInPictureToggle: false }, // add this toggle
                    // playbackRates: [1, 1.25, 1.5, 2],
                    preload: 'auto',
                    fill: true,
                    sources: [{ src: recordingUrl, type: 'application/x-mpegURL' }],
                  }}
                />
              </VideoWrapper>
              <Tooltip title='Close recording'>
                <RecordingButton>
                  <IconButton
                    iconId='close'
                    variant='transparent'
                    onClick={() => setShowRecordingPlayer(isShowing => !isShowing)}
                  />
                </RecordingButton>
              </Tooltip>
            </BottomRightContainer>
          )}
        </AnimatePresence>
      )}

      <LiveSessionModalActions
        control={modal}
        liveSessionId={liveSessionId}
        liveSessionDetails={liveSessionDetails}
      />
    </ColumnLayout>
  )
}
