import { useQuery } from '@tanstack/react-query'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { queryClient } from 'sierra-client/api/query-client'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { segmentService } from 'sierra-client/core/segment'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserSetRegistrationStep } from 'sierra-domain/routes'
import { UserLoggedOutRequestParams } from 'sierra-domain/segment/event'
import { UserRegistrationStep } from 'sierra-domain/user'
import { RoundAvatar } from 'sierra-ui/components'
import { Spacer, View } from 'sierra-ui/primitives'
import { LightTokenProvider } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const common = css`
  flex: 1;
  align-self: stretch;

  display: flex;
  flex-direction: row;
`

const Right = styled.div`
  ${common};

  justify-content: flex-end;
  align-items: center;
`

const Separator = styled.div`
  height: 0.75rem;
  width: 1px;
  background-color: ${p => p.theme.text.default};
  opacity: 0.25;
  margin-right: 1rem;
`

type HeaderView = 'none' | 'logout' | 'skippable' | 'verify'

function computeHeaderView({
  isLoggedIn,
  registrationStep,
  isPendingEmailVerification,
  isDisabled,
}: {
  isLoggedIn: boolean
  registrationStep: UserRegistrationStep | undefined
  isPendingEmailVerification: boolean
  isDisabled: boolean
}): HeaderView {
  if (isLoggedIn) {
    if (isDisabled) return 'logout'
    switch (registrationStep) {
      case 'account-details':
        return 'logout'
      case 'skills':
        return 'skippable'
    }
    if (isPendingEmailVerification) return 'verify'
    return 'none'
  }
  return 'none'
}

const HeaderTextWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: 16px 30px 16px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const AuthHeader = (): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const { data: user } = useQuery(currentUserQuery)
  const isLoggedIn = Boolean(user)
  const registrationStep = user?.registrationStep
  const isPendingEmailVerification = Boolean(user?.isPendingEmailVerification)
  const isDisabled = Boolean(user?.disabled)

  const view = computeHeaderView({
    isLoggedIn,
    registrationStep,
    isPendingEmailVerification,
    isDisabled,
  })

  const skip = async (): Promise<void> => {
    const result = await postWithUserErrorCode(XRealtimeUserSetRegistrationStep, {
      step: 'done',
    })

    if (isLeft(result)) {
      // eslint-disable-next-line no-console
      console.log(t(getUserErrorTranslationKey(result.left)))
      return
    }

    await queryClient.invalidateQueries({ queryKey: currentUserQuery.queryKey, exact: true })
  }

  return (
    <LightTokenProvider>
      <HeaderTextWrapper>
        <Right>
          <View>
            {isLoggedIn && (view === 'logout' || view === 'verify') && (
              <>
                <SecondaryButton
                  onClick={() => {
                    void segmentService.track(new UserLoggedOutRequestParams())
                    void getAuthClient().signOut()
                  }}
                >
                  {t('topbar.log-out')}
                </SecondaryButton>
                <Spacer />
              </>
            )}
            {view === 'skippable' && (
              <>
                <SecondaryButton onClick={skip}>{t('dictionary.skip')}</SecondaryButton>
                <Spacer />
              </>
            )}
            {isLoggedIn && view !== 'logout' && (
              <>
                {view !== 'none' && <Separator />}
                <RoundAvatar
                  size='small'
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  src={getAvatarImage(user?.uuid, user?.avatar)}
                  color={user?.avatarColor}
                />
              </>
            )}
          </View>
        </Right>
      </HeaderTextWrapper>
    </LightTokenProvider>
  )
}
