import React, { useCallback, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { GlobalFilter } from 'sierra-client/components/table/global-filter'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  RenderCountsPrimitive,
  ToolbarContainer,
  ToolbarLeftContainer,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { getGlobalRouter } from 'sierra-client/router'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActionButton, BetweenContainer } from 'sierra-client/views/manage/components/common'
import { ExportCSVButton } from 'sierra-client/views/manage/components/export-csv'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  ManageTableLarge,
  useManageTableLarge,
} from 'sierra-client/views/manage/components/manage-table-large'
import { TagLibraryModal } from 'sierra-client/views/manage/tags/components/tag-library'
import { RemoveTagModal } from 'sierra-client/views/manage/tags/modals'
import { mapTagToCsv, useTags } from 'sierra-client/views/manage/tags/use-tags'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { TagDetail } from 'sierra-domain/api/admin'
import { XRealtimeAdminTagsUpdateTagsState } from 'sierra-domain/routes'
import { TruncatedText } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

export const ManageTags: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()

  const { tags, isLoading, fetchTags } = useTags()

  // Active action/modal
  const [action, setAction] = useState<
    | undefined
    | {
        action: 'add-tag'
      }
    | {
        action: 'duplicate' | 'remove'
        tagIds: string[]
      }
  >(undefined)

  const goToTag = useCallback((tagId: string) => {
    void getGlobalRouter().navigate({ to: `/manage/tags/${tagId}` })
  }, [])

  const columns: Column<TagDetail>[] = useMemo(
    () => [
      {
        id: 'name',
        width: '25%',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('table.name')} {...p} />
          </>
        ),
        accessor: tag => tag.tag.data.name,
        canSort: true,
        Cell: (p: CellProps<TagDetail>) => {
          const { name } = p.row.original.tag.data
          return (
            <>
              <SelectableRow {...p} />
              <PillRow>{name ? <Pill>{name}</Pill> : ''}</PillRow>
            </>
          )
        },
      },
      {
        id: 'description',
        width: '45%',
        Header: p => <SortableHeader label={t('admin.organization.description')} {...p} />,
        accessor: tag => tag.tag.data.description,
        Cell: ({ row }: CellProps<TagDetail>): JSX.Element => (
          <>
            {row.original.tag.data.description === undefined ||
            row.original.tag.data.description === '' ? null : ( // t('t.no-description')
              <TruncatedText lines={1} size='small' color='foreground/secondary'>
                {row.original.tag.data.description}
              </TruncatedText>
            )}
          </>
        ),
      },
      {
        id: 'coursesCount',
        width: '15%',
        Header: p => <SortableHeader label={t('admin.table.tags.courses')} {...p} />,
        accessor: tag => tag.totalCoursesCount,
      },
      {
        id: 'actions',
        width: '15%',
        Cell: ({ row }: CellProps<TagDetail>) => (
          <View gap='none' justifyContent='flex-end' alignItems='center'>
            <IconMenu
              iconId='overflow-menu--horizontal'
              variant='transparent'
              onSelect={item => {
                switch (item.id) {
                  case 'edit':
                    void getGlobalRouter().navigate({ to: `/manage/tags/${row.original.id}` })
                    break
                  case 'remove':
                    setAction({
                      action: 'remove',
                      tagIds: [row.original.id],
                    })
                    break
                }
              }}
              menuItems={[
                {
                  id: 'edit',
                  type: 'label',
                  label: t('content.edit'),
                },
                {
                  id: 'remove',
                  type: 'label',
                  label: t('admin.remove'),
                  color: 'destructive/background',
                },
              ]}
            />
          </View>
        ),
        disableSortBy: true,
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableLarge({
    tableOptions: { data: tags, columns },
    getEntityId: s => s.id,
  })

  const selectedIds = {
    tagIds: tableInstance.selectedFlatRows.map(r => r.original.id),
  }

  return (
    <>
      <PageTitle title={t('table.skills')} />

      <ColumnLayout>
        <PageHeader
          title='admin.organization.tags.title'
          withoutMargin
          helpLink='https://help.sana.ai/en/articles/64070-tags'
        />

        <View direction='column' gap='16'>
          <BetweenContainer>
            <GlobalFilter
              tableInstance={tableInstance}
              render={props => <AnimatedSearch {...props} placeholder={t('manage.search.tags')} />}
            />
            <Button
              id='tour-tags-addtags'
              variant='secondary'
              onClick={async () => setAction({ action: 'add-tag' })}
            >
              {t('content.add-tag')}
            </Button>
          </BetweenContainer>

          <ToolbarContainer>
            <ToolbarLeftContainer>
              <RenderCountsPrimitive>
                {t('learner-home.skills', { count: tags.length })}
              </RenderCountsPrimitive>
            </ToolbarLeftContainer>

            <ExportCSVButton
              color='secondary'
              fetchCsvData={() => tableInstance.rows.map(r => r.original).map(mapTagToCsv)}
              filename='admin.analytics.group'
            />
          </ToolbarContainer>
        </View>

        <ManageTableLarge
          tableInstance={tableInstance}
          isLoading={isLoading}
          getEntityId={s => s.id}
          onViewDetails={goToTag}
          bulkActions={
            <ActionButton color='redBright' onClick={() => setAction({ action: 'remove', ...selectedIds })}>
              {t('admin.remove')}
            </ActionButton>
          }
          translations={{
            tableLoading: t('manage.tags.table-loading'),
            tableEnd: t('manage.tags.table-end'),
            tableNoResults: t('manage.tags.no-results'),
            csvPrefix: t('admin.organization.tags.title'),
          }}
        />
        {action?.action === 'add-tag' && (
          <TagLibraryModal
            isOpen
            onClose={() => {
              setAction(undefined)
            }}
            onSave={async (tagIds: string[]) => {
              setAction(undefined)
              await postWithUserErrorException(XRealtimeAdminTagsUpdateTagsState, {
                tags: tagIds,
                state: 'enabled',
              })
              await fetchTags()
              notifications.push({
                type: 'custom',
                level: 'success',
                body: t('manage.tags.n-tags-added', { count: tagIds.length }),
              })
            }}
            onAfterSave={async () => {
              setAction(undefined)
              await fetchTags()
            }}
          />
        )}
        {action?.action === 'remove' && (
          <RemoveTagModal
            isOpen
            primaryAction={async () => {
              setAction(undefined)
              await postWithUserErrorException(XRealtimeAdminTagsUpdateTagsState, {
                tags: action.tagIds,
                state: 'disabled',
              })
              await fetchTags()
              notifications.push({
                type: 'custom',
                level: 'success',
                body: t('manage.tags.n-tags-removed', { count: action.tagIds.length }),
              })
            }}
            onClose={() => {
              setAction(undefined)
            }}
          />
        )}
      </ColumnLayout>
    </>
  )
}
