import { FC, useEffect, useMemo } from 'react'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { SelfPacedIds, useRequiredRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { accessDeniedRedirect, contentNotFoundRedirect } from 'sierra-client/router/navigation'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectCourseById, selectPrependUrlWithProgramAndPath } from 'sierra-client/state/content/selectors'
import { CourseEntity } from 'sierra-client/state/content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useResetSelfPacedSlice } from 'sierra-client/state/self-paced/hooks'
import { fetchCourseDataById, fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { selectTimeLeft } from 'sierra-client/state/v2/selectors'
import { OverviewHeader } from 'sierra-client/views/learner/components/overview/header'
import { CourseSummary } from 'sierra-client/views/learner/course/components/summary'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeContentGetUserContentIsRequiredAssignment } from 'sierra-domain/routes'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

type Url = string

const NavigateToNextPage: React.FC<{ url: Url }> = ({ url }) => {
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)
  const pathUrl = prependUrlWithProgramAndPath(url)

  useEffect(() => {
    void getGlobalRouter().navigate({ to: pathUrl, replace: true })
  }, [pathUrl])

  return null
}

const GlobalStyles = createGlobalStyle`
  :root {
    --gutter: 1rem;

    @media screen and (min-width: ${v2_breakpoint.phone}) {
      --gutter: 1.5rem;
    }

    @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
      --gutter: 2rem;
    }
  }

  #__next {
    /* This is to allow sticky behavior on the left column on desktop */
    @media screen and (min-width: ${v2_breakpoint.tablet}) {
      overflow: visible !important;
    }
  }
`

const _CourseDetailsPageWithPermission: FC<{
  course: CourseEntity
  timeLeft:
    | {
        total: number
        lessonsModules: Record<string, number>
        quizModules: Record<string, number>
      }
    | undefined
  flexibleContentId: SelfPacedContentId
}> = ({ course, timeLeft, flexibleContentId }) => {
  const learnerTheme = useLearnerTheme()
  useResetSelfPacedSlice()

  const assetContext = { type: 'course' as const, courseId: course.id }
  const imgSrc = useResolveAsset({ image: course.settings.image, assetContext, size: 'default' })
  const isRequiredAssignment =
    useCachedQuery(
      XRealtimeContentGetUserContentIsRequiredAssignment,
      { courseId: course.id },
      {
        retry: false,
      }
    ).data?.isRequiredAssignment === true

  return (
    <SanaPage
      mode='light'
      showIntercomLauncher={true}
      page={PageIdentifier.LearnerSelfPaced({ courseId: flexibleContentId })}
    >
      <ThemeProvider theme={learnerTheme}>
        <PageTitle title={course.settings.title} />
        <PageContainer>
          <GlobalStyles />
          <OverviewHeader
            title={course.settings.title}
            image={imgSrc}
            isRequiredAssignment={isRequiredAssignment}
          />
          <CourseSummary
            timeLeft={timeLeft?.total ?? 0}
            timeTotal={course.readingTimes?.total ?? 0}
            courseId={course.id}
            courseType={course.type}
            tagIds={course.settings.tags}
            description={course.settings.description}
          />
        </PageContainer>
      </ThemeProvider>
    </SanaPage>
  )
}

const CourseDetailsPageWithPermission: FC<SelfPacedIds> = ({ flexibleContentId }) => {
  const dispatch = useDispatch()
  const timeLeft = useSelector(selectTimeLeft)[flexibleContentId]

  useResetSelfPacedSlice()

  useEffect(() => {
    void dispatch(fetchCourseDataById({ courseId: flexibleContentId }))
    void dispatch(fetchCourseStatusById({ courseId: flexibleContentId }))
  }, [dispatch, flexibleContentId])

  const course = useSelector(state => selectCourseById(state, flexibleContentId))

  const redirectUrl: Url | undefined = useMemo(() => {
    if (course === undefined) return undefined

    const isIframe = window.self !== window.top

    // Only redirect directly to course content if the course is in an iframe
    if (isIframe) {
      return `/s/${flexibleContentId}/next`
    }

    return undefined
  }, [course, flexibleContentId])

  // We skip the course details page if there is no description and no smart review.
  if (redirectUrl !== undefined) {
    return <NavigateToNextPage url={redirectUrl} />
  }

  if (course === undefined) return null

  return (
    <_CourseDetailsPageWithPermission
      course={course}
      timeLeft={timeLeft}
      flexibleContentId={flexibleContentId}
    />
  )
}

export const CourseDetailsPage = (): JSX.Element => {
  const ids = useRequiredRouterSelfPacedIds()

  return (
    <RequireCoursePermission
      contentId={ids.flexibleContentId}
      minPermissionLevel='view'
      onAccessDenied={accessDeniedRedirect}
      onContentNotFound={contentNotFoundRedirect}
      showSpinner
    >
      <CourseDetailsPageWithPermission {...ids} />
    </RequireCoursePermission>
  )
}
