import { useMutation, UseMutationResult } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import { useRef, useState } from 'react'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { subscribeSse, typedPost } from 'sierra-client/state/api'
import { PreviousQAQuestion, QAQuestion } from 'sierra-domain/api/author-v2'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeStrategyAuthordGenerateQuestions,
  XRealtimeStrategySelfPacedGenerateQuestions,
} from 'sierra-domain/routes'
import {
  SSEXRealtimeAnswerQAQuestion,
  SSEXRealtimeStrategySelfPacedCreateCourseOutline,
} from 'sierra-domain/routes-sse'
import { assertNever, iife } from 'sierra-domain/utils'
const GenerateQuestionsRoute = {
  learner: XRealtimeStrategySelfPacedGenerateQuestions,
  editor: XRealtimeStrategyAuthordGenerateQuestions,
} as const

export type TutorModalState =
  | { type: 'showing-qa'; withInitialQuestion: string; knownSourceFileIds: FileId[] }
  | { type: 'showing-eli5'; forFileId: FileId }
  | { type: 'hidden' }

export type TutorStateAvailable = {
  type: 'available'
  qaState: QAState
  setTutorModalState: React.Dispatch<React.SetStateAction<TutorModalState>>
}
export type TutorStateUnavailable = { type: 'unavailable' }
export const tutorStateUnavailable: TutorState = { type: 'unavailable' }

export type TutorState = TutorStateAvailable | TutorStateUnavailable

export type QACardState =
  | { type: 'word-cloud'; previousChats: [] }
  | {
      type: 'chat'
      latestChatEntry: ChatEntry
      previousChats: PreviousChatEntry[]
    }

export type QAEntry = {
  type: 'question-and-answer'
  status: 'answering' | 'answered'
  query: string
  answer: string
  fileIds: FileId[]
}

export type QuizQuestion = {
  status: 'is-generating' | 'is-finished-generating'
  questionTitle: string
  alternatives: {
    text: string
    correct: boolean
  }[]
}

export type QuizEntry = {
  type: 'quiz'
  status: { type: 'loading' } | { type: 'loaded' }
  questions: QuizQuestion[]
}

export type ChatEntry = QAEntry | QuizEntry

export type PreviousChatEntry = (QAEntry & { status: 'answered' }) | QuizEntry

function getPreviousQuestions(state: QACardState): PreviousQAQuestion[] {
  const previous: PreviousQAQuestion[] = state.previousChats.flatMap(chat => {
    if (chat.type === 'quiz') return []
    return [
      {
        question: chat.query,
        fileIds: chat.fileIds,
      },
    ]
  })
  const current: PreviousQAQuestion[] =
    state.type !== 'word-cloud' && state.latestChatEntry.type === 'question-and-answer'
      ? [{ question: state.latestChatEntry.query, fileIds: state.latestChatEntry.fileIds }]
      : []

  return [...previous, ...current]
}

type GenerateQuestionsArgs = {
  latestQuestion?: PreviousQAQuestion | undefined
  specificFileIds?: FileId[] | undefined
}

export type QAState = {
  location: keyof typeof GenerateQuestionsRoute
  courseId: SelfPacedContentId
  startedFromFileId: FileId
  answerQuestionMutation: UseMutationResult<void, unknown, { question: string; knownSourceFileIds: FileId[] }>
  generateQuestionsMutation: UseMutationResult<{ questions: QAQuestion[] }, unknown, GenerateQuestionsArgs>
  startQuizMutation: UseMutationResult<void, unknown, void>
  cardState: QACardState
}

function extractQuestions({
  rawHtml,
  isFinishedStreaming,
}: {
  rawHtml: string
  isFinishedStreaming: boolean
}): QuizQuestion[] {
  const html = rawHtml
    // Replace demarcators for code blocks (the LLM likes to produce these)
    .replaceAll('```html', '')
    .replaceAll('```', '')
    // replace html at the end of the line
    .replace(/<\/$/, '')
    .replace(/<$/, '')
  const purified = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['div'],
    ALLOWED_ATTR: ['class', 'data-is-correct'],
  })

  const parser = new DOMParser()
  const doc = parser.parseFromString(purified, 'text/html')

  const questions: QuizQuestion[] = []

  const questionDivs = doc.querySelectorAll('.question')
  questionDivs.forEach((questionDiv, index) => {
    const titleContent = questionDiv.querySelector('.title')?.textContent
    if (titleContent === null) {
      console.error('Missing question in title')
    }

    const title: QuizQuestion['questionTitle'] = titleContent ?? ''
    const alternatives: QuizQuestion['alternatives'] = []
    for (const alternativeDiv of questionDiv.querySelectorAll('.alternative')) {
      const text = alternativeDiv.textContent
      if (text === null) {
        console.error('Missing alternative text')
      }
      const correctText = alternativeDiv.getAttribute('data-is-correct')
      if (correctText === null) {
        console.error('Missing data-is-correct attribute')
      }
      const correct = correctText === 'true' ? true : false

      alternatives.push({ text: text ?? '', correct })
    }

    const status: QuizQuestion['status'] = iife(() => {
      if (isFinishedStreaming) return 'is-finished-generating'

      // If we are still streaming questions, we mark the last one
      // as "is-generating" to indicate that it might include more content
      if (index === questionDivs.length - 1) return 'is-generating'

      return 'is-finished-generating'
    })

    questions.push({ questionTitle: title, alternatives, status })
  })

  return questions
}

export function useQAState({
  courseId,
  fileId,
  location,
}: {
  courseId: SelfPacedContentId
  fileId: FileId
  location: keyof typeof GenerateQuestionsRoute
}): QAState {
  const [cardState, setCardState] = useState<QACardState>({ type: 'word-cloud', previousChats: [] })
  const generateQuestionsMutation = useMutation({
    mutationFn: ({ latestQuestion, specificFileIds }: GenerateQuestionsArgs) => {
      const previousQuestions = [...getPreviousQuestions(cardState), latestQuestion].filter(
        it => it !== undefined
      )
      if (location === 'learner') {
        return typedPost(GenerateQuestionsRoute[location], {
          courseId,
          fileId,
          previousQuestions,
          specificFileIds,
        })
      } else {
        return typedPost(GenerateQuestionsRoute[location], {
          createContentId: courseId,
          fileId,
          previousQuestions,
          specificFileIds,
        })
      }
    },
  })
  const abortControllerRef = useRef<AbortController | undefined>(undefined)

  useOnUnmount(() => {
    abortControllerRef.current?.abort()
  })

  const getNewPreviousChats = (previousState: QACardState): PreviousChatEntry[] => {
    if (previousState.type !== 'chat') return []
    if (previousState.latestChatEntry.type === 'question-and-answer')
      return [...previousState.previousChats, { ...previousState.latestChatEntry, status: 'answered' }]

    return [...previousState.previousChats, previousState.latestChatEntry]
  }

  const answerQuestionMutation = useMutation({
    mutationFn: async ({
      question,
      knownSourceFileIds,
    }: {
      question: string
      knownSourceFileIds: FileId[]
    }) => {
      abortControllerRef.current?.abort()
      const abortController = new AbortController()
      abortControllerRef.current = abortController

      setCardState(previousState => {
        const previousChats = getNewPreviousChats(previousState)
        return {
          type: 'chat',
          latestChatEntry: {
            type: 'question-and-answer',
            status: 'answering',
            query: question,
            answer: '',
            fileIds: [],
          },
          previousChats,
        }
      })

      await subscribeSse({
        route: SSEXRealtimeAnswerQAQuestion,
        input: {
          courseId,
          fileId,
          location,
          question,
          knownSourceFileIds,
        },
        onMessage: event => {
          if (abortController.signal.aborted) {
            return
          }

          const data = event.data
          switch (data.type) {
            case 'matching-file-ids-event':
              generateQuestionsMutation.mutate({
                latestQuestion: { question, fileIds: data.fileIds },
              })
              setCardState(previousState => {
                if (previousState.type !== 'chat') return previousState
                const latestChatEntry = previousState.latestChatEntry
                if (latestChatEntry.type !== 'question-and-answer') return previousState

                const newLatest: ChatEntry = { ...latestChatEntry, fileIds: data.fileIds }
                const newState: QACardState = { ...previousState, latestChatEntry: newLatest }
                return newState
              })
              break
            case 'answer-event':
              setCardState(previousState => {
                if (previousState.type !== 'chat') return previousState
                const latestChatEntry = previousState.latestChatEntry
                if (latestChatEntry.type !== 'question-and-answer') return previousState

                const newLatest = { ...latestChatEntry, answer: latestChatEntry.answer + data.text }
                return { ...previousState, latestChatEntry: newLatest }
              })
              break
            default:
              data satisfies never
          }
        },
        signal: abortController.signal,
      })

      if (abortController.signal.aborted) {
        return
      }

      setCardState(previousState => {
        if (previousState.type !== 'chat') return previousState
        const latestChatEntry = previousState.latestChatEntry
        if (latestChatEntry.type !== 'question-and-answer') return previousState
        if (latestChatEntry.status !== 'answering') return previousState

        const newLatest: ChatEntry = { ...latestChatEntry, status: 'answered' }
        const newQACardState: QACardState = { ...previousState, latestChatEntry: newLatest }
        return newQACardState
      })
      return undefined
    },
  })

  const startQuizMutation: QAState['startQuizMutation'] = useMutation({
    mutationFn: async () => {
      abortControllerRef.current?.abort()
      const abortController = new AbortController()
      abortControllerRef.current = abortController

      setCardState(previousState => {
        const previousChats = getNewPreviousChats(previousState)
        const newState: QACardState = {
          type: 'chat',
          latestChatEntry: {
            type: 'quiz',
            status: { type: 'loading' },
            questions: [],
          },
          previousChats,
        }
        return newState
      })

      let rawHtml = ''

      await subscribeSse({
        route: SSEXRealtimeStrategySelfPacedCreateCourseOutline,
        input: { courseId, fileId, location },
        onMessage: event => {
          if (abortController.signal.aborted) {
            return
          }

          const data = event.data
          switch (data.type) {
            case 'question-html-event': {
              rawHtml += data.text
              const questions = extractQuestions({ rawHtml, isFinishedStreaming: false })
              setCardState(previousState => {
                if (previousState.type !== 'chat') return previousState
                const latestChatEntry = previousState.latestChatEntry
                if (latestChatEntry.type !== 'quiz') return previousState

                const newLatest: QuizEntry = { ...latestChatEntry, questions }
                const newState: QACardState = { ...previousState, latestChatEntry: newLatest }
                return newState
              })
              break
            }
            default:
              assertNever(data.type)
          }
        },
        signal: abortController.signal,
      })

      if (abortController.signal.aborted) {
        return
      }

      setCardState(previousState => {
        if (previousState.type !== 'chat') return previousState
        const latestChatEntry = previousState.latestChatEntry
        if (latestChatEntry.type !== 'quiz') return previousState
        const newLatest: QuizEntry = {
          ...latestChatEntry,
          status: { type: 'loaded' },
          questions: extractQuestions({ rawHtml, isFinishedStreaming: true }),
        }
        const newState: QACardState = { ...previousState, latestChatEntry: newLatest }
        return newState
      })
    },
  })

  return {
    location,
    answerQuestionMutation,
    generateQuestionsMutation,
    startQuizMutation,
    cardState,
    courseId,
    startedFromFileId: fileId,
  }
}
