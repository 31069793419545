/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'
import _ from 'lodash'
import { updateCollaborators } from 'sierra-client/state/author-course-settings/actions'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { UserId } from 'sierra-domain/api/uuid'

export type Mention = {
  type: 'mention'
  attrs: { uuid: UserId; role?: 'editor' | 'commenter'; label: string }
}

export const getMentions = (tiptapJsonData: JSONContent): Mention[] | undefined => {
  return tiptapJsonData.content?.[0]?.content?.filter(({ type }) => type === 'mention') as
    | Mention[]
    | undefined
}

export const createCommentersFromMentions = ({
  tiptapJsonData,
  dispatch,
  previousCollaborators,
}: {
  tiptapJsonData: JSONContent
  dispatch: Dispatch<any>
  previousCollaborators: Collaborator[]
}): void => {
  const mentions = getMentions(tiptapJsonData)

  if (mentions !== undefined) {
    const newCommenters = _.chain(mentions)
      .uniqBy(({ attrs: { uuid } }) => uuid)
      .filter(({ attrs: { role } }) => role === undefined)
      .map(({ attrs: { uuid } }) => ({ uuid, role: 'commenter' }))
      .value()

    if (newCommenters.length > 0) {
      void dispatch(
        updateCollaborators({ previousCollaborators, changedCollaborators: newCommenters as Collaborator[] })
      )
    }
  }
}
