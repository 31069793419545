import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { UsersByIdsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { mapBasicUserToIdentity } from 'sierra-client/components/common/identities-selector/identities-utils'
import { useAllUsersFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { UserId } from 'sierra-domain/api/uuid'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'

const usersByIdsQuery = graphql(`
  query UsersByIds($ids: [UserId!]!) {
    users: usersFromIds(ids: $ids) {
      id
      displayName
      email
      avatar {
        ...AvatarFragment
      }
    }
  }
`)

const useUsersFromPredicate = (predicate: Predicate): UsersByIdsQuery['users'] => {
  const userIds = fromPredicate(predicate)
    // TODO: Using the UserId type for 'value.user-id' caused type errors in the recursive Filter type.
    //  Seemed like a bug in zod, but I don't know. For now we'll cast the value here.
    .map(value => (value.type === 'value.user-id' ? (value.value as UserId) : undefined))
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: usersByIdsQuery,
      queryOptions: {
        enabled: userIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: userIds }
  )

  if (userIds.length === 0) {
    return []
  }

  return response.data?.users ?? []
}

const useLabelsForUserPredicate = (predicate: Predicate): string[] => {
  const users = useUsersFromPredicate(predicate)
  const labels = users.map(it => it.displayName)
  return labels
}

const useQueryUsersByIds = (predicate: Predicate): Identity[] => {
  const users = useUsersFromPredicate(predicate)

  return users.map(mapBasicUserToIdentity)
}

type UserAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}
export const UserAutoComplete: React.FC<UserAutoCompleteProps> = ({ ctx, predicate }) => {
  const queryUsers = useAllUsersFetcher({ limit: 100 })
  const selectedUsers = useQueryUsersByIds(predicate)
  const { t } = useTranslation()

  const onSelect = useCallback(
    (user: Identity) => {
      ctx.update(f => setPred(f, addPredValue(predicate, { type: 'value.user-id', value: user.id })))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (user: Identity) => {
      ctx.update(f => setPred(f, removePredValue(predicate, { type: 'value.user-id', value: user.id })))
    },
    [ctx, predicate]
  )

  return (
    <FormElement label={t('dictionary.user-plural')}>
      <IdentitiesSelector
        selectedIdentities={selectedUsers}
        onSelect={onSelect}
        onUnselect={onUnselect}
        fetchIdentities={queryUsers}
        placeholder='manage.search.users'
      />
    </FormElement>
  )
}

export const UserAutocompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForUserPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
