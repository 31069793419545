import { FC, useCallback, useId } from 'react'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { Card } from 'sierra-client/features/multi-tenancy/components'
import { GranularVisibilityState } from 'sierra-client/features/multi-tenancy/granular-visibility/types'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { isDefined } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'

export const OrganizationSettingsCard: FCC<{
  organization: AccessibleOrganization
  onTogglePublishing: (org: AccessibleOrganization, checked: boolean) => void
  checked: boolean
  locked: boolean
  unavailable: boolean
}> = ({ children, organization, onTogglePublishing, checked, locked, unavailable }) => {
  const { t } = useTranslation()
  const formId = useId()

  const onCheckedChange = useCallback(
    (n: boolean | 'indeterminate') => {
      const nextChecked = n === true
      onTogglePublishing(organization, nextChecked)
    },
    [organization, onTogglePublishing]
  )

  return (
    <ConditionalWrapper
      condition={unavailable}
      renderWrapper={children => (
        <Tooltip title={t('multi-tenancy.content-distribution.sub-org-disabled')}>{children}</Tooltip>
      )}
    >
      <Card $disabled={unavailable}>
        <View>
          <Checkbox
            id={formId}
            checked={checked}
            onCheckedChange={onCheckedChange}
            disabled={locked || unavailable}
          />
          <Text bold color='currentColor' as='label' htmlFor={formId}>
            {organization.name}
          </Text>
        </View>

        {isDefined(children) && (
          <View justifyContent='flex-end' grow>
            <View justifyContent='flex-end'>{children}</View>
          </View>
        )}
      </Card>
    </ConditionalWrapper>
  )
}

export const OrganizationClusterVisibilitySettings: FC<{
  courseId: CourseId
  rootVisibility: CourseVisibilityInOrg
  granularVisibility: GranularVisibilityState
  onVisibilityChanges: (visibilityState: GranularVisibilityState) => void
}> = ({ courseId, rootVisibility, granularVisibility, onVisibilityChanges }) => {
  const { t } = useTranslation()
  const cluster = useOrganizationCluster()

  const granularVisibilityOptions = useCourseVisibilityDropdownAllowedItems(courseId, {
    privateEnabled: false,
    privateAvailable: false,
    separateVisibilityEnabled: false,
  })

  const hasCluster = isDefined(cluster.cluster) && cluster.cluster.length > 0

  const onTogglePublishing = useCallback(
    (organization: AccessibleOrganization, checked: boolean) => {
      if (rootVisibility === 'private') {
        throw new Error('Invalid visibility')
      }

      if (organization.isClusterParent) {
        throw Error('The parent organization is always included in publishing')
      }

      const currentState = granularVisibility.distributions[organization.namespaceId]

      if (currentState === undefined) {
        throw Error(`Failed to resolve state for ${organization.namespaceId}`)
      }

      onVisibilityChanges({
        ...granularVisibility,
        distributions: {
          ...granularVisibility.distributions,
          [organization.namespaceId]: {
            ...currentState,
            enabled: checked,
          },
        },
      })
    },
    [onVisibilityChanges, rootVisibility, granularVisibility]
  )

  const handleGranularVisibilityChanged =
    (affectedOrganization: AccessibleOrganization) =>
    (selectedVisibility: CourseVisibilityInOrg): void => {
      if (affectedOrganization.isClusterParent) {
        onVisibilityChanges({
          ...granularVisibility,
          parent: selectedVisibility,
        })
      } else {
        const checked = granularVisibility.distributions[affectedOrganization.namespaceId]?.enabled

        onVisibilityChanges({
          ...granularVisibility,
          distributions: {
            ...granularVisibility.distributions,
            [affectedOrganization.namespaceId]: {
              enabled: checked ?? false,
              visibility: selectedVisibility,
            },
          },
        })
      }
    }

  if (cluster.loading || !hasCluster || !cluster.selfIsParent) {
    return false
  }

  return (
    <View direction='column'>
      <Text bold color='foreground/muted'>
        {t('multi-tenancy.content-distribution.publish-to')}
      </Text>

      <View direction='column' gap='6'>
        {cluster.cluster.map(org => {
          const unavailable = !org.isClusterParent && rootVisibility === 'private'
          const orgGranularVisibility = org.isClusterParent
            ? granularVisibility.parent
            : granularVisibility.distributions[org.namespaceId]?.visibility

          if (orgGranularVisibility === undefined) {
            throw Error(`Failed to resolve visibility for ${org.namespaceId}`)
          }

          const checked = org.isClusterParent
            ? true
            : (granularVisibility.distributions[org.namespaceId]?.enabled ?? false)

          return (
            <OrganizationSettingsCard
              key={org.domain}
              organization={org}
              onTogglePublishing={onTogglePublishing}
              checked={checked}
              locked={org.isClusterParent}
              unavailable={unavailable}
            >
              {rootVisibility === 'separate-visibility' && (
                <CourseVisibilityDropdown
                  selectedVisibility={orgGranularVisibility}
                  onSelect={handleGranularVisibilityChanged(org)}
                  options={granularVisibilityOptions}
                  dropdownVariant='ghost'
                />
              )}
            </OrganizationSettingsCard>
          )
        })}
      </View>
    </View>
  )
}
