import { useCallback, useEffect, useState } from 'react'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { queryClient } from 'sierra-client/api/query-client'
import { usePost } from 'sierra-client/hooks/use-post'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { updateUser as updateUserAction } from 'sierra-client/state/user/user-slice'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AccountSettings } from 'sierra-domain/api/user'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserMe, XRealtimeUserSetAccountSettings } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'

type UseUserSettingsOutput = {
  currentUser: User | undefined
  saveUserSettings: (userSettings: AccountSettings) => Promise<{ i18nArgs?: I18nArgs; error: boolean }>
  fetchUserSettings: () => Promise<{ i18nArgs?: I18nArgs; error: boolean }>
}

export const useUserSettings = (): UseUserSettingsOutput => {
  const { postWithUserErrorCode } = usePost()
  const dispatch = useDispatch()

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)

  const fetchUserSettings = useCallback<UseUserSettingsOutput['fetchUserSettings']>(async () => {
    const meResult = await postWithUserErrorCode(XRealtimeUserMe, {})
    if (isLeft(meResult)) {
      return {
        i18nArgs: [getUserErrorTranslationKey(meResult.left)],
        error: true,
      }
    }

    const userData = meResult.right
    setCurrentUser(userData)
    return {
      error: false,
    }
  }, [postWithUserErrorCode])

  const saveUserSettings = useCallback<UseUserSettingsOutput['saveUserSettings']>(
    async (userSettings: AccountSettings) => {
      const accountDetailsResult = await postWithUserErrorCode(XRealtimeUserSetAccountSettings, userSettings)
      if (isLeft(accountDetailsResult)) {
        return {
          i18nArgs: [getUserErrorTranslationKey(accountDetailsResult.left)],
          error: true,
        }
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: currentUserQuery.queryKey }),
        dispatch(updateUserAction()),
      ])

      return {
        error: false,
      }
    },
    [dispatch, postWithUserErrorCode]
  )

  useEffect(() => {
    void fetchUserSettings()
  }, [fetchUserSettings])

  return {
    currentUser,
    saveUserSettings,
    fetchUserSettings,
  }
}
