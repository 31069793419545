import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActionModal, ActionModalProps } from 'sierra-client/components/common/modals/action-modal'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useEditorMode } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useEmbedData } from 'sierra-client/views/v3-author/embed/embed-data-layer'
import { getEmbedElement } from 'sierra-client/views/v3-author/embed/utils/handlers'
import { usePreventVideoSeeking } from 'sierra-client/views/v3-author/embed/utils/use-prevent-video-seeking'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { EmbedInteraction } from 'sierra-domain/api/block-interaction'
import { Entity } from 'sierra-domain/entity'
import { User } from 'sierra-domain/user'
import { Embed as EmbedBlock } from 'sierra-domain/v3-author'
import { InputPrimitive, LoadingSpinner, Skeleton, Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css, keyframes } from 'styled-components'
import { z } from 'zod'

function createUrl(basePath: string): URL | undefined {
  const isMissingProtocol = !(
    basePath.toLowerCase().startsWith('https://') || basePath.toLowerCase().startsWith('http://')
  )
  const path = isMissingProtocol ? `https://${basePath}` : basePath

  try {
    // new URL() can fail for several reasons.
    return new URL(path)
  } catch (e) {
    return undefined
  }
}

type EmbedWrapperProps = {
  showSelectionBorder?: boolean
}
export const EmbedWrapper = styled.div<EmbedWrapperProps>`
  min-height: 50px;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  /* This is to fix an issue with border radius not being applied in Safari.
                           * https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
                           */
  isolation: isolate;
  transform: translateZ(0);

  ${p =>
    p.showSelectionBorder === true &&
    css`
      box-shadow: 0 0 0 2px #b4d5ff;
    `}
  /* Karolinska need hardcoded height for their iframes */
    .karolinska-video {
    height: 468px;
  }

  .howdou-embed {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .howdou-embed-dummy {
    margin-top: 56.25%;
  }

  .howdou-embed-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  overflow: hidden;
`

type UrlEmbedWrapperProps = {
  isLegacyEmbed: boolean
  showSelectionBorder: boolean
  customHeight?: number
}
export const UrlEmbedWrapper = styled.div<UrlEmbedWrapperProps>`
  border-radius: ${p => p.theme.borderRadius['size-10']};
  height: 600px;
  /* This is to fix ann issue with border radius not being applied in Safari.
                       * https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
                       */
  isolation: isolate;
  transform: translateZ(0);

  ${p =>
    p.isLegacyEmbed &&
    css`
      height: fit-content;
    `}

  ${p =>
    p.customHeight !== undefined &&
    css`
      height: ${p.customHeight}px;
    `}

    ${p =>
    p.showSelectionBorder &&
    css`
      box-shadow: 0 0 0 2px #b4d5ff;
    `}
`

const PlainEmbedContainer = styled.div<{ $fixedHeight?: number }>`
  position: relative; /* Create a new stacking context */
  width: 100%;

  ${p => {
    if (p.$fixedHeight !== undefined && !isNaN(p.$fixedHeight) && p.$fixedHeight > 0) {
      return css`
        height: ${p.$fixedHeight}px;
      `
    } else {
      return css`
        padding-bottom: calc((3 / 4) * 100%);
      `
    }
  }};
`

const blurOut = keyframes`
    0% {
        filter: blur(8px);
    }
    100% {
        filter: blur(0);
    }
`

const PlainEmbedContainerInner = styled.div<{ variant: EmbedBlock['variant']; show: boolean }>`
  position: absolute;
  inset: 0;
  z-index: 0;
  filter: blur(8px);
  border-radius: ${p => p.theme.borderRadius['size-10']};
  ${p =>
    p.show &&
    css`
      animation: ${blurOut} 1s linear 0.5s forwards;
    `}
`

const PlainEmbedIframe = styled.iframe`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${p => p.theme.borderRadius['size-10']};
`

const gleerupRegExp = /(?:rho\.eedev\.se|gleerupsportal\.se)/

// The gleerups embeds are not rendered as embeds, instead they are rendered as plain A tags using
// the GleerupsResource below.
//
// There are courses for Medlearn that use this type of embed. For example, see
// https://sanalabs.sana.ai/course/nyNOv_ziyg35/module/w9J43cMfnUBw/lesson/nHurv-pURPAa
const getGleerupsUrl = (block: EmbedBlock, user: User): string => {
  // Support old embeds
  if (
    block.ltiData?.context_label === undefined &&
    block.ltiData?.custom_lis_person_cust_id === undefined &&
    (user.organizationUuid === '3b486460-79e9-4f5a-9dd1-65e26ab32e37' ||
      user.organizationUuid === '07675b30-2f83-4742-a740-bd6b0842a3f6')
  ) {
    let contextLabel
    if (user.organizationUuid === '3b486460-79e9-4f5a-9dd1-65e26ab32e37') {
      // SFI
      contextLabel = 'SFI2B-AI'
    } else {
      // Medlearn
      contextLabel = 'VÅRVÅR01'
    }

    const qs = new URLSearchParams({
      lis_person_contact_email_primary: user.email,
      custom_lis_person_cust_id: '3132',
      lis_person_name_given: user.firstName,
      lis_person_name_family: user.lastName,
      roles: 'student',
      context_title: 'Test1234',
      context_label: contextLabel,
      oauth_consumer_key: block.url,
    }).toString()

    return `https://gleerupsportal.se/iris.php?${qs}`
  } else {
    const qs = new URLSearchParams({
      lis_person_contact_email_primary: user.email,
      lis_person_name_given: user.firstName,
      lis_person_name_family: user.lastName,
      roles: 'student',
      context_title: 'context_title',
      custom_lis_person_cust_id: block.ltiData?.custom_lis_person_cust_id ?? '',
      context_label: block.ltiData?.context_label ?? '',
      oauth_consumer_key: block.url,
    }).toString()

    return `https://gleerupsportal.se/iris.php?${qs}`
  }
}

const horizontalBlockPadding = css`
  padding-left: ${spacing['96']};
  padding-right: ${spacing['96']};
`

const LegacyQuoteWrap = styled.div<{ borderColor?: string; backgroundColor?: string }>`
  ${horizontalBlockPadding};

  border-left: 0.25rem solid ${(p): string => p.borderColor ?? '#002fa7'};
  margin: 0 0 2rem;
  padding: 1.5rem 2rem;
  min-height: 2.5rem;
  background-color: ${(p): string => p.backgroundColor ?? '#eaf1f8'};
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;

  &,
  article & {
    p,
    strong,
    i {
      font-family: ${fonts.sansSerif};
      color: #212121;
      margin-bottom: 0;
      font-size: 100%;
    }

    a,
    p a {
      font-family: ${fonts.sansSerif};
      color: #002fa7;
      text-decoration: underline;
    }

    p:last-of-type {
      margin: 0;
    }

    ul {
      margin-left: 1.2rem !important;
      list-style: disc;
    }

    ol {
      margin-left: 1.2rem !important;
      list-style: decimal;
    }

    ol,
    ul {
      li {
        font-size: 1rem;
        line-height: 1.5em;
        font-family: ${fonts.sansSerif};
        color: #212121;
        padding: 0;

        p,
        strong,
        i {
          font-size: 1rem;
          line-height: 1.5em;
          font-family: ${fonts.sansSerif};
          color: #212121;
        }

        p {
          margin-bottom: 1.5rem;
        }

        a {
          font-family: ${fonts.sansSerif};
          color: #002fa7;
          text-decoration: underline;
        }

        strong {
          color: #212121;
        }

        &::before {
          display: none;
        }
      }
    }
  }
`

const GleerupResource: React.FC<{ block: EmbedBlock }> = ({ block }) => {
  const user = useSelector(selectUser)

  if (!user)
    return (
      <LegacyQuoteWrap borderColor='#bbb' backgroundColor='#f7f8fa'>
        You must be signed in to access this resource.
      </LegacyQuoteWrap>
    )

  return (
    <LegacyQuoteWrap borderColor='#bbb' backgroundColor='#f7f8fa'>
      <a href={getGleerupsUrl(block, user)} target='_blank' rel='noreferrer'>
        Länk till Gleerups
      </a>
    </LegacyQuoteWrap>
  )
}

type PlainEmbedSkeletonProps = {
  height: number
}
const PlainEmbedSkeleton = styled(Skeleton).attrs({
  variant: 'rect',
  animation: 'wave',
})<PlainEmbedSkeletonProps>`
  overflow: hidden;
  position: absolute;
  inset: 0;
  width: 100%;

  ${p =>
    p.height <= 0 &&
    css`
      height: 100% !important;
    `};
`

const disappear = keyframes`
    0% {
        opacity: 1;
    }
    85% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        pointer-events: none;
    }
`

const PlainEmbedLoadWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; /* Make sure the iframe is always rendred on top of the loader */

  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    p.hide &&
    css`
      animation: ${disappear} 1.5s linear 0s forwards;
    `}
`

const PlainEmbedLoader: React.FC<{ hide: boolean; height: number }> = ({ hide, height }) => (
  <PlainEmbedLoadWrapper hide={hide}>
    <PlainEmbedSkeleton height={height} />
    <LoadingSpinner size='large' />
  </PlainEmbedLoadWrapper>
)

const EmbedMessageData = z.object({
  type: z.literal('outcome'),
  outcome: z.union([z.literal(1), z.literal(0)]),
  sanaActivityId: z.string(),
})

type Outcome = Omit<EmbedInteraction, 'timestamp'>
type Environment = 'create' | 'learn' | 'review' | 'placement-test'

const EmbedBlockComponent: React.FC<{
  block: Entity<EmbedBlock>
  environment: Environment
  onOutcomeSubmitted: (outcome: Outcome) => void
  embedEl: HTMLDivElement | null
}> = ({ block, environment, onOutcomeSubmitted, embedEl }) => {
  const {
    id: blockId,
    isMandatory,
    url: blockUrl,
    data: { type: blockDataType },
  } = block

  const [element, setElement] = useState<undefined | JSX.Element>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const user = useSelector(selectUser)

  usePreventVideoSeeking({ embedEl, block, onCompletion: () => {}, hasFinishedBlock: false })

  useEffect(() => {
    void (async () => {
      if (blockDataType !== 'plain' && user !== undefined) {
        setElement(await getEmbedElement(blockUrl, user))
      }
    })()
  }, [blockUrl, blockDataType, user])

  const href: undefined | string = useMemo(() => {
    const activityId = blockId

    const url = createUrl(blockUrl)

    if (url === undefined) return undefined

    url.searchParams.set('sanaActivityId', activityId)
    url.searchParams.set('sanaEnvironment', environment)

    return url.href
  }, [blockId, blockUrl, environment])

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (blockUrl.includes(event.origin) && (isMandatory ?? false)) {
        const parseResult = EmbedMessageData.safeParse(event.data)

        if (parseResult.success === false) {
          errorLogger.captureError(
            Error(
              `Embed block recieved message from ${event.origin} with incorrect format ${JSON.stringify({
                message: event,
              })}`
            )
          )

          return
        }

        const payload = parseResult.data

        // Ignore messages from other mounted embed blocks.
        if (payload.sanaActivityId !== blockId) {
          return
        }

        onOutcomeSubmitted({ blockId, correct: payload.outcome === 1, type: 'embed-interaction' })
      }
    },
    [blockId, blockUrl, isMandatory, onOutcomeSubmitted]
  )

  const isDebugMode = useIsDebugMode()

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  if (user === undefined) return <></>

  if (gleerupRegExp.test(block.url)) return <GleerupResource block={block} />

  if (block.data.type === 'plain') {
    return (
      <>
        <PlainEmbedContainer $fixedHeight={block.data.height}>
          {/* This skeleton has the same size as the iframe. It's always rendered *behind* the iframe. If the iframe loads, this will be hidden. */}
          <PlainEmbedLoader height={block.data.height ?? 0} hide={isLoading === false} />
          <PlainEmbedContainerInner variant={block.variant} show={isLoading === false}>
            <PlainEmbedIframe
              ref={iframeRef}
              src={href}
              allow={`microphone; clipboard-write ${blockUrl};`}
              onLoad={() => {
                if (iframeRef.current !== null) {
                  // This will only be null if the component has unmounted. It works without this condition, but will prevent any errors ("set state called on an unmounted component") if the user navigates away in the 2000ms
                  setIsLoading(false)
                }
              }}
            />
          </PlainEmbedContainerInner>
        </PlainEmbedContainer>
        {isDebugMode && (
          <View justifyContent='center' alignItems='center'>
            [Debug] Embed Environment: {environment}
          </View>
        )}
      </>
    )
  }

  if (element === undefined) return <></>

  return <>{element}</>
}

const modeToEnvironment: Record<EditorMode, Environment> = {
  'create': 'create',
  'review': 'review',
  'placement-test': 'placement-test',
  'self-paced': 'learn',
  'live': 'learn',
  'recap': 'learn',
  'template': 'learn',
  'version-history': 'learn',
}

// TODO: implement preventing seeking of video embeds
export const V3EmbedBlock: React.FC<{
  element: Entity<EmbedBlock>
  embedEl: HTMLDivElement | null
}> = ({ element: block, embedEl }) => {
  const mode = useEditorMode()
  const environment = modeToEnvironment[mode]
  const dataLayer = useEmbedData()

  const onOutcomeSubmitted = useCallback(
    (outcome: Outcome) => {
      if (dataLayer === undefined) {
        throw Error('Failed to submit embed outcome without a mounted data layer')
      }

      dataLayer.submitOutcome(outcome)
    },
    [dataLayer]
  )

  return (
    <EmbedBlockComponent
      embedEl={embedEl}
      block={block}
      environment={environment}
      onOutcomeSubmitted={onOutcomeSubmitted}
    />
  )
}

export const ConfigModal = ({
  open,
  onClose,
  block,
  update,
}: Pick<ActionModalProps, 'open' | 'onClose'> & {
  block: Entity<EmbedBlock>
  update: (block: Entity<EmbedBlock>) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const [context_label, set_context_label] = useState(block.ltiData?.context_label ?? '')
  const [custom_lis_person_cust_id, set_custom_lis_person_cust_id] = useState(
    block.ltiData?.custom_lis_person_cust_id ?? ''
  )
  const [embedHeight, setEmbedHeight] = useState<number | undefined>(0)
  const [customDurationInSeconds, setCustomDurationInSeconds] = useState<number | undefined>(
    block.meta?.durationInSeconds
  )

  useEffect(() => {
    if (block.data.type === 'plain') {
      setEmbedHeight(block.data.height ?? 0)
    }
  }, [block])

  return (
    <ActionModal
      title={t('settings.settings')}
      open={open}
      onClose={onClose}
      primaryAction={() => {
        update({
          ...block,
          ltiData: { context_label, custom_lis_person_cust_id },
          data:
            block.data.type === 'plain'
              ? {
                  type: 'plain',
                  height: embedHeight,
                }
              : {
                  type: 'legacy',
                },
          meta: { ...(block.meta ?? {}), durationInSeconds: customDurationInSeconds },
        })
        void onClose()
      }}
      primaryActionLabel={t('dictionary.save')}
    >
      <Text bold>{t('author.embed-content-settings')}</Text>

      <Spacer size='4' />

      <InputPrimitive
        placeholder={'context_label'}
        value={context_label}
        onChange={e => set_context_label(e.target.value)}
      />

      <Spacer size='8' />

      <InputPrimitive
        placeholder={'custom_lis_person_cust_id'}
        value={custom_lis_person_cust_id}
        onChange={e => set_custom_lis_person_cust_id(e.target.value)}
      />

      <Spacer size='8' />

      <Text bold>{t('author.embed-increase-lesson-time')}</Text>

      <Spacer size='4' />

      <InputPrimitive
        placeholder={'Minutes'}
        type='number'
        min={0}
        value={customDurationInSeconds !== undefined ? String(customDurationInSeconds / 60) : ''}
        onChange={e => {
          if (e.target.value === '') {
            setCustomDurationInSeconds(undefined)
          } else {
            const duration = parseFloat(e.target.value)
            if (!isNaN(duration)) {
              setCustomDurationInSeconds(duration * 60)
            }
          }
        }}
      />

      <Spacer size='8' />
      {block.data.type === 'plain' && (
        <>
          <Text bold>{t('author.embed-visual-settings')}</Text>
          <Spacer size='4' />
          <InputPrimitive
            placeholder={t('author.embed-height')}
            type='number'
            min={0}
            value={embedHeight === undefined ? '' : String(embedHeight)}
            onChange={e => setEmbedHeight(e.target.value === '' ? undefined : parseInt(e.target.value))}
          />
        </>
      )}
    </ActionModal>
  )
}
