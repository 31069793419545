import { MobileHelperInternal } from 'sierra-client/views/course-helper/mobile/mobile-helper-internal'
import { Previous } from 'sierra-client/views/course-helper/mobile/previous'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import styled from 'styled-components'

const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  pointer-events: none;

  gap: 1rem;
`

export const MobileHelper: React.FC<{
  tutorState: TutorState
}> = ({ tutorState }) => {
  return (
    <BottomFixed>
      <Previous />
      <MobileHelperInternal tutorState={tutorState} />
    </BottomFixed>
  )
}
