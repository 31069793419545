import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { ApplicationNotifications, useNotif } from 'sierra-client/components/common/notifications'
import { BreakoutRoomController } from 'sierra-client/components/liveV2/breakout-room-controller'
import { CameraTrackController } from 'sierra-client/components/liveV2/camera-track-controller'
import {
  useLiveSessionContext,
  useLiveSessionState,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { StatisticsCollector } from 'sierra-client/components/liveV2/live-layer/call-stats/statistics-collector'
import { StatsMonitor } from 'sierra-client/components/liveV2/live-layer/call-stats/stats-monitor'
import { LiveLayer } from 'sierra-client/components/liveV2/live-layer/live-layer'
import { LiveProvider } from 'sierra-client/components/liveV2/live-provider'
import { LiveSessionEndedModal } from 'sierra-client/components/liveV2/live-session-ended-modal'
import { LogTrackingEvents } from 'sierra-client/components/liveV2/log-tracking-events'
import { ParticipantsVideoPriorityProvider } from 'sierra-client/components/liveV2/participant-provider'
import { PreLobby } from 'sierra-client/components/liveV2/pre-lobby'
import { RecordingProvider } from 'sierra-client/components/liveV2/recording/recording-provider'
import { useLiveKeyboardShortcuts } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-live-keyboard-shortcuts'
import { useMutedSpeakingCheck } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-muted-speaking-check'
import { useLocalTracks } from 'sierra-client/components/liveV2/services/video-call-service/hooks/useLocalTracks'
import { SessionSummary } from 'sierra-client/components/liveV2/session-summary'
import { SpeakerProvider } from 'sierra-client/components/liveV2/speaker-provider'
import { TranscriptionController } from 'sierra-client/components/liveV2/transcription-controller'
import { ConfirmTranscribingModal } from 'sierra-client/components/liveV2/transcriptions/confirm-live-transcriptions-modal'
import { Mode } from 'sierra-client/components/liveV2/types'
import { UserControlEventsWatcher } from 'sierra-client/components/liveV2/user-control-events-watcher'
import { VideoSubscribeController } from 'sierra-client/components/liveV2/video-subscribe-controller'
import { SessionIdlePrompt } from 'sierra-client/features/sana-now'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { IntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'
import { fetchCourseDataById } from 'sierra-client/state/v2/courses-actions'
import { FCC } from 'sierra-client/types'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${token('surface/default')};
    color: ${token('foreground/primary')};
  }
`

const IdleSessionModal: FC = () => {
  const { leave } = useLiveContext()
  return <SessionIdlePrompt onSessionEnded={leave} />
}

// Render children, or pre-lobby if the video is not yet configured
const LiveLayerPageWrapperInner: FCC<{
  liveSessionId: ScopedLiveSessionId
  mode: Exclude<Mode, 'post-lobby'>
  setMode: React.Dispatch<React.SetStateAction<Mode>>
}> = ({ children, liveSessionId, mode, setMode }) => {
  const showedSpeakingWhileMutedNotifRef = useRef(false)
  const notif = useNotif()
  const callIsActive = useSelector(selectCallIsActive)
  const { left } = useLiveContext()
  useLiveKeyboardShortcuts()

  const { localAudioTrack, localNonPublishedAudioTrack } = useLocalTracks()

  const speakingWhileMutedCallback = useCallback((): void => {
    if (showedSpeakingWhileMutedNotifRef.current) return
    notif.push({ type: 'custom', level: 'info', body: 'Are you talking? Your microphone is off.' })
    showedSpeakingWhileMutedNotifRef.current = true
  }, [notif])

  const { isMuted } = useMutedSpeakingCheck({
    enabled: true,
    localAudioTrack,
    localNonPublishedAudioTrack,
    callback: speakingWhileMutedCallback,
  })

  useOnChanged((prev, curr) => {
    if (prev === true && curr === false) {
      // User unmuted. The next time we render and the user is muted, allow showing the notification again.
      showedSpeakingWhileMutedNotifRef.current = false
    }
  }, isMuted)

  useEffect(() => {
    if (callIsActive && !left) {
      setMode('in-session')
    } else if (left) {
      setMode('post-lobby')
    }
  }, [callIsActive, left, setMode])

  return (
    <>
      {mode === 'pre-lobby' && <PreLobby liveSessionId={liveSessionId} />}
      {mode === 'in-session' && (
        <>
          {children}
          <LiveLayer />
          <StatsMonitor />
          <TranscriptionController />
          <IdleSessionModal />
        </>
      )}
    </>
  )
}

const ReturnToPrelobbyIfReopened = ({ setMode }: { setMode: (mode: Mode) => void }): null => {
  const sessionState = useLiveSessionState()
  const previousSessionState = usePrevious(sessionState)

  useOnChanged(() => {
    if (previousSessionState === 'ended' && sessionState === 'active') {
      setMode('pre-lobby')
    }
  }, [previousSessionState, sessionState])

  return null
}

export const LiveLayerPageWrapper: FCC<{ sessionId: ScopedLiveSessionId }> = ({ children, sessionId }) => {
  const liveSession = useLiveSessionContext()
  const [mode, setMode] = useState<Mode>(liveSession.data.endedAt === undefined ? 'pre-lobby' : 'post-lobby')
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(fetchCourseDataById({ courseId: liveSession.data.flexibleContentId }))
  }, [dispatch, liveSession.data.flexibleContentId])

  const goToPreLobby = useCallback(() => setMode('pre-lobby'), [])

  return (
    <>
      <ApplicationNotifications />
      <GlobalStyles />
      <IntercomLauncherVisibility visibility='hidden' />

      <CustomInterfaceThemeProvider>
        {mode === 'post-lobby' ? (
          <>
            <SessionSummary goToPreLobby={goToPreLobby} />
            <ReturnToPrelobbyIfReopened setMode={setMode} />
          </>
        ) : (
          <LiveProvider mode={mode}>
            <RecordingProvider>
              <ParticipantsVideoPriorityProvider>
                <ConfirmTranscribingModal />
                <LiveSessionEndedModal />
                <StatisticsCollector />
                <CameraTrackController />
                <VideoSubscribeController />
                <BreakoutRoomController />
                <UserControlEventsWatcher />
                <SpeakerProvider />
                <LogTrackingEvents />
                <LiveLayerPageWrapperInner liveSessionId={sessionId} mode={mode} setMode={setMode}>
                  {children}
                </LiveLayerPageWrapperInner>
              </ParticipantsVideoPriorityProvider>
            </RecordingProvider>
          </LiveProvider>
        )}
      </CustomInterfaceThemeProvider>
    </>
  )
}
