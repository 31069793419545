import { ContentAttributeWithValue } from 'sierra-client/views/manage/content-attributes/types'
import { uuid } from 'sierra-domain/api/uuid'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

export const MOCKED_ATTRIBUTE_VALUES: Array<ContentAttributeWithValue> = [
  {
    name: 'Attribute4',
    description: 'Attribute4 description',
    id: uuid(),
    index: 0,
    learnerVisible: true,
    type: 'select',
    allowMultiSelect: false,
    options: [
      {
        label: 'option1',
        id: nanoid12(),
      },
      {
        label: 'option2',
        id: nanoid12(),
      },
      {
        label: 'option3',
        id: nanoid12(),
      },
      {
        label: 'option4',
        id: nanoid12(),
      },
      {
        label: 'option5',
        id: nanoid12(),
      },
      {
        label: 'option6',
        id: nanoid12(),
      },
      {
        label: 'option7',
        id: nanoid12(),
      },
      {
        label: 'option8',
        id: nanoid12(),
      },
      {
        label: 'option9',
        id: nanoid12(),
      },
      {
        label: 'option10',
        id: nanoid12(),
      },
    ],
    value: 'option1',
  },
  {
    name: 'Attribute3',
    description: 'Attribute3 description',
    id: uuid(),
    index: 1,
    learnerVisible: true,
    type: 'date',
    value: '2025-01-01',
  },
  {
    name: 'Attribute2',
    description: 'Attribute2 description',
    id: uuid(),
    index: 2,
    learnerVisible: true,
    type: 'text',
    value: 'Free text',
  },
  {
    name: 'Attribute1',
    description: 'Attribute1 description',
    id: uuid(),
    index: 3,
    learnerVisible: true,
    type: 'number',
    value: '1',
  },
]
