import { useAtomValue } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import {
  getMeasuresFromWidget,
  getWidgetIcon,
  toLabel,
} from 'sierra-client/features/insights/display-widgets/utils'
import { widgetErrorBoundaryComponent } from 'sierra-client/features/insights/display-widgets/widget-card/widget-error-component'
import { EditableWidgetTitle } from 'sierra-client/features/insights/editable-widget-title'
import {
  ViewsData,
  useInsightsViewsFromMeasures,
} from 'sierra-client/features/insights/hooks/use-insights-views'
import {
  insightsWidgetModifiedLogger,
  insightsWidgetSavedLogger,
} from 'sierra-client/features/insights/logger'
import { insightsErrorBoundaryBeforeSend } from 'sierra-client/features/insights/utils'
import { GeneratingWidgetState, NLInsights } from 'sierra-client/features/insights/widget-builder/nl-insights'
import {
  WidgetBuilderState,
  buildWidgetBuilderStateFromWidget,
  buildWidgetFromWidgetBuilderState,
  useWidgetBuilderState,
} from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { Filter, andAll } from 'sierra-client/lib/filter'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { useDispatch } from 'sierra-client/state/hooks'
import { askInsightsModelAtom } from 'sierra-client/state/settings'
import {
  Dashboard,
  DashboardWidget,
  Dimension,
  FilterDomain,
  Widget,
  areDimensionRefsEqual,
  areMeasureRefsEqual,
} from 'sierra-domain/api/insights'
import { XAnalyticsAsk } from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'
import { normalizeSorting } from './normalize-sorting'
import { SqlViewer } from './sql-viewer'
import { MaybePartialDashboardWidget, PartialDashboardWidget, WidgetSortHandler } from './types'
import { isCompleteWidget } from './utils'
import { WidgetBuilderDataViewer } from './widget-builder-data-viewer'
import { WidgetBuilderAction, WidgetBuilderSettings } from './widget-builder-settings'

const WidgetBuilderHeader = styled(View)`
  width: 100%;
`

const InsightsPage = styled(View).attrs({ direction: 'row', alignItems: 'stretch', grow: true })`
  width: 100%;
  max-height: 100%;
`

const LeftColumn = styled(View).attrs({ direction: 'column', grow: true, padding: '32', paddingLeft: '40' })`
  max-height: 100%;
  min-width: 0px;
  border-right: 1px solid ${token('border/default')};
`

const RightColumn = styled(View).attrs({
  direction: 'column',
  padding: '32',
  paddingLeft: '24',
  paddingRight: '40',
})`
  flex-basis: 328px;
  max-height: 100%;
`

export const generateTitleForWidget = (
  widget: Widget,
  viewsData: ViewsData,
  t: TranslationLookup
): string => {
  const measureLabels =
    'measures' in widget
      ? widget.measures
          .map(measureRef =>
            viewsData.availableMeasures.find(measure => areMeasureRefsEqual(measureRef, measure.ref))
          )
          .filter(isDefined)
          .map(measure => labelToString(toLabel(measure.label), t))
      : []

  const dimensionLabels =
    'dimensions' in widget
      ? widget.dimensions
          .map(dimensionRef =>
            viewsData.availableDimensions.find(dimension =>
              areDimensionRefsEqual(dimension.ref, dimensionRef)
            )
          )
          .filter(isDefined)
          .map(dimension => labelToString(toLabel(dimension.label), t))
      : undefined

  const dimensionLabel =
    dimensionLabels !== undefined
      ? widget.type === 'widget.list-table'
        ? `${dimensionLabels.join(', ')}`
        : `${t('manage.insights.widget.header.separator')} ${dimensionLabels.join(', ')}`
      : undefined

  const labelArray = [measureLabels.join(', '), dimensionLabel].filter(isDefined).join(' ')

  if (labelArray.length > 0) {
    return labelArray
  }

  return ''
}

type SubmitAction =
  | { type: 'edit'; dashboardWidget: DashboardWidget }
  | { type: 'duplicate'; dashboardWidget: PartialDashboardWidget }
  | { type: 'new' }

const getSubmitData = (
  action: SubmitAction,
  state: WidgetBuilderState,
  title: string,
  automaticTitle: boolean
): MaybePartialDashboardWidget | undefined => {
  const widget = buildWidgetFromWidgetBuilderState(state)
  if (widget === undefined) {
    return undefined
  }
  switch (action.type) {
    case 'edit':
      return {
        ...action.dashboardWidget,
        widget,
        title,
        settings: {
          ...action.dashboardWidget.settings,
          automaticTitle,
        },
      }
    case 'duplicate':
      return {
        widget,
        title: `${title} (Duplicate)`,
        settings: {
          ...action.dashboardWidget.settings,
          automaticTitle,
        },
      }
    case 'new':
      return {
        widget,
        title,
        settings: {
          automaticTitle,
        },
      }

    default:
      assertNever(action)
  }
}

export const WidgetBuilder: React.FC<{
  action: WidgetBuilderAction
  onSubmit: (widget: MaybePartialDashboardWidget, dashboardId?: Dashboard['id']) => void
  isSubmitting: boolean
  errorText?: string
  dashboardFilter?: Filter
  filterDomains?: FilterDomain[]
}> = ({ action, onSubmit, isSubmitting, errorText, dashboardFilter, filterDomains }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modelSelection = useAtomValue(askInsightsModelAtom)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [reloadKey, setReloadKey] = useState(0)
  const [title, setTitle] = useState(
    action.type === 'open-edit' ||
      action.type === 'open-duplicate' ||
      action.type === 'open-viewer' ||
      action.type === 'open-template-from-dashboard' ||
      action.type === 'open-template-from-dashboard-viewer'
      ? action.dashboardWidget.title
      : ''
  )
  const [automaticTitle, setAutomaticTitle] = useState(
    action.type === 'open-edit' ||
      action.type === 'open-duplicate' ||
      action.type === 'open-viewer' ||
      action.type === 'open-template-from-dashboard' ||
      action.type === 'open-template-from-dashboard-viewer'
      ? action.dashboardWidget.settings.automaticTitle
      : true
  )
  const { postWithUserErrorException } = usePost()

  const {
    state: currentWidget,
    set: setWidget,
    redo,
    undo,
    canRedo,
    canUndo,
  } = useWidgetBuilderState(
    action.type === 'open-edit' ||
      action.type === 'open-duplicate' ||
      action.type === 'open-template-from-home' ||
      action.type === 'open-template-from-dashboard' ||
      action.type === 'open-template-from-dashboard-viewer' ||
      action.type === 'open-viewer'
      ? action.dashboardWidget.widget
      : undefined
  )

  const [generatingWidgetState, setGeneratingWidgetState] = React.useState<GeneratingWidgetState>({
    type: 'idle',
  })

  const generateWidgetFromQuestion: (question: string) => Promise<void> = useCallback(
    async (question: string): Promise<void> => {
      setGeneratingWidgetState({ type: 'generating' })
      const { result } = await postWithUserErrorException(XAnalyticsAsk, {
        question,
        model: modelSelection,
      })

      switch (result.type) {
        case 'result.widget': {
          // Note: The backend just returns the filter itself, but we need it to be wrapped in an AND filter
          const wrappedFilter = isDefined(result.widget.filter) ? andAll([result.widget.filter]) : undefined
          setWidget(buildWidgetBuilderStateFromWidget({ ...result.widget, filter: wrappedFilter }))
          setGeneratingWidgetState({ type: 'idle' })
          break
        }
        case 'result.feedback':
          setGeneratingWidgetState({
            type: 'feedback',
            feedback: result.feedback,
            suggestedWidgets: result.suggestedWidgets ?? [],
          })
          break
        case 'result.fallback':
          setGeneratingWidgetState({
            type: 'feedback',
            feedback: t('analytics.assistant.fallback-response'),
            suggestedWidgets: [],
          })
          break
      }
    },
    [modelSelection, postWithUserErrorException, setWidget, t]
  )

  useEffect(() => {
    if (
      action.type === 'open-generate-from-home' ||
      action.type === 'open-generate-from-dashboard' ||
      action.type === 'open-generate-from-dashboard-viewer'
    ) {
      void generateWidgetFromQuestion(action.query)
    }
  }, [action, generateWidgetFromQuestion])

  const { data: viewsData, isLoading } = useInsightsViewsFromMeasures(
    currentWidget.selections.measures,
    currentWidget.selections.view
  )

  // Sets new title based on widget changes
  useOnChanged(() => {
    const builtWidget = buildWidgetFromWidgetBuilderState(currentWidget)
    if (automaticTitle && builtWidget !== undefined && !isLoading) {
      const newTitle = generateTitleForWidget(builtWidget, viewsData, t)
      if (newTitle !== title) {
        setTitle(newTitle)
      }
    }
  }, [currentWidget, automaticTitle])

  const handleSubmit = (): void => {
    const submitData = getSubmitData(
      action.type === 'open-duplicate'
        ? { type: 'duplicate', dashboardWidget: action.dashboardWidget }
        : action.type === 'open-edit'
          ? { type: 'edit', dashboardWidget: action.dashboardWidget }
          : { type: 'new' },
      currentWidget,
      title,
      automaticTitle
    )

    if (submitData !== undefined) {
      onSubmit(submitData)
      if (action.type === 'open-new') {
        // we're creating a new widget
        void dispatch(
          insightsWidgetSavedLogger({
            widgetType: submitData.widget.type,
            measure: getMeasuresFromWidget(submitData.widget)?.[0],
            dimension: 'dimensions' in submitData.widget ? submitData.widget.dimensions[0] : undefined,
          })
        )
      } else {
        // we're editing an existing widget
        void dispatch(insightsWidgetModifiedLogger({ widgetType: submitData.widget.type }))
      }
    } else {
      throw new Error('Widget is not complete')
    }
  }

  const handleSubmitSaveAsNew = (dashboardId?: Dashboard['id']): void => {
    const submitData = getSubmitData({ type: 'new' }, currentWidget, title, automaticTitle)
    if (submitData !== undefined) {
      onSubmit(submitData, dashboardId)
    } else {
      throw new Error('Widget is not complete')
    }
  }

  const onDrillDown = (filter: Filter, newDimension: Dimension): void => {
    if (currentWidget.visualisation.type !== 'metric') {
      setWidget({
        ...currentWidget,
        selections: {
          ...currentWidget.selections,
          dimensions: [newDimension.ref],
          filter,
        },
      })
    }
  }

  const onSetFilter = (filter: Filter): void => {
    setWidget({
      ...currentWidget,
      selections: {
        ...currentWidget.selections,
        filter,
      },
    })
  }

  const onSort: WidgetSortHandler = useCallback(
    sortBy => {
      setWidget({
        ...currentWidget,
        selections: {
          ...currentWidget.selections,
          sortBy,
        },
      })
    },
    [currentWidget, setWidget]
  )

  const onSetLimit = (newLimit?: number): void => {
    setWidget({
      ...currentWidget,
      selections: {
        ...currentWidget.selections,
        limit: newLimit,
      },
    })
  }

  const setWidgetWithNormalizedSorting = (widget: WidgetBuilderState): void => {
    if (widget.selections.sortBy !== undefined) {
      const normalizedSortBy = normalizeSorting(widget)
      setWidget({
        ...widget,
        selections: {
          ...widget.selections,
          sortBy: normalizedSortBy,
        },
      })
    } else {
      setWidget(widget)
    }
  }

  const submitDisabled = isEditingTitle || !isCompleteWidget(currentWidget)

  const builtWidget = buildWidgetFromWidgetBuilderState(currentWidget)

  return (
    <InsightsPage>
      <LeftColumn>
        <WidgetBuilderHeader justifyContent='space-between'>
          <View>
            {builtWidget !== undefined && <Icon iconId={getWidgetIcon(builtWidget.type)} />}
            <EditableWidgetTitle
              isEditable={isEditingTitle}
              setIsEditable={setIsEditingTitle}
              initialTitle={title}
              editLocation='widgetBuilder'
              onChange={newTitle => {
                setTitle(newTitle)
                setAutomaticTitle(false)
              }}
            />
          </View>
          <View>
            <SqlViewer widget={builtWidget}></SqlViewer>
          </View>
        </WidgetBuilderHeader>
        <StrategicErrorBoundary
          id='insights-widget-builder'
          Fallback={widgetErrorBoundaryComponent(() => setReloadKey(key => key + 1))}
          strategies={[]}
          resetKeys={[currentWidget, reloadKey]}
          beforeSend={error => insightsErrorBoundaryBeforeSend(error, builtWidget)}
        >
          {builtWidget === undefined ? (
            <NLInsights
              setGeneratingWidgetState={setGeneratingWidgetState}
              generatingWidgetState={generatingWidgetState}
              askInsightsQuestion={generateWidgetFromQuestion}
              setWidget={setWidget}
            />
          ) : (
            <WidgetBuilderDataViewer
              onSetFilter={onSetFilter}
              onDrillDown={onDrillDown}
              onSort={onSort}
              widget={builtWidget}
              onSetLimit={onSetLimit}
              setWidget={setWidget}
              setTitle={title => {
                setTitle(title)
                setAutomaticTitle(false)
              }}
              dashboardFilter={dashboardFilter}
            />
          )}
        </StrategicErrorBoundary>
      </LeftColumn>
      <RightColumn>
        <WidgetBuilderSettings
          action={action}
          widgetBuilderState={currentWidget}
          onChange={setWidgetWithNormalizedSorting}
          onSubmit={handleSubmit}
          onSubmitSaveAsNew={handleSubmitSaveAsNew}
          isSubmitting={isSubmitting}
          undo={undo}
          redo={redo}
          canUndo={canUndo}
          canRedo={canRedo}
          submitDisabled={{
            ...(submitDisabled
              ? {
                  disabled: submitDisabled,
                  tooltip:
                    action.type === 'open-viewer'
                      ? t('manage.insights.widget-builder.disabled-submit-not-editor')
                      : t('manage.insights.widget-builder.disabled-submit-not-complete'),
                }
              : { disabled: false }),
          }}
          errorText={errorText}
          dashboardFilter={dashboardFilter}
          filterDomains={filterDomains}
        />
      </RightColumn>
    </InsightsPage>
  )
}
