import React from 'react'
import { useIsOwnerUser } from 'sierra-client/api/hooks/use-user'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, PlainSanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { ErrorDetails } from 'sierra-client/views/error-details'
import { RequestError } from 'sierra-domain/error'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ErrorPageWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30.625rem;
  height: 100vh;
  margin: auto;
`

type ErrorPageProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  sentryEventId?: string | undefined
}

export const WhoopsPage: React.FC<ErrorPageProps> = ({ error, sentryEventId }) => {
  const { t } = useTranslation()
  const isOwner = useIsOwnerUser()

  let errorType: 'error' | 'forbidden' = 'error'
  if (error instanceof RequestError && error.status === 403) {
    errorType = 'forbidden'
  }

  return (
    <PlainSanaPage mode='light' page={PageIdentifier.Error()}>
      <ErrorPageWrap>
        <Heading size='h3' bold>
          {t(`${errorType}-page.header`)}
        </Heading>
        <Spacer size='xsmall' />
        <Text size='large' spacing='xsmall' align='center' color='foreground/secondary'>
          {t(`${errorType}-page.explanation`)}
        </Text>

        <Spacer />

        <View direction='row'>
          <Button
            id='home-button'
            variant='secondary'
            onClick={() => {
              void getGlobalRouter().navigate({ to: '/' })
            }}
          >
            {t(`${errorType}-page.back-to-home`)}
          </Button>

          {isOwner && (
            <Button
              id='feedback-button'
              onClick={(): void => {
                errorLogger.askForUserReport('Error Page')
              }}
              // eslint-disable-next-line react/jsx-no-literals
            >
              Submit error report
            </Button>
          )}
        </View>

        <Spacer size='xxlarge' />

        <ErrorDetails sentryEventId={sentryEventId} error={error} />
      </ErrorPageWrap>
    </PlainSanaPage>
  )
}
