import { PrimitiveAtom } from 'jotai'
import React from 'react'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { AINarrationsInfra } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations-infra'
import { RightSidebarState } from 'sierra-client/views/flexible-content/create-page-right-sidebar-state'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const AINarrations: React.FC<{
  fileId: FileId
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  rightSidebarControlStateAtom: PrimitiveAtom<RightSidebarState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
}> = ({
  fileId,
  scopedCreateContentId,
  operationState,
  rightSidebarControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
}) => {
  const file = useSelector(state =>
    selectFlexibleContentFile(state, ScopedCreateContentId.extractId(scopedCreateContentId), fileId)
  )

  if (file === undefined) {
    return <Debug>File is undefined</Debug>
  }

  return (
    <AINarrationsInfra
      fileId={fileId}
      scopedCreateContentId={scopedCreateContentId}
      operationState={operationState}
      rightSidebarControlStateAtom={rightSidebarControlStateAtom}
      legacyNarrationStateAtom={legacyNarrationStateAtom}
      narrationSettings={narrationSettings}
      narrationMetadata={narrationMetadata}
      assetContext={assetContext}
    />
  )
}
