import { createSlice } from '@reduxjs/toolkit'
import { getFileContentImage } from 'sierra-client/api/content'
import rawSanaTemplatesData from 'sierra-client/components/templates/import/template-data.json'
import { TemplateData } from 'sierra-client/components/templates/import/types'
import { TemplateTabCategory } from 'sierra-client/components/templates/template-filter'
import { fetchPartnerTemplates } from 'sierra-client/state/templates/actions'
import { SanaTemplate, TemplatesState } from 'sierra-client/state/templates/types'
import { assertNever } from 'sierra-domain/utils'

const getSanaTemplates = (): SanaTemplate[] =>
  TemplateData.parse(rawSanaTemplatesData).map(
    ({ contentType, label, key, description, categories, author }) => ({
      templateType: 'sana',
      contentType,
      id: key,
      label,
      description,
      imagePath: getFileContentImage(`template-miniatures/images/${key}.png`, {
        width: 640,
        bucket: 'sierra-static',
      }),
      categories: categories.map(c => {
        switch (c) {
          case 'onboarding':
            return TemplateTabCategory.Onboarding
          case 'ready-made-courses':
            return TemplateTabCategory.ReadyMadeCourses
          case 'workshops':
            return TemplateTabCategory.Workshops
          case 'leadership-development':
            return TemplateTabCategory.LeadershipDevelopment
          case 'sales-enablement':
            return TemplateTabCategory.SalesEnablement
          case 'compliance':
            return TemplateTabCategory.Compliance
          case 'product-management':
            return TemplateTabCategory.ProductManagement
          case 'sana-onboarding':
            return TemplateTabCategory.SanaOnboarding
          default:
            assertNever(c)
        }
      }),
      author,
    })
  )

const initialState: TemplatesState = {
  partnerTemplates: 'loading',
  sanaTemplates: getSanaTemplates(),
}

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPartnerTemplates.fulfilled, (state, { payload }) => {
      state.partnerTemplates = payload
    })
    builder.addCase(fetchPartnerTemplates.pending, state => {
      state.partnerTemplates = 'loading'
    })
  },
})
