import { useMutation } from '@tanstack/react-query'
import { SkillId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  ContentSkillSelectionWithRecommendation,
  assignImplicitSkillLevelToContent,
  assignSkillToContentMutation,
  unassignSkillFromContentMutation,
  useInvalidateSkillAssignedToContent,
  useSkillsAssignedToContent,
  useTracking,
} from 'sierra-client/features/skills'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useSelector } from 'sierra-client/state/hooks'

import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'

export const SkillsTab: SettingsTabComponent = () => {
  const tracking = useTracking()
  const courseId = useSelector(settingsState.selectors.currentCourseId)
  const contentId = `course:${courseId}`
  const skillsAssignedtoContent = useSkillsAssignedToContent(contentId)
  const { t } = useTranslation()

  const notification = useNotif()
  const invalidateSkillsQuery = useInvalidateSkillAssignedToContent(contentId)

  const mutationVars = {
    onSettled() {
      void invalidateSkillsQuery()
    },
    onError() {
      notification.push({ type: 'error' })
    },
  }

  const { mutate: assignSkillMutation } = useMutation({
    mutationFn: ({ skillId }: { skillId: SkillId }) => {
      return graphQuery(assignImplicitSkillLevelToContent, {
        contentIds: [`course:${courseId}`],
        skillId,
      })
    },
    onSuccess: (_, { skillId }) =>
      tracking.content.addContent({
        skillId: skillId,
        content: [
          {
            contentType: 'COURSE',
          },
        ],
      }),
    ...mutationVars,
  })

  const { mutate: updateSkillLevelMutation } = useMutation({
    mutationFn: ({
      skillId,
      skillLevelSettingId,
    }: {
      skillId: SkillId
      skillLevelSettingId: SkillLevelSettingId
    }) => {
      return graphQuery(assignSkillToContentMutation, {
        contentIds: [contentId],
        skillId,
        skillLevelSettingId,
      })
    },
    onSuccess: (_, { skillLevelSettingId }) =>
      tracking.content.updateLevel({
        contentId: contentId,
        level: skillLevelSettingId,
      }),
    ...mutationVars,
  })

  const { mutate: unassignSkillMutation } = useMutation({
    mutationFn: ({ skillId }: { skillId: SkillId }) => {
      return graphQuery(unassignSkillFromContentMutation, {
        contentIds: [contentId],
        skillId: skillId,
      })
    },
    onSuccess: (_, { skillId }) =>
      tracking.content.removeContent({
        skillId: skillId,
        contentId: contentId,
      }),
    ...mutationVars,
  })

  if (courseId === null) return null

  return (
    <View direction='column' gap='none'>
      <Text color='foreground/primary' bold capitalize='first'>
        {t('dictionary.skills')}
      </Text>
      <Text color='foreground/muted' spacing='12'>
        {t('skills.course-settings.description')}
      </Text>
      {isDefined(skillsAssignedtoContent.data) && (
        <ContentSkillSelectionWithRecommendation
          courseId={courseId}
          onSkillAdd={(skill, onError) => {
            assignSkillMutation({ skillId: skill.id }, { onError })
          }}
          onSkillRemove={(skill, onError) => {
            unassignSkillMutation({ skillId: skill.id }, { onError })
          }}
          onSkillLevelChange={(skill, onError) => {
            if (isDefined(skill.levelSettingId)) {
              updateSkillLevelMutation(
                { skillId: skill.id, skillLevelSettingId: skill.levelSettingId },
                { onError }
              )
            }
          }}
          onSkillRecommendationAccepted={() => invalidateSkillsQuery()}
        />
      )}
    </View>
  )
}
