import { useChannel, useChannelEvents } from 'sierra-client/realtime-data/use-channel'
import { z } from 'zod'

type ChannelNamePrefix = `${string}`
type EventNamePrefix = `${string}`

type Event<Prefix extends EventNamePrefix, Data extends z.ZodType> = { event: Prefix; dataSchema: Data }
type ValidatedEvent<Ev> =
  Ev extends Event<EventNamePrefix, z.ZodType>
    ? {
        event: Ev['event']
        data: z.infer<Ev['dataSchema']>
      }
    : never

type DataForEventType<Ev, EvName extends EventNamePrefix> =
  Ev extends Event<EvName, infer DataType> ? z.infer<DataType> : never

type Channel<Prefix extends ChannelNamePrefix, Events extends Event<EventNamePrefix, z.ZodType>[]> = {
  channelPrefix: Prefix
  events: Events
  validate: <Ev extends Events[number]>(
    eventName: Ev['event'],
    data: unknown
  ) => data is z.infer<Ev['dataSchema']>
  useChannelEvent: <Ev extends Events[number], EvName extends Ev['event']>(props: {
    channelId: string
    eventId?: string
    event: EvName
    callback: (data: DataForEventType<Ev, EvName>) => void
  }) => ReturnType<typeof useChannelEvents>
  useChannel: <Ev extends Events[number]>(props: {
    channelId: string
    callback: (data: ValidatedEvent<Ev>) => void
  }) => ReturnType<typeof useChannel>
}

export const createEvent = <Prefix extends EventNamePrefix, DataSchema extends z.ZodType>(
  prefix: Prefix,
  dataSchema: DataSchema
): Event<Prefix, DataSchema> => ({ event: prefix, dataSchema })

export const createChannel = <
  ChannelPrefix extends ChannelNamePrefix,
  Events extends Event<EventNamePrefix, z.ZodType>[],
>(
  prefix: ChannelPrefix,
  events: Events
): Channel<ChannelPrefix, Events> => ({
  channelPrefix: prefix,
  events,
  validate: <Ev extends Events[number]>(
    eventName: Ev['event'],
    data: unknown
  ): data is z.infer<Ev['dataSchema']> => {
    const event = events.find(e => e.event === eventName)
    if (event === undefined) return false
    return event.dataSchema.safeParse(data).success
  },
  useChannelEvent: <Ev extends Events[number], EvName extends Ev['event']>(props: {
    channelId: string
    eventId?: string
    event: EvName
    callback: (data: DataForEventType<Ev, EvName>) => void
  }) => {
    const wrappedCallback = (data: unknown): void => {
      const validate = (data: unknown): ValidatedEvent<Ev>['data'] | undefined => {
        const event = events.find(e => e.event === props.event)

        if (event === undefined) return undefined
        const parseResult = event.dataSchema.safeParse(data)
        if (parseResult.success) return parseResult.data
        console.error('Error while parsing event data: ', parseResult.error)
        return undefined
      }

      const validatedData = validate(data)
      if (validatedData !== undefined) props.callback(validatedData)
    }

    return useChannelEvents(
      `${prefix}:${props.channelId}`,
      `${props.event}${props.eventId !== undefined ? `:${props.eventId}` : ''}`,
      wrappedCallback
    )
  },
  useChannel: <Ev extends Events[number]>(props: {
    channelId: string
    callback: (result: ValidatedEvent<Ev>) => void
  }) => {
    const wrappedCallback = (data: unknown, eventName: Ev['event'] | undefined): void => {
      if (eventName === undefined) {
        return
      }

      const validate = (data: unknown): ValidatedEvent<Ev>['data'] | undefined => {
        const eventValidationPrefix = eventName.split(':')[0]
        const event = events.find(e => e.event === eventValidationPrefix)
        if (event === undefined) return undefined
        const parseResult = event.dataSchema.safeParse(data)
        if (parseResult.success) return parseResult.data
        console.error('Error while parsing event data: ', parseResult.error)
        return undefined
      }

      const validatedData = validate(data)
      if (validatedData !== undefined)
        props.callback({ data: validatedData, event: eventName } as ValidatedEvent<Ev>)
    }

    return useChannel(`${prefix}:${props.channelId}`, wrappedCallback)
  },
})
