import { FC, useState } from 'react'
import { useCourseCollaboratorGroupsMutation } from 'sierra-client/api/hooks/use-course-collaborator'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { currentCourseId } from 'sierra-client/state/author-course-settings/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  CourseCollaboratorIdentitiesSelector,
  CourseCollaboratorIdentitiesSelectorProps,
} from 'sierra-client/views/course-settings/course-collaborator-identities-selector'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { CourseCollaboratorGroup, CourseRole, IdentityWithMetadata } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminCoursesListCourseCollaboratorGroups } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, LoadingSpinner, Spacer, Text } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const MaxWidthContainer = styled.div`
  max-width: 150px;
`

const CollaboratorList = styled.ul`
  list-style: none;
  position: relative;

  li {
    border-bottom: 1px solid ${palette.grey[5]};
  }

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: CourseCollaboratorGroup): string => option.groupName ?? ''

const InviteCollaboratorGroupsForm: FC<{
  courseId: NanoId12
  disabled: boolean
}> = ({ courseId, disabled }) => {
  const { t } = useTranslation()
  const notifications = useNotif()

  const [selectedCollaboratorGroupIdentities, setSelectedCollaboratorGroupIdentitiesState] = useState<
    IdentityWithMetadata[]
  >([])

  const setSelectedCollaboratorGroupIdentities: CourseCollaboratorIdentitiesSelectorProps['setSelectedIdentities'] =
    update => {
      const updatedIdentities = update(selectedCollaboratorGroupIdentities)
      setSelectedCollaboratorGroupIdentitiesState(updatedIdentities)
    }

  const fetchCollaboratorGroupIdentities = useAdminIdentitiesFetcher({ withTypes: ['user-group'] })

  const [collaboratorRole, setCollaboratorRole] = useState<CourseRole>('editor')

  const updateCollaboratorGroupsMutation = useCourseCollaboratorGroupsMutation()

  const handleInviteSelections = (): void => {
    updateCollaboratorGroupsMutation.mutate(
      {
        courseId,
        add: selectedCollaboratorGroupIdentities.map(i => {
          switch (i.identity.type) {
            case 'userGroup':
              return i.identity.id
            case 'user':
              throw new Error('User identities are not supported in collaborator group tab')
            default:
              assertNever(i.identity)
          }
        }),
        remove: [],
      },
      {
        onSuccess: () => {
          setSelectedCollaboratorGroupIdentities(() => [])

          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.group-invited-singular'),
          })
        },
      }
    )
  }

  const menuItems: MenuItem<CourseRole>[] = [
    { type: 'label', label: t('author.can-edit'), id: 'editor', selected: collaboratorRole === 'editor' },
  ]

  const selectedItem = menuItems.find(item => item.id === collaboratorRole)

  return (
    <InputContainer>
      <CourseCollaboratorIdentitiesSelector
        selectedIdentities={selectedCollaboratorGroupIdentities}
        setSelectedIdentities={setSelectedCollaboratorGroupIdentities}
        fetchIdentities={fetchCollaboratorGroupIdentities}
        type='user-group'
      />

      <MaxWidthContainer>
        <SingleSelectDropdown<CourseRole>
          selectedItem={selectedItem}
          menuItems={menuItems}
          onSelect={item => setCollaboratorRole(item.id)}
          disabled={disabled}
        />
      </MaxWidthContainer>
      <Button
        variant='secondary'
        onClick={handleInviteSelections}
        disabled={disabled || selectedCollaboratorGroupIdentities.length === 0}
      >
        {t('dictionary.invite')}
      </Button>
    </InputContainer>
  )
}

const CourseCollaboratorGroupList: FC<{
  collaboratorGroups: CourseCollaboratorGroup[]
  courseId: NanoId12
  disabled: boolean
}> = ({ collaboratorGroups, courseId, disabled }) => {
  const { t } = useTranslation()

  const updateCollaboratorGroupsMutation = useCourseCollaboratorGroupsMutation()

  const handleRemoveGroup = (group: CourseCollaboratorGroup): void => {
    updateCollaboratorGroupsMutation.mutate({
      courseId,
      add: [],
      remove: [group.groupId],
    })
  }

  const editorItem = {
    type: 'label' as const,
    label: t('author.can-edit'),
    id: 'editor' as const,
    selected: true,
  }
  const items: MenuItem<'editor' | 'remove'>[] = [
    editorItem,
    { type: 'label', label: t('admin.remove'), id: 'remove' as const, color: 'destructive/background' },
  ]

  return (
    <CollaboratorList>
      {collaboratorGroups.length === 0 ? (
        <li>
          <Text size='small' color='grey35'>
            {t('author.no-collaborators')}
          </Text>
        </li>
      ) : (
        [...collaboratorGroups].map(group => (
          <li key={group.groupId} className='grid'>
            <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold size='small'>
              {getOptionLabel(group)}
            </Text>

            <SingleSelectDropdown
              variant='ghost'
              selectedItem={editorItem}
              menuItems={items}
              onSelect={item => {
                if (item.id === 'remove') {
                  handleRemoveGroup(group)
                }
              }}
              disabled={disabled}
            />
          </li>
        ))
      )}
    </CollaboratorList>
  )
}

const DataLoader: FC<{ courseId: NanoId12 }> = ({ courseId }) => {
  const { t } = useTranslation()

  const collaboratorGroupsQuery = useCachedQuery(
    XRealtimeAdminCoursesListCourseCollaboratorGroups,
    { courseId },
    { retry: false }
  )

  const canEditCollaborators = useHasContentKindPermission(courseId, 'EDIT_COLLABORATORS')

  return (
    <Container>
      {collaboratorGroupsQuery.data === undefined ? (
        <LoadingSpinner padding='large' />
      ) : (
        <>
          <Text bold size='small'>
            {t('author.invite-collaborators')}
          </Text>
          <Spacer size='8' />
          <InviteCollaboratorGroupsForm courseId={courseId} disabled={!canEditCollaborators} />
          <Spacer size='16' />
          <CourseCollaboratorGroupList
            collaboratorGroups={collaboratorGroupsQuery.data.collaborators}
            courseId={courseId}
            disabled={!canEditCollaborators}
          />
        </>
      )}
    </Container>
  )
}

export const CollaboratorGroupsTab: SettingsTabComponent = () => {
  const courseId = useSelector(currentCourseId)

  return courseId === null ? <LoadingSpinner padding='large' /> : <DataLoader courseId={courseId} />
}
