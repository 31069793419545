import React from 'react'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ReportsView } from 'sierra-client/views/manage/reports/components/reports-view'
import { SavedHeatmapsView } from 'sierra-client/views/manage/reports/components/saved-heatmaps-view'
import { useIsReportingEnabled } from 'sierra-client/views/manage/reports/report-utils'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { Spacer } from 'sierra-ui/primitives'

export const ManageOverview: React.FC<unknown> = () => {
  const isReportingEnabled = useIsReportingEnabled()

  return (
    <ColumnLayout>
      <PageHeader title={'manage-dashboard.reports.title'} />
      <SavedHeatmapsView />
      {isReportingEnabled && (
        <>
          <Spacer size='96' />
          <ReportsView />
        </>
      )}
    </ColumnLayout>
  )
}
