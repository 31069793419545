/* eslint-disable react/jsx-no-literals */
import { useQuery } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { treeToString } from 'sierra-client/editor/utils/print-tree'
import { ExperimentalEditor } from 'sierra-client/features/experimental-editor'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { useLastDefinedValue } from 'sierra-client/hooks/use-last-defined-value'
import { useSseWithErrorNotification } from 'sierra-client/hooks/use-sse'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useCachedQuery } from 'sierra-client/state/api'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { createTestEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import { parsePastedHtml, ResolveImage } from 'sierra-client/views/v3-author/paste/with-paste-html'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { ImageWithSize, PdfPageData, ScanPageEvent } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { createFile, File } from 'sierra-domain/flexible-content/types'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import { omitUndefinedFields } from 'sierra-domain/omit-undefined-fields'
import { patchEditor } from 'sierra-domain/patch-editor'
import {
  XRealtimeAuthorExtractImagesWithVision,
  XRealtimeAuthorPdfImportGetData,
  XRealtimeAuthorProcessPdfImages,
} from 'sierra-domain/routes'
import { SSEXRealtimeAuthorScanPdfPage } from 'sierra-domain/routes-sse'
import { transformNodes } from 'sierra-domain/slate-util'
import { iife } from 'sierra-domain/utils'
import { SlateDocument, SlateRootElement } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import {
  Button,
  Heading,
  HeadingPrimitive,
  IconButton,
  LoadingSpinner,
  Text,
  View,
} from 'sierra-ui/primitives'
import { FCC } from 'sierra-ui/types'
import { Element, Text as SlateText } from 'slate'
import styled, { css } from 'styled-components'

function removeIrrelevantParts(html: string): string {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  for (const element of [
    ...Array.from(doc.querySelectorAll('footer')),
    ...Array.from(doc.querySelectorAll('.meta-header')),
    ...Array.from(doc.querySelectorAll('.meta-footer')),
  ]) {
    console.debug('[removeIrrelevantParts] Removing element', element.nodeName)
    element.remove()
  }

  return doc.documentElement.innerHTML
}

function postProcess(slateDocument: SlateDocument): SlateDocument {
  return transformNodes(slateDocument, node => {
    if (!Element.isElement(node)) {
      return node
    }

    // Images in columns should always take up the full column
    if (isElementType('horizontal-stack', node)) {
      return {
        ...node,
        children: node.children.map(column => {
          if (!isElementType('vertical-stack', column)) return column

          return {
            ...column,
            children: column.children.map(row => {
              if (!isElementType('image', row)) return row

              return {
                ...row,
                // Take up the full width of the column
                customSize: 1,
              }
            }),
          }
        }),
      }
    }

    // Make all headings and large-text paragraphs bold
    if (isElementType('heading', node) || (isElementType('paragraph', node) && node.level === 0)) {
      return {
        ...node,
        children: node.children.map(child => {
          if (!SlateText.isText(child)) return child
          return { ...child, bold: true }
        }),
      }
    }

    return node
  })
}

type Theme = 'white' | 'black'
function extractTheme(html: string): Theme {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const body = doc.querySelector('body')
  const theme = body?.getAttribute('data-theme')
  if (theme === 'dark') return 'black'
  else return 'white'
}

function htmlToSlateDocument(html: string, { resolveImage }: { resolveImage: ResolveImage }): SlateDocument {
  try {
    const parsedAndSanitized = parsePastedHtml(removeIrrelevantParts(html), {
      editor: undefined,
      resolveImage,
    })
    const pruned = parsedAndSanitized.flatMap(slateNode => {
      try {
        SlateRootElement.parse(slateNode)
        return [slateNode]
      } catch (e) {
        console.debug('Failed to parse node', slateNode)
        return []
      }
    })

    return postProcess(SlateDocument.parse(pruned))
  } catch (e) {
    console.error(e)
    return [
      createParagraph({ children: [{ text: 'Failed to parse HTML' }] }),
      createParagraph({ children: [{ text: e instanceof Error ? `Error: e.message` : 'unknown error' }] }),
    ]
  }
}

const PageHtmlContainer = styled.div<{ $theme: 'dark' | 'light' }>`
  width: 90%;
  overflow: auto;
  height: fit-content;
  border: 1px solid black;
  padding: 32px;

  background: ${props => (props.$theme === 'dark' ? 'black' : 'white')};
  color: ${props => (props.$theme === 'dark' ? 'white' : 'black')};

  /* Limit size of images */
  & img {
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    height: auto;
  }

  & table,
  th,
  td {
    border: 1px solid black;
  }

  & span {
    color: attr(data-color);
  }

  /* The <footer> and .meta-header, .meta-footer is where the LLM
   puts information that typically appears in the margin of the page
   such as logos, addresses, etc. We remove this from the final content
   but keep it greyed out in the html preview for easier debugging.
   We don't want to ignore the real <header> tag as the LLM likes to put
   useful data there. */
  & .meta-header,
  & .meta-footer,
  & footer {
    opacity: 0.2;
    border: 1px dashed rgba(255, 0, 0, 1);
  }

  /* Emulate the columns that our editor supports */
  & .row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  & .column {
    flex: 1;
  }
`

const PageHtml = styled.div``

function cleanDemarcators(text: string): string {
  const htmlStart = '```html'
  const htmlEnd = '```'
  let newText = text
  if (text.startsWith(htmlStart)) {
    newText = newText.slice(htmlStart.length)
  }
  if (newText.endsWith(htmlEnd)) {
    newText = newText.slice(0, newText.length - htmlEnd.length)
  }
  return newText
}

function resolveImagesInHtml({
  text,
  images,
  pdfId,
}: {
  text: string
  images: ScannedImage[]
  pdfId: NanoId12
}): string {
  let newText = text
  for (const image of images) {
    const oldSrc = `${image.id}`
    const assetContext: AssetContext = { type: 'pdf-image', pdfId }
    const newSrc = resolveNamespacedCourseImage(image.image, 'default', assetContext)
    newText = newText
      .replaceAll(`src="${oldSrc}"`, `src="${newSrc}"`)
      .replaceAll(`src='${oldSrc}'`, `src='${newSrc}'`)
  }
  return newText
}

const domPurifyConfig: DOMPurify.Config = {
  ALLOWED_TAGS: [
    's',
    'p',
    'div',
    'span',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'em',
    'b',
    'i',
    'u',
    'sup',
    'sub',
    'br',
    'ul',
    'ol',
    'li',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'img',
    'body',
    'footer',
  ],
  ALLOWED_ATTR: ['class', 'style', 'src', 'alt', 'colspan', 'rowspan', 'data-theme'],
}

const PdfPageAsImgContainerHeight = 400
const PdfPageAsImgContainer = styled.div<{ $placeholderSize: { height: number; width: number } | undefined }>`
  height: ${PdfPageAsImgContainerHeight}px;
  min-height: ${PdfPageAsImgContainerHeight}px;
  max-height: ${PdfPageAsImgContainerHeight}px;
  ${props => {
    if (props.$placeholderSize === undefined) {
      return css`
        width: auto;
        width: fit-content;
      `
    }
    return css`
      width: ${(props.$placeholderSize.width / props.$placeholderSize.height) *
      PdfPageAsImgContainerHeight}px;
      background: rgba(0, 0, 0, 0.1);
    `
  }};

  border: 1px solid black;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`

const PdfPageAsImg = styled.img`
  display: block;
  height: 100%;
  width: auto;
`

const SmallImg = styled.img<{ fixedHeight?: string }>`
  max-height: 100px;
  max-width: 100px;

  object-fit: contain;
  height: ${props => props.fixedHeight ?? 'auto'};
  width: auto;
`

const Collapsable: FCC<{ title: string }> = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <View direction='column' gap='8'>
      <Button onClick={() => setExpanded(!expanded)} icon={expanded ? 'arrow--down' : 'arrow--right'}>
        {title}
      </Button>
      {expanded && children}
    </View>
  )
}

const ImageWithSizeComponent: React.FC<{ image: ImageWithSize }> = ({ image }) => {
  return (
    <View direction='column' gap='4'>
      <SmallImg src={image.src} />
      <Text>
        {image.height}x{image.width}
      </Text>
    </View>
  )
}

const ViewPdfPage: React.FC<{
  pdfId: NanoId12
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
}> = ({ pdfId, pageIndex, setPageIndex }) => {
  const imagesQuery = useCachedQuery(
    XRealtimeAuthorProcessPdfImages,
    { pdfId, pageIndex },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  )

  const [extractWithVisionEnabledForPage, setExtractWithVisionEnabledForPage] = useState<{
    pageIndex: number | undefined
  }>({ pageIndex: undefined })
  const extractWithVisionEnabled = extractWithVisionEnabledForPage.pageIndex === pageIndex

  const extractImagesWithVisionQuery = useCachedQuery(
    XRealtimeAuthorExtractImagesWithVision,
    {
      pdfId,
      pageIndex,
    },
    {
      enabled: extractWithVisionEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  )

  const { data } = imagesQuery

  const latestDefinedData = useLastDefinedValue(data)

  return (
    <View direction='column' gap='16'>
      <View gap='32'>
        {imagesQuery.error ? (
          <Text>{imagesQuery.error.message}</Text>
        ) : (
          <>
            <View direction='column'>
              <PdfPageAsImgContainer
                $placeholderSize={
                  data === undefined && latestDefinedData !== undefined
                    ? { height: latestDefinedData.pageRect.height, width: latestDefinedData.pageRect.width }
                    : undefined
                }
              >
                {data !== undefined ? (
                  <>
                    <PdfPageAsImg src={imagesQuery.data.pageImage.src} />

                    {data.imageRects.map((rect, index) => (
                      <div
                        key={index}
                        // eslint-disable-next-line react/forbid-dom-props
                        style={{
                          position: 'absolute',
                          border: '2px dashed rgba(255, 0, 0, 0.5)',
                          left: `${(rect.x / data.pageRect.width) * 100}%`,
                          top: `${(rect.y / data.pageRect.height) * 100}%`,
                          width: `${(rect.width / data.pageRect.width) * 100}%`,
                          height: `${(rect.height / data.pageRect.height) * 100}%`,
                          transformOrigin: 'bottom left',
                          transform: `rotate(${rect.rotation}deg)`,
                        }}
                      />
                    ))}

                    {data.groupedRects.map((rect, index) => (
                      <div
                        key={index}
                        // eslint-disable-next-line react/forbid-dom-props
                        style={{
                          position: 'absolute',
                          border: '3px dashed rgba(0, 0, 255, 0.9)',
                          left: `${(rect.x / data.pageRect.width) * 100}%`,
                          top: `${(rect.y / data.pageRect.height) * 100}%`,
                          width: `${(rect.width / data.pageRect.width) * 100}%`,
                          height: `${(rect.height / data.pageRect.height) * 100}%`,
                          transformOrigin: 'bottom left',
                          transform: `rotate(${rect.rotation}deg)`,
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <Text>imagesQuery: {JSON.stringify(imagesQuery.status)}</Text>
                )}
              </PdfPageAsImgContainer>

              <View>
                <IconButton
                  iconId='arrow--left'
                  onClick={() => {
                    setPageIndex(previous => previous - 1)
                  }}
                />
                <IconButton
                  iconId='arrow--right'
                  onClick={() => {
                    setPageIndex(previous => previous + 1)
                  }}
                />
              </View>

              {data !== undefined && (
                <View direction='column' gap='16'>
                  <View direction='column' alignSelf='flex-start'>
                    <View direction='column'>
                      <HeadingPrimitive bold size='h4'>
                        Images in page
                      </HeadingPrimitive>
                      <View gap='4'>
                        {data.images.map((image, index) => (
                          <ImageWithSizeComponent key={index} image={image} />
                        ))}
                      </View>
                    </View>

                    <View direction='column'>
                      <HeadingPrimitive bold size='h4'>
                        Grouped images in page
                      </HeadingPrimitive>
                      <View gap='4'>
                        {data.groupedImages.map((image, index) => (
                          <ImageWithSizeComponent key={index} image={image} />
                        ))}
                      </View>
                    </View>
                  </View>

                  {data.extractedImages.map((result, index) => (
                    <View key={index} direction='column' gap='4'>
                      <HeadingPrimitive bold size='h4'>
                        {result.heuristic}
                      </HeadingPrimitive>
                      <Text bold>Images</Text>
                      <View gap='4'>
                        {result.images.map((image, index) => (
                          <ImageWithSizeComponent key={index} image={image} />
                        ))}
                      </View>
                      <Collapsable title='Debug'>
                        <View>
                          {result.debugDataList.map(({ image }, index) => (
                            <ImageWithSizeComponent key={index} image={image} />
                          ))}
                        </View>
                      </Collapsable>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </>
        )}
      </View>

      <Button
        loading={extractImagesWithVisionQuery.isFetching}
        disabledWithReason={extractImagesWithVisionQuery.isFetching ? 'Loading...' : undefined}
        onClick={() => {
          if (!extractWithVisionEnabled) {
            setExtractWithVisionEnabledForPage({ pageIndex })
          } else {
            void extractImagesWithVisionQuery.refetch()
          }
        }}
      >
        {extractWithVisionEnabled ? 'Restart extracting images with vision' : 'Extract images with vision'}
      </Button>
      <>
        {extractImagesWithVisionQuery.isError && <Text>{extractImagesWithVisionQuery.error.message}</Text>}
        {extractImagesWithVisionQuery.data !== undefined && !extractImagesWithVisionQuery.isFetching && (
          <View direction='column' gap='16'>
            <View gap='4'>
              <Text bold>Passes: {extractImagesWithVisionQuery.data.passes}</Text>
              <Text size='technical'>
                (the system can run multiple passes if too many images are given low confidence scores)
              </Text>
            </View>

            <Text bold>
              Evaluated images ({extractImagesWithVisionQuery.data.imagesWithConfidence.length})
            </Text>
            <View gap='16' direction='row' alignItems='flex-start'>
              {_.chain(extractImagesWithVisionQuery.data.imagesWithConfidence)
                .sortBy(image => image.confidence.score)
                .reverse()
                .value()
                .map(({ image, confidence }, index) => (
                  <View key={index} direction='column' gap='4'>
                    <SmallImg fixedHeight='100px' src={image.previewSrc} />
                    <Text>
                      <strong>Confidence</strong>: {confidence.score}
                    </Text>
                    <Text>
                      <strong>Reason:</strong> {confidence.reason}
                    </Text>
                  </View>
                ))}
            </View>

            <Text bold>
              Ignored images due to processing limits (
              {extractImagesWithVisionQuery.data.ignoredImages.length})
            </Text>
            <View gap='16' direction='row'>
              {extractImagesWithVisionQuery.data.ignoredImages.map((image, index) => (
                <SmallImg key={index} src={image.previewSrc} />
              ))}
            </View>
          </View>
        )}
      </>
    </View>
  )
}

const CandidateImageWraper = styled(View)`
  max-width: 150px;
  border: 1px solid black;
  padding: 8px;
`

const Flex1 = styled.div`
  flex: 1;
  overflow: hidden;
`

function allTagsAndAttributes(document: Document): { tags: Set<string>; attributes: Set<string> } {
  const allTags = Array.from(document.querySelectorAll('*'))
  const allAttributes = allTags.flatMap(tag => Array.from(tag.attributes)).map(tag => tag.name)
  const tagNames = allTags.map(tag => tag.tagName)
  return { tags: new Set(tagNames), attributes: new Set(allAttributes) }
}

type ScannedImage = ScanPageEvent & { type: 'image' }

type LlmOutput = {
  text: string
  images: ScannedImage[]
  slateDocument: SlateDocument | undefined
  file: File
  events: { timeSinceLastEvent: number; data: ScanPageEvent }[]
}

const emptyLlmOutput: LlmOutput = {
  text: '',
  images: [],
  file: createFile(),
  slateDocument: undefined,
  events: [],
}

const PreHtml = styled.pre`
  overflow-x: auto;
`

const Page: React.FC<{
  page: PdfPageData
  pdfId: NanoId12
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
}> = ({ page, pdfId, setPageIndex }) => {
  const { subscribeSse } = useSseWithErrorNotification()

  const [enableSseForPage, setEnableSseForPage] = React.useState<[pageId: string, isEnabled: boolean]>([
    page.id,
    false,
  ])

  useEffect(() => {
    return () => {
      // Disable for the current page when unmounting
      setEnableSseForPage([page.id, false])
    }
  }, [page.id])

  const enabled = enableSseForPage[0] === page.id && enableSseForPage[1]

  const [llmOutput, _setLlmOutput] = useState<[session: Date, LlmOutput] | undefined>(undefined)
  const [replay, setReplay] = useState<LlmOutput['events'] | undefined>(undefined)

  // It's possible for the SSE calls to overlap at this point. So to ensure we only get the latest output,
  // we create a new date each time we start streaming, and ensure that all new output is from that date.
  const setLlmOutput = useStableFunction((session: Date, setAction: React.SetStateAction<LlmOutput>) => {
    _setLlmOutput(previousValue => {
      const newValue =
        typeof setAction === 'function' ? setAction(previousValue?.[1] ?? emptyLlmOutput) : setAction
      if (previousValue === undefined) {
        return [session, newValue]
      }
      const [previousDate] = previousValue
      if (previousDate.getTime() > session.getTime()) {
        // The new value is from a different SSE session
        return previousValue
      }
      return [session, newValue]
    })
  })

  const scanPage = useQuery({
    queryKey: [SSEXRealtimeAuthorScanPdfPage, { pdfId: pdfId, pageIndex: page.pageIndex }],
    queryFn: async ({ signal }) => {
      const session = new Date()

      setLlmOutput(session, emptyLlmOutput)

      const setSlateDocument = _.throttle(html => {
        setLlmOutput(session, previous => {
          const resolveImage: ResolveImage = src => {
            const asset = previous.images.find(image => image.id === src)
            if (asset === undefined) {
              return undefined
            } else {
              return { image: asset.image }
            }
          }
          return {
            ...previous,
            slateDocument: htmlToSlateDocument(cleanDemarcators(html), { resolveImage }),
          }
        })
      })

      let text = ''
      let lastEventTime: number | undefined = undefined
      const handleData = (data: ScanPageEvent): void => {
        setLlmOutput(session, previous => {
          const time = new Date().getTime()
          const timeSinceLastEvent = lastEventTime === undefined ? 0 : time - lastEventTime
          lastEventTime = time
          const event: LlmOutput['events'][number] = { timeSinceLastEvent, data }

          return {
            ...previous,
            events: [...previous.events, event],
          }
        })

        if (data.type === 'text') {
          setLlmOutput(session, previous => {
            const html = previous.text + data.text

            const newFile: File = {
              ...previous.file,
              theme: extractTheme(html),
            }

            return {
              ...previous,
              file: _.isEqual(previous.file, newFile) ? previous.file : newFile,
              text: html,
            }
          })
          text += data.text
          setSlateDocument(text)
        } else {
          setLlmOutput(session, previous => ({
            ...previous,
            images: [...previous.images, data],
          }))
        }
      }

      if (replay !== undefined) {
        for (const event of replay) {
          await new Promise(resolve =>
            setTimeout(
              resolve,
              // replay at faster speed
              event.timeSinceLastEvent / 5
            )
          )
          handleData(event.data)
        }
      } else {
        await subscribeSse(
          SSEXRealtimeAuthorScanPdfPage,
          { pdfId: pdfId, pageIndex: page.pageIndex },
          ({ data }) => handleData(data),
          () => {},
          signal
        )
      }

      setSlateDocument.flush()
      return {}
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
  })

  const images = llmOutput?.[1].images ?? []
  const llmOutputText = llmOutput?.[1].text ?? ''
  const slateDocument = llmOutput?.[1].slateDocument
  const replayEvents = llmOutput?.[1].events ?? []
  const llmOutputFile = llmOutput?.[1].file

  const htmlString = resolveImagesInHtml({ text: cleanDemarcators(llmOutputText), images, pdfId })
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const sanitizedHtmlString = DOMPurify.sanitize(htmlString, domPurifyConfig).toString()

  const { droppedTags, droppedAttributes } = iife(() => {
    const rawHtml = new DOMParser().parseFromString(htmlString, 'text/html')
    const sanitizedHtml = new DOMParser().parseFromString(sanitizedHtmlString, 'text/html')

    const { tags: rawTags, attributes: rawAttributes } = allTagsAndAttributes(rawHtml)
    const { tags: sanitizedTags, attributes: sanitizedAttributes } = allTagsAndAttributes(sanitizedHtml)
    return {
      droppedTags: Array.from(rawTags).filter(tag => !sanitizedTags.has(tag)),
      droppedAttributes: Array.from(rawAttributes).filter(attr => !sanitizedAttributes.has(attr)),
    }
  })

  const pdfAssetContext = useMemo((): AssetContext => ({ type: 'pdf-image', pdfId }), [pdfId])

  const [showHtml, setShowHtml] = useState(true)
  const [showSlate, setShowSlate] = useState(true)

  const outputSession = llmOutput !== undefined ? llmOutput[0] : undefined
  const editorProps = useMemo(() => {
    if (outputSession === undefined || !showSlate) {
      return undefined
    }
    const editor = createTestEditor()
    return {
      editor,
      editorId: createNanoId12FromString(outputSession.toISOString()),
    }
  }, [outputSession, showSlate])

  const { reportInDev } = useDevelopmentSnackbar()
  useEffect(() => {
    if (editorProps?.editor !== undefined && slateDocument !== undefined) {
      try {
        patchEditor(editorProps.editor, slateDocument, true)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Failed to patch editor, target:', slateDocument)
        reportInDev('Failed to patch editor', {})
      }
    }
  }, [editorProps?.editor, reportInDev, slateDocument])

  return (
    <View direction='column' gap='16'>
      <ViewPdfPage pdfId={pdfId} pageIndex={page.pageIndex} setPageIndex={setPageIndex} />

      <View>
        <Button
          onClick={() => {
            if (!enabled) {
              setEnableSseForPage([page.id, true])
            } else {
              void scanPage.refetch()
            }
          }}
          loading={enabled && scanPage.isFetching}
        >
          {iife(() => {
            if (replay !== undefined) {
              return 'Replay events'
            } else if (!enabled) {
              return 'Start generation'
            } else {
              return 'Restart generation'
            }
          })}
        </Button>

        <Button
          onClick={() => {
            setShowSlate(previous => !previous)
          }}
          disabledWithReason={slateDocument === undefined ? 'No slate data' : undefined}
        >
          {showSlate ? 'Hide slate' : 'Show slate'}
        </Button>

        <Button
          onClick={() => {
            setShowHtml(previous => !previous)
          }}
          disabledWithReason={llmOutputText.trim().length === 0 ? 'No html data' : undefined}
        >
          {showHtml ? 'Hide HTML' : 'Show HTML'}
        </Button>

        {replayEvents.length > 0 && (
          <Button
            variant='secondary'
            onClick={() => {
              setReplay(previous => (previous === undefined ? replayEvents : undefined))
            }}
          >
            {replay === undefined ? 'Save replay' : 'Clear replay'}
          </Button>
        )}
      </View>

      {scanPage.isError && <Text bold>🚨 Generation error: {scanPage.error.message}</Text>}

      {llmOutputFile !== undefined && llmOutputText.trim().length > 0 && (
        <>
          <View alignItems='flex-start'>
            {showSlate && (
              <Flex1>
                <Text bold size='large'>
                  Editor output:
                </Text>
                {slateDocument !== undefined && editorProps !== undefined && (
                  <PolarisCardTheme data={llmOutputFile.data} theme={llmOutputFile.theme}>
                    <CardCanvas assetContext={pdfAssetContext} card={llmOutputFile}>
                      <ExperimentalEditor
                        mode='create'
                        editor={editorProps.editor}
                        editorId={editorProps.editorId}
                        assetContext={pdfAssetContext}
                        abortSignal={undefined}
                        actions={[]}
                        onFinishedRunningActions={() => {}}
                        setError={() => {}}
                      />
                    </CardCanvas>
                  </PolarisCardTheme>
                )}
              </Flex1>
            )}

            {showHtml && (
              <Flex1>
                <Text bold size='large'>
                  HTML output:
                </Text>
                <PageHtmlContainer
                  $theme={llmOutputFile.theme === 'black' ? ('dark' as const) : ('light' as const)}
                >
                  <PageHtml
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(htmlString, domPurifyConfig),
                    }}
                  />
                </PageHtmlContainer>
              </Flex1>
            )}
          </View>

          <Text bold size='large'>
            Candidate Images:
          </Text>
          <View wrap='wrap' alignItems='flex-start' justifyContent='flex-start'>
            {_.chain(images)
              .sortBy(image => image.confidence?.score ?? 0)
              .reverse()
              .value()
              .map((image, index) => (
                <CandidateImageWraper key={index} direction='column'>
                  {image.confidence && (
                    <>
                      <Text>
                        <strong>Confidence</strong>: {image.confidence.score}
                      </Text>
                      <Text>
                        <strong>Reason:</strong> {image.confidence.reason}
                      </Text>
                    </>
                  )}

                  <SmallImg src={resolveNamespacedCourseImage(image.image, 'course-sm', pdfAssetContext)} />
                </CandidateImageWraper>
              ))}
          </View>

          <View direction='column'>
            <Text bold size='large'>
              Dropped tags:
            </Text>
            {droppedTags.length === 0 ? (
              <Text>✅ No tags dropped</Text>
            ) : (
              <>
                <Text>⚠️ Dropped {droppedTags.length} tags</Text>
                <ul>
                  {droppedTags.map(tag => (
                    <li key={tag}>{tag}</li>
                  ))}
                </ul>
              </>
            )}

            {droppedAttributes.length === 0 ? (
              <Text>✅ No attributes dropped</Text>
            ) : (
              <>
                <Text bold size='large'>
                  ⚠️ Dropped {droppedAttributes.length} attributes:
                </Text>
                <ul>
                  {droppedAttributes.map(attr => (
                    <li key={attr}>{attr}</li>
                  ))}
                </ul>
              </>
            )}

            <Text bold size='large'>
              Raw html:
            </Text>
            <Text size='technical'>
              <PreHtml>{htmlString}</PreHtml>
            </Text>

            <Text bold size='large'>
              Sanitized html:
            </Text>
            <Text size='technical'>
              <PreHtml>{sanitizedHtmlString}</PreHtml>
            </Text>

            {slateDocument !== undefined && (
              <>
                <Text bold size='large'>
                  Slate tree:
                </Text>
                <Text size='technical'>
                  <PreHtml>
                    {treeToString(omitUndefinedFields(slateDocument), {
                      includeIds: false,
                      includeProps: true,
                    })}
                  </PreHtml>
                </Text>
              </>
            )}
          </View>
        </>
      )}
    </View>
  )
}

export const GenerateWithVision: React.FC<{ pdfId: NanoId12 }> = ({ pdfId }) => {
  const pdfData = useCachedQuery(
    XRealtimeAuthorPdfImportGetData,
    { pdfId },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  ).data

  const [pdfIndex, _setPdfIndex] = useState(0)
  const setPageIndex: typeof _setPdfIndex = useStableFunction(setAction => {
    const numberOfPages = pdfData?.type === 'completed' ? pdfData.pages?.length : undefined
    if (numberOfPages === undefined) {
      return
    }

    _setPdfIndex(previousValue => {
      let newValue = typeof setAction === 'function' ? setAction(previousValue) : setAction
      if (newValue < 0) {
        newValue = numberOfPages + newValue
      }
      return Math.max(0, newValue % numberOfPages)
    })
  })

  if (pdfData === undefined) {
    return <div>Loading...</div>
  }

  if (pdfData.type !== 'completed') {
    return <div>Pdf is still processing...</div>
  }

  const relevantPage = iife(() => {
    const relevantPage = pdfData.pages?.[pdfIndex]
    return relevantPage
  })

  return (
    <View direction='column' padding='32'>
      <Heading size='h2' bold>
        {pdfData.filename}
      </Heading>

      <View>
        <Text bold>Page:</Text>
        <input
          type='number'
          value={pdfIndex}
          onChange={e => {
            setPageIndex(parseInt(e.target.value))
          }}
        />
      </View>

      {relevantPage !== undefined ? (
        <Page pdfId={pdfId} page={relevantPage} setPageIndex={setPageIndex} />
      ) : (
        <LoadingSpinner />
      )}
    </View>
  )
}
