import { useCallback } from 'react'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Report } from 'sierra-domain/api/analytics-reporting'
import { isLeft } from 'sierra-domain/either'
import { UserErrorCode } from 'sierra-domain/error'
import { XRealtimeAnalyticsReportsJobsGetDownloadUrl } from 'sierra-domain/routes'

export const useIsReportingEnabled = (): boolean => {
  return useOrganizationPermissions().has('ACCESS_REPORTS')
}

export const useAreReportJobsEnabled = (): boolean => {
  // Analytics users can download reports without Reports access
  const organizationPermissions = useOrganizationPermissions()
  return organizationPermissions.has('ACCESS_REPORTS') || organizationPermissions.has('ACCESS_ANALYTICS')
}

export const useDownloadReport: () => (jobId: string) => Promise<UserErrorCode | undefined> = () => {
  const { postWithUserErrorCode } = usePost()

  const downloadReport = useCallback(
    async (jobId: string) => {
      const result = await postWithUserErrorCode(XRealtimeAnalyticsReportsJobsGetDownloadUrl, {
        jobId,
      })

      if (isLeft(result)) {
        return result.left
      }

      window.open(result.right.url, '_blank', 'noopener,noreferrer')
    },
    [postWithUserErrorCode]
  )

  return downloadReport
}

type GetReportTitle = (report: Report) => string
type GetReportDescription = (report: Report) => string

export const useGetReportTitle = (): GetReportTitle => {
  const { dynamicT } = useTranslation()

  return useCallback<GetReportTitle>(
    report => dynamicT(`manage.reports.report-title.${report.id}`, undefined, report.title),
    [dynamicT]
  )
}

export const useGetReportDescription = (): GetReportDescription => {
  const { dynamicT } = useTranslation()

  return useCallback<GetReportDescription>(
    report => dynamicT(`manage.reports.report-description.${report.id}`, undefined, report.description),
    [dynamicT]
  )
}
