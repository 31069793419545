import { Icon, IconId } from 'sierra-ui/components'
import styled from 'styled-components'

const StyledThumbnail = styled.div<{ $height?: number; $width?: number; $radius?: number }>`
  display: flex;
  position: relative;
  width: ${p => (p.$width !== undefined ? p.$width : 4)}rem;
  min-width: ${p => (p.$width !== undefined ? p.$width : 4)}rem;
  height: ${p => (p.$height !== undefined ? p.$height : 4)}rem;
  min-height: ${p => (p.$height !== undefined ? p.$height : 4)}rem;
  border-radius: ${p => (p.$radius !== undefined ? p.$radius : 1)}rem;
  background-color: ${p => p.theme.color.grey10};
  overflow: hidden;
`

const ThumbnailImg = styled.img.attrs({
  alt: '', // decorative image
})`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const IconWrapper = styled.div<{ iconSize?: 'small' | 'normal' }>`
  height: ${p => (p.iconSize === 'small' ? '15px' : '20px')};
  width: ${p => (p.iconSize === 'small' ? '15px' : '20px')};
  position: absolute;
  bottom: 4px;
  left: 4px;

  border-radius: 6px;
  border: 1px solid;
  border-image-source: linear-gradient(
    156.52deg,
    rgba(255, 255, 255, 0.28) 2.12%,
    rgba(255, 255, 255, 7e-5) 39%,
    rgba(255, 255, 255, 7e-5) 54.33%,
    rgba(255, 255, 255, 0.07) 93.02%
  );

  box-shadow: 0px 4px 12px 0px #00000014;

  backdrop-filter: blur(68px);
  background-blend-mode: saturation;
  background: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px;
`

export const Thumbnail: React.FC<{
  image?: string
  className?: string
  style?: React.CSSProperties
  width?: number
  height?: number
  radius?: number
  lazy?: boolean
  iconId?: IconId
  iconSize?: 'small' | 'normal'
}> = ({ image, width, height, radius, lazy, iconId, iconSize, ...props }) => (
  <StyledThumbnail $width={width} $height={height} $radius={radius} {...props}>
    {image !== undefined ? <ThumbnailImg loading={lazy !== false ? 'lazy' : undefined} src={image} /> : null}
    {iconId !== undefined && (
      <IconWrapper iconSize={iconSize}>
        <Icon iconId={iconId} size={iconSize === 'small' ? 'size-10' : 'size-14'} color='grey75' />
      </IconWrapper>
    )}
  </StyledThumbnail>
)
