import { keepPreviousData } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ImageFragmentFragment } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { CourseId } from 'sierra-domain/api/nano-id'
import { andAll, Filter } from 'sierra-domain/filter/datatype/filter'
import { STATIC_EMPTY_ARRAY } from 'sierra-domain/utils'
import { Autocomplete, AutocompleteOption } from 'sierra-ui/components'
import { RenderMatchingListContext } from 'sierra-ui/components/autocomplete/types'
import { IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const coursesDataSource = graphql(`
  query CoursesDataSource($query: String!, $limit: Int!, $filter: ContentFilter!) {
    content(query: $query, limit: $limit, contentTypes: [COURSE], filter: $filter) {
      data {
        contentId
        __typename
        ... on Course {
          courseId
        }
        ... on NativeCourseGroup {
          courseEditions {
            edition {
              ...ExpensiveContentFragment
            }
          }
          privateCourseEditionsCount
        }
        title

        image {
          ...ImageFragment
        }
      }
    }
  }
`)

// This filter is used to filter out the content with empty title. Since the initial query is an empty string,
// content without a title will be returned by default unless we apply this filter. This should provide a better
// user experience. We assume that that important content will have a title.
const searchContentFilter = (): Filter =>
  andAll([
    {
      type: 'filter.filter',
      domain: {
        type: 'content.title',
      },
      operator: {
        type: 'operator.neq',
      },
      predicate: {
        type: 'predicate.single',
        value: {
          type: 'value.string',
          value: '',
        },
      },
    },
    {
      type: 'filter.filter',
      domain: {
        type: 'content.classification',
      },
      operator: {
        type: 'operator.eq',
      },
      predicate: {
        type: 'predicate.single',
        value: {
          type: 'value.string',
          value: 'course',
        },
      },
    },
  ])

type Props<T extends CourseMenuItem> = {
  getItemProps: RenderMatchingListContext<T>['getItemProps']
  matchingItems: T[]
}

const ListContentWrapper = styled.div`
  background: ${token('surface/default')};
  max-height: 300px;
  gap: 0;
  overflow: auto;
`
const ListItem = styled(AutocompleteOption)`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid ${token('border/strong')};
  transition: all 150ms;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &[data-highlighted],
  &:hover {
    background-color: ${token('foreground/primary').opacity(0.1)};
  }
`
const ListThumbnail = styled(Thumbnail).attrs({
  height: 2,
  width: 3,
  radius: 0.5,
})``
const VirtualizedContent = <T extends CourseMenuItem>({
  getItemProps,
  matchingItems,
}: Props<T>): JSX.Element => {
  const scrollingRef = useRef<HTMLDivElement>(null)

  const assetResolver = useAssetResolver({ size: 'thumbnail' })

  return (
    <ListContentWrapper ref={scrollingRef}>
      <ListVirtualizer
        items={matchingItems}
        scrollElement={scrollingRef.current}
        estimateSize={1}
        renderItem={(menuItem, index) => {
          const imageSrc = assetResolver(convertGQLImage(menuItem.image), {
            type: 'course',
            courseId: menuItem.id,
          })
          return (
            <ListItem key={menuItem.id} {...getItemProps(menuItem, index)}>
              <ListThumbnail image={imageSrc} />
              <View>{menuItem.label}</View>
            </ListItem>
          )
        }}
      />
    </ListContentWrapper>
  )
}

const SelectedContentPill = styled(View)`
  background: ${token('form/background/2')};
  padding: 3px;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
`

const SelectedContentThumbnail = styled(Thumbnail).attrs({
  height: 1.125,
  width: 1.5,
  radius: 0.3125,
})``

type CourseMenuItem = {
  id: CourseId
  label: string
  image?: ImageFragmentFragment
}
export const CourseSelector: React.FC<{
  selectedIds: Array<CourseId>
  onSelect: (id: CourseId) => void
  onDelete: (id: CourseId) => void
}> = ({ onSelect, onDelete, selectedIds }) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const searchContent = useGraphQuery(
    {
      document: coursesDataSource,
      queryOptions: {
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
        // We only care about selfpaced for now
        select: (data): Array<CourseMenuItem> =>
          data.content.data.flatMap(c => {
            if (c.__typename === 'NativeCourseGroup') {
              return c.courseEditions.map(ce => ({
                id: ce.edition.courseId,
                label: ce.edition.title,
                image: null2Undefined(ce.edition.image),
              }))
            }
            if (c.__typename === 'NativeSelfPacedCourse') {
              return [
                {
                  id: c.courseId,
                  label: c.title,
                  image: null2Undefined(c.image),
                },
              ]
            }
            return []
          }),
      },
    },
    // TODO: (HUY) This component is still a POC. This limit will be removed
    { query, limit: 999, filter: JSON.stringify(searchContentFilter()) }
  )

  const menuItems = searchContent.data ?? STATIC_EMPTY_ARRAY

  const selected = menuItems.filter(item => selectedIds.includes(item.id))

  const assetResolver = useAssetResolver({ size: 'thumbnail' })

  return (
    <Autocomplete
      autofocus
      placeholder={t('scenario-card.data-source.course-placeholder')}
      query={query}
      onQueryChange={setQuery}
      matchingItems={menuItems}
      selectedItems={selected}
      onSelect={item => {
        onSelect(item.id)
      }}
      onUnselect={item => {
        onDelete(item.id)
      }}
      maxLines={1}
      renderMatchingItemList={({ getItemProps }) => (
        <VirtualizedContent getItemProps={getItemProps} matchingItems={menuItems} />
      )}
      renderSelectedItem={(item, { onUnselect }) => {
        const imageSrc = assetResolver(convertGQLImage(item.image), { type: 'course', courseId: item.id })
        return (
          <SelectedContentPill gap='4'>
            <SelectedContentThumbnail image={imageSrc} />
            <View>{item.label}</View>
            <IconButton iconId='close' size='x-small' variant='transparent' onClick={onUnselect} />
          </SelectedContentPill>
        )
      }}
    />
  )
}
