import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { useInvalidateEditableContentCache } from 'sierra-client/api/hooks/queries-invalidation'
import { typedPost } from 'sierra-client/state/api'
import { ImportFlexibleContent } from 'sierra-domain/api/admin'
import { EditableCourseKind } from 'sierra-domain/api/editable-content'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentRecord, SerializedYDoc } from 'sierra-domain/collaboration/serialization/types'
import { XRealtimeAuthorGetCourseSettings, XRealtimeAuthorImportFlexibleContent } from 'sierra-domain/routes'

export function useDuplicateCourseVersionMutation(): UseMutationResult<
  { kind: EditableCourseKind; id: CreateContentId },
  unknown,
  { serializedYDoc: SerializedYDoc; courseId: CreateContentId },
  unknown
> {
  const invalidate = useInvalidateEditableContentCache()

  const mutation = useMutation<
    { kind: EditableCourseKind; id: CreateContentId },
    unknown,
    { serializedYDoc: SerializedYDoc; courseId: CreateContentId },
    unknown
  >({
    mutationFn: async ({ serializedYDoc, courseId }) => {
      const { settings, kind: _kind } = await typedPost(XRealtimeAuthorGetCourseSettings, {
        courseId,
      })
      const kind = EditableCourseKind.parse(_kind)

      const course: ImportFlexibleContent = {
        kind,
        settings: settings,
        record: FlexibleContentRecord.parse(serializedYDoc['']),
        importAssetsFromCourseId: courseId,
      }

      const { courseId: newCourseId } = await typedPost(XRealtimeAuthorImportFlexibleContent, course)

      return { kind, id: newCourseId }
    },
    onSuccess: () => {
      void invalidate()
    },
  })

  return mutation
}
