import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { usePost } from 'sierra-client/hooks/use-post'
import { EnrolledUser, ListCourseEnrolledUsersRequest } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsUnassignFromUser,
  XRealtimeAdminCoursesListEnrolledUsers,
} from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'

export const useUnassignContent = (): ((userId: UserId, content: SharingModalContent) => Promise<void>) => {
  const { postWithUserErrorException } = usePost()

  const unassignContent: (userId: UserId, content: SharingModalContent) => Promise<void> = useCallback(
    async (userId, content) => {
      const groupIds: string[] = []
      const pathIds: string[] = []

      const { courseIds, liveSessionIds } = iife(() => {
        switch (content.type) {
          case 'course':
            return {
              courseIds: [content.id],
              liveSessionIds: [],
            }
          case 'live-session':
            return {
              courseIds: [],
              liveSessionIds: [content.id],
            }
          default:
            assertNever(content.type)
        }
      })

      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromUser, {
        userId,
        courseIds,
        pathIds,
        groupIds,
        liveSessionIds,
      })
    },
    [postWithUserErrorException]
  )

  return unassignContent
}

export const useExistingUserAssignments = ({
  courseId,
  shouldLoadMore,
}: {
  courseId: NanoId12
  shouldLoadMore: boolean
}): {
  assignments: EnrolledUser[]
  isLoading: boolean | undefined
  reloadAssignments: () => Promise<void>
  hasMore: boolean
} => {
  const { postWithUserErrorException } = usePost()

  const [userAssignments, setUserAssignments] = useState<EnrolledUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined)
  const [hasMore, setHasMore] = useState(true)

  const [lastUserId, setLastUserId] = useState<UserId | undefined>(undefined)
  const fetchUserAssignments = useCallback(
    async (lastUserId?: UserId) => {
      const request: ListCourseEnrolledUsersRequest = {
        courseId: courseId,
        assignedToLiveSession: undefined,
        commonFilters: {
          lastUserId: lastUserId,
          maxResults: undefined,
          query: undefined,
          sortBy: { direction: 'asc', type: 'name' },
          requestedUserIds: undefined,
          groupIds: undefined,
        },
        assignmentType: 'direct',
      }
      return postWithUserErrorException(XRealtimeAdminCoursesListEnrolledUsers, request)
    },
    [courseId, postWithUserErrorException]
  )

  const fetch = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await fetchUserAssignments(lastUserId)
    setUserAssignments(prevState => [...prevState, ...response.data])
    setHasMore(response.hasMore)
    setIsLoading(false)
  }, [fetchUserAssignments, lastUserId])

  useEffect(() => {
    if (isLoading !== true && hasMore && shouldLoadMore) void fetch()
  }, [fetch, hasMore, isLoading, shouldLoadMore])

  useEffect(() => setLastUserId(_.last(userAssignments)?.userInfo.baseUserInfo.userId), [userAssignments])

  const reload = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await fetchUserAssignments(undefined)
    setUserAssignments(response.data)
    setHasMore(response.hasMore)
    setIsLoading(false)
  }, [fetchUserAssignments])

  return {
    assignments: userAssignments,
    isLoading,
    reloadAssignments: reload,
    hasMore,
  }
}
