import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseVisibility } from 'sierra-client/api/graphql/gql/graphql'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import { date } from 'sierra-client/core/format'
import { SquareLogo } from 'sierra-client/features/multi-tenancy/components'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, Popover } from 'sierra-ui/components'
import { Pill, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'

function readableVisibility(visibility: CourseVisibility): TranslationKey {
  switch (visibility) {
    case 'PRIVATE': {
      throw Error('Distributed courses are not allowed to be private')
    }
    case 'VISIBLE': {
      return 'multi-tenancy.content-distribution.visibility.public'
    }
    case 'VISIBLE_FOR_ADMINS': {
      return 'multi-tenancy.content-distribution.visibility.visible-for-admins'
    }
    default:
      assertNever(visibility)
  }
}

const PopoverContent = styled.div`
  width: 380px;
  padding: 8px;

  header {
    padding: 8px;
  }
`
const PopoverOrgRow = styled.div`
  border-radius: 8px;
  overflow: hidden;

  & > a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    transition: all 150ms;

    &:hover,
    &:focus {
      background: ${token('surface/soft')};
    }
  }
`
const CountLabel = styled(Text).attrs({
  size: 'technical',
  color: 'foreground/secondary',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  height: 14px;
  background: ${token('form/border/1')};
  border-radius: 50%;
`

type DistributionPillProps = {
  courseId: CourseId | null
  publishStates: null | Array<{
    visibility: CourseVisibility
    lastPublishedAt?: string | null
    org: {
      name: string
      squareLogoUrl?: string | null
      domain: string
      isClusterParent: boolean
    }
  }>
}

export const DistributionPill: FC<DistributionPillProps> = ({ courseId, publishStates }) => {
  const { t } = useTranslation()
  const organizationCluster = useOrganizationCluster()
  const parent = organizationCluster.loading
    ? null
    : (organizationCluster.cluster.find(org => org.isClusterParent) ?? null)

  if (!isDefined(parent)) {
    return null
  }

  if (!isDefined(publishStates) || publishStates.length <= 1) {
    return (
      <Pill variant='ghost'>
        {isDefined(parent.squareLogoUrl) ? (
          <SquareLogo $size={12} src={parent.squareLogoUrl} />
        ) : (
          <OrganizationLogo orgName={parent.name} brandSettings={undefined} />
        )}

        <Text size='micro' bold>
          {parent.name}
        </Text>
      </Pill>
    )
  }

  return (
    <Popover
      openOnHover
      renderTrigger={() => {
        return (
          <Pill variant='ghost'>
            <Text size='micro' bold>
              Multiple orgs
            </Text>
            <CountLabel>{publishStates.length}</CountLabel>
          </Pill>
        )
      }}
    >
      <PopoverContent>
        <header>
          <Text color='foreground/secondary'>{t('multi-tenancy.content-distribution.publish-to')}</Text>
        </header>

        {publishStates.map(state => {
          const { org, visibility, lastPublishedAt } = state
          const courseUrl =
            courseId !== null
              ? new URL(`manage/courses/${courseId}`, `https://${org.domain}`).toString()
              : null

          return (
            <PopoverOrgRow key={org.name}>
              <ConditionalWrapper
                condition={courseUrl !== null}
                renderWrapper={children =>
                  courseUrl !== null && (
                    <a href={courseUrl} target='_blank' rel='noreferrer'>
                      {children}
                    </a>
                  )
                }
              >
                {isDefined(org.squareLogoUrl) ? (
                  <SquareLogo src={org.squareLogoUrl} />
                ) : (
                  <OrganizationLogo orgName={org.name} brandSettings={undefined} />
                )}

                <View direction='column' gap='none' grow>
                  <View>
                    <Text bold>{org.name}</Text>
                    {!org.isClusterParent && <Icon iconId='launch' />}
                  </View>

                  {isDefined(lastPublishedAt) && (
                    <Text color='foreground/muted'>
                      {t('manage.published-at', { date: date(lastPublishedAt) })}
                    </Text>
                  )}
                </View>

                <View>
                  <Text>{t(readableVisibility(visibility))}</Text>
                </View>
              </ConditionalWrapper>
            </PopoverOrgRow>
          )
        })}
      </PopoverContent>
    </Popover>
  )
}
