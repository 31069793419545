import { useEffect, useMemo, useState } from 'react'

const DEFAULT_PAGE_SIZE = 10

interface UsePaginationItem {
  onClick: () => void
  type: 'page' | 'next' | 'previous'
  page: number
  selected: boolean
  disabled?: boolean
}

export interface UsePaginationResult {
  items: UsePaginationItem[]
}

export type UseEnhancedPaginationProps<T> = {
  pageSize?: number
  items: T[]
  initialPage?: number
}

export type UseEnhancedPaginationResult<T> = {
  paginationItems: UsePaginationResult['items']
  itemsInPage: T[]
}

export const useEnhancedPagination = <T,>({
  items,
  pageSize = DEFAULT_PAGE_SIZE,
  initialPage = 1,
}: UseEnhancedPaginationProps<T>): UseEnhancedPaginationResult<T> => {
  const pageCount = Math.ceil(items.length / pageSize)

  const [currentPage, setCurrentPage] = useState(initialPage)

  useEffect(() => {
    if (currentPage > pageCount) {
      setCurrentPage(pageCount || 1)
    }
  }, [currentPage, pageCount])

  const paginationItems = useMemo(() => {
    const items: UsePaginationItem[] = []

    items.push({
      onClick: () => setCurrentPage(currentPage - 1),
      type: 'previous',
      page: currentPage - 1,
      selected: false,
      disabled: currentPage === 1,
    })

    for (let page = 1; page <= pageCount; page++) {
      items.push({
        onClick: () => setCurrentPage(page),
        type: 'page',
        page,
        selected: page === currentPage,
      })
    }

    items.push({
      onClick: () => setCurrentPage(currentPage + 1),
      type: 'next',
      page: currentPage + 1,
      selected: false,
      disabled: currentPage === pageCount,
    })

    return items
  }, [currentPage, pageCount])

  const itemsInPage = useMemo(() => {
    const start = pageSize * (currentPage - 1)
    const finish = start + pageSize
    return items.slice(start, finish)
  }, [currentPage, items, pageSize])

  return {
    paginationItems,
    itemsInPage,
  }
}
