import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { GetEventGroupWithCalendarEventzQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { getGraphQueryKey, graphQueryFn } from 'sierra-client/api/hooks/use-graphql-query'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useCachedQuery } from 'sierra-client/state/api'
import { OverviewGrid } from 'sierra-client/views/learner/components/overview/common'
import { OverviewHeader } from 'sierra-client/views/learner/components/overview/header'
import { EventGroupPageDetails } from 'sierra-client/views/learner/event-group/event-group-page-details'
import { EventGroupPageListItem } from 'sierra-client/views/learner/event-group/event-group-page-list-item'
import { EventGroupId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { XRealtimeContentGetUserContentIsRequiredAssignment } from 'sierra-domain/routes'
import { isNonNullable } from 'sierra-domain/utils'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { ThemeProvider } from 'styled-components'

const Description = styled(Text)`
  width: 100%;
  white-space: pre-wrap;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    width: 85%;
  }
`

const EventGroupContentWrapper = styled.div`
  margin-bottom: ${spacing['80']};
  margin-top: ${spacing['48']};
`

const eventGroupGraphQuery = graphql(`
  query getEventGroupWithCalendarEventz($id: CourseId!) {
    eventGroup(id: $id) {
      title
      description
      image {
        ...ImageFragment
      }
      __typename
      selfEnrollmentEnabled

      calendarEvents(input: { isSelfEnrollable: true, isUpcoming: true }) {
        id
        ...EventGroupPageDetails_events
        ...EventGroupPageListItem_item
      }

      assignedEvents: calendarEvents(input: { isAssigned: true, isUpcoming: true }) {
        id
        ...EventGroupPageDetails_events
        ...EventGroupPageListItem_item
      }

      calendarEventsWithApprovalRequests {
        ...EventGroupPageDetails_eventsWithApprovalRequests
      }
    }
  }
`)

export const getEventGroupQuery = (
  id: EventGroupId
): UseQueryOptions<GetEventGroupWithCalendarEventzQuery> => {
  return {
    queryKey: getGraphQueryKey(eventGroupGraphQuery, { id }),
    queryFn: () => graphQueryFn(eventGroupGraphQuery, { id }),
    staleTime: 5 * 1000, // 5 seconds
  }
}

export type EventGroupPageProps = {
  eventGroupId: EventGroupId
}

export const EventGroupPageContent = ({ eventGroupId }: EventGroupPageProps): JSX.Element => {
  const eventGroupQuery = getEventGroupQuery(eventGroupId)
  const query = useQuery(eventGroupQuery)
  const isRequiredAssignment =
    useCachedQuery(
      XRealtimeContentGetUserContentIsRequiredAssignment,
      { courseId: eventGroupId },
      {
        retry: false,
      }
    ).data?.isRequiredAssignment === true
  const eventGroup = query.data?.eventGroup

  const { t } = useTranslation()

  const image = eventGroup?.image !== undefined ? convertGQLImage(eventGroup.image) : undefined
  const assetContext: AssetContext = { type: 'course', courseId: eventGroupId }
  const resolvedImageUrl = useResolveAsset({ image, assetContext, size: 'default' })

  if (query.isPending || eventGroup === undefined || eventGroup === null) {
    return <></>
  }

  const events = eventGroup.assignedEvents.length > 0 ? eventGroup.assignedEvents : eventGroup.calendarEvents

  const listedEvents =
    eventGroup.calendarEvents.length > 0 ? eventGroup.calendarEvents : eventGroup.assignedEvents

  return (
    <>
      <OverviewHeader
        image={resolvedImageUrl}
        title={eventGroup.title}
        isRequiredAssignment={isRequiredAssignment}
      />
      <OverviewGrid>
        <EventGroupPageDetails
          events={events}
          eventsWithApprovalRequests={eventGroup.calendarEventsWithApprovalRequests}
          image={image}
          assetContext={assetContext}
          refetch={() => query.refetch()}
        />
        <EventGroupContentWrapper>
          {isNonNullable(eventGroup.description) && eventGroup.description.trim().length > 0 && (
            <>
              <Text color='foreground/muted' bold>
                {t('event-groups.about-the-event')}
              </Text>
              <Spacer size='12' />
              <Description size='regular'>
                <FreeTextEditor
                  inputId='event-group-description'
                  content={eventGroup.description}
                  useHtml
                  editable={false}
                />
              </Description>
              <Spacer size='32' />
            </>
          )}
          <Text color='foreground/muted' bold>
            {t('event-groups.upcoming-sessions')}
          </Text>
          <Spacer size='12' />
          <View direction='column' gap='24'>
            {listedEvents.length === 0 && t('events-groups.no-sessions')}
            {listedEvents.map(event => (
              <EventGroupPageListItem
                key={event.id}
                item={event}
                eventGroupTitle={eventGroup.title}
                image={image}
                assetContext={assetContext}
                itemChanged={() => query.refetch()}
                selfEnrollEnabled={eventGroup.selfEnrollmentEnabled}
              />
            ))}
          </View>
        </EventGroupContentWrapper>
      </OverviewGrid>
    </>
  )
}

export const EventGroupPage = ({ eventGroupId }: EventGroupPageProps): JSX.Element => {
  const learnerTheme = useLearnerTheme()

  return (
    <SanaPage
      mode='light'
      showIntercomLauncher={true}
      page={PageIdentifier.LearnerEventGroupPage({ eventGroupId })}
    >
      <ThemeProvider theme={learnerTheme}>
        <EventGroupPageContent eventGroupId={eventGroupId} />
      </ThemeProvider>
    </SanaPage>
  )
}
