import React from 'react'
import { useLastDefinedValue } from 'sierra-client/hooks/use-last-defined-value'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { TutorStateAvailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { Tooltip } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Absolute = styled.div`
  position: absolute;
  white-space: nowrap;
  top: 0;
`

function getDebugFileIds(fileIds: FileId[]): string {
  return `[debug] sources ${fileIds.join(', ')}`
}

export const AITutorSuggestQuestion: React.FC<{
  tutorState: TutorStateAvailable
  count: number
}> = ({ tutorState, count }) => {
  const { qaState, setTutorModalState } = tutorState

  const generatedQuestions = useLastDefinedValue(qaState.generateQuestionsMutation.data?.questions)
  const suggestedQuestions = generatedQuestions?.slice(0, count) ?? []

  if (suggestedQuestions.length === 0) {
    // eslint-disable-next-line react/jsx-no-literals
    return <Debug>{'[debug generate questions]: ' + qaState.generateQuestionsMutation.status}</Debug>
  }

  if (qaState.generateQuestionsMutation.isPending) {
    return <LoadingSpinner size='16' padding='none' />
  }

  return (
    <View wrap='wrap' gap='4'>
      {suggestedQuestions.map(({ question, fileIds }) => (
        <View position='relative' key={question} gap='4' direction='column'>
          <Button
            variant='ghost'
            onClick={() => {
              setTutorModalState({
                type: 'showing-qa',
                withInitialQuestion: question,
                knownSourceFileIds: fileIds,
              })
            }}
          >
            {question}
          </Button>
          <Debug>
            <Tooltip title={getDebugFileIds(fileIds)}>
              <Absolute>
                <Text size='technical'>{getDebugFileIds(fileIds)}</Text>
              </Absolute>
            </Tooltip>
          </Debug>
        </View>
      ))}
    </View>
  )
}
