import { useCallback, useContext, useState } from 'react'
import { ThemePicker } from 'sierra-client/components/common/inputs/theme-picker'
import { Link } from 'sierra-client/components/common/link'
import { DefaultRequiredModal } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { config, getFlag } from 'sierra-client/config/global-config'
import { REQUIRED_ASSIGNMENTS_HELP_PAGE } from 'sierra-client/config/links'
import { useLanguageItems } from 'sierra-client/hooks/use-language-items'
import { useOnce } from 'sierra-client/hooks/use-once'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { themeNames } from 'sierra-client/views/course-settings/components/preview-theme-picker'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import { Separator } from 'sierra-client/views/showcase/common'
import { CourseTheme } from 'sierra-domain/content/v2/content'
import { FormElement, MenuItem } from 'sierra-ui/components'
import { Button, InputPrimitive, Switch, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette, spacing, token } from 'sierra-ui/theming'
import { ThemeName, getTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

const ThemeContainer = styled.div<{ $themeName: ThemeName }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spacing['80']};
  padding: 0 1rem;
  background-color: ${p => p.theme.home.backgroundColor};
  border-radius: ${spacing['4']};
  border: 1px solid;
  border-color: ${p => (p.$themeName === 'white' ? palette.grey[5] : p.theme.home.backgroundColor)};
  transition:
    color 0.2s ease,
    background-color 0.2s ease,
    border-color 0.2s ease;
`

const DescriptionWrapper = styled(View)`
  max-width: calc(100% - 100px);
  white-space: pre-wrap;
`
const StyledLink = styled(Link)`
  color: ${token('foreground/link')};

  &:hover {
    color: ${token('foreground/link')};
  }
`

export const PathSettingsTab: SettingsTabComponent = ({
  pathId,
  handleFormChange,
  pathDataForm,
  switchToTranslationTab,
  translations,
  updateTranslation,
  canEditPathVisibility,
}) => {
  const { t } = useTranslation()
  const items = useLanguageItems()

  const orgConfig = config.organization
  const themeContext = useContext(ThemeContext)
  const safeThemeName: ThemeName =
    pathDataForm.settings.theme?.name ?? orgConfig.settings.brand.defaultTheme?.name ?? 'white'

  const pathTheme = getTheme(themeContext, safeThemeName)
  const updatedThemeNames: Record<CourseTheme['name'], string> = {
    ...themeNames,
    'custom-1': orgConfig.settings.brand.customThemes.customTheme1?.customThemeName ?? 'Custom 1',
    'custom-2': orgConfig.settings.brand.customThemes.customTheme2?.customThemeName ?? 'Custom 2',
    'custom-3': orgConfig.settings.brand.customThemes.customTheme3?.customThemeName ?? 'Custom 3',
    'custom-4': orgConfig.settings.brand.customThemes.customTheme4?.customThemeName ?? 'Custom 4',
    'custom-5': orgConfig.settings.brand.customThemes.customTheme5?.customThemeName ?? 'Custom 5',
    'custom-6': orgConfig.settings.brand.customThemes.customTheme6?.customThemeName ?? 'Custom 6',
    'custom-7': orgConfig.settings.brand.customThemes.customTheme7?.customThemeName ?? 'Custom 7',
    'custom-8': orgConfig.settings.brand.customThemes.customTheme8?.customThemeName ?? 'Custom 8',
    'custom-9': orgConfig.settings.brand.customThemes.customTheme9?.customThemeName ?? 'Custom 9',
    'custom-10': orgConfig.settings.brand.customThemes.customTheme10?.customThemeName ?? 'Custom 10',
    'custom-11': orgConfig.settings.brand.customThemes.customTheme11?.customThemeName ?? 'Custom 11',
    'custom-12': orgConfig.settings.brand.customThemes.customTheme12?.customThemeName ?? 'Custom 12',
    'custom-13': orgConfig.settings.brand.customThemes.customTheme13?.customThemeName ?? 'Custom 13',
    'custom-14': orgConfig.settings.brand.customThemes.customTheme14?.customThemeName ?? 'Custom 14',
    'custom-15': orgConfig.settings.brand.customThemes.customTheme15?.customThemeName ?? 'Custom 15',
    'custom-16': orgConfig.settings.brand.customThemes.customTheme16?.customThemeName ?? 'Custom 16',
    'custom-17': orgConfig.settings.brand.customThemes.customTheme17?.customThemeName ?? 'Custom 17',
    'custom-18': orgConfig.settings.brand.customThemes.customTheme18?.customThemeName ?? 'Custom 18',
    'custom-19': orgConfig.settings.brand.customThemes.customTheme19?.customThemeName ?? 'Custom 19',
    'custom-20': orgConfig.settings.brand.customThemes.customTheme20?.customThemeName ?? 'Custom 20',
    'custom-21': orgConfig.settings.brand.customThemes.customTheme21?.customThemeName ?? 'Custom 21',
    'custom-22': orgConfig.settings.brand.customThemes.customTheme22?.customThemeName ?? 'Custom 22',
    'custom-23': orgConfig.settings.brand.customThemes.customTheme23?.customThemeName ?? 'Custom 23',
    'custom-24': orgConfig.settings.brand.customThemes.customTheme24?.customThemeName ?? 'Custom 24',
    'custom-25': orgConfig.settings.brand.customThemes.customTheme25?.customThemeName ?? 'Custom 25',
    'custom-26': orgConfig.settings.brand.customThemes.customTheme26?.customThemeName ?? 'Custom 26',
    'custom-27': orgConfig.settings.brand.customThemes.customTheme27?.customThemeName ?? 'Custom 27',
    'custom-28': orgConfig.settings.brand.customThemes.customTheme28?.customThemeName ?? 'Custom 28',
  }

  const [showDefaultRequiredModal, setShowDefaultRequiredModal] = useState<
    | {
        open: boolean
        newDefaultRequiredValue: boolean
      }
    | undefined
  >(undefined)
  const { showOnce, setSeen } = useOnce(`default-required-content--${pathId}`)

  const [currentLanguageId, setCurrentLanguageId] = useState<string | undefined>(
    translations.find(it => it.isDefault)?.language
  )
  const onChangeLanguageSelection = useCallback(
    (language: MenuItem<string>) => {
      const defElemIndex = translations.findIndex(it => it.isDefault)
      const defElem = defElemIndex >= 0 ? translations[defElemIndex] : undefined
      if (defElem) {
        updateTranslation(defElemIndex, defElem.title, defElem.description, language.id, defElem.key)
      }
      setCurrentLanguageId(language.id)
    },
    [translations, updateTranslation]
  )

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFormChange('title', e.target.value)

      const defElemIndex = translations.findIndex(it => it.isDefault)
      const defElem = defElemIndex >= 0 ? translations[defElemIndex] : undefined
      if (defElem) {
        updateTranslation(defElemIndex, e.target.value, defElem.description, defElem.language, defElem.key)
      }
    },
    [translations, updateTranslation, handleFormChange]
  )

  const onChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      handleFormChange('description', e.target.value)

      const defElemIndex = translations.findIndex(it => it.isDefault)
      const defElem = defElemIndex >= 0 ? translations[defElemIndex] : undefined
      if (defElem) {
        updateTranslation(defElemIndex, defElem.title, e.target.value, defElem.language, defElem.key)
      }
    },
    [translations, updateTranslation, handleFormChange]
  )

  const handleThemeChange = (newTheme: ThemeName): void => {
    handleFormChange('settings', {
      ...pathDataForm.settings,
      theme: {
        type: 'preset',
        name: newTheme,
      },
    })
  }

  const selectedLanguageItem = items.find(item => item.id === currentLanguageId)

  return (
    <View direction='column' gap='24'>
      <FormElement label={t('dictionary.name')} htmlFor='title'>
        <InputPrimitive
          id='title'
          placeholder={t('admin.organization.paths.title-placeholder')}
          value={pathDataForm.title}
          onChange={onChangeTitle}
        />
      </FormElement>

      <FormElement label={t('dictionary.description')} htmlFor='description'>
        <TextAreaPrimitive
          id='description'
          placeholder={t('admin.organization.paths.enter-description')}
          value={pathDataForm.description ?? ''}
          onChange={onChangeDescription}
          rows={4}
        />
      </FormElement>

      {getFlag('required-assignments') && (
        <>
          <View direction='column' gap='4'>
            <View justifyContent='space-between'>
              <View gap='6'>
                <Text bold>{t('author.course-settings.required-path')}</Text>
              </View>
              <Switch
                checked={pathDataForm.settings.defaultRequiredAssignments}
                onChange={checked => {
                  if (showOnce) {
                    setShowDefaultRequiredModal({
                      open: true,
                      newDefaultRequiredValue: checked,
                    })
                  } else {
                    handleFormChange('settings', {
                      ...pathDataForm.settings,
                      defaultRequiredAssignments: checked,
                    })
                  }
                }}
                text=''
                size='small'
                textPos='left'
              />
            </View>
            <DescriptionWrapper>
              <Text size='micro' bold color='foreground/muted'>
                {t('assignment.high-priority-assignment.description')}{' '}
                <StyledLink target='_blank' href={REQUIRED_ASSIGNMENTS_HELP_PAGE} size='micro' bold>
                  {t('dictionary.learn-more')}
                </StyledLink>
              </Text>
            </DescriptionWrapper>
          </View>
          {showDefaultRequiredModal !== undefined && (
            <DefaultRequiredModal
              open={showOnce}
              onClose={() => {
                setSeen()
                setShowDefaultRequiredModal(undefined)
              }}
              changeRequired={() => {
                handleFormChange('settings', {
                  ...pathDataForm.settings,
                  defaultRequiredAssignments: showDefaultRequiredModal.newDefaultRequiredValue,
                })
              }}
            />
          )}
        </>
      )}

      <FormElement label={t('dictionary.language')} htmlFor='language'>
        <SingleSelectDropdown
          id='language'
          searchPlaceholder={t('dictionary.search')}
          withSearch
          selectedItem={selectedLanguageItem}
          onSelect={onChangeLanguageSelection}
          menuItems={items}
          maxHeight={300}
        />
      </FormElement>

      <Button variant='secondary' onClick={switchToTranslationTab}>
        {t('admin.settings.manage.translations')}
      </Button>

      <FormElement
        label={t('admin.settings.visibility')}
        helper={
          (pathDataForm.settings.isPathVisible ?? false)
            ? t('admin.settings.path-visibility-active-byline')
            : t('admin.settings.path-visibility-inactive-byline')
        }
      >
        <View alignItems='center'>
          <Switch
            disabled={!canEditPathVisibility}
            checked={pathDataForm.settings.isPathVisible ?? false}
            onChange={checked =>
              handleFormChange('settings', { ...pathDataForm.settings, isPathVisible: checked })
            }
            ariaLabel={t('admin.settings.visibility-label')}
          />

          <Text size='small' bold>
            {t('admin.settings.visibility-label')}
          </Text>
        </View>
      </FormElement>

      <Separator top='none' bottom='none' />

      <FormElement label={t('author.course-settings.course-settings')}>
        <View alignItems='center'>
          <Switch
            checked={pathDataForm.settings.sequentialLearning}
            onChange={checked =>
              handleFormChange('settings', { ...pathDataForm.settings, sequentialLearning: checked })
            }
            ariaLabel={t('admin.organization.paths.enable-sequential-learning')}
          />
          <Text size='small' bold>
            {t('admin.organization.paths.enable-sequential-learning')}
          </Text>
        </View>
      </FormElement>

      <Separator top='none' bottom='none' />

      <FormElement label={t('manage.paths.cover-theme')}>
        <ThemeProvider theme={pathTheme}>
          <ThemeContainer $themeName={safeThemeName}>
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
              {updatedThemeNames[safeThemeName]}
            </Text>
            <ThemePicker
              currentTheme={safeThemeName}
              onClick={theme => handleThemeChange(theme)}
              trigger={
                <Button variant={safeThemeName === 'white' ? 'secondary' : 'primary'}>
                  {t('manage.change-theme')}
                </Button>
              }
            />
          </ThemeContainer>
        </ThemeProvider>
      </FormElement>

      <FormElement label={t('manage.paths.cover-image')}>
        <ImageUpload
          value={pathDataForm.image}
          onChange={url => handleFormChange('image', url)}
          assetContext={{ type: 'path', pathId }}
        />
      </FormElement>
    </View>
  )
}
