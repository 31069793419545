import _, { isEqual } from 'lodash'
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  EventGroupPageDetails_EventsFragment,
  EventGroupPageDetails_EventsWithApprovalRequestsFragment,
} from 'sierra-client/api/graphql/gql/graphql'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  DetailsBottom,
  DetailsTop,
  OverviewDetails,
} from 'sierra-client/views/learner/components/overview/common'
import { EventLocationMap } from 'sierra-client/views/learner/event-group/event-location-map'
import { SeatRequestCount } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/common'
import { HandleEnrollmentRequests } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/handle-enrollment-requests'
import {
  formatEventSchedule,
  getEventLocationString,
  getGqlEventScheduleEnd,
  getGqlEventScheduleStart,
  gqlEventLocationToEventLocation,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { isNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.05).toString()};
  margin-bottom: ${spacing['24']};
`

const StyledOverviewDetails = styled(OverviewDetails)`
  border: 1px solid ${token('border/default')};
  overflow: hidden;
`

const LocationMap = styled(EventLocationMap)`
  width: 100%;
  height: 200px;
`

export const eventDetailsFragment = graphql(`
  fragment EventGroupPageDetails_events on CalendarEvent {
    id
    schedule {
      ...CalendarEventScheduleFragment
    }
    location {
      ...CalendarEventLocationFragment
    }
  }
`)

type EventGroupRequestsContainerProps = {
  $offsetHeight: number
  $detailsHeight: number
}
const EventGroupRequestsPositioningWrapper = styled.div.attrs<EventGroupRequestsContainerProps>(p => ({
  style: {
    '--offset-height': p.$offsetHeight ? `${p.$offsetHeight}px` : `7rem`,
    '--details-height': `${p.$detailsHeight}px`,
  },
}))<EventGroupRequestsContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: info;

  margin-top: calc(var(--details-height) - 40px);
  margin-right: 2px;

  top: calc(1rem + var(--offset-height) + var(--details-height) / 2);

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    margin-top: 140px;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    /* this is just temporary. wanted to understand how the details box position worked. */
    top: calc(1rem + var(--offset-height) + var(--details-height) / 2);
  }
`

export const EventGroupPageDetails: FC<{
  events: Array<EventGroupPageDetails_EventsFragment>
  eventsWithApprovalRequests: Array<EventGroupPageDetails_EventsWithApprovalRequestsFragment>
  image?: ImageUnion
  assetContext: AssetContext
  refetch: () => void
}> = ({ events, eventsWithApprovalRequests, image, refetch, assetContext }) => {
  const { t } = useTranslation()

  const detailRef = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [negativeMargin, setNegativeMargin] = useState(0)
  const [detailsHeight, setDetailsHeight] = useState(0)

  const { time, location, locationString } = useMemo(() => {
    const eventData = events
    const allSessionsHaveSameLocation =
      eventData.length === 1 || eventData.every((event, _, arr) => isEqual(arr[0]?.location, event.location))

    const allSessionsHaveSameTime =
      eventData.length === 1 ||
      eventData.every(
        (event, _, arr) =>
          getGqlEventScheduleStart(arr[0]!.schedule) === getGqlEventScheduleStart(event.schedule) &&
          getGqlEventScheduleEnd(arr[0]!.schedule) === getGqlEventScheduleEnd(event.schedule)
      )

    const firstEvent = eventData[0]
    const location = allSessionsHaveSameLocation
      ? gqlEventLocationToEventLocation(firstEvent?.location)
      : undefined

    const schedule =
      firstEvent !== undefined ? gqlEventScheduleToEventSchedule(firstEvent.schedule) : undefined
    const sameTimes = schedule === undefined ? t('events-groups.no-sessions') : formatEventSchedule(schedule)
    const time = allSessionsHaveSameTime ? sameTimes : t('events-groups.multiple-sessions')

    const locationString =
      !allSessionsHaveSameLocation && eventData.length > 1
        ? t('events.multiple-locations')
        : (getEventLocationString(location) ?? t('events.no-location'))

    return { time, location, locationString, eventData }
  }, [events, t])

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return

    const updateMarginAndOffset = (): void => {
      if (detailRef.current) {
        const height = detailRef.current.clientHeight / 2 + 112
        setOffsetHeight(height)
        setDetailsHeight(detailRef.current.clientHeight)
      }

      // Calculate to see if in between smallest and largest hero height
      const heightSpan = _.clamp(window.innerWidth * 0.47, 576, 736)
      setNegativeMargin(heightSpan / 2 + 32)
    }

    updateMarginAndOffset()

    window.addEventListener('resize', updateMarginAndOffset)

    return () => window.removeEventListener('resize', updateMarginAndOffset)
  }, [detailRef, setOffsetHeight, setNegativeMargin])

  const evsWithApprovalRequests = eventsWithApprovalRequests

  const allPendingRequests = evsWithApprovalRequests
    .flatMap(event => event.enrollmentRequestsToHandle)
    .filter(isNonNullable)
    .filter(request => request.requestStatus === 'PENDING')

  const resolvedImageUrl = useResolveAsset({ image, assetContext, size: 'default' })

  return (
    <>
      <StyledOverviewDetails
        ref={detailRef}
        $offsetHeight={offsetHeight}
        $negativeMargin={negativeMargin}
        $largeMobileMargin={true}
      >
        <DetailsTop>
          <Text size='regular' bold>
            {t('dictionary.details')}
          </Text>
          <Divider />
        </DetailsTop>
        <DetailsBottom>
          <IconListItem faded={false} bold color='foreground/muted' iconId='calendar' text={time} />
          <Spacer size='4' />
          <IconListItem faded={false} color='foreground/muted' iconId='location' text={locationString} />
          <Spacer size='16' />
        </DetailsBottom>
        {location?.type === 'google-place' && <LocationMap location={location} />}
      </StyledOverviewDetails>
      {evsWithApprovalRequests.length > 0 && (
        <EventGroupRequestsPositioningWrapper $offsetHeight={offsetHeight} $detailsHeight={detailsHeight}>
          <View direction='row' justifyContent='flex-start' alignItems='center' gap='6'>
            <Text bold color='foreground/muted'>
              {t('dictionary.requests')}
            </Text>
            {allPendingRequests.length > 0 && <SeatRequestCount count={allPendingRequests.length} />}
          </View>
          <HandleEnrollmentRequests
            eventsWithApprovalRequests={eventsWithApprovalRequests}
            refetch={refetch}
            imageUrl={resolvedImageUrl}
          />
        </EventGroupRequestsPositioningWrapper>
      )}
    </>
  )
}
