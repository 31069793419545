import { ReactNode } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AllowedModalSizes, CloseModalButton, Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type OnCloseReason =
  | 'backdropClick'
  | 'escapeKeyDown'
  | 'primaryAction'
  | 'secondaryAction'
  | 'closeButtonClicked'

export type ActionModalProps = {
  'title'?: string
  'open': boolean
  'data-testid'?: string
  'children'?: ReactNode
  'onClose': (reason?: OnCloseReason) => void | Promise<void>
  'primaryAction'?: () => void | Promise<void>
  'secondaryAction'?: () => void | Promise<void>
  'primaryActionLabel'?: string
  'secondaryActionLabel'?: string
  'deleteAction'?: boolean
  'duplicateAction'?: boolean
  'hideCloseIcon'?: boolean
  'isLoading'?: boolean
  'size'?: AllowedModalSizes
}

const Actions = styled(View).attrs({ gap: '8' })`
  align-self: flex-end;
`

const Content = styled(View).attrs({ direction: 'column', justifyContent: 'flex-start', grow: true })``

export const ActionModal = ({
  children,
  open,
  onClose,
  title,
  primaryAction,
  secondaryAction,
  primaryActionLabel,
  secondaryActionLabel,
  deleteAction = false,
  isLoading = false,
  size = 'fit-content',
  ...props
}: ActionModalProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal
      padding='24'
      margin='8'
      size={size}
      data-testid={props['data-testid'] ?? 'ConfirmModal'}
      open={open}
      onClose={onClose}
    >
      {props.hideCloseIcon !== true && (
        <CloseModalButton
          ariaLabel={t('dictionary.close')}
          onClick={() => {
            void onClose('closeButtonClicked')
          }}
        />
      )}
      <ModalHeader alignItems='flex-start'>
        <Text size='large' bold>
          {title !== undefined ? title : t('admin.confirm-modal-headline')}
        </Text>
      </ModalHeader>
      <Content>
        <Text size='regular' color='foreground/muted'>
          {children}
        </Text>
      </Content>
      <Spacer size='32' />
      <Actions>
        <Button
          variant='secondary'
          onClick={async () => {
            if (secondaryAction) await secondaryAction()
            await onClose('secondaryAction')
          }}
        >
          {secondaryActionLabel ?? t('admin.organization.confirm-cancel')}
        </Button>
        {primaryAction !== undefined && (
          <Button
            variant={deleteAction ? 'destructive' : 'primary'}
            disabled={isLoading}
            loading={isLoading}
            onClick={async () => {
              await primaryAction()
              await onClose('primaryAction')
            }}
          >
            {primaryActionLabel ?? t('dictionary.confirm')}
          </Button>
        )}
      </Actions>
    </Modal>
  )
}
