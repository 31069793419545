import { default as React } from 'react'
import { LanguageCode, defaultLanguage, languageItems } from 'sierra-client/components/code/types'
import { getFlag } from 'sierra-client/config/global-config'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { color } from 'sierra-ui/color'
import { MenuItem } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { DarkTokenProvider } from 'sierra-ui/theming'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const Language = styled.div.attrs({ contentEditable: false })`
  width: 100%;
  color: ${color('white').opacity(0.6).toString()};
  user-select: none;
  z-index: 1000;
`

const TrashCan = styled(IconButton).attrs({ variant: 'ghost', contentEditable: false, iconId: 'trash-can' })`
  margin-left: auto;
  margin-right: 1.5rem;
`

const CopyButton = styled(IconButton).attrs({
  variant: 'ghost',
  contentEditable: false,
})`
  margin-left: auto;
  margin-right: 1.5rem;
`

const CopyCodeToClipboard: React.FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation()
  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(text)
    setRecentlyClicked()
  }

  return (
    <CopyButton
      iconId={recentlyClicked ? 'checkmark' : 'copy--file'}
      tooltip={t('admin.copy')}
      onClick={handleClick}
    />
  )
}

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const CodeInner = styled(View)`
  position: relative;
  flex-direction: column;
  background: ${p => p.theme.color.grey80};
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  & pre {
    position: relative;
  }
  padding: 1.5rem 0 1rem 1.5rem;

  & > pre > div {
    color: ${color('white').opacity(0.9).toString()};
    caret-color: white;
    font-family: 'JetBrainsMono', 'Monaco', 'Menlo', 'Fira code', 'Fira Mono', monospace;
  }

  & span[data-slate-placeholder='true'] {
    white-space: nowrap;
  }
`

export const CodeWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, ...props }, ref) => {
    return (
      <BlockWrapper {...props} ref={ref}>
        {children}
      </BlockWrapper>
    )
  }
)

const CodeDiv = styled.div`
  overflow: auto;
  padding-bottom: 8px;
  padding-right: 24px;
`

const Pre = styled.pre`
  padding-left: 24px;
`

export const Code: SlateFC = ({ children, element, readOnly, ...props }) => {
  assertElementType('code', element)

  const { t } = useTranslation()
  const editor = useSlateStatic()
  const language = element.language ?? defaultLanguage

  const codeString = element.children.map(child => child.text).join('')

  const items: MenuItem<LanguageCode>[] = Object.entries(languageItems).map(([id, label]) => ({
    id: id as LanguageCode,
    label,
    type: 'label',
  }))

  const selectedItem = items.find(item => item.id === language)

  return (
    <DarkTokenProvider>
      <CodeInner direction='column' gap='xsmall' grow {...props}>
        <LanguageContainer contentEditable={false}>
          {readOnly ? (
            <>
              <Language>{languageItems[language]}</Language>
              <CopyCodeToClipboard text={codeString} />
            </>
          ) : (
            <>
              <SingleSelectDropdown
                grow={false}
                selectedItem={selectedItem}
                menuItems={items}
                onSelect={item => {
                  const language = item.id
                  updateNodeWithId(editor, element.id, { language })
                }}
              />
              <TrashCan
                tooltip={t('author.block-editor.remove')}
                onClick={() => removeNodeWithId(editor, element.id)}
              />
            </>
          )}
        </LanguageContainer>
        <Pre>
          <CodeDiv>{children}</CodeDiv>
        </Pre>
        <BlockCommentIndicator element={element} radius='xsmall' />
      </CodeInner>
    </DarkTokenProvider>
  )
}
