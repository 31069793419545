import React, { useEffect, useState } from 'react'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EditorInner, LayoutType } from 'sierra-client/views/flexible-content/editor-inner'
import { createSanaEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import {
  EditorContextProvider,
  EditorContextProviderProps,
} from 'sierra-client/views/v3-author/editor-context/editor-context-provider'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { renderLeaf } from 'sierra-client/views/v3-author/render-leaf'
import * as Renderer from 'sierra-client/views/v3-author/renderer'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { AssetContext } from 'sierra-domain/asset-context'
import { SlateFile } from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { patchEditor } from 'sierra-domain/patch-editor'
import { SanaEditor, SlateDocument } from 'sierra-domain/v3-author'

const supportedSlashMenuEntryIds = [] as const

export function useStaticEditor(document: SlateDocument, outsideEditor?: SanaEditor): SanaEditor {
  const pasteFile = (): void => {}
  const copyPasteAssetOptions = { type: 'disabled' as const }
  const [editor] = useState(() => outsideEditor ?? createSanaEditor({ pasteFile, copyPasteAssetOptions }))

  // Update the editor's internal state when the `document` changes
  useEffect(() => {
    patchEditor(editor, document)
  }, [document, editor])

  return editor
}

export const StaticEditor: React.FC<{
  tutorState: TutorState
  document: SlateDocument
  mode: EditorMode
  layout?: LayoutType
  editor?: SanaEditor
  readOnly?: boolean
  rulesOfBlocksViolations?: EditorContextProviderProps['rulesOfBlocksViolations']
  assetContext: AssetContext
  file: SlateFile | undefined
}> = ({
  tutorState,
  document,
  mode,
  readOnly = true,
  layout,
  rulesOfBlocksViolations,
  editor: outsideEditor,
  assetContext,
  file,
}) => {
  const [editorId] = useState(() => nanoid12())
  const editor = useStaticEditor(document, outsideEditor)
  const renderElement = Renderer.useRenderElement({ file })

  const cursorsEnabled = false

  return (
    <FocusEditorContext>
      <EditorContextProvider
        initialValue={document}
        editor={editor}
        editorId={editorId}
        readOnly={readOnly}
        mode={mode}
        supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
        chatId={undefined}
        enableCommenting={false}
        rulesOfBlocksViolations={rulesOfBlocksViolations}
        assetContext={assetContext}
      >
        <EditorInner
          tutorState={tutorState}
          layout={layout ?? 'default'}
          cursorsEnabled={cursorsEnabled}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        >
          <span />
        </EditorInner>
      </EditorContextProvider>
    </FocusEditorContext>
  )
}
