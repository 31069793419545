import React, { useState } from 'react'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  canvasColumn,
  dateTimesColumn,
  numbersColumn,
  stringsColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import {
  RenderCountsPrimitive,
  ToolbarContainer,
  ToolbarLeftContainer,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { ExportCSVButton } from 'sierra-client/views/manage/components/export-csv'
import { mapUserGroupToCsv } from 'sierra-client/views/manage/user-groups/use-user-groups'
import { UserGroupRow, UserGroupType } from 'sierra-domain/api/manage'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, RoundAvatar } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type UserGroup = UserGroupRow

type UserGroupTableDefinition = TableDefinitionOf<
  UserGroup,
  [
    { type: 'canvas'; ref: 'groupName' },
    { type: 'numbers'; ref: 'membersCount' },
    { type: 'strings'; ref: 'type' },
    { type: 'dateTimes'; ref: 'createdAt' },
  ]
>

type UserGroupTableData = TableDataFromDefinition<UserGroup, UserGroupTableDefinition>
type UserGroupTableMeta = StaticDataLoaderMeta<UserGroupTableData>

const StyledRoundAvatar = styled(RoundAvatar)<{ hide: boolean }>`
  pointer-events: none;
  opacity: ${p => (p.hide ? 0 : 1)};
  transition: opacity 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
`

const searchKey: StaticLoaderSearchKeyBy<UserGroupTableData> = (tableData, row) =>
  tableData.rows[row]?.rawData.name ?? ''

const tableDefinition = (t: TranslationLookup): UserGroupTableDefinition => {
  return {
    columns: [
      canvasColumn({
        getData: r => {
          const colorByType: Record<UserGroupType, ColorName> = {
            'user-scim': 'orangeBright',
            'user-api': 'orangeBright',
            'user-manual': 'greenBright',
            'user-filter': 'purpleBright',
          }
          return {
            meta: {
              exports: () => r.name ?? '',
              sorts: () => r.name ?? '',
            },
            view: (
              <View>
                <StyledRoundAvatar
                  size='medium'
                  firstName={r.name}
                  hide={false}
                  color={colorByType[r.type]}
                />
                <Text
                  size='small'
                  bold
                  color={Boolean(r.invalidFilter) ? 'warning/background' : 'foreground/primary'}
                >
                  {r.type === 'user-filter' && (
                    <>
                      {Boolean(r.invalidFilter) ? (
                        <Icon iconId={'warning--filled'} color={'warning/background'} />
                      ) : (
                        <Icon iconId={'smart'} />
                      )}
                      &nbsp;&nbsp;
                    </>
                  )}
                  {Boolean(r.name) ? r.name : 'N/A'}
                </Text>
              </View>
            ),
          }
        },
        header: translatedLabel('table.name'),
        ref: 'groupName',
        sortable: true,
      }),
      numbersColumn({
        getData: r => r.numLearners,
        header: translatedLabel('table.members'),
        ref: 'membersCount',
        sortable: true,
      }),
      stringsColumn({
        getData: r => {
          const translationKeyByGroupTyoe: Record<UserGroupType, TranslationKey> = {
            'user-api': 'user-group-type-external',
            'user-manual': 'user-group-type-static',
            'user-scim': 'user-group-type-external',
            'user-filter': 'user-group-type-smart',
          }
          return t(translationKeyByGroupTyoe[r.type])
        },
        header: translatedLabel('table.type'),
        ref: 'type',
        sortable: true,
      }),
      dateTimesColumn({
        getData: r => (r.createdAt !== undefined ? { date: new Date(r.createdAt) } : undefined),
        header: translatedLabel('table.createdAt'),
        ref: 'createdAt',
        sortable: true,
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.id,
    },
  }
}

const useUserGroupTable = ({
  data,
}: {
  data: UserGroupRow[]
}): UseTableAPI<UserGroupTableData, UserGroupTableMeta> => {
  const { t } = useTranslation()
  const loader = staticDataLoader(definition2Data(tableDefinition(t), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [],
      right: [],
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.SOLO,
    },
  })
}

const SearchSection: React.FC<Pick<UserGroupsTableProps, 'onCreateNew'>> = ({ onCreateNew }) => {
  const { t } = useTranslation()

  const { api } = useTabularContext()

  const orgPermissions = useOrganizationPermissions()
  const canCreateGroup = orgPermissions.has('CREATE_GROUP')

  const [initialQuery] = useState(api.query.query().query)

  return (
    <View justifyContent='space-between'>
      <AnimatedSearch
        onChange={query => api.action.setQuery({ query })}
        placeholder={t('manage.search.groups')}
        defaultValue={initialQuery}
        initialOpen={Boolean(initialQuery)}
      />
      {canCreateGroup && (
        <Button variant='secondary' onClick={onCreateNew}>
          {t('admin.create-new')}
        </Button>
      )}
    </View>
  )
}

const Toolbar: React.FC<Pick<UserGroupsTableProps, 'totalCount' | 'userGroups'>> = ({
  totalCount,
  userGroups,
}) => {
  const { t } = useTranslation()
  return (
    <ToolbarContainer>
      <ToolbarLeftContainer>
        <RenderCountsPrimitive>{t('manage.n-groups', { count: totalCount })}</RenderCountsPrimitive>
      </ToolbarLeftContainer>
      <ExportCSVButton
        color='secondary'
        fetchCsvData={() => userGroups.map(mapUserGroupToCsv)}
        filename='admin.analytics.group'
      />
    </ToolbarContainer>
  )
}

export type UserGroupsTableProps = {
  userGroups: UserGroupRow[]
  totalCount: number
  onCreateNew: () => void
}

export const UserGroupsTable: React.FC<UserGroupsTableProps> = ({ userGroups, totalCount, onCreateNew }) => {
  const tableAPI = useUserGroupTable({ data: userGroups })
  const { t } = useTranslation()

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/user-groups/${rawData.id}` })
        },
      }}
    >
      <SearchSection onCreateNew={onCreateNew} />
      <Spacer size='xsmall' />
      <Toolbar totalCount={totalCount} userGroups={userGroups} />
      <Spacer size='xsmall' />
      {userGroups.length === 0 ? (
        <InfiniteScrollMessage text={t('manage.groups.no-results')} />
      ) : (
        <BasicTabular />
      )}
    </TabularProviderFromTableAPI>
  )
}
