import { useAtom, useAtomValue } from 'jotai'
import React, { Dispatch, SetStateAction } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { dateTimesColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { TabularQsKey, UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { UserDetailResponse, UserGroupMembership } from 'sierra-domain/api/manage'
import { Spacer, View } from 'sierra-ui/primitives'

type UserGroup = UserGroupMembership

type UserGroupTableDefinition = TableDefinitionOf<
  UserGroup,
  [{ type: 'strings'; ref: 'groupName' }, { type: 'dateTimes'; ref: 'assignedAt' }]
>

type UserGroupTableData = TableDataFromDefinition<UserGroup, UserGroupTableDefinition>
type UserGroupTableMeta = StaticDataLoaderMeta<UserGroupTableData>

const searchKey: StaticLoaderSearchKeyBy<UserGroupTableData> = (tableData, row) =>
  tableData.rows[row]?.data.groupName.data ?? ''

const tableDefinition = (): UserGroupTableDefinition => {
  return {
    columns: [
      stringsColumn({
        sortable: true,
        getData: r => r.groupName,
        header: translatedLabel('table.name'),
        ref: 'groupName',
        hints: ['bold'],
      }),
      dateTimesColumn({
        sortable: true,
        header: translatedLabel('admin.groups.assigned-at'),
        ref: 'assignedAt',
        getData: r => ({ date: new Date(r.assignedAt) }),
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.groupId,
    },
  }
}

const useUserGroupTable = ({
  data,
}: {
  data: UserGroup[]
}): UseTableAPI<UserGroupTableData, UserGroupTableMeta> => {
  const { t } = useTranslation()
  const loader = staticDataLoader(definition2Data(tableDefinition(), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  label: t('manage.view-course-details'),
                  onClick: () =>
                    getGlobalRouter().navigate({ to: `/manage/user-groups/${row.rawData.groupId}` }),
                  icon: 'user--group',
                },
              ],
            }
          },
        }),
      ],
    },
    options: {
      queryStateKeyPrefix: TabularQsKey.GROUP,
    },
  })
}

type SearchSectionProps = {
  tableAPI: UseTableAPI<UserGroupTableData, UserGroupTableMeta>
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

const SearchSection: React.FC<SearchSectionProps> = ({ tableAPI }) => {
  const { t } = useTranslation()
  const query = useAtomValue(tableAPI.api.atoms.query)

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('manage.search.groups')}
      />
    </View>
  )
}

const userGroupToCsv = (group: UserGroupMembership): Record<string, string> => ({
  groupId: group.groupId,
  groupName: group.groupName ?? '',
  assignedAt: new Date(group.assignedAt).toISOString(),
})

type ToolbarProps = {
  tableAPI: UseTableAPI<UserGroupTableData, UserGroupTableMeta>
  mapCsv: (ids: string[]) => Record<string, string>[]
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, mapCsv }) => {
  const { api } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.n-groups',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.groups'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.groups')} />
    </View>
  )
}
export type UserGroupsTableProps = {
  userGroupMemberships: UserDetailResponse['userGroupMemberships']
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

export const UserGroupsTable: React.FC<UserGroupsTableProps> = ({ userGroupMemberships, setUserAction }) => {
  const tableAPI = useUserGroupTable({ data: userGroupMemberships })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/user-groups/${rawData.groupId}` })
        },
      }}
    >
      <Spacer size='xsmall' />
      <SearchSection setUserAction={setUserAction} tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        mapCsv={(ids: string[]) =>
          userGroupMemberships.filter(row => ids.includes(row.groupId)).map(userGroupToCsv)
        }
      />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(userGroupMemberships.map(userGroupToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
