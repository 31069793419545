import {
  XRealtimeAdminFeatureContentForOrganization,
  XRealtimeAdminRemoveFeaturedContentForOrganization,
} from 'sierra-domain/routes'

import React, { useEffect, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { RecommendationCard } from 'sierra-client/components/recommendations/recommendation-card'
import { useContentKindPermissions, usePathPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useFeaturedContent } from 'sierra-client/views/manage/content/hooks/use-featured-content'
import { bareContentId } from 'sierra-client/views/manage/content/utils/content-utils'
import { FeaturedOrganizationContent } from 'sierra-domain/api/admin'
import { CourseKind } from 'sierra-domain/api/common'
import { FeaturedCard } from 'sierra-domain/api/infocard'
import { CourseId, LiveContentId, NanoId12, PathId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { iife, isDefined } from 'sierra-domain/utils'
import { FormElement, Input, Modal, Tooltip } from 'sierra-ui/components'
import { Button, Heading, Spacer, Switch, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type FeaturedContentDialogItem = {
  id: string
  contentType: 'course' | 'path' | 'homework' | 'program'
  courseKind?: CourseKind
  title: string
  isPublicVisibility: boolean
  image?: ImageUnion
  duration?: number
}

type FeaturedContentDialogProps = {
  isOpen: boolean
  onClose: () => void
  content: FeaturedContentDialogItem
}

const LeftContainer = styled(View).attrs({ grow: true })`
  max-width: 440px;
`

const PreviewContainer = styled(View).attrs({ background: 'grey2', padding: 'xlarge', grow: true })`
  height: 100%;
  pointer-events: none;
`

const RecommendationCardWrapper = styled(View)`
  width: 420px;
  height: 520px;
`

const generateRecommendation = (
  featuredContent: Pick<FeaturedOrganizationContent, 'description' | 'buttonText' | 'showMetadata' | 'title'>,
  content: FeaturedContentDialogItem
): Recommendation => {
  const { description, buttonText, showMetadata, title } = featuredContent
  const bareId = bareContentId(content.id)

  const entityBase = {
    entityType: content.courseKind ?? 'path',
    title: title === '' ? content.title : title,
    image: content.image,
    timeEstimate: content.duration ?? 0,
    learnerContext: {
      progress: 0,
      isFavorite: false,
      exercises: [],
    },
    tags: [],
    feature: {
      title: title === '' ? content.title : title,
      description: description !== '' ? description : undefined,
      buttonText: buttonText !== '' ? buttonText : undefined,
      showMetadata,
    },
  } satisfies Omit<FeaturedCard['entity'], 'id'>

  const entity = iife((): FeaturedCard['entity'] => {
    if (entityBase.entityType === 'path') {
      return {
        ...entityBase,
        entityType: 'path',
        id: PathId.parse(bareId),
        content: [],
      }
    } else if (entityBase.entityType === 'native:self-paced') {
      return {
        ...entityBase,
        entityType: 'native:self-paced',
        id: SelfPacedContentId.parse(bareId),
      }
    } else if (entityBase.entityType === 'native:live') {
      return {
        ...entityBase,
        entityType: 'native:live',
        id: LiveContentId.parse(bareId),
        liveSessions: [],
      }
    } else {
      return {
        ...entityBase,
        entityType: entityBase.entityType,
        id: CourseId.parse(bareId),
      }
    }
  })

  return {
    card: {
      type: 'featured',
      entity,
    },
  }
}

const RemoveFeaturedContentButton: React.FC<{
  contentId: NanoId12
  handleRemove: () => Promise<void>
}> = ({ contentId, handleRemove }) => {
  const { t } = useTranslation()
  const permissions = useContentKindPermissions(NanoId12.parse(bareContentId(contentId)))
  const removeFeaturedContentPermission = permissions.has('REMOVE_FROM_FEATURED_CONTENT')

  if (!removeFeaturedContentPermission) return null

  return (
    <Button variant='secondary' icon='trash-can' onClick={handleRemove}>
      {t('dictionary.remove')}
    </Button>
  )
}

const RemoveFeaturedPathButton: React.FC<{
  pathId: PathId
  handleRemove: () => Promise<void>
}> = ({ pathId, handleRemove }) => {
  const { t } = useTranslation()
  const permissions = usePathPermissions(pathId)
  const removeFeaturedContentPermission = permissions.has('REMOVE_FROM_FEATURED_CONTENT')

  if (!removeFeaturedContentPermission) return null

  return (
    <Button variant='secondary' icon='trash-can' onClick={handleRemove}>
      {t('dictionary.remove')}
    </Button>
  )
}

export const FeaturedContentDialog: React.FC<FeaturedContentDialogProps> = ({ isOpen, onClose, content }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [buttonText, setButtonText] = useState('Start')
  const [hideWhenCompleted, sethideWhenCompleted] = useState(true)
  const [showMetadata, setshowMetadata] = useState(true)

  const { postWithUserErrorException } = usePost()

  const { content: featuredContent, refetch: refetchFeaturedContent } = useFeaturedContent()
  const featuredContentItem = featuredContent?.find(featured => featured.id === bareContentId(content.id))
  const confirmationModalContext = useConfirmationModalContext()
  const alreadyFeaturedCourse = isDefined(featuredContentItem) && featuredContentItem.type === 'course'
  const alreadyFeaturedPath = isDefined(featuredContentItem) && featuredContentItem.type === 'path'

  // We disable the feature option if there are already 4 featured items and the current item is not already featured
  // This is because we only allow 4 featured items at the time on the home page
  const disableFeatureOption =
    isDefined(featuredContent) &&
    featuredContent.length >= 4 &&
    !(alreadyFeaturedCourse || alreadyFeaturedPath)

  useEffect(() => {
    if (featuredContentItem === undefined) return
    setTitle(featuredContentItem.title)
    setDescription(featuredContentItem.description ?? '')
    setButtonText(featuredContentItem.buttonText)
    sethideWhenCompleted(featuredContentItem.hideWhenCompleted)
    setshowMetadata(featuredContentItem.showMetadata)
  }, [featuredContentItem])

  const onSubmit = async (): Promise<void> => {
    if (content.contentType !== 'program') {
      await postWithUserErrorException(XRealtimeAdminFeatureContentForOrganization, {
        content: {
          id: NanoId12.parse(bareContentId(content.id)),
          type: content.contentType,
          title: title === '' ? content.title : title,
          description: description !== '' ? description : undefined,
          buttonText,
          hideWhenCompleted,
          showMetadata,
        },
      })
      refetchFeaturedContent()
      onClose()
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (!content.isPublicVisibility) {
      confirmationModalContext.show({
        bodyText: t('manage.feature-content-dialog.confirm-public-availability'),
        onConfirm: onSubmit,
      })
    } else {
      await onSubmit()
    }
  }

  const handleRemove = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeAdminRemoveFeaturedContentForOrganization, {
      contentId: NanoId12.parse(bareContentId(content.id)),
    })
    refetchFeaturedContent()
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onClose} size='fit-content'>
      <View gap='none'>
        <LeftContainer direction='column' padding='xlarge'>
          <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold>
            {t('manage.feature-content-dialog.title')}
          </Heading>

          <Text color='grey40' size='small'>
            {content.courseKind === 'native:live'
              ? t('manage.feature-content-dialog.live-session-description')
              : t('manage.feature-content-dialog.description')}
          </Text>
          <View grow direction='column'>
            <Input
              gap='none'
              spacing={'xsmall'}
              required
              placeholder={content.title}
              id='feature-content-title'
              label={t('dictionary.title')}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <FormElement label={t('dictionary.description')} grow htmlFor='feature-content-description'>
              <TextAreaPrimitive
                id='feature-content-description'
                placeholder={t('manage.feature-content-dialog.description-placeholder')}
                rows={3}
                autoExpand
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </FormElement>

            <Spacer size='8' />

            <Input
              gap='none'
              spacing={'xsmall'}
              id='feature-content-button-text'
              label={t('manage.feature-content-dialog.button-text-label')}
              value={buttonText}
              onChange={e => setButtonText(e.target.value)}
            />

            <View direction='column' gap={'xsmall'}>
              <Switch
                text={t('manage.feature-content-dialog.hide-when-completed')}
                checked={hideWhenCompleted}
                onChange={checked => sethideWhenCompleted(checked)}
              />
              <Switch
                text={t('manage.feature-content-dialog.show-metadata')}
                checked={showMetadata}
                onChange={checked => setshowMetadata(checked)}
              />
            </View>
          </View>
          <View alignItems='center' marginTop={'xxlarge'}>
            {alreadyFeaturedCourse ? (
              <RemoveFeaturedContentButton
                contentId={NanoId12.parse(bareContentId(content.id))}
                handleRemove={handleRemove}
              />
            ) : alreadyFeaturedPath ? (
              <RemoveFeaturedPathButton
                pathId={PathId.parse(bareContentId(content.id))}
                handleRemove={handleRemove}
              />
            ) : null}
            <View justifyContent='flex-end' grow>
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Tooltip title={disableFeatureOption ? t('admin.featured-dialog.max-featured') : ''}>
                <Button variant='primary' onClick={handleSubmit} disabled={disableFeatureOption}>
                  {t('manage.feature-content-dialog.submit-action-label')}
                </Button>
              </Tooltip>
            </View>
          </View>
        </LeftContainer>
        <PreviewContainer>
          <RecommendationCardWrapper>
            <RecommendationCard
              recommendation={generateRecommendation(
                { title, description, buttonText, showMetadata },
                content
              )}
            />
          </RecommendationCardWrapper>
        </PreviewContainer>
      </View>
    </Modal>
  )
}
