import { TabRootContainer } from 'sierra-client/components/sharing/tabs/components/containers'
import { TabTop } from 'sierra-client/components/sharing/tabs/components/tab-top'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ExportTab as SettingsExportTab } from 'sierra-client/views/course-settings/tabs'

type ExportTabProps = {
  content: SharingModalContent
  onClose: () => void
}

export const ExportTab: React.FC<ExportTabProps> = ({ content, onClose }) => {
  const { t } = useTranslation()

  return (
    <TabRootContainer>
      <TabTop title={t('author.export-title', { title: content.title })} onClose={onClose} />
      <div>
        <SettingsExportTab courseId={content.id} onSave={() => {}} onClose={() => {}} />
      </div>
    </TabRootContainer>
  )
}
