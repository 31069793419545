import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { runEditorActions, RunEditorActionsResult } from 'sierra-client/editor/actions/run-editor-actions'
import { usePropMonitor } from 'sierra-client/hooks/use-prop-monitor'
import { newGeneralData } from 'sierra-client/state/flexible-content/factory'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EditorInner } from 'sierra-client/views/flexible-content/editor-inner'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { EditorContextProvider } from 'sierra-client/views/v3-author/editor-context/editor-context-provider'
import { tutorStateUnavailable } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { renderLeaf } from 'sierra-client/views/v3-author/render-leaf'
import * as Renderer from 'sierra-client/views/v3-author/renderer'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { useOnKeyDown } from 'sierra-client/views/v3-author/use-on-key-down'
import { NanoId12, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { createSlateFile, File } from 'sierra-domain/flexible-content/types'
import { EditorAction } from 'sierra-domain/v3-author/arbitraries'
import { allSlashMenuEntryIds } from 'sierra-domain/v3-author/slash-menu'
import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function log(...messages: unknown[]): void {
  // eslint-disable-next-line no-console
  console.log('[experimental-editor]', ...messages)
}

const supportedSlashMenuEntryIds = allSlashMenuEntryIds

const file: File = {
  id: 'file:UqRwuPL9sVDg',
  type: 'file',
  title: 'experimental file',
  data: newGeneralData(),
}
const scopedCreateContentId = ScopedSelfPacedContentId.fromId(SelfPacedContentId.parse('D-_XRWj2GsXh'))

const Border = styled.div`
  border: 1px solid blue;
  width: 100%;
`

const defaultFile = createSlateFile({ data: { type: 'general' } })

const ExperimentalEditorInner: React.FC<{
  abortSignal: AbortSignal | undefined
  actions: EditorAction[]
  onFinishedRunningActions: (result: RunEditorActionsResult) => void
}> = ({ actions, abortSignal, onFinishedRunningActions }) => {
  const cursorsEnabled = false
  const editor = useSlateStatic()
  const onKeyDown = useOnKeyDown()

  const renderElement = Renderer.useRenderElement({ file: defaultFile })

  usePropMonitor({ actions, abortSignal, onFinishedRunningActions, editor })

  useEffect(() => {
    void runEditorActions(editor, actions, onKeyDown, abortSignal).then(result => {
      if (abortSignal?.aborted === true) return
      onFinishedRunningActions(result)
    })
  }, [abortSignal, actions, editor, onFinishedRunningActions, onKeyDown])

  return (
    <EditorInner
      layout={'page-card'}
      cursorsEnabled={cursorsEnabled}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      tutorState={tutorStateUnavailable}
    >
      <span />
    </EditorInner>
  )
}

const unknownAssetContext: AssetContext = { type: 'unknown' }
export const ExperimentalEditor: React.FC<{
  mode: EditorMode
  editor: Editor
  editorId: NanoId12
  abortSignal: AbortSignal | undefined
  setError: (error: Error) => void
  actions: EditorAction[]
  onFinishedRunningActions: (result: RunEditorActionsResult) => void
  assetContext?: AssetContext
}> = ({
  mode,
  editor,
  editorId,
  setError,
  actions,
  abortSignal,
  assetContext = unknownAssetContext,
  onFinishedRunningActions,
}) => {
  return (
    <ErrorBoundary onError={setError} fallback={<h1>crash</h1>}>
      <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
        <FocusEditorContext>
          <EditorContextProvider
            initialValue={editor.children}
            editor={editor}
            editorId={editorId}
            readOnly={false}
            mode={mode}
            supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
            chatId={undefined}
            enableCommenting={false}
            rulesOfBlocksViolations='error'
            assetContext={assetContext}
          >
            <Border>
              <ExperimentalEditorInner
                actions={actions}
                abortSignal={abortSignal}
                onFinishedRunningActions={onFinishedRunningActions}
              />
            </Border>
          </EditorContextProvider>
        </FocusEditorContext>
      </FileContext>
    </ErrorBoundary>
  )
}
