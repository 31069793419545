import { InfiniteQueryObserver, QueryKey, useQueryClient } from '@tanstack/react-query'
import {
  Atom,
  WritableAtom as JotaiWritableAtom,
  SetStateAction,
  atom,
  createStore,
  useAtomValue,
} from 'jotai'
import { RESET, atomWithReset, loadable, selectAtom } from 'jotai/utils'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { Filter, filteredByDomainReps, identity, orAll } from 'sierra-client/lib/filter'
import { UseFilter } from 'sierra-client/lib/filter/use-filter'
import {
  jsonWithZodSerializer,
  queryStateAtom,
  stringWithZodSerializer,
} from 'sierra-client/lib/querystate/use-query-state'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { Pager, Pagination } from 'sierra-client/lib/tabular/datatype/pagination'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { TableRowsData } from 'sierra-client/lib/tabular/to-table-row-data'
import { ColumnRef, RowRef } from 'sierra-client/lib/tabular/types'
import { VirtualColumns, toTableRowDataWithVirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { FilterGenerateDomain } from 'sierra-domain/api/filter'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { NonZero } from 'sierra-domain/utils'
import { v4 as uuidv4 } from 'uuid'
import z from 'zod'

type WritableAtom<T> = JotaiWritableAtom<T, [SetStateAction<T>], unknown>

const tableDataToHeaders = <Data extends TableData>({
  tableData,
  hiddenAtom,
  paginationAtom,
}: {
  tableData: Data
  hiddenAtom: Atom<Set<ColumnRef>>
  paginationAtom: Atom<Pagination>
}): Header[] =>
  tableData.columns.map((col): Header => {
    const { header } = col

    if (typeof header !== 'string' && header.type === 'empty') {
      return {
        type: 'empty',
        ref: col.ref,
        hints: [],
        enabled: atom(() => false),
        sortable: atom(() => false),
        columnType: col.type,
      } satisfies Header
    }

    if (typeof header !== 'string' && header.type === 'cell') {
      return {
        type: 'cell',
        ref: col.ref,
        enabled: selectAtom(hiddenAtom, hidden => !hidden.has(col.ref)),
        sortable: atom(get => {
          return col.sortable ?? get(paginationAtom).done // for static data loader?
        }),
        cell: header.cell,
        hints: [],
        tooltip: col.tooltip,
        columnType: col.type,
      } satisfies Header
    }

    return {
      type: 'label',
      ref: col.ref,
      enabled: selectAtom(hiddenAtom, hidden => !hidden.has(col.ref)),
      sortable: atom(get => {
        return col.sortable ?? get(paginationAtom).done // for static data loader?
      }),
      label: header,
      hints: [],
      tooltip: col.tooltip,
      columnType: col.type,
    } satisfies Header
  })

const headersWithVirtualColumns = <Data extends TableData = TableData>(
  headers: Header[],
  api: TableAPI<Data>,
  _td: Data,
  virtual: VirtualColumns<Data>
): Header[] => [...virtual.left.map(v => v.header(api)), ...headers, ...virtual.right.map(v => v.header(api))]

const filterQueryStateSerializer = jsonWithZodSerializer(Filter)

const createFilterAtom = ({
  store,
  initFilter,
  domainReps,
  domain,
  allowEmptyFilters = false,
  queryStateKeyPrefix,
}: {
  store: ReturnType<typeof createStore>
  initFilter: Filter
  domainReps: DomainRep[]
  domain?: FilterGenerateDomain
  allowEmptyFilters?: boolean
  queryStateKeyPrefix: string | null
}): Atom<UseFilter> => {
  const queryStateKey = `${queryStateKeyPrefix}filter`
  const qsAtom =
    queryStateKeyPrefix === null
      ? atom(filteredByDomainReps({ filter: initFilter, domainReps: domainReps, allowEmptyFilters }))
      : queryStateAtom(filterQueryStateSerializer, initFilter, queryStateKey, (f: Filter) =>
          filteredByDomainReps({ filter: f, domainReps: domainReps, allowEmptyFilters })
        )
  return atom((get): UseFilter => {
    const f = get(qsAtom)
    return {
      filter: f,
      ctx: {
        domainReps,
        update: modify => store.set(qsAtom, modify(f)),
        remove: () => store.set(qsAtom, orAll([])),
      },
      domain,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunction<T> = T extends Function ? never : T

const atomToStateActionAtom = <T extends NonFunction<unknown>>(
  atom: JotaiWritableAtom<T, [T], void>
): WritableAtom<T> => {
  return {
    read: atom.read,
    write: (get, set, next: SetStateAction<T>) => {
      atom.write(get, set, typeof next === 'function' ? (next as (prev: T) => T)(get(atom)) : next)
    },
  }
}

const sortingQueryStateSerializer = jsonWithZodSerializer(z.array(Sorting))

const createSortingAtom = ({
  initSort = [],
  queryStateKeyPrefix,
}: {
  initSort: Sorting[] | undefined
  queryStateKeyPrefix: string | null
}): WritableAtom<Sorting[]> => {
  if (queryStateKeyPrefix === null) {
    return atom<Sorting[]>(initSort)
  }
  const queryStateKey = `${queryStateKeyPrefix}sort`
  const qsAtom = queryStateAtom(sortingQueryStateSerializer, initSort, queryStateKey)
  return atomToStateActionAtom(qsAtom)
}

const searchQueryStateSerializer = stringWithZodSerializer(z.string())

const createSearchAtom = ({
  queryStateKeyPrefix,
}: {
  queryStateKeyPrefix: string | null
}): WritableAtom<string> => {
  if (queryStateKeyPrefix === null) {
    return atom<string>('')
  }
  const queryStateKey = `${queryStateKeyPrefix}query`
  const qsAtom = queryStateAtom(searchQueryStateSerializer, '', queryStateKey)
  return atomToStateActionAtom(qsAtom)
}

export type UseTableAPI<Data extends TableData, Meta> = {
  dataLoader: DL.DataLoaderStateMachine<Data, Meta>
  rowsAtom: Atom<TableRowsData<Data>[]>
  headersAtom: Atom<Header[]>
  selectionAtom: Atom<Selection>
  api: TableAPI<Data>
  store: ReturnType<typeof createStore>
}

const hiddenColumnsSer = jsonWithZodSerializer(z.array(z.string()))

export type TableAPIOptions = {
  filter?: {
    domainReps: DomainRep[]
    initialFilter: Filter
    allowEmptyFilters?: boolean
    domain?: FilterGenerateDomain
  }
  sorting?: {
    // If you want to initialize with some sorting. Can be overwritten!
    initialSorting?: Sorting[]
    // Static sorting with always be used before any of the other sorting. Will not be overwrriten
    // but could create duplicates unless used correctly.
    staticSorting?: Sorting[]
  }
  limit?: number
  /**
   * This value will be used a as a prefix for query string keys: `<prefix>filter`, `<prefix>sort` and `<prefix>query` containing certain table state.
   * Make sure that there are no conflicting query string keys on the same page (url), ie if there are multiple tables on the the same page.
   * If this is the only table on the url, you may use `TabularQsKeys.SOLO`, which is essentially no prefix.
   * If you do not want to persist table state in the query string, pass `null`.
   *
   */
  queryStateKeyPrefix: TabularQsKey | null
}

type UseTableAPIParams<Data extends TableData, Meta> = {
  dataLoader: {
    loader: DL.DataLoaderStateMachine<Data, Meta>
    options?: {
      queryKey?: QueryKey
      refetchOnWindowFocus?: boolean
      gcTime?: number
      staleTime?: number
    }
  }
  virtualColumns: VirtualColumns<Data>
  options: TableAPIOptions
}

const getData = <D, M>(d: DL.State<D, M>): Array<D> => {
  switch (d.type) {
    case 'done':
      return d.data
    case 'more':
      return d.data
    case 'init':
      return []
  }
}

const useTableDeps = ({
  options,
  store,
}: {
  options?: TableAPIOptions
  store: ReturnType<typeof createStore>
}): {
  depsAtom: Atom<DL.AllArguments>
  filterAtom: Atom<UseFilter | undefined>
  sortingAtom: WritableAtom<Sorting[]>
  queryAtom: WritableAtom<string>
} => {
  return useMemo(() => {
    const staticSort = options?.sorting?.staticSorting ?? []
    const filterAtom: Atom<UseFilter | undefined> = options?.filter
      ? createFilterAtom({
          store,
          initFilter: options.filter.initialFilter,
          domainReps: options.filter.domainReps,
          domain: options.filter.domain,
          allowEmptyFilters: options.filter.allowEmptyFilters,
          queryStateKeyPrefix: options.queryStateKeyPrefix,
        })
      : atom(undefined)
    const sortingAtom = createSortingAtom({
      initSort: options?.sorting?.initialSorting,
      queryStateKeyPrefix: options?.queryStateKeyPrefix ?? null,
    })
    const queryAtom = createSearchAtom({ queryStateKeyPrefix: options?.queryStateKeyPrefix ?? null })

    const depsAtom = atom<DL.AllArguments>(get => ({
      predicate: {
        filter: get(filterAtom)?.filter,
        query: get(queryAtom),
      },
      modifier: {
        sorting: staticSort.concat(get(sortingAtom)),
      },
      control: { limit: options?.limit },
    }))
    return { depsAtom, filterAtom, sortingAtom, queryAtom }
  }, [
    options?.filter,
    options?.limit,
    options?.queryStateKeyPrefix,
    options?.sorting?.initialSorting,
    options?.sorting?.staticSorting,
    store,
  ])
}

const useQueryKey = (qk?: QueryKey): QueryKey => {
  const memoizedQueryKey = useDeepEqualityMemo(qk)
  const unknownQueryKey = useMemo(() => ['unknown-table-query', uuidv4()], [])
  return memoizedQueryKey ?? unknownQueryKey
}

export const useTableAPI = <Data extends TableData, Meta>({
  dataLoader,
  virtualColumns,
  options,
}: UseTableAPIParams<Data, Meta>): UseTableAPI<Data, Meta> => {
  const _qk = useQueryKey(dataLoader.options?.queryKey)
  const queryClient = useQueryClient()
  const store = React.useMemo(createStore, [])

  // Here are all of the dependencies that are needed for the dataloader (fetching data)
  const { depsAtom, filterAtom, sortingAtom, queryAtom } = useTableDeps({ options, store })

  // Atom that collects all of the responses from the dataloader
  const dataLoaderStateAtom = useMemo(() => atom<DL.State<Data, Meta>>({ type: 'init' }), [])

  const qk = useMemo<QueryKey>(() => {
    return [..._qk, store.get(depsAtom)]
  }, [_qk, depsAtom, store])

  // This will be the request to subscribe to for setting data on dataLoaderStateAtom
  // dataLoaderStateAtom is in turn subscribed by rowsAtom etc for rendering out
  // the table itself
  const req = useMemo(
    () =>
      new InfiniteQueryObserver(queryClient, {
        // Some views do not want refetch on windowOnFocus so defaulting to false
        refetchOnWindowFocus: dataLoader.options?.refetchOnWindowFocus ?? false,
        gcTime: dataLoader.options?.gcTime,
        staleTime: dataLoader.options?.staleTime,
        queryKey: qk,
        queryFn: async params => {
          const state = params.pageParam
          const deps = store.get(depsAtom)
          switch (state.type) {
            case 'init': {
              return dataLoader.loader.onInit(deps)
            }
            case 'more': {
              return dataLoader.loader.onMore(state, {
                control: {
                  limit: options.limit,
                },
              })
            }
            case 'done': {
              return state
            }
          }
        },
        getNextPageParam: lastPage => {
          if (lastPage.type === 'done') {
            // Nothing to get if its undefined
            return undefined
          }
          return lastPage
        },
        initialPageParam: { type: 'init' } as DL.State<Data, Meta>,
      }),
    [
      dataLoader.loader,
      dataLoader.options?.gcTime,
      dataLoader.options?.refetchOnWindowFocus,
      dataLoader.options?.staleTime,
      depsAtom,
      options.limit,
      qk,
      queryClient,
      store,
    ]
  )

  // subscribe and set the latest data to the dataLoaderStateAtom
  useEffect(() => {
    const unsub = req.subscribe(res => {
      const data = res.data?.pages.at(-1)
      if (data) {
        store.set(dataLoaderStateAtom, data)
      }
    })

    return () => {
      unsub()
    }
  }, [dataLoaderStateAtom, req, sortingAtom, store])

  // All the atoms that are there to show values, hide, select things in the table. Basically a state
  const { paginationAtom, currentPageAtom, pagerAtom, selectionAtom, hiddenAtom, expandedAtom, isEmptyAtom } =
    React.useMemo(() => {
      const expandedAtom = atom(new Set<string>())

      const defaultHiddenAtom = atom<ColumnRef[]>(get => {
        const data = getData(get(dataLoaderStateAtom))
        // grab the latest data and set the hidden columns based on that
        return (
          data
            .at(-1)
            ?.columns.filter(c => !c.enabled)
            .map(c => c.ref) ?? []
        )
      })

      const hiddenColumnQsKey = `${options.queryStateKeyPrefix ?? ''}hc`
      const hiddenQueryStateAtomAtom = atom(get =>
        queryStateAtom<ColumnRef[]>(hiddenColumnsSer, get(defaultHiddenAtom), hiddenColumnQsKey)
      )

      const hiddenQueryStateAtom = atom(
        get => get(get(hiddenQueryStateAtomAtom)),
        (get, set, next: ColumnRef[]) => {
          set(get(hiddenQueryStateAtomAtom), next)
        }
      )

      const hiddenAtom = atom(
        get => new Set(get(hiddenQueryStateAtom)),
        (_, set, next: Set<ColumnRef>) => set(hiddenQueryStateAtom, [...next])
      )

      const selectionAtom = atomWithReset<Selection>({ type: 'none' })

      const paginationAtom = atom<Pagination>(get => {
        const state = get(dataLoaderStateAtom)
        if (state.type === 'init') {
          return {
            total: 0,
            loaded: 0,
            done: false,
          }
        }
        return state.pagination
      })

      const currentPageAtom = atomWithReset(NonZero.parse(1))
      const pagerAtom = atom<Pager>(get => {
        const pagination = get(paginationAtom)
        const allPages = getData(get(dataLoaderStateAtom))
        const currentPage = get(currentPageAtom)

        const initPageSize = allPages[0]?.rows.length
        const currentPageSize = allPages[currentPage - 1]?.rows.length ?? 0

        return {
          currentPage: currentPage,
          loadedPages: allPages.length,
          totalPages: Math.ceil(pagination.total / (initPageSize ?? 1)),
          currentPageSize: currentPageSize,
          requestedPageSize: options.limit ?? 0,
        } satisfies Pager
      })

      const isEmptyAtom = atom(get => {
        const loader = get(dataLoaderStateAtom)
        return loader.type === 'done' && loader.data.flatMap(({ rows }) => rows).length === 0
      })
      return {
        isEmptyAtom,
        paginationAtom,
        selectionAtom,
        hiddenAtom,
        expandedAtom,
        pagerAtom,
        currentPageAtom,
      }
    }, [dataLoaderStateAtom, options.limit, options.queryStateKeyPrefix])

  // Create a new function to refresh. This is necessary to allow reseting all pages on infinite query
  const refresh = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: qk })
    // When refreshing, reset selection
    store.set(selectionAtom, RESET)
  }, [qk, queryClient, selectionAtom, store])

  // Api that is used to interact with the table
  const api: TableAPI<Data> = React.useMemo(() => {
    const actions = {
      setFilter: ({ filter }) => store.get(filterAtom)?.ctx.update(() => filter),

      setSorting: ({ sorting }) => store.set(sortingAtom, sorting),

      setSelection: (payload: Selection) => {
        // If you set manually to zero, it will reset to selection 'none'
        if (payload.type === 'manual' && payload.rows.size === 0) {
          store.set(selectionAtom, { type: 'none' })
        } else {
          store.set(selectionAtom, payload)
        }
      },

      setQuery: ({ query }) => store.set(queryAtom, query),

      hideColumns: ({ columns }) => store.set(hiddenAtom, new Set(columns)),

      toggleExpanded: (payload: { row: RowRef }) => {
        const expanded = new Set(store.get(expandedAtom))
        if (expanded.has(payload.row)) {
          expanded.delete(payload.row)
        } else {
          expanded.add(payload.row)
        }
        store.set(expandedAtom, expanded)
      },

      refresh: async () => {
        await refresh()
      },

      loadMore: async () => {
        const state = store.get(dataLoaderStateAtom)
        if (state.type === 'done' || (state.type === 'more' && state.pagination.done)) {
          return
        }
        if (state.type === 'more') {
          await req.fetchNextPage()
        }
      },

      nextPage: async () => {
        const pagination = store.get(paginationAtom)
        const pager = store.get(pagerAtom)
        const currentPage = pager.currentPage
        const isOnLastLoadedPage = currentPage === pager.loadedPages
        const canRequestMore = !pagination.done

        if (isOnLastLoadedPage && canRequestMore) {
          await actions.loadMore()
          return store.set(currentPageAtom, prev => NonZero.parse(prev + 1))
        }

        if (!isOnLastLoadedPage) {
          return store.set(currentPageAtom, prev => NonZero.parse(prev + 1))
        }
      },
      // eslint-disable-next-line @typescript-eslint/require-await
      previousPage: async () => {
        const pager = store.get(pagerAtom)
        const hasPreviousPage = pager.currentPage > 0
        if (hasPreviousPage) {
          store.set(currentPageAtom, prev => NonZero.parse(prev - 1))
        }
      },
    } satisfies TableAPI<Data>['action']

    return {
      action: actions,
      query: {
        selected: () => ({ selection: store.get(selectionAtom) }),
        tableData: () => ({ tableData: getData(store.get(dataLoaderStateAtom)) }),
        filter: () => ({ filter: store.get(filterAtom)?.filter ?? identity }),
        query: () => ({ query: store.get(queryAtom) }),
        sorting: () => ({ sorting: store.get(sortingAtom) }),
        loaderState: () => ({ loaderState: store.get(dataLoaderStateAtom).type }),
        columns: () => ({
          columns:
            getData(store.get(dataLoaderStateAtom))
              .at(-1)
              ?.columns.map(c => ({ ref: c.ref, type: c.type, enabled: c.enabled })) ?? [],
        }),
        pagination: () => ({ pagination: { ...store.get(paginationAtom), ...store.get(pagerAtom) } }),
      },

      atoms: {
        isEmpty: isEmptyAtom,
        filter: atom(get => get(filterAtom)?.filter ?? identity),
        useFilter: filterAtom,
        selection: selectionAtom,
        pagination: atom(get => ({ ...get(paginationAtom), ...get(pagerAtom) })),
        sorting: sortingAtom,
        expanded: expandedAtom,
        loaderState: atom(get => get(dataLoaderStateAtom).type),
        tableData: atom(get => getData(get(dataLoaderStateAtom))),
        columns: atom(
          get =>
            getData(get(dataLoaderStateAtom))
              .at(-1)
              ?.columns.map(c => ({ ref: c.ref, type: c.type, enabled: c.enabled })) ?? []
        ),
        query: atom(get => get(queryAtom)),
      },
    }
  }, [
    currentPageAtom,
    dataLoaderStateAtom,
    expandedAtom,
    filterAtom,
    hiddenAtom,
    isEmptyAtom,
    pagerAtom,
    paginationAtom,
    queryAtom,
    refresh,
    req,
    selectionAtom,
    sortingAtom,
    store,
  ])

  // The data that  is used to render the headers in the table
  const headersAtom = React.useMemo(
    () =>
      atom<Header[]>(get => {
        const tableData = getData(get(dataLoaderStateAtom))
        const data = tableData[0]
        if (data === undefined) {
          return []
        }
        return headersWithVirtualColumns(
          tableDataToHeaders({ tableData: data, hiddenAtom, paginationAtom }),
          api,
          data,
          virtualColumns
        )
      }),
    [api, dataLoaderStateAtom, hiddenAtom, paginationAtom, virtualColumns]
  )

  // The actual data that gets rendered in the table
  const rowsAtom = React.useMemo(
    () =>
      atom<TableRowsData<Data>[]>(get => {
        const data = get(dataLoaderStateAtom)
        if (data.type === 'init') {
          return []
        }

        return data.data.map(d => {
          return toTableRowDataWithVirtualColumns({
            tableData: d,
            hiddenAtom,
            selectionAtom,
            expandedAtom,
            virtualColumns,
            api,
          })
        })
      }),
    [api, dataLoaderStateAtom, expandedAtom, hiddenAtom, selectionAtom, virtualColumns]
  )

  // ########## This makes sure we do a refetch when the deps change
  // Those happens when we change the predicates or the modifiers
  // It is then consumed by the `useAtomValue` below
  const requests = useMemo(
    () =>
      loadable(
        atom(async get => {
          const deps = get(depsAtom)
          // Using store.get does not add dataLoaderStateAtom as a dependency for this atom. i.e, this is atom
          // is not recomputed when dataLoaderStateAtom changes, ony when depsAtom changes
          const state = store.get(dataLoaderStateAtom)

          if (state.type === 'more' || state.type === 'done') {
            const hasChangedPredicate = !_.isEqual(state.predicate, deps.predicate)
            const hasChangedModifier = !_.isEqual(state.modifier, deps.modifier)
            // predicates or modifiers have changed. Do a refetch. Deps will be reeread in the function above
            if (hasChangedPredicate || hasChangedModifier) {
              await refresh()
            }
          }
          return state
        })
      ),
    [dataLoaderStateAtom, depsAtom, refresh, store]
  )

  const requestsState = useAtomValue(requests, { store })
  React.useEffect(() => {
    if (requestsState.state === 'hasError') {
      console.error('Error in dataloader:', requestsState.error)
    }
  }, [requestsState])
  // ########

  return React.useMemo(
    () => ({
      dataLoader: dataLoader.loader,
      rowsAtom,
      headersAtom,
      selectionAtom,
      api,
      store,
    }),
    [api, dataLoader, headersAtom, rowsAtom, selectionAtom, store]
  )
}

/**
 * The purpose of this enum is to gather all tabular query string key prefixes in one place,
 * in order to avoid conflicting keys on the same page.
 */
export enum TabularQsKey {
  /** If this is the only table on the page this prefix (which essentially no prefix) may be used. */
  SOLO = '',
  CONTENT = 'c',
  FEEDBACK = 'f',
  USERS = 'u',
  SESSION = 's',
  GROUP = 'g',
  PROGRAM = 'p',
  FIRST = 't1',
  SECOND = 't2',
  THIRD = 't3',
}
