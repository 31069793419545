import { FC, useMemo } from 'react'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseShareLinkAccessLevel, CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { assertNever, iife } from 'sierra-domain/utils'
import { LabelMenuItem } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { DefaultDropdownTriggerProps, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const PillContainer = styled.div`
  padding: 2.5px 6px;
  background-color: ${token('surface/strong')};
  border-radius: 6px;
`

const PublishRequiredPill: FC = () => {
  const { t } = useTranslation()
  return (
    <PillContainer>
      <Text bold size='technical' color='foreground/muted'>
        {t('share.publish-required')}
      </Text>
    </PillContainer>
  )
}

export const CourseVisibilityDropdown: FC<{
  selectedVisibility: CourseVisibilityInOrg
  onSelect: (value: CourseVisibilityInOrg) => void
  disabled?: boolean
  status?: 'draft' | 'published'
  options: VisibilityOptionState[]
  dropdownVariant?: DefaultDropdownTriggerProps['variant']
}> = ({ selectedVisibility, onSelect, disabled, options, dropdownVariant }) => {
  const { t } = useTranslation()

  const menuItems = options.map<LabelMenuItem<CourseVisibilityInOrg>>(option => {
    const label = iife(() => {
      switch (option.visibility) {
        case 'private':
          return t('share.label.not-visible')
        case 'visible-admins':
          return t('share.label.visible-admins')
        case 'visible-everyone':
          return t('share.label.visible-everyone')
        case 'separate-visibility':
          return t('share.label.separate-visibility')
        default:
          assertNever(option.visibility)
      }
    })

    return {
      id: option.visibility,
      type: 'label',
      label,
      disabled: !option.enabled,
    }
  })

  const selectedItem = menuItems.find(item => item.id === selectedVisibility)

  return (
    <SingleSelectDropdown
      menuItems={menuItems}
      disabled={disabled}
      selectedItem={selectedItem}
      onSelect={item => {
        onSelect(item.id)
      }}
      variant={dropdownVariant}
    />
  )
}

export type VisibilityOptionState = {
  visibility: CourseVisibilityInOrg
  enabled: boolean
}

// TODO: get rid of the weird options here
export const useCourseVisibilityDropdownAllowedItems = (
  id: CourseId,
  options: { privateEnabled: boolean; privateAvailable: boolean; separateVisibilityEnabled: boolean }
): VisibilityOptionState[] => {
  const isVisibleEverywhereAllowed = useContentKindPermissions(id).has('EDIT_VISIBLE_EVERYWHERE')

  const items = useMemo(() => {
    const list: VisibilityOptionState[] = [
      {
        visibility: 'visible-admins' as const,
        enabled: true,
      },
      {
        visibility: 'visible-everyone' as const,
        enabled: isVisibleEverywhereAllowed,
      },
    ]

    if (options.privateAvailable) {
      list.unshift({
        visibility: 'private' as const,
        enabled: options.privateEnabled,
      })
    }

    if (options.separateVisibilityEnabled) {
      list.push({
        visibility: 'separate-visibility' as const,
        enabled: true,
      })
    }

    return list
  }, [
    isVisibleEverywhereAllowed,
    options.privateEnabled,
    options.privateAvailable,
    options.separateVisibilityEnabled,
  ])

  return items
}

export const ShareLinkAccessDropdown: FC<{
  selectedShareLinkAccess: CourseShareLinkAccessLevel
  onSelect: (value: CourseShareLinkAccessLevel) => void
  disabled?: boolean
  type: 'self-paced' | 'live'
  status?: 'draft' | 'published'
  allowedItems: CourseShareLinkAccessLevel[]
}> = ({ selectedShareLinkAccess, onSelect, disabled, type, status = 'published', allowedItems }) => {
  const { t } = useTranslation()

  const shareLinkAccessToMenuItems: {
    [K in CourseShareLinkAccessLevel]: LabelMenuItem<CourseShareLinkAccessLevel>
  } = {
    'invite-only': {
      id: 'invite-only',
      type: 'label',
      label: t('share.label.no-access'),
      hidden: !allowedItems.includes('invite-only'),
    },
    'can-edit': {
      id: 'can-edit',
      type: 'label',
      label: t('share.label.can-edit'),
      hidden: !allowedItems.includes('can-edit'),
    },
    'can-comment': {
      id: 'can-comment',
      type: 'label',
      label: t('share.label.can-comment'),
      hidden: !allowedItems.includes('can-comment'),
    },
    'can-view': {
      id: 'can-view',
      type: 'label',
      label: type === 'self-paced' ? t('share.label.can-view') : t('share.label.can-join'),
      disabled: status === 'draft',
      postComponent: status === 'draft' ? <PublishRequiredPill /> : undefined,
      hidden: !allowedItems.includes('can-view'),
    },
  }
  const menuItems = Object.values(shareLinkAccessToMenuItems)
  const selectedItem = shareLinkAccessToMenuItems[selectedShareLinkAccess]

  return (
    <SingleSelectDropdown
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
      disabled={disabled}
    />
  )
}
