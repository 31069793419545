import { atom } from 'jotai'
import React, { useMemo } from 'react'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { numbersColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader } from 'sierra-client/lib/tabular/dataloader/static'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { selectedAtom } from 'sierra-client/lib/tabular/utils/atom-helpers'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useProgramReminderRecipients } from 'sierra-client/views/manage/programs/hooks/use-program-reminder-recipients'
import { Member } from 'sierra-client/views/manage/programs/send-reminder/types'
import { UserStatus } from 'sierra-domain/user/status'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type RecipientData = Member

type ReminderRecipientTableDefinition = TableDefinitionOf<
  RecipientData,
  [{ type: 'users'; ref: 'recipient' }, { type: 'numbers'; ref: 'progress' }]
>

type ReminderRecipientsTableData = TableDataFromDefinition<RecipientData, ReminderRecipientTableDefinition>

const reminderRecipientsTableDefinition = (): ReminderRecipientTableDefinition => ({
  nested: {},
  rows: {
    getId: r => r.id,
  },
  columns: [
    usersColumn({
      ref: 'recipient',
      header: translatedLabel('manage.programs.send-reminder.recipient'),
      sortable: true,
      getData: r => ({
        id: r.id,
        email: r.email,
        firstName: r.firstName !== null ? r.firstName : '',
        lastName: r.lastName !== null ? r.lastName : '',
        avatarColor: r.avatar?.type === 'color' ? r.avatar.color : undefined,
        avatar:
          r.avatar?.type === 'image'
            ? r.avatar.image.type === 'file'
              ? getAvatarImage(r.id, r.avatar.image.file)
              : undefined
            : undefined,
        status: r.status.toLowerCase() as UserStatus,
        isRequiredAssignment: undefined,
        recurrences: undefined,
      }),
    }),
    numbersColumn({
      ref: 'progress',
      header: translatedLabel('admin.analytics.table.progress'),
      sortable: true,
      getData: r => r.progress,
      hints: ['percentage'],
    }),
  ],
})

export type ReminderRecipientsTabularAction = {
  onRemoveRecipient: (ref: RowRef, api: TableAPI<ReminderRecipientsTableData>) => void
}

const virtualColumns = (
  action: ReminderRecipientsTabularAction
): VirtualColumns<ReminderRecipientsTableData> => ({
  left: [],
  right: [
    {
      ref: 'actions',
      header: () =>
        ({
          type: 'empty',
          ref: 'actions',
          enabled: atom(() => true),
          sortable: atom(() => false),
          hints: ['sticky', 'sticky-right'],
        }) satisfies Header,
      cell: ({ pos, api }) => ({
        type: 'rowAction',
        action: {
          type: 'label',
          callback: action.onRemoveRecipient,
          hints: [],
          label: translatedLabel('dictionary.remove'),
        },
        pos,
        hints: ['sticky', 'sticky-right', 'remove-row'],
        enabled: atom(() => true),
        selected: selectedAtom(pos, api.atoms.selection),
      }),
    },
  ],
})

export const useProgramReminderRecipientsTableAPI = (
  action: ReminderRecipientsTabularAction,
  programId: string
): UseTableAPI<ReminderRecipientsTableData, any> => {
  const { selectedMembers } = useProgramReminderRecipients(programId)

  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({
        loader: staticDataLoader<ReminderRecipientsTableData>(
          definition2Data(reminderRecipientsTableDefinition(), selectedMembers)
        ),
        options: { queryKey: ['program-reminder-recipients-tabular'] },
      }),
      [selectedMembers]
    ),
    virtualColumns: React.useMemo(() => virtualColumns(action), [action]),
    options: {
      queryStateKeyPrefix: null,
    },
  })
}

const TableWrapper = styled(View).attrs({ direction: 'column', grow: true })`
  height: 100%;
  width: 100%;
`

export const ProgramReminderRecipientsTabular: React.FC<{
  tableAPI: UseTableAPI<ReminderRecipientsTableData, any>
}> = React.memo(({ tableAPI }) => {
  const memoizedCallbacks = useMemo<TableCallbacks<ReminderRecipientsTableData>>(
    () => ({ onRow: row => getGlobalRouter().navigate({ to: `/manage/users/${row.ref}` }) }),
    []
  )

  return (
    <TableWrapper>
      <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
        <BasicTabular />
      </TabularProviderFromTableAPI>
    </TableWrapper>
  )
})
