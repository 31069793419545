import { AnimatePresence, motion } from 'framer-motion'
import { FC, useCallback, useEffect, useState } from 'react'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { getFlag } from 'sierra-client/config/global-config'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import {
  Card,
  GranularVisibilityState,
  organizationComparator,
  SquareLogo,
  UnpublishConfirmationModal,
  useCourseDistributionSettingsMutation,
  useOrganizationCluster,
  useUnpublishDistributedCourseMutation,
  visibilityPatch,
} from 'sierra-client/features/multi-tenancy'
import { GridArea } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedInvalidateQuery, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  CoursePermissionSettingsContextProvider,
  useCoursePermissionSettings,
} from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import {
  XRealtimeAuthorGetCoursePublishState,
  XRealtimeAuthorUnpublishSelfPacedCourse,
  XRealtimeGetCourseDistributionPublishStates,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { dotSeparator, useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const UnpublishText = styled(Text).attrs({
  color: 'foreground/muted',
})`
  transition: all 150ms;
  &:hover {
    color: red;
    cursor: pointer;
  }
  button {
    all: unset;
    color: inherit;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
  grid-auto-rows: auto;
  grid-template-areas: 'thumbnail meta';
  gap: 12px;
`

type Props = {
  courseId: CourseId
}

const CourseVisibilitySettingsInner: FC<Props> = ({ courseId }) => {
  const { formatTimeAgo } = useLocalizedFormatters()

  const [unpublishingFromOrganization, setUnpublishingFromOrganization] =
    useState<AccessibleOrganization | null>(null)

  const organizationCluster = useOrganizationCluster()
  const isClusterMember = organizationCluster.loading === false && organizationCluster.cluster.length > 0
  const isClusterParent = organizationCluster.loading === false && organizationCluster.selfIsParent

  const distributionSettingsMutation = useCourseDistributionSettingsMutation()
  const unpublishDistributedCourseMutation = useUnpublishDistributedCourseMutation()
  const unpublishCourseMutation = useTypedMutation(XRealtimeAuthorUnpublishSelfPacedCourse, {
    onSettled: async () => {
      await typedInvalidateQuery(XRealtimeAuthorGetCoursePublishState, { contentId: courseId })
      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
    },
  })
  const publishStateQuery = useCachedQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
  const publishStates = (publishStateQuery.data?.publishState ?? []).sort((first, second) =>
    organizationComparator(first.organization, second.organization)
  )

  const { coursePermissionSettings, fetchInitialCoursePermissionSettings, updateVisibilityInOrg } =
    useCoursePermissionSettings()
  const [rootVisibility, setRootVisibility] = useState<CourseVisibilityInOrg | null>(null)
  const [granularVisibility, setGranularVisibility] = useState<GranularVisibilityState>({
    parent: undefined,
    distributions: {},
  })

  const rootVisibilityOptions = useCourseVisibilityDropdownAllowedItems(courseId, {
    privateEnabled: false,
    privateAvailable: true,
    separateVisibilityEnabled: getFlag('multi-tenancy/granular-visibility'),
  })
  const granularVisibilityOptions = useCourseVisibilityDropdownAllowedItems(courseId, {
    privateEnabled: false,
    privateAvailable: true,
    separateVisibilityEnabled: false,
  })

  useEffect(() => {
    /**
     * Update visibility states when the course permission has loaded
     */

    if (coursePermissionSettings.status === 'done' && rootVisibility === null) {
      setRootVisibility(coursePermissionSettings.visibilityInOrg)
      setGranularVisibility(state => {
        return { ...state, parent: coursePermissionSettings.visibilityInOrg }
      })
    }
  }, [coursePermissionSettings, rootVisibility])

  useEffect(() => {
    /**
     * Update distribution visibilities when publish states are loaded
     */

    function translate(visibility: 'private' | 'visible-for-admins' | 'visible'): CourseVisibilityInOrg {
      switch (visibility) {
        case 'private': {
          return 'private'
        }
        case 'visible-for-admins': {
          return 'visible-admins'
        }
        case 'visible': {
          return 'visible-everyone'
        }
      }
    }

    setGranularVisibility(() => {
      return publishStates.reduce(
        (acc: GranularVisibilityState, state) => {
          const visibility = translate(state.visibility)

          if (state.organization.isClusterParent) {
            acc.parent = visibility
          } else {
            acc.distributions[state.organization.namespaceId] = {
              enabled: true,
              visibility,
            }
          }
          return acc
        },
        { parent: undefined, distributions: {} }
      )
    })
  }, [publishStates])

  useOnChanged((_, currentCourseId) => {
    /**
     * Fetch the course permissions for the course id
     */

    void fetchInitialCoursePermissionSettings(currentCourseId)
  }, courseId)

  const onRootVisibilityChange = useCallback(
    (visibility: CourseVisibilityInOrg) => {
      setRootVisibility(visibility)
      setGranularVisibility(state => {
        const patch = visibilityPatch(
          state,
          publishStates.map(ps => ps.organization),
          visibility === 'separate-visibility' ? 'visible-admins' : visibility
        )
        return patch
      })
    },
    [setRootVisibility, publishStates]
  )

  const onGranularVisibilityChange = useCallback(
    (organization: AccessibleOrganization) => (visibility: CourseVisibilityInOrg) => {
      setGranularVisibility(state => {
        if (organization.isClusterParent) {
          return { ...state, parent: visibility }
        } else {
          return {
            ...state,
            distributions: {
              ...state.distributions,
              [organization.namespaceId]: {
                enabled: state.distributions[organization.namespaceId]?.enabled ?? false,
                visibility,
              },
            },
          }
        }
      })
    },
    []
  )

  useOnChanged(async (previous, next) => {
    if (previous?.parent !== next.parent && isDefined(next.parent)) {
      if (isDefined(granularVisibility.parent)) {
        await updateVisibilityInOrg(courseId, granularVisibility.parent)
      }
    }

    distributionSettingsMutation.mutate({
      courseId,
      settings: Object.entries(granularVisibility.distributions).map(([namespaceId, distribution]) => ({
        childNamespaceId: namespaceId,
        visibility: distribution.visibility === 'visible-admins' ? 'visible-for-admins' : 'visible',
      })),
    })
  }, granularVisibility)

  const unpublishCourse = useCallback(() => {
    const organization = unpublishingFromOrganization

    if (organization === null) {
      return
    }

    if (organization.isClusterParent) {
      unpublishCourseMutation.mutate({ contentId: courseId })
    } else {
      return unpublishDistributedCourseMutation.mutate({
        namespaceId: organization.namespaceId,
        contentId: courseId,
      })
    }
  }, [courseId, unpublishCourseMutation, unpublishDistributedCourseMutation, unpublishingFromOrganization])

  const { t } = useTranslation()

  if (organizationCluster.loading || rootVisibility === null) {
    return null
  }

  return (
    <View direction='column' gap='24'>
      <FormElement label={t('dictionary.visibility')}>
        <CourseVisibilityDropdown
          selectedVisibility={rootVisibility}
          onSelect={onRootVisibilityChange}
          options={rootVisibilityOptions}
          disabled={isClusterMember && !isClusterParent}
        />
      </FormElement>

      {isClusterParent && (
        <>
          <View direction='column' gap='8' padding='2'>
            <AnimatePresence initial={false}>
              {publishStates.map(state => {
                const organization = state.organization
                const organizationVisibility = organization.isClusterParent
                  ? granularVisibility.parent
                  : granularVisibility.distributions[organization.namespaceId]?.visibility

                if (!isDefined(organizationVisibility)) {
                  return null
                }

                return (
                  <Card key={organization.domain} $column>
                    <CardGrid>
                      <GridArea area='thumbnail'>
                        {isDefined(organization.squareLogoUrl) ? (
                          <SquareLogo src={organization.squareLogoUrl} />
                        ) : (
                          <OrganizationLogo orgName={organization.name} brandSettings={undefined} />
                        )}
                      </GridArea>

                      <GridArea area='meta'>
                        <View direction='column' gap='none'>
                          <View>
                            <Text>{organization.name}</Text>
                            {organization.isClusterParent === true && (
                              <Icon iconId='building' color='foreground/muted' />
                            )}
                          </View>

                          <View gap='4'>
                            <Text color='foreground/muted'>
                              {t('manage.published-at', { date: formatTimeAgo(state.lastPublishedAt) })}
                            </Text>
                            <Text color='foreground/muted'>{dotSeparator}</Text>
                            <UnpublishText>
                              <button onClick={() => setUnpublishingFromOrganization(organization)}>
                                {t('admin.author.unpublish')}
                              </button>
                            </UnpublishText>
                          </View>
                        </View>
                      </GridArea>

                      <AnimatePresence>
                        {rootVisibility === 'separate-visibility' && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.18 }}
                            style={{ gridColumnStart: 2 }}
                          >
                            <CourseVisibilityDropdown
                              selectedVisibility={organizationVisibility}
                              onSelect={onGranularVisibilityChange(organization)}
                              options={granularVisibilityOptions}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </CardGrid>
                  </Card>
                )
              })}
            </AnimatePresence>
          </View>

          <UnpublishConfirmationModal
            organization={unpublishingFromOrganization}
            onCancel={() => setUnpublishingFromOrganization(null)}
            onConfirm={() => unpublishCourse()}
          />
        </>
      )}
    </View>
  )
}

export const CourseVisibilitySettings: FC<Props> = props => {
  return (
    <CoursePermissionSettingsContextProvider>
      <CourseVisibilitySettingsInner {...props} />
    </CoursePermissionSettingsContextProvider>
  )
}
