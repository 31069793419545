import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import React, { useState } from 'react'
import { currentUserQuery } from 'sierra-client/api/hooks/use-user'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useDebugToast } from 'sierra-client/layout/sana-page/components/debug-toast'
import { useSelector } from 'sierra-client/state/hooks'
import { selectLogs } from 'sierra-client/state/logging/selectors'
import { isOwnerUser } from 'sierra-domain/access-level'
import { assertNever, iife } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const Pre = styled.pre`
  font-family: monospace;
`

const LogSnacks: React.FC = () => {
  const { addToast } = useDebugToast()
  const logs = useSelector(selectLogs)
  const [initialLogs] = useState(() => logs)
  const [selectedLog, setSelectedLog] = useState<(typeof logs)[number]>()

  useOnChanged((previousLogs, allLogs) => {
    const offset = (previousLogs ?? initialLogs).length
    const onlyNewLogs = _.drop(allLogs, offset)
    onlyNewLogs.forEach((log, index) => {
      const indexInOriginal = index + offset
      const isDuplicate = _.isEqual(allLogs[indexInOriginal - 1], log)
      const message = iife(() => {
        switch (log.type) {
          case 'event':
            return log.message.event
          case 'page':
            return `page: ${log.message.name}`
          default:
            assertNever(log)
        }
      })

      addToast({
        title: `${isDuplicate ? 'duplicate: ' : ''}${message}`,
        variant: isDuplicate ? 'warning' : 'info',
        action: {
          text: 'show',
          altText: 'show log',
          onClick: () => setSelectedLog(log),
        },
      })
    })
  }, logs)

  return (
    <Modal open={selectedLog !== undefined} onClose={() => setSelectedLog(undefined)} size='large'>
      <Pre>{selectedLog && JSON.stringify(selectedLog.message, null, 2)}</Pre>
    </Modal>
  )
}

export const Logs = (): JSX.Element | null => {
  const isDebugMode = useIsDebugMode()
  const { data: isOwner } = useQuery({
    ...currentUserQuery,
    enabled: isDebugMode,
    select: isOwnerUser,
  })

  if (!isDebugMode || isOwner !== true) return null

  return <LogSnacks />
}
