import { useAtomValue } from 'jotai/index'
import { config } from 'sierra-client/config/global-config'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { CourseCompleted } from 'sierra-client/views/course-helper/content/course-completed'
import { CoursePending, ScormCoursePending } from 'sierra-client/views/course-helper/content/course-pending'
import { ScormCourseFallback } from 'sierra-client/views/course-helper/content/fallback/scorm-fallback'
import { Forward } from 'sierra-client/views/course-helper/content/forward'
import { NextCourseInPath } from 'sierra-client/views/course-helper/content/next-course-in-path.tsx'
import { NextCourseInProgram } from 'sierra-client/views/course-helper/content/next-course-in-program'
import { ProgramCompleted } from 'sierra-client/views/course-helper/content/program-completed'
import { ProgramPending } from 'sierra-client/views/course-helper/content/program-pending'
import { TextComponent } from 'sierra-client/views/course-helper/content/text-component'
import { Undefined } from 'sierra-client/views/course-helper/content/undefined'
import { HelperState } from 'sierra-client/views/course-helper/types'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { assertNever } from 'sierra-domain/utils'

const RenderNativeHelperContent: React.FC<{
  tutorState: TutorState
  state: HelperState
}> = ({ tutorState, state }) => {
  switch (state.type) {
    case 'none':
      return null
    case 'forward':
      return <Forward />
    case 'text':
      return <TextComponent textState={state} />
    case 'course-completed':
      return <CourseCompleted tutorState={tutorState} />
    case 'program-completed':
      return <ProgramCompleted tutorState={tutorState} />
    case 'program-pending':
      return <ProgramPending tutorState={tutorState} />
    case 'next-course-in-program':
      return <NextCourseInProgram tutorState={tutorState} />
    case 'next-course-in-path':
      return <NextCourseInPath tutorState={tutorState} />
    case 'course-pending':
      return <CoursePending tutorState={tutorState} helperState={state} />
    case 'undefined' /* TODO(seb): Remove once all are implemented */:
      return <Undefined />
    default:
      assertNever(state)
  }
}

const RenderHelperScormContent: React.FC<{
  tutorState: TutorState
  state: HelperState
}> = ({ tutorState, state }) => {
  switch (state.type) {
    case 'none':
      return null
    case 'forward':
      return <Forward />
    case 'text':
      return <TextComponent textState={state} />
    case 'course-completed':
      return <ScormCourseFallback />
    case 'course-pending':
      return <ScormCoursePending tutorState={tutorState} helperState={state} />
    case 'next-course-in-program':
    case 'next-course-in-path':
    case 'program-completed':
    case 'program-pending':
      return null
    case 'undefined' /* TODO(seb): Remove once all are implemented */:
      return <Undefined />
    default:
      assertNever(state)
  }
}

export const RenderHelperContent: React.FC<{
  tutorState: TutorState
}> = ({ tutorState }) => {
  const state = useAtomValue(helperStateAtom)
  const { isScorm } = config.scorm

  if (isScorm) {
    return <RenderHelperScormContent tutorState={tutorState} state={state} />
  }
  return <RenderNativeHelperContent tutorState={tutorState} state={state} />
}
