import React, { useState } from 'react'
import { useDuplicateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { useCourseTranslationItems } from 'sierra-client/hooks/use-language-items'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseTranslationLanguageCode } from 'sierra-client/i18n/course-translation-languages'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Button } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  gap: 0.5rem;
  grid: 1fr / repeat(2, min-content);
`

export const TranslateCourseShortcut: React.FC<{
  courseId: CourseId
  courseKind: CourseKind
}> = ({ courseId, courseKind }) => {
  const items = useCourseTranslationItems()

  const duplicateAndTranslate = useDuplicateEditableContentMutation()
  const shortcutDispatch = useShortcutMenuDispatch()

  return (
    <ShortcutMenu.Settings
      items={items}
      permission='ACCESS_EDITOR'
      label={{ type: 'untranslated', value: 'Translate course' }}
      iconId='translate'
      onItemSelected={({ id: targetLanguage }) => {
        if (!(courseKind === 'native:live' || courseKind === 'native:self-paced')) {
          return
        }

        void duplicateAndTranslate.mutate(
          {
            targetLanguage,
            originalFlexibleContentId: courseId,
          },
          {
            onSuccess: () => {
              shortcutDispatch({ type: 'close' })
            },
          }
        )
      }}
    />
  )
}

export const DuplicateTranslatedCourse: React.FC<{
  courseId: CourseId
  courseKind: CourseKind
  onTranslated: () => void
}> = ({ courseId, courseKind, onTranslated }) => {
  const { t } = useTranslation()
  const [languageCode, setLanguageCode] = useState<CourseTranslationLanguageCode>()

  const disabled = Boolean(languageCode === undefined)

  const duplicateAndTranslate = useDuplicateEditableContentMutation()
  const duplicate = (): void => {
    if (!(courseKind === 'native:live' || courseKind === 'native:self-paced')) {
      return
    }

    void duplicateAndTranslate.mutate(
      {
        originalFlexibleContentId: courseId,
        targetLanguage: languageCode,
      },
      {
        onSuccess: () => {
          onTranslated()
        },
      }
    )
  }

  const items = useCourseTranslationItems()

  const selectedItem = items.find(item => item.id === languageCode)

  return (
    <Grid>
      <SingleSelectDropdown
        truncateTrigger={false}
        selectedItem={selectedItem}
        menuItems={items}
        onSelect={item => {
          setLanguageCode(item.id)
        }}
        placeholder={t('course-settings.select-language')}
      />

      <Button
        variant='secondary'
        loading={duplicateAndTranslate.isPending}
        onClick={duplicate}
        disabled={disabled}
      >
        {duplicateAndTranslate.isPending ? t('dictionary.translating') : t('dictionary.duplicate')}
      </Button>
    </Grid>
  )
}
