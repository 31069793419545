import { useAtom, useAtomValue } from 'jotai'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { date } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { dateTimesColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { TabularQsKey, useTableAPI, UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { typedPost } from 'sierra-client/state/api'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActionButton, EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { CourseCommentsRequest, CourseRating } from 'sierra-domain/api/manage'
import {
  XRealtimeAdminCoursesCourseComments,
  XRealtimeAdminCoursesCourseCommentsCount,
} from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { scrollViewStyles, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type CourseFeedbackData = CourseRating

type CourseFeedbackTableDefinition = TableDefinitionOf<
  CourseFeedbackData,
  [
    { type: 'strings'; ref: 'comment' },
    { type: 'strings'; ref: 'rating' },
    { type: 'dateTimes'; ref: 'createdAt' },
  ]
>

type CourseFeedbackTableData = TableDataFromDefinition<CourseFeedbackData, CourseFeedbackTableDefinition>

type CourseFeedbackTableMeta = { cursor: string | undefined }

type SortColumn = 'comment' | 'rating' | 'createdAt'

const isValidSortColumn = (column: string): column is SortColumn =>
  ['comment', 'rating', 'createdAt'].includes(column)

const mapSortArguments = ({
  column,
  direction,
}: Sorting): Exclude<CourseCommentsRequest['sortBy'], void>[number] => {
  const key = iife(() => {
    switch (column) {
      case 'rating':
        return 'RATING'
      case 'comment':
        return 'COMMENT'
      case 'createdAt':
        return 'CREATED_AT'
      default:
        throw new Error('Invalid sort column')
    }
  })
  return {
    key,
    order: direction === 'ascending' ? 'ASC' : 'DESC',
  }
}

const tableDefinition: CourseFeedbackTableDefinition = {
  columns: [
    stringsColumn({
      ref: 'comment',
      sortable: isValidSortColumn('comment'),
      header: translatedLabel('dictionary.comment-singular'),
      getData: row => row.comment,
    }),
    stringsColumn({
      ref: 'rating',
      sortable: isValidSortColumn('rating'),
      header: translatedLabel('table.rating'),
      getData: row => `${row.rating.toFixed(1)} / 5`,
    }),
    dateTimesColumn({
      ref: 'createdAt',
      sortable: isValidSortColumn('createdAt'),
      header: translatedLabel('dictionary.date'),
      getData: row => ({ date: new Date(row.createdAt) }),
    }),
  ],
  rows: {
    getId: row => `${row.comment.slice(-30)}-${row.rating}-${row.createdAt.valueOf()}`,
  },
  nested: {},
}

const PAGE_SIZE = 200

const useCourseFeedbackTable = ({
  courseId,
}: {
  courseId: string
}): UseTableAPI<CourseFeedbackTableData, { cursor: string | undefined }> => {
  const loader = createDataLoader({
    fetchInit: async ({ control, predicate, modifier }) => {
      const [{ data, cursor }, { count }] = await Promise.all([
        typedPost(XRealtimeAdminCoursesCourseComments, {
          limit: control.limit ?? PAGE_SIZE,
          id: courseId,
          query: predicate.query,
          sortBy: modifier.sorting?.filter(({ column }) => isValidSortColumn(column)).map(mapSortArguments),
        }),
        typedPost(XRealtimeAdminCoursesCourseCommentsCount, {
          id: courseId,
          query: predicate.query,
        }),
      ])

      return {
        data,
        meta: {
          cursor: null2Undefined(cursor),
        },
        done: !Boolean(cursor),
        totalCount: count,
      }
    },

    fetchMore: async ({ meta, predicate, control, pagination, modifier }) => {
      const { cursor, data } = await typedPost(XRealtimeAdminCoursesCourseComments, {
        limit: control.limit ?? PAGE_SIZE,
        id: courseId,
        query: predicate.query,
        cursor: null2Undefined(meta.cursor),
        sortBy: modifier.sorting?.filter(({ column }) => isValidSortColumn(column)).map(mapSortArguments),
      })

      return {
        data,
        meta: {
          cursor: null2Undefined(cursor),
        },
        done: !Boolean(cursor),
        totalCount: pagination.total,
      }
    },

    transformResults(data) {
      return [definition2Data(tableDefinition, data)]
    },
  })

  return useTableAPI({
    dataLoader: {
      loader,
      options: {
        queryKey: [XRealtimeAdminCoursesCourseComments.path, courseId],
      },
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [],
    },
    options: {
      limit: PAGE_SIZE,
      queryStateKeyPrefix: TabularQsKey.FEEDBACK,
    },
  })
}

type SearchProps = {
  tableAPI: UseTableAPI<CourseFeedbackTableData, CourseFeedbackTableMeta>
}

const Search: React.FC<SearchProps> = ({ tableAPI }) => {
  const { t } = useTranslation()

  const [debouncedQuery, liveQuery, setQuery] = useDebouncedAndLiveState(
    tableAPI.api.query.query().query ?? ''
  )

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: debouncedQuery })
  }, [debouncedQuery, tableAPI.api.action])

  return <AnimatedSearch value={liveQuery} placeholder={t('manage.search.users')} onChange={setQuery} />
}

type ToolbarProps = {
  tableAPI: UseTableAPI<CourseFeedbackTableData, CourseFeedbackTableMeta>
}
const Toolbar: React.FC<ToolbarProps> = ({ tableAPI }) => {
  const { api, dataLoader } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('dictionary.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type !== 'none' ? (
          <>
            <ActionButton
              onClick={() =>
                exportTableData({
                  api,
                  fileType: 'csv',
                  dataLoader,
                  t,
                  withName: t('dictionary.feedback'),
                })
              }
              color='blueBright'
            >
              {csvButtonText}
            </ActionButton>
            <ActionButton
              color='redBright'
              onClick={() => tableAPI.api.action.setSelection({ type: 'none' })}
            >
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 1rem;
  max-height: 27rem;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

const mapFeedbackToCsv = (feedback: CourseRating): Record<string, string> => ({
  comment: feedback.comment,
  createdAt: date(feedback.createdAt),
  rating: feedback.rating.toFixed(1),
})

type FooterProps = {
  fetchCsv: () => Promise<Record<string, string>[]>
}

const Footer: React.FC<FooterProps> = ({ fetchCsv }) => {
  const { t } = useTranslation()

  return (
    <View marginBottom='32' marginTop='32' justifyContent={'flex-end'}>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('dictionary.feedback')} />
    </View>
  )
}

export type CourseFeedbackTableProps = {
  courseId: string
}

const EmptyStateWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const isEmpty = useAtomValue(api.atoms.isEmpty)

  return isEmpty ? (
    <View direction='column' gap='medium'>
      <EmptySection>
        <Icon iconId='generate--paragraph' size='size-14' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.course.feedback.empty-title')}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center'>
          {t('manage.course.feedback.empty-message')}
        </EmptyBody>
        <Spacer size='small' />
      </EmptySection>
    </View>
  ) : (
    children
  )
}

export const CourseFeedbackTable: React.FC<CourseFeedbackTableProps> = ({ courseId }) => {
  const { t } = useTranslation()
  const tableAPI = useCourseFeedbackTable({
    courseId,
  })

  const fetchCsv = async (): Promise<Record<string, string>[]> => {
    const { data } = await typedPost(XRealtimeAdminCoursesCourseComments, {
      id: courseId,
    })
    return data.map(mapFeedbackToCsv)
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Text size='large' bold>
        {t('dictionary.feedback')}
      </Text>
      <Spacer size='xsmall' />
      <EmptyStateWrapper>
        <Search tableAPI={tableAPI} />
        <Spacer size='xsmall' />
        <Toolbar tableAPI={tableAPI} />
        <Table />
        <Footer fetchCsv={fetchCsv} />
      </EmptyStateWrapper>
    </TabularProviderFromTableAPI>
  )
}
