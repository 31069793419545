import React from 'react'
import { Context } from 'sierra-client/lib/filter/components/common'
import { FilterComponent } from 'sierra-client/lib/filter/components/filter'
import * as UI from 'sierra-client/lib/filter/ui'
import { FCC } from 'sierra-client/types'
import { AssetContext } from 'sierra-domain/asset-context'
import { Filter } from 'sierra-domain/filter/datatype/filter'

export const FilterRoot: FCC<{
  ctx: Context
  filter: Filter
  generateFilter?: (query: string) => Promise<Filter>
  assetContext: AssetContext
  readOnly?: boolean
}> = React.memo(({ ctx, filter, children, generateFilter, assetContext, readOnly = false }) => (
  <UI.FilterGroup.Wrapper readOnly={readOnly}>
    <UI.Filter readOnly={readOnly} grow>
      <FilterComponent
        ctx={ctx}
        filter={filter}
        generateFilter={generateFilter}
        assetContext={assetContext}
        readOnly={readOnly}
      />
      {children}
    </UI.Filter>
  </UI.FilterGroup.Wrapper>
))
