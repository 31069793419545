import { motion } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'
import { FCC } from 'sierra-client/types'
import { helperCollapsedAtom, helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { CourseRating } from 'sierra-client/views/course-helper/content/course-rating'
import { useIsMobileHelper } from 'sierra-client/views/course-helper/hooks/use-is-mobile-helper'
import { useHelperSize } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { AITutorSuggestQuestion } from 'sierra-client/views/course-helper/tutor/ai-tutor-suggest-questions'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { ColorBuilder, color } from 'sierra-ui/color'
import { Icon, IconId } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css, useTheme } from 'styled-components'

const collapseDuration = '0.2'

const contentVariants = {
  closed: {
    height: '0',
    transition: {
      when: 'afterChildren',
      duration: collapseDuration,
    },
  },
  open: {
    height: 'auto',
    transition: {
      when: 'beforeChildren',
      duration: collapseDuration,
    },
  },
}

const contentInnerVariants = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
}

const Wrapper = styled(View).attrs({ direction: 'column', gap: 'none' })<{
  $isMobileHelper: boolean
}>`
  padding: 24px;
  min-width: 416px;

  ${p =>
    p.$isMobileHelper &&
    css`
      padding: 16px 18px;
      min-width: auto;
      width: 100%;
    `}
`

const Content = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  animated: true,
  variants: contentVariants,
  gap: 'none',
  grow: true,
})`
  overflow: hidden;
`

const ContentInner = styled(motion.div).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  variants: contentInnerVariants,
})<{ $padding: number }>`
  padding-top: ${p => p.$padding}px;

  /* For overflow scroll on mobile */
  flex: 1 1 100%;
  overflow: scroll;
`

const Header = styled(View).attrs({
  grow: false,
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  transition: border-bottom-color 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
`

const FullWidth = styled(View)`
  width: 100%;
`

const SuggestedQuestionsContainer = styled(View)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

/* We keep this one separate, so it fades out nicely with the content when we collapse */
const HorizontalLine = styled.div<{ $padding: number }>`
  border-bottom: 1px solid ${token('form/border/1')};
  padding-bottom: ${p => p.$padding}px;
`

export const HelperActions = styled(View).attrs({ grow: true, gap: '8', justifyContent: 'flex-end' })``

export const HelperContent: FCC<{
  title: string
  icon: IconId
  tutorState: TutorState
}> = ({ children, title, icon, tutorState }) => {
  const [isCollapsed, setCollapsed] = useAtom(helperCollapsedAtom)
  const isMobileHelper = useIsMobileHelper()
  const { updateSize } = useHelperSize()
  const padding = isMobileHelper ? 16 : 24

  return (
    <Wrapper
      $isMobileHelper={isMobileHelper}
      cursor={isCollapsed ? 'pointer' : 'default'}
      onClick={() => {
        if (isCollapsed) {
          setCollapsed(false)
        }
      }}
    >
      <Header>
        <FullWidth justifyContent='space-between' alignItems='center'>
          <View grow direction='row' gap='8' alignItems='center'>
            <Icon iconId={icon} color='grey40' />
            <Text size='regular' bold>
              {title}
            </Text>
          </View>
          <IconButton
            iconId={isCollapsed ? 'expand-screen' : 'shrink-screen'}
            size='small'
            variant='transparent'
            onClick={() => setCollapsed(!isCollapsed)}
          />
        </FullWidth>

        {tutorState.type === 'available' && !isCollapsed && (
          <SuggestedQuestionsContainer>
            <AITutorSuggestQuestion count={2} tutorState={tutorState} />
          </SuggestedQuestionsContainer>
        )}
      </Header>
      <Content
        initial={isCollapsed ? 'closed' : 'open'}
        animate={isCollapsed ? 'closed' : 'open'}
        onAnimationStart={() => updateSize({ animationEnabled: false })}
        onUpdate={() => updateSize({ animationEnabled: false })}
        onAnimationComplete={() => updateSize({ animationEnabled: true })}
      >
        <HorizontalLine $padding={padding} />
        <ContentInner $padding={padding}>
          {children}
          <CourseRating />
        </ContentInner>
      </Content>
    </Wrapper>
  )
}

export type InlineStatePaddingSwitch = { $isMobile: boolean }

export const inlineStatePadding = css<InlineStatePaddingSwitch>`
  padding: ${p => (p.$isMobile ? `14px 16px` : `16px 20px`)};
`

const IconLabelState_ = styled(View).attrs({
  grow: true,
  direction: 'row',
  gap: '8',
  justifyContent: 'space-between',
  alignItems: 'center',
})<InlineStatePaddingSwitch>`
  user-select: none;
  ${inlineStatePadding};
`

const ColoredText = styled(Text)<{ $color: undefined | { backgroundColor: ColorBuilder } }>`
  ${p =>
    p.$color !== undefined
      ? p.$color.backgroundColor.brightness() > 0.9
        ? 'color: rgba(0, 0, 0, 0.6)'
        : 'color: white'
      : ''};
`

export const ColoredIcon = styled(Icon)<{ $color: undefined | { backgroundColor: ColorBuilder } }>`
  ${p =>
    p.$color !== undefined &&
    css`
      box-shadow: none;
      ${p.$color.backgroundColor.brightness() > 0.9
        ? css`
            color: rgba(0, 0, 0, 0.4);
          `
        : css`
            color: white;
            opacity: 0.6;
          `};
    `}
`

export const IconLabelState: FC<{ title: string; iconId: IconId; onClick?: () => void }> = ({
  title,
  iconId,
  onClick,
}) => {
  const state = useAtomValue(helperStateAtom)
  const theme = useTheme()
  const backgroundColor = color(theme.home.backgroundColor)
  const isMobileHelper = useIsMobileHelper()

  return (
    <IconLabelState_
      onClick={onClick}
      cursor={onClick === undefined ? 'default' : 'pointer'}
      $isMobile={isMobileHelper}
      role='button'
    >
      <ColoredText
        $color={
          state.type === 'text' && state.textType !== 'continue'
            ? { backgroundColor: backgroundColor }
            : undefined
        }
        size='small'
        bold
      >
        {title}
      </ColoredText>
      <ColoredIcon
        $color={
          state.type === 'text' && state.textType !== 'continue'
            ? { backgroundColor: backgroundColor }
            : undefined
        }
        iconId={iconId}
        size='size-20'
      />
    </IconLabelState_>
  )
}
