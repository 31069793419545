import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { AuthHeader } from 'sierra-client/layout/header/auth'
import { horizontalPadding, removeScrollbar } from 'sierra-client/layout/styles/content'
import { FCC } from 'sierra-client/types'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled(View)`
  ${removeScrollbar}
  height: calc(var(--VH, 1vh) * 100);

  background-color: ${token('surface/soft')};
  gap: 0;
`

const InnerWrapper = styled(View)`
  ${horizontalPadding}
  width: 100%;
  height: 100%;
`

// eslint-disable-next-line react/jsx-no-literals
const PoweredBySana = styled(Text).attrs({ children: <>Powered by Sana™</> })`
  color: ${token('foreground/secondary')};
`

// Pass Authentication form as child
export const AuthenticationLayout: FCC = ({ children }) => {
  return (
    <AppThemeTokenProvider>
      <Wrapper direction='column' alignItems='center'>
        <AuthHeader />
        <InnerWrapper direction='column' justifyContent='space-around' alignItems='center'>
          <Spacer />
          {children}
          <PoweredBySana />
          <Spacer />
        </InnerWrapper>
      </Wrapper>
    </AppThemeTokenProvider>
  )
}

export const AuthenticationForm = styled.form`
  width: 100%;
`
