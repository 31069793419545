import { useMutation } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { SelfEnrollToProgramMutationVariables } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { ProgressLine } from 'sierra-client/components/common/progress-line'
import { getFlag } from 'sierra-client/config/global-config'
import { useGetDaysLeft } from 'sierra-client/core/format'
import { Fading, Sliding, clusterSectionsLearner } from 'sierra-client/features/program'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import {
  DetailsBottom,
  DetailsTop,
  OverviewDetails,
  OverviewGrid,
  ProgressLineWrapper,
} from 'sierra-client/views/learner/components/overview/common'
import { EllipsedText } from 'sierra-client/views/learner/components/overview/ellipsed-text'
import { OverviewHeader } from 'sierra-client/views/learner/components/overview/header'
import {
  HOVER_BACKGROUND_PADDING_SIZE,
  HeadingContainer,
  ImageContainer,
  PathLine,
  PathSubCourses,
  ProgramInfoButtonWrapper,
  ProgramPageContentWrapper,
  SectionView,
  SelfEnrollSessionListLayout,
  StepContainer,
  StepContentContainer,
  StepInfoContainer,
  StepInfoHeader,
  StepSubContent,
  TextMaxLines,
  WrapperOld,
} from 'sierra-client/views/learner/program/atoms'
import { EnrolledStepContent } from 'sierra-client/views/learner/program/components/enrolled-step-content'
import { StepMetaDataBasic } from 'sierra-client/views/learner/program/components/meta-data'
import { NextStepInfo } from 'sierra-client/views/learner/program/components/next-step-info'
import { StepContentLabel } from 'sierra-client/views/learner/program/components/step-content-label'
import { ProgramPathStep } from 'sierra-client/views/learner/program/program-path-step'
import { StepUpcomingLiveSession } from 'sierra-client/views/learner/program/types'
import {
  selfEnrollToProgramMutation,
  useGetLearnerProgram,
} from 'sierra-client/views/learner/program/use-get-learner-program'
import {
  EnrollmentData,
  EnrollmentStepType,
  ProgramInfo,
  ProgramInfoStepType,
  getStepImage,
} from 'sierra-client/views/learner/program/utils/step-graphql'
import {
  courseTypeNameToCourseKind,
  getContinueURLForStep,
  getNextStepForProgram,
  isSameEnrollmentStep,
  mapLiveSessionLocation,
  programInfoStepAsCourseKind,
  programInfoStepAssetContext,
  useFormatDuration,
} from 'sierra-client/views/learner/program/utils/utils'
import { SelfEnrollCalendarEvents } from 'sierra-client/views/learner/self-enroll-calendar-events/self-enroll-calendar-events'
import { SelfEnrollLiveSessionList } from 'sierra-client/views/learner/self-enroll-live-session/self-enroll-live-session-list'
import { useContentAttributeValues } from 'sierra-client/views/manage/content-attributes/hooks'
import { LearnerAttributesRenderer } from 'sierra-client/views/manage/content-attributes/renderers'
import { Separator } from 'sierra-client/views/showcase/common'
import { CourseKind } from 'sierra-domain/api/common'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, isNonEmptyString, isNonNullable } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Button, IconButton, InternalTruncatedText, Skeleton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import { isDefined } from 'sierra-ui/utils/is-defined'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled from 'styled-components'

const Wrapper: FCC<{ $hidden: boolean }> = ({ $hidden, children }) => {
  return (
    <AnimatePresence>
      {$hidden ? null : (
        <WrapperOld
          initial={{
            height: 0,
            opacity: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          animate={{
            height: 'auto',
            opacity: 1,
            paddingBottom: HOVER_BACKGROUND_PADDING_SIZE,
          }}
          exit={{
            height: 0,
            opacity: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          {children}
        </WrapperOld>
      )}
    </AnimatePresence>
  )
}

type ProgramStepListItemProps = {
  programId: string
  locked: boolean
  enrollmentStep: EnrollmentStepType
  parentPathId?: string
  enrollment: EnrollmentData
  index: number
  programStep: ProgramInfoStepType
  nextStep: EnrollmentStepType | undefined
}

const ProgramStepListItem: FC<ProgramStepListItemProps> = ({
  programId,
  locked,
  enrollmentStep,
  parentPathId,
  index,
  programStep,
  nextStep,
}) => {
  const { t } = useTranslation()

  const { schedule } = programStep

  const [hidePathSubContent, { toggle: togglePathSubContent }] = useToggle(false)

  const [upcomingSelfEnrollmentLiveSessions, setUpcomingSelfEnrollmentLiveSessions] = useState<
    StepUpcomingLiveSession[] | undefined
  >(
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
      enrollmentStep.content.__typename === 'NativeLiveCourse'
      ? (enrollmentStep.upcomingSelfEnrollmentLiveSessions ?? undefined)
      : undefined
  )

  const showLiveSessionList =
    !locked &&
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    (upcomingSelfEnrollmentLiveSessions?.length ?? 0) > 0

  const showCalendarEventList =
    !locked &&
    enrollmentStep.__typename === 'UserProgramCourseStep' &&
    enrollmentStep.content.__typename === 'NativeEventGroup' &&
    enrollmentStep.content.upcomingSelfEnrollmentCalendarEvents.length > 0

  const isPathStepWithSubSteps =
    enrollmentStep.__typename === 'UserProgramPathStep' && enrollmentStep.subSteps.length > 0

  const courseImage = getStepImage(enrollmentStep)

  const setIsEnrolled = React.useCallback(
    (sessionId: string, enroll: boolean): void => {
      if (enrollmentStep.__typename === 'UserProgramCourseStep' && upcomingSelfEnrollmentLiveSessions) {
        const upcomingSelfEnrollmentLiveSessionsToSet = upcomingSelfEnrollmentLiveSessions.map(s =>
          s.liveSession.liveSessionId === sessionId ? { ...s, isEnrolled: enroll } : s
        )
        setUpcomingSelfEnrollmentLiveSessions(upcomingSelfEnrollmentLiveSessionsToSet)
      }
    },
    [upcomingSelfEnrollmentLiveSessions, enrollmentStep.__typename]
  )

  const { availableAt } = enrollmentStep

  const buildTooltip = useCallback((): string | null => {
    if (!locked) {
      return null
    }

    if (isDefined(availableAt)) {
      const days = Math.ceil(DateTime.fromISO(availableAt).diffNow('days').days)
      return t('program-overview.details.available.on-date.tooltip-2', { count: days })
    }

    if (!isDefined(schedule)) {
      // TODO: is this an actual case we need to handle?
      return null
    }

    const scheduleType = schedule.__typename
    const previousIndex = index - 1 + 1 // Use a 1-based step index in presentation

    switch (scheduleType) {
      case 'Directly':
      case 'RelativeDate': {
        const days = scheduleType === 'Directly' ? 0 : schedule.offset.value

        if (days > 0) {
          return t('manage.programs.learner.step-available.relative-delayed', { step: previousIndex, days })
        } else {
          return t('manage.programs.learner.step-available.relative', { step: previousIndex })
        }
      }
      case 'OnCompletionOfPrevious': {
        const days = schedule.offset.value

        if (days > 0) {
          return t('manage.programs.learner.step-available.previous-completion-delayed', {
            step: previousIndex,
            days,
          })
        } else {
          return t('manage.programs.learner.step-available.previous-completion', { step: previousIndex })
        }
      }
      case 'AbsoluteDate': {
        throw Error('Scheduling is absolute, but `availableAt` is undefined')
      }

      default:
        assertNever(scheduleType)
    }
  }, [t, locked, availableAt, schedule, index])

  const tooltip = buildTooltip()
  const isNextStep = isDefined(nextStep) ? isSameEnrollmentStep(enrollmentStep, nextStep) : false

  return (
    <>
      <Tooltip title={tooltip}>
        <EnrolledStepContent
          step={enrollmentStep}
          programId={programId}
          displayIndex={index}
          locked={locked}
          parentPathId={parentPathId}
          upcomingSelfEnrollmentLiveSessions={upcomingSelfEnrollmentLiveSessions}
          subContentControls={{ togglePathSubContent, collapseButton: hidePathSubContent }}
          isNextStep={isNextStep}
        />
      </Tooltip>
      {(showLiveSessionList || showCalendarEventList || isPathStepWithSubSteps) && (
        <StepSubContent>
          {showLiveSessionList && (
            <SelfEnrollSessionListLayout>
              <SelfEnrollLiveSessionList
                courseId={enrollmentStep.courseId}
                courseImage={courseImage ?? undefined}
                setIsEnrolled={setIsEnrolled}
                sessions={
                  upcomingSelfEnrollmentLiveSessions?.map(userLiveSession => ({
                    title: userLiveSession.liveSession.title,
                    startTime: userLiveSession.liveSession.startTime,
                    endTime: userLiveSession.liveSession.endTime,
                    allDay: userLiveSession.liveSession.allDay,
                    isEnrolled: userLiveSession.isEnrolled,
                    usedSpots: userLiveSession.usedSpots ?? 0,
                    totalSpots: userLiveSession.liveSession.totalSpots ?? undefined,
                    sessionId: userLiveSession.liveSession.liveSessionId,
                    location: mapLiveSessionLocation(userLiveSession.liveSession.location),
                  })) ?? []
                }
              />
            </SelfEnrollSessionListLayout>
          )}
          {showCalendarEventList && (
            <SelfEnrollSessionListLayout>
              <SelfEnrollCalendarEvents
                sessions={
                  enrollmentStep.content.__typename === 'NativeEventGroup'
                    ? enrollmentStep.content.upcomingSelfEnrollmentCalendarEvents
                    : undefined
                }
                courseId={enrollmentStep.courseId}
                eventGroupId={enrollmentStep.courseId}
                courseImage={courseImage ?? undefined}
              />
            </SelfEnrollSessionListLayout>
          )}
          {isPathStepWithSubSteps && (
            <PathSubCourses $collapsed={hidePathSubContent}>
              <PathLine />
              <Wrapper $hidden={hidePathSubContent}>
                {enrollmentStep.subSteps.map(subStep => {
                  const isSubStepNextStep = isDefined(nextStep)
                    ? isSameEnrollmentStep(subStep, nextStep)
                    : false

                  return (
                    <ProgramPathStep
                      key={enrollmentStep.pathId}
                      locked={locked}
                      pathId={enrollmentStep.pathId}
                      programId={programId}
                      step={subStep}
                      isNextStep={isSubStepNextStep}
                    />
                  )
                })}
              </Wrapper>
            </PathSubCourses>
          )}
        </StepSubContent>
      )}
    </>
  )
}

type BasicStep = {
  title: string
  image: ImageUnion | undefined
  duration: string
  contentType: CourseKind | 'path' | 'program' | undefined
  description?: string | null
  assetContext: AssetContext
}

type CourseListItemBasicProps = {
  step: BasicStep
  subSteps: BasicStep[]
  displayIndex?: number
}

const CourseStepListItemBasic: FC<CourseListItemBasicProps> = ({ step, subSteps, displayIndex }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const imageSrc = useResolveAsset({
    image: step.image,
    size: 'course-sm',
    assetContext: step.assetContext,
  })

  return (
    <>
      <Tooltip title={t('program-overview.details.available.on.enrollment.tooltip')}>
        <StepContentContainer locked={true} href={null}>
          <ImageContainer>
            {isDefined(step.contentType) && !isMobile && <StepContentLabel contentType={step.contentType} />}
            <SanaImage src={imageSrc} ratio='16:9' rounded radius='size-20' />
          </ImageContainer>

          <StepInfoContainer marginLeft='24' direction='column' paddingTop={isMobile ? undefined : '8'}>
            <StepInfoHeader>
              <View direction='column'>
                <HeadingContainer>
                  <TextMaxLines size={isMobile ? 'small' : 'regular'} bold lines={1} title={step.title}>
                    {isDefined(displayIndex) ? `${displayIndex + 1}. ${step.title}` : step.title}
                  </TextMaxLines>
                </HeadingContainer>

                <StepMetaDataBasic duration={step.duration} />
              </View>
            </StepInfoHeader>
            {isNonEmptyString(step.description) && !isMobile && (
              <InternalTruncatedText color='foreground/secondary' lines={3}>
                {step.description.replace(/<[^>]*>?/gm, '')}
              </InternalTruncatedText>
            )}
          </StepInfoContainer>
        </StepContentContainer>
      </Tooltip>
      {subSteps.length > 0 && (
        <StepSubContent>
          <PathSubCourses $collapsed={false}>
            <PathLine />
            <Wrapper $hidden={false}>
              {subSteps.map((subStep, i) => (
                <CourseStepListItemBasic key={`course${i}`} step={subStep} subSteps={[]} />
              ))}
            </Wrapper>
          </PathSubCourses>
        </StepSubContent>
      )}
    </>
  )
}

//todo: check if there are functions for this already, and move out
export const getEnrollmentStepTypeId = (step: EnrollmentStepType): string => {
  switch (step.__typename) {
    case 'UserProgramCourseStep':
      return step.courseId
    case 'UserProgramPathStep':
      return step.pathId
    case 'UserProgramEmailStep':
      return step.resourceId
    default:
      assertNever(step)
  }
}

export const getProgramInfoStepTypeId = (step: ProgramInfoStepType): string => {
  switch (step.__typename) {
    case 'CourseProgramStep':
      return step.courseId
    case 'EmailProgramStep':
      return step.resourceId
    case 'PathProgramStep':
      return step.pathId
    default:
      assertNever(step)
  }
}

const FoldingSection = styled(motion.div).attrs({
  variants: {
    open: {
      height: 'auto',
      opacity: 1,
      filter: 'blur(0px)',
      y: 0,
    },
    closed: {
      height: 0,
      opacity: 0,
      filter: 'blur(6px)',
      y: -4,
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    duration: 0.2,
  },
})`
  overflow: hidden;
`

const IconContainer = styled.div`
  background: ${token('success/background')};
  color: ${token('success/foreground')};
  width: 1rem;
  height: 1rem;
  font-size: 10px;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
`

const SuccessIcon: React.FC = () => (
  <IconContainer>
    <Icon color='currentColor' iconId='checkmark' size='size-10' />
  </IconContainer>
)

const Section: FCC<{
  title?: string
  description?: string | null
  firstInOutline: boolean
  sectionCompleted?: boolean
  noOfSteps: number
}> = ({ title, description, children, firstInOutline, sectionCompleted, noOfSteps }) => {
  const [open, { toggle, set }] = useToggle(isDefined(sectionCompleted) ? !sectionCompleted : true)

  useEffect(() => {
    if (!isDefined(sectionCompleted)) return
    set(!sectionCompleted)
  }, [sectionCompleted, set])

  const { t } = useTranslation()

  return (
    <SectionView $firstInOutline={firstInOutline}>
      <header>
        <View direction='column'>
          <View>
            {!open && isDefined(sectionCompleted) && sectionCompleted && (
              <Fading delay={0.07}>
                <SuccessIcon />
              </Fading>
            )}
            <View gap='16'>
              <Sliding>
                <Text size='large' bold>
                  {title}
                </Text>
              </Sliding>
              {!open && (
                <Fading>
                  <Text size='regular' color='foreground/muted'>
                    {t('program.overview.section.title.no-of-steps', { count: noOfSteps })}
                  </Text>
                </Fading>
              )}
            </View>
          </View>

          {isNonEmptyString(description) && (
            <AnimatePresence>
              {open && (
                <motion.div
                  variants={{
                    open: {
                      height: 'auto',
                      opacity: 1,
                      filter: 'blur(0px)',
                      y: 0,
                    },
                    closed: {
                      height: 0,
                      opacity: 0,
                      filter: 'blur(6px)',
                      y: -8,
                    },
                  }}
                  initial='closed'
                  animate='open'
                  exit='closed'
                  transition={{
                    duration: 0.2,
                  }}
                >
                  <Text color='foreground/secondary'>{description}</Text>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </View>

        <IconButton
          variant='transparent'
          size='small'
          tooltip={open ? 'Collapse section' : 'Expand section'} // TODO: add translations
          iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
          onClick={toggle}
        />
      </header>

      <AnimatePresence>
        {open && (
          <FoldingSection>
            {isNonEmptyString(description)}
            {children}
          </FoldingSection>
        )}
      </AnimatePresence>
    </SectionView>
  )
}

export const ProgramOutlineStepList: FC<{ enrollment: EnrollmentData; programInfo: ProgramInfo }> = ({
  enrollment,
  programInfo,
}) => {
  const sectionsFeatureEnabled = getFlag('programs/sections')

  const clusters = clusterSectionsLearner(programInfo)

  if (enrollment === undefined || enrollment === null) {
    /**
     * When the user is not yet enrolled in the program
     */

    return clusters.map((cluster, clusterIndex) => {
      const sectionIndex = cluster[0]?.sectionIndex ?? null
      const clusterKey = sectionIndex ?? clusterIndex
      const sectionTitle = isDefined(sectionIndex) ? programInfo.sections[sectionIndex]?.title : ''
      const sectionDescription = isDefined(sectionIndex)
        ? programInfo.sections[sectionIndex]?.description
        : ''

      return (
        <ConditionalWrapper
          key={clusterKey}
          condition={sectionsFeatureEnabled && isDefined(sectionIndex)}
          renderWrapper={children => (
            <Section
              title={sectionTitle}
              description={sectionDescription}
              firstInOutline={clusterIndex === 0}
              noOfSteps={cluster.length}
            >
              {children}
            </Section>
          )}
          renderWrapperElse={children => (
            <View direction='column' style={{ paddingBlock: '20px' }}>
              {children}
            </View>
          )}
        >
          <ol>
            {cluster.map((step: ProgramInfoStepType, index: number) => {
              const stepOutlineIndex = programInfo.steps
                .filter(step => step.__typename !== 'EmailProgramStep')
                .findIndex(s => getProgramInfoStepTypeId(s) === getProgramInfoStepTypeId(step))

              if (step.__typename === 'EmailProgramStep') {
                return null
              }

              const contentType = programInfoStepAsCourseKind(step)
              const { content } = step

              if (content === undefined || content === null) {
                return null
              }

              const basicStep = {
                title: content.title,
                image: convertGQLImage(content.image),
                duration: content.duration,
                contentType: contentType,
                description: content.description,
                assetContext: programInfoStepAssetContext(step),
              }

              const basicSubSteps =
                step.__typename === 'PathProgramStep'
                  ? (step.path?.courses.map(
                      course =>
                        ({
                          title: course.title,
                          image: convertGQLImage(course.image),
                          duration: course.duration,
                          contentType: courseTypeNameToCourseKind[course.courseKind],
                          description: content.description,
                          assetContext: { type: 'course', courseId: course.courseId },
                        }) as BasicStep
                    ) ?? [])
                  : []

              return (
                <StepContainer as='li' key={index} hasSubContent={basicSubSteps.length > 0}>
                  <CourseStepListItemBasic
                    step={basicStep}
                    subSteps={basicSubSteps}
                    displayIndex={stepOutlineIndex}
                  />
                </StepContainer>
              )
            })}
          </ol>
        </ConditionalWrapper>
      )
    })
  }

  /**
   * When the user is enrolled in the program
   */

  const { nextStep } = getNextStepForProgram(enrollment)

  return clusters.map((cluster, clusterIndex) => {
    const sectionIndex = cluster[0]?.sectionIndex ?? null
    const clusterKey = sectionIndex ?? clusterIndex
    const sectionTitle = isDefined(sectionIndex) ? programInfo.sections[sectionIndex]?.title : ''
    const sectionDescription = isDefined(sectionIndex) ? programInfo.sections[sectionIndex]?.description : ''

    let sectionCompleted = true
    if (isDefined(sectionIndex)) {
      for (const step of cluster) {
        const enrollmentStep = enrollment.steps.find(
          s => getEnrollmentStepTypeId(s) === getProgramInfoStepTypeId(step)
        )

        if (
          enrollmentStep?.__typename === 'UserProgramCourseStep' ||
          enrollmentStep?.__typename === 'UserProgramPathStep'
        ) {
          const completed = enrollmentStep.progress.progress === 1
          if (!completed) {
            sectionCompleted = false
            break
          }
        }
      }
    }

    return (
      <ConditionalWrapper
        key={clusterKey}
        condition={sectionsFeatureEnabled && isDefined(sectionIndex)}
        renderWrapper={children => (
          <Section
            title={sectionTitle}
            description={sectionDescription}
            firstInOutline={clusterIndex === 0}
            sectionCompleted={sectionCompleted}
            noOfSteps={cluster.length}
          >
            {children}
          </Section>
        )}
        renderWrapperElse={children => (
          <View direction='column' style={{ paddingBlock: '20px' }}>
            {children}
          </View>
        )}
      >
        <ol>
          {cluster.map((step: ProgramInfoStepType, index: number) => {
            const stepOutlineIndex = programInfo.steps
              .filter(step => step.__typename !== 'EmailProgramStep')
              .findIndex(s => getProgramInfoStepTypeId(s) === getProgramInfoStepTypeId(step))

            const enrollmentStep = enrollment.steps.find(
              s => getEnrollmentStepTypeId(s) === getProgramInfoStepTypeId(step)
            )

            if (enrollmentStep !== undefined) {
              const hasLiveSessions =
                enrollmentStep.__typename === 'UserProgramCourseStep'
                  ? isNonNullable(enrollmentStep.upcomingSelfEnrollmentLiveSessions) &&
                    enrollmentStep.upcomingSelfEnrollmentLiveSessions.length > 0
                  : false

              const hasCalendarEvents =
                enrollmentStep.__typename === 'UserProgramCourseStep'
                  ? enrollmentStep.content.__typename === 'NativeEventGroup' &&
                    enrollmentStep.content.upcomingSelfEnrollmentCalendarEvents.length > 0
                  : false

              const hasSubSteps =
                enrollmentStep.__typename === 'UserProgramPathStep' && enrollmentStep.subSteps.length > 0
              const locked =
                enrollmentStep.availableAt === null ||
                enrollmentStep.availableAt === undefined ||
                DateTime.fromISO(enrollmentStep.availableAt).diffNow().toMillis() > 0

              return (
                <StepContainer
                  key={index}
                  as='li'
                  aria-disabled={locked}
                  hasSubContent={hasLiveSessions || hasCalendarEvents || hasSubSteps}
                >
                  <ProgramStepListItem
                    index={stepOutlineIndex}
                    enrollment={enrollment}
                    enrollmentStep={enrollmentStep}
                    programId={programInfo.id}
                    locked={locked}
                    programStep={step}
                    nextStep={nextStep}
                  />
                </StepContainer>
              )
            }
          })}
        </ol>
      </ConditionalWrapper>
    )
  })
}

/* Redirect user to 404 if the program can't be found (it means no access) */
const useProgramOverviewAccessible = ({
  isLoading,
  programExists,
  hasAccessToProgram,
}: {
  isLoading: boolean
  programExists: boolean
  hasAccessToProgram: boolean
}): void => {
  const isAccessible = programExists && hasAccessToProgram

  useLayoutEffect(() => {
    if (!isLoading && !isAccessible) {
      void notFound404Redirect()
    }
  }, [isLoading, isAccessible])
}

/**
 * The white box on top of the hero image that scrolls with the page.
 */
const ProgramDetails: FC<{
  enrollment: EnrollmentData
  programInfo: ProgramInfo
  isSelfEnrollable: boolean
  onSelfEnroll: () => void
}> = ({ enrollment, programInfo, isSelfEnrollable, onSelfEnroll }) => {
  const { t } = useTranslation()

  const detailRef = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [negativeMargin, setNegativeMargin] = useState(0)

  // This is basically just copied from the path-page.
  // A lot of magic numbers and not really clear what it is doing.
  // It has something to do with vertically aligning the details box on the hero
  // image and enabling it to scroll with the page.
  useLayoutEffect(() => {
    if (typeof window === 'undefined') return

    const updateMarginAndOffset = (): void => {
      if (detailRef.current) {
        const height = detailRef.current.clientHeight / 2 + 112
        setOffsetHeight(height)
      }

      // Calculate to see if in between smallest and largest hero height
      const heightSpan = _.clamp(window.innerWidth * 0.47, 576, 736)
      setNegativeMargin(heightSpan / 2 + 32)
    }

    updateMarginAndOffset()

    window.addEventListener('resize', updateMarginAndOffset)

    return () => window.removeEventListener('resize', updateMarginAndOffset)
  }, [detailRef, setOffsetHeight, setNegativeMargin])

  const isEnrolled = Boolean(enrollment)
  const hasStarted = (enrollment?.progress.progress ?? 0) > 0
  const completed = Boolean(enrollment?.progress.passedAt)
  const dueDate: string | undefined = undefined
  const { daysLeft } = useGetDaysLeft(dueDate)

  const { nextStep, index } = getNextStepForProgram(enrollment)

  const continueHref =
    nextStep !== undefined
      ? getContinueURLForStep({
          step: nextStep,
          upcomingSelfEnrollmentLiveSessions: undefined,
          programId: programInfo.id,
          parentPathId: undefined,
        })
      : undefined

  const formattedTimeLeft = useFormatDuration(
    enrollment?.progress.timeLeft ?? programInfo.duration,
    hasStarted
  )

  return (
    <OverviewDetails
      ref={detailRef}
      $offsetHeight={offsetHeight}
      $negativeMargin={negativeMargin}
      $largeMobileMargin={true}
    >
      <DetailsTop>
        <Text size='regular' bold>
          {t('program-overview.details.heading')}
        </Text>
        {enrollment !== undefined && enrollment !== null && (
          <ProgressLineWrapper>
            <ProgressLine $large $progress={enrollment.progress.progress} />
          </ProgressLineWrapper>
        )}
        <View gap='8' direction='column'>
          <IconListItem
            faded={false}
            color='foreground/secondary'
            iconId='browse'
            text={t('program-overview.details.n-step-program', {
              count: enrollment?.steps.length ?? programInfo.steps.length,
            })}
          />

          <IconListItem
            faded={false}
            color='foreground/secondary'
            iconId={completed ? 'checkmark' : 'time'}
            text={completed ? t('dictionary.completed') : formattedTimeLeft}
          />

          {daysLeft !== undefined && !completed && (
            <>
              <IconListItem
                faded={false}
                color='foreground/secondary'
                iconId='event--schedule'
                text={daysLeft}
              />
            </>
          )}
        </View>

        {isEnrolled && isDefined(nextStep) && isDefined(continueHref) && (
          <NextStepInfo
            step={nextStep}
            href={continueHref}
            hasStartedProgram={hasStarted}
            displayIndex={index}
          />
        )}
        {!isEnrolled && isSelfEnrollable && (
          <ProgramInfoButtonWrapper>
            <Button grow onClick={onSelfEnroll}>
              {t('dictionary.enroll')}
            </Button>
          </ProgramInfoButtonWrapper>
        )}
      </DetailsTop>
      <DetailsBottom></DetailsBottom>
    </OverviewDetails>
  )
}

/**
 * The description of the program.
 */
const ProgramDescription: FC<{ programInfo: ProgramInfo }> = ({ programInfo }) => {
  const { t } = useTranslation()
  const description = programInfo.description

  if (!isNonEmptyString(description)) return null

  return (
    <>
      <Text bold size='small' color='foreground/secondary'>
        {t('program-overview.description.title')}
      </Text>
      <Spacer size='8' />
      <EllipsedText
        text={description}
        textSize='large'
        minimizeText={t('program-overview.description-show-less')}
        expandText={t('program-overview.description-show-more')}
      />
    </>
  )
}

/**
 * Step outline of the program.
 */
const ProgramOutline: FC<{ enrollment: EnrollmentData; programInfo: ProgramInfo }> = ({
  enrollment,
  programInfo,
}): JSX.Element => {
  const domId = useGenerateDomId()
  return (
    <section aria-labelledby={domId} data-testid='program-outline'>
      <ProgramOutlineStepList enrollment={enrollment} programInfo={programInfo} />
    </section>
  )
}

export const ProgramPage: FC<{ programId: string }> = ({ programId }) => {
  const enrollmentQuery = useGetLearnerProgram({ programId })

  const selfEnrollToProgram = useMutation({
    mutationFn: (variables: SelfEnrollToProgramMutationVariables) =>
      graphQuery(selfEnrollToProgramMutation, variables),
    onSettled: () => enrollmentQuery.refetch(),
  })

  const enrollment = enrollmentQuery.data?.me.enrollment
  const program = isDefined(enrollment) ? enrollment.program : enrollmentQuery.data?.program
  const isLoading = enrollmentQuery.isPending
  const contentAttributeValues = useContentAttributeValues()
  const contentAttributesEnabled = getFlag('content-attributes')

  // No data means that the learner cannot access the program and hence is not visible
  // Also, we don't want to show non-public programs to admins if they are not enrolled
  const isSelfEnrollable = program?.visibility === 'VISIBLE'

  const onSelfEnroll = (): void => {
    selfEnrollToProgram.mutate({ programId })
  }

  useProgramOverviewAccessible({
    isLoading: isLoading,
    programExists: enrollmentQuery.isSuccess,
    hasAccessToProgram: program !== undefined,
  })

  const assetContext: AssetContext = useMemo(
    () => ({ type: 'program', programId: ProgramId.parse(programId) }),
    [programId]
  )
  const programImage = useResolveAsset({
    image: program ? convertGQLImage(program.image) : undefined,
    assetContext,
    size: 'default',
  })

  if (isLoading || !isDefined(program)) {
    return (
      <View direction='column' gap='24'>
        <Skeleton $width='100%' $height={700} $radius={8} />

        <View direction='column' gap='24' padding='64'>
          <Skeleton $width='50%' $height={100} $radius={8} />
          <Skeleton $width='50%' $height={100} $radius={8} />
          <Skeleton $width='50%' $height={100} $radius={8} />
          <Skeleton $width='50%' $height={100} $radius={8} />
          <Skeleton $width='50%' $height={100} $radius={8} />
          <Skeleton $width='50%' $height={100} $radius={8} />
        </View>
      </View>
    )
  }

  return (
    <>
      <PageTitle title={program.name} />
      <OverviewHeader
        image={programImage}
        title={program.name}
        isRequiredAssignment={enrollment?.isRequiredAssignment === true}
      />
      <OverviewGrid>
        <ProgramDetails
          enrollment={enrollment}
          programInfo={program}
          isSelfEnrollable={isSelfEnrollable}
          onSelfEnroll={onSelfEnroll}
        />
        <ProgramPageContentWrapper>
          <ProgramDescription programInfo={program} />
          <ProgramOutline enrollment={enrollment} programInfo={program} />
          {contentAttributesEnabled && isDefined(contentAttributeValues.query.data) && (
            <>
              <Separator top='12' bottom='48' />
              <LearnerAttributesRenderer attributes={contentAttributeValues.query.data} />
            </>
          )}
        </ProgramPageContentWrapper>
      </OverviewGrid>
    </>
  )
}
