import { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ContentAttributeInfo } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { AttributesForm } from 'sierra-client/views/course-settings/tabs/attributes-tab/form'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { useContentAttributes } from 'sierra-client/views/manage/content-attributes/hooks'
import { GQL2CAT } from 'sierra-client/views/manage/content-attributes/types'
import { Skeleton, View } from 'sierra-ui/primitives'

const getAttributesForCourse = graphql(`
  query getAttributesForCourse($courseId: CourseId!) {
    course(id: $courseId) {
      attributes {
        def {
          id
          description
          name
          index
          learnerVisible
          type {
            __typename
          }
        }
        values
      }
    }
  }
`)

export const AttributesTab: SettingsTabComponent = ({ courseId, onSave, onClose }) => {
  const { query: attributesData } = useContentAttributes()
  const { data, isLoading } = useGraphQuery(
    {
      document: getAttributesForCourse,
    },
    { courseId }
  )

  const invalidateGetAttributesForCourse = useInvalidateGraphQuery(getAttributesForCourse)

  const attributes = useMemo(() => {
    //const tagsAsCategories = tags2Categories(attributesData.data?.contentAttributesSchema.tagsDef ?? [])
    const contentAttributes = attributesData.data?.contentAttributesSchema.customAttributeDefs.map(attr =>
      GQL2CAT(attr)
    )

    return contentAttributes ?? []
  }, [attributesData.data?.contentAttributesSchema])

  if (isLoading || attributesData.isLoading) {
    return (
      <View direction='column' gap='24'>
        <Skeleton $width='100%' $height='68px' $radius='12px' />
        <Skeleton $width='100%' $height='68px' $radius='12px' />
        <Skeleton $width='100%' $height='68px' $radius='12px' />
        <Skeleton $width='100%' $height='68px' $radius='12px' />
      </View>
    )
  }

  return (
    <AttributesForm
      courseId={courseId}
      attributes={attributes}
      courseAttributes={data?.course?.attributes as ContentAttributeInfo[]}
      onSave={() => {
        void invalidateGetAttributesForCourse()
        onSave()
      }}
      onClose={onClose}
    />
  )
}
