import { motion, useSpring, useTransform } from 'framer-motion'
import React, { useCallback, useRef, useState } from 'react'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useUser } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useMatrixData } from 'sierra-client/views/v3-author/matrix/data-layer'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { MatrixPosition } from 'sierra-domain/api/strategy-v2'
import { UserId } from 'sierra-domain/api/uuid'
import { color } from 'sierra-ui/color'
import { RoundAvatar } from 'sierra-ui/components'
import { getElevatedBoxShadowStyles } from 'sierra-ui/components/elevation/elevated-surface-styles'
import { Spacer, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const MOBILE_INSET = 8
const DESKTOP_INSET = 24

type MatrixPositionPercentage = { topPercent: number; leftPercent: number }
const matrixPositionToPercentage = (position: MatrixPosition): MatrixPositionPercentage => ({
  topPercent: (1 - (position.y + 100) / 200) * 100,
  leftPercent: ((position.x + 100) / 200) * 100,
})

const matrixPercentageToPosition = (percentage: MatrixPositionPercentage): MatrixPosition => ({
  x: (percentage.leftPercent / 100) * 200 - 100,
  y: (1 - percentage.topPercent / 100) * 200 - 100,
})

const Container = styled.div<{ $disableUserSelect: boolean }>`
  padding: 0;
  user-select: ${p => (p.$disableUserSelect ? 'none' : 'auto')};
  touch-action: none;
`

const LeftRightOverFlowCss = css`
  max-height: 100%;
  width: fit-content;
  max-width: 20ch;
`

const TopBottomOverFlowCss = css`
  max-height: 50px;
  width: fit-content;
  max-width: 100%;
`

const OptionsContainer = styled.div<{ $mobile: boolean }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: ${p => (p.$mobile ? '3 / 4' : '16 / 9')};
  padding: ${p => (p.$mobile ? MOBILE_INSET : DESKTOP_INSET)}px;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.03)};
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  grid-template-areas: 'a b' 'd c';

  > * {
    &:first-child {
      ${p =>
        p.$mobile
          ? css`
              grid-area: a;
              text-align: start;
              align-self: end;
              justify-self: start;
              transform: translateY(50%) translateX(-50%) rotate(-90deg) translateY(${MOBILE_INSET}px);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: a;
              text-align: start;
              align-self: end;
              justify-self: start;
              transform: translateY(50%);
              ${LeftRightOverFlowCss}
            `}
    }

    &:nth-child(2) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: b;
              text-align: center;
              align-self: start;
              justify-self: start;
              transform: translateX(-50%);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: b;
              text-align: center;
              align-self: start;
              justify-self: start;
              transform: translateX(-50%);
              ${TopBottomOverFlowCss}
            `}
    }

    &:nth-child(3) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: c;
              align-self: start;
              justify-self: end;
              text-align: right;
              transform: translateY(-50%) translateX(50%) rotate(90deg) translateY(${MOBILE_INSET}px);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: c;
              align-self: start;
              justify-self: end;
              text-align: right;
              transform: translateY(-50%);
              ${LeftRightOverFlowCss}
            `}
    }

    &:nth-child(4) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: d;
              text-align: center;
              align-self: end;
              justify-self: end;
              transform: translateX(50%);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: d;
              text-align: center;
              align-self: end;
              justify-self: end;
              transform: translateX(50%);
              ${TopBottomOverFlowCss}
            `}
    }
  }
`

const MatrixInnerContainer = styled.div<{ $inset: number; $isReadOnly: boolean }>`
  position: absolute;
  inset: ${p => p.$inset}px;
  width: calc(100% - ${p => p.$inset * 2}px);
  height: calc(100% - ${p => p.$inset * 2}px);
  overflow: visible;
  cursor: ${p => (p.$isReadOnly ? 'default' : 'pointer')};
`

const SVGContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const SVG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  z-index: -1;

  line {
    stroke: ${token('form/border/2')};
  }
`

const getMatrixPosition = (event: MouseEvent, containerRect: DOMRect): MatrixPosition | undefined => {
  const x = event.clientX - containerRect.left
  const y = event.clientY - containerRect.top

  const normalizedX = (x / containerRect.width) * 200 - 100
  const normalizedY = (1 - y / containerRect.height) * 200 - 100

  const matrixPosition = MatrixPosition.safeParse({ x: normalizedX, y: normalizedY })
  return matrixPosition.success ? matrixPosition.data : undefined
}

const DashedCross: React.FC = () => {
  return (
    <SVGContainer contentEditable={false}>
      <SVG width='100%' height='100%'>
        <line
          x1='0'
          y1='50%'
          x2='100%'
          y2='50%'
          strokeDasharray='2, 8'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </SVG>
      <SVG width='100%' height='100%'>
        <line
          x1='50%'
          y1='0'
          x2='50%'
          y2='100%'
          strokeDasharray='2, 8'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </SVG>
    </SVGContainer>
  )
}

const MatrixPositionContainer = styled(motion.div)`
  position: absolute;
`

export const CreateMatrix: FCC = ({ children }) => {
  const { t } = useTranslation()
  // The toolbar is rendered as a child of this component and it is positioned absolutely,
  // due to options container being relative, the toolbar is out of place, we therefore extract it
  // and render it outside
  const [optionsChildren, ...toolbarChild] = React.Children.toArray(children)

  return (
    <>
      <Text size='small' bold color='foreground/secondary' contentEditable={false}>
        {t('matrix.place-yourself')}
      </Text>
      <Spacer size='8' contentEditable={false} />
      <Container $disableUserSelect={false}>
        <OptionsContainer $mobile={false}>
          <RenderingContext withGrid={false}>{optionsChildren}</RenderingContext>
          <DashedCross />
        </OptionsContainer>
        {toolbarChild}
      </Container>
    </>
  )
}

const AvatarContainer = styled.div<{ $withShadow: boolean; $isDragging: boolean }>`
  border-radius: 50%;
  box-shadow: ${p =>
    p.$withShadow ? getElevatedBoxShadowStyles({ shadowSize: 'large', border: false }) : 'none'};

  transform: translate(-50%, -50%);
  scale: ${p => (p.$isDragging ? 0.9 : 0.7)};

  cursor: ${p => (p.$isDragging ? 'grabbing' : 'grab')};

  transition: scale 0.2s ease-in-out;
  transform-origin: top left;

  ${p =>
    p.$withShadow &&
    css`
      border: 4px solid ${token('surface/default')};
      outline-offset: 8px;
      border: 4px solid #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    `};
`

const MatrixAvatar: FCC<{
  userId?: UserId
  large?: boolean
  isDragging?: boolean
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
}> = ({ userId, large, isDragging, onMouseDown }) => {
  const user = useUser(userId)

  if (userId === undefined) {
    return <RoundAvatar size='small' firstName={'Anonymous'} />
  }

  if (user?.status === 'loaded') {
    return (
      <AvatarContainer
        $isDragging={isDragging === true}
        $withShadow={large === true}
        onMouseDown={onMouseDown}
      >
        {large === true ? (
          <RoundAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
            size='large'
          />
        ) : (
          <RoundAvatar
            size='medium'
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
          />
        )}
      </AvatarContainer>
    )
  }

  return null
}

export const LearnMatrix: FCC = ({ children }) => {
  const { t } = useTranslation()
  const { matrixData, userPosition, setUserPosition } = useMatrixData()
  const currentUser = useCurrentUser()
  const isMobile = useIsMobile()
  const [isDragging, setIsDragging] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const isReadOnly = setUserPosition === undefined

  const percentagePosition = userPosition ? matrixPositionToPercentage(userPosition) : undefined
  const topPercentMotionValue = useSpring(percentagePosition?.topPercent ?? 50, {
    stiffness: 500,
    damping: 30,
  })

  const leftPercentMotionValue = useSpring(percentagePosition?.leftPercent ?? 50, {
    stiffness: 500,
    damping: 30,
  })

  const topPercentSpring = useTransform(topPercentMotionValue, v => `${v}%`)
  const leftPercentSpring = useTransform(leftPercentMotionValue, v => `${v}%`)

  useOnChanged((_, curr) => {
    if (curr === undefined) return

    const percentagePosition = matrixPositionToPercentage(curr)

    topPercentMotionValue.set(percentagePosition.topPercent)
    leftPercentMotionValue.set(percentagePosition.leftPercent)
  }, userPosition)

  const handleOnMouseDown = useCallback(
    (evt: React.PointerEvent<HTMLDivElement>) => {
      const containerRect = containerRef.current?.getBoundingClientRect()

      if (containerRect === undefined) return

      setIsDragging(true)

      const handleOnMove = (moveEvent: PointerEvent): void => {
        const matrixPosition = getMatrixPosition(moveEvent, containerRect)

        if (matrixPosition) {
          const percentagePosition = matrixPositionToPercentage(matrixPosition)
          topPercentMotionValue.set(percentagePosition.topPercent)
          leftPercentMotionValue.set(percentagePosition.leftPercent)
        }
      }

      const handleOnRelease = (event: MouseEvent): void => {
        const matrixPosition = getMatrixPosition(event, containerRect)
        if (matrixPosition) {
          setUserPosition?.(matrixPosition)
        } else {
          const topPercent = topPercentMotionValue.get()
          const leftPercent = leftPercentMotionValue.get()
          setUserPosition?.(matrixPercentageToPosition({ topPercent, leftPercent }))
        }

        setIsDragging(false)
        window.removeEventListener('pointermove', handleOnMove)
        window.removeEventListener('pointerup', handleOnRelease)
      }

      window.addEventListener('pointerup', handleOnRelease)
      window.addEventListener('pointermove', handleOnMove, { passive: false })
      handleOnMove(evt.nativeEvent)
    },
    [leftPercentMotionValue, setUserPosition, topPercentMotionValue]
  )

  const userPositionPercentage = userPosition && matrixPositionToPercentage(userPosition)

  return (
    <>
      <Text size='small' bold color='foreground/secondary'>
        {t('matrix.place-yourself')}
      </Text>
      <Spacer size='8' contentEditable={false} />
      <Container $disableUserSelect={true}>
        <OptionsContainer $mobile={isMobile}>
          <RenderingContext withGrid={false}>{children}</RenderingContext>
          <DashedCross />
          <MatrixInnerContainer
            ref={containerRef}
            $inset={isMobile ? MOBILE_INSET : DESKTOP_INSET}
            $isReadOnly={isReadOnly}
            onPointerDown={isReadOnly ? undefined : handleOnMouseDown}
          >
            {matrixData?.responses.map(response => {
              const percentagePosition = matrixPositionToPercentage(response.position)
              return (
                <MatrixPositionContainer
                  layoutId={response.matrixResponseId}
                  initial={false}
                  transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 30,
                    duration: 0.5,
                  }}
                  animate={{
                    top: `${percentagePosition.topPercent}%`,
                    left: `${percentagePosition.leftPercent}%`,
                  }}
                  key={response.matrixResponseId}
                >
                  <MatrixAvatar userId={response.userId} />
                </MatrixPositionContainer>
              )
            })}
            {userPositionPercentage && currentUser.status === 'success' && (
              <MatrixPositionContainer
                layoutId='user'
                transition={{
                  type: 'spring',
                  bounce: 0.2,
                  duration: 0.5,
                }}
                style={{
                  top: topPercentSpring,
                  left: leftPercentSpring,
                }}
                animate={{
                  opacity: isDragging ? 0.8 : 1,
                }}
              >
                <MatrixAvatar isDragging={isDragging} large userId={currentUser.data?.uuid} />
              </MatrixPositionContainer>
            )}
          </MatrixInnerContainer>
        </OptionsContainer>
      </Container>
    </>
  )
}
