import { AnimatePresence, motion } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { useLiveSessionStateMutation } from 'sierra-client/api/hooks/use-live-session'
import { useIsGuestUser } from 'sierra-client/api/hooks/use-user'
import { Link } from 'sierra-client/components/common/link'
import { useLivePageContext } from 'sierra-client/components/liveV2/contexts/live-page'
import {
  useLiveSessionContext,
  useLiveSessionState,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { SessionSummaryContainer, Summary } from 'sierra-client/components/liveV2/summary'
import { useTemplates } from 'sierra-client/components/templates/use-templates'
import { flexibleContentTemplateFromTemplate } from 'sierra-client/components/templates/utils'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { DownloadDataButton } from 'sierra-client/features/sana-now'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { createFlexibleContentFromTemplate } from 'sierra-client/state/author-course-settings/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { Template } from 'sierra-client/state/templates/types'
import { CreateNewCourseButton } from 'sierra-client/views/manage/components/create-new-course-button'
import { FlexibleContentTemplate } from 'sierra-domain/api/author-v2'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeContentLiveSessionRecap } from 'sierra-domain/routes'
import { Tooltip } from 'sierra-ui/components'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import { palette, token, zIndex } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    & {
        #__next {
            overflow: visible;
        }
    }
`

const Header = styled(View)`
  width: 100%;
  z-index: 2;
  grid-column: 1 / -1;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0.75rem 0;
  justify-content: space-between;
`

const HeaderContainer = styled(SessionSummaryContainer)`
  background-color: ${token('surface/soft')};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`

const HeaderTitle = styled(Text)`
  display: none;

  ${minWidth.tablet} {
    display: block;
  }
`

const Footer = styled(View)`
  grid-column: 1 / -1;
  order: 3;
`

const CreatePushModal = styled(View)`
  display: none;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    display: flex;
  }

  position: fixed;
  z-index: ${zIndex.MODAL};
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${token('surface/default')};
  border: 1px solid ${token('border/default')};
  border-radius: 0.5rem;
  padding: 20px 24px;
  width: 420px;
  right: 1rem;
  bottom: 1rem;
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.38);
`

const StyledCreateNewCourseButton = styled(CreateNewCourseButton)`
  background-color: ${p => p.theme.color.grey85};
  color: ${palette.primitives.white};
  height: 36px;
  padding: 0 16px;
  border-radius: 10px;
  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
  border: none;

  &:hover {
    background-color: ${p => p.theme.color.grey80};
  }
`

const TemplatePreviews = styled(View)`
  padding-bottom: 0.75rem;
`

const useHasRecapAvailable = (liveSessionId?: LiveSessionId): boolean => {
  const [hasRecapAvailable, setHasRecapAvailable] = React.useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (liveSessionId === undefined) return

    void postWithUserErrorException(XRealtimeContentLiveSessionRecap, { liveSessionId }, dispatch, {
      notifications: false,
    })
      .then(() => {
        setHasRecapAvailable(true)
      })
      .catch(error => {
        if (error instanceof RequestError && error.status === 404) {
          setHasRecapAvailable(false)
        }
      })
  }, [liveSessionId, dispatch])

  return hasRecapAvailable
}

const hoverAnimation = css`
  transform: scale(1.03);
  transition: transform 0.3s ease;
`

const ImageFrame = styled.div<{ $borderOnHover?: boolean }>`
  position: relative;
  aspect-ratio: 21 / 16;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translateZ(0);

  border: ${p => (p.$borderOnHover === true ? '1px solid rgba(0,0,0,0.05)' : 'none')};

  &:hover {
    border: ${p => (p.$borderOnHover === true ? '1px solid rgba(0,0,0,0.15)' : 'none')};
  }
`

const StyledImage = styled.img<{ $animate?: boolean }>`
  aspect-ratio: 21 / 16;
  width: 100%;
  object-fit: cover;
  ${p => p.$animate === true && hoverAnimation}

  will-change: scale;
`

const RootView = styled(View)`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;

  &:hover ${StyledImage} {
    transform: scale(1);
  }
`

type TemplateMiniatureProps = {
  template: Template
  onClick: () => void
}

const MiniTemplatePreview: React.FC<TemplateMiniatureProps> = ({ template, onClick }) => {
  return (
    <RootView
      onClick={onClick}
      cursor='pointer'
      direction='column'
      alignItems='flex-start'
      position='relative'
    >
      <ImageFrame $borderOnHover={template.disableAnimation === true || template.imagePath === undefined}>
        <StyledImage
          $animate={template.disableAnimation !== true && template.imagePath !== undefined}
          src={template.imagePath ?? getCourseDefaultImage({ width: 640 })}
        />
      </ImageFrame>
    </RootView>
  )
}

const CreateNudgeModal = (): JSX.Element => {
  const isCreateAccessible = useIsCreateAccessible()
  const [showModal, setShowModal] = useState(true)
  const templates = useTemplates()
  const previewTemplates = templates.community
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const createFromTemplate = useCallback(
    async (template: FlexibleContentTemplate): Promise<void> => {
      if (!loading) {
        setLoading(true)
        void dispatch(createFlexibleContentFromTemplate({ flexibleContentTemplate: template }))
        void dispatch(Logging.liveSession.postSessionCreatePushModalTemplateClicked())
      }
    },
    [dispatch, loading]
  )

  useEffect(() => {
    void dispatch(Logging.liveSession.postSessionCreatePushModalViewed())
  }, [dispatch])

  return (
    <AnimatePresence>
      {isCreateAccessible && showModal && (
        <motion.div
          initial={{ y: -8, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 0, opacity: 0 }}
          transition={{ duration: 0.1, ease: [0.25, 0.1, 0.25, 1] }}
        >
          <CreatePushModal gap='16'>
            <IconButton
              iconId='close'
              onClick={() => {
                setShowModal(false)
                void dispatch(Logging.liveSession.postSessionCreatePushModalDismissed())
              }}
              variant='transparent'
            />
            <View direction='column' gap='4'>
              <Text size='small' bold>
                {t('recap.create-push.title')}
              </Text>
              <Text size='small' color='foreground/muted'>
                {t('recap.create-push.body')}
              </Text>
            </View>
            <TemplatePreviews>
              {previewTemplates.slice(0, 3).map(t => (
                <MiniTemplatePreview
                  key={t.id}
                  template={t}
                  onClick={() => {
                    void createFromTemplate(flexibleContentTemplateFromTemplate(t))
                  }}
                />
              ))}
            </TemplatePreviews>

            <View>
              <StyledCreateNewCourseButton
                label={t('dictionary.browse-templates')}
                onClick={() => {
                  void dispatch(Logging.liveSession.postSessionCreatePushModalCreateButtonClicked())
                }}
              />
            </View>
          </CreatePushModal>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const formatDate = (dateString: string): string | undefined => {
  const date = DateTime.fromISO(dateString)
  if (date.isValid) return date.toFormat("EEEE', 'MMMM' 'd' @ 't")
}

const SessionOngoingTitle = styled(Text).attrs({ size: 'large', bold: true })``

const SessionOngoingSubtitle = styled(Text).attrs({ size: 'small' })``

const SessionOngoing = ({ rejoin }: { rejoin: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const liveSession = useLiveSessionContext()

  return (
    <View grow alignItems='center' justifyContent='center'>
      <View direction='column' justifyContent='center' alignItems='center'>
        <SessionOngoingTitle>{t('live.post-session-page.session-ongoing-title')}</SessionOngoingTitle>
        <SessionOngoingSubtitle color='foreground/secondary'>
          {liveSession.data.type === 'scheduled' && formatDate(liveSession.data.startTime)}
        </SessionOngoingSubtitle>

        <View marginTop='12' justifyContent='center' alignItems='center'>
          <Button onClick={rejoin}>{t('dictionary.rejoin')}</Button>
        </View>
      </View>
    </View>
  )
}

export const SessionSummary: React.FC<{ goToPreLobby: () => void }> = props => {
  const { liveSessionId } = useLivePageContext()
  const liveSession = useLiveSessionContext()
  const tenantId = config.organization.tenantId
  const { data: isGuestUser } = useIsGuestUser()
  const isFacilitator = useSelector(selectIsFacilitator)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sessionState = useLiveSessionState()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const hasRecap = useHasRecapAvailable(liveSessionId) && liveSession.data.enableRecap === true
  const mutation = useLiveSessionStateMutation()

  useEffect(() => {
    void dispatch(Logging.liveSession.postSessionPageViewed({ pageVersion: 'session-summary-page' }))
  }, [dispatch])

  return (
    <>
      <GlobalStyles />
      <HeaderContainer>
        <Header grow>
          <View gap='16'>
            <HeaderTitle size='small' bold>
              {liveSession.data.title}
            </HeaderTitle>
          </View>
          <View direction='row' gap='8'>
            {isGuestUser === false && isFacilitatorOrLearnerLedSession && sessionState === 'ended' && (
              <Tooltip title='Open your session back up again'>
                <Button
                  icon='information'
                  variant='secondary'
                  onClick={() => {
                    mutation.mutate(
                      {
                        liveSessionId: liveSession.liveSessionId,
                        state: 'active',
                      },
                      {
                        onSuccess: () => {
                          void dispatch(liveSessionStateChanged('active'))
                        },
                      }
                    )
                  }}
                >
                  {t('live.reopen-session')}
                </Button>
              </Tooltip>
            )}
            {sessionState === 'active' && (
              <Button variant='secondary' onClick={props.goToPreLobby}>
                {t('dictionary.rejoin')}
              </Button>
            )}
            {sessionState === 'ended' && isGuestUser === false && hasRecap === true && (
              <Link next href={`/r/${liveSessionId}`}>
                <Button variant='primary' onClick={() => {}}>
                  {t('dictionary.view-content')}
                </Button>
              </Link>
            )}
          </View>
        </Header>
      </HeaderContainer>
      {sessionState === 'ended' && <Summary liveSessionId={liveSessionId} tenantId={tenantId} />}
      {sessionState === 'active' && <SessionOngoing rejoin={props.goToPreLobby} />}
      <SessionSummaryContainer>
        <Footer direction='column' alignItems='flex-end' paddingBottom='24' paddingTop='24' grow>
          <View>
            {isGuestUser === false && isFacilitator === true && sessionState === 'ended' && (
              <DownloadDataButton />
            )}
          </View>
        </Footer>
      </SessionSummaryContainer>
      <CreateNudgeModal />
    </>
  )
}
