import { AnimatePresence, motion } from 'framer-motion'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { aiTutorTodoT } from 'sierra-client/views/course-helper/tutor/ai-tutor-todo-t'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { SpinningIcon } from 'sierra-client/views/v3-author/qa-card/spinning-icon'
import { QuizEntry, QuizQuestion } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { useQuiz } from 'sierra-client/views/v3-author/qa-card/use-quiz'
import { iife } from 'sierra-domain/utils'
import {
  Button,
  HeadingPrimitive,
  IconButton,
  LoadingSpinner,
  Spacer,
  Text,
  View,
} from 'sierra-ui/primitives'
import { LightTokenProvider } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  scrollbar-gutter: stable;
  align-items: flex-start;
`

const FullHeightContainer = styled(View)`
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const QuestionHeader = styled(HeadingPrimitive)``

const QuestionAlternativeContainer = styled.div<{ paddingLeft: string }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 48px;
  padding-left: ${p => p.paddingLeft}px;
`

const QuestionAlternative = styled(motion.div)<{
  isSelected?: boolean
  wasCorrect?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 300px;
  border-radius: 48px;
  padding: 30.03px 33.78px;
  gap: 9.38px;
  backdrop-filter: blur(10px);
  background: ${props =>
      props.isSelected === true
        ? props.wasCorrect === true
          ? 'linear-gradient(180deg, rgba(0, 255, 0, 0.1) 0%, rgba(0, 255, 0, 0.3) 100%)'
          : 'linear-gradient(180deg, rgba(255, 0, 0, 0.1) 0%, rgba(255, 0, 0, 0.3) 100%)'
        : 'linear-gradient(180deg, rgba(205, 254, 0, 0) 0%, rgba(205, 254, 0, 0.3) 100%)'},
    linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  box-shadow:
    0px 22.52px 30.03px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0.85px rgba(0, 0, 0, 0.04);

  &:not(:first-child) {
    margin-right: -40px;
  }

  &:nth-child(odd) {
    transform: rotate(4.83deg);
    z-index: 1;
    transition: transform 0.1s ease-in-out;
  }

  &:nth-child(even) {
    transform: rotate(-4.83deg);
    z-index: 2;
    transition: transform 0.1s ease-in-out;
  }

  &:nth-child(odd):hover {
    transform: ${props => (props.disabled === false ? 'rotate(4.83deg) scale(1.05)' : 'rotate(4.83deg)')};
  }

  &:nth-child(even):hover {
    transform: ${props => (props.disabled === false ? 'rotate(-4.83deg) scale(1.05)' : 'rotate(-4.83deg)')};
  }

  &:hover {
    cursor: ${props => (props.disabled === false ? 'pointer' : 'not-allowed')};
    transform: ${props => (props.disabled === false ? 'scale(1.05)' : 'none')};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const QuestionCardContainer = styled(View)`
  min-width: 100%;
  max-width: 100%;
`

const QuestionAlternativeText = styled(Text)`
  font-size: 30px;
  line-height: 36.9px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 11%;
`

type QuizState = {
  currentQuestionIndex: number
  correctStreak: number
  totalAnswered: number
  answers: Array<{
    questionIndex: number
    selectedAnswer: string
    wasCorrect: boolean
  }>
  status: 'not-started' | 'in-progress' | 'completed'
}

const QuestionCard: React.FC<{
  question: QuizQuestion | 'placeholder'
  onAlternativeClick: (alternative: { text: string; correct: boolean }) => void
  previousAnswer:
    | {
        selectedAnswer: string
        wasCorrect: boolean
      }
    | undefined
}> = ({ question, onAlternativeClick, previousAnswer }) => {
  const questionTitle = iife(() => {
    if (question === 'placeholder' || question.questionTitle.trim().length === 0) {
      // Make sure the question is never empty so the header gets the correct height
      // with a non-breaking space
      return '\xa0'
    }
    return question.questionTitle
  })
  const isMobile = useIsMobile()

  const spacer = isMobile ? ('8' as const) : ('32' as const)

  return (
    <QuestionCardContainer direction='column' gap={'16'} justifyContent='center'>
      <View alignItems='flex-start' gap={spacer}>
        <View paddingTop={'4'} direction='column' gap='none'>
          <SpinningIcon
            iconId='sana-symbol'
            $isSpinning={question === 'placeholder' || question.status === 'is-generating'}
            size='size-24'
          />
        </View>
        <QuestionHeader bold size='h4'>
          {questionTitle}
        </QuestionHeader>
      </View>

      <QuestionAlternativeContainer paddingLeft={spacer}>
        <LightTokenProvider>
          <AnimatePresence>
            {
              /* We shouldn't show the alternatives until the question has finished generating */
              question !== 'placeholder' && question.status === 'is-finished-generating' ? (
                <>
                  {question.alternatives.map((alternative, index) => {
                    const isSelected = previousAnswer?.selectedAnswer === alternative.text

                    return (
                      <QuestionAlternative
                        key={alternative.text}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1, delay: index * 0.1 } }}
                        exit={{ opacity: 0, transition: { duration: 0 } }}
                        disabled={!!previousAnswer}
                        onClick={() => !previousAnswer && onAlternativeClick(alternative)}
                        isSelected={isSelected}
                        wasCorrect={isSelected ? previousAnswer.wasCorrect : undefined}
                      >
                        <QuestionAlternativeText>{alternative.text}</QuestionAlternativeText>
                      </QuestionAlternative>
                    )
                  })}
                </>
              ) : (
                <LoadingSpinner />
              )
            }
          </AnimatePresence>
        </LightTokenProvider>
      </QuestionAlternativeContainer>
    </QuestionCardContainer>
  )
}

const EndScreen: React.FC<{
  answers: QuizState['answers']
  totalQuestions: number
  onRestart: () => void
}> = ({ answers, totalQuestions, onRestart }) => {
  const correctAnswers = answers.filter(a => a.wasCorrect).length

  const isMobile = useIsMobile()

  const spacer = isMobile ? ('8' as const) : ('32' as const)

  return (
    <FullHeightContainer direction='column' gap={'16'}>
      <View gap={spacer}>
        <SpinningIcon iconId='sana-symbol' $isSpinning={false} size='size-24' />
        <QuestionHeader bold size='h4'>
          Quiz Complete!
        </QuestionHeader>
      </View>
      <View direction='row' gap={spacer}>
        <Spacer size='24' />
        <View direction='column'>
          <Text bold size='large'>
            You got {correctAnswers} out of {totalQuestions} correct
          </Text>
          <Text size='small' color={'foreground/muted'}>
            Accuracy: {Math.round((correctAnswers / totalQuestions) * 100)}%
          </Text>
          <Button variant='primary' onClick={onRestart}>
            Try Again
          </Button>
        </View>
      </View>
    </FullHeightContainer>
  )
}

export const QACardQuestions: React.FC<{
  quiz: QuizEntry
  isLatest: boolean
  containerHeight: number
  paddingBottom: number
  paddingTop: number
}> = ({ quiz, isLatest, containerHeight, paddingBottom, paddingTop }) => {
  const questions = quiz.questions

  const { quizState, restartQuiz, handleAlternativeClick, changeQuestion, currentQuestionAnswer } =
    useQuiz(questions)

  const currentQuestion = questions[quizState.currentQuestionIndex] ?? 'placeholder'

  const nextQuestionIsFinishedGenerating =
    questions[quizState.currentQuestionIndex + 1]?.status === 'is-finished-generating'

  const buttonDisabledReason =
    currentQuestion === 'placeholder' ? aiTutorTodoT('Quiz is still generating') : undefined

  const isMobile = useIsMobile()

  const spacer = isMobile ? ('8' as const) : ('32' as const)

  return (
    <Container
      style={isLatest ? { minHeight: `${containerHeight}px`, paddingBottom, paddingTop } : { paddingTop }}
    >
      {quiz.status.type === 'loading' && (
        <Debug>
          <View gap='16' alignItems='center'>
            <Text size='technical'>[Debug: Streaming questions response...]</Text>
          </View>
        </Debug>
      )}
      <>
        {quizState.status === 'completed' ? (
          <EndScreen answers={quizState.answers} totalQuestions={questions.length} onRestart={restartQuiz} />
        ) : (
          <>
            <FullHeightContainer>
              <QuestionCard
                question={currentQuestion}
                onAlternativeClick={handleAlternativeClick}
                previousAnswer={currentQuestionAnswer}
              />
              {currentQuestion !== 'placeholder' && currentQuestion.status !== 'is-generating' && (
                <View direction='column' marginTop={'24'} paddingLeft={spacer}>
                  <View>
                    <IconButton
                      variant='ghost'
                      iconId='arrow--left'
                      disabled={buttonDisabledReason !== undefined}
                      onClick={() => changeQuestion('down')}
                    />
                    <IconButton
                      iconId='arrow--right'
                      variant='ghost'
                      disabled={buttonDisabledReason !== undefined || !nextQuestionIsFinishedGenerating}
                      onClick={() => changeQuestion('up')}
                    />
                  </View>
                </View>
              )}
            </FullHeightContainer>
          </>
        )}
      </>
    </Container>
  )
}
