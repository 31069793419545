import { useCallback, useEffect, useRef, useState } from 'react'
import { color } from 'sierra-ui/color'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const AttributeOption = styled(View)`
  padding: 4px 8px;
  border-radius: 8px;
  flex-shrink: 0;
  background-color: ${p => color(resolveTokenOrColor('org/primary', p.theme)).opacity(0.08)};
  color: ${token('foreground/secondary')};
  font-weight: 500;
  font-size: 12px;
`

export const AttributeOptions: React.FC<{ options: Array<string> }> = ({ options }) => {
  return (
    <View gap='6' wrap='wrap'>
      {options.map((option, index) => (
        <AttributeOption key={option + index}>{option}</AttributeOption>
      ))}
    </View>
  )
}

const OptionsContainer = styled(View)`
  flex-direction: row;
  gap: 6px;
  flex-grow: 1;
  justify-content: flex-end;
  overflow: hidden;
  flex-wrap: nowrap;
`

export const AttributeOptionsSingleRow: React.FC<{ options: string[] }> = ({ options }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [hiddenIndex, setHiddenIndex] = useState(options.length)
  const [hasCalculated, setHasCalculated] = useState(false)

  const calculateVisiblePills = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    if (!hasCalculated) {
      setHasCalculated(true)
    }

    const container = containerRef.current
    const containerWidth = container.offsetWidth
    let totalWidth = 0

    const pills = Array.from(container.children)

    const isHidden = (pill: Element): boolean => {
      // adds 6 to account for the gap between pills
      const width = pill.getBoundingClientRect().width + 6
      totalWidth += width
      // adds 70 to account for "+N" text
      return totalWidth + 70 > containerWidth
    }

    const firstHiddenPill = pills.findIndex(isHidden)

    setHiddenIndex(firstHiddenPill)
  }, [hasCalculated])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateVisiblePills)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }
    return () => resizeObserver.disconnect()
  }, [calculateVisiblePills])

  useEffect(() => {
    calculateVisiblePills()
  }, [options, calculateVisiblePills])

  const visiblePills = hiddenIndex > 0 ? options.slice(0, hiddenIndex) : options

  return (
    <OptionsContainer
      animated
      initial={{ opacity: 0 }}
      animate={{ opacity: hasCalculated ? 1 : 0 }}
      transition={{ duration: 1 }}
      wrap='nowrap'
      ref={containerRef}
    >
      {visiblePills.map(option => (
        <AttributeOption key={option}>{option}</AttributeOption>
      ))}
      {hiddenIndex > 0 && (
        <View padding='4 8'>
          <Text size='micro' bold color='foreground/secondary'>
            +{options.length - hiddenIndex}
          </Text>
        </View>
      )}
    </OptionsContainer>
  )
}
