import { FC, useEffect, useMemo } from 'react'
import { selectCardExercises, selectCardIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { getEditorLayoutForFileType } from 'sierra-client/views/flexible-content/get-editor-layout-for-file-type'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { StaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ExerciseStatus } from 'sierra-domain/api/strategy-v2'
import { File, SlateFile, SlateFileType } from 'sierra-domain/flexible-content/types'
import { idsInNodes } from 'sierra-domain/slate-util'
import { assertWith } from 'sierra-domain/utils'
import { SanaEditor, SlateDocument, SlateRootElement } from 'sierra-domain/v3-author'

export const filterNodesByExercises = (
  document: SlateDocument,
  exercises: Record<string, ExerciseStatus> | undefined
): SlateDocument => {
  const visibleNodes: SlateDocument = []

  for (const node of document) {
    const slateParse = SlateRootElement.safeParse(node)

    if (slateParse.success) {
      const element = slateParse.data
      const isPlainEmbed = element.type === 'embed' && element.data.type === 'plain'

      // This check is necessary, because users only plain embed blocks can contain a mandatory exercise.
      // But users can put on `mandatory` on a plain embed and then toggle to legacy embed,
      // in which the isMandatory check is not supported,
      // This will cause the users to be stuck in a course without being able to proceed.
      const containsBlockingExercises =
        isPlainEmbed &&
        idsInNodes([element])
          .map(id => exercises?.[id])
          .some(exercise => exercise !== undefined && exercise.attemptedInLessonAt === undefined)

      if (containsBlockingExercises) {
        visibleNodes.push(element)
        break
      } else {
        visibleNodes.push(element)
      }
    }
  }

  return visibleNodes
}

const AutoCompleteCardsIfCompletedOrNoExercises: FC<{
  courseId: CreateContentId
  file: File
  exercises: Record<string, ExerciseStatus>
}> = ({ courseId, file, exercises }) => {
  const isCardCompleted = useSelector(state => selectCardIsCompleted(state, courseId, file.id))
  const { setCardCompleted } = useSetCardProgress()

  const exercisesCompleted = Object.values(exercises).every(
    exercise => exercise.hasCorrectInteraction === true
  )

  useEffect(() => {
    if (!isCardCompleted && exercisesCompleted) {
      setCardCompleted()
    }
  }, [isCardCompleted, exercisesCompleted, setCardCompleted])

  return null
}

const CardProgressReporterMaybe: React.FC<{ courseId: CreateContentId; file: File }> = ({
  file,
  courseId,
}) => {
  const exercises = useSelector(state => selectCardExercises(state, courseId, file.id)) ?? {}

  switch (file.data.type) {
    case 'homework':
    case 'assessment-card':
    case 'sliding-scale': //note: homework, assessment card and sliding scale card report its own progress
      return null
    default:
      return (
        <AutoCompleteCardsIfCompletedOrNoExercises courseId={courseId} file={file} exercises={exercises} />
      )
  }
}

export const SelfPacedSlateCard: React.FC<{
  courseId: CreateContentId
  file: SlateFile
  filteredDocument: SlateDocument
  editor: SanaEditor
  tutorState: TutorState
}> = ({ file, courseId, filteredDocument, editor, tutorState }) => {
  const mode: EditorMode = 'self-paced'
  assertWith(SlateFileType, file.data.type)

  const layout = getEditorLayoutForFileType(file.data.type)
  const assetContext = useMemo(() => ({ type: 'course', courseId }) as const, [courseId])

  return (
    <>
      <StaticEditor
        tutorState={tutorState}
        layout={layout}
        document={filteredDocument}
        mode={mode}
        editor={editor}
        assetContext={assetContext}
        file={file}
      />
      {/* CardProgressReporter needs to render after StaticEditor in order to track when the whole content has been scrolled */}
      <CardProgressReporterMaybe courseId={courseId} file={file} />
    </>
  )
}
