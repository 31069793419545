import fuzzysort from 'fuzzysort'
import { useCallback, useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { usePotentialIdentities } from 'sierra-client/api/hooks/use-teamspace'
import { mapBasicUserToIdentity } from 'sierra-client/components/common/identities-selector/identities-utils'
import { FetchIdentities, Identity } from 'sierra-client/components/common/identities-selector/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { usePost } from 'sierra-client/hooks/use-post'
import { useCachedQuery } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Avatar } from 'sierra-domain/api/manage'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminCalendarEventsListPotentialFacilitatorIdentities,
  XRealtimeAdminQueryIdentities,
  XRealtimeAdminUsersListUsers,
  XRealtimeAuthorLiveSessionsQueryPotentialFacilitatorIdentitites,
  XRealtimeAuthorQueryPotentialCollaboratorUserIdentities,
  XRealtimeStrategySelfPacedContentHomeworkListPossibleReviewers,
} from 'sierra-domain/routes'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { getUserName, isDefined } from 'sierra-domain/utils'

const canIssueAsUsersQuery = graphql(`
  query canIssueAsUsers($certificateReceivers: [UserId!]!, $query: String, $limit: Int) {
    canIssueAsUsers(certificateReceivers: $certificateReceivers, query: $query, limit: $limit) {
      id
      displayName
      email
      avatar {
        ...AvatarFragment
      }
    }
  }
`)

type UseAdminIdentitiesOptions = {
  withTypes: Array<'user' | 'user-group'>
  limit?: number
}
type UseAdminIdentitiesFetcher = (params?: UseAdminIdentitiesOptions) => FetchIdentities

/**
 * For fetching identities. Defaults to only `user` unless params also specify `user-group`
 * withTypes can be an `array` as we do deep equality memoization. Do notice that changing order
 * in the list itself will break the memoization
 * */
export const useAdminIdentitiesFetcher: UseAdminIdentitiesFetcher = options => {
  const limit = useMemo(() => options?.limit ?? 20, [options?.limit])
  const types = useDeepEqualityMemo(options?.withTypes ?? (['user'] satisfies Array<'user' | 'user-group'>))

  const { postWithUserErrorException } = usePost()

  // It's important to use a unique name for this function since it is used for caching
  const fetchAdminIdentities = async (query: string): Promise<Identity[]> => {
    const result = await postWithUserErrorException(XRealtimeAdminQueryIdentities, {
      query,
      limit,
      types,
    })
    return result.identities.map(identity => ({ ...identity, id: identity.identity.id }))
  }

  return useCallback(fetchAdminIdentities, [limit, postWithUserErrorException, types])
}

type UseFacilitatorIdentitiesOptions = {
  limit?: number
}
type UseFacilitatorIdentitiesFetcher = (params?: UseFacilitatorIdentitiesOptions) => FetchIdentities

export const useFacilitatorIdentitiesFetcher: UseFacilitatorIdentitiesFetcher = options => {
  const limit = useMemo(() => options?.limit ?? 20, [options?.limit])

  const { postWithUserErrorException } = usePost()

  // It's important to use a unique name for this function since it is used for caching
  const fetchPotentialFacilitatorIdentities = async (query: string): Promise<Identity[]> => {
    const result = await postWithUserErrorException(
      XRealtimeAuthorLiveSessionsQueryPotentialFacilitatorIdentitites,
      {
        query,
        limit,
      }
    )
    return result.identities.map(identity => ({ ...identity, id: identity.identity.id }))
  }

  return useCallback(fetchPotentialFacilitatorIdentities, [limit, postWithUserErrorException])
}

export const useTeamspacePotentialIdentitiesFetcher = (teamspaceId?: NanoId12): FetchIdentities => {
  const potentialIdentitiesQuery = usePotentialIdentities(teamspaceId, {
    select: data => {
      return data.map(identity => ({ ...identity, id: identity.identity.id }))
    },
  })

  // It's important to use a unique name for this function since it is used for caching
  const fetchTeamspacePotentialIdentities = async (query: string): Promise<Identity[]> => {
    const potentialIdentities = potentialIdentitiesQuery.data ?? []

    if (query === '') {
      return potentialIdentities
    }

    const result = fuzzysort.go(query, potentialIdentities, { keys: ['email', 'name'] }).map(({ obj }) => obj)

    return result
  }

  return useCallback<FetchIdentities>(fetchTeamspacePotentialIdentities, [potentialIdentitiesQuery.data])
}

const mapReviewerToIdentity = (reviewer: BaseUserRow): Identity => {
  return {
    id: reviewer.userId,
    identity: {
      type: 'user',
      id: reviewer.userId,
    },
    name: getUserName(reviewer) ?? '',
    email: reviewer.email,
    avatar: {
      type: 'color',
      initials: `${reviewer.firstName?.[0] ?? ''}${reviewer.lastName?.[0] ?? ''}`,
      color: reviewer.avatarColor,
    },
  }
}

export const usePossibleReviewersIdentitiesFetcher = (): FetchIdentities => {
  const possibleReviewersQuery = useCachedQuery(
    XRealtimeStrategySelfPacedContentHomeworkListPossibleReviewers,
    { staleTime: 60 * 1000 }
  )

  // It's important to use a unique name for this function since it is used for caching
  const fetchReviewerIdentities = async (query: string): Promise<Identity[]> => {
    const potentialReviewers = possibleReviewersQuery.data?.data ?? []

    if (query === '') {
      return potentialReviewers.map(mapReviewerToIdentity)
    }

    const result = fuzzysort
      .go(query, potentialReviewers, { keys: ['email', 'name'] })
      .map(({ obj }) => obj)
      .map(mapReviewerToIdentity)

    return result
  }

  return useCallback<FetchIdentities>(fetchReviewerIdentities, [possibleReviewersQuery.data])
}

export const useCanIssueAsUsersFetcher = (
  certificateReceivers: UserId[],
  limit: number = 20
): FetchIdentities => {
  // Make sure we memoize them based on its internal strings
  const memoizedIds = useDeepEqualityMemo(certificateReceivers)
  // It's important to use a unique name for this function since it is used for caching
  const fetchCanIssueAsUserIdentities = async (query: string): Promise<Identity[]> => {
    const { canIssueAsUsers } = await graphQuery(canIssueAsUsersQuery, {
      certificateReceivers: memoizedIds,
      query: query,
      limit: limit,
    })

    return canIssueAsUsers.map(mapBasicUserToIdentity)
  }

  return useCallback<FetchIdentities>(fetchCanIssueAsUserIdentities, [limit, memoizedIds])
}

export const usePotentialFacilitatorsForCalendarEventsFetcher = (
  calendarEventId?: string
): FetchIdentities => {
  const { postWithUserErrorException } = usePost()

  // It's important to use a unique name for this function since it is used for caching
  const fetchPotentialFacilitatorsForCalendarEvents = async (query: string): Promise<Identity[]> => {
    const result = await postWithUserErrorException(
      XRealtimeAdminCalendarEventsListPotentialFacilitatorIdentities,
      {
        query,
        calendarEventId,
      }
    )
    return result.identities.map(identity => ({ ...identity, id: identity.identity.id }))
  }

  return useCallback(fetchPotentialFacilitatorsForCalendarEvents, [
    calendarEventId,
    postWithUserErrorException,
  ])
}

export const usePotentialCourseCollaboratorUsersFetcher = (courseId: CourseId): FetchIdentities => {
  const { postWithUserErrorException } = usePost()

  // It's important to use a unique name for this function since it is used for caching
  const fetchPotentialCourseCollaboratorUsers: FetchIdentities = async query => {
    const result = await postWithUserErrorException(XRealtimeAuthorQueryPotentialCollaboratorUserIdentities, {
      courseId,
      query,
      limit: 30,
    })

    return result.identities.map(identity => ({ ...identity, id: identity.identity.id }))
  }

  return useCallback<FetchIdentities>(fetchPotentialCourseCollaboratorUsers, [
    postWithUserErrorException,
    courseId,
  ])
}

export const useAssignableUsersFetcher = (): FetchIdentities => {
  const { postWithUserErrorException } = usePost()

  // It's important to use a unique name for this function since it is used for caching
  const fetchAssignableUsers = async (query: string): Promise<Identity[]> => {
    const response = await postWithUserErrorException(XRealtimeAdminUsersListUsers, {
      statusFilter: undefined,
      commonFilters: {
        requestedUserIds: undefined,
        lastUserId: undefined,
        maxResults: undefined,
        sortBy: undefined,
        groupIds: undefined,
        query,
      },
    })

    return response.data.map(userRow => {
      const collaborator = userRow.userInfo
      const avatar: Avatar = isDefined(collaborator.avatar)
        ? {
            type: 'image',
            image: {
              type: 'url',
              url: getAvatarImage(collaborator.userId, collaborator.avatar) ?? '',
            },
          }
        : {
            type: 'color',
            initials: `${collaborator.firstName?.[0] ?? ''}${collaborator.lastName?.[0] ?? ''}`,
            color: collaborator.avatarColor,
          }
      return {
        id: collaborator.userId,
        identity: { type: 'user', id: collaborator.userId },
        name: collaborator.firstName + ' ' + collaborator.lastName,
        email: collaborator.email,
        avatar,
      }
    })
  }

  return useCallback<FetchIdentities>(fetchAssignableUsers, [postWithUserErrorException])
}

const usersQuery = graphql(`
  query UserAutoComplete($query: String!, $limit: Int!) {
    users(query: $query, limit: $limit) {
      data {
        id
        displayName
        email
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

/**
 * Returns a fetcher that queries over all users in the organization. Make sure that's OK before using it!
 */
export const useAllUsersFetcher = ({ limit }: { limit: number }): FetchIdentities => {
  // It's important to use a unique name for this function since it is used for caching
  const fetchAllUsers = async (query: string): Promise<Identity[]> => {
    const response = await graphQuery(usersQuery, { query, limit })

    return response.users.data.map(mapBasicUserToIdentity)
  }

  return useCallback(fetchAllUsers, [limit])
}
