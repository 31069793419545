import ReactDOM from 'react-dom/client'
import { initAuth } from 'sierra-client/auth/init-auth-client'
import { getFlag } from 'sierra-client/config/global-config'
import { createLogging, UserSingleton } from 'sierra-client/core/user'
import { initErrorTracking } from 'sierra-client/error/init-error-tracking'
import 'sierra-client/i18n/i18n'
import { checkPolyfills } from 'sierra-client/polyfills/polyfills'
import { ReactRoot } from 'sierra-client/react-root'
import { cleanEntryUrl } from 'sierra-client/router'
import { store } from 'sierra-client/state/store'

checkPolyfills()
cleanEntryUrl()
initAuth()
UserSingleton.initialize(store, createLogging())
initErrorTracking({ withReplays: getFlag('session-replays') })

const rootElement = document.getElementById('app')!

ReactDOM.createRoot(rootElement).render(<ReactRoot />)
