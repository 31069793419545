import Ably from 'ably'
import { getAuthClient } from 'sierra-client/auth/auth-client'
import { config } from 'sierra-client/config/global-config'
import { setUnion } from 'sierra-client/lib/querystate/utils'
import { logger } from 'sierra-client/logger/logger'
import { Either, left, right } from 'sierra-domain/either'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { iife } from 'sierra-domain/utils'
import { retry } from 'ts-retry-promise'

export const getAblyAuthParameters = ({
  channels = [],
}: { channels?: string[] } = {}): Partial<Ably.AuthOptions> => {
  const authenticate = '/x-realtime/realtime-data/authenticate'
  const host = config.auth.host
  const token = getAuthClient().getToken()
  const authorizationHeader = token !== undefined ? `Bearer ${token}` : ''

  return {
    authUrl: host !== undefined ? `https://${host}${authenticate}` : authenticate,
    authMethod: 'POST',

    // For the authentication work in the MR preview environment, we need ably-js to set
    // withCredentials = true on the XMLHttpRequest, and it only does this if it detects
    // that there is a header named "authorization". It is important that the header name is
    // in lower case for this to work since it does an exact string matching.
    // If withCredentials is set to true, this will cause the XMLHttpRequest to include the
    // cookies, that we need for the Core service to authenticate the request.
    // For details of how the XHR requset is formed, see src/platform/web/lib/transport/xhrrequest.ts
    // in the ably-js repository.
    // The Core service will ignore the authorization header if it is set to empty string,
    // making it fall back to the cookie based authorization as we want.
    authHeaders: { authorization: authorizationHeader },

    authParams: { channels: channels.join(',') },
  }
}

function parseAuthenticatedChannels(tenantId: string, tokenDetails: Ably.TokenDetails): string[] {
  const prefix = `${tenantId}:`
  return Object.keys(JSON.parse(tokenDetails.capability)).flatMap(v => {
    return v.startsWith(prefix) ? [v.slice(prefix.length)] : []
  })
}

export type AblyClientWithAuthState = {
  ablyClient: Ably.Realtime
  authenticatedChannels: Set<string>
  currentToken: Ably.TokenDetails | undefined
}

let ongoingAuthRequest: Promise<Ably.TokenDetails> | undefined = undefined

/**
 * Do not call this function directly, use authenticateChannel instead
 */
export async function _authenticateChannels(
  client: AblyClientWithAuthState,
  tenantId: string,
  channelNames: Set<string>
): Promise<void> {
  // We need to ensure we only do one auth request at a time, otherwise we can get a weird race where
  // we don't get all the needed channel permissions
  // we can ignore if the old request failed
  while (ongoingAuthRequest !== undefined) {
    try {
      await ongoingAuthRequest
    } catch (error) {
      /* ignore */
    }
  }

  try {
    ongoingAuthRequest = iife(() => {
      // This is only used for debugging.
      const requestId = nanoid12()
      const start = performance.now()

      logger.debug(`Starting Ably authentication request ${requestId}`, { channelNames })

      return client.ablyClient.auth
        .authorize(
          undefined,
          getAblyAuthParameters({
            channels: Array.from(setUnion(client.authenticatedChannels, channelNames)),
          })
        )
        .then(res => {
          const duration = performance.now() - start

          logger.debug(`Fulfilled Ably authentication request ${requestId} after ${duration}ms`)
          return res
        })
        .catch(e => {
          const duration = performance.now() - start

          logger.error(`Rejected Ably authentication request ${requestId} after ${duration}ms`, {
            cause: e,
          })
          throw e
        })
    })

    client.currentToken = await ongoingAuthRequest

    const authenticatedChannels = parseAuthenticatedChannels(tenantId, client.currentToken)
    client.authenticatedChannels = new Set([...authenticatedChannels])
  } catch (error) {
    logger.info('authenticateChannel error', { error, channelNames })
    throw error
  } finally {
    ongoingAuthRequest = undefined
  }
}

type BatchState = {
  currentBatch: Set<string>
  authenticationRequest: Promise<unknown>
}
let batchState: BatchState | undefined = undefined

/**
 * Authenticate an Ably channel.
 *
 * Does not retry failed requests.
 *
 * Batches requests.
 */
async function authenticateChannelBatched(
  client: AblyClientWithAuthState,
  tenantId: string,
  channelName: string
): Promise<void> {
  // We do not want to re-authenticate channels that have already been authenticated
  if (client.authenticatedChannels.has(channelName)) {
    return
  }

  // We want to batch up auth requests to avoid making too many requests sequentially
  if (batchState === undefined) {
    const currentBatch = new Set([channelName])
    batchState = {
      currentBatch,
      authenticationRequest: iife(async () => {
        // Batch requests within a small time window.
        await new Promise(resolve => setTimeout(resolve, 20))

        // There's no need to trigger a new batch while there's an auth request pending.
        // We'll wait for it to resolve first.
        while (ongoingAuthRequest !== undefined) {
          try {
            await ongoingAuthRequest
          } catch (error) {
            /* ignore */
          }
        }

        // Make sure the next call starts a new batch
        batchState = undefined

        await _authenticateChannels(client, tenantId, currentBatch)
      }),
    }
  } else {
    batchState.currentBatch.add(channelName)
  }

  await batchState.authenticationRequest
}

/**
 * Authenticate an Ably channel.
 *
 * Retries failed requests.
 */
export async function authenticateChannel(
  client: AblyClientWithAuthState,
  tenantId: string,
  channelName: string
): Promise<Either<Error, boolean>> {
  try {
    await retry(() => authenticateChannelBatched(client, tenantId, channelName), {
      retries: 3,
      retryIf: error => {
        // don't retry auth errors
        if (
          error instanceof Error &&
          'statusCode' in error &&
          (error.statusCode === 403 || error.statusCode === 401)
        )
          return false
        return true
      },
      logger: message => logger.info(`retrying authenticating ably channel: ${message}`),
      timeout: 30_000,
    })

    const isAuthenticated = client.authenticatedChannels.has(channelName)
    return right(isAuthenticated)
  } catch (e) {
    const error = e instanceof Error ? e : new Error('unknown error', { cause: e })
    return left(error)
  }
}
