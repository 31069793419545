import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { groupBy } from 'sierra-client/lib/filter/components/common'
import {
  AfterDateProps,
  ClickableLabel,
  Label,
  StartDateSelectorProps,
  labelString,
  useTimeDomains,
} from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/common'
import { TimeAttribute } from 'sierra-domain/api/manage'
import { Input, MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const NumberInput = styled(Input).attrs({
  id: 'after-days',
  spacing: 'none',
  type: 'number',
  inputMode: 'numeric',
})`
  max-width: 60px;
`

const SelectWrapper = styled(View)`
  width: 200px;
`

const AfterDates: React.FC<AfterDateProps & { disabled: boolean }> = ({
  disabled,
  afterDays,
  setAfterDays,
}) => {
  const { t } = useTranslation()

  return (
    <View direction='row' gap='24'>
      <View gap='6'>
        <Label $disabled={disabled}>{t('manage.programs.enrollment-rules.start-date.delay-with')}</Label>
        <NumberInput
          value={String(afterDays)}
          onChange={e => setAfterDays(parseInt(e.target.value))}
          disabled={disabled}
        />
        <Label $disabled={disabled}>
          {afterDays === 1
            ? t('manage.programs.enrollment-rules.day')
            : t('manage.programs.enrollment-rules.days')}
        </Label>
      </View>
    </View>
  )
}

const getRelativeId = (date: TimeAttribute): string => {
  if (date.type === 'now') {
    return 'now'
  }

  return `relative:${date.attribute.type}${
    date.attribute.type === 'user.customAttribute' ? `:${date.attribute.key}` : ''
  }`
}

const isPositiveInteger = (input?: number): number => {
  if (input === undefined) {
    return 0
  }

  if (isNaN(input)) {
    return 0
  }

  if (input < 0) {
    return 0
  }

  return input
}

type DateMenuItem = MenuItem & {
  value?: TimeAttribute
}

export const Relative: React.FC<StartDateSelectorProps & { onClick: () => void }> = ({
  startDate,
  setStartDate,
  disabled,
  onClick,
}) => {
  const { t, dynamicT } = useTranslation()
  const { timeDomains } = useTimeDomains()
  const [afterDays, setAfterDays] = useState(0)

  // Update after days if loaded and disabled
  useEffect(() => {
    if (startDate.type === 'relative') {
      setAfterDays(startDate.offset.days)
    }
  }, [startDate])

  const timeDomainByCategory = useMemo(
    () => groupBy(timeDomains, d => labelString(d.category, dynamicT)),
    [timeDomains, dynamicT]
  )

  const relativeDateItems = useMemo(() => {
    const items: DateMenuItem[] = Array.from(timeDomainByCategory.keys()).map(category => ({
      id: category,
      key: category,
      type: 'group',
      label: category,
      menuItems: (timeDomainByCategory.get(category) ?? []).map((domain, i) => ({
        id: getRelativeId(domain.ref),
        key: i,
        type: 'label',
        value: domain.ref,
        label: labelString(domain.label, dynamicT),
      })),
    }))

    return items
  }, [timeDomainByCategory, dynamicT])

  const Headline = (
    <ClickableLabel onClick={onClick}>
      {t('manage.programs.enrollment-rules.start-date.select-attribute')}
    </ClickableLabel>
  )

  if (startDate.type !== 'relative') {
    return Headline
  }

  const currentId = getRelativeId(startDate)

  const selectedItem = relativeDateItems
    .flatMap(it => (it.type === 'group' ? it.menuItems : it))
    .find(item => item.id === currentId)

  return (
    <View gap='24'>
      {Headline}
      <SelectWrapper>
        <SingleSelectDropdown
          selectedItem={selectedItem}
          onSelect={(item: DateMenuItem) => {
            const value = item.value
            if (value !== undefined) {
              if (value.type === 'relative') {
                setStartDate({
                  ...value,
                  offset: {
                    type: 'days',
                    days: afterDays,
                  },
                })
              } else {
                setStartDate(value)
              }
            }
          }}
          menuItems={relativeDateItems}
          disabled={disabled}
        />
      </SelectWrapper>
      <AfterDates
        disabled={disabled === true}
        afterDays={afterDays}
        setAfterDays={days => {
          const daysInNumber = isPositiveInteger(days)

          setAfterDays(daysInNumber)
          setStartDate({ ...startDate, offset: { type: 'days', days: daysInNumber } })
        }}
      />
    </View>
  )
}
