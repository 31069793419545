import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { PotentialCollaboratorInfo } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminUsersListPotentialCollaborators } from 'sierra-domain/routes'
import { assertIsNonNullable, assertNever } from 'sierra-domain/utils'

function mapCollaborator(potentialCollaborator: PotentialCollaboratorInfo): Collaborator {
  return {
    uuid: potentialCollaborator.userId,
    firstName: potentialCollaborator.firstName,
    lastName: potentialCollaborator.lastName,
    avatar: potentialCollaborator.avatar ?? undefined,
    avatarColor: potentialCollaborator.avatarColor,
    email: potentialCollaborator.email,
    role: potentialCollaborator.role,
  }
}

type AvailableAndCurrentCollaborators = {
  available: Collaborator[]
  current: Collaborator[]
}

function useHasManageOrEditorAccess(): boolean {
  const orgPermissions = useOrganizationPermissions()

  return orgPermissions.has('ACCESS_MANAGE') || orgPermissions.has('ACCESS_EDITOR')
}

export function useAllAvailableCourseCollaborators({
  courseId,
}: {
  courseId: CourseId
}): UseQueryResult<AvailableAndCurrentCollaborators> {
  const hasAccess = useHasManageOrEditorAccess()

  return useCachedQuery(
    XRealtimeAdminUsersListPotentialCollaborators,
    {
      courseId,
    },
    {
      staleTime: 10 * 1000,
      select: data => {
        return {
          available: data.map(mapCollaborator),
          current: data.filter(it => it.role !== undefined).map(mapCollaborator),
        }
      },
      enabled: hasAccess,
    }
  )
}

/**
 * We will use this in order to migrate away from storing collaborators in Redux.
 */
export function useAllAvailableCourseCollaboratorsMaybe({
  courseId,
}: {
  courseId: CourseId | undefined
}): UseQueryResult<AvailableAndCurrentCollaborators> {
  const hasAccess = useHasManageOrEditorAccess()

  return useQuery({
    queryKey: [XRealtimeAdminUsersListPotentialCollaborators.path, { courseId }],
    queryFn: async () => {
      assertIsNonNullable(courseId, 'This query should not be enabled unless courseId is defined')

      return typedPost(XRealtimeAdminUsersListPotentialCollaborators, { courseId })
    },
    select: data => {
      return {
        available: data.map(mapCollaborator),
        current: data.filter(it => it.role !== undefined).map(mapCollaborator),
      }
    },
    staleTime: 10 * 1000,
    enabled: courseId !== undefined && hasAccess,
  })
}

export function useCourseCollaborators({ courseId }: { courseId: CourseId }): Collaborator[] {
  const { data } = useAllAvailableCourseCollaborators({ courseId })

  const currentData = data?.current
  return useMemo(() => currentData ?? [], [currentData])
}

export function useIsCurrentUserCourseCollaborator({
  courseId,
}: {
  courseId: CourseId | undefined
}): boolean {
  const currentUserId = useCurrentUserId().data
  const { data } = useAllAvailableCourseCollaboratorsMaybe({ courseId })

  if (data === undefined) {
    return false
  }

  return data.current.some(collaborator => collaborator.uuid === currentUserId)
}

export function useCollaboratorIdsAboveOrEqualToEditAccess({ courseId }: { courseId: CourseId }): UserId[] {
  const { data } = useAllAvailableCourseCollaborators({ courseId })

  return useMemo(
    () =>
      data?.available.flatMap(it => {
        switch (it.role) {
          case undefined:
          case 'commenter':
            return []
          case 'editor':
          case 'owner':
            return [it.uuid]
          default:
            assertNever(it.role)
        }
      }) ?? [],
    [data]
  )
}
