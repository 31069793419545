import { createSelector } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import {
  CourseSettingsState,
  CourseSettingsValidation,
} from 'sierra-client/state/author-course-settings/types'
import { RootState } from 'sierra-client/state/types'
import { CourseSettings } from 'sierra-domain/api/common'

const selectSlice = (rootState: RootState): CourseSettingsState => rootState.authorCourseSettings

export const isFetching = createSelector(selectSlice, slice => slice.isFetching)

export const currentCourseId = createSelector(selectSlice, slice => slice.courseId)

export const currentView = createSelector(selectSlice, slice => slice.view)

export const selectOriginalSettings = createSelector([selectSlice], slice => slice.originalSettings)

export const selectDraftSettings = createSelector(selectSlice, slice => slice.draftSettings)
export const selectCourseKind = createSelector(selectSlice, slice => slice.kind)
export const selectReadOnly = createSelector(selectSlice, slice => slice.readOnly)
export const selectParsedFormErrors = createSelector(selectSlice, slice => {
  const errors: { [P in keyof CourseSettings]?: CourseSettingsValidation } = {}
  slice.draftSettingsValidation.forEach(e => {
    errors[e.field] = e
  })
  return errors
})

export const hasUnsavedSettingsChanges = createSelector(selectSlice, state => {
  return state.originalSettings !== 'loading' && !isEqual(state.originalSettings, state.draftSettings)
})

export const hasUnsavedIsRequiredChange = createSelector(selectSlice, state => {
  return (
    state.originalSettings !== 'loading' &&
    !isEqual(
      state.originalSettings.defaultRequiredAssignments,
      state.draftSettings?.defaultRequiredAssignments
    )
  )
})

const empty = [] as const
export const allAbbreviations = createSelector(selectDraftSettings, draft => draft?.abbreviations ?? empty)

export const selectTemplateSettings = createSelector(selectOriginalSettings, settings =>
  settings !== 'loading' ? settings.templateSettings : undefined
)

export const selectOriginalSettingsDescription = createSelector(selectOriginalSettings, settings =>
  settings !== 'loading' ? settings.description : undefined
)

export const selectOriginalSettingsImage = createSelector(selectOriginalSettings, settings =>
  settings !== 'loading' ? settings.image : undefined
)

export const selectOriginalSettingsDefaultRequiredAssignments = createSelector(
  selectOriginalSettings,
  settings => (settings !== 'loading' ? settings.defaultRequiredAssignments : undefined)
)
