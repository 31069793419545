import { concat } from 'lodash'
import 'regenerator-runtime/runtime' // note: must be loaded before exceljs
import { downloadBlob } from 'sierra-client/api'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { BaseColumn } from 'sierra-client/lib/tabular/datatype/column'
import { Data } from 'sierra-client/lib/tabular/datatype/data'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { assignment2string } from 'sierra-client/lib/tabular/sorting'
import { assertNever, getUserName, iife } from 'sierra-domain/utils'

export type FileType = 'csv' | 'xlsx'

export type TableFile = {
  headers: string[]
  rows: (string | number | boolean | Date | string[] | undefined)[][]
  fileType: FileType
}

export const createTableFileBlob = async ({ headers, rows, fileType }: TableFile): Promise<Blob> => {
  const ExcelJS = await import('exceljs')
  const workbook = new ExcelJS.Workbook()

  const worksheet = workbook.addWorksheet('Sheet1')

  worksheet.addRow(headers)
  worksheet.addRows(rows)

  const buffer = await iife(() => {
    switch (fileType) {
      case 'csv':
        return workbook.csv.writeBuffer()
      case 'xlsx':
        return workbook.xlsx.writeBuffer()
      default:
        assertNever(fileType)
    }
  })

  const blob = new Blob([buffer])
  return blob
}

const getColumnHeaderString = (column: BaseColumn, t: TranslationLookup): string => {
  if (typeof column.header === 'string' || column.header.type !== 'cell') {
    return labelToString(column.header, t)
  }

  return labelToString(column.header.label, t)
}

function toHeaderData(tableData: TableData, t: TranslationLookup): string[] {
  return tableData.columns.flatMap(column =>
    // Add extra column for user column to include both name and email.
    column.type === 'users' ? [getColumnHeaderString(column, t), 'Email'] : [getColumnHeaderString(column, t)]
  )
}

type CellOutput = number | string | boolean | Date | [string, string]

function getCellData({
  row,
  column,
}: {
  row: TableData['rows'][number]
  column: TableData['columns'][number]
}): CellOutput {
  const { type, data } = {
    type: column.type,
    data: row.data[column.ref]?.data,
  } as Data

  switch (type) {
    case 'booleans':
    case 'numbers':
    case 'strings':
    case 'expiryTimes':
      return data ?? ''
    case 'dateTimes':
      return data?.date ?? ''
    case 'duration':
      return data !== undefined ? data.as('minutes') : ''
    case 'users': {
      if (data === undefined) {
        return ['<unknown>', '<unknown>']
      } else {
        return [getUserName(data) ?? '<unknown>', data.email]
      }
    }
    case 'enrolledBys': {
      switch (data.type) {
        case 'admin':
          return getUserName(data) ?? ''
        case 'self':
          return 'self enrollment'
        case 'enrollment-rule':
          return data.name
        case 'unknown':
          return 'unknown'
        default:
          assertNever(data)
      }
      break
    }
    case 'homeworks':
      return data.title ?? ''
    case 'userStacks':
      return data.users.map(getUserName).join(', ')
    case 'content':
      return data?.title ?? ''
    case 'liveSession':
      return data?.title ?? ''
    case 'skillContent':
      return data.title
    case 'links': {
      return data.label
    }
    case 'certificates':
      return data?.title ?? ''
    case 'certificateIssuedBy': {
      switch (data.type) {
        case 'content':
        case 'program':
          return data.title
        case 'user':
          return data.displayName
        default:
          assertNever(data)
      }
      break
    }
    case 'issuedCertificates':
      return data.issuedToUserDisplayName
    case 'addStepContent':
      return data.title
    case 'issuesRevoked':
      return data.issued
    case 'programVersionNumbers':
      return data.progress.toFixed(2)
    case 'titlesAndSubtitles':
      return data === undefined ? '' : `${data.title} ${data.subtitle}`
    case 'groups':
      return data?.name ?? ''
    case 'eventGroups':
      return data.title
    case 'singleSelect':
      return data.selected
    case 'card':
      return data?.title ?? ''
    case 'canvas':
      return data.meta.exports() ?? ''
    case 'pills':
      return data === undefined ? '' : data.type + data.text + data.subtext
    case 'progress':
      return data?.progress ?? ''
    case 'assignedVia':
      return assignment2string(data.assignmentData)
    case 'detailedContents':
      return data?.title ?? ''
    case 'assignedProgram':
      return data?.name ?? ''
    default:
      assertNever(type)
  }
}

export const toRowData = (
  tableData: TableData
): Array<Array<number | string | boolean | Date | undefined | string[]>> =>
  tableData.rows.map(row => {
    return tableData.columns.flatMap(column => getCellData({ row, column }))
  })

export const exportTableData = async <Data extends TableData, Meta>({
  api,
  dataLoader,
  t,
  fileType = 'csv',
  includeNested = false,
  withName = undefined,
}: {
  api: TableAPI
  dataLoader: DL.DataLoaderStateMachine<Data, Meta>
  t: TranslationLookup
  fileType: FileType
  includeNested?: boolean
  withName?: string
}): Promise<void> => {
  const filter = api.query.filter().filter
  const query = api.query.query().query

  let state = await dataLoader.onInit({
    predicate: { filter, query },
    modifier: {},
    // It appears that we currently have a limit max of 1 < limit < 1000
    control: { limit: 999 },
  })

  while (state.type !== 'done') {
    state = await dataLoader.onMore(state, { control: state.control })
  }

  // this only flattens one level
  const tableData = includeNested
    ? state.data.map(d => ({ ...d, rows: d.rows.flatMap(row => concat(row, d.nested[row.id]?.rows ?? [])) }))
    : state.data

  const selection = api.query.selected().selection

  // Filter out the ones we have selected, excluded etc based on selection
  const filtered = tableData.map(td => {
    const matched = iife(() => {
      switch (selection.type) {
        case 'all':
          return td.rows.filter(({ id }) => !selection.excluded.has(id))
        case 'none':
          return td.rows
        case 'manual':
          return td.rows.filter(({ id }) => selection.rows.has(id))
        default:
          assertNever(selection)
      }
    })

    return { ...td, rows: matched }
  })

  const headers = tableData.slice(0, 1).flatMap(td => toHeaderData(td, t))
  const rows = filtered.flatMap(toRowData)

  const blob = await createTableFileBlob({ headers, rows, fileType })
  downloadBlob(blob, `${withName ?? 'export'}.${fileType}`)
}
