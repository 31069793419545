import React, { useEffect, useState } from 'react'
import { createFakeAwareness } from 'sierra-client/collaboration/fake-awareness'
import { SyncClock } from 'sierra-client/collaboration/sync-clock'
import {
  LiveSessionContextProvider,
  useLiveSessionContext,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  LiveSessionAblyPresenceReduxSync,
  LiveSessionYMapSyncAbly,
} from 'sierra-client/components/liveV2/live-session-ymap-sync'
import { usePost } from 'sierra-client/hooks/use-post'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectRecapFileIds } from 'sierra-client/state/flexible-content/recap-file'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectLiveSessionData } from 'sierra-client/state/live-session/selectors'
import { fetchCourseDataById } from 'sierra-client/state/v2/courses-actions'
import { CreateContentObserver } from 'sierra-client/views/flexible-content/create-content-observer'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { RecapContextProvider } from 'sierra-client/views/recap/recap-context'
import { RecapPlayer } from 'sierra-client/views/recap/recap-player'
import { LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { deserialize } from 'sierra-domain/collaboration/serialization/deserialize'
import { ScopedLiveSessionId, ScopedYDocId } from 'sierra-domain/collaboration/types'
import { LiveSession } from 'sierra-domain/content/session'
import { RequestError } from 'sierra-domain/error'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { flexibleContentJsonDataKey } from 'sierra-domain/flexible-content/types'
import { LiveSessionData } from 'sierra-domain/live-session'
import { XRealtimeContentGetLiveSession, XRealtimeContentLiveSessionRecap } from 'sierra-domain/routes'
import { asNonNullable } from 'sierra-domain/utils'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const useFetchCourseSettings = (liveSession?: LiveSession): void => {
  const dispatch = useDispatch()
  const flexibleContentId = liveSession?.data.flexibleContentId

  useEffect(() => {
    if (flexibleContentId !== undefined) {
      void dispatch(fetchCourseDataById({ courseId: flexibleContentId }))
    }
  }, [dispatch, flexibleContentId])
}

const useRecapSessionData = (): { liveSession?: LiveSession; liveSessionData?: LiveSessionData } => {
  const liveSession = useLiveSessionContext()
  const liveSessionData = useSelector(selectLiveSessionData)

  useFetchCourseSettings(liveSession)

  return { liveSession, liveSessionData }
}

type Context = {
  yDocId: ScopedYDocId
  yDoc: Y.Doc
  awareness: Awareness
  jsonDataYMap: Y.Map<unknown>
}

const useRecapContext = (
  liveSessionId: LiveSessionId
): { context: Context | undefined; recapIsAvailable: boolean | undefined } => {
  const [context, setContext] = useState<Context | undefined>(undefined)
  const [recapIsAvailable, setRecapIsAvailable] = useState<boolean | undefined>(undefined)
  const { postWithUserErrorException } = usePost()

  useEffect(() => {
    void postWithUserErrorException(XRealtimeContentLiveSessionRecap, { liveSessionId })
      .then(result => {
        return result.record
      })
      .then(record => deserialize({ '': record }))
      .then(yDoc => {
        const rootMap = asNonNullable(yDoc.getMap<Y.Map<unknown>>())
        const jsonDataYMap: Y.Map<unknown> = asNonNullable(rootMap.get(flexibleContentJsonDataKey))
        const awareness = createFakeAwareness(yDoc)
        setContext({
          yDoc,
          awareness,
          jsonDataYMap,
          yDocId: ScopedLiveSessionId.fromId(liveSessionId),
        })
        setRecapIsAvailable(true)
      })
      .catch(error => {
        if (error instanceof RequestError && error.status === 404) {
          setRecapIsAvailable(false)
        }
      })
  }, [liveSessionId, postWithUserErrorException])

  return { context, recapIsAvailable }
}

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 100vw;
  ${height_100dvh}

  display: flex;
  flex-direction: 'column';

  & > * {
    margin: auto;
  }
`

const Loading: React.FC<{ debugMessage: string }> = ({ debugMessage }) => (
  <Container>
    <View direction='column'>
      <Debug>
        <Text>[Debug: {debugMessage}]</Text>
      </Debug>
      <LoadingSpinner />
    </View>
  </Container>
)

const NoRecapAvailable: React.FC = () => {
  useEffect(() => {
    void notFound404Redirect()
  }, [])

  return null
}

const StartPage: React.FC<{
  liveSessionId: LiveSessionId
  liveSession: LiveSession
}> = props => {
  const flexibleContentId = props.liveSession.data.flexibleContentId
  const fileIds = useSelector(state => selectRecapFileIds(state, flexibleContentId))
  const firstFileId = fileIds[0]

  useEffect(() => {
    if (firstFileId !== undefined) {
      void getGlobalRouter().navigate({ to: `/r/${props.liveSessionId}/${firstFileId}`, replace: true })
    }
  }, [props.liveSessionId, firstFileId])

  return <Loading debugMessage='StartPage' />
}

const RecapPageWithContent: React.FC<{
  liveSessionId: LiveSessionId
  fileId?: FileId
  liveSession: LiveSession
  liveSessionData: LiveSessionData
}> = props => {
  const flexibleContentId = props.liveSession.data.flexibleContentId
  const liveContentId = LiveContentId.parse(flexibleContentId)
  const { context, recapIsAvailable } = useRecapContext(props.liveSessionId)

  if (recapIsAvailable === false) return <NoRecapAvailable />
  if (context === undefined) return <Loading debugMessage='YDoc context missing (RecapPageWithContent)' />

  return (
    <RecapContextProvider liveSessionId={props.liveSessionId} flexibleContentId={flexibleContentId}>
      <PageContainer>
        <CreateContentObserver jsonDataYMap={context.jsonDataYMap} createContentId={liveContentId} />
        {props.fileId === undefined ? (
          <StartPage liveSessionId={props.liveSessionId} liveSession={props.liveSession} />
        ) : (
          <RecapPlayer
            yDocId={context.yDocId}
            liveSessionId={props.liveSessionId}
            fileId={props.fileId}
            liveSession={props.liveSession}
            liveContentId={liveContentId}
            yDoc={context.yDoc}
            awareness={context.awareness}
          />
        )}
      </PageContainer>
    </RecapContextProvider>
  )
}

const RecapPageWithLiveData: React.FC<{
  liveSessionId: LiveSessionId
  fileId?: FileId
}> = props => {
  const { liveSession, liveSessionData } = useRecapSessionData()

  if (liveSession === undefined)
    return <Loading debugMessage='Live session undefined (RecapPageWithLiveData)' />
  if (liveSessionData === undefined)
    return <Loading debugMessage='Live session data undefined (RecapPageWithLiveData)' />
  if (liveSession.data.endedAt === undefined) return <NoRecapAvailable />
  if (liveSession.data.enableRecap === false) return <NoRecapAvailable />

  return <RecapPageWithContent liveSession={liveSession} liveSessionData={liveSessionData} {...props} />
}

export const RecapPage: React.FC<{
  liveSessionId: LiveSessionId
  fileId?: FileId
}> = props => {
  const queryResult = useCachedQuery(XRealtimeContentGetLiveSession, {
    liveSessionId: props.liveSessionId,
  })

  useEffect(() => {
    if (queryResult.isError) {
      if (queryResult.error instanceof RequestError) {
        if (queryResult.error.status === 404) {
          void notFound404Redirect()
        }
      } else {
        throw queryResult.error
      }
    }
  }, [queryResult.isError, queryResult.error])

  if (!queryResult.isSuccess) {
    return <Loading debugMessage='Get live session query result is not success (RecapPage)' />
  }

  const liveSession = queryResult.data
  const scopedLiveSessionId = ScopedLiveSessionId.fromId(props.liveSessionId)

  return (
    <SanaPage
      mode='light'
      page={PageIdentifier.LiveSessionRecapPage({
        liveSessionId: props.liveSessionId,
        sessionTitle: liveSession.data.title,
      })}
    >
      <LiveSessionContextProvider value={liveSession}>
        <LiveSessionYMapSyncAbly liveSessionId={scopedLiveSessionId} />
        <LiveSessionAblyPresenceReduxSync liveSessionId={scopedLiveSessionId} />
        <RecapPageWithLiveData {...props} />
      </LiveSessionContextProvider>
      <SyncClock />
    </SanaPage>
  )
}
