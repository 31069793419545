import { useEffect, useMemo } from 'react'
import { VideoPlayerCard } from 'sierra-client/components/liveV2/cards/video-player-card'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { RecapFile } from 'sierra-client/state/flexible-content/recap-file'
import { useDispatch } from 'sierra-client/state/hooks'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { LearnerImageCard } from 'sierra-client/views/flexible-content/image-card'
import { SlateCard } from 'sierra-client/views/flexible-content/slate-card'
import { StickyNotesCard } from 'sierra-client/views/sticky-notes-card/learner'
import { LiveDropAWordDataProvider } from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { LiveDropAWordCard } from 'sierra-client/views/v3-author/drop-a-word/live-drop-a-word-card'
import { TutorState } from 'sierra-client/views/v3-author/qa-card/use-qa-state'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { assertIsFileOfType, assertIsSlateFile } from 'sierra-domain/flexible-content/types'

const Switch: React.FC<{ liveSessionId: LiveSessionId; file: RecapFile; courseId: CreateContentId }> = ({
  file,
  courseId,
  liveSessionId,
}) => {
  const mode: EditorMode = 'recap'

  const assetContext = useMemo(() => ({ type: 'course' as const, courseId: courseId }), [courseId])
  const tutorState: TutorState = useMemo(() => ({ type: 'unavailable' }), [])

  switch (file.data.type) {
    case 'image':
      return <LearnerImageCard data={file.data} assetContext={assetContext} />
    case 'embed':
      return <EmbedCard readOnly data={file.data} />
    case 'sticky-notes':
      return (
        <StickyNotesCard
          liveSessionId={ScopedLiveSessionId.fromId(liveSessionId)}
          blockedDueToSessionLimit={false}
          fileId={file.id}
          mode={mode}
        />
      )
    case 'video':
      return <VideoPlayerCard video={file.data.video} />
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'reflections':
    case 'question-card':
    case 'slate-card':
    case 'bullet':
    case 'general':
    case 'external-notepad':
    case 'notepad': {
      assertIsSlateFile(file)

      return <SlateCard tutorState={tutorState} readOnly courseId={courseId} file={file} mode={mode} />
    }
    case 'drop-a-word': {
      assertIsFileOfType(file, 'drop-a-word')

      return (
        <LiveDropAWordDataProvider
          contentId={courseId}
          file={file}
          liveSessionId={liveSessionId}
          isInteractive={false}
        >
          <LiveDropAWordCard contentId={courseId} addWordDisabled fileData={file.data} fileId={file.id} />
        </LiveDropAWordDataProvider>
      )
    }

    default:
      file.data satisfies never
      return null
  }
}

export const RecapCardRenderer: React.FC<{
  file: RecapFile
  scopedCreateContentId: ScopedCreateContentId
  liveSessionId: LiveSessionId
}> = ({ file, scopedCreateContentId, liveSessionId }) => {
  const dispatch = useDispatch()

  const courseId = ScopedCreateContentId.extractId(scopedCreateContentId)
  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: courseId,
        fileId: file.id,
        cardType: file.data.type,
        mode: 'recap',
      })
    )
  }, [courseId, dispatch, file.data.type, file.id])

  return (
    <FocusEditorContext>
      <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
        <Switch file={file} courseId={courseId} liveSessionId={liveSessionId} />
      </FileContext>
    </FocusEditorContext>
  )
}
