import { Icon } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const SpinningIcon = styled(Icon)<{ $isSpinning: boolean }>`
  ${p =>
    p.$isSpinning &&
    css`
      animation: spin 1.5s ease-in-out 150ms infinite;
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }
    `}
  & svg {
    display: block;
    width: 24px;
    height: 24px;
    color: ${token('foreground/primary')};
  }
`
