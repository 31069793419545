import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMeasure from 'react-use-measure'
import { getFlag } from 'sierra-client/config/global-config'
import { useUploadImage } from 'sierra-client/hooks/use-image-upload'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UnsplashSuggestions } from 'sierra-client/hooks/use-unsplash-images'
import { MediaUploadWrapper } from 'sierra-client/views/v3-author/common/media-uploader/shared'
import { FileTypes } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { toSanaImage } from 'sierra-client/views/v3-author/images/unsplash-browser'
import { acceptedImageTypes } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageData } from 'sierra-domain/flexible-content/types'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { IconButton, LoadingSpinner, Skeleton, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { useFocused, useSelected } from 'slate-react'
import styled, { css } from 'styled-components'

const ImageSelectorWrapper = styled(MediaUploadWrapper)<{
  isDraggingOver: boolean
  fullscreen: boolean
  $selected: boolean
}>`
  background-color: ${p =>
    p.isDraggingOver ? p.theme.color.blueLighter : color(p.theme.home.textColor).opacity(0.05).toString()};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${p =>
    getFlag('new-block-radius') ? p.theme.borderRadius['new-block-radius'] : p.theme.borderRadius['size-10']};
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-bottom: 16px;

  &:hover {
    ${p => color(p.theme.home.textColor).opacity(0.1).toString()}
  }

  ${p =>
    p.fullscreen
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: 100%;
          height: auto;
          aspect-ratio: 3 / 4;
          max-height: 500px;
        `}
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`

const PreviewImages = styled.div`
  display: flex;
  gap: ${spacing[8]};
`

const imageWidth = 64

const PreviewImage = styled.img`
  width: ${imageWidth}px;
  height: ${imageWidth}px;
  object-fit: cover;
  object-position: center;
  border-radius: ${p => p.theme.borderRadius['size-10']};

  transition:
    box-shadow 100ms ease-in-out,
    transform 100ms ease-in-out;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0px 2px rgb(0 0 0 / 0.2);
  }
`

const Opacity = styled.div`
  opacity: 0.3;
`

const SuggestionsView = styled(View)``

export const ImageSelector: React.FC<{
  onOpen: () => void
  onDelete?: () => void
  contentEditable?: boolean | 'inherit'
  className?: string
  onUploadDone: (payload: ImageData['image']) => void
  fullscreen?: boolean
  suggestedQuery?: string
  unsplashSuggestions?: Omit<UnsplashSuggestions, 'requestMore' | 'resolvedQuery'>
  // You can trigger the image in the given file to be uploaded by setting this prop
  initialFile?: File
  assetContext: AssetContext
}> = ({
  onOpen,
  className,
  onUploadDone: _onUploadDone,
  fullscreen,
  onDelete,
  unsplashSuggestions,
  initialFile,
  assetContext,
}) => {
  const { uploadImage: _uploadImage } = useUploadImage()
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const { t } = useTranslation()

  const focused = useFocused()
  const selected = useSelected()

  const [isLoading, setIsLoading] = useState(false)

  const onUploadDone = useStableFunction(_onUploadDone)
  const uploadImage: typeof _uploadImage = useCallback(
    async (image, assetContext) => {
      setIsLoading(true)
      try {
        const result = await _uploadImage(image, assetContext)
        onUploadDone(result)
        return result
      } finally {
        setIsLoading(false)
      }
    },
    [_uploadImage, onUploadDone]
  )

  useEffect(() => {
    if (initialFile === undefined) return
    void uploadImage(initialFile, assetContext)
  }, [initialFile, uploadImage, assetContext])

  const [ref, { width }] = useMeasure()

  const numberOfSuggestions = width > 4 * imageWidth + 32 ? 4 : width > 2 * imageWidth + 32 ? 2 : 0
  const suggestions = useMemo(() => {
    if (!unsplashSuggestions || unsplashSuggestions.isLoading) return []
    return _.take(_.shuffle(_.take(unsplashSuggestions.images, 20)), numberOfSuggestions)
  }, [numberOfSuggestions, unsplashSuggestions])

  return (
    <ImageSelectorWrapper
      $selected={focused && selected}
      ref={ref}
      className={className}
      onClick={() => onOpen()}
      contentEditable={false}
      onDragOver={e => {
        if (e.dataTransfer.items[0] === undefined) return
        if (acceptedImageTypes.includes(e.dataTransfer.items[0].type as FileTypes)) {
          e.preventDefault()
          setIsDraggingOver(true)
        }
      }}
      onDragLeave={() => setIsDraggingOver(false)}
      onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDraggingOver(false)
        const file = e.dataTransfer.files[0]
        if (file !== undefined && acceptedImageTypes.includes(file.type as FileTypes)) {
          await uploadImage(file, assetContext)
        }
      }}
      isDraggingOver={isDraggingOver}
      fullscreen={fullscreen === true}
    >
      {fullscreen !== true && (
        <CloseButtonWrapper>
          <IconButton
            iconId='trash-can'
            variant='transparent'
            size='small'
            color='foreground/muted'
            onClick={onDelete}
          />
        </CloseButtonWrapper>
      )}
      <View justifyContent='center' alignItems='center' direction='column' grow={true}>
        {isLoading ? (
          <LoadingSpinner size='48' />
        ) : (
          <>
            <Opacity>
              <Icon iconId='image' size='size-24' />
            </Opacity>
            <Opacity>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                {t('author.image-picker.prompt')}
              </Text>
            </Opacity>
          </>
        )}
      </View>
      {numberOfSuggestions > 0 && (
        <SuggestionsView justifyContent='flex-end' alignItems='center' direction='column' gap='10'>
          {unsplashSuggestions === undefined ? null : (
            <>
              <>
                <Opacity>
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                    {t('author.image-picker.suggestions')}
                  </Text>
                </Opacity>
                <PreviewImages>
                  {unsplashSuggestions.isLoading
                    ? _.range(numberOfSuggestions).map(i => (
                        <Skeleton $height={imageWidth} $width={imageWidth} $radius={10} key={i} />
                      ))
                    : suggestions.map(image => (
                        <PreviewImage
                          onClick={async e => {
                            e.preventDefault()
                            e.stopPropagation()
                            onUploadDone(await toSanaImage(image))
                          }}
                          key={image.id}
                          src={image.urls.thumb}
                          id={image.urls.thumb}
                        />
                      ))}
                </PreviewImages>
              </>
            </>
          )}
        </SuggestionsView>
      )}
    </ImageSelectorWrapper>
  )
}
