import { useEffect } from 'react'
import { useHasUser } from 'sierra-client/api/hooks/use-user'
import { selectCurrentPathId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchPathById } from 'sierra-client/state/v2/paths-actions'

// Fetch new path data whenever the current path changes
export const PathContentFetcher = (): null => {
  const dispatch = useDispatch()
  const currentPathId = useSelector(selectCurrentPathId)
  const hasUser = useHasUser()

  useEffect(() => {
    if (!hasUser) return

    if (currentPathId === undefined) return

    void dispatch(fetchPathById({ pathId: currentPathId }))
  }, [currentPathId, dispatch, hasUser])

  return null
}
